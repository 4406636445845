const code = (color: string): string => {
  switch (color) {
    case "Pending":
      return "#DE4625"
    case "In Progress":
      return "#C19C4F"
    case "Notified":
      return "#C19C4F"
    case "Cancelled":
      return "#A56300"
    case "Cancel":
      return "#D05611"
    case "Approved":
      return "#166CBB"
    case "Closed":
      return "#004E8B"
    case "No Show":
      return "green"
    case "Arrived":
      return "green"
    case "Collected":
      return "#166CBB"
    case "Resolved":
      return "#166CBB"
    case "Received":
      return "#166CBB"
    default:
      return color
  }
}

const ColorStatus = {
  code
}

export default ColorStatus
