import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import TabsButtons from "components/Table/components/TabsButtons/static"
import Footer from "components/Forms/Footer"
import Form from "pages/Settings/Others/UploadCSV/components/Form"
import FileUpload from "pages/Settings/Others/UploadCSV/components/FileUpload"

/** Context */
import withContext from "context/Settings/Others/UploadCSV/main/withContext"
import { CtxType } from "context/Settings/Others/UploadCSV/main/Context"

const OptionsTab: Record<number, string> = {
  1: "File Type",
  2: "Upload CSV"
}

const SettingsOthersView: React.FC<CtxType> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [activeTab, setActiveTab] = React.useState(1)

  /** Methods */
  const handleNext = () => {
    if (activeTab !== 1) {
      return null
    }
    return setActiveTab(2)
  }

  const handleBack = () => {
    if (activeTab !== 2) {
      return false
    }

    return setActiveTab(1)
  }

  /** Views */
  const ViewForm = (): JSX.Element => {
    if (activeTab !== 1) {
      return <div />
    }
    return <Form />
  }

  const ViewFileUpload = (): JSX.Element => {
    if (activeTab !== 2) {
      return <div />
    }
    return <FileUpload />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box>
          <PageHeaderTitle
            title="Upload CSV"
            breadcrumbs="settings / others / upload csv"
          />
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <TabsButtons options={OptionsTab} activeTab={activeTab} />
        </Box>
        <Card>
          <Box>
            <ViewForm />
            <ViewFileUpload />
          </Box>
          <Box margin="0 0 30px">
            <Footer
              handleNext={() => handleNext()}
              handleBack={activeTab === 1 ? null : () => handleBack()}
              handleCancel={() => null}
              label="Next"
              hideCancel
            />
          </Box>
        </Card>
      </WithHeader>
    )
  }

  return <>{MainView()}</>
}

export default withContext(SettingsOthersView)
