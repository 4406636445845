import React from "react"

export type CtxType = {
  showAddForm: Function
  setActiveFilter: Function
  filterTable: Function
  setDownloadData: Function
  showUpdateForm: Function
  activeFilter: number
  activeTable: number
  initData: Record<any, any>
  downloadData: Array<{}>
}

export const Context = React.createContext<Partial<CtxType>>({})
