import React, { useState } from "react"
import Cookie from "js-cookie"
import { useSelector } from "react-redux"
/**
 * Components
 */
import Toast from "components/Toast"
/**
 * Services
 */
import UnitManagementAPI from "services/Dashboard/UnitManagement"
/**
 * Context
 */
import { Context as ContextMain } from "context/Dashboard/UnitManagement/categories/main/Context"
import { toUnix } from "utils/date"
import { UploadImage } from "utils/uploadFile"
import { Context } from "./Context"

interface CreateUserProps {
  data?: Record<string, any>
  successCallback?: Function
}

const CreateUserProvider: React.FC<CreateUserProps> = ({
  children,
  data,
  successCallback
}) => {
  const { userData } = useSelector((state: any) => state.user)
  const { showMain } = React.useContext(ContextMain)

  /** check if its from new request or owners/tenants */
  /** this will be used for the profile photos/attachments */
  const hasData = data !== undefined
  const fromData = data?.attachments !== undefined

  const [openDialog, setOpenDialog] = useState(false)
  const [attaching, setAttaching] = useState(false)
  const [fileAttaching, setFileAttaching] = useState(false)
  const [photos, setPhotos] = useState<any>(data?.photos?.files || Array)
  const [localPhotos, setLocalPhotos] = useState<any>(data?.photos?.files || Array)
  const [files, setFiles] = useState<any>(data?.supporting_doc?.files || Array)
  const [userDetails, setuserDetails] = useState({
    uid: data?._uid,
    name: data?.name || "",
    firstName: "",
    lastName: "",
    phoneNo: data?.phone_no || "",
    mobileNo: data?.mobile_no || "",
    unitUid: data?.unit_uid || "",
    email: data?.email || "",
    isResident: data?.is_resident || false,
    registeredAddress: data?.registered_address || "",
    strataTitle: data?.strata_title || false,
    tenancyStart: data?.tenancy_start || "",
    tenancyEnd: data?.tenancy_end || "",
    roles: "",
    formType: data?.owner_or_tenant === 1 ? "owner" : "tenant",
    blockUnit: data?.unit_uid || data?.unit?._uid || ""
  })

  React.useEffect(() => {
    if (fromData) {
      setPhotos(data?.attachments)
    }
    if (data?.profile_photo?.key !== "") {
      setPhotos([data?.profile_photo])
    }

    if (!hasData) {
      setPhotos([])
    }
  }, [])

  /** Notification */
  // const notifyCreate = (successMessage: string) =>
  //   toast(successMessage, {
  //     type: toast.TYPE.SUCCESS,
  //   })

  // const notifyError = (message: string) =>
  //   toast(`${message}`, {
  //     type: toast.TYPE.ERROR,
  //   })

  // const notifyOwnerNotAllowed = () =>
  //   toast(`Owner must have Strata title or is residing in the unit`, {
  //     type: toast.TYPE.ERROR,
  //   })

  /** Methods */
  const handleRegisterUser = async () => {
    const ownerData = {
      "name": userDetails.name,
      "phone_no": userDetails.phoneNo,
      "mobile_no": userDetails.mobileNo,
      "unit_uid": userDetails.blockUnit,
      "email": userDetails.email,
      "is_resident": userDetails.isResident,
      "registered_address": userDetails.registeredAddress,
      "strata_title": userDetails.strataTitle,
      "account_request_uid": userData?.account_uid,
      "profile_photo": {
        "key": photos[0]?.key || "",
        "file_name": photos[0]?.file_name || "",
        "encoding": photos[0]?.encoding || "",
        "file_type": 1
      },
      "supporting_doc": {
        "files": [
          {
            ...files[0],
            "file_type": 2
          }
        ]
      },
      "condo_uid": Cookie.get("condoUID")
    }
    const tenantData = {
      "name": userDetails.name,
      "phone_no": userDetails.phoneNo,
      "mobile_no": userDetails.mobileNo,
      "unit_uid": userDetails.blockUnit,
      "email": userDetails.email,
      "registered_address": userDetails.registeredAddress,
      "tenancy_start": toUnix(userDetails?.tenancyStart),
      "tenancy_end": toUnix(userDetails?.tenancyEnd),
      "account_request_uid": userData?.account_uid,
      "profile_photo": {
        "key": photos[0]?.key || "",
        "file_name": photos[0]?.file_name || "",
        "encoding": photos[0]?.encoding || "",
        "file_type": 1
      },
      "supporting_doc": {
        "files": [
          {
            ...files[0],
            "file_type": 2
          }
        ]
      },
      "condo_uid": Cookie.get("condoUID")
    }
    const formData = userDetails.formType === "owner" ? ownerData : tenantData

    if (
      userDetails.formType === "owner" &&
      userDetails.isResident === false &&
      userDetails.strataTitle === false
    ) {
      Toast(`Owner must have Strata title or is residing in the unit`, false)
      return false
    }

    if (!hasData) {
      try {
        const response =
          userDetails.formType === "owner"
            ? await UnitManagementAPI.createOwner(formData)
            : await UnitManagementAPI.createTenant(formData)

        const statusCode = response?.data?._statusCode

        if (statusCode === -114) {
          return Toast(response?.data?._message, false)
        }

        if (statusCode === -113) {
          return Toast(response?.data?._message, false)
        }

        if (statusCode === -121) {
          return Toast("Email or Name already exists. Please try again.", false)
        }

        Toast(
          `${userDetails.strataTitle ? "OWNER" : "RESIDENT"} successfully created.`,
          true
        )

        showMain && showMain()

        return response
      } catch (e) {
        return e
      }
    } else {
      try {
        const response =
          userDetails.formType === "owner"
            ? UnitManagementAPI.updateOwner(formData, userDetails?.uid)
            : UnitManagementAPI.updateTenant(formData, userDetails?.uid)
        Toast(
          `${!userDetails.isResident ? "OWNER" : "RESIDENT"} successfully updated.`,
          true
        )

        if (successCallback === undefined) {
          showMain && showMain()
        } else {
          successCallback && successCallback()
        }

        return response
      } catch (e) {
        return e
      }
    }
  }

  function handleImageUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  const removePhoto = (value: number) => {
    const fileFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })

    const fileLocalFiltered = localPhotos.filter((item: any, index: number) => {
      return index !== value
    })

    setPhotos(fileFiltered)
    setLocalPhotos(fileLocalFiltered)
  }

  function handleFileUpload(event: any) {
    setFileAttaching(true)
    UploadImage(event, 2)
      .then((output: any) => {
        setFiles([...files, output])
        setFileAttaching(false)
      })
      .catch(() => setFileAttaching(false))
  }

  const removeFile = (value: number) => {
    const fileFiltered = files.filter((item: any, index: number) => {
      return index !== value
    })
    setFiles(fileFiltered)
  }

  return (
    <Context.Provider
      value={{
        handleRegisterUser,
        handleImageUpload,
        setPhotos,
        removePhoto,
        setOpenDialog,
        setuserDetails,
        setFiles,
        handleFileUpload,
        removeFile,
        userDetails,
        attaching,
        fileAttaching,
        photos,
        localPhotos,
        openDialog,
        files
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreateUserProvider
