import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  titleStyle: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.body.main
  },
  facilityContainer: {
    width: "100%",
    overflowX: "auto",
    // paddingTop: "30px",
    display: "flex",
    "& > div": {
      marginRight: "30px"
      // paddingBottom: "20px",
    }
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "353px",
    height: "127px",
    overflow: "hidden",
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white
  },
  content: {
    padding: "10px 0"
  },
  imageContainer: {
    borderRadius: "10px",
    overflow: "hidden",
    minWidth: "124px",
    height: "100%",
    marginRight: "15px"
  },
  nameStyle: {
    color: theme.palette.body.main,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "26px",
    width: "100%"
  },
  total: {
    color: theme.palette.body.secondary,
    fontSize: "14px",
    lineHeight: "20px"
  },
  status: {
    color: theme.palette.body.gray
  }
}))

export default styles
