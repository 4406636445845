import React from "react"

/** Context */
import { Context } from "./Context"

/** Interface */
export interface VMSServiceProvidersProviderProps {
  showAddForm: Function
}

const VMSServiceProvidersProvider: React.FC<VMSServiceProvidersProviderProps> = ({
  children,
  showAddForm
}) => {
  /** State */
  const [activeFilter, setActiveFilter] = React.useState(0)

  /** Methods */
  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  return (
    <Context.Provider
      value={{
        showAddForm,
        activeFilter,
        setActiveFilter,
        filterTable
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default VMSServiceProvidersProvider
