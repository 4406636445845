import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Component */
import Drawer from "components/Drawer/new"
import Header from "components/Header"
import Footer from "components/Forms/Footer/new"
import service from "services/Settings/PropertyManagement/Feedback"
import Form from "./form"

/** Service */

interface Props {
  openState: boolean
  data?: Record<string, any>
  action?: Function
  setopenState: Function
}

export const refSubmit = React.createRef<HTMLDivElement>()

const CategoryDetails: React.FC<Props> = ({ openState, setopenState, data }) => {
  const hasData = data !== undefined
  const [details, setDetails] = useState({
    name: "",
    bccEmailFirst: ""
  })
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    setDetails({
      name: data?.feedback_type_name || "",
      bccEmailFirst: data?.bcc_emails[0] || ""
    })
  }, [data])

  /** Notification */
  const notifyCreate = () =>
    toast(`Successfully ${hasData ? "updated" : "created"} the category.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleFormChange = (
    name: string,
    e: any,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)
    setDetails &&
      setDetails({
        ...details,
        [name]: e.target.value
      })
  }

  const handleFormSubmit = async () => {
    setSubmitting(true)

    const payload = {
      "bcc_emails": [details?.bccEmailFirst]
    }

    try {
      const response = await service.updateApplication(payload, data?._uid)
      mutate("fetchSettingsFeedback")
      notifyCreate()
      setSubmitting(false)
      setopenState(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const handleSubmit = () => {
    if (details?.name !== "" && details?.bccEmailFirst !== "") {
      handleFormSubmit()
    } else {
      refSubmit.current?.click()
    }
  }

  const ViewForm = () => {
    return <Form details={details} formChange={handleFormChange} />
  }

  return (
    <Drawer
      openState={openState || false}
      setOpenState={(value: boolean) => setopenState(value)}
    >
      <Header
        title="Update Category"
        subtitle="SETTINGS / application / update category"
      />
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="1">{ViewForm()}</Box>
        <Box paddingBottom="30px">
          <Footer
            handleNext={() => (isSubmitting ? null : handleSubmit())}
            handleBack={null}
            handleCancel={() =>
              isSubmitting ? null : setopenState && setopenState(false)}
            label={isSubmitting ? "Submitting" : "Submit"}
          />
        </Box>
      </Box>
    </Drawer>
  )
}

export default CategoryDetails
