import React, { useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Context */
import { WrapperContext } from "pages/Dashboard/UnitManagement/components/UnitDetails"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/unitDetails/Context"
import { CtxMenuContext } from "components/ContextMenu"
/** Styles */
import { IconEdit, IconView } from "components/Icons/ContextMenu"
import styles from "./styles"

interface ContextOwnerProps {
  data: Record<string, any>
}

const ContextBookKeeping: React.FC<ContextOwnerProps> = ({ data }) => {
  const { listItem } = styles()
  const { showEdit } = useContext(WrapperContext)
  const { setShowDetails, setActiveData } = useContext(ParentContext)
  const { openContext } = useContext(CtxMenuContext)

  /** Methods */
  function handleEdit(dataItems: Record<string, any>): void {
    openContext && openContext(false)
    showEdit && showEdit(dataItems)
  }

  function handleView(dataItems: Record<string, any>): void {
    openContext && openContext(false)
    setActiveData && setActiveData(dataItems)
    setShowDetails && setShowDetails(true)
  }

  return (
    <>
      <List component="nav">
        <ListItem className={listItem} button onClick={() => handleView(data)}>
          <i>{IconView}</i>
          View
        </ListItem>
        <ListItem className={listItem} button onClick={(): void => handleEdit(data)}>
          <i>{IconEdit}</i>
          Edit
        </ListItem>
      </List>
      {/* <Dialog
        action={() => handleUpdateStatus()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the status?"
      /> */}
    </>
  )
}

export default ContextBookKeeping
