import useSWR from "swr"
import service from "services/Settings/AccountManagement/Admin"

function AdminSettingsTableHook() {
  const fetchSettingsAdmin = async () => service.getAdmins()

  const { data, isValidating, error } = useSWR(
    "fetchSettingsAdmin",
    fetchSettingsAdmin,
    {
      revalidateOnFocus: true
    }
  )
  const tableData = data?.data?._data

  return {
    tableData,
    isValidating,
    error
  }
}

export default AdminSettingsTableHook
