import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(340px, 340px))",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    padding: "20px"
  }
}))

export default styles
