import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import UploadBox from "components/UploadBox/new"

/** Context */
import { CtxType } from "context/Dashboard/Applications/forms/addApplication/Context"
import withContext from "context/Dashboard/Applications/forms/addApplication/withContext"

/** Styles */
import styles from "./styles"

const SupportingDocument: React.FC<CtxType> = ({
  handleDocsUpload,
  files,
  removeDoc,
  fileAttaching
}) => {
  const { root } = styles()
  return (
    <div className={root}>
      <FormWrapper title="Supporting Documents" width="50%">
        <Box width="50%" margin="auto" padding="0 0 40px 0">
          <UploadBox
            hasTitle={false}
            onDrop={(event: any) => handleDocsUpload(event, 2)}
            files={files}
            removePhoto={(event: any) => removeDoc(event, 2)}
            attaching={fileAttaching}
            title="Choose file to upload"
            acceptedFile="application/pdf"
          />
        </Box>
      </FormWrapper>
    </div>
  )
}

export default withContext(SupportingDocument)
