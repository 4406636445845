import { Machine } from "xstate"

export const FacilitiesMachine = Machine({
  id: "facilitiesMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_BOOKING: "addBooking",
        EDIT_BOOKING: "editBooking",
        APPROVE_BOOKING: "approveBooking",
        REJECT_BOOKING: "rejectBooking"
      }
    },
    addBooking: {
      on: {
        BACK: "tableView"
      }
    },
    editBooking: {
      on: {
        BACK: "tableView"
      }
    },
    approveBooking: {
      on: {
        BACK: "tableView"
      }
    },
    rejectBooking: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_BOOKING: "addBooking",
    EDIT_BOOKING: "editBooking",
    APPROVE_BOOKING: "approveBooking",
    REJECT_BOOKING: "rejectBooking"
  }
})
