import React from "react"
import Box from "@material-ui/core/Box"

/**
 * Component
 */
import Drawer from "components/Drawer"
import Header from "components/Header"
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"
/**
 * Config
 */
import { type, status, vehicleType } from "config/Dashboard/Applications"
/**
 * Styles
 */
import { fromUnix, fromUnixTime } from "utils/date"
import styles from "./styles"
/**
 * utils
 */

interface ApplicationDetailsProps {
  openDetails: boolean
  setopenDetails: Function
  activeDetails: Record<string, any> | undefined
}

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
  openDetails,
  setopenDetails,
  activeDetails
}) => {
  const classes = styles()
  const ad = activeDetails
  const files = ad?.supporting_doc?.files
  const photos = ad?.request_data?.vehicle?.vehicle_photo?.photos
  const hasFiles = files !== undefined && files !== null
  const hasPhotos = photos !== undefined && photos !== null
  const statuses = ad?.status_update_time?.status_changes
  let rejRemarks = ""
  if (statuses && statuses.length > 0) {
    statuses.forEach((ele: any) => {
      if (ele.status_change_to === 3 && ad?.status === 3) {
        rejRemarks = ele.rejected_remarks
      }
    })
  }

  function checkValue(value: any, output?: string) {
    if (value === 0 || value === "") {
      return "N/A"
    }

    return output !== undefined ? output : value
  }

  function ViewMovingDetails(): JSX.Element {
    if (ad?.application_type !== 2) {
      return <div />
    }

    return (
      <Box>
        <Box className={classes.sectionItem}>
          <Label label="Name" />
          <Value val={ad?.request_data?.move_in_out?.name} />
        </Box>
        <Box display="flex">
          <Box flex="1" className={classes.sectionItem}>
            <Label label="ETA Date" />
            <Value val={fromUnix(ad?.request_data?.move_in_out?.eta_ms)} />
          </Box>
          <Box flex="1" className={classes.sectionItem}>
            <Label label="ETA Time" />
            <Value val={fromUnixTime(ad?.request_data?.move_in_out?.eta_ms)} />
          </Box>
        </Box>
        <Box className={classes.sectionItem}>
          <Label label="purpose" />
          <Value val={checkValue(ad?.request_data?.move_in_out?.purpose)} />
        </Box>

        {hasFiles && (
          <Box className={classes.sectionItem}>
            <Label label="supporting docs" />
            <Box>
              {files &&
                Object.keys(files).map((file) => {
                  return (
                    <Box>
                      <a href={files[file]?.url} target="_blank" rel="noreferrer">
                        <Value val={files[file].file_name} />
                      </a>
                    </Box>
                  )
                })}
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  function ViewVehiclesDetails(): JSX.Element {
    if (ad?.application_type !== 1) {
      return <div />
    }

    return (
      <Box>
        <Box className={classes.sectionItem}>
          <Label label="owner name" />
          <Value val={ad?.request_data?.vehicle?.owner_name} />
        </Box>

        <Box className={classes.sectionItem}>
          <Label label="vehicle type" />
          <Value
            val={checkValue(vehicleType[ad?.request_data?.vehicle?.vehicle_type])}
          />
        </Box>
        <Box className={classes.sectionItem}>
          <Label label="vehicle model" />
          <Value val={checkValue(ad?.request_data?.vehicle?.vehicle_model)} />
        </Box>

        <Box display="flex">
          <Box className={classes.sectionItem}>
            <Label label="vehicle number" />
            <Value val={checkValue(ad?.request_data?.vehicle?.vehicle_no)} />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="iu number" />
            <Value val={checkValue(ad?.request_data?.vehicle?.iu_no)} />
          </Box>
        </Box>
        <Box display="flex">
          <Box className={classes.sectionItem}>
            <Label label="start date" />
            <Value val={checkValue(ad?.start_date, fromUnix(ad?.start_date))} />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="end date" />
            <Value val={checkValue(ad?.end_date, fromUnix(ad?.end_date))} />
          </Box>
        </Box>

        {hasFiles && (
          <Box display="flex">
            <Box className={classes.sectionItem}>
              <Label label="supporting docs" />
              <Box>
                {files &&
                  Object.keys(files).map((file) => {
                    return (
                      <Box>
                        <a href={files[file]?.url} target="_blank" rel="noreferrer">
                          <Value val={files[file].file_name} />
                        </a>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Box>
        )}

        {hasPhotos && (
          <Box display="flex">
            <Box className={classes.sectionItem}>
              <Label label="photos" />
              <Box>
                {photos &&
                  Object.keys(photos).map((photo) => {
                    return (
                      <Box>
                        <a
                          href={photos[photo]?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Value val={photos[photo].file_name} />
                        </a>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  function ViewAccessCardDetails(): JSX.Element {
    if (
      ad?.application_type !== 3 &&
      ad?.application_type !== 4 &&
      ad?.application_type !== 5
    ) {
      return <div />
    }

    return (
      <Box>
        <Box className={classes.sectionItem}>
          <Label label="serial no#" />
          <Value val={ad?.serial_no} />
        </Box>
        <Box display="flex">
          <Box className={classes.sectionItem}>
            <Label label="start date" />
            <Value val={checkValue(ad?.start_date, fromUnix(ad?.start_date))} />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="end date" />
            <Value val={checkValue(ad?.end_date, fromUnix(ad?.end_date))} />
          </Box>
        </Box>

        {hasFiles && (
          <Box display="flex">
            <Box className={classes.sectionItem}>
              <Label label="supporting docs" />
              <Box>
                {files &&
                  Object.keys(files).map((file) => {
                    return (
                      <Box>
                        <a href={files[file]?.url} target="_blank" rel="noreferrer">
                          <Value val={files[file].file_name} />
                        </a>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  function ViewRenovationDetails(): JSX.Element {
    if (ad?.application_type !== 6) {
      return <div />
    }

    return (
      <Box>
        <Box className={classes.sectionItem}>
          <Label label="contractor name" />
          <Value val={ad?.request_data?.renovation?.contractor_name} />
        </Box>
        <Box className={classes.sectionItem}>
          <Label label="contractor contact" />
          <Value val={ad?.request_data?.renovation?.contractor_contact} />
        </Box>
        <Box display="flex">
          <Box className={classes.sectionItem}>
            <Label label="start date" />
            <Value val={checkValue(ad?.start_date, fromUnix(ad?.start_date))} />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="end date" />
            <Value val={checkValue(ad?.end_date, fromUnix(ad?.end_date))} />
          </Box>
        </Box>

        {hasFiles && (
          <Box display="flex">
            <Box className={classes.sectionItem}>
              <Label label="supporting docs" />
              <Box>
                {files &&
                  Object.keys(files).map((file) => {
                    return (
                      <Box>
                        <a href={files[file]?.url} target="_blank" rel="noreferrer">
                          <Value val={files[file].file_name} />
                        </a>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      <Drawer openState={openDetails} setopenState={setopenDetails}>
        <Header
          title={type[ad?.application_type]}
          subtitle="View / Applications"
          subtitleAbove
        />

        <Box className={classes.section}>
          <Box className={classes.sectionPadding}>
            <Box className={classes.sectionItem}>
              <Label label="date received" />
              <Value val={fromUnix(ad?.application_date)} />
            </Box>
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionPadding}>
            <Box>
              <Box className={classes.sectionTitle}>Personal Details</Box>
            </Box>
            <Box>
              <Box className={classes.sectionItem}>
                <Label label="block/unit no." />
                <Value val={ad?.unit?.short_name} />
              </Box>
            </Box>
            <Box>
              <Box className={classes.sectionItem}>
                <Label label="name" />
                <Value val={ad?.applicant_name} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionPadding}>
            <Box>
              <Box className={classes.sectionTitle}>Application Details</Box>
            </Box>
            <ViewMovingDetails />
            <ViewVehiclesDetails />
            <ViewAccessCardDetails />
            <ViewRenovationDetails />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionPadding}>
            <Box>
              <Box className={classes.sectionTitle}>Status & Remarks</Box>
            </Box>
            <Box>
              <Box className={classes.sectionItem}>
                <Label label="Status" />
                <Value val={status[ad?.status]} />
              </Box>
            </Box>
            <Box>
              <Box className={classes.sectionItem}>
                <Label label="Remarks" />
                <Value val={checkValue(ad?.remarks)} />
              </Box>
            </Box>
            <Box>
              {rejRemarks && (
                <Box className={classes.sectionItem}>
                  <Label label="Rejected Remarks" />
                  <Value val={checkValue(rejRemarks)} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default ApplicationDetails
