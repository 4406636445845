import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #D5D5D5",
    boxShadow: "none"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#017c8d",
    minWidth: "270px",
    borderTopRightRadius: "68px",
    paddingLeft: "25px"
  },
  condoName: {
    color: theme.palette.body.main,
    fontSize: "20px",
    fontWeight: 600
  }
}))

export default styles
