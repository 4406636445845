import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import Table from "pages/Settings/PropertyManagement/VisitorManagement/tables/main"
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import CategoryForm from "pages/Settings/PropertyManagement/VisitorManagement/components/CategoryForm"
import PermissionMessage from "components/PermissionMessage"

/** Machine */
import { SettingsVMSMachine } from "machines/Settings/PropertyManagement/VisitorManagement"

/** Context */
import { AppContext } from "App"
import styles from "./styles"

const SettingsVMS: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(SettingsVMSMachine)
  const xValue = current?.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  /** Views */
  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }
    const { title } = styles()
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <PageHeaderTitle
              title="Visitor Management"
              breadcrumbs="SETTINGS / visitor management"
            />
          </Box>
        </Box>

        <WithTable>
          <Box>
            <div className={title}>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 0.132324C0.897 0.132324 0 0.986769 0 2.03744V17.2784H4V15.3733H2V2.03744H12V7.33233C12.174 7.43044 12.343 7.53693 12.5 7.65791C12.935 7.32356 13.446 7.0817 14 6.93977V2.03744C14 0.986769 13.103 0.132324 12 0.132324H2ZM4 3.94256V5.84768H6V3.94256H4ZM8 3.94256V5.84768H10V3.94256H8ZM4 7.75279V9.65791H6V7.75279H4ZM10 8.70535C9.46957 8.70535 8.96086 8.90607 8.58579 9.26335C8.21071 9.62063 8 10.1052 8 10.6105C8 11.1157 8.21071 11.6003 8.58579 11.9576C8.96086 12.3149 9.46957 12.5156 10 12.5156C10.5304 12.5156 11.0391 12.3149 11.4142 11.9576C11.7893 11.6003 12 11.1157 12 10.6105C12 10.1052 11.7893 9.62063 11.4142 9.26335C11.0391 8.90607 10.5304 8.70535 10 8.70535ZM15 8.70535C14.4696 8.70535 13.9609 8.90607 13.5858 9.26335C13.2107 9.62063 13 10.1052 13 10.6105C13 11.1157 13.2107 11.6003 13.5858 11.9576C13.9609 12.3149 14.4696 12.5156 15 12.5156C15.5304 12.5156 16.0391 12.3149 16.4142 11.9576C16.7893 11.6003 17 11.1157 17 10.6105C17 10.1052 16.7893 9.62063 16.4142 9.26335C16.0391 8.90607 15.5304 8.70535 15 8.70535ZM4 11.563V13.4681H6V11.563H4ZM10 14.4207C7.815 14.4207 6 15.2864 6 16.5323V17.2784H11H14H19V16.5323C19 15.2864 17.185 14.4207 15 14.4207C14.0549 14.4207 13.1897 14.59 12.5 14.8747C11.8103 14.59 10.9451 14.4207 10 14.4207Z"
                  fill="#454B57"
                />
              </svg>

              <span>Visitor Management</span>
            </div>
          </Box>
          <Table
            showEdit={() => {
              send("UPDATE_CATEGORY")
            }}
          />
        </WithTable>
      </>
    )
  }

  const ViewSettings = (): JSX.Element => {
    if (xValue !== "addCategory") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Add Category"
              breadcrumbs="SETTINGS / visitor management / add category"
              backAction={() => send("TABLE_VIEW")}
            />
          </Box>
        </Box>
        <CategoryForm sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const ViewUpdateCategory = (): JSX.Element => {
    if (xValue !== "updateCategory") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Update Category"
              breadcrumbs="SETTINGS / visitor management / update category"
              backAction={() => send("TABLE_VIEW")}
            />
          </Box>
        </Box>
        <CategoryForm sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <ViewTable />
        <ViewSettings />
        <ViewUpdateCategory />
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.manage_condo_settings) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default SettingsVMS
