import useSWR from "swr"
import service from "services/Dashboard/Reports"

export function TotalResidentsHook() {
  const fetchTotalResidents = async () => service.getResidents()

  const { data, isValidating, error } = useSWR(
    `fetchTotalResidents`,
    fetchTotalResidents,
    {
      revalidateOnFocus: false
    }
  )
  const totalResidents = data?.data?._data?.total

  return {
    totalResidents,
    isValidating,
    error
  }
}
