import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Machine */
import { FacilityFormMachine } from "machines/Settings/PropertyManagement/Facilities/facilityForm"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import TabsButtons from "components/Table/components/TabsButtons/static"
import FormDetails from "pages/Settings/PropertyManagement/Facilities/components/FacilityForm/Details"
import FormPhotos from "pages/Settings/PropertyManagement/Facilities/components/FacilityForm/Photos"
import FormStatus from "pages/Settings/PropertyManagement/Facilities/components/FacilityForm/Status"

import Footer from "components/Forms/Footer/new"
import Dialog from "components/Dialog"

/** Config */
import { tabsFacilities as OptionTabs } from "config/Settings/PropertyManagement/Facilities"

/** Context */
import { StateValue } from "xstate"
import withContext from "./withContext"
import { CtxType } from "./Context"

export const refSubmit = React.createRef<HTMLDivElement>()

const SettingsFacilitiesView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  submitting,
  showFacilities,
  handleAddFacility,
  hasData
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [validDetails, setValidDetails] = useState(false)
  const [validPhotos, setValidPhotos] = useState(false)
  const [validStatus, setValidStatus] = useState(false)

  const [current, send] = useMachine(FacilityFormMachine)
  const xValue = current?.value

  function steps(step: StateValue) {
    switch (step) {
      case "details":
        return 0
      case "photos":
        return 1
      case "status":
        return 2
      default:
        return 0
    }
  }

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    /** Step 1 */
    if (xValue === "details" && validDetails) {
      send("NEXT")
    }

    /** Step 2*/
    if (xValue === "photos" && validPhotos) {
      send("NEXT")
    }

    /** Step 3*/
    if (xValue === "status" && validStatus) {
      handleAddFacility()
    }
  }

  function ViewDetails(): JSX.Element {
    if (xValue !== "details") {
      return <div />
    }
    return <FormDetails setValid={setValidDetails} />
  }

  function ViewPhotos(): JSX.Element {
    if (xValue !== "photos") {
      return <div />
    }
    return <FormPhotos setValid={setValidPhotos} />
  }

  function ViewStatus(): JSX.Element {
    if (xValue !== "status") {
      return <div />
    }
    return <FormStatus setValid={setValidStatus} />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box display="flex">
          <Box>
            <PageHeaderTitle
              title={`${hasData ? "Update Facility" : "Add Facility"}`}
              breadcrumbs={`settings / facilities / ${
                hasData ? "update" : "add"
              } facility`}
              backAction={() => showFacilities()}
            />
          </Box>
        </Box>
        <Box>
          <TabsButtons options={OptionTabs} activeTab={steps(xValue)} />
        </Box>
        <Box>
          <Card>
            {ViewDetails()}
            {ViewPhotos()}
            {ViewStatus()}
            <Box margin="0 0 30px">
              <Footer
                handleNext={() => handleNext()}
                handleBack={xValue === "details" ? null : () => send("BACK")}
                handleCancel={() => setOpenDialog && setOpenDialog(true)}
                label={submitting ? "Submitting..." : "Next"}
                disabled={submitting}
              />
            </Box>
          </Card>
          <Dialog
            action={() => showFacilities()}
            isOpen={openDialog}
            setOpen={setOpenDialog}
            actionLabel="OK"
            title=""
            message="Are you sure you want to cancel?"
          />
        </Box>
      </WithHeader>
    )
  }

  return <>{MainView()}</>
}

export default withContext(SettingsFacilitiesView)
