import { Machine } from "xstate"

export const CategoryFormMachine = Machine({
  id: "CategoryFormMachine",
  initial: "details",
  states: {
    details: {
      on: {
        NEXT: "timeSlots"
      }
    },
    timeSlots: {
      on: {
        BACK: "details"
      }
    }
  }
})
