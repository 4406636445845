import React, { useContext, useState, useMemo } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import UploadBox from "components/UploadBox/new"
import DatePicker from "components/Forms/DatePicker"
import Editor from "components/Editor"
import Spacer from "components/Spacer"
import Dialog from "components/Dialog"
/** Context */
import { Context } from "context/Dashboard/Announcements/Posts/forms/post/Context"
/** Validation */
import { createPost } from "config/Dashboard/Announcements/validation"
/** Styles */
import styles from "./styles"

const PostFormCreate: React.FC = () => {
  const { formContainer, formField } = styles()
  const {
    postDetails,
    setPostDetails,
    photos,
    files,
    handleFileUpload,
    removeFile,
    attachingFile,
    handleImageUpload,
    removeImage,
    attachingPhoto,
    handleCreatePost,
    formSubmitting,
    sendBack,
    hasData
  } = useContext(Context)
  const pd = postDetails
  const [openDialog, setOpenDialog] = useState(false)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setPostDetails &&
        setPostDetails({
          ...postDetails,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setPostDetails &&
        setPostDetails({
          ...postDetails,
          [name]: e?.target?.value
        })
    }
  }

  const handleSubmitForm = (handleSubmit: Function, status: number) => {
    if (
      pd?.title !== "" &&
      pd?.shortDescription !== "" &&
      pd?.description !== "" &&
      pd?.startDate !== "" &&
      pd?.endDate !== "" &&
      photos.length !== 0
    ) {
      handleCreatePost && handleCreatePost(status)
    } else {
      handleSubmit()
    }
  }

  const validDescription = String(pd?.description)?.length < 9

  return (
    <div>
      <Box>
        <Formik
          initialValues={useMemo(() => {
            return {
              title: pd?.title,
              startDate: pd?.startDate,
              endDate: pd?.endDate,
              description: pd?.description,
              shortDescription: pd?.shortDescription
            }
          }, [])}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={createPost}
          isInitialValid={hasData || false}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form>
                <Box className={formContainer}>
                  <FormWrapper>
                    <Box className={formField}>
                      <FormInput
                        name="title"
                        value={pd?.title}
                        idValue="title"
                        label="Title"
                        placeholder="Title"
                        handleOnChange={(e: React.ChangeEvent) =>
                          handleFormChange("title", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={errors.title && touched.title && errors.title}
                      />
                    </Box>

                    <Box className={formField}>
                      <FormInput
                        name="shortDescription"
                        value={pd?.shortDescription}
                        idValue="shortDescription"
                        label="Post Excerpt"
                        placeholder="Post Excerpt"
                        charLength={300}
                        multiline
                        rows={5}
                        handleOnChange={(e: React.ChangeEvent) =>
                          handleFormChange("shortDescription", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={
                          touched.shortDescription &&
                          Boolean(errors.shortDescription)
                        }
                        helperText={
                          errors.shortDescription &&
                          touched.shortDescription &&
                          errors.shortDescription
                        }
                      />
                      <Box component="small" color="#777E86">
                        Note: Maximum 300 characters
                      </Box>
                    </Box>

                    <Box className={formField}>
                      <Editor
                        error={touched.description && pd?.description === "<p></p>"}
                        onBlur={handleBlur}
                        content={pd?.description}
                        setContent={(content: any) =>
                          handleFormChange("description", content, setFieldValue)}
                      />
                      {validDescription && (
                        <Box component="small" color="red">
                          * Description is required.
                        </Box>
                      )}
                    </Box>

                    <Box display="flex" className={formField}>
                      <Box flex="1">
                        <DatePicker
                          label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          value={pd?.startDate}
                          placeholder="mm/dd/yyyy"
                          maxDate={pd?.endDate}
                          handleDateChange={(value: string): void =>
                            handleFormChange("startDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={
                            errors.startDate && touched.startDate && errors.startDate
                          }
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <DatePicker
                          label="End Date"
                          name="endDate"
                          format="MM/dd/yyyy"
                          value={pd?.endDate}
                          placeholder="mm/dd/yyyy"
                          minDate={pd?.startDate}
                          handleDateChange={(value: string): void =>
                            handleFormChange("endDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={
                            errors.endDate && touched.endDate && errors.endDate
                          }
                        />
                      </Box>
                    </Box>
                  </FormWrapper>
                </Box>

                <Box className={formContainer}>
                  <FormWrapper title="Upload an Image">
                    <Box padding="0 0 40px 0">
                      <UploadBox
                        hasTitle={false}
                        title="Choose file to upload"
                        files={photos && photos}
                        onDrop={(event: React.ChangeEvent): void =>
                          handleImageUpload && handleImageUpload(event)}
                        removePhoto={(event: React.ChangeEvent): void =>
                          removeImage && removeImage(event)}
                        attaching={attachingPhoto}
                        disabled={photos.length === 3}
                      />
                      {photos.length === 0 && (
                        <Box component="small" color="red">
                          *Image is required.
                        </Box>
                      )}
                    </Box>
                  </FormWrapper>
                </Box>

                <Box className={formContainer}>
                  <FormWrapper title="Attachment">
                    <Box padding="0 0 40px 0">
                      <UploadBox
                        hasTitle={false}
                        title="Choose file to upload"
                        files={files}
                        acceptedFile="application/pdf"
                        onDrop={(event: React.ChangeEvent): void =>
                          handleFileUpload && handleFileUpload(event)}
                        removePhoto={(event: React.ChangeEvent): void =>
                          removeFile && removeFile(event)}
                        attaching={attachingFile || false}
                        disabled={files.length === 1}
                      />
                      {/* {files.length === 0 && (
                        <Box component="small" color="red">
                          *Attachment is required.
                        </Box>
                      )} */}
                    </Box>
                  </FormWrapper>
                </Box>

                <Box display="flex" justifyContent="flex-end" padding="40px 0 0 0">
                  <Box marginRight="10px">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenDialog(true)}
                    >
                      CANCEL
                    </Button>
                  </Box>
                  <Box marginRight="10px">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSubmitForm(handleSubmit, 1)}
                    >
                      {formSubmitting ? "SAVING..." : "SAVE AS DRAFT"}
                    </Button>
                  </Box>
                  <Box marginRight="10px">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitForm(handleSubmit, 2)}
                    >
                      {formSubmitting ? "PUBLISHING..." : "SAVE & PUBLISH"}
                    </Button>
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Box>

      <Dialog
        action={() => sendBack && sendBack()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to cancel?"
      />
    </div>
  )
}

export default PostFormCreate
