import React from "react"

import styles from "./styles"

interface StepperProps {
  items: Array<string>
  activeStep: number
  justifyStart?: boolean
  showNumber?: boolean
}

const Stepper: React.FC<StepperProps> = ({
  items,
  activeStep,
  justifyStart,
  showNumber = true
}) => {
  const { container, stepper, defaultContainer, defaultStepper } = styles()
  const root = justifyStart ? defaultContainer : container
  const tab = justifyStart ? defaultStepper : stepper
  return (
    <div className={root}>
      {items.map((item: string, index: number) => {
        const itemNum = index + 1
        const isActive = itemNum === activeStep
        const itemClass = `${tab} ${isActive ? "active" : ""}`
        return (
          <div className={itemClass}>
            {showNumber && <span>{itemNum}</span>} 
            {' '}
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default Stepper
