import * as React from "react"

const CountFeedback = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0V2H18V12H20V2C20 0.895 19.105 0 18 0H4ZM2 4C0.9 4 0.00976562 4.9 0.00976562 6L0 21L4 17H14C15.1 17 16 16.1 16 15V6C16 4.9 15.1 4 14 4H2ZM2 6H14V15H4H3.17188L2.58594 15.5859L2 16.1719V6Z"
      fill="#454B57"
    />
  </svg>
)

export default CountFeedback
