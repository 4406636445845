import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0 0 40px 0"
  },
  container: {
    width: "610px",
    height: "439px",
    border: "3px dashed #F2F2F2",
    margin: "0 auto 100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .uploadButton": {
      color: theme.palette.primary.main,
      border: "2px solid #09707B",
      width: "247px",
      height: "40px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 10px"
    },
    "& .info": {
      fontSize: "12px",
      letterSpacing: "0.004em",
      color: "#777E86"
    }
  },
  uploadBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    cursor: "pointer"
  },
  uploadContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}))

export default styles
