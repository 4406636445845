import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  personalDetails: {
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      borderBottom: "1px solid #F2F2F2",
      marginBottom: "40px",
      margin: "0 auto"
    }
  },
  spaceBottom: {
    paddingBottom: "20px"
  },
  sectionRow: {
    display: "flex",
    paddingBottom: "20px"
  },
  sectionRowLeft: {
    flex: "1",
    marginRight: "15px"
  },
  sectionRowRight: {
    flex: "1"
  }
}))

export default styles
