import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
/**
 * Styles
 */
import {
  IconReportDelivery,
  IconReportService,
  IconReportVisitor,
  IconReportVMS
} from "components/Icons/Dashboard"
import styles from "./styles"
/**
 * Icon
 */
/**
 * Hooks
 */
import {
  TotalVmsCreatedHook,
  TotalVmsDeliveryHook,
  TotalVmsServiceHook,
  TotalVmsVisitorsHook
} from "./hooks"

const VisitorManagement = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`
  const { totalVmsCreated, isValidating } = TotalVmsCreatedHook(selectedDate)
  const { totalDelivery } = TotalVmsDeliveryHook(selectedDate)
  const { totalService } = TotalVmsServiceHook(selectedDate)
  const { totalVisitors } = TotalVmsVisitorsHook(selectedDate)

  const options = [
    {
      name: "Visitors",
      total: totalVisitors,
      icon: IconReportVisitor
    },
    {
      name: "Package/ Delivery",
      total: totalDelivery,
      icon: IconReportDelivery
    },
    {
      name: "Service Providers",
      total: totalService,
      icon: IconReportService
    }
  ]

  function ViewContent(): JSX.Element {
    return (
      <Box>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade in timeout={1000}>
            <Box>
              <Box
                alignItems="center"
                justifyContent="space-between"
                padding="26px 50px"
              >
                <Box className={classes.title}>Total</Box>
                <Box className={classes.subtitleStyle}>{currentReport}</Box>
                <Box className={classes.totalStyle}>{totalVmsCreated}</Box>
              </Box>

              <Box>
                {options.map((item) => (
                  <div className={classes.feedbackList} key={item.name}>
                    <div className={classes.body}>
                      <div>{item.icon}</div>
                      <div className="feedbackListName">{item.name}</div>
                    </div>
                    <div className={classes.body}>{item.total}</div>
                  </div>
                ))}
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    )
  }

  return (
    <Card>
      <Box className={classes.header}>
        <CardTitle title="Visitor Management" icon={IconReportVMS} />
      </Box>

      <ViewContent />
    </Card>
  )
}

export default VisitorManagement
