import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  generalInfo: {
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      borderBottom: "1px solid #F2F2F2",
      width: "40%",
      marginBottom: "40px"
    }
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "48%"
    }
  },
  spaceBottom: {
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "#f44336;",
    fontSize: "0.75rem;"
  }
}))

export default styles
