import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toNumeral } from "utils/helpers"
import { toUnix } from "utils/date"

export function OutstandingPaymentHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchOutstandingPayment = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo
    })

  const { data, isValidating, error } = useSWR(
    `fetchOutstandingPayment-${unixFrom}-${unixTo}`,
    fetchOutstandingPayment,
    {
      revalidateOnFocus: false
    }
  )

  const fetchOutstandingPaymentFacilities = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "category": "Facilities"
    })

  const { data: facilities } = useSWR(
    `fetchOutstandingPaymentFacilities-${unixFrom}-${unixTo}`,
    fetchOutstandingPaymentFacilities,
    {
      revalidateOnFocus: false
    }
  )

  const fetchOutstandingPaymentApplications = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "category": "Applications"
    })

  const { data: applications } = useSWR(
    `fetchOutstandingPaymentApplications-${unixFrom}-${unixTo}`,
    fetchOutstandingPaymentApplications,
    {
      revalidateOnFocus: false
    }
  )

  const fetchOutstandingPaymentMaintenance = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "category": "Maintenance"
    })

  const { data: maintenance } = useSWR(
    `fetchOutstandingPaymentMaintenance-${unixFrom}-${unixTo}`,
    fetchOutstandingPaymentMaintenance,
    {
      revalidateOnFocus: false
    }
  )

  const fetchOutstandingPaymentScheduled = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "category": "Scheduled"
    })

  const { data: scheduled } = useSWR(
    `fetchOutstandingPaymentScheduled-${unixFrom}-${unixTo}`,
    fetchOutstandingPaymentScheduled,
    {
      revalidateOnFocus: false
    }
  )

  const fetchOutstandingPaymentOthers = async () =>
    service.getOutstandingPayments({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "category": "Others"
    })

  const { data: others } = useSWR(
    `fetchOutstandingPaymentOthers-${unixFrom}-${unixTo}`,
    fetchOutstandingPaymentOthers,
    {
      revalidateOnFocus: false
    }
  )

  const amount = data?.data?._data?.amt
  const amountFacilities = facilities?.data?._data?.amt
  const amountApplications = applications?.data?._data?.amt
  const amountMaintenance = maintenance?.data?._data?.amt
  const amountScheduled = scheduled?.data?._data?.amt
  const amountOthers = others?.data?._data?.amt

  return {
    amount: toNumeral(amount),
    facilities: toNumeral(amountFacilities),
    applications: toNumeral(amountApplications),
    maintenance: toNumeral(amountMaintenance),
    scheduled: toNumeral(amountScheduled),
    others: toNumeral(amountOthers),
    isValidating,
    error
  }
}
