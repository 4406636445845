import useSWR from "swr"
import service from "services/Dashboard/Dashboard"
import { toUnix, formatDate } from "utils/date"

export function ExpiringSoonContracts(date: any) {
  const todayStartDate = toUnix(`${formatDate(date?.startDate)} 23:59:59`)

  const fetchDashboardExpiringSoon = async () =>
    service.getExpiringSoon({
      "booking_date": "desc",
      "current_timestamp": todayStartDate,
      "expiration_days": 90,
      "type": 3,
      "sub_type": 1,
      "limit": 3
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardExpiringSoon-${todayStartDate}`,
    fetchDashboardExpiringSoon,
    {
      revalidateOnFocus: true
    }
  )
  const expiringSoonList = data?.data?._data

  return {
    expiringSoonList,
    isValidating,
    error
  }
}

export function ExpiringSoonLicensePermits(date: any) {
  const todayStartDate = toUnix(`${formatDate(date?.startDate)} 23:59:59`)

  const fetchDashboardExpiringSoonPermit = async () =>
    service.getExpiringSoon({
      "booking_date": "desc",
      "current_timestamp": todayStartDate,
      "expiration_days": 90,
      "type": 3,
      "sub_type": 2,
      "limit": 3
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardExpiringSoonPermits-${todayStartDate}`,
    fetchDashboardExpiringSoonPermit,
    {
      revalidateOnFocus: true
    }
  )
  const expiringSoonListPermits = data?.data?._data

  return {
    expiringSoonListPermits,
    isValidatingPermits: isValidating,
    error
  }
}

export function ExpiringSoonCyclicService(date: any) {
  const todayStartDate = toUnix(`${formatDate(date?.startDate)} 23:59:59`)

  const fetchDashboardExpiringSoonCylic = async () =>
    service.getExpiringSoon({
      "booking_date": "desc",
      "current_timestamp": todayStartDate,
      "expiration_days": 90,
      "type": 3,
      "sub_type": 4,
      "limit": 3
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardExpiringSoonCyclic-${todayStartDate}`,
    fetchDashboardExpiringSoonCylic,
    {
      revalidateOnFocus: true
    }
  )
  const expiringSoonListCyclic = data?.data?._data

  return {
    expiringSoonListCyclic,
    isValidatingCyclic: isValidating,
    error
  }
}

export function ExpiringSoonOthers(date: any) {
  const todayStartDate = toUnix(`${formatDate(date?.startDate)} 23:59:59`)

  const fetchDashboardExpiringSoonOthers = async () =>
    service.getExpiringSoon({
      "booking_date": "desc",
      "current_timestamp": todayStartDate,
      "expiration_days": 90,
      "type": 3,
      "sub_type": 5,
      "limit": 3
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardExpiringSoonOthers-${todayStartDate}`,
    fetchDashboardExpiringSoonOthers,
    {
      revalidateOnFocus: true
    }
  )
  const expiringSoonListOthers = data?.data?._data

  return {
    expiringSoonListOthers,
    isValidatingOthers: isValidating,
    error
  }
}
