import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

interface SwitchProps {
  checked: boolean
  action: Function
  label?: string | null
}

const SwitchToggle: React.FC<SwitchProps> = ({ checked, action, label }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={() => action()}
          value={checked}
          color="primary"
        />
      }
      label={label}
    />
  )
}
export default SwitchToggle
