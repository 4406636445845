import React from "react"

import AccessCardsProvider from "context/Dashboard/UnitManagement/categories/accessCards/Provider"
import AccessCardsView from "context/Dashboard/UnitManagement/categories/accessCards/View"

export default (): JSX.Element => (
  <AccessCardsProvider>
    <AccessCardsView />
  </AccessCardsProvider>
)
