import * as React from "react"

interface EventProps {
  color: string
}

const Event: React.FC<EventProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 0V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13V2H5V0H3ZM2 7H16V18H2V7ZM10 12V16H14V12H10Z"
        fill={color}
      />
    </svg>
  )
}

export default Event
