import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import { Button, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
/**
 * Components
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Icon
 */
import { IconScheduleServicing } from "components/Icons/Dashboard"
/**
 * Context
 */
import { Context } from "context/Dashboard/Dashboard/main/Context"
// import { AppContext } from "App"
/**
 * Hooks
 */
// import { paymentCategory } from "config/Dashboard/Bookkeeping"
import { ScheduleServicingHook } from "./hooks"
/**
 * Config
 */
/**
 * Styles
 */
import styles from "./styles"

const ScheduleServicing: React.FC = () => {
  const classes = styles()
  const { dateRange } = useContext(Context)
  const { servicingData, isValidating } = ScheduleServicingHook(dateRange)

  function ViewHeader(): JSX.Element {
    return (
      <Box className={classes.header}>
        <CardTitle title="Scheduled Servicing" icon={IconScheduleServicing} />
      </Box>
    )
  }

  function ViewBreakdown(): JSX.Element {
    return (
      <Box>
        {servicingData?.map((item: any) => {
          return (
            <Box className={classes.breakdown}>
              <Box className={classes.timelineTitle}>{item.title}</Box>
              <Box className={classes.timelineSubtitle}>{item.remarks}</Box>
              <Box className={classes.dot} />
            </Box>
          )
        })}
      </Box>
    )
  }

  function ViewContent(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    if (servicingData.length === 0) {
      return (
        <Fade in timeout={1000}>
          <Box>
            {/* <ViewTotal /> */}
            <Box className={classes.content}>
              <Typography>No service scheduled</Typography>
            </Box>
          </Box>
        </Fade>
      )
    }
    return (
      <Fade in timeout={1000}>
        <Box className={classes.overflow}>
          {/* <ViewTotal /> */}
          <Box className={classes.content}>
            <ViewBreakdown />
          </Box>
        </Box>
      </Fade>
    )
  }

  return (
    <Card>
      <Box>
        <ViewHeader />
        <ViewContent />
        <Box display="flex" justifyContent="flex-end" padding="10px 10px">
          <Button
            color="primary"
            size="large"
            component={Link}
            to="/property-management/estate-matters/management-documents"
          >
            <Box fontWeight="600">VIEW ALL</Box>
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default ScheduleServicing
