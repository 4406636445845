import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: "35px"
  },
  containerDefault: {
    marginBottom: "35px"
  },
  section: { padding: "0 30px 35px" },
  label: {
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.0015em",
    color: theme.palette.secondary.dark,
    paddingBottom: "15px"
  },
  labelGap: {
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.0015em",
    color: theme.palette.secondary.dark,
    paddingBottom: "25px"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    maxHeight: "100px",
    padding: "0 30px",
    borderTop: "1px solid #F2F2F2",
    "& > div": {
      flex: 1
    },
    "& > div:last-child": {
      flex: 2
    }
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  }
}))

export default styles
