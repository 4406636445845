import React from "react"
import Fade from "@material-ui/core/Fade"

import AuthProvider from "context/auth-context"
import AppInit from "config/init"
import Preloader from "components/Preloader"
import AppMain from "layout/AppMain"
import ConnectionError from "pages/Common/ConnectionError"
import { Detector } from "../offline"

export type CtxType = {
  permission: Record<string, any>
  condoInfo: Record<string, any>
  userData: Record<string, any>
  getUserPermission: Function | any
  hasCondoUID: boolean
}

export const AppContext = React.createContext<Partial<CtxType>>({})

const App = () => {
  const {
    permission,
    condoInfo,
    userData,
    fetchingPermission,
    fetchingCondo,
    getUserPermission
  } = AppInit()

  const condoData = condoInfo as any
  const hasCondoUID = condoData?._uid !== undefined

  if (fetchingPermission && fetchingCondo) {
    return <Preloader />
  }

  return (
    <Fade in timeout={2000}>
      <div>
        <AuthProvider>
          <AppContext.Provider
            value={{
              permission,
              condoInfo,
              hasCondoUID,
              userData,
              getUserPermission
            }}
          >
            <Detector
              render={({ online }: any) => (
                <>{online ? <AppMain /> : <ConnectionError />}</>
              )}
            />
          </AppContext.Provider>
        </AuthProvider>
      </div>
    </Fade>
  )
}

export default App
