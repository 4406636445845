import * as React from "react"

const Deposits: React.FC = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C7.239 0 5 2.239 5 5C5 7.761 7.239 10 10 10C12.761 10 15 7.761 15 5C15 2.239 12.761 0 10 0ZM10 2C11.657 2 13 3.343 13 5C13 6.657 11.657 8 10 8C8.343 8 7 6.657 7 5C7 3.343 8.343 2 10 2ZM10.4082 3L8.50977 3.77734V4.81445L9.30273 4.50781V7H10.6621V3H10.4082ZM0 6V17C0 18.103 0.897 19 2 19H18C19.103 19 20 18.103 20 17V6H18V17H2V6H0ZM6 12L10 16L14 12H6Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Deposits
