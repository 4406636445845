import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  facility: {
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "50px",
    marginBottom: "20px"
  },
  facilityInfo: {
    display: "flex",
    width: "100%",
    padding: "30px 20px 20px 60px",
    "&.breakdown": {
      padding: "30px 0 20px 0"
    }
  },
  facilityImage: {
    overflow: "hidden",
    borderRadius: "10px",
    width: "212px",
    height: "162px"
  },
  facilityDetails: {
    flex: 1,
    padding: "0 25px"
  },
  labelStyle: {
    fontSize: "10px",
    marginBottom: "4px",
    color: theme.palette.body.secondary,
    textTransform: "uppercase"
  },
  valueStyle: {
    fontSize: "16px",
    color: theme.palette.body.main
  },
  facilityDetailsSection: {
    paddingBottom: "15px"
  },
  twoCol: {
    display: "flex",
    alignItems: "center",
    "& > div:first-child": {
      paddingRight: "100px"
    }
  },
  /** Status */
  headers: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px 0 60px",
    height: "51px",
    "& > div": {
      flex: 1,
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
      color: theme.palette.body.lightDark
    }
  },
  details: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px 0 60px",
    height: "61px",
    backgroundColor: "#FAFAFA",
    marginBottom: "2px",
    cursor: "pointer",
    "& > div": {
      flex: 1,
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "18px",
      color: theme.palette.body.lightDark
    }
  },
  selectedDetails: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px 0 60px",
    height: "61px",
    backgroundColor: "#F2F2F2",
    marginBottom: "2px",
    cursor: "pointer",
    "& > div": {
      flex: 1,
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "18px",
      color: theme.palette.body.lightDark
    }
  }
}))

export default styles
