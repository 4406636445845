import React from "react"

import UMMainProvider, {
  UMMainProviderProps
} from "context/Dashboard/UnitManagement/categories/main/Provider"
import UMMainView from "context/Dashboard/UnitManagement/categories/main/View"

const UMMain: React.FC<UMMainProviderProps> = ({
  showUpdateUser,
  showCreateVehicle,
  showEditVehicle,
  showVehicleDetails,
  showCreateAccessCards,
  showEditAccessCards,
  showAccessCardsDetails,
  showCreateFromData,
  showCreateOwner,
  showEditOwner,
  showCreateResident,
  showEditResident,
  showAddPS,
  showEditPS,
  activeTable,
  showPSDetails,
  // archivePS,
  showDetails,
  showMain,
  activeView,
  activeData
}) => (
  <UMMainProvider
    showUpdateUser={showUpdateUser}
    showCreateFromData={showCreateFromData}
    showCreateVehicle={showCreateVehicle}
    showEditVehicle={showEditVehicle}
    showVehicleDetails={showVehicleDetails}
    showCreateAccessCards={showCreateAccessCards}
    showEditAccessCards={showEditAccessCards}
    showAccessCardsDetails={showAccessCardsDetails}
    showCreateOwner={showCreateOwner}
    showEditOwner={showEditOwner}
    showCreateResident={showCreateResident}
    showEditResident={showEditResident}
    showAddPS={showAddPS}
    showEditPS={showEditPS}
    showPSDetails={showPSDetails}
    showDetails={showDetails}
    showMain={showMain}
    activeTable={activeTable}
    activeView={activeView}
    activeData={activeData}
  >
    <UMMainView />
  </UMMainProvider>
)

export default UMMain
