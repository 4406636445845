import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** Component */
import MovingInOut from "pages/Dashboard/Applications/categories/movingInOut"
import Vehicles from "pages/Dashboard/Applications/categories/vehicles"
import Renovation from "pages/Dashboard/Applications/categories/renovation"
import AccessCards from "pages/Dashboard/Applications/categories/accessCards"
import ApplicationDetails from "pages/Dashboard/Applications/components/ApplicationDetails"

/** Context */
import withContext from "context/Dashboard/Applications/main/withContext"
import { Context } from "context/Dashboard/Applications/categories/main/Context"
import { CtxType } from "context/Dashboard/Applications/main/Context"

const MainCategories: React.FC<CtxType> = ({ activeTable }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { showDetails, setShowDetails, activeDetails } = useContext(Context)

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 1:
        return <MovingInOut />
      case 0:
        return <Vehicles />
      case 2:
        return <AccessCards />
      case 3:
        return <Renovation />
      default:
        break
    }
    return activeTableParam
  }

  function MainView(): JSX.Element {
    return <Box>{TableView(activeTable)}</Box>
  }

  function ViewDetails(): JSX.Element {
    return (
      <ApplicationDetails
        activeDetails={activeDetails}
        openDetails={showDetails || false}
        setopenDetails={(value: boolean) => setShowDetails && setShowDetails(value)}
      />
    )
  }

  return (
    <>
      <div>
        {MainView()}
        {ViewDetails()}
      </div>
    </>
  )
}

export default withContext(MainCategories)
