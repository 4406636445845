import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts"

import styles from "./styles"

const OPTIONS = [
  {
    "name": "Used",
    "color": "#09707B"
  },
  {
    "name": "Unbooked",
    "color": "#A56300"
  },
  {
    "name": "No Show",
    "color": "#D8B469"
  }
]

interface Props {
  facility: Record<string, any>
  date: Record<string, any>
}

const Facility: React.FC<Props> = ({ facility, date }) => {
  const classes = styles()

  const categoryName = facility?.category?.category_name
  const currentDate = `${date?.from} - ${date?.to}`
  const used = facility?.categoryData?.used
  const unbooked = facility?.categoryData?.unbooked
  const noShow = facility?.categoryData?.no_show
  const cancelled = facility?.categoryData?.cancelled

  const totalAvailable = facility?.categoryData?.total_available
  const totalApproved = facility?.categoryData?.total_approved

  const data = [
    {
      "name": "Used",
      "value": used
    },
    {
      "name": "Unbooked",
      "value": unbooked
    },
    {
      "name": "No Show",
      "value": noShow
    }
  ]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${
          Number((percent * 100).toFixed(0)) === 0
            ? ""
            : `${Number((percent * 100).toFixed(0))}%`
        }`}
      </text>
    )
  }

  function ViewOptions(): JSX.Element {
    return (
      <div className={classes.options}>
        {OPTIONS.map((option: Record<string, any>) => (
          <div className={classes.optionContainer} key={`${option.name}`}>
            <div
              className={classes.optionColor}
              style={{ backgroundColor: `${option.color}` }}
            />
            <div className={classes.optionName}>{option?.name}</div>
          </div>
        ))}
      </div>
    )
  }

  function ViewChart(): JSX.Element {
    return (
      <Box padding="20px 0" display="flex" justifyContent="center">
        <ResponsiveContainer width={450} height={250}>
          <PieChart width={400} height={250}>
            <Pie
              outerRadius={120}
              data={data}
              cx="50%"
              cy="50%"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell fill={OPTIONS[index]?.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    )
  }

  return (
    <Card>
      <Box display="flex">
        <Box borderRight="1px solid #F2F2F2">
          <Box padding="15px 30px">
            <Box className={classes.title}>{categoryName}</Box>
            <Box className={classes.subtitleStyle}>{currentDate}</Box>
            <div className={classes.totalStyle}>
              {`${totalApproved}`} 
              {' '}
              <small>{`/ ${totalAvailable}`}</small>
            </div>
          </Box>

          <Box>
            <div className={classes.feedbackList}>
              <div className={classes.body}>
                <div className="feedbackListName">Used</div>
              </div>
              <div className={classes.body}>{used}</div>
            </div>

            <div className={classes.feedbackList}>
              <div className={classes.body}>
                <div className="feedbackListName">Unbooked</div>
              </div>
              <div className={classes.body}>{unbooked}</div>
            </div>

            <div className={classes.feedbackList}>
              <div className={classes.body}>
                <div className="feedbackListName">No Show</div>
              </div>
              <div className={classes.body}>{noShow}</div>
            </div>
          </Box>
        </Box>
        <Box>
          <div>{ViewOptions()}</div>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {ViewChart()}
          </div>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        borderTop="1px solid #F2F2F2"
        padding="15px 30px"
      >
        <div className={classes.body}>
          Number of Cancelled Bookings:
          {cancelled}
        </div>
      </Box>
    </Card>
  )
}
export default Facility
