import * as React from "react"

interface EstateMattersProps {
  color: string
}

const EstateMatters: React.FC<EstateMattersProps> = ({ color }) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.0996094L0 10H3V10.0039L11 2.79102L17 8.18945V17H13V14H15.6309L8.5 7.66211L1.36914 14H4V17C4 18.105 4.895 19 6 19H12H13H17C18.105 19 19 18.105 19 17V10H22L11 0.0996094ZM8.5 10.3379L11 12.5605V17H6V12.5605L8.5 10.3379Z"
        fill={color}
      />
    </svg>
  )
}

export default EstateMatters
