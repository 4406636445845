import React, { useContext } from "react"
/**
 * Context
 */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
import { AppContext } from "App"
/**
 * Config
 */
import { paymentStatus as status } from "config/Dashboard/Facilities"
/**
 * Utils
 */
import { toNumeral } from "utils/helpers"
/**
 * Styles
 */
import styles from "./styles"

interface Props extends CtxType {
  facilityBooking: Record<string, any>
}

const FacilitiesBreakdown: React.FC<Props> = ({
  facilityBooking,
  setSelectedForm,
  selectedForm,
  hasData
}): JSX.Element => {
  const classes = styles()
  const { condoInfo } = useContext(AppContext)

  const isDeposit = selectedForm?.isDeposit
  const selectedTimeslot = selectedForm?.timeslotUID
  const fFacility = facilityBooking?.facility?._uid
  const fTimeslotUID = facilityBooking?.facility_time_slot_uid
  const activeTimeslot = selectedTimeslot === facilityBooking?.facility_time_slot_uid
  const bookingDeposit = facilityBooking?.deposit_for_booking
  const bookingUsage = facilityBooking?.usage_fee_for_booking
  const currency = condoInfo?.currency

  function View() {
    return (
      <div>
        <div className={classes.headers}>
          {hasData && <div>Status</div>}
          <div>Type</div>
          <div>Amount</div>
          <div>Tax</div>
          <div>Total</div>
        </div>

        <div
          tabIndex={-1}
          role="button"
          className={
            activeTimeslot && isDeposit ? classes.selectedDetails : classes.details
          }
          onClick={() =>
            setSelectedForm({
              facilityUID: fFacility,
              timeslotUID: fTimeslotUID,
              isDeposit: true
            })}
        >
          {hasData && <div>{status[bookingDeposit?.payment_status]}</div>}
          <div>Deposit</div>
          <div>{toNumeral(bookingDeposit?.amt, currency)}</div>
          <div>-</div>
          <div>{toNumeral(bookingDeposit?.amt, currency)}</div>
        </div>

        <div
          tabIndex={-1}
          role="button"
          className={
            activeTimeslot && !isDeposit ? classes.selectedDetails : classes.details
          }
          onClick={() =>
            setSelectedForm({
              facilityUID: fFacility,
              timeslotUID: fTimeslotUID,
              isDeposit: false
            })}
        >
          {hasData && <div>{status[bookingUsage?.payment_status]}</div>}
          <div>Usage Fee</div>
          <div>{toNumeral(bookingUsage?.amt, currency)}</div>
          <div>{toNumeral(bookingUsage?.tax, currency)}</div>
          <div>
            {toNumeral(
              Number(bookingUsage?.amt) + Number(bookingUsage?.tax),
              currency
            )}
          </div>
        </div>
      </div>
    )
  }

  return <div className={classes.container}>{View()}</div>
}

export default withContext(FacilitiesBreakdown)
