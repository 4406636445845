import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Box from "@material-ui/core/Box"

/** Service */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Components */
import Table from "components/Table"
import Loader from "components/Loader"

/** Context */
import { compare } from "utils/helpers"
import { Context } from "context/Dashboard/Bookkeeping/categories/main/Context"
import tableHeaders from "./table/tableHeaders"

const TableDepositPayments: React.FC = () => {
  const { setDownloadData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchDepositPayments = async () =>
    BookkeepingAPI.getPayments({
      "payment_status": "3",
      "payment_type": "2"
    })

  const { data, isValidating } = useSWR(
    "fetchDepositPayments",
    fetchDepositPayments,
    {
      revalidateOnFocus: true
    }
  )

  const itemsList = data?.data?._data
  const tableItems = itemsList && itemsList.sort(compare)

  return (
    <Box style={{ height: isValidating ? "1000px" : "auto" }}>
      {isValidating ? (
        <Loader dark />
      ) : (
        <Fade in={!isValidating} timeout={2000}>
          <Box>
            <Table
              data={tableItems || []}
              columns={tableHeaders}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isFilterable
              action={(values: any) => {
                return (
                  setDownloadData &&
                  setDownloadData(
                    values?.map((i: any) => {
                      return {
                        "Deposit Status": i?.isLock,
                        "Status": i?.status,
                        "Receipt No.": i?.txnId,
                        "Block/Unit No.": i?.blockUnitNo,
                        "Name": i?.payeeName,
                        "Type": i?.type,
                        "Category": i?.category,
                        "Payment Description": i?.paymentDescription,
                        "Payment Date": i?.paymentDate,
                        "Payment Method": i?.paymentMethod,
                        "Payment Agent": i?.paymentAgent,
                        "Payment Reference": i?.paymentReference,
                        "Amount": i?.amount,
                        "Tax": i?.tax,
                        "Closed by": i?.closedBy,
                        "Closed Date": i?.closeDate
                      }
                    })
                  )
                )
              }}
            />
          </Box>
        </Fade>
      )}
    </Box>
  )
}

export default TableDepositPayments
