import React from "react"

export type CtxType = {
  handleNext: Function
  handleBack: Function
  setActiveStep: Function
  setOpenDialog: Function
  handleCreatePS: Function
  setAddUnitActive: Function
  setPsActive: Function
  steps: Function
  setPsDetails: Function
  activeStep: number
  openDialog: boolean
  psDetails: Record<string, any>
}

export const Context = React.createContext<Partial<CtxType>>({})
