import React from "react"
import Box from "@material-ui/core/Box"
import useSWR from "swr"
import Cookie from "js-cookie"

/** Component */

/** API */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Context */
import dateHelper from "utils/date"
import { toNumeral } from "utils/helpers"

/** Styles */
import styles from "../styles"

interface TotalPaymentsProps {
  dates: any
  currency: string
}

const TotalPayments: React.FC<TotalPaymentsProps> = ({ dates, currency }) => {
  const { totalStyle } = styles()

  const fetchTotalPaymentsStats = async () =>
    BookkeepingAPI.getTotalCollections({
      "condo_uid": Cookie.get("condoUID"),
      "start_date": `${dateHelper.toUnix(dates.first)}`,
      "end_date": `${dateHelper.toUnix(dates.last)}`,
      "payment_type": "1,2"
    })

  const { data } = useSWR("fetchTotalPaymentsStats", fetchTotalPaymentsStats, {
    revalidateOnFocus: true
  })

  const oPayments = data?.data?._data !== undefined && data?.data?._data

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="16px 23px 16px 50px"
      borderRight="1px solid #F2F2F2"
    >
      <Box className={totalStyle}>
        <small>{currency}</small>
        {toNumeral(oPayments?.amt)}
      </Box>
    </Box>
  )
}
export default TotalPayments
