import React, { FunctionComponent } from "react"
import Button from "@material-ui/core/Button"
import Dropzone from "react-dropzone"

interface Props {
  onDrop: Function
  disabled: boolean
  label: string
}

const UploadButton: FunctionComponent<Props> = ({ onDrop, disabled, label }) => {
  return (
    <Dropzone
      accept="image/*"
      onDrop={(files) => onDrop && onDrop(files)}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button variant="outlined" color="primary">
            {label}
          </Button>
        </div>
      )}
    </Dropzone>
  )
}

export default UploadButton
