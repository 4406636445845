import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    height: "271px",
    padding: "0 40px",
    backgroundColor: "#F2F2F2"
  },
  facilityInfo: {
    padding: "30px 20px 0 60px",
    marginBottom: "-15px"
  },
  labelStyle: {
    fontSize: "10px",
    marginBottom: "8px",
    color: theme.palette.body.secondary,
    textTransform: "uppercase"
  }
}))

export default styles
