import React, { useContext, useMemo, useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
/** Components */
import FormLabel from "components/Forms/Label"
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/category/Context"
/** Config */
import { addCategoryEntitlement } from "config/Settings/PropertyManagement/Facilities/validation"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/category/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormDetails: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const { category, setCategory } = useContext(Context)

  const [isInitialValid, setIsInitialValid] = useState(false)

  const defaultValues = {
    entitlementPeriod: category?.entitlementPeriod,
    slotsPerUnit: category?.slotsPerUnit || "",
    amountDeposit: category?.amountDeposit,
    amountUsageFee: category?.amountUsageFee,
    isDeposit: category?.isDeposit,
    isUsageFee: category?.isUsageFee
  }

  useEffect(() => {
    addCategoryEntitlement
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false))
  }, [defaultValues])

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setCategory &&
        setCategory({
          ...category,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setCategory &&
        setCategory({
          ...category,
          [name]: e?.target?.value
        })
    }
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return defaultValues
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addCategoryEntitlement}
        isInitialValid={isInitialValid}
      >
        {({
          touched,
          errors,
          isValid,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper title="Entitlement Period & Payment">
                  <Box className={classes.section}>
                    <Box width="50%">
                      <Box marginBottom="25px">
                        <FormControl>
                          <FormLabel label="Entitlement Period" />
                          <RadioGroup
                            aria-label="entry"
                            name="entry"
                            value={category?.entitlementPeriod}
                            row
                            onChange={(e: any): void =>
                              handleFormChange("entitlementPeriod", e, setFieldValue)}
                          >
                            <FormControlLabel
                              value="3"
                              control={<Radio color="primary" />}
                              label="Monthly"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio color="primary" />}
                              label="Weekly"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label="Daily"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Box marginBottom="25px">
                        <FormInput
                          label="Slots per unit"
                          type="number"
                          name="slotsPerUnit"
                          value={category?.slotsPerUnit}
                          idValue="slotsPerUnit"
                          placeholder="Slots per unit"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange("slotsPerUnit", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={
                            touched.slotsPerUnit && Boolean(errors.slotsPerUnit)
                          }
                          helperText={
                            errors.slotsPerUnit &&
                            touched.slotsPerUnit &&
                            errors.slotsPerUnit
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classes.sectionLast}>
                    <FormLabel label="Payment" />
                    {/* Deposit */}
                    <Box marginBottom="25px">
                      <Box className={classes.sectionWrapper}>
                        <Box className={classes.sectionContent}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={category?.isDeposit}
                                  onChange={(): void =>
                                    handleFormChange(
                                      "isDeposit",
                                      !category?.isDeposit,
                                      setFieldValue
                                    )}
                                  value={category?.isDeposit}
                                  color="primary"
                                />
                              }
                              label="Deposit"
                            />
                          </FormGroup>
                        </Box>
                        <Box flex="1">
                          <FormInput
                            disabled={!category?.isDeposit}
                            type="number"
                            name="amountDeposit"
                            value={category?.amountDeposit}
                            idValue="amountDeposit"
                            placeholder="Amount"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("amountDeposit", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.amountDeposit && Boolean(errors.amountDeposit)
                            }
                            helperText={
                              errors.amountDeposit &&
                              touched.amountDeposit &&
                              errors.amountDeposit
                            }
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* One-time Fee */}
                    <Box marginBottom="25px">
                      <Box className={classes.sectionWrapper}>
                        <Box className={classes.sectionContent}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={category?.isUsageFee}
                                  onChange={(): void =>
                                    handleFormChange(
                                      "isUsageFee",
                                      !category?.isUsageFee,
                                      setFieldValue
                                    )}
                                  value={category?.isUsageFee}
                                  color="primary"
                                />
                              }
                              label="Usage Fee"
                            />
                          </FormGroup>
                        </Box>
                        <Box flex="1">
                          <FormInput
                            disabled={!category?.isUsageFee}
                            type="number"
                            name="amountUsageFee"
                            value={category?.amountUsageFee}
                            idValue="amountUsageFee"
                            placeholder="Amount"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("amountUsageFee", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.amountUsageFee &&
                              Boolean(errors.amountUsageFee)
                            }
                            helperText={
                              errors.amountUsageFee &&
                              touched.amountUsageFee &&
                              errors.amountUsageFee
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormDetails
