/**
 * Utils
 */
import { fromUnixTime, fromUnix } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "Block/ Unit No.",
        accessor: "unit.short_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Name",
        accessor: "booking_by_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Facility",
        id: "facilityType",
        accessor: (c: any) => checkValue(c?.facility?.name),
        Cell: ({ original }: any) => checkValue(original?.facility?.name),
        filterable: false,
        sortable: false
      },
      {
        Header: "Date",
        id: "date",
        // accessor: (c: any) => checkDate(c._created, fromUnix(c._created)),
        // Cell: ({ original }: any) =>
        //   checkDate(original._created, fromUnix(original._created)),
        accessor: (c: any) => checkDate(c?.booking_date, fromUnix(c?.booking_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.booking_date, fromUnix(original?.booking_date)),
        filterable: false,
        sortable: false
      },
      {
        Header: "Time",
        id: "time",
        accessor: (c: any) =>
          `${checkDate(
            c?.facility_time_slot?.start_time,
            fromUnixTime(c?.facility_time_slot?.start_time, false, "HH:mm")
          )} - ${checkDate(
            c?.facility_time_slot?.end_time,
            fromUnixTime(c?.facility_time_slot?.end_time, false, "HH:mm")
          )}`,
        Cell: ({ original }: any) =>
          `${checkDate(
            original?.facility_time_slot?.start_time,
            fromUnixTime(original?.facility_time_slot?.start_time, false, "HH:mm")
          )} - ${checkDate(
            original?.facility_time_slot?.end_time,
            fromUnixTime(original?.facility_time_slot?.end_time, false, "HH:mm")
          )}`,
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
