import * as React from "react"

const Back: React.FC = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.929688L0.929688 8L8 15.0703L9.5 13.5703L4.92969 9H19V7H4.92969L9.5 2.42969L8 0.929688Z"
        fill="white"
      />
    </svg>
  )
}

export default Back
