import React from "react"
import Box from "@material-ui/core/Box"
import RadioGroup from "@material-ui/core/RadioGroup"

/** Components */
import ItemOption from "./ItemOption"

/** Styles */
import styles from "./styles"

const sampleData = [
  {
    name: "Block & Unit Numbers & Share Value",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Strata Roll",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Resident List",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Access Cards List",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Vehicles List",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Booked/Pending Facilities",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Vehicles List",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Deposit List",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Payment Schedule",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Application Settings",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Facilites Settings",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Feedback Settings",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "VMS Settings",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Bookkeeping Settings",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Admin User Roles",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Admin User Names",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Staff User Roles",
    file: "Sample_Document_Format.CSV"
  },
  {
    name: "Staff User Names",
    file: "Sample_Document_Format.CSV"
  }
]

const UploadMain: React.FC = () => {
  const { title, content } = styles()

  const ViewItem = (): JSX.Element => {
    return (
      <>
        {sampleData.map((item: any) => (
          <ItemOption title={item?.name} file={item?.file} />
        ))}
      </>
    )
  }

  return (
    <Box padding="40px">
      <Box paddingBottom="30px" className={title}>
        Select file type
      </Box>
      <Box>
        <RadioGroup
          aria-label="file-type"
          name="file-type"
          value=""
          onChange={() => null}
          style={{ flexDirection: "row" }}
          className={content}
        >
          <ViewItem />
        </RadioGroup>
      </Box>
    </Box>
  )
}

export default UploadMain
