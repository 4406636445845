import React from "react"

import FacilitiesFormProvider, {
  Props
} from "context/Settings/PropertyManagement/Facilities/forms/facility/Provider"
import FacilitiesFormView from "context/Settings/PropertyManagement/Facilities/forms/facility/View"

const FacilitiesForm: React.FC<Props> = ({ showFacilities, data }) => (
  <FacilitiesFormProvider showFacilities={showFacilities} data={data}>
    <FacilitiesFormView />
  </FacilitiesFormProvider>
)

export default FacilitiesForm
