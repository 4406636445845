import Cookies from "js-cookie"

import request from "services/request"
import API from "./endpoints"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function getCountAttendance(params?: Record<string, any>) {
  return request({
    url: API.COUNT_ATTENDANCE,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalEmployee(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_EMPLOYEE,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getOutstandingPayments(params?: Record<string, any>) {
  return request({
    url: API.OUTSTANDING_PAYMENT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalCollections(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_COLLECTIONS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getRegisteredUnits(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_REGISTERED_UNITS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalUnits(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_UNITS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTenantedUnits(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_TENANTED_UNITS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getRegisteredUsers(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_USERS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getResidents(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_RESIDENTS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalVehicles(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_VEHICLES,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getVmsCreated(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_VMS_CREATED,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getApplicationsCount(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_APPLICATIONS_COUNT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFeedbackClosedCount(params?: Record<string, any>) {
  return request({
    url: API.FEEDBACK_CLOSED_COUNT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFeedbackCount(params?: Record<string, any>) {
  return request({
    url: API.FEEDBACK_COUNT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFeedbackUnresolvedCount(params?: Record<string, any>) {
  return request({
    url: API.FEEDBACK_COUNT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFeedbackResolvedTimeCount(params?: Record<string, any>) {
  return request({
    url: API.FEEDBACK_RESOLVED_TIME_COUNT,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFacilitiesBookings(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_BOOKING_STATS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

const DashboardApi = {
  getCountAttendance,
  getTotalEmployee,
  getOutstandingPayments,
  getTotalCollections,
  getRegisteredUnits,
  getTotalUnits,
  getTenantedUnits,
  getRegisteredUsers,
  getResidents,
  getTotalVehicles,
  getVmsCreated,
  getApplicationsCount,
  getFeedbackCount,
  getFeedbackClosedCount,
  getFeedbackResolvedTimeCount,
  getFeedbackUnresolvedCount,
  getFacilitiesBookings
}

export default DashboardApi
