import React from "react"

export type CtxType = {
  feedbackDetails: Record<string, any>
  photos: any
  localPhotos: any
  previewImage: any
  attaching: boolean
  openDialog: boolean
  activeFeedback: Record<any, any>
  showForm: Function
  setFeedbackDetails: Function
  handleAddFeedback: Function
  setOpenDialog: Function
  handleImageUpload: Function
  removePhoto: Function
  handleFormChange: Function
}

export const AddFeedbackContext = React.createContext<Partial<CtxType>>({})
