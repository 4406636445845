import useSWR from "swr"
import service from "services/Dashboard/Dashboard/"

export function CountAccountRequestHook() {
  const fetchCountAccountRequestHook = async () => service.getCountAccountRequests()

  const { data, isValidating, error } = useSWR(
    "fetchCountAccountRequestHook",
    fetchCountAccountRequestHook,
    {
      revalidateOnFocus: true
    }
  )
  const totalAccountRequest = data?.data?._data?.total

  return {
    totalAccountRequest,
    isValidatingAccountRequest: isValidating,
    error
  }
}

export function CountChangeAddress() {
  const fetchCountChangeAddressRequestHook = async () =>
    service.getCountChangeAddress()

  const { data, isValidating, error } = useSWR(
    "fetchCountChangeAddressRequestHook",
    fetchCountChangeAddressRequestHook,
    {
      revalidateOnFocus: true
    }
  )
  const totalChangeAddress = data?.data?._data?.total

  return {
    totalChangeAddress,
    isValidatingChangeAddress: isValidating,
    error
  }
}
