import * as React from "react"

const Maintenance: React.FC = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 0L0 2.66667L1.875 5.47917C2.36967 6.22183 3.20308 6.66667 4.09375 6.66667H4.78125L9.4974 11.3828L1.98438 18.8932C1.11504 19.7626 1.11504 21.1723 1.98438 22.0417L3.28906 23.3464C3.72506 23.7824 4.29392 24 4.86458 24C5.43392 24 6.00544 23.7836 6.4401 23.349L12.6667 17.1198L18.8932 23.349C19.3279 23.7836 19.8994 24 20.4688 24C21.0381 24 21.607 23.7836 22.0417 23.349L23.3464 22.0443C24.2157 21.1749 24.2157 19.7652 23.3464 18.8958L17.1198 12.6667L19.2005 10.5859C19.4632 10.6246 19.7267 10.6667 20 10.6667C22.9453 10.6667 25.3333 8.27867 25.3333 5.33333C25.3333 4.552 25.1568 3.81383 24.8542 3.14583L22.9688 5.03125L20.8958 4.4375L20.3021 2.36458L22.1875 0.479167C21.5195 0.1765 20.7813 0 20 0C17.0547 0 14.6667 2.388 14.6667 5.33333C14.6667 5.60667 14.7087 5.87015 14.7474 6.13281L11.3828 9.4974L6.66667 4.78125V4.09375C6.66667 3.20308 6.22183 2.36967 5.47917 1.875L2.66667 0ZM18.2578 2.49219L18.3776 2.91667L18.9714 4.98958L19.276 6.05729L20.3438 6.36198L22.4167 6.95573L22.8411 7.07812C22.2531 8.03013 21.2 8.66667 20 8.66667C19.884 8.66667 19.7067 8.64004 19.5 8.60938L18.5026 8.45833L17.7865 9.17188L15.7057 11.2526L15.6927 11.2396L11.2396 15.6927L11.2526 15.7057L5.02604 21.9323C4.98204 21.9763 4.92592 22 4.86458 22C4.80325 22 4.74973 21.9763 4.70573 21.9323L3.40104 20.6302C3.31304 20.5422 3.31304 20.3966 3.40104 20.3073L16.1615 7.54688L16.8724 6.8125L16.7214 5.8125C16.6934 5.62583 16.6667 5.44933 16.6667 5.33333C16.6667 4.13333 17.3045 3.07885 18.2578 2.49219ZM15.7057 14.0807L21.9349 20.3073C22.0229 20.3953 22.0229 20.5383 21.9349 20.6276L20.6302 21.9323C20.5862 21.9763 20.5327 21.9974 20.4714 21.9974C20.4087 21.9974 20.3539 21.9763 20.3099 21.9323L14.0833 15.7057L15.7057 14.0807Z"
        fill="white"
      />
    </svg>
  )
}

export default Maintenance
