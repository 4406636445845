import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toUnix } from "utils/date"

export function TotalVmsCreatedHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalVmsCreated = async () =>
    service.getVmsCreated({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalVmsCreated-${unixFrom}-${unixTo}`,
    fetchTotalVmsCreated,
    {
      revalidateOnFocus: false
    }
  )
  const totalVmsCreated = data?.data?._data?.total

  return {
    totalVmsCreated,
    isValidating,
    error
  }
}

export function TotalVmsVisitorsHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalVmsVisitors = async () =>
    service.getVmsCreated({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "vms_type": 1
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalVmsVisitors-${unixFrom}-${unixTo}`,
    fetchTotalVmsVisitors,
    {
      revalidateOnFocus: false
    }
  )
  const totalVisitors = data?.data?._data?.total

  return {
    totalVisitors,
    isValidating,
    error
  }
}

export function TotalVmsDeliveryHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalVmsDelivery = async () =>
    service.getVmsCreated({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "vms_type": 2
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalVmsDelivery-${unixFrom}-${unixTo}`,
    fetchTotalVmsDelivery,
    {
      revalidateOnFocus: false
    }
  )
  const totalDelivery = data?.data?._data?.total

  return {
    totalDelivery,
    isValidating,
    error
  }
}

export function TotalVmsServiceHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalVmsService = async () =>
    service.getVmsCreated({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "vms_type": 3
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalVmsService-${unixFrom}-${unixTo}`,
    fetchTotalVmsService,
    {
      revalidateOnFocus: false
    }
  )
  const totalService = data?.data?._data?.total

  return {
    totalService,
    isValidating,
    error
  }
}
