import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

/** Service */
import vmsAPI from "services/Dashboard/VMS"

/** Config */
import {
  fromUnix,
  toUnix,
  toUnixTimeRange,
  displayDate,
  dayName,
  fromUnixDateTime
} from "utils/date"

/** Context */

import { CondoUID } from "config/Common/CondoInfo"
import { checkBooking, canBookDateRange } from "utils/functions"
import { AddUpdateContext } from "./AddUpdateContext"

export interface AddVisitorProps {
  showform: Function
  data?: Record<string, any>
  settings: Record<string, any>
}

const AddUpdate: React.FC<AddVisitorProps> = ({
  children,
  data,
  showform,
  settings
}) => {
  const hasData = data !== undefined

  const defaultVmsType = data?.vms_type !== undefined ? String(data?.vms_type) : "1"

  const defaultMultiple = data?.vms?.multiple_persons ? "2" : "1"

  const [bookingAllowed, setBookingAllowed] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(1)
  const [openDialog, setOpenDialog] = useState(false)
  const [visitorDetails, setVisitorDetails] = useState({
    allowCondo: data?.vms?.allow_condo_to_receive || false,
    vmsType: defaultVmsType,
    name: data?.vms?.name || "",
    email: data?.vms?.email || "",
    mobile: data?.vms?.mobile || "",
    remarks: data?.vms?.remarks || "",
    purpose: data?.vms?.purpose || "",
    unitUID: data?.unit?._uid || "",
    startDate: data !== undefined ? fromUnix(data?.vms?.start_date) : "",
    endDate: data !== undefined ? fromUnix(data?.vms?.end_date) : "",
    eta: data !== undefined ? fromUnixDateTime(data?.vms?.eta_ms) : "",
    multiplePersons: defaultMultiple,
    vehicleNumber: data?.vms?.vehicle_no,
    numberOfPersons: data?.vms?.no_of_persons || 2,
    contactPerson: data?.vms?.contact_person || "",
    contactPersonUID: data?.vms?.account_info_uid || "",
    byAdmin: data?.vms?.for_admin || false
  })

  const vd = visitorDetails

  /** Methods */
  const notifyError = () =>
    toast("Error creating VMS, please check all the fields.", {
      type: toast.TYPE.ERROR
    })

  const notifySuccess = () =>
    toast("Successfully created VMS.", {
      type: toast.TYPE.SUCCESS
    })

  const handleAddVMS = async () => {
    const {
      allowCondo,
      name,
      mobile,
      email,
      eta,
      remarks,
      unitUID,
      contactPerson,
      multiplePersons,
      numberOfPersons,
      startDate,
      endDate,
      purpose,
      vmsType,
      byAdmin,
      vehicleNumber
    } = visitorDetails

    const dataVisitor = {
      "name": name,
      "multiple_persons": multiplePersons !== "1",
      "eta_ms": eta !== null && toUnix(eta),
      "mobile": mobile,
      "email": email,
      "no_of_persons": Number(numberOfPersons),
      "remarks": remarks,
      "unit_uid": visitorDetails.byAdmin ? "" : unitUID,
      "contact_person": contactPerson,
      "for_admin": byAdmin,
      "condo_uid": CondoUID,
      "vehicle_no": vehicleNumber
    }

    const dataDelivery = {
      "name": name,
      "multiple_persons": multiplePersons !== "1",
      "mobile": mobile,
      "email": email,
      "remarks": remarks,
      "unit_uid": visitorDetails.byAdmin ? "" : unitUID,
      "contact_person": contactPerson,
      "allow_condo_to_receive": allowCondo,
      "start_date_ms": toUnixTimeRange(startDate, `00:00:00.000`),
      "end_date_ms": toUnixTimeRange(endDate, `23:59:59.999`),
      "condo_uid": CondoUID,
      "vehicle_no": vehicleNumber
    }

    const dataServiceProvider = {
      "name": name,
      "multiple_persons": multiplePersons !== "1",
      "eta_ms": eta !== null && toUnix(eta),
      "mobile": mobile,
      "email": email,
      "no_of_persons": Number(numberOfPersons),
      "remarks": remarks,
      "unit_uid": visitorDetails.byAdmin ? "" : unitUID,
      "contact_person": contactPerson,
      "purpose": purpose,
      "condo_uid": CondoUID,
      "vehicle_no": vehicleNumber
    }

    if (!visitorDetails.byAdmin) {
      delete dataVisitor.condo_uid
      delete dataDelivery.condo_uid
      delete dataServiceProvider.condo_uid
    }

    setSubmitting(true)

    const endpoint = (type: string) => {
      switch (type) {
        case "1":
          return hasData
            ? vmsAPI.updateVisitor(dataVisitor, data?.vms?._uid)
            : vmsAPI.createVisitor(dataVisitor)
        case "2":
          return hasData
            ? vmsAPI.updateDelivery(dataDelivery, data?.vms?._uid)
            : vmsAPI.createDelivery(dataDelivery)
        case "3":
          return hasData
            ? vmsAPI.updateServiceProvider(dataServiceProvider, data?.vms?._uid)
            : vmsAPI.createServiceProvider(dataServiceProvider)
        default:
          return null
      }
    }

    try {
      const response = await endpoint(vmsType)
      if (response._statusCode === -128) {
        return notifyError()
      }
      notifySuccess()
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return notifyError()
    }
  }

  const getSteps = () => {
    return ["select vms type", "visitor's information", "requester's information"]
  }

  const dayCheck = (datetime: string, settingsItem: Record<string, any>) => {
    switch (dayName(datetime || new Date())) {
      case "Sunday":
        return settingsItem?.holiday_time_slots
      case "Saturday":
        return settingsItem?.saturday_time_slots
      default:
        return settingsItem?.weekday_time_slots
    }
  }

  const allowedTime = (sTime: string) => {
    switch (sTime) {
      case "":
        return "Booking for the selected date is restricted."
      case "00:00:00":
        return "Booking for the selected date has no restriction"
      default:
        return `Allowed time for the selected date is between ${
          dayCheck(vd?.eta, settings[vd?.vmsType])[0]?.start_time
        } - ${dayCheck(vd?.eta, settings[vd?.vmsType])[0]?.end_time}`
    }
  }

  useEffect(() => {
    setBookingAllowed(true)

    if (vd?.vmsType === "2") {
      if (settings[vd?.vmsType].allowedDate) {
        canBookDateRange(
          settings[vd?.vmsType]?.allowedDate,
          vd?.startDate,
          setBookingAllowed
        )
      }
    } else if (vd?.eta !== "") {
      checkBooking(vd?.eta, settings[vd?.vmsType], setBookingAllowed)
    }
  }, [vd?.eta, settings[vd?.vmsType]?.allowedDate, vd?.startDate])

  return (
    <AddUpdateContext.Provider
      value={{
        activeStep,
        openDialog,
        visitorDetails,
        showform,
        submitting,
        bookingAllowed,
        setBookingAllowed,
        setActiveStep,
        setOpenDialog,
        setVisitorDetails,
        steps: getSteps,
        handleAddVMS,
        allowedDateMessage:
          vd?.vmsType !== "2"
            ? `Allowed date is ${displayDate(
                settings[vd?.vmsType]?.allowedDate,
                "MMM DD, YYYY"
              )} onwards`
            : "",
        allowedTimeMessage: allowedTime(
          dayCheck(vd?.eta, settings[vd?.vmsType])[0]?.start_time
        )
      }}
    >
      {children}
    </AddUpdateContext.Provider>
  )
}

export default AddUpdate
