import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"

import { AppContext } from "App"

// interface
interface WithTableProps {
  children: any
}

const WithTable: React.FC<WithTableProps> = ({ children }) => {
  const { hasCondoUID } = useContext(AppContext)

  if (!hasCondoUID) {
    return null
  }

  return (
    <Card>
      <Box>{children}</Box>
    </Card>
  )
}

export default WithTable
