import React from "react"
import FeedbackOthersProvider from "context/Dashboard/Feedback/categories/others/Provider"
import FeedbackOthersView from "context/Dashboard/Feedback/categories/others/View"

const FeedbackOthersProviderMain: React.FC = (): JSX.Element => (
  <FeedbackOthersProvider>
    <FeedbackOthersView />
  </FeedbackOthersProvider>
)

export default FeedbackOthersProviderMain
