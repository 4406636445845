import * as React from "react"

const Renovation: React.FC = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66853 0L0.892578 2.89062L2.49156 4.39062L2.92285 2.8125L4.08677 3.33984L4.7403 7C4.85698 7.484 5.14249 7.90273 5.54074 8.17773L8.15109 9.91797L7.19057 15H11.4997L12.2003 11.5332L14.9049 15H17.4964L12.9556 8.8125C12.6817 8.5525 12.3418 8.38041 11.9724 8.31641L9.7689 8.02539C9.38994 7.97539 9.02425 7.84934 8.6935 7.65234L6.55964 6.38281L5.97203 3.07422L3.18087 1.84766L3.52553 0.535156L1.66853 0ZM11.4997 2.99805C10.9882 2.99805 10.4977 3.20876 10.136 3.58383C9.77434 3.95891 9.57115 4.46761 9.57115 4.99805C9.57115 5.52848 9.77434 6.03719 10.136 6.41226C10.4977 6.78733 10.9882 6.99805 11.4997 6.99805C12.0112 6.99805 12.5017 6.78733 12.8634 6.41226C13.2251 6.03719 13.4283 5.52848 13.4283 4.99805C13.4283 4.46761 13.2251 3.95891 12.8634 3.58383C12.5017 3.20876 12.0112 2.99805 11.4997 2.99805ZM2.82115 17V19H20.1783V17H2.82115Z"
        fill="white"
      />
    </svg>
  )
}

export default Renovation
