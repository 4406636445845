const version = "v1"
const base = "svc-vms"
const baseFacility = "svc-facility"
const baseAccount = "svc-account"
const baseStorage = "svc-storage"

const API = {
  // ONBOARD
  FETCH_STAFFS: `${baseAccount}/${version}/users`,
  FETCH_ALL_ROLES: `${baseFacility}/${version}/user-roles`,
  PRESIGN_URL: `${baseStorage}/v2/upload-url`,
  FETCH_STAFF_LIST: `${baseAccount}/${version}/staff/users`,
  FETCH_ADMINS: `${baseAccount}/${version}/admin/users`,
  CONDO_INFO: `${baseFacility}/${version}/condo`
}

export default API
