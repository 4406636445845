import React, { useState } from "react"
import moment from "moment"
import { toast } from "react-toastify"
import useSWR, { mutate } from "swr"

/** Context */
import service from "services/Dashboard/Attendances"
import { toUnix, formatDate, toUnixTomorrow, toUnixDateOnly } from "utils/date"
import { Context } from "./Context"

const Provider: React.FC = (props: any) => {
  const { children } = props
  const [activeTable, setactiveTable] = useState(0)
  const [activeStatus, setActiveStatus] = useState(0)
  const [timeinOpen, setTimeinOpen] = useState(false)
  const [timeoutOpen, setTimeoutOpen] = useState(false)
  const [showStaff, setShowStaff] = useState(false)
  const [activeDate, setActiveDate] = useState<any>(moment())
  const [activeData, setActiveData] = useState<any>({})
  const [currentAction, setCurrentAction] = useState(1)
  const [isSubmitting, setSubmitting] = useState(false)
  const [downloadData, setDownloadData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [withTimeout, setwithTimeout] = useState<any>([])

  const [logDetails, setLogDetails] = useState({
    attendanceUID: "",
    category: 1,
    accountUID: "",
    name: "",
    attendanceType: 1,
    attendanceDateTime: new Date().toString(),
    attendanceDateTimeout: new Date().toString(),
    bluetoothBeaconUid: "",
    remarks: "",
    timeinReferenceUid: "",
    timeoutUID: "",
    hasTimeout: false
  })

  const unixDate = toUnix(activeDate)
  const refreshTable = () =>
    mutate(`fetchAttendances${activeStatus}-${unixDate}-${activeTable}`)

  /**
   * Notification
   */
  const notifyCreate = (staffName: string) =>
    toast(`${staffName} successfully timed-in.`, {
      type: toast.TYPE.SUCCESS
    })

  const notifyCreateFail = (staffName: string) =>
    toast(`Timed-in failed for ${staffName}.`, {
      type: toast.TYPE.ERROR
    })

  const notifyDelete = () =>
    toast(`Successfully deleted the time log`, {
      type: toast.TYPE.SUCCESS
    })

  /**
   * Methods
   */

  const fetchAttendances = async () => {
    const payload = {
      "start_date": toUnixDateOnly(activeDate),
      "end_date": toUnixTomorrow(activeDate, 1),
      "user_role_category": activeTable
    }

    const payloadAll = {
      "start_date": toUnixDateOnly(activeDate),
      "end_date": toUnixTomorrow(activeDate, 1)
    }

    const payloadOption = activeTable === 0 ? payloadAll : payload

    return service.getAttendances(payloadOption)
  }

  const { data, isValidating } = useSWR(
    `fetchAttendances${activeStatus}-${unixDate}-${activeTable}`,
    fetchAttendances,
    {
      revalidateOnFocus: true
    }
  )

  const attendanceData = data?.data?._data

  React.useEffect(() => {
    const timeOuts: any = []
    attendanceData?.filter((i: any) => {
      if (
        i?.time_out?._uid === "" &&
        i?.attendance_type === 1 &&
        !withTimeout.includes(i?.account_uid)
      ) {
        timeOuts.push(i?.account_uid)
      }
      setwithTimeout(timeOuts)
    })
  }, [data, logDetails])

  function showTimein(drawerState: boolean, dataItems?: Record<string, any>): void {
    setTimeinOpen(drawerState)

    if (dataItems !== undefined) {
      const di = dataItems
      setActiveData(dataItems)
      setLogDetails({
        attendanceUID: di?._uid,
        category: di?.user_role_category,
        accountUID: `${di?.account_uid}_${di?.name}`,
        name: di?.name,
        attendanceType: di?.attendanceType,
        attendanceDateTime: new Date(di?.attendance_date_time).toString(),
        attendanceDateTimeout: new Date().toString(),
        bluetoothBeaconUid: di?.bluetooth_beacon_uid,
        remarks: di?.remarks,
        timeinReferenceUid: di?._uid,
        timeoutUID: "",
        hasTimeout: di?.time_out?._uid !== ""
      })
    }
  }

  function showTimeout(drawerState: boolean, dataItems?: Record<string, any>): void {
    setTimeoutOpen(drawerState)

    if (dataItems !== undefined) {
      const di = dataItems
      setActiveData(dataItems)
      setLogDetails({
        attendanceUID: di?._uid,
        category: di?.user_role_category,
        accountUID: `${di?.account_uid}_${di?.name}`,
        name: di?.name,
        attendanceType: di?.attendanceType,
        attendanceDateTime: new Date(di?.attendance_date_time).toString(),
        attendanceDateTimeout:
          dataItems?.time_out?.attendance_date_time !== null
            ? new Date(di?.time_out?.attendance_date_time).toString()
            : new Date().toString(),
        bluetoothBeaconUid: di?.bluetooth_beacon_uid,
        remarks: di?.remarks,
        timeinReferenceUid: di?._uid,
        timeoutUID: di?.time_out?._uid,
        hasTimeout: di?.time_out?._uid !== ""
      })
    }
  }

  function showDetails(drawerState: boolean, dataItems?: Record<string, any>): void {
    setShowStaff(drawerState)

    if (dataItems !== undefined) {
      const di = dataItems
      setActiveData(dataItems)
      setLogDetails({
        attendanceUID: di?._uid,
        category: di?.user_role_category,
        accountUID: `${di?.account_uid}_${di?.name}`,
        name: di?.name,
        attendanceType: di?.attendanceType,
        attendanceDateTime: new Date(di?.attendance_date_time).toString(),
        attendanceDateTimeout: new Date().toString(),
        bluetoothBeaconUid: di?.bluetooth_beacon_uid,
        remarks: di?.remarks,
        timeinReferenceUid: di?._uid,
        timeoutUID: "",
        hasTimeout: di?.time_out?._uid !== ""
      })
    }
  }

  function resetForm() {
    setActiveData({})
    setLogDetails({
      attendanceUID: "",
      category: 1,
      accountUID: "",
      name: "",
      attendanceType: 1,
      attendanceDateTime: new Date().toString(),
      attendanceDateTimeout: new Date().toString(),
      bluetoothBeaconUid: "",
      remarks: "",
      timeinReferenceUid: "",
      timeoutUID: "",
      hasTimeout: false
    })
  }

  async function handleTimeinStaff(formType: number) {
    setSubmitting(true)

    const ld = logDetails
    const accUID = ld?.accountUID?.split("_")[0]
    const accName = ld?.accountUID?.split("_")[1]

    const payload = {
      "account_uid": accUID,
      "name": accName,
      "attendance_type": 1,
      "attendance_date_time": toUnix(ld?.attendanceDateTime),
      "photo": {
        "key": "",
        "file_name": "",
        "encoding": ""
      },
      "bluetooth_beacon_uid": ld?.bluetoothBeaconUid,
      "remarks": ld?.remarks,
      "time_in_reference_uid": ""
    }

    // const endpoint =
    //   formType === 1
    //     ? service.createAttendance(payload)
    //     : service.updateAttendance(payload, ld?.attendanceUID)

    /**
     * Check if has timeout
     */
    try {
      const response =
        formType === 1
          ? await service.createAttendance(payload)
          : await service.updateAttendance(payload, ld?.attendanceUID)
      if (response.status === 200) {
        notifyCreate(accName)
        showTimein(false)
        refreshTable()
        setSubmitting(false)
      } else {
        notifyCreateFail(accName)
        showTimein(false)
        refreshTable()
        setSubmitting(false)
      }

      return response
    } catch (e) {
      notifyCreateFail(accName)
      showTimein(false)
      refreshTable()
      setSubmitting(false)
      return e
    }
  }

  async function handleTimeoutStaff() {
    setSubmitting(true)

    const ld = logDetails
    const accUID = ld?.accountUID?.split("_")[0]
    const accName = ld?.accountUID?.split("_")[1]

    const payload = {
      "account_uid": accUID,
      "name": accName,
      "attendance_type": 2,
      "attendance_date_time": toUnix(ld?.attendanceDateTimeout),
      "photo": {
        "key": "",
        "file_name": "",
        "encoding": ""
      },
      "bluetooth_beacon_uid": ld?.bluetoothBeaconUid,
      "remarks": ld?.remarks,
      "time_in_reference_uid": ld?.timeinReferenceUid
    }

    const endpoint = logDetails?.hasTimeout
      ? service.updateAttendance(payload, ld?.timeoutUID)
      : service.createAttendance(payload)

    try {
      const response = await endpoint
      notifyCreate(accName)
      showTimeout(false)
      refreshTable()
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  async function handleDeleteTimein(logUID: string) {
    setSubmitting(true)
    try {
      const response = await service.deleteAttendance(logUID)
      notifyDelete()
      refreshTable()
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  async function handleDeleteTimeout(logUID: string) {
    setSubmitting(true)
    try {
      const response = await service.deleteAttendance(logUID)
      notifyDelete()
      refreshTable()
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const timeinData = attendanceData?.filter((i: Record<string, any>) => {
    return (
      i?.attendance_type === 1 &&
      i?.time_in_reference_uid === "" &&
      i?.time_out?._uid === ""
    )
  })

  const timeoutData = attendanceData?.filter((i: Record<string, any>) => {
    return (
      i?.attendance_type === 1 &&
      i?.time_in_reference_uid === "" &&
      i?.time_out?._uid !== ""
    )
  })

  return (
    <Context.Provider
      value={{
        logDetails,
        showStaff,
        activeTable,
        activeStatus,
        activeData,
        activeDate,
        currentAction,
        downloadData,
        setactiveTable,
        setActiveStatus,
        setActiveDate,
        setActiveData,
        handleTimeinStaff,
        handleTimeoutStaff,
        handleDeleteTimein,
        handleDeleteTimeout,
        setLogDetails,
        resetForm,
        setCurrentAction,
        setDownloadData,
        showTimein,
        showTimeout,
        showDetails,
        setIsEdit,
        isSubmitting,
        CSVFileName: `Attendance-${formatDate(activeDate)}.csv`,
        timeinData,
        timeoutData,
        isEdit,
        isValidating,
        timeinOpen,
        timeoutOpen,
        withTimeout
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
