import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  }
}))

export default styles
