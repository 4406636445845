export const MainNav = [
  {
    label: "Dashboard",
    to: "#/property-management/dashboards"
  },
  {
    label: "Reports",
    to: "#/property-management/reports"
  },
  {
    label: "Attendance",
    to: "#/property-management/attendance"
  },
  {
    label: "Announcements",
    content: [
      {
        label: "Post",
        to: "#/property-management/announcements/posts"
      }
    ]
  },
  {
    label: "Applications",
    to: "#/property-management/applications"
  },
  {
    label: "Facilities",
    to: "#/property-management/facilities"
  },

  {
    label: "Bookkeeping",
    to: "#/property-management/book-keeping"
  },
  {
    label: "Feedback",
    to: "#/property-management/feedback"
  },
  {
    label: "Unit Management",
    to: "#/property-management/unit-management"
  },
  {
    icon: "visitormanagement",
    label: "Visitor Management",
    to: "#/property-management/visitor-management"
  },
  {
    label: "Estate Matters",
    to: "#/property-management/estate-matters",
    content: [
      {
        label: "Public Documents",
        to: "#/property-management/estate-matters/public-documents"
      },
      {
        label: "Construction / Manuals",
        to: "#/property-management/estate-matters/construction-and-manuals"
      },
      {
        label: "Management Documents",
        to: "#/property-management/estate-matters/management-documents"
      }
    ]
  }
]

export const SettingsNav = [
  {
    label: "Back to Dashboard",
    to: "#/property-management/dashboards"
  },
  {
    label: "Facilities",
    to: "#/settings/facilities"
  },
  {
    label: "Visitor Management",
    to: "#/settings/visitor-management"
  },
  {
    label: "Applications",
    to: "#/settings/application"
  }
]
