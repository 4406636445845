import React, { useContext, useState } from "react"
import Fade from "@material-ui/core/Fade"
import useSWR from "swr"
import feedbackApi from "services/Dashboard/Feedback"

/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import { Context } from "context/Dashboard/Feedback/categories/main/Context"
import tableHeaders from "../tableHeaders"

const TableAll: React.FC = () => {
  const { tabStatus, activeTable, setDownloadData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchAllFeedback = async () =>
    feedbackApi.getFeedbacks({
      "posted_date_sort": "desc"
    })

  const { data, isValidating } = useSWR(
    `fetchAllFeedback-${activeTable}-${tabStatus}`,
    fetchAllFeedback,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []

  const filteredData = tableData?.filter((i: any) => i.status === tabStatus)
  const activeData = tabStatus !== 0 ? filteredData : tableData

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <Table
        data={activeData || []}
        columns={tableHeaders(`fetchAllFeedback-${activeTable}-${tabStatus}`)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isFilterable
        action={(values: any) => {
          return (
            setDownloadData &&
            setDownloadData(
              values?.map((i: any) => {
                return {
                  "Status": i?.status,
                  "Block/Unit No.": i?.feedbackUnitShortName,
                  "Owner Name": i?.feedbackOwnerAccountName,
                  "Type": i?.feedbackType,
                  "Assigned To": i?.assignedToStaffAccountName,
                  "Posted Date": i?.postedDate,
                  "Urgent": i?.isUrgent,
                  "Remarks": i?.remarks,
                  "Aging (number of days)": i.aging
                }
              })
            )
          )
        }}
      />
    </Fade>
  )
}

export default TableAll
