import React from "react"

export type CtxType = {
  setOpenDialog: Function
  setAccountRequestData: Function
  setActiveData: Function
  openDialog: boolean
  accountRequestData: Record<string, any>
  activeData: Record<string, any>
}

export const Context = React.createContext<Partial<CtxType>>({})
