import React from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import InputAdornment from "@material-ui/core/InputAdornment"

// components
import FormLabel from "components/Forms/Label"

interface HeaderProps {
  loginDetails: Record<string, string>
  handleChange: Function
}

const Form: React.FC<HeaderProps> = ({ loginDetails, handleChange }) => {
  const [valuesPass, setValuesPass] = React.useState(false)
  const handleClickShowPassword = () => {
    setValuesPass(!valuesPass)
  }
  return (
    <Box>
      <Box marginBottom="30px">
        <FormLabel label="username / email" />
        <TextField
          placeholder="Username / Email"
          variant="outlined"
          value={loginDetails.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange("email", e.target.value)}
          inputProps={{ "className": "login-email-input" }}
        />
      </Box>

      <Box marginBottom="30px">
        <FormLabel label="Password" />
        <OutlinedInput
          placeholder="••••••••••••••••••"
          type={valuesPass ? "text" : "password"}
          // variant="outlined"
          value={loginDetails.pw}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange("pw", e.target.value)}
          inputProps={{ "className": "login-password-input" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {valuesPass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </Box>
  )
}
export default Form
