import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"

/** Components */
import Drawer from "components/Drawer/new"
import Header from "components/Header"
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import Label from "components/Forms/Label"

/** Utils */
import { fromUnix } from "utils/date"

/** Validation */
import { rejectApplication } from "config/Dashboard/Applications/validation"
import {
  FetchApplicationsMovingInOut,
  FetchApplicationsVehicle,
  FetchApplicationsAccessCard,
  FetchApplicationsRenovation
} from "config/Dashboard/Applications/apiName"

/** Context */
import { Context } from "context/Dashboard/Applications/main/Context"

import styles from "./styles"

interface RejectApplicationProps {
  openState: boolean
  setOpenState: Function
  data?: Record<string, any>
}

const RejectApplication: React.FC<RejectApplicationProps> = ({
  openState,
  setOpenState,
  data
}) => {
  const { value } = styles()
  const applicationType = data?.application_type
  const {
    remarks,
    setRemarks,
    handleRejectItem,
    isSubmitting,
    activeFilter
  } = useContext(Context)

  function mutateCB(type: number, currentFilter = 0) {
    switch (type) {
      case 1:
        return FetchApplicationsVehicle(currentFilter)
      case 2:
        return FetchApplicationsMovingInOut(currentFilter)
      case 3:
      case 4:
      case 5:
        return FetchApplicationsAccessCard(currentFilter)
      case 6:
        return FetchApplicationsRenovation(currentFilter)
      default:
        return FetchApplicationsMovingInOut(currentFilter)
    }
  }

  /** Methods */
  function handleReject(handleSubmit: Function): void {
    handleSubmit()

    if (remarks !== "") {
      handleRejectItem &&
        handleRejectItem(data?._uid).then(() => [
          setOpenState(false),
          mutateCB(applicationType, activeFilter)
        ])
    }
  }

  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: Function,
    setFieldTouched: Function,
    setFieldValue: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)
    setFieldValue(name, e.target.value)
    setRemarks && setRemarks(e.target.value)
  }

  return (
    <>
      <Drawer openState={openState} setOpenState={setOpenState}>
        <Header title="Reject Application" />

        <Box padding="40px 30px">
          <Box borderBottom="1px solid #F2F2F2">
            <Box marginBottom="15px">
              <Label label="Applicant Name" />
              <Box className={value}>{data?.applicant_name}</Box>
            </Box>

            <Box marginBottom="15px">
              <Label label="Application Date" />
              <Box className={value}>{fromUnix(data?.application_date)}</Box>
            </Box>

            <Box marginBottom="15px">
              <Label label="Serial No." />
              <Box className={value}>
                {data?.serial_no === "" ? "N/A" : data?.serial_no}
              </Box>
            </Box>
          </Box>

          <Formik
            initialValues={{
              remarks
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={rejectApplication}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
              setFieldTouched
            }): JSX.Element => {
              return (
                <form>
                  <FormWrapper>
                    <Box marginBottom="25px">
                      <FormInput
                        name="remarks"
                        value={remarks}
                        idValue="remarks"
                        label="Remarks"
                        placeholder="Remarks"
                        multiline
                        rows={3}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(
                            "remarks",
                            e,
                            handleChange,
                            setFieldTouched,
                            setFieldValue
                          )}
                        onBlur={handleBlur}
                        error={touched.remarks && Boolean(errors.remarks)}
                        helperText={
                          errors.remarks && touched.remarks && errors.remarks
                        }
                      />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box flex="1" maxWidth="49%">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => setOpenState(false)}
                          className="btn-cancel"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box flex="1" maxWidth="49%">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => handleReject(handleSubmit)}
                          className="btn-cancel"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Reject"}
                        </Button>
                      </Box>
                    </Box>
                  </FormWrapper>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Drawer>
    </>
  )
}

export default RejectApplication
