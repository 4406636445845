import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { AppContext } from "App"
import AppHeader from "../AppHeader"
import AppSidebar from "../AppSidebar"

/** Context */

const useStyles = makeStyles(() => ({
  pageName: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#454B57",
    padding: "20px 0",
    zIndex: 9,
    backgroundColor: "rgb(242, 242, 242)",
    width: "100%"
  },
  content: {
    width: "calc(100% - 270px)",
    padding: "30px",
    backgroundColor: "#FAFAFA"
  }
}))

// interface
interface WithHeaderProps {
  children: any
  match?: any
}

const WithHeader: React.FC<WithHeaderProps> = ({ children }) => {
  const { content } = useStyles()
  const { hasCondoUID } = useContext(AppContext)

  return (
    <>
      <div>
        <AppHeader />
        <Box
          display="flex"
          style={{
            height: "100%",
            marginTop: "81px",
            backgroundColor: "#F2F2F2"
          }}
        >
          <Box>
            <AppSidebar />
          </Box>

          {hasCondoUID && <Box className={content}>{children}</Box>}
        </Box>
      </div>
    </>
  )
}

export default WithHeader
