import React from "react"

/** Components */
import ContextMenu from "components/ContextMenu"

/** Config */
import ContextSettingsFeedback from "../../ContextMenu"

const TableHeaderColumns = () => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any) => {
          return (
            <ContextMenu>
              <ContextSettingsFeedback data={row.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      // { Remove for now as per Melvin
      //   Header: "S/N",
      //   id: "s_n",
      //   accessor: "null",
      //   width: 100,
      // },
      {
        Header: "Name",
        id: "name",
        // accessor: "account_info_name"
        accessor: (original: Record<string, any>) => {
          return original?.account_info_name
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.account_info_name === "" || null
            ? "N/A"
            : original?.account_info_name
        }
      },
      {
        Header: "Email",
        id: "email",
        // accessor: "email"
        accessor: (original: Record<string, any>) => {
          return original?.email
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.email === "" || null ? "N/A" : original?.email
        }
      },
      {
        Header: "Phone Number",
        id: "phone_no",
        // accessor: "mobile_no"
        accessor: (original: Record<string, any>) => {
          return original?.mobile_no
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.mobile_no === "" || null ? "N/A" : original?.mobile_no
        }
      },
      {
        Header: "User Roles",
        id: "user_role",
        // accessor: "user_role.role_name"
        accessor: (original: Record<string, any>) => {
          return original?.user_role.role_name
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.user_role.role_name === "" || null
            ? "N/A"
            : original?.user_role.role_name
        }
      },
      {
        Header: "Login Code",
        id: "login_code",
        // accessor: "access_code"
        accessor: (original: Record<string, any>) => {
          return original?.access_code
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.access_code === "" || null ? "N/A" : original?.access_code
        }
      }
    ]
  }
]

export default TableHeaderColumns
