import React, { useState, useRef } from "react"
import { usePdf } from "@mikecousins/react-pdf"

interface Props {
  file: string
}

const MyPdfViewer: React.FC<Props> = ({ file }) => {
  const [page] = useState(1)
  const canvasRef = useRef(null)

  const { pdfDocument } = usePdf({
    file,
    page,
    canvasRef
  })

  return (
    <div style={{ padding: "0 20px" }}>
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} />
    </div>
  )
}

export default MyPdfViewer
