import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import LoginCurves from "../assets/loginWithLogo.svg"

const styles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh"
    // background: "linear-gradient(157.32deg, #004E8B 3.02%, #002848 91.85%)",
    // borderRadius: "0px 500px 0px 0px",
  }
}))

const Brand: React.FC = () => {
  const { container } = styles()
  return (
    <Box className={container} position="relative">
      <Box position="absolute" top="0" left="0" zIndex="-1" height="100vh">
        <img src={LoginCurves} alt="Cora" style={{ height: "inherit" }} />
      </Box>
    </Box>
  )
}

export default Brand
