import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Formik } from "formik"
/** Components */
import Staffs from "components/Common/Staffs/new"
import FormWrapper from "components/Forms/FormWrapper"
import TimePicker from "components/Forms/TimePicker"

/** Validation */
import { createTimein } from "config/Dashboard/Attendance/validation"
/** Config */
import { RolesCategory } from "config/Common/Roles"
/** Styles */

import withContext from "context/Dashboard/Attendance/main/withContext"
import { CtxType } from "context/Dashboard/Attendance/main/Context"
import styles from "./styles"

const LogForm: React.FC<CtxType> = ({
  logDetails,
  setLogDetails,
  handleTimeinStaff,
  showTimein,
  isSubmitting,
  withTimeout,
  isEdit
}) => {
  const { container, section, label, labelGap, footer, wrapper } = styles()
  const ld = logDetails
  const accUID = ld?.accountUID?.split("_")[0]
  const cannotTimein = withTimeout.includes(accUID) && ld?.attendanceUID === ""

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setLogDetails &&
        setLogDetails({
          ...logDetails,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setLogDetails &&
        setLogDetails({
          ...logDetails,
          [name]: e?.target?.value
        })
    }
  }

  const handleFormCategory = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue(name, e?.target?.value)
    setLogDetails &&
      setLogDetails({
        ...logDetails,
        [name]: e?.target?.value,
        accountUID: ""
      })
  }

  const handleSubmitForm = (handleSubmit: Function): void => {
    if (ld?.accountUID === "") {
      return handleSubmit()
    }

    return handleTimeinStaff(ld?.attendanceUID === "" ? 1 : 2)
  }

  const actionText = () => {
    return ld?.attendanceUID === "" ? "TIME-IN" : "UPDATE"
  }

  return (
    <Formik
      initialValues={{
        category: ld?.category,
        accountUID: ld?.accountUID,
        attendanceDateTime: ld?.attendanceDateTime
      }}
      onSubmit={(values, actions): void => {
        JSON.stringify(values, null, 2)
        actions.setSubmitting(false)
      }}
      validationSchema={createTimein}
    >
      {({
        touched,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue
      }): JSX.Element => {
        return (
          <form style={{ height: "100%" }}>
            <Box className={wrapper}>
              <Box flex="3">
                <FormWrapper>
                  <Box className={container}>
                    <Box className={section}>
                      <Box className={label}>Category</Box>
                      <RadioGroup
                        value={String(ld?.category)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                          handleFormCategory("category", e, setFieldValue)}
                      >
                        {Object.keys(RolesCategory).map((role: any) => {
                          return (
                            <FormControlLabel
                              key={role}
                              value={role}
                              control={<Radio color="primary" />}
                              label={RolesCategory[role]}
                              labelPlacement="end"
                              disabled={isEdit}
                            />
                          )
                        })}
                      </RadioGroup>
                    </Box>

                    <Box className={section}>
                      <Box className={label}>Select Staff</Box>
                      <Box>
                        <Staffs
                          disabled={isEdit}
                          value={ld?.accountUID}
                          category={ld?.category}
                          handleChange={(e: any) =>
                            handleFormChange("accountUID", e, setFieldValue)}
                          onBlur={(e: any) => handleBlur(e)}
                          error={touched?.accountUID && Boolean(errors?.accountUID)}
                          helperText={
                            touched?.accountUID && errors?.accountUID?.toString()
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Box className={section}>
                      <Box className={labelGap} paddingBottom="10px">
                        Login Details
                      </Box>
                      <Box>
                        <TimePicker
                          label="Time in"
                          name="timeIn"
                          value={ld?.attendanceDateTime}
                          handleDateChange={(value: string): void =>
                            handleFormChange(
                              "attendanceDateTime",
                              value,
                              setFieldValue
                            )}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.attendanceDateTime &&
                            Boolean(errors.attendanceDateTime)
                          }
                          helperText={
                            touched.attendanceDateTime &&
                            errors.attendanceDateTime?.toString()
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box className={footer}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => showTimein(false)}
                  >
                    CANCEL
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm(handleSubmit)}
                    disabled={cannotTimein}
                  >
                    {isSubmitting ? "Submitting..." : actionText()}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default withContext(LogForm)
