import * as React from "react"
import { AddFeedbackContext } from "./Context"

function withContext(Component: any) {
  return function contextComponent(props: any) {
    return (
      <AddFeedbackContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </AddFeedbackContext.Consumer>
    )
  }
}

export default withContext
