import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

/** Components */
import Drawer from "components/Drawer"
import Header from "components/Header"
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"

/** Context */
import { AppContext } from "App"

import { checkValue } from "utils/helpers"

/** Header */
import styles from "./styles"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  action?: Function
  setopenState: Function
}

const Details: React.FC<DetailsProps> = ({
  openState,
  setopenState,
  data,
  action
}) => {
  const { section, sectionTitle, sectionItem } = styles()
  const files = data?.supporting_doc?.files

  const { condoInfo } = useContext(AppContext)
  const isRejected = data?.is_rejected

  const handleCreate = () => {
    action && action()
    setopenState(false)
  }

  return (
    <Drawer openState={openState || false} setopenState={setopenState}>
      <Header
        title={isRejected ? "Rejected" : "New User Sign-up"}
        subtitle="Unit Management"
        subtitleAbove
      />
      <Box className={section}>
        <Box padding="30px 30px 15px 30px">
          <Box>
            <Box className={sectionTitle}>General Info</Box>
          </Box>
          <Box>
            <Box className={sectionItem}>
              <Label label="condo name" />
              <Value val={checkValue(condoInfo?.name)} />
            </Box>
            <Box className={sectionItem}>
              <Label label="block/unit no." />
              <Value val={checkValue(data?.unit?.short_name)} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={section}>
        <Box padding="30px 30px 15px 30px">
          <Box>
            <Box className={sectionTitle}>Personal Details</Box>
          </Box>
          <Box>
            <Box className={sectionItem}>
              <Label label="Full Name" />
              <Value val={checkValue(data?.name)} />
            </Box>
            <Box className={sectionItem}>
              <Label label="contact number" />
              <Value val={checkValue(data?.mobile_no)} />
            </Box>
            <Box className={sectionItem}>
              <Label label="email address" />
              <Value val={checkValue(data?.email)} />
            </Box>
          </Box>
        </Box>
      </Box>

      {files && (
        <Box className={section}>
          <Box padding="30px 30px 15px 30px">
            <Box>
              <Box className={sectionTitle}>Supporting Docs</Box>
            </Box>
            <Box>
              {Object.keys(files).map((file) => {
                return (
                  <Box className={sectionItem}>
                    <a href={files[file]?.url} target="_blank" rel="noreferrer">
                      <Value val={files[file].file_name} />
                    </a>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      )}

      {!isRejected && (
        <Box display="flex" justifyContent="center" padding="30px 0">
          <Box marginRight="15px">
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setopenState(false)}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleCreate()}
              fullWidth
            >
              CREATE ACCOUNT
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  )
}
export default Details
