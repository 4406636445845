import React from "react"
import Box from "@material-ui/core/Box"

import Button from "@material-ui/core/Button"
import { IconAdd } from "components/Icons/Common"

import styles from "./styles"

interface Props {
  action: Function
}

const NoFacilityView: React.FC<Props> = ({ action }) => {
  const classes = styles()
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>No facilities available</Box>
      <Button
        color="primary"
        startIcon={IconAdd}
        onClick={() => action()}
        variant="contained"
      >
        ADD FACILITY
      </Button>
    </Box>
  )
}
export default NoFacilityView
