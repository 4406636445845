import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Box from "@material-ui/core/Box"
/** Service */
import UnitManagementAPI from "services/Dashboard/UnitManagement"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"

import tableHeaders from "./table/tableHeaders"

const TableRejected: React.FC = () => {
  const { setDownloadData } = useContext(ParentContext)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchRejectedAccountRequests = async () =>
    UnitManagementAPI.getAccountRequests({
      "is_rejected": true
    })

  const { data, isValidating } = useSWR(
    "fetchRejectedAccountRequests",
    fetchRejectedAccountRequests,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data).reverse()
      : []

  if (isValidating) {
    return (
      <Box height="500px">
        <Loader dark />
      </Box>
    )
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData}
          columns={tableHeaders}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          minRows={3}
          isFilterable
          action={(values: any) => {
            return (
              values.length !== 0 &&
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Block/Unit No.": i?.unitNumber,
                    "Name": i?.name,
                    "Email": i?.email,
                    "Mobile": i?.mobileNumber
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TableRejected
