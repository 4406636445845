import * as Yup from "yup"

export const addCategory: Record<any, any> = Yup.object().shape({
  name: Yup.string().required("Required"),
  bccEmailFirst: Yup.string().required("Required")
})

const validation = {
  addCategory
}

export default validation
