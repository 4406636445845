import * as React from "react"

interface VisitorManagementProps {
  color: string
}

const VisitorManagement: React.FC<VisitorManagementProps> = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.897 0 0 0.897 0 2V18H4V16H2V2H12V7.55859C12.174 7.66159 12.343 7.77339 12.5 7.90039C12.935 7.54939 13.446 7.29548 14 7.14648V2C14 0.897 13.103 0 12 0H2ZM4 4V6H6V4H4ZM8 4V6H10V4H8ZM4 8V10H6V8H4ZM10 9C9.46957 9 8.96086 9.21071 8.58579 9.58579C8.21071 9.96086 8 10.4696 8 11C8 11.5304 8.21071 12.0391 8.58579 12.4142C8.96086 12.7893 9.46957 13 10 13C10.5304 13 11.0391 12.7893 11.4142 12.4142C11.7893 12.0391 12 11.5304 12 11C12 10.4696 11.7893 9.96086 11.4142 9.58579C11.0391 9.21071 10.5304 9 10 9ZM15 9C14.4696 9 13.9609 9.21071 13.5858 9.58579C13.2107 9.96086 13 10.4696 13 11C13 11.5304 13.2107 12.0391 13.5858 12.4142C13.9609 12.7893 14.4696 13 15 13C15.5304 13 16.0391 12.7893 16.4142 12.4142C16.7893 12.0391 17 11.5304 17 11C17 10.4696 16.7893 9.96086 16.4142 9.58579C16.0391 9.21071 15.5304 9 15 9ZM4 12V14H6V12H4ZM10 15C7.815 15 6 15.9088 6 17.2168V18H11H14H19V17.2168C19 15.9088 17.185 15 15 15C14.0549 15 13.1897 15.1777 12.5 15.4766C11.8103 15.1777 10.9451 15 10 15Z"
        fill={color}
      />
    </svg>
  )
}

export default VisitorManagement
