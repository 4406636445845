import React from "react"

/** Context */
import { Context } from "./Context"

const OwnersProvider: React.FC = ({ children }) => {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [accountRequestData, setAccountRequestData] = React.useState([])
  const [activeData, setActiveData] = React.useState([])

  return (
    <Context.Provider
      value={{
        setOpenDialog,
        setAccountRequestData,
        setActiveData,
        activeData,
        openDialog,
        accountRequestData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default OwnersProvider
