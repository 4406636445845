import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toUnix } from "utils/date"

export function TotalAttendanceHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalAttendance = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalAttendance-${unixFrom}-${unixTo}`,
    fetchTotalAttendance,
    {
      revalidateOnFocus: false
    }
  )

  /** Office */
  const fetchTotalOffice = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 1
    })

  const { data: office } = useSWR(
    `fetchTotalOffice-${unixFrom}-${unixTo}`,
    fetchTotalOffice,
    {
      revalidateOnFocus: false
    }
  )

  /** Security */
  const fetchTotalSecurity = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 2
    })

  const { data: security } = useSWR(
    `fetchTotalSecurity-${unixFrom}-${unixTo}`,
    fetchTotalSecurity,
    {
      revalidateOnFocus: false
    }
  )

  /** Cleaning */
  const fetchTotalCleaning = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 3
    })

  const { data: cleaning } = useSWR(
    `fetchTotalCleaning-${unixFrom}-${unixTo}`,
    fetchTotalCleaning,
    {
      revalidateOnFocus: false
    }
  )

  /** Landscape */
  const fetchTotalLandscape = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 4
    })

  const { data: landscape } = useSWR(
    `fetchTotalLandscape-${unixFrom}-${unixTo}`,
    fetchTotalLandscape,
    {
      revalidateOnFocus: false
    }
  )

  /** Maintenance */
  const fetchTotalMaintenance = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 5
    })

  const { data: maintenance } = useSWR(
    `fetchTotalMaintenance-${unixFrom}-${unixTo}`,
    fetchTotalMaintenance,
    {
      revalidateOnFocus: false
    }
  )

  /** Maintenance */
  const fetchTotalOthers = async () =>
    service.getCountAttendance({
      "start_date": unixFrom,
      "end_date": unixTo,
      "attendance_type": 1,
      "user_role_category": 6
    })

  const { data: others } = useSWR(
    `fetchTotalOthers-${unixFrom}-${unixTo}`,
    fetchTotalOthers,
    {
      revalidateOnFocus: false
    }
  )

  const totalAttendance = data?.data?._data?.total
  const totalOffice = office?.data?._data?.total
  const totalSecurity = security?.data?._data?.total
  const totalCleaning = cleaning?.data?._data?.total
  const totalLandscape = landscape?.data?._data?.total
  const totalMaintenance = maintenance?.data?._data?.total
  const totalOthers = others?.data?._data?.total

  return {
    totalAttendance,
    totalSecurity,
    totalOffice,
    totalCleaning,
    totalLandscape,
    totalMaintenance,
    totalOthers,
    isValidating,
    error
  }
}
