import Forms from "components/Icons/UnitManagement/StrataRoll"
import Residents from "components/Icons/UnitManagement/Residents"
import Vehicles from "components/Icons/UnitManagement/Vehicles"
import AccessCards from "components/Icons/UnitManagement/AccessCards"
import Others from "components/Icons/UnitManagement/Others"
import AccountsRequests from "components/Icons/UnitManagement/AccountsRequests"
import PaymentSchedule from "components/Icons/UnitManagement/PaymentSchedule"

export const Menu = [
  {
    label: "StrataRoll",
    icon: Forms,
    value: "strataroll",
    color: "#FA7268"
  },
  {
    label: "Residents",
    icon: Residents,
    value: "residents",
    color: "#2D9CDB"
  },
  {
    label: "Vehicles",
    icon: Vehicles,
    value: "vehicles",
    color: "#50D757"
  },
  {
    label: "Access Cards",
    icon: AccessCards,
    value: "accesscards",
    color: "#FFD542"
  },
  {
    label: "Others",
    icon: Others,
    value: "others",
    color: "#A56300"
  }
]

const MenuIcons: {
  [index: string]: {}
} = {
  "owners": Forms,
  "residents": Residents,
  "vehicles": Vehicles,
  "accesscards": AccessCards,
  "others": Others,
  "accountsrequests": AccountsRequests,
  "paymentschedule": PaymentSchedule
}

export default Menu

export { MenuIcons }
