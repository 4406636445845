import React, { useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { Context } from "context/Settings/PropertyManagement/Applications/main/Context"

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    borderBottom: "1px solid #F0F3F7",
    padding: "10px 10px 10px 20px",
    width: "100%",
    "& i": {
      marginRight: "15px",
      paddingTop: "3px",
      width: "25px",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  innerList: {
    padding: "10px",
    "& > div": {
      paddingLeft: "55px"
    }
  },
  collapseControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& i": {
      paddingTop: "3px"
    },
    "& > div": {
      display: "flex",
      alignItems: "center"
    }
  }
}))

const IconEdit = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4141 0C15.1581 0 14.902 0.0979687 14.707 0.292969L12.707 2.29297L11.293 3.70703L0 15V19H4L18.707 4.29297C19.098 3.90197 19.098 3.26891 18.707 2.87891L16.1211 0.292969C15.9261 0.0979687 15.6701 0 15.4141 0ZM15.4141 2.41406L16.5859 3.58594L15.293 4.87891L14.1211 3.70703L15.4141 2.41406ZM12.707 5.12109L13.8789 6.29297L3.17188 17H2V15.8281L12.707 5.12109Z"
      fill="#09707B"
    />
  </svg>
)

interface Props {
  data: any
  showEdit: Function
}

const ContextAdmin: React.FC<Props> = ({ data, showEdit }) => {
  const { listItem } = useStyles()
  const { setActiveData } = useContext(Context)

  function showEditView(items: Record<string, any>) {
    setActiveData && setActiveData(items)
    showEdit()
  }

  return (
    <List component="nav">
      <ListItem className={listItem} button onClick={() => showEditView(data)}>
        <i>{IconEdit}</i>
        Edit
      </ListItem>
    </List>
  )
}

export default ContextAdmin
