import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

import StaffForm from "pages/Settings/AccountManagement/Staff/components/Form/main"

/** Context */
import { Context as MainContext } from "context/Settings/AccountManagement/Staff/main/Context"
import withContext from "context/Settings/AccountManagement/Staff/forms/staff/withContext"
import { CtxType } from "context/Settings/AccountManagement/Staff/forms/staff/Context"

const AddStaffView: React.FC<CtxType> = () => {
  const { showForm, setShowForm, setActiveData, formType } = useContext(MainContext)

  const MainView = (): JSX.Element => {
    return (
      <Box>
        <StaffForm
          openState={showForm || false}
          setopenState={() => {
            setShowForm && setShowForm(false)
            setActiveData && setActiveData({ "data": "" })
          }}
          formType={formType || 1}
        />
      </Box>
    )
  }

  return <>{MainView()}</>
}

export default withContext(AddStaffView)
