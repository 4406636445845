import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

import LoginCurves from "../assets/loginWithLogo.svg"

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100vh"
  },
  containerBrand: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: -1,
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      overflow: "hidden"
    }
  }
}))

const Brand: React.FC = () => {
  const classes = styles()
  return (
    <Box className={classes.container} position="relative">
      <Box className={classes.containerBrand}>
        <img src={LoginCurves} alt="Cora" style={{ height: "inherit" }} />
      </Box>
    </Box>
  )
}

export default Brand
