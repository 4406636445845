import React from "react"

import ReplyProvider, {
  ReplyProviderProps
} from "context/Dashboard/Feedback/reply/ReplyProvider"
import ReplyView from "context/Dashboard/Feedback/reply/ReplyView"

interface ReplyProps {
  setReplyActive: Function
  getFeedbacks: Function
  setTabsFilter: Function
  data: Record<string, any>
}
const ReplyMain: React.FC<ReplyProviderProps> = ({ showForm, isCreate, data }) => {
  return (
    <ReplyProvider showForm={showForm} isCreate={isCreate} data={data}>
      <ReplyView />
    </ReplyProvider>
  )
}

export default ReplyMain
