import React from "react"

import EditPaymentScheduleProvider from "context/Dashboard/UnitManagement/forms/paymentSchedule/editPaymentSchedule/Provider"
import EditPaymentScheduleView from "context/Dashboard/UnitManagement/forms/paymentSchedule/editPaymentSchedule/View"

/** Interface */
interface EditPaymentScheduleProps {
  data: Record<any, any>
}

const EditPaymentSchedule: React.FC<EditPaymentScheduleProps> = ({ data }) => (
  <EditPaymentScheduleProvider data={data}>
    <EditPaymentScheduleView />
  </EditPaymentScheduleProvider>
)

export default EditPaymentSchedule
