import React, { useContext, ReactInstance } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ReactToPrint from "react-to-print"
/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import Label from "components/Forms/Label"
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
import { AppContext } from "App"
/**
 * Utils
 */
import { checkValue } from "utils/helpers"
import { refPrintBtn } from "context/Dashboard/Facilites/forms/addBooking/View"
import FacilitiesBreakdown from "../Common/FacilitiesBreakdown"
/**
 * Styles
 */
import styles from "./styles"

/** Refs */

/* eslint-disable react/prefer-stateless-function */
class EmptyComponent extends React.Component {
  render() {
    return null
  }
}

const PaymentSummary: React.FC<CtxType> = ({
  facilityBookings,
  mainView,
  returnedBooking
}) => {
  const classes = styles()
  const { condoInfo } = useContext(AppContext)

  const ele: ReactInstance = new EmptyComponent({})
  const refReport = React.useRef<any>(ele)

  const isAddBooking = mainView === "addBooking"
  const details = returnedBooking[0]
  const fBookings = facilityBookings[0] as any

  function addBookingView(): JSX.Element {
    if (!isAddBooking) {
      return (
        <Box>
          <FacilitiesBreakdown facilityBooking={fBookings} forSummary />
        </Box>
      )
    }
    return (
      <Box>
        {facilityBookings?.map((facilityBooking: Record<string, any>) => {
          return <FacilitiesBreakdown facilityBooking={facilityBooking} forSummary />
        })}
      </Box>
    )
  }

  return (
    <Box>
      <div ref={refReport}>
        <FormWrapper title="Booking Details" titleSmall width="80%">
          <Box width="80%" margin="auto">
            <Box className={classes.bookingDetails}>
              <Box className={classes.bookingDetailsInfo}>
                <Box>
                  <Label label="name" />
                  <Box>
                    {checkValue(
                      details?.booking_by_name || fBookings?.booking_by_name
                    )}
                  </Box>
                </Box>
                <Box>
                  <Label label="condo" />
                  <Box>{checkValue(condoInfo?.name)}</Box>
                </Box>
                <Box>
                  <Label label="block/unit no." />
                  <Box>
                    {checkValue(
                      details?.unit?.short_name || fBookings?.unit?.short_name
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {addBookingView()}

            <Box className={classes.receivedBy}>
              <Label label="received by:" />
              <Box borderTop="1px solid #F2F2F2" marginTop="50px" textAlign="center">
                <Box marginTop="20px">Signature over printed name</Box>
              </Box>
            </Box>
            <Box>
              <ReactToPrint
                trigger={() => <Button onClick={() => null} ref={refPrintBtn} />}
                content={() => (refReport.current ? refReport.current : ele)}
              />
            </Box>
          </Box>
        </FormWrapper>
      </div>
    </Box>
  )
}
export default withContext(PaymentSummary)
