import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
// import Select from "components/Forms/Select"
/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
import BlockUnit from "components/Common/BlockUnit/new"
import Names from "components/Common/Names"
import DatePicker from "components/Forms/DatePicker"
import UploadBox from "components/UploadBox/new"
/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/accessCards/createAccessCard/Context"
import withContext from "context/Dashboard/UnitManagement/forms/accessCards/createAccessCard/withContext"
/** Config */
import { addAccessCardDetails } from "config/Dashboard/UnitManagement/validation"
// import { vehicleType as vehicleTypeList } from "config/Dashboard/Applications"
/** Refs */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/accessCards/createAccessCard/View"
import { handleBlockUnit, handleResidentName } from "utils/functions"
import styles from "./styles"
/** helpers */

const PersonalDetails: React.FC<CtxType> = ({
  accessCardDetails,
  setAccessCardDetails,
  handleDocsUpload,
  removeDoc,
  photos,
  localPhotos,
  attaching
}) => {
  const {
    blockUnit,
    ownerName,
    serialNumber,
    activationDate,
    expiryDate,
    vehicleType
  } = accessCardDetails

  const classes = styles(0)

  /** Methods */
  const handleFormChange = (setFieldValue: Function, key: string, e: any): void => {
    if (e.target === undefined) {
      setAccessCardDetails &&
        setAccessCardDetails({
          ...accessCardDetails,
          [key]: e
        })
      setFieldValue(key, e)
    } else {
      setAccessCardDetails &&
        setAccessCardDetails({
          ...accessCardDetails,
          [key]: e?.target?.value
        })
      setFieldValue(key, e?.target?.value)
    }
  }

  return (
    <div>
      <Box margin="auto" padding="0 0 40px 0">
        <Formik
          initialValues={{
            blockUnit,
            ownerName,
            serialNumber,
            activationDate,
            expiryDate,
            vehicleType
          }}
          onSubmit={(values: any) => {
            return values
          }}
          validationSchema={addAccessCardDetails}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form>
                <Box className={classes.container}>
                  <Box className={classes.section}>
                    <FormWrapper title="Personal Details">
                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <BlockUnit
                            iValue={blockUnit}
                            onChange={(value: string) =>
                              handleBlockUnit(
                                setFieldValue,
                                value,
                                setAccessCardDetails,
                                accessCardDetails,
                                "blockUnit",
                                "ownerAccountUID"
                              )}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.blockUnit && Boolean(errors.blockUnit)}
                            helperText={
                              touched.blockUnit && errors.blockUnit?.toString()
                            }
                          />
                        </Box>

                        <Box className={classes.sectionRowRight}>
                          <Names
                            value={ownerName}
                            onChange={(value: Event) =>
                              handleResidentName(
                                setFieldValue,
                                value,
                                setAccessCardDetails,
                                accessCardDetails,
                                "ownerName",
                                "ownerAccountUID"
                              )}
                            unitUID={blockUnit}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={touched.ownerName && Boolean(errors.ownerName)}
                            helperText={
                              errors.ownerName &&
                              touched.ownerName &&
                              errors.ownerName.toString()
                            }
                          />
                        </Box>
                      </Box>
                    </FormWrapper>
                  </Box>

                  <Box>
                    <FormWrapper title="Access Card Details">
                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <FormInput
                            name="serialNumber"
                            value={serialNumber}
                            idValue="serialNumber"
                            label="Serial Number"
                            placeholder="Serial Number"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange(setFieldValue, "serialNumber", e)}
                            onBlur={handleBlur}
                            error={
                              touched.serialNumber && Boolean(errors.serialNumber)
                            }
                            helperText={
                              errors.serialNumber &&
                              touched.serialNumber &&
                              errors.serialNumber
                            }
                          />
                        </Box>
                      </Box>

                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <DatePicker
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            minDate={activationDate}
                            label="Activation Date"
                            name="activationDate"
                            value={activationDate}
                            handleDateChange={(value: string): void =>
                              handleFormChange(
                                setFieldValue,
                                "activationDate",
                                value
                              )}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={
                              touched.activationDate &&
                              Boolean(errors.activationDate)
                            }
                            helperText={
                              touched.activationDate &&
                              errors.activationDate?.toString()
                            }
                          />
                        </Box>
                        <Box className={classes.sectionRowRight}>
                          <DatePicker
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            minDate={activationDate}
                            label="Expiry Date"
                            name="expiryDate"
                            value={expiryDate === 0 ? null : expiryDate}
                            handleDateChange={(value: string): void =>
                              handleFormChange(setFieldValue, "expiryDate", value)}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.expiryDate && Boolean(errors.expiryDate)}
                            helperText={
                              touched.expiryDate && errors.expiryDate?.toString()
                            }
                          />
                        </Box>
                      </Box>
                    </FormWrapper>
                  </Box>
                  <Box>
                    <FormWrapper title="Photo">
                      <UploadBox
                        hasTitle={false}
                        onDrop={(event: any) => handleDocsUpload(event)}
                        files={photos}
                        previewImages={localPhotos}
                        removePhoto={(event: any) => removeDoc(event)}
                        attaching={attaching}
                        title="Choose file to upload"
                      />
                    </FormWrapper>
                  </Box>
                </Box>
                <RefButton action={handleSubmit} refValue={refSubmit} />
              </form>
            )
          }}
        </Formik>
      </Box>
    </div>
  )
}

export default withContext(PersonalDetails)
