import React from "react"

import Provider from "context/Dashboard/EstateMatters/ConstructionManuals/main/Provider"
import View from "context/Dashboard/EstateMatters/ConstructionManuals/main/View"

export default () => (
  <Provider>
    <View />
  </Provider>
)
