import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Component */
import Stepper from "components/Stepper"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"

/** Context */
import { CreateUserMachine } from "machines/Dashboard/UnitManagement/createUser"

import GeneralInfo from "pages/Dashboard/UnitManagement/components/Common/UserForm/GeneralInfo"
import PersonalDetails from "pages/Dashboard/UnitManagement/components/Common/UserForm/PersonalDetails"
import UploadImage from "pages/Dashboard/UnitManagement/components/Common/UserForm/UploadImage"
import SupportingDocuments from "pages/Dashboard/UnitManagement/components/Common/UserForm/SupportingDocuments"

/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"

/** Refs */
export const refSubmit = React.createRef<HTMLDivElement>()

const CreateOwnerView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  userDetails,
  handleRegisterUser
}) => {
  const { showMain } = React.useContext(ParentContext)
  const [current, send] = useMachine(CreateUserMachine)
  const xValue = current.value
  const ud = userDetails

  const handleNext = () => {
    // owner residing
    if (
      xValue === "generalInfo" &&
      ud?.isResiding &&
      ud?.formType === "owner" &&
      ud?.blockUnit !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }
    // owner not residing
    if (
      xValue === "generalInfo" &&
      !ud?.isResiding &&
      ud?.formType === "owner" &&
      ud?.blockUnit !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (
      xValue === "generalInfo" &&
      ud?.formType === "tenant" &&
      ud?.blockUnit !== "" &&
      ud?.tenancyStart !== "" &&
      ud?.tenancyEnd !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    /** personal details */

    if (
      xValue === "personalDetails" &&
      ud?.formType === "owner" &&
      ud?.isResident &&
      !ud?.strataTitle &&
      ud?.name !== "" &&
      ud?.phoneNo !== "" &&
      ud?.mobileNo !== "" &&
      ud?.email !== "" &&
      ud?.registeredAddress !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (
      xValue === "personalDetails" &&
      ud?.formType === "owner" &&
      ud?.isResident &&
      ud?.strataTitle &&
      ud?.name !== "" &&
      ud?.phoneNo !== "" &&
      ud?.mobileNo !== "" &&
      ud?.email !== "" &&
      ud?.registeredAddress !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (
      xValue === "personalDetails" &&
      ud?.formType === "owner" &&
      !ud?.isResident &&
      ud?.strataTitle &&
      ud?.name !== "" &&
      ud?.phoneNo !== "" &&
      ud?.mobileNo !== "" &&
      ud?.email !== "" &&
      ud?.registeredAddress !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (
      xValue === "personalDetails" &&
      ud?.formType === "owner" &&
      !ud?.isResident &&
      !ud?.strataTitle &&
      ud?.name !== "" &&
      ud?.phoneNo !== "" &&
      ud?.mobileNo !== "" &&
      ud?.email !== "" &&
      ud?.registeredAddress !== ""
    ) {
      refSubmit.current?.click()
    }

    if (
      xValue === "personalDetails" &&
      ud?.formType === "tenant" &&
      ud?.name !== "" &&
      ud?.phoneNo !== "" &&
      ud?.mobileNo !== "" &&
      ud?.email !== ""
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    /** upload image */
    if (xValue === "uploadImage") {
      send("NEXT")
    }

    /** supporting documents */
    if (xValue === "supportingDocuments") {
      handleRegisterUser()
    }
  }

  const ViewGeneralInfo = (): JSX.Element => {
    if (xValue !== "generalInfo") {
      return <div />
    }
    return <GeneralInfo />
  }

  const ViewPersonalDetails = (): JSX.Element => {
    if (xValue !== "personalDetails") {
      return <div />
    }
    return <PersonalDetails />
  }

  const ViewUploadImage = (): JSX.Element => {
    if (xValue !== "uploadImage") {
      return <div />
    }
    return <UploadImage />
  }

  const ViewSupportingDocuments = (): JSX.Element => {
    if (xValue !== "supportingDocuments") {
      return <div />
    }
    return <SupportingDocuments />
  }

  const CurrentStep = (step: any) => {
    switch (step) {
      case "generalInfo":
        return 1
      case "personalDetails":
        return 2
      case "uploadImage":
        return 3
      case "supportingDocuments":
        return 4
      default:
        break
    }
    return step
  }

  return (
    <>
      <Card>
        <Stepper
          items={[
            "general info",
            "personal details",
            "upload image",
            "supporting documents"
          ]}
          activeStep={CurrentStep(xValue || 1)}
          justifyStart
        />
        <Box>
          <div>
            {ViewGeneralInfo()}
            {ViewPersonalDetails()}
            {ViewUploadImage()}
            {ViewSupportingDocuments()}
          </div>
          <Box margin="30px 0">
            <Footer
              handleNext={() => handleNext()}
              handleBack={xValue === "generalInfo" ? null : () => send("BACK")}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label="Next"
            />
          </Box>
        </Box>
        <Dialog
          action={() => showMain && showMain()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message="Are you sure you want to cancel?"
        />
      </Card>
    </>
  )
}

export default withContext(CreateOwnerView)
