import React from "react"
import Box from "@material-ui/core/Box"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
/**
 * Styles
 */
import styles from "./styles"

const Icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM2 2H16V6H2V2ZM4 3C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4C3 4.26522 3.10536 4.51957 3.29289 4.70711C3.48043 4.89464 3.73478 5 4 5C4.26522 5 4.51957 4.89464 4.70711 4.70711C4.89464 4.51957 5 4.26522 5 4C5 3.73478 4.89464 3.48043 4.70711 3.29289C4.51957 3.10536 4.26522 3 4 3ZM7 3C6.73478 3 6.48043 3.10536 6.29289 3.29289C6.10536 3.48043 6 3.73478 6 4C6 4.26522 6.10536 4.51957 6.29289 4.70711C6.48043 4.89464 6.73478 5 7 5C7.26522 5 7.51957 4.89464 7.70711 4.70711C7.89464 4.51957 8 4.26522 8 4C8 3.73478 7.89464 3.48043 7.70711 3.29289C7.51957 3.10536 7.26522 3 7 3ZM10 3V5H14V3H10ZM2 8H16V16H2V8ZM5.5 9C5.10218 9 4.72064 9.15804 4.43934 9.43934C4.15804 9.72064 4 10.1022 4 10.5C4 10.8978 4.15804 11.2794 4.43934 11.5607C4.72064 11.842 5.10218 12 5.5 12C5.89782 12 6.27936 11.842 6.56066 11.5607C6.84196 11.2794 7 10.8978 7 10.5C7 10.1022 6.84196 9.72064 6.56066 9.43934C6.27936 9.15804 5.89782 9 5.5 9ZM10 10V12H14V10H10ZM5.5 13C4.666 13 3 13.419 3 14.25V15H8V14.25C8 13.419 6.334 13 5.5 13ZM10 13V15H14V13H10Z"
      fill="#646464"
    />
  </svg>
)

interface Props {
  value: number
  handleChange: Function
}

const Application: React.FC<Props> = ({ value, handleChange }) => {
  const { container, title } = styles()

  const selectTab = (event: any, newValue: number) => {
    handleChange(newValue)
  }

  return (
    <Box className={container}>
      <Box className={title}>
        <Box marginRight="20px">{Icon}</Box>
        Application
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={selectTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Today" />
          <Tab label="Next Day" />
          <Tab label="Pending Approval" />
        </Tabs>
      </Box>
    </Box>
  )
}

export default Application
