import React, { useState } from "react"
import Cookies from "js-cookie"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useHistory, withRouter } from "react-router-dom"

/* Redux */
import { setUserDetails } from "redux/user/actions"

/* Service */
import OnboardAPI from "services/Onboard"

/* Context */
export type CtxType = {
  isAuth: boolean
  isLoading: boolean
  login: Function
  logout: Function
  forgotPassword: Function
  resetPassword: Function
  bearer: string
}

export const AuthContext = React.createContext<Partial<CtxType>>({})

/** Notification */
const notifyError = () =>
  toast(`Wrong email and password.`, {
    type: toast.TYPE.ERROR
  })

const notifyForgotPassword = () =>
  toast(`Email has been sent.`, {
    type: toast.TYPE.SUCCESS
  })

const notifyForgotError = (message: string) =>
  toast(`${message}`, {
    type: toast.TYPE.ERROR
  })

const notifyResetPassword = () =>
  toast(`Your password has been reset.`, {
    type: toast.TYPE.SUCCESS
  })

const notifyResetError = (message: string) =>
  toast(`${message}`, {
    type: toast.TYPE.ERROR
  })

const AuthProvider = ({ children }: any) => {
  const dispatch = useDispatch()
  const [isAuth, setIsAuth] = useState(!!Cookies.get("bearer"))
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  async function login(loginDetails: any) {
    setIsLoading(true)

    if (loginDetails.email === "" || loginDetails.pw === "") {
      notifyError()
      setIsLoading(false)
      return null
    }

    try {
      const response = await OnboardAPI.loginUser(loginDetails)
      const data = await response?.data?._data

      if (data === null) {
        notifyError()
        setIsLoading(false)
        return data
      }

      Cookies.set("bearer", data?.access_token)
      Cookies.set("condoUID", data?.user?.condos[0]?.user_role?.condo_uid)

      setIsAuth(true)
      setIsLoading(false)
      dispatch(setUserDetails(data?.user))
      history.push("/property-management/dashboards")
      return response
    } catch (e) {
      setIsLoading(false)
      return e
    }
  }

  function logout() {
    Cookies.remove("bearer")
    Cookies.remove("condoUID")
    setIsAuth(false)
    dispatch(setUserDetails({}))
    history.push("/login")
    window.location.reload(true)
  }

  async function forgotPassword(email: string) {
    try {
      const response = await OnboardAPI.forgotPassword(email)

      if (response?.data?._statusCode === -269) {
        notifyForgotError(response?.data?._message)
      } else {
        notifyForgotPassword()
      }
      return response
    } catch (e) {
      return e
    }
  }

  async function resetPassword(newPassword: string, token: string, email: string) {
    try {
      const response = await OnboardAPI.resetPassword(newPassword, token, email)
      if (response?.data?._statusCode === 1) {
        notifyResetPassword()
      } else {
        notifyResetError(response?.data?._message)
      }
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        login,
        logout,
        forgotPassword,
        resetPassword,
        isLoading,
        bearer: Cookies.get("bearer")
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default withRouter(AuthProvider)
