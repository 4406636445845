export const feedbackConfig: any = {
  status: {
    0: "All",
    1: "Pending",
    2: "In Progress",
    3: "Resolved",
    4: "Cancelled"
  },
  type: {
    1: "Cleanliness",
    2: "Security",
    3: "Defects",
    4: "Landscape",
    5: "Others"
  },
  filter: {
    "All": 0,
    "Pending": 1,
    "In Progress": 2,
    "Resolved": 3,
    "Cancelled": 4
  }
}

export const apiMutateNames: Record<number, string> = {
  0: "fetchAllFeedback",
  1: "fetchCleanlinessFeedback",
  2: "fetchSecurityFeedback",
  3: "fetchDefectsFeedback",
  4: "fetchLandscapeFeedback",
  5: "fetchOthersFeedback"
}

export const { status, type, filter } = feedbackConfig
