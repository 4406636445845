import React, { useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import service from "services/Dashboard/Facilities"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/Facilites/main/Context"
import { AppContext } from "App"
import tableHeaders from "./table/tableHeaders"

const TableMovingInOut: React.FC = () => {
  const { activeMenu, activeFilter } = React.useContext(Context)
  const { condoInfo } = React.useContext(AppContext)
  const [currentPage, setCurrentPage] = useState(0)

  const categoryName = activeMenu?.category_name
  const categoryUID = activeMenu?._uid

  /** Methods */
  const fetchBookings = async () =>
    service.getBookings({
      "facility_category_uid": categoryUID
    })

  const { data, isValidating } = useSWR(
    `fetchBookings-${categoryName}-${activeFilter}`,
    fetchBookings,
    {
      revalidateOnFocus: true
    }
  )

  const filterItem = activeFilter !== undefined && activeFilter

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []

  const filteredData = tableData?.filter((i: any) => {
    return i.status === filterItem
  })

  const displayData = activeFilter === 0 ? tableData : filteredData

  /**
   * View
   */
  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={displayData || []}
          columns={tableHeaders(activeFilter === 2, condoInfo)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          minRows={3}
          isFilterable
          // action={(values: any) => {
          //   return (
          //     setDownloadData &&
          //     setDownloadData(
          //       values?.map((i: any) => {
          //         return {
          //           "Status": i?.status,
          //           "Date Received": i?.dateReceived,
          //           "Reference No.": i?.referenceNumber,
          //           "Block/Unit No.": i?.blockUnitNumber,
          //           "Applicant Name": i?.applicantName,
          //           "Application Date": i?.applicationDate,
          //           "Application Time": i?.applicationTime,
          //           "Purpose": i?.purpose,
          //           "Remarks": i?.remarks,
          //         }
          //       }),
          //     )
          //   )
          // }}
        />
      </div>
    </Fade>
  )
}

export default TableMovingInOut
