export const SET_ENABLE_BACKGROUND_IMAGE =
  "THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE"

export const SET_ENABLE_MOBILE_MENU = "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU"
export const SET_ENABLE_MOBILE_MENU_SMALL =
  "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL"

export const SET_ENABLE_FIXED_HEADER = "THEME_OPTIONS/SET_ENABLE_FIXED_HEADER"
export const SET_ENABLE_HEADER_SHADOW = "THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW"
export const SET_ENABLE_SIDEBAR_SHADOW = "THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW"
export const SET_ENABLE_FIXED_SIDEBAR = "THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR"
export const SET_ENABLE_CLOSED_SIDEBAR = "THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR"
export const SET_ENABLE_FIXED_FOOTER = "THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER"

export const SET_ENABLE_PAGETITLE_ICON = "THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON"
export const SET_ENABLE_PAGETITLE_SUBHEADING =
  "THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING"
export const SET_ENABLE_PAGE_TABS_ALT = "THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT"

export const SET_BACKGROUND_IMAGE = "THEME_OPTIONS/SET_BACKGROUND_IMAGE"
export const SET_BACKGROUND_COLOR = "THEME_OPTIONS/SET_BACKGROUND_COLOR"
export const SET_COLOR_SCHEME = "THEME_OPTIONS/SET_COLOR_SCHEME"
export const SET_BACKGROUND_IMAGE_OPACITY =
  "THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY"

export const SET_HEADER_BACKGROUND_COLOR =
  "THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR"
