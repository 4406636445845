import { createMuiTheme } from "@material-ui/core/styles"

const Main = "#09707B"
const ThemeGreen = "#418A96"
const ThemeGreenLight = "#198C98"
const ThemeGreen100 = "#418A96"
const ThemeSecondary = "#E17E23"
const ThemePink = "#FC766A"
const ThemeGold = "#D8B469"
const ThemeOrange = "#E17E23"
const ThemeBlue = "#0E61A2"
const ThemeBrown = "#A56300"
const ThemeGray = "#777E86"
const ThemeDark = "#454B57"
const COLORWHITE = "#FFFFFF"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Main
    },
    secondary: {
      light: "#0066ff",
      main: ThemeSecondary,
      dark: "#454B57"
    },
    common: {
      black: "#000000",
      white: "#FFFFFF"
    },
    sidebar: {
      main: "#004E8B"
    },
    body: {
      main: "#141414",
      secondary: "#646464",
      dark: "#777E86",
      green: ThemeGreen,
      greenLight: ThemeGreenLight,
      greenLight100: ThemeGreen100,
      pink: ThemePink,
      orange: ThemeOrange,
      gold: ThemeGold,
      blue: ThemeBlue,
      brown: ThemeBrown,
      gray: ThemeGray,
      lightDark: ThemeDark
    }
  },
  typography: {
    fontFamily: "Public Sans, sans-serif"
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: "none",
        borderRadius: "10px"
      }
    },
    MuiButtonGroup: {
      root: {
        width: "100%",
        display: "flex"
      },
      grouped: {
        flex: 1
      }
    },
    MuiRadio: {
      root: {
        color: "rgba(0, 78, 139, 0.6)"
      }
    },
    MuiInputBase: {
      root: {
        minHeight: 40
      },
      input: {
        color: "#777E86",
        fontSize: "14px"
      },
      formControl: {
        marginBottom: "5px"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#646464"
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#777E86"
      }
    },
    MuiInputLabel: {
      root: {
        backgroundColor: "#FFFFFF",
        padding: "0 3px",
        fontSize: "12px"
      },
      outlined: {
        transform: "translate(14px, 20px) scale(1)"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px 14px",
        display: "flex",
        alignItems: "center",
        "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "#000000"
        }
      },
      notchedOutline: {
        "& > legend": {
          maxWidth: 0
        }
      },
      root: {
        minHeight: "56px",
        minWidth: "100%"
      }
    },
    MuiFormControl: {
      root: {
        width: "100%"
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiToolbar: {},
    MuiButton: {
      contained: {
        boxShadow: "none"
      },
      label: {
        textTransform: "capitalize",
        fontWeight: 500
      },
      outlinedPrimary: {
        border: `2px solid ${ThemeGreen}`,
        "&:hover": {
          border: `2px solid ${ThemeGreen}`
        }
      }
    },
    MuiTab: {
      root: {
        color: Main,
        fontWeight: 800,
        minWidth: "auto !important",
        padding: "25px 20px !important",
        "&.Mui-selected": {
          color: `${ThemeSecondary} !important`
        }
      },
      textColorPrimary: {
        color: Main
      },
      textColorInherit: {
        color: Main,
        opacity: 1
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: ThemeSecondary
      }
    },
    MuiSelect: {
      root: {
        fontSize: "14px",
        color: "#646464"
      },
      selectMenu: {
        backgroundColor: COLORWHITE
      },
      outlined: {
        backgroundColor: "rgba(237, 240, 241, 0.2) !important",
        border: "1px solid #EDF0F1",
        height: "34px !important"
      }
    },
    MuiDialogContent: {
      root: {
        ".MuiTab-textColorInherit": {
          backgroundColor: "red !important"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0
      }
    }
    // MuiBox: {
    //   root: {
    //     marginTop: 0,
    //   },
    // },
  }
})

export default theme

// .MuiTab-textColorInherit
