import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    background: "transparent",
    borderRadius: "28px",
    height: "48px",
    border: "1px solid rgba(0,0,0,0)",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "auto",
    marginBottom: "10px",
    "&.active": {
      background: theme.palette.body.orange,
      color: theme.palette.common.white
    },
    "&.default": {
      backgroundColor: "rgba(33, 33, 33, 0.08)",
      border: "1px solid rgba(0, 0, 0, 0.12)"
    },
    "&.Mui-disabled": {
      "& > span > div": {
        color: "rgba(0, 0, 0, 0.3)"
      }
    },
    "&.active.disabled > span > div": {
      color: theme.palette.common.white
    },
    "&:hover.active.disabled": {
      backgroundColor: "rgba(225, 126, 35, 0.5) !important",
      color: theme.palette.common.white
    }
  },
  name: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "20px",
    color: theme.palette.primary.main,
    flex: 1,
    padding: "0 16px",
    textAlign: "center",
    "&.active": {
      color: theme.palette.common.white
    }
  },
  mediumWidth: {
    minWidth: "140px"
  },
  smallWidth: {
    minWidth: "95px"
  },
  largeWidth: {
    minWidth: "180px"
  }
}))

// interface
interface FilterButtonsProps {
  label: string
  onClick: Function
  isActive?: boolean
  hasDefaultColor?: boolean
  style?: any
  disabled?: boolean
  isButton?: boolean
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
  label,
  onClick,
  isActive,
  hasDefaultColor,
  style,
  disabled,
  isButton
}) => {
  const { container, name } = useStyles()

  const disabledClass = `${disabled ? "disabled" : ""}`
  const activeClass = `${isActive ? "active" : "container"}`
  const classes = `${container} ${activeClass} ${disabledClass} ${
    !isActive && hasDefaultColor ? "default" : ""
  }`

  if (isButton) {
    return (
      <Button
        className={classes}
        onClick={() => onClick()}
        style={style}
        disabled={disabled}
      >
        <Box className={`${name} ${activeClass}`}>{label}</Box>
      </Button>
    )
  }

  return (
    <Box className={classes} onClick={() => onClick()} style={style}>
      <Box className={`${name} ${activeClass}`}>{label}</Box>
    </Box>
  )
}

export default FilterButtons
