import React from "react"

const IconVisitor = (): JSX.Element => (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C5.343 0 4 1.343 4 3C4 4.657 5.343 6 7 6C8.657 6 10 4.657 10 3C10 1.343 8.657 0 7 0ZM15 0C13.343 0 12 1.343 12 3C12 4.657 13.343 6 15 6C16.657 6 18 4.657 18 3C18 1.343 16.657 0 15 0ZM7 2C7.551 2 8 2.449 8 3C8 3.551 7.551 4 7 4C6.449 4 6 3.551 6 3C6 2.449 6.449 2 7 2ZM15 2C15.551 2 16 2.449 16 3C16 3.551 15.551 4 15 4C14.449 4 14 3.551 14 3C14 2.449 14.449 2 15 2ZM7 8C4 8 0 9.464 0 11.5V14H13.0781H14H22V11.5C22 9.464 18 8 15 8C13.7159 8 12.2617 8.28294 11.0039 8.75C9.74378 8.28175 8.28671 8 7 8ZM7 10C9.36 10 11.62 11.0256 12 11.5996V12H2V11.5996C2.38 11.0256 4.64 10 7 10ZM15 10C17.36 10 19.62 11.0256 20 11.5996V12H14V11.5C14 11.0182 13.7703 10.57 13.3887 10.1641C13.9083 10.0693 14.4448 10 15 10Z"
      fill="white"
    />
  </svg>
)

const IconDeliveries = (): JSX.Element => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 0C2.57531 9.33959e-05 2.4037 0.0459415 2.25223 0.13298C2.10077 0.220018 1.97475 0.34521 1.88672 0.496094L0.136719 3.49609C0.0473304 3.64899 0.000149602 3.82289 0 4V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V4C17.9999 3.82289 17.9527 3.64899 17.8633 3.49609L16.1133 0.496094C16.0252 0.345211 15.8992 0.220018 15.7478 0.13298C15.5963 0.0459415 15.4247 9.34308e-05 15.25 0H2.75ZM3.32422 2H8V4H2.1582L3.32422 2ZM10 2H14.6758L15.8418 4H10V2ZM2 6H16V16H2V6ZM6 8V10H12V8H6Z"
      fill="white"
    />
  </svg>
)

const IconServiceProviders = (): JSX.Element => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57826 0.0112363C7.29538 0.0254469 7.01 0.054493 6.72279 0.0991269C2.48498 0.759103 -0.329363 4.71528 0.0391928 8.93897C0.196157 10.7497 0.90055 11.828 1.52161 12.7241C2.29781 13.8454 3.00013 14.7635 3.00013 17.2398V19.9995H12.0001V16.9995H14.0001C15.0932 16.9995 16.0001 16.0926 16.0001 14.9995V12.021L17.2267 11.5034C17.7873 11.2674 18.0466 10.4149 17.7247 9.90772L15.9474 7.11866V7.1167C15.4889 2.93103 11.8214 -0.201924 7.57826 0.0112363ZM7.00013 2.0796V7.271C6.69661 7.44624 6.44447 7.69816 6.26896 8.00152C6.09346 8.30489 6.00075 8.64904 6.00013 8.99952C6.00013 9.52995 6.21084 10.0387 6.58592 10.4137C6.96099 10.7888 7.4697 10.9995 8.00013 10.9995C8.1749 10.9994 8.34891 10.9765 8.51771 10.9312L10.004 12.4175C10.002 12.4448 10.0007 12.4721 10.0001 12.4995C10.0001 12.8973 10.1582 13.2789 10.4395 13.5602C10.7208 13.8415 11.1023 13.9995 11.5001 13.9995C11.898 13.9995 12.2795 13.8415 12.5608 13.5602C12.8421 13.2789 13.0001 12.8973 13.0001 12.4995C13.0001 12.1017 12.8421 11.7202 12.5608 11.4389C12.2795 11.1576 11.898 10.9995 11.5001 10.9995C11.4728 11.0001 11.4454 11.0014 11.4181 11.0034L9.92982 9.51514C9.97561 9.34706 9.99925 9.17372 10.0001 8.99952C9.99985 8.64871 9.90731 8.30415 9.73179 8.00041C9.55626 7.69668 9.30394 7.44445 9.00013 7.26905V2.0796C11.6212 2.50885 13.6616 4.61941 13.9591 7.33545L14.0099 7.79835L14.2599 8.19288L15.4611 10.0776L14.0001 10.6948V11.3569V14.9995H10.0001V17.9995H5.00013V17.2398C5.00013 14.328 3.94195 12.7062 3.16615 11.5855C2.54521 10.6895 2.15442 10.1864 2.03138 8.7671V8.76514C1.74888 5.52769 3.84562 2.5883 7.00013 2.0796Z"
      fill="white"
    />
  </svg>
)

const IconPickup = (): JSX.Element => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0C0.45 0 0 0.45 0 1V11C0 11.38 0.210781 11.7206 0.550781 11.8906L2 12.6191V17C2 17.55 2.45 18 3 18H5L5.66992 16H7.06055C7.02055 15.67 7 15.34 7 15C7 14.66 7.02055 14.33 7.06055 14H4V11C4 10.45 4.45 10 5 10H8.75977C9.33977 9.27 10.0398 8.65016 10.8398 8.16016H10.8496C10.8896 8.10016 10.94 8.05 11 8H6V6H12V7.58008C12.63 7.33008 13.3 7.15055 14 7.06055V6C14 4.9 13.1 4 12 4H6C4.9 4 4 4.9 4 6V8.17969C3.02 8.52969 2.27055 9.37016 2.06055 10.4102L2 10.3809V2H16V7.06055C16.7 7.15055 17.37 7.33008 18 7.58008V1C18 0.45 17.55 0 17 0H1ZM15 9C11.7 9 9 11.7 9 15C9 18.3 11.7 21 15 21C18.3 21 21 18.3 21 15C21 11.7 18.3 9 15 9ZM15 11C17.2 11 19 12.8 19 15C19 17.2 17.2 19 15 19C12.8 19 11 17.2 11 15C11 12.8 12.8 11 15 11ZM16.6133 13L14.5254 15.1641L13.3867 13.9844L12.5 14.9023L14.5254 17L17.5 13.918L16.6133 13Z"
      fill="#454B57"
    />
  </svg>
)

const IconAll = (): JSX.Element => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33329 0C1.86263 0 0.666626 1.196 0.666626 2.66667V24H5.99996V21.3333H3.33329V2.66667H16.6666V10.0781C16.8986 10.2155 17.124 10.3645 17.3333 10.5339C17.9133 10.0659 18.5946 9.72731 19.3333 9.52865V2.66667C19.3333 1.196 18.1373 0 16.6666 0H3.33329ZM5.99996 5.33333V8H8.66663V5.33333H5.99996ZM11.3333 5.33333V8H14V5.33333H11.3333ZM5.99996 10.6667V13.3333H8.66663V10.6667H5.99996ZM14 12C13.2927 12 12.6144 12.281 12.1143 12.781C11.6142 13.2811 11.3333 13.9594 11.3333 14.6667C11.3333 15.3739 11.6142 16.0522 12.1143 16.5523C12.6144 17.0524 13.2927 17.3333 14 17.3333C14.7072 17.3333 15.3855 17.0524 15.8856 16.5523C16.3857 16.0522 16.6666 15.3739 16.6666 14.6667C16.6666 13.9594 16.3857 13.2811 15.8856 12.781C15.3855 12.281 14.7072 12 14 12ZM20.6666 12C19.9594 12 19.2811 12.281 18.781 12.781C18.2809 13.2811 18 13.9594 18 14.6667C18 15.3739 18.2809 16.0522 18.781 16.5523C19.2811 17.0524 19.9594 17.3333 20.6666 17.3333C21.3739 17.3333 22.0521 17.0524 22.5522 16.5523C23.0523 16.0522 23.3333 15.3739 23.3333 14.6667C23.3333 13.9594 23.0523 13.2811 22.5522 12.781C22.0521 12.281 21.3739 12 20.6666 12ZM5.99996 16V18.6667H8.66663V16H5.99996ZM14 20C11.0866 20 8.66663 21.2117 8.66663 22.9557V24H15.3333H19.3333H26V22.9557C26 21.2117 23.58 20 20.6666 20C19.4065 20 18.2529 20.237 17.3333 20.6354C16.4137 20.237 15.2601 20 14 20Z"
      fill="white"
    />
  </svg>
)

export const Menu = [
  {
    label: "Delivery",
    icon: IconDeliveries,
    color: "#EC4B19"
  },
  {
    label: "Pickup",
    icon: IconPickup,
    color: "#EC4B19"
  },
  {
    label: "Visitor",
    icon: IconVisitor,
    color: "#057686"
  },
  {
    label: "Service Providers",
    icon: IconServiceProviders,
    color: "#62CB77"
  }
]

const MenuIcons: {
  [index: number]: {}
} = {
  0: IconAll,
  1: IconVisitor,
  2: IconDeliveries,
  3: IconServiceProviders
}

export { MenuIcons }

export default Menu
