import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  section: {
    padding: "20px 40px 10px",
    borderBottom: "1px solid #F2F2F2",
    alignItems: "center",
    display: "flex"
  },
  sectionHead: {
    padding: "30px 40px",
    borderBottom: "3px solid #F2F2F2"
  },
  otherTitle: {
    color: theme.palette.secondary.dark,
    fontSize: "20px"
  }
}))

export default styles
