import * as Yup from "yup"

const validation: Record<any, {}> = {
  documentInfoValidation: Yup.object().shape({
    serialNo: Yup.string().required("Required"),
    title: Yup.string().required("Required")
  }),
  documentUpdateInfoValidation: Yup.object().shape({
    serialNo: Yup.string().required("Required"),
    title: Yup.string().required("Required")
  })
}

export const { documentInfoValidation, documentUpdateInfoValidation } = validation
