/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"
import { Formik } from "formik"
import * as Yup from "yup"

/** Components */
import FormInput from "components/Forms/FormInput"
import Footer from "components/Forms/Footer"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Bookkeeping/main/Context"

interface Props {
  activeView: number
}

const PaymentType: React.FC<Props> = ({ activeView }) => {
  const { paymentTypes, setPaymentTypes, updateBookkeeping } = useContext(Context)
  const pt = paymentTypes[activeView]

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    handleChange: Function,
    setFieldValue: Function,
    target: number
  ) => {
    handleChange(e)
    setFieldValue(name, e?.target?.value)
    setPaymentTypes &&
      setPaymentTypes({
        ...paymentTypes,
        [target]: {
          ...paymentTypes[target],
          [name]: e?.target?.value
        }
      })
  }

  const handleFormSubmit = (isValid: boolean) => {
    if (isValid) {
      updateBookkeeping && updateBookkeeping()
    }
  }

  return (
    <Box>
      <Formik
        initialValues={{
          type_name: pt?.type_name,
          is_enabled: pt?.is_enabled
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          typeName: Yup.string().required("Required")
        })}
      >
        {({
          touched,
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          isValid
        }): JSX.Element => {
          return (
            <form>
              <Box marginBottom="25px">
                <FormInput
                  name="typeName"
                  value={pt?.type_name}
                  idValue="typeName"
                  label="Type Name"
                  placeholder="Type Name"
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(
                      "type_name",
                      e,
                      handleChange,
                      setFieldValue,
                      activeView
                    )}
                  onBlur={handleBlur}
                  error={touched.type_name && Boolean(errors.type_name)}
                  helperText={
                    errors.type_name && touched.type_name && errors.type_name
                  }
                />
              </Box>
              <Box>
                <Checkbox
                  label="Disabled"
                  value={pt?.is_enabled || false}
                  onChange={() =>
                    setPaymentTypes &&
                    setPaymentTypes({
                      ...paymentTypes,
                      [activeView]: {
                        ...paymentTypes[activeView],
                        "is_enabled": !pt?.is_enabled
                      }
                    })}
                />
              </Box>
              <Box marginTop="40px">
                <Footer
                  handleNext={() => handleFormSubmit(isValid)}
                  handleBack={null}
                  handleCancel={() => null}
                  hideCancel
                  label="Submit"
                  stretch
                  noBorder
                />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default PaymentType
