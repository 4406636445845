import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
// import {Link} from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

// component

import Header from "./Header"
import Form from "./Form"

// services

// assets
// import Logo from "../../../../assets/images/Cora_logo_2x.png"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: "412px",
    marginBottom: "40px"
  },
  forgot: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    padding: "0 28px"
  },
  copyright: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  ctaButton: {
    width: "100%"
  }
}))

const SignupCard: React.FC = () => {
  const { container, copyright, ctaButton } = useStyles()
  const [condo, setcondo] = useState("")
  const [keepLoggedIn, setkeepLoggedIn] = useState(false)
  const [signupDetails, setsignupDetails] = useState({
    email: "",
    pw: "",
    "device_id": "web",
    "method_type": "EMAIL_PASSWORD"
  })

  // const handleLogin = () => {
  //   if (cookies) {
  //     OnboardAPI.loginUser(loginDetails)
  //       .then(res => [
  //         setCookie("bearer", res.data._data.access_token),
  //         setloginDetails({
  //           ...loginDetails,
  //           email: "",
  //           pw: "",
  //         }),
  //         history?.push("/property-management"),
  //       ])
  //       .catch((err: any) => err)
  //   }
  // }

  const handleChange = (key: string, value: string): void => {
    setsignupDetails({
      ...signupDetails,
      [key]: value
    })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={container}>
        <Box padding="28px 28px 0">
          <Header />
          <Form
            setcondo={setcondo}
            condo={condo}
            loginDetails={signupDetails}
            setloginDetails={setsignupDetails}
            handleChange={handleChange}
            keepLoggedIn={keepLoggedIn}
            setkeepLoggedIn={setkeepLoggedIn}
          />
        </Box>
        {/* CTA  */}
        <Box display="flex" flexDirection="column" padding="28px">
          <Box marginBottom="30px" flex="1">
            <Button
              // component={Link}
              // to="/property-management"
              variant="contained"
              color="primary"
              size="large"
              // onClick={() => handleLogin()}
              className={ctaButton}
            >
              SIGN UP
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={copyright}>Copyright © CORA 2020</Box>
    </Box>
  )
}

export default SignupCard
