import * as Yup from "yup"

const validation: Record<any, {}> = {
  createPost: Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    shortDescription: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required")
  })
}

export const { createPost } = validation
