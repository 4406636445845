import * as React from "react"

interface ConstructionProps {
  color: string
}

const Construction: React.FC<ConstructionProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0C2.90575 0 2 0.905745 2 2V4H4V2H11V7H16V18H8V20H16C17.0943 20 18 19.0943 18 18V6L12 0H4ZM2 6V7.67188L0 10.4023V12H2V18H0V20H6V18H4V12H12V10.0137L4 7.28516V6H2ZM4 9.39844L5.76367 10H4V9.39844ZM10 13V16H12V13H10Z"
        fill={color}
      />
    </svg>
  )
}

export default Construction
