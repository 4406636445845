/** Menu */
import {
  IconAdmin,
  IconAllEmployees,
  IconCleaner,
  IconLandscape,
  IconMaintenance,
  IconSecurity
} from "components/Icons/Attendance"

const AttendanceConfig: any = {
  MenuFilter: [
    {
      name: "All Employees",
      type: 0,
      color: "#0E61A2",
      icon: IconAllEmployees
    },
    {
      name: "Admin",
      type: 1,
      color: "#A56300",
      icon: IconAdmin
    },
    {
      name: "Security",
      type: 2,
      color: "#D8B469",
      icon: IconSecurity
    },
    {
      name: "Cleaners",
      type: 3,
      color: "#A56300",
      icon: IconCleaner
    },
    {
      name: "Landscape",
      type: 4,
      color: "#3090A6",
      icon: IconLandscape
    },
    {
      name: "Maintenance",
      type: 5,
      color: "#0E61A2",
      icon: IconMaintenance
    }
  ],
  MenuStatus: {
    1: "Present",
    2: "Timed Out"
  }
}

export const { MenuFilter, MenuStatus } = AttendanceConfig
