import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getFeedback(params: Record<string, any>) {
  return request({
    url: API.LIST_FEEDBACK,
    method: "GET",
    headers: headers(),
    params: params
  })
}

function updateApplication(data: Record<string, any>, uid: string) {
  return request({
    url: `${API.UPDATE_APPLICATION}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: data
  })
}

const feedbackSettings = {
  getFeedback,
  updateApplication
}

export default feedbackSettings
