import React, { FunctionComponent } from "react"

import AddStaffProvider, {
  Props
} from "context/Settings/AccountManagement/Staff/forms/staff/Provider"
import AddStaffView from "context/Settings/AccountManagement/Staff/forms/staff/View"

const AddStaff: FunctionComponent<Props> = ({ data }) => (
  <AddStaffProvider data={data}>
    <AddStaffView />
  </AddStaffProvider>
)

export default AddStaff
