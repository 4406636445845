import React from "react"
import Box from "@material-ui/core/Box"

// styles
import styles from "./styles"

interface TabsButtonProps {
  options: Record<any, any>
  activeTab: number
}

const TabsButton: React.FC<TabsButtonProps> = ({ options, activeTab }) => {
  const { btn } = styles()
  return (
    <Box display="flex">
      {Object.keys(options).map((option) => {
        const objLength = Object.keys(options).length
        return (
          <Box
            className={`${btn} ${activeTab === parseInt(option, 0) ? "active" : ""}`}
          >
            {options[parseInt(option, 0)]}
            <Box
              className={`${
                activeTab === parseInt(option, 0) ? `arrow-right` : ""
              } ${objLength > 2 ? `wide` : ""}`}
            />
          </Box>
        )
      })}
    </Box>
  )
}
export default TabsButton
