import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function updateUserInfo(data: Object, uid: string) {
  return request({
    url: `${API.UPDATE_INFO}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

const ProfileSettings = {
  updateUserInfo
}

export default ProfileSettings
