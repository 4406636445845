import React, { useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import ApplicationsAPI from "services/Dashboard/Applications"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/Applications/categories/main/Context"
import tableHeaders from "./table/tableHeaders"

const TableMovingInOut: React.FC = () => {
  const { activeFilter, setDownloadData } = React.useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchApplicationsMovingInOut = async () =>
    ApplicationsAPI.getApplications({
      "application_type": 2
    })

  const { data, isValidating } = useSWR(
    `fetchApplicationsMovingInOut-${activeFilter}`,
    fetchApplicationsMovingInOut,
    {
      revalidateOnFocus: true
    }
  )

  const filterItem = activeFilter !== undefined && activeFilter + 1

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []
  const filteredData = tableData?.filter((i: any) => i.status === filterItem)

  /**
   * View
   */
  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={filteredData || []}
          columns={tableHeaders}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          minRows={3}
          isFilterable
          action={(values: any) => {
            return (
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Status": i?.status,
                    "Date Received": i?.dateReceived,
                    "Reference No.": i?.referenceNumber,
                    "Block/Unit No.": i?.blockUnitNumber,
                    "Applicant Name": i?.applicantName,
                    "Start Date": i?.startDate,
                    "Application Time": i?.applicationTime,
                    "Purpose": i?.purpose,
                    "Remarks": i?.remarks
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TableMovingInOut
