import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"
/** Component */
import Stepper from "components/Stepper"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"
import SelectType from "pages/Dashboard/VisitorManagement/components/AddVisitor/components/SelectType"
import VisitorsInfo from "pages/Dashboard/VisitorManagement/components/AddVisitor/components/VisitorsInfo"
import ResidentsInfo from "pages/Dashboard/VisitorManagement/components/AddVisitor/components/ResidentsInfo"
/** Context */
import withContext from "context/Dashboard/VMS/add-update/withContext"
/** Machine */
import { AddVmsMachine } from "machines/Dashboard/VMS/addVms"
import { StateValue } from "xstate"
import { AddUpdateCtxType } from "./AddUpdateContext"

/** Ref */
export const refSubmit = React.createRef<HTMLDivElement>()

const AddUpdateView: React.FC<AddUpdateCtxType> = ({
  openDialog,
  setOpenDialog,
  showform,
  visitorDetails,
  handleAddVMS,
  submitting,
  bookingAllowed,
  setBookingAllowed
}) => {
  const [isVisitorValid, setVisitorValid] = useState(false)
  const [isDeliveryValid, setDeliveryValid] = useState(false)
  const [isServiceValid, setServiceValid] = useState(false)

  const [current, send] = useMachine(AddVmsMachine)
  const xValue = current.value
  const { byAdmin, unitUID, contactPerson, vmsType } = visitorDetails

  // const canBook =
  //   (eta !== "" && vmsType !== "2" && xValue === "visitorsinfo") ||
  //   (startDate !== "" && vmsType === "2" && xValue === "visitorsinfo")

  const StepperView = ({ value }: any): JSX.Element => {
    const activeStep: Record<string, number> = {
      "selecttype": 1,
      "visitorsinfo": 2,
      "requestersinfo": 3
    }

    return (
      <Stepper
        items={[
          "select vms type",
          "visitors information",
          "requester's information"
        ]}
        activeStep={activeStep[value]}
        justifyStart
      />
    )
  }

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    /** Step 1 */
    if (xValue === "selecttype") {
      send("NEXT")
    }
    /** end of Step 1 */

    /** Step 2 */
    if (xValue === "visitorsinfo" && vmsType === "1" && isVisitorValid) {
      send("NEXT")
    }

    if (xValue === "visitorsinfo" && vmsType === "2" && isDeliveryValid) {
      send("NEXT")
    }

    if (xValue === "visitorsinfo" && vmsType === "3" && isServiceValid) {
      send("NEXT")
    }
    /** end of Step 2 */

    /** Step 3 */
    if (xValue === "requestersinfo") {
      if (byAdmin) {
        handleAddVMS().then(() => showform())
      }

      if (!byAdmin && unitUID !== "" && contactPerson !== "") {
        handleAddVMS().then(() => showform())
      }

      refSubmit.current?.click()
    }
    /** end of Step 3 */
  }

  function getStepContent(step: StateValue): JSX.Element {
    switch (step) {
      case "selecttype":
        return <SelectType />
      case "visitorsinfo":
        return (
          <VisitorsInfo
            setVisitorValid={setVisitorValid}
            setDeliveryValid={setDeliveryValid}
            setServiceValid={setServiceValid}
          />
        )
      case "requestersinfo":
        return <ResidentsInfo />
      default:
        return <div />
    }
  }

  return (
    <>
      <Card>
        <StepperView value={current.value} />
        <Box>{getStepContent(xValue)}</Box>
        <Box margin="30px 0">
          <Footer
            handleNext={() => handleNext()}
            handleBack={xValue === "selecttype" ? null : () => send("BACK")}
            handleCancel={() => setOpenDialog && setOpenDialog(true)}
            label={submitting ? "Submitting..." : "Next"}
            disabled={submitting}
          />
        </Box>
      </Card>
      <Dialog
        action={() => showform()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="OK"
        title=""
        message="Are you sure you want to cancel?"
      />

      {/* {canBook && ( */}
      <Dialog
        action={(): null => null}
        isOpen={!bookingAllowed}
        setOpen={() => setBookingAllowed(true)}
        actionLabel="OK"
        title=""
        message="Application disallowed according to Settings. Do you still want to proceed with Application?"
        // message={allowedTimeMessage}
      />
      {/* )} */}
    </>
  )
}

export default withContext(AddUpdateView)
