import React from "react"

import VMSAllProvider, {
  VMSAllProviderProps
} from "context/Dashboard/VMS/categories/vmsAll/Provider"
import VMSAllView from "context/Dashboard/VMS/categories/vmsAll/View"

const VMSAllProviderMain: React.FC<VMSAllProviderProps> = ({
  showAddForm
}): JSX.Element => (
  <VMSAllProvider showAddForm={showAddForm}>
    <VMSAllView />
  </VMSAllProvider>
)

export default VMSAllProviderMain
