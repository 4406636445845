import { Machine } from "xstate"

export const CreatePublicDocumentsMachine = Machine({
  id: "addApplication",
  initial: "documentInfo",
  states: {
    documentInfo: {
      on: {
        NEXT: "documentUpload"
      }
    },
    documentUpload: {
      on: {
        BACK: "documentInfo"
      }
    }
  }
})
