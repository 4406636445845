import React from "react"

export type CtxType = {
  paymentDetails: Record<string, any>
  openDialog: boolean
  submitting: boolean
  setOpenDialog: Function
  setPaymentDetails: Function
  handleCreatePayment: Function
  showPayment: Function
  setShowPayment: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
