import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

/** Context */
import Service from "services/Dashboard/Applications"
import serviceSettings from "services/Settings/PropertyManagement/Applications"
import { dateAdvance, dateSameOrAfter, isExpired } from "utils/date"
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  /** States */
  const [activeFilter, setActiveFilter] = useState(0)
  const [activeTable, setActiveTable] = useState(0)
  const [activeData, setActiveData] = useState({})
  const [showReject, setShowReject] = useState(false)
  const [remarks, setRemarks] = useState("")
  const [isSubmitting, setSubmitting] = useState(false)

  const [settings, setSettings] = useState({})
  const [canCancel, setCanCancel] = useState(true)

  /** Notification */
  const notifyRejected = () =>
    toast("Successfully rejected the application", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const fetchSettings = async () => {
    try {
      const response = await serviceSettings.getApplication()
      const data = response?.data._data
      const obj: any = {}
      await data?.map((d: Record<string, any>) => {
        obj[d?.application_type] = {
          ...d,
          allowedDate:
            d?.advance_booking_days !== 0
              ? dateAdvance(d?.advance_booking_days)
              : null,
          allowedCancellation:
            d?.cancel_booking_days !== 0 ? dateAdvance(d?.cancel_booking_days) : null
        }
      })
      setSettings(obj)
      return obj
    } catch (e) {
      return e
    }
  }

  const checkCancel = (settingsItem: Record<string, any>) => {
    return setCanCancel(
      dateSameOrAfter(new Date(), settingsItem?.allowedCancellation)
    )
  }

  const checkExpired = (etams: number) => {
    return isExpired(etams)
  }

  const handleRejectItem = async (dataUID: string) => {
    const itemPayload = {
      "_uid": dataUID,
      "rejected_remarks": remarks
    }
    setSubmitting(true)

    try {
      const response = await Service.rejectApplication(itemPayload)
      setSubmitting(false)
      setRemarks("")
      notifyRejected()
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  useEffect(() => {
    fetchSettings().then((obj: Record<string, any>) =>
      checkCancel(obj[activeTable + 1])
    )
  }, [activeTable])

  return (
    <Context.Provider
      value={{
        activeTable,
        activeFilter,
        activeData,
        showReject,
        checkExpired,
        canCancel,
        remarks,
        isSubmitting,
        settings,
        setCanCancel,
        setActiveTable,
        setActiveData,
        setShowReject,
        setRemarks,
        handleRejectItem,
        setActiveFilter
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
