import React from "react"

export type CtxType = {
  handleFormSubmit: Function
  setResetData: Function
  setUserPassword: Function
  userPassword: string
  resetData: Record<string, any>
  isSubmitting: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
