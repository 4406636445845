/**
 * Utils
 */
import { fromUnix } from "utils/date"
import { checkDate } from "utils/helpers"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "S/No.",
        accessor: "serial_no",
        filterable: false,
        sortable: false,
        width: 120
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        sortable: false
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        sortable: false
      },
      {
        Header: "Expiry Date",
        id: "date",
        accessor: (c: any) => checkDate(c?.end_date, fromUnix(c?.end_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.end_date, fromUnix(original?.end_date)),
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
