import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    overflow: "hidden",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    backgroundColor: "#F1F2F3"
  },
  tabItem: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    padding: "0 50px",
    height: "75px",
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  }
}))

interface StepperProps {
  items: Array<string>
  activeStep?: number
}

const StepperTabs: React.FC<StepperProps> = ({ items, activeStep }) => {
  const { container, tabItem } = styles()
  return (
    <Box display="flex" className={container}>
      {items.map((item: string, index: number) => {
        const itemNum = index + 1
        const isActive = itemNum === activeStep
        const itemClass = `${tabItem} ${isActive ? "active" : ""}`
        return <div className={itemClass}>{item}</div>
      })}
    </Box>
  )
}

export default StepperTabs
