import useSWR from "swr"
import service from "services/Dashboard/Reports"

export function TotalTenantedUnitsHook() {
  const fetchTotalTenantedUnits = async () => service.getTenantedUnits()

  const { data, isValidating, error } = useSWR(
    `fetchTotalTenantedUnits`,
    fetchTotalTenantedUnits,
    {
      revalidateOnFocus: false
    }
  )
  const totalTenantedUnits = data?.data?._data?.total

  return {
    totalTenantedUnits,
    isValidating,
    error
  }
}

export function TotalUnitsHook() {
  const fetchTotalUnits = async () => service.getTotalUnits()

  const { data, isValidating, error } = useSWR(`fetchTotalUnits`, fetchTotalUnits, {
    revalidateOnFocus: false
  })
  const totalUnits = data?.data?._data?.total

  return {
    totalUnits,
    isValidating,
    error
  }
}
