import useSWR from "swr"
import service from "services/Dashboard/Reports"

export function TotalVehiclesHook() {
  const fetchTotalVehicles = async () => service.getTotalVehicles()

  const { data, isValidating, error } = useSWR(
    `fetchTotalVehicles`,
    fetchTotalVehicles,
    {
      revalidateOnFocus: false
    }
  )
  const totalVehicles = data?.data?._data?.total

  return {
    totalVehicles,
    isValidating,
    error
  }
}
