import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import UploadBox from "components/UploadBox/new"

/** Context */
import withContext from "context/Settings/Others/UploadCSV/main/withContext"
import { CtxType } from "context/Settings/Others/UploadCSV/main/Context"

const UploadMain: React.FC<CtxType> = ({
  handleImageUpload,
  photos,
  removePhoto,
  attaching
}) => {
  return (
    <Box padding="60px 30%">
      <UploadBox
        onDrop={handleImageUpload}
        files={photos}
        removePhoto={removePhoto}
        attaching={attaching}
      />
    </Box>
  )
}

export default withContext(UploadMain)
