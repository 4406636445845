import * as React from "react"

const Landscape = (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8071 3.186L16.9506 2.332C16.5048 1.889 15.784 1.889 15.3382 2.332L15.2153 2.452V2C15.2153 0.895 14.3422 0 13.2643 0C12.1864 0 11.3133 0.895 11.3133 2V2.453L11.1895 2.333C10.7437 1.89 10.0228 1.89 9.57797 2.333L8.7215 3.187C8.27571 3.63 8.27571 4.349 8.7215 4.793L8.95562 5.026C7.97039 5.637 7.36852 6.606 7.00466 7.657C5.97554 6.216 4.27333 5.057 1.57906 5L0.583099 4.979V6C0.583099 8.801 1.7527 10.73 3.19835 12H0.583099V14H1.55858L2.39554 18.394C2.57892 19.324 3.38369 20 4.30942 20H10.5135C11.4402 20 12.2449 19.324 12.4273 18.393L13.2458 14.197L13.2487 14H14.2398V12H8.38691C8.38691 8.912 8.9244 7.248 10.1681 6.643C9.895 7.455 10.215 8.372 10.9631 8.791C11.7884 9.252 12.8146 8.926 13.2555 8.063L13.2877 7.998C13.3062 7.998 13.3238 7.993 13.3414 7.992L13.4028 8.1C13.8262 8.929 14.8104 9.242 15.6025 8.799C16.3956 8.356 16.6941 7.326 16.2707 6.498L16.0083 6.013C16.0122 6.002 16.0151 5.991 16.0181 5.98C16.3439 6.01 16.6785 5.916 16.9292 5.668L17.809 4.793C18.2529 4.348 18.2529 3.63 17.8071 3.186ZM6.42035 11.673C5.09468 11.152 3.0491 9.906 2.61697 7.108C5.81263 7.709 6.33549 10.623 6.42035 11.673ZM11.2938 14L10.5125 18H4.30845L3.52806 14H11.2938ZM13.2643 6C12.7258 6 12.2888 5.552 12.2888 5C12.2888 4.448 12.7258 4 13.2643 4C13.8028 4 14.2398 4.448 14.2398 5C14.2398 5.552 13.8028 6 13.2643 6Z"
      fill="#454B57"
    />
  </svg>
)

export default Landscape
