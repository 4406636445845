import React from "react"

import FeedbackCleanlinessProvider from "context/Dashboard/Feedback/categories/cleanliness/Provider"
import FeedbackCleanlinessView from "context/Dashboard/Feedback/categories/cleanliness/View"

const FeedbackCleanlinessProviderMain: React.FC = (): JSX.Element => (
  <FeedbackCleanlinessProvider>
    <FeedbackCleanlinessView />
  </FeedbackCleanlinessProvider>
)

export default FeedbackCleanlinessProviderMain
