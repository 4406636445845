import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"
/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import FilterWithInfo from "components/FilterButtons/FilterWithInfo"
import LinkButton from "components/LinkButton"
import AddFeedback from "pages/Dashboard/Feedback/components/AddFeedback"
import EditFeedback from "pages/Dashboard/Feedback/components/EditFeedback"
import Reply from "pages/Dashboard/Feedback/components/Reply"
import Details from "pages/Dashboard/Feedback/components/Details"
import { MenuIcons } from "pages/Dashboard/Feedback/config/Menu"
import FeedbackMain from "pages/Dashboard/Feedback/categories/main"
import PermissionMessage from "components/PermissionMessage"

/** Context */
import { FeedbackMachine } from "machines/Dashboard/Feedback"
import { AppContext } from "App"
import withContext from "./withContext"
import { CtxType } from "./FeedbackContext"
/** Machines */

const FilterMenu = [
  {
    name: "All Feedback",
    type: 0,
    info: "10 new",
    color: "#004E8B",
    icon: MenuIcons.all
  },
  {
    name: "Cleanliness",
    type: 1,
    info: "10 new",
    color: "#007B83",
    icon: MenuIcons.cleanliness
  },
  {
    name: "Security",
    type: 2,
    info: "10 new",
    color: "#D8B469",
    icon: MenuIcons.security
  },
  {
    name: "Defects",
    type: 3,
    info: "10 new",
    color: "#D05611",
    icon: MenuIcons.defects
  },
  {
    name: "Landscape",
    type: 4,
    info: "10 new",
    color: "#D05611",
    icon: MenuIcons.landscape
  },
  {
    name: "Others",
    type: 5,
    info: "10 new",
    color: "#D05611",
    icon: MenuIcons.others
  }
]

const Feedback: React.FC<CtxType> = ({
  filterData,
  activeTable,
  activeItem,
  setActiveStatus,
  activeStatus,
  tabStatus,
  setTabStatus
}) => {
  const [current, send] = useMachine(FeedbackMachine)
  const xValue = current?.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const FilterView = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }
    return (
      <Box display="flex" flexWrap="wrap" padding="20px 0">
        {FilterMenu.map((item: any) => {
          return (
            <FilterWithInfo
              type={item.type}
              name={item.name}
              color={item.color}
              icon={item.icon}
              action={() => filterData(item.type)}
              hasViewAll
            />
          )
        })}
      </Box>
    )
  }

  const ViewPageTitle = (): JSX.Element => {
    if (xValue === "tableView") {
      return (
        <PageHeaderTitle
          title="Feedback"
          breadcrumbs="property management / feedback"
        />
      )
    }

    if (xValue === "addFeedback") {
      return (
        <PageHeaderTitle
          title="Add Feedback"
          breadcrumbs="property management / feedback/ add feedback"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editFeedback") {
      return (
        <PageHeaderTitle
          title="Edit Feedback"
          breadcrumbs="property management / feedback/ edit feedback"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "addReply") {
      return (
        <PageHeaderTitle
          title="Add Reply"
          breadcrumbs="property management / feedback/ add Reply"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "details") {
      return (
        <PageHeaderTitle
          title="Details"
          breadcrumbs="property management / feedback/ details"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    return <div />
  }

  const AddFeedbackView = (): JSX.Element => {
    if (xValue !== "addFeedback") {
      return <div />
    }

    return <AddFeedback showForm={() => send("TABLE_VIEW")} />
  }

  const EditFeedbackView = (): JSX.Element => {
    if (xValue !== "editFeedback") {
      return <div />
    }

    return (
      <EditFeedback showForm={() => send("TABLE_VIEW")} activeItem={activeItem} />
    )
  }

  const ReplyView = (): JSX.Element => {
    if (xValue !== "addReply") {
      return <div />
    }
    return (
      <Reply
        showForm={() => send("TABLE_VIEW")}
        isCreate={false}
        data={activeItem}
      />
    )
  }

  const ViewSettingBtn = (): JSX.Element => {
    if (xValue === "addFeedback" || xValue === "addReply" || xValue === "details") {
      return <div />
    }

    return (
      <LinkButton
        name="FEEDBACK SETTINGS"
        url={
          permission?.manage_condo_settings
            ? "/settings/property-management/feedbacks"
            : "#"
        }
      />
    )
  }

  const ViewDetails = (): JSX.Element => {
    if (xValue !== "details") {
      return <div />
    }
    return <Details showForm={() => send("TABLE_VIEW")} data={activeItem} />
  }

  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <FeedbackMain
        showAddForm={() => send("ADD_FEEDBACK")}
        showEditForm={() => send("EDIT_FEEDBACK")}
        showReplyForm={() => send("ADD_REPLY")}
        showDetails={() => send("DETAILS")}
        activeTable={activeTable}
        setActiveStatus={setActiveStatus}
        activeStatus={activeStatus}
        tabStatus={tabStatus}
        filterTabStatus={setTabStatus}
      />
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <div>
        <WithHeader>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <ViewPageTitle />
            </Box>
            <Box>
              <ViewSettingBtn />
            </Box>
          </Box>
          <Box>{FilterView()}</Box>
          <Box>
            {ViewTable()}
            <AddFeedbackView />
            <EditFeedbackView />
            <ReplyView />
            <ViewDetails />
          </Box>
        </WithHeader>
      </div>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.feedback_view) {
    return <div>{MainView()}</div>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(Feedback)
