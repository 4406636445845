import React from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Button from "@material-ui/core/Button"
import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  tabContainer: {
    "& .active": {
      color: theme.palette.secondary.main
    },
    "& button": {
      padding: "25px 20px !important",
      overflow: "hidden",
      position: "relative",
      fontSize: "0.875rem",
      maxWidth: "264px",
      minWidth: "auto !important",
      boxSizing: "border-box",
      minHeight: "48px",
      textAlign: "center",
      flexShrink: 0,
      lineHeight: "1.75",
      whitespace: "normal"
    },
    "& span": {
      fontWeight: "800",
      textTransform: "uppercase"
    }
  }
}))

interface TabsWithRefProps {
  value: string | number
  handleChange: Function
  options: Record<string, any>
  filterRef?: any
}

const TabsWithRef: React.FC<TabsWithRefProps> = ({
  value,
  handleChange,
  options,
  filterRef
}) => {
  const { tabContainer } = styles()

  const tabChange = (event: any, newValue: string | number) => {
    handleChange(newValue)
  }

  return (
    <Tabs value={value} onChange={tabChange} className={tabContainer}>
      {Object.keys(options).map((item: string, index: number) => {
        return (
          <Tab
            label={item}
            component={() => (
              <Button
                color="primary"
                className={`${value === index ? "active" : "inactive"}`}
                ref={filterRef && filterRef[index]}
                onClick={() => tabChange(null, index)}
              >
                {options[item]}
              </Button>
            )}
          />
        )
      })}
    </Tabs>
  )
}
export default TabsWithRef
