const version = "v1"
const base = "svc-application"

const API = {
  LIST_VEHICLES: `${base}/${version}/vehicles`,
  LIST_ACCESS_CARDS: `${base}/${version}/access-cards`,
  LIST_APPLICATIONS: `${base}/${version}/applications`,
  APPROVE_APPLICATION: `${base}/${version}/application/approve`,
  REJECT_APPLICATION: `${base}/${version}/application/reject`,
  CREATE_APPLICATION: `${base}/${version}/application`,
  UPDATE_APPLICATION: `${base}/${version}/application`,
  DELETE_APPLICATION: `${base}/${version}/application`,
  CANCEL_APPLICATION: `${base}/${version}/application/cancel`,
  CREATE_VEHICLE: `${base}/${version}/vehicle`,
  UPDATE_VEHICLE: `${base}/${version}/vehicle`,
  DELETE_VEHICLE: `${base}/${version}/vehicle`,
  CREATE_ACCESS_CARD: `${base}/${version}/access-card`,
  UPDATE_ACCESS_CARD: `${base}/${version}/access-card`,
  DELETE_ACCESS_CARD: `${base}/${version}/access-card`,
  ARCHIVED_VEHICLE: `${base}/${version}/archive-vehicle`,
  ARCHIVED_ACCESS_CARD: `${base}/${version}/archive-access-card`
}

export default API
