import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "60%",
    margin: "20px auto"
  },
  section: {
    margin: "0 auto 135px auto"
  },

  title: {
    fontSize: "16px",
    color: theme.palette.secondary.dark,
    paddingBottom: "20px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
      maxWidth: "48%"
    }
  }
}))

export default styles
