import React, { useState } from "react"

/** Context */
import { displayDate, toUnix, toUnixTomorrow } from "utils/date"
import { Context } from "./Context"
/** Utils */

const Provider: React.FC = ({ children }) => {
  const [viewSettings, setViewSettings] = useState({
    applications: true,
    vms: true,
    newAccounts: true,
    paymentsToday: true,
    newFeedback: true,
    attendance: true
  })

  const [dateRange] = useState({
    startDate: toUnix(displayDate(new Date(), "YYYY-MM-DD 00:00:00")),
    endDate: toUnix(displayDate(new Date(), "YYYY-MM-DD 23:59:59"))
  })

  const [dateOptions] = useState({
    currentDate: displayDate(new Date(), "MMMM DD, YYYY"),
    currentDateUnix: toUnix(displayDate(new Date(), "MMMM DD, YYYY")),
    tomorrowDate: toUnixTomorrow(displayDate(new Date(), "MMMM DD, YYYY"), 1)
  })

  return (
    <Context.Provider
      value={{
        dateRange,
        dateOptions,
        viewSettings,
        setViewSettings
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
