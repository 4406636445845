import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Components */
import Dialog from "components/Dialog"

/** Context */
import { Context as CategoriesMainCtx } from "context/Dashboard/UnitManagement/categories/main/Context"
import { Context as OwnersCtx } from "context/Dashboard/UnitManagement/categories/owners/Context"
import { Context as ResidentsCtx } from "context/Dashboard/UnitManagement/categories/residents/Context"
import { CtxMenuContext } from "components/ContextMenu"
import { AppContext } from "App"
import { IconEdit, IconView, IconDelete } from "components/Icons/ContextMenu"
import styles from "./styles"

interface ContextOwnerProps {
  data: Record<string, any>
  endpoint?: string
}

const ContextBookKeeping: React.FC<ContextOwnerProps> = ({ data, endpoint }) => {
  const { listItem } = styles()
  const { permission } = useContext(AppContext)
  const { openContext } = useContext(CtxMenuContext)
  const [openDialog, setOpenDialog] = useState(false)
  const canEdit = permission?.unit_mgt_edit

  const {
    showDetails,
    showEditOwner,
    showEditResident,
    handleArchived
  } = React.useContext(CategoriesMainCtx)

  const Owners = React.useContext(OwnersCtx)
  const Residents = React.useContext(ResidentsCtx)

  /** Methods */
  function handleEdit(dataItems: Record<string, any>): void {
    const type = dataItems?.owner_or_tenant
    Owners.setActiveData && Owners.setActiveData(dataItems)
    Residents.setActiveData && Residents.setActiveData(dataItems)

    if (type === 1) {
      showEditOwner && showEditOwner()
    }

    if (type === 2) {
      showEditResident && showEditResident()
    }
  }

  function handleDialog() {
    setOpenDialog(true)
  }

  function handleArchivedItem(itemId: string) {
    openContext && openContext(false)
    handleArchived && handleArchived(itemId, endpoint)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => showDetails && showDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={(): void | null => (!canEdit ? null : handleEdit(data))}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={(): void | null => (!canEdit ? null : handleDialog())}
        >
          <i>{IconDelete}</i>
          Archived
        </ListItem>
      </List>
      <Dialog
        action={() => handleArchivedItem(data?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the status?"
      />
    </>
  )
}

export default ContextBookKeeping
