import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Icon
 */
import { IconUsers } from "components/Icons/Dashboard"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Hooks
 */
import { TotalRegisteredUsersHook } from "./hooks"

const TotalRegisteredUsers: React.FC = () => {
  const classes = styles()
  const { totalRegisteredUsers, isValidating } = TotalRegisteredUsersHook()

  function ViewTotal(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    return (
      <Fade timeout={1000} in>
        <Box className={classes.info}>
          <Box className={classes.total}>{totalRegisteredUsers}</Box>
        </Box>
      </Fade>
    )
  }

  return (
    <Card>
      <Box className={classes.header}>
        <CardTitle title="Total Registered Users" icon={IconUsers} />
      </Box>
      <ViewTotal />
    </Card>
  )
}

export default TotalRegisteredUsers
