import React from "react"

const Defects = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71484 0.300781L5.28516 1.69922L6.67773 3.12109C6.26006 3.63656 6 4.28478 6 5C6 5.05372 6.01284 5.08581 6.01562 5.13477C5.53027 5.27344 5.07393 5.51639 4.70312 5.88672C4.35657 6.23233 4.0417 6.60771 3.75 7H0V9H2.61523C2.35282 9.64091 2.16435 10.3104 2.07227 11H0V13H2.04688C2.10421 13.6954 2.2164 14.3674 2.39844 15H0V17H3.27734C3.41331 17.2163 3.55964 17.4261 3.71875 17.625C4.90159 19.1035 6.75 20 9 20C11.25 20 13.0984 19.1035 14.2812 17.625C14.4404 17.4261 14.5867 17.2163 14.7227 17H18V15H15.6016C15.7837 14.3671 15.8986 13.6962 15.9551 13H18V11H15.9199C15.8248 10.3235 15.6371 9.65172 15.3691 9H18V7H14.2422C13.9536 6.60716 13.6418 6.23066 13.2969 5.88672C12.9261 5.51639 12.4697 5.27344 11.9844 5.13477C11.9872 5.08581 12 5.05372 12 5C12 4.28478 11.7399 3.63656 11.3223 3.12109L12.7148 1.69922L11.2852 0.300781L9.56641 2.05664C9.38288 2.02156 9.19376 2 9 2C8.80624 2 8.61712 2.02156 8.43359 2.05664L6.71484 0.300781ZM6.87305 7H11.127C11.4217 7 11.6951 7.11111 11.8848 7.30078V7.30273C13.1595 8.57402 14 10.4948 14 12C14 13.8333 13.5359 15.3535 12.7188 16.375C12.0695 17.1865 11.2038 17.7291 10 17.9199V9H8V17.9199C6.79619 17.7291 5.93048 17.1865 5.28125 16.375C4.46409 15.3535 4 13.8333 4 12C4 10.3157 4.8157 8.59872 6.11523 7.30273V7.30078C6.3049 7.11111 6.57833 7 6.87305 7Z"
      fill="#454B57"
    />
  </svg>
)

export default Defects
