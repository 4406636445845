import * as Yup from "yup"

export const resetPassword: Record<any, any> = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password should be 8 characters minimum."),
  confirmPassword: Yup.string()
    .min(8, "Password should be 8 characters minimum.")
    .when("newPassword", {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same"
      )
    })
})

export const forgotPassword: Record<any, any> = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
})

const validation = {
  resetPassword,
  forgotPassword
}

export default validation
