import React from "react"

export type CtxType = {
  paymentDetails: Record<string, any>
  data: Record<string, any>
  openDialog: boolean
  showPayment: boolean
  submitting: boolean
  setOpenDialog: Function
  setPaymentDetails: Function
  handleCancelPayment: Function
  setShowPayment: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
