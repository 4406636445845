import React from "react"

export type CtxType = {
  activeTable: number
  accountRequestsTab: number
  docsFilters: any
  activeDoc: any
  filterTabs: any
  activeData: any
  showDocument: any
  downloadData: Array<{}>
  setShowDocument: Function
  setActiveData: Function
  setActiveTable: Function
  setAccountRequestsTab: Function
  setDocsFilter: Function
  setActiveDoc: Function
  selectTab: Function
  deleteDocument: Function

  setDownloadData: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
