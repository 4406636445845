import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Components
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Icon
 */
import { IconPayments } from "components/Icons/Dashboard"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
import { AppContext } from "App"
/**
 * Hooks
 */
import { paymentCategory } from "config/Dashboard/Bookkeeping"
import { OutstandingPaymentHook } from "./hooks"
/**
 * Config
 */
/**
 * Styles
 */
import styles from "./styles"

const OutstandingPayments: React.FC = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const { condoInfo } = useContext(AppContext)
  const currency = condoInfo?.currency
  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`
  const {
    amount,
    facilities,
    applications,
    maintenance,
    scheduled,
    others,
    isValidating
  } = OutstandingPaymentHook(selectedDate)

  const PaymentValues: any = {
    "Facilities": facilities,
    "Applications": applications,
    "Maintenance": maintenance,
    "Scheduled": scheduled,
    "Others": others
  }

  function ViewHeader(): JSX.Element {
    return (
      <Box className={classes.header}>
        <CardTitle
          title="Outstanding Payments"
          subtitle={currentReport}
          icon={IconPayments}
        />
      </Box>
    )
  }

  function ViewTotal(): JSX.Element {
    return (
      <Box className={classes.totalAmount}>
        <Box className={classes.totalStyle}>
          <span>{currency}</span>
          {amount}
        </Box>
      </Box>
    )
  }

  function ViewBreakdown(): JSX.Element {
    return (
      <Box>
        {Object.keys(paymentCategory).map((item: any) => {
          return (
            <Box className={classes.breakdown}>
              <Box className={classes.timelineTitle}>{paymentCategory[item]}</Box>
              <Box className={classes.timelineSubtitle}>
                {PaymentValues[paymentCategory[item]]}
              </Box>
              <Box className={classes.dot} />
            </Box>
          )
        })}
      </Box>
    )
  }

  function ViewContent(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    return (
      <Fade in timeout={1000}>
        <Box>
          <ViewTotal />
          <Box className={classes.content}>
            <ViewBreakdown />
          </Box>
        </Box>
      </Fade>
    )
  }

  return (
    <Card>
      <Box>
        <ViewHeader />
        <ViewContent />
      </Box>
    </Card>
  )
}

export default OutstandingPayments
