import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  formContainer: {
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "30px"
  },
  formField: {
    marginBottom: "25px"
  }
}))

export default styles
