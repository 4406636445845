import React, { useMemo, useState, useEffect } from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import { Formik } from "formik"
/** Components */
import Names from "components/Common/Names"
import BlockUnit from "components/Common/BlockUnit/new"
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
/** Ref */
import { refSubmit } from "context/Dashboard/Facilites/forms/addBooking/View"
/** Validation */
import {
  bookingDetailsAdmin as bdAdmin,
  bookingDetails as bdDefault
} from "config/Dashboard/Facilities/validation"
/** Styles */
import {
  handleResidentName,
  handleBlockUnit,
  handleFormChange
} from "utils/functions"
import styles from "./styles"
/** helpers */

interface Props extends CtxType {
  setValid: Function
  setBookingDetails: Function
  bookingDetails: Record<string, any>
  hasData: boolean
}

const GeneralInfo: React.FC<Props> = ({
  setBookingDetails,
  bookingDetails,
  setValid,
  hasData
}) => {
  const classes = styles()
  const bd = bookingDetails

  const validation = bd.forAdmin ? bdAdmin : bdDefault
  const [isInitialValid, setIsInitialValid] = useState(false)

  const defaultValues = {
    unitUID: bd.unitUID,
    bookingByName: bd.bookingByName,
    forAdmin: bd.forAdmin,
    remarks: bd.remarks
  }

  useEffect(() => {
    validation
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false))
  }, [defaultValues])

  return (
    <div className={classes.container}>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return defaultValues
        }, [])}
        onSubmit={(values: any) => {
          return values
        }}
        validationSchema={validation}
        isInitialValid={isInitialValid}
      >
        {({
          touched,
          errors,
          isValid,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <FormWrapper width="40%">
                <Box width="40%" margin="auto">
                  <Box margin="auto">
                    <Box className={classes.spaceBottom}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={hasData}
                              checked={bd.forAdmin}
                              onChange={() =>
                                handleFormChange(
                                  setFieldValue,
                                  setBookingDetails,
                                  bookingDetails,
                                  "forAdmin",
                                  !bd.forAdmin
                                )}
                              value={bd.forAdmin}
                              color="primary"
                            />
                          }
                          label="Book for Admin"
                        />
                      </FormGroup>
                    </Box>
                  </Box>

                  {!bd.forAdmin && (
                    <Box margin="auto">
                      <Box className={classes.spaceBottom}>
                        <BlockUnit
                          disabled={hasData}
                          iValue={bd.unitUID}
                          onChange={(value: string) =>
                            handleBlockUnit(
                              setFieldValue,
                              value,
                              setBookingDetails,
                              bookingDetails,
                              "unitUID",
                              "bookingByAccountUID"
                            )}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.unitUID && Boolean(errors.unitUID)}
                          helperText={
                            errors.unitUID &&
                            touched.unitUID &&
                            String(errors.unitUID)
                          }
                        />
                      </Box>

                      <Box className={classes.spaceBottom}>
                        <Names
                          disabled={hasData}
                          value={bd.bookingByName}
                          onChange={(value: Event) =>
                            handleResidentName(
                              setFieldValue,
                              value,
                              setBookingDetails,
                              bookingDetails,
                              "bookingByName",
                              "bookingByAccountUID",
                              "bookingByUserDetailUID"
                            )}
                          unitUID={bd.unitUID}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.bookingByName && Boolean(errors.bookingByName)
                          }
                          helperText={
                            errors.bookingByName &&
                            touched.bookingByName &&
                            String(errors.bookingByName)
                          }
                        />
                      </Box>
                    </Box>
                  )}

                  <Box className={classes.spaceBottom}>
                    <FormInput
                      label="Remarks"
                      placeholder="Remarks"
                      value={bd.remarks}
                      handleOnChange={(e: React.ChangeEvent) =>
                        handleFormChange(
                          setFieldValue,
                          setBookingDetails,
                          bookingDetails,
                          "remarks",
                          e
                        )}
                      name="remarks"
                      idValue="remarks"
                      multiline
                      rows={3}
                      onBlur={handleBlur}
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={
                        errors.remarks && touched.remarks && errors.remarks
                      }
                    />
                  </Box>
                </Box>
                <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              </FormWrapper>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withContext(GeneralInfo)
