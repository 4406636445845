import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  timeslotContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > button": {
      display: "flex",
      flexBasis: "calc(48%)",
      justifyContent: "center",
      flexDirection: "column"
    }
  },
  root: {
    border: "1px solid #EDF0F1"
  },
  media: {
    height: 150
  },
  facilityGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(315px, 315px))",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    padding: "20px 0"
  },
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0"
  },
  infoMain: {
    borderBottom: "1px solid #F2F2F2"
  },
  timeslotHeader: {
    color: theme.palette.body.lightDark,
    fontSize: "12px",
    paddingBottom: "12px"
  },
  cardAction: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px 16px",
    width: "100%"
  }
}))

export default styles
