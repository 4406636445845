import useSWR from "swr"
import service from "services/Dashboard/Reports"

export function TotalRegisteredUsersHook() {
  const fetchTotalRegisteredUsers = async () => service.getRegisteredUsers()

  const { data, isValidating, error } = useSWR(
    `fetchTotalRegisteredUsers`,
    fetchTotalRegisteredUsers,
    {
      revalidateOnFocus: false
    }
  )
  const totalRegisteredUsers = data?.data?._data?.total

  return {
    totalRegisteredUsers,
    isValidating,
    error
  }
}
