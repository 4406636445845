import * as Yup from "yup"

export const vmsValidation: Record<number, {}> = {
  1: Yup.object().shape({
    name: Yup.string().required("Required"),
    eta: Yup.string().required("Required"),
    remarks: Yup.string(),
    multiplePersons: Yup.string(),
    numberOfPersons: Yup.number().when("multiplePersons", {
      is: "2",
      then: Yup.number()
        .required("Number of persons must be 2 or more.")
        .min(2, "Number of persons must be 2 or more.")
    })
  }),
  2: Yup.object().shape({
    name: Yup.string().required("Required"),
    remarks: Yup.string(),
    startDate: Yup.string()
      .nullable()
      .required("Required"),
    endDate: Yup.string()
      .nullable()
      .required("Required")
  }),
  3: Yup.object().shape({
    name: Yup.string().required("Required"),
    eta: Yup.string().required("Required"),
    purpose: Yup.string().required("Required")
  })
}

export const vmsResidentInfo: Record<string, {}> = {
  "default": Yup.object().shape({
    unitUID: Yup.string().required("Required"),
    contactPerson: Yup.string().required("Required")
  })
}
