import React from "react"

import ClosePaymentProvider, {
  ClosePaymentProps
} from "context/Dashboard/Bookkeeping/forms/closePayment/Provider"
import ClosePaymentView from "context/Dashboard/Bookkeeping/forms/closePayment/View"

const ClosePaymentSchedule: React.FC<ClosePaymentProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <ClosePaymentProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <ClosePaymentView />
  </ClosePaymentProvider>
)

export default ClosePaymentSchedule
