import React from "react"

import FeedbackLandscapeProvider from "context/Dashboard/Feedback/categories/landscape/Provider"
import FeedbackLandscapeView from "context/Dashboard/Feedback/categories/landscape/View"

const FeedbackLandscapeProviderMain: React.FC = (): JSX.Element => (
  <FeedbackLandscapeProvider>
    <FeedbackLandscapeView />
  </FeedbackLandscapeProvider>
)

export default FeedbackLandscapeProviderMain
