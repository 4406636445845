import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Drawer from "components/Drawer"
import DownloadWrapper from "components/DownloadFileWrapper"

import PDF from "./PDF"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
}

const Details: React.FC<DetailsProps> = ({ openState, setopenState, data }) => {
  const fileUrl = data?.attachment?.files ? data?.attachment?.files[0]?.url : null
  const fileName = data?.attachment?.files
    ? data?.attachment?.files[0]?.file_name
    : null

  const ViewNoFile = () => {
    if (fileUrl !== null) {
      return <div />
    }
    return <h4>No Attachment</h4>
  }

  const ViewFile = () => {
    if (fileUrl === null) {
      return <div />
    }

    return (
      <DownloadWrapper fileUrl={fileUrl} fileName={fileName}>
        <PDF file={fileUrl} />
      </DownloadWrapper>
    )
  }

  return (
    <Drawer openState={openState} setopenState={setopenState} closeBtnDark>
      <Box display="flex" height="100%">
        <Box
          bgcolor="#F2F2F2"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          {ViewNoFile()}
          {ViewFile()}
        </Box>
      </Box>
    </Drawer>
  )
}
export default Details
