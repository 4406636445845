import React, { useEffect } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { mutate } from "swr"
/** Components */
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer"
import Form from "pages/Dashboard/Feedback/components/Reply/Form"
import EditForm from "pages/Dashboard/Feedback/components/Reply/Form/editing"
import Messages from "pages/Dashboard/Feedback/components/Messages"
/** Context */
import { ReplyCtxType } from "./ReplyContext"
import withReplyContext from "./withReplyContext"

export const refSubmit = React.createRef<HTMLDivElement>()

const ReplyView: React.FC<ReplyCtxType> = ({
  data,
  openDialog,
  setOpenDialog,
  isEditing,
  activeReply,
  showForm,
  handleFeedbackReply,
  handleFeedbackReplyUpdate,
  replyDetails
}) => {
  const { message } = replyDetails
  const { reply } = activeReply

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    if (isEditing) {
      if (reply !== "") {
        return handleFeedbackReplyUpdate().then(() =>
          mutate("fetchFeedbackMessages")
        )
      }
      return null
    }
    if (message !== "") {
      return handleFeedbackReply().then(() => mutate("fetchFeedbackMessages"))
    }
    return null
  }

  /** Views */
  const MessagesView = () => {
    return <Messages feedbackId={data?._uid} />
  }

  const EditFormView = () => {
    if (isEditing) {
      return <EditForm message={activeReply} />
    }
    return <Form />
  }

  return (
    <>
      <Card>
        <Box bgcolor="#FFFFFF">
          <Box display="flex">
            <Box
              flex="1"
              maxWidth="50%"
              height="100vh"
              overflow="auto"
              bgcolor="#F2F2F2"
            >
              <Box>{MessagesView()}</Box>
            </Box>
            <Box flex="1" maxWidth="50%">
              {EditFormView()}
              <Box margin="0 0 30px">
                <Footer
                  handleNext={() => handleNext()}
                  handleBack={null}
                  handleCancel={() => setOpenDialog && setOpenDialog(true)}
                  label="Save"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      <Dialog
        action={() => showForm()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="OK"
        title=""
        message="Are you sure you want to cancel?"
      />
    </>
  )
}

export default withReplyContext(ReplyView)
