import React from "react"
import Box from "@material-ui/core/Box"

import styles from "./styles"

interface TabRolesProps {
  activeItem: number
  selectItem: Function
  options: any
}

const TabRoles: React.FC<TabRolesProps> = ({ activeItem, selectItem, options }) => {
  const { btn } = styles()
  const isArray = Array.isArray(options)

  function ViewOptionsArray(): JSX.Element {
    if (!isArray) {
      return <div />
    }
    return (
      <>
        {options !== undefined &&
          options.map((option: any, index: number) => {
            return (
              <Box
                className={`${btn} ${activeItem === index ? "active" : ""}`}
                onClick={() => selectItem(index)}
              >
                {option}
              </Box>
            )
          })}
      </>
    )
  }

  function ViewOptionsObject(): JSX.Element {
    if (isArray) {
      return <div />
    }
    return (
      <>
        {options !== undefined &&
          Object.keys(options).map((option: any, index: number) => {
            return (
              <Box
                className={`${btn} ${activeItem === index ? "active" : ""}`}
                onClick={() =>
                  selectItem(index, options[option]._uid, options[option].role_name)}
              >
                {options[option].role_name}
              </Box>
            )
          })}
      </>
    )
  }

  return (
    <>
      <Box display="flex" paddingRight="10px">
        <ViewOptionsArray />
        <ViewOptionsObject />
      </Box>
    </>
  )
}
export default TabRoles
