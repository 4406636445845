import React from "react"

import BookkeepingProvider from "context/Dashboard/Bookkeeping/main/Provider"
import BookkeepingView from "context/Dashboard/Bookkeeping/main/View"

export default () => (
  <BookkeepingProvider>
    <BookkeepingView />
  </BookkeepingProvider>
)
