import { Machine } from "xstate"

export const RefundPaymentMachine = Machine({
  id: "refundpayment",
  initial: "payerInfo",
  states: {
    payerInfo: {
      on: {
        NEXT: "details"
      }
    },
    details: {
      on: {
        NEXT: "acknowledgement",
        BACK: "payerInfo"
      }
    },
    acknowledgement: {
      on: {
        BACK: "details"
      }
    }
  }
})
