import React, { useContext, useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"

/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import TimePicker from "components/Forms/TimePicker"

/** Config */
import { addTimeSlots } from "config/Settings/PropertyManagement/Applications/validation"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Applications/forms/category/Context"

/** Styles */
import { refSubmit } from "context/Settings/PropertyManagement/Applications/forms/category/View"
import styles from "./styles"

interface Props {
  setIsValid: Function
}

const CategoryDetails: React.FC<Props> = ({ setIsValid }) => {
  const { section, title } = styles()
  const { weekdayTime, saturdayTime, holidayTime, handleTimeChange } = useContext(
    Context
  )

  return (
    <div>
      <Box margin="auto" padding="0 0 40px 0">
        <FormWrapper title="Time Slot" width="50%">
          <Formik
            validateOnMount
            initialValues={useMemo(() => {
              return {
                weekdayStartTime: weekdayTime?.startTime,
                weekdayEndTime: weekdayTime?.endTime,
                saturdayStartTime: saturdayTime?.startTime,
                saturdayEndTime: saturdayTime?.endTime,
                holidayStartTime: holidayTime?.startTime,
                holidayEndTime: holidayTime?.endTime
              }
            }, [])}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={addTimeSlots}
          >
            {({
              isValid,
              touched,
              errors,
              handleBlur,
              handleSubmit
            }): JSX.Element => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box className={section}>
                    <Box className={title}>Monday - Friday</Box>
                    <Box display="flex" alignItems="flex-start">
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="Start Time"
                          name="weekdayStartTime"
                          value={weekdayTime?.startTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "weekday", "start")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.weekdayStartTime &&
                            Boolean(errors.weekdayStartTime)
                          }
                          helperText={
                            touched.weekdayStartTime &&
                            errors.weekdayStartTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                      <Box padding="30px 20px 0">to</Box>
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="End Time"
                          name="weekdayEndTime"
                          value={weekdayTime?.endTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "weekday", "end nhy")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.weekdayEndTime && Boolean(errors.weekdayEndTime)
                          }
                          helperText={
                            touched.weekdayEndTime &&
                            errors.weekdayEndTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Saturdays */}
                  <Box className={section}>
                    <Box className={title}>Saturday</Box>
                    <Box display="flex" alignItems="flex-start">
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="Start Time"
                          name="saturdayStartTime"
                          value={saturdayTime?.startTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "saturday", "start")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.saturdayStartTime &&
                            Boolean(errors.saturdayStartTime)
                          }
                          helperText={
                            touched.saturdayStartTime &&
                            errors.saturdayStartTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                      <Box padding="30px 20px 0">to</Box>
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="End Time"
                          name="saturdayEndTime"
                          value={saturdayTime?.endTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "saturday", "end")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.saturdayEndTime &&
                            Boolean(errors.saturdayEndTime)
                          }
                          helperText={
                            touched.saturdayEndTime &&
                            errors.saturdayEndTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Sunday - Holidays */}
                  <Box className={section}>
                    <Box className={title}>Sunday </Box>
                    <Box display="flex" alignItems="flex-start">
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="Start Time"
                          name="holidayStartTime"
                          value={holidayTime?.startTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "holiday", "start")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.holidayStartTime &&
                            Boolean(errors.holidayStartTime)
                          }
                          helperText={
                            touched.holidayStartTime &&
                            errors.holidayStartTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                      <Box padding="30px 20px 0">to</Box>
                      <Box flex="1">
                        <TimePicker
                          ampm={false}
                          label="End Time"
                          name="holidayEndTime"
                          value={holidayTime?.endTime}
                          handleDateChange={(value: string): void =>
                            handleTimeChange &&
                            handleTimeChange(value, "holiday", "end")}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.holidayEndTime && Boolean(errors.holidayEndTime)
                          }
                          helperText={
                            touched.holidayEndTime &&
                            errors.holidayEndTime?.toString()
                          }
                          clearable
                          minutesStep={5}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>{isValid ? setIsValid(true) : setIsValid(false)}</Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </form>
              )
            }}
          </Formik>
        </FormWrapper>
      </Box>
    </div>
  )
}

export default CategoryDetails
