import Cookies from "js-cookie"

import request from "services/request"
import API from "./endpoints"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function getStaff(params: Object) {
  return request({
    url: API.FETCH_STAFF_LIST,
    method: "GET",
    params: {
      ...params,
      "condo_uid": Cookies.get("condoUID")
    },
    headers: headers()
  })
}

function getStaffList(params: Object) {
  return request({
    url: API.FETCH_STAFF_LIST,
    method: "GET",
    params: {
      ...params,
      "condo_uid": Cookies.get("condoUID")
    },
    headers: headers()
  })
}

function getAdminList() {
  return request({
    url: API.FETCH_ADMINS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID")
    },
    headers: headers()
  })
}

function getRoles(params: Object) {
  return request({
    url: API.FETCH_ALL_ROLES,
    method: "GET",
    params: {
      ...params,
      "condo_uid": Cookies.get("condoUID")
    },
    headers: headers()
  })
}

function getCondoInfo(condoUID: Object) {
  return request({
    url: `${API.CONDO_INFO}/${condoUID}`,
    method: "GET",
    headers: headers()
  })
}

function getPresigned(fileType?: number) {
  return request({
    url: API.PRESIGN_URL,
    method: "GET",
    params: {
      "file_type": fileType !== undefined ? fileType : 1
    },
    headers: {
      "Content-Type": "image/jpeg, image/png",
      ...headers()
    }
  })
}

const CommonApi = {
  getRoles,
  getStaff,
  getStaffList,
  getPresigned,
  getAdminList,
  getCondoInfo
}

export default CommonApi
