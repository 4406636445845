import React from "react"

import SettingsAdminProvider from "context/Settings/AccountManagement/Admin/main/Provider"
import SettingsAdminView from "context/Settings/AccountManagement/Admin/main/View"

const SettingsAdmin: React.FC = () => (
  <SettingsAdminProvider>
    <SettingsAdminView />
  </SettingsAdminProvider>
)

export default SettingsAdmin
