import React, { useState } from "react"
import { toast } from "react-toastify"
/** API */
import FeedbackAPI from "services/Dashboard/Feedback"
import { Context } from "./FeedbackContext"

export type FeedbackStateType = {
  TableContext: any
  ViewStatusContext: any
  ViewDisplayContext: any
  ClientCtx: Record<any, Function>
}

export const RefAll = React.createRef<HTMLButtonElement>()
export const RefPending = React.createRef<HTMLButtonElement>()
export const RefInProgress = React.createRef<HTMLButtonElement>()
export const RefResolved = React.createRef<HTMLButtonElement>()
export const RefCancelled = React.createRef<HTMLButtonElement>()

export const StatusRefs: {
  [index: string]: any
} = {
  1: RefAll,
  2: RefPending,
  3: RefInProgress,
  4: RefResolved,
  5: RefCancelled
}

const FeedbackProvider = ({ children }: any) => {
  const [activeTable, setactiveTable] = useState(0)
  const [activeItem, setActiveItem] = useState({})
  const [isCreate, setIsCreate] = useState(true)
  const [activeStatus, setActiveStatus] = useState(0)
  const [tabStatus, setTabStatus] = useState(0)

  /* Notification */
  const notifyAssign = () =>
    toast("Successfully assigned to a Staff", {
      type: toast.TYPE.SUCCESS
    })

  const notifyUrgency = () =>
    toast("Successfully updated the urgency status", {
      type: toast.TYPE.SUCCESS
    })

  const notifyStatus = () =>
    toast("Successfully updated the status", {
      type: toast.TYPE.SUCCESS
    })

  const notifyStatusError = () =>
    toast("Error updating the Status. Please try again", {
      type: toast.TYPE.ERROR
    })

  /** Methods */

  const filterData = (value: number) => {
    setactiveTable(value)
  }

  const filterTabStatus = (statusValue: number) => {
    setTabStatus(statusValue)
  }

  const handleReply = (feedbackData: Record<string, string | number>) => {
    setActiveItem(feedbackData)
  }

  const handleUpdateStatus = async (uid: string, status: string) => {
    const payload = {
      "feedback_uid": uid,
      "status": parseInt(status, 0)
    }

    try {
      const response = await FeedbackAPI.updateStatus(payload)
      if (response.data._data === null) {
        notifyStatusError()
      } else {
        window.scrollTo(0, 0)
        notifyStatus()
      }

      return response
    } catch (e) {
      return e
    }
  }

  const handleIsUrgent = async (uid: string, status: boolean) => {
    const payload = {
      "feedback_uid": uid,
      "is_urgent": status
    }

    try {
      const response = await FeedbackAPI.setUrgency(payload)
      notifyUrgency()
      return response
    } catch (e) {
      return e
    }
  }

  const handleAssignToStaff = async (uid: string, accountId: string) => {
    const payload = {
      "feedback_uid": uid,
      "assigned_to_staff_account_uid": accountId
    }

    try {
      const response = await FeedbackAPI.assignToStaff(payload)
      notifyAssign()
      return response
    } catch (e) {
      return e
    }
  }

  const feedbackEdit = (details: Record<string, any>) => {
    setActiveItem(details)
    setIsCreate(false)
  }

  const handleView = (details: Record<string, any>) => {
    setActiveItem(details)
  }

  return (
    <Context.Provider
      value={{
        activeItem,
        activeTable,
        isCreate,
        activeStatus,
        tabStatus,
        setTabStatus,
        setActiveStatus,
        filterData,
        handleReply,
        handleAssignToStaff,
        handleUpdateStatus,
        handleIsUrgent,
        feedbackEdit,
        handleView,
        filterTabStatus
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FeedbackProvider
