import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getRoles(params: Record<string, any>) {
  return request({
    url: API.LIST_ROLES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function getRolePermissions(roleId: string) {
  return request({
    url: `${API.LIST_ROLE_PERMISSION}/${roleId}`,
    method: "GET",
    headers: headers()
  })
}

function createStaffRole(payload: Record<string, any>) {
  return request({
    url: API.CREATE_ROLE,
    method: "POST",
    headers: headers(),
    data: {
      ...payload,
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function updateStaffRole(payload: Record<string, any>, uid: string) {
  return request({
    url: `${API.CREATE_ROLE}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: payload
  })
}

function updatePermission(payload: Record<string, any>, roleId: string) {
  return request({
    url: `${API.UPDATE_PERMISSION}/${roleId}`,
    method: "PUT",
    headers: headers(),
    data: {
      "permission": payload
    }
  })
}

function getStaff(roleId?: string) {
  return request({
    url: API.LIST_STAFFS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      "user_role_uid": roleId
    }
  })
}

function createStaffUser(payload: Record<string, any>) {
  return request({
    url: API.STAFF_USER,
    method: "POST",
    headers: headers(),
    data: {
      ...payload,
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function updateStaffUser(payload: Record<string, any>, uid: string) {
  return request({
    url: `${API.UPDATE_STAFF}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: payload
  })
}

function deleteStaffUser(uid: string) {
  return request({
    url: `${API.DELETE_USER}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

function removeRole(roleID: string) {
  return request({
    url: `${API.REMOVE_ROLE}/${roleID}`,
    method: "DELETE",
    headers: headers()
    // params: {
    //   "condo_uid": Cookies.get("condoUID"),
    // },
  })
}

const BookKeepingSettings = {
  getRoles,
  getRolePermissions,
  updatePermission,
  createStaffUser,
  updateStaffUser,
  createStaffRole,
  updateStaffRole,
  getStaff,
  deleteStaffUser,
  removeRole
}

export default BookKeepingSettings
