const message = {
  /** Dialog */
  bookingNotAllowed:
    "Application is disallowed according to Settings. Do you still want to proceed with Application?",
  cancelBookingNotAllowed:
    "Cancellation of Application is disallowed according to Settings. Do you still want to proceed?",
  confirmCancel: "Are you sure you want to set the status to Cancelled?",
  confirmPending: "Are you sure you want to set the status to Pending?",
  confirmArrived: "Are you sure you want to set the status to Arrived?",
  confirmClosed: "Are you sure you want to set the status to Closed?",
  confirmReceived: "Are you sure you want to set the status to Received?",
  confirmCollected: "Are you sure you want to set the status to Collected?",
  expiredBookingCancel:
    "This Application has already expired. Do you want to set the status to Cancelled?",
  expiredBookingPending:
    "This Application has already expired. Do you want to set the status to Pending?",
  expiredBookingArrived:
    "This Application has already expired. Do you want to set the status to Arrived?",
  expiredBookingClosed:
    "This Application has already expired. Do you want to set the status to Closed?",
  expiredBookingReceived:
    "This Application has already expired. Do you want to set the status to Received?",
  expiredBookingCollected:
    "This Application has already expired. Do you want to set the status to Collected?",
  expiredBookingReject:
    "This Application has already expired. Do you want to set the status to Rejected?"
}

export default message
