import React from "react"
import Box from "@material-ui/core/Box"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  TimePicker as TimePickerUI
} from "@material-ui/pickers"

import FormLabel from "components/Forms/Label"

interface FormInputProps {
  label: string
  value: any
  handleDateChange: Function
  onBlur?: Function
  error?: boolean | undefined
  helperText?: boolean | string | undefined
  name: string
  ampm?: boolean
  minutesStep?: number
  clearable?: boolean
}

const TimePicker: React.FC<FormInputProps> = ({
  label = "date",
  value,
  handleDateChange,
  onBlur,
  error,
  helperText,
  ampm = true,
  minutesStep = 1,
  clearable = false
}) => {
  return (
    <Box flex="1">
      <FormLabel label={label} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePickerUI
          inputVariant="outlined"
          value={value}
          onChange={(e) => handleDateChange(e)}
          onError={(e) => e}
          onBlur={(e) => onBlur && onBlur(e)}
          error={error}
          helperText={helperText}
          minutesStep={minutesStep}
          ampm={ampm}
          clearable={clearable}
          maxDateMessage="this is max message"
          minDateMessage="this is min message"
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}
export default TimePicker
