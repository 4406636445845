import React from "react"

import SettingsApplicationsProvider from "context/Settings/PropertyManagement/Applications/main/Provider"
import SettingsApplicationsView from "context/Settings/PropertyManagement/Applications/main/View"

const SettingsApplications: React.FC = () => {
  return (
    <SettingsApplicationsProvider>
      <SettingsApplicationsView />
    </SettingsApplicationsProvider>
  )
}

export default SettingsApplications
