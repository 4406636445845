import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  },
  values: {
    maxWidth: "calc(100%/3)",
    marginBottom: "20px",
    paddingRight: "20px",
    width: "calc(100%/3)"
  },
  printBtn: {
    width: "170px",
    marginLeft: "20px"
  },
  table: {
    padding: "0 30px",
    "& > .ReactTable": {
      // backgroundColor: "red",
      border: "1px solid #F2F2F2",
      borderRadius: "10px"
    },
    "& > .ReactTable .rt-thead.-header": {
      borderBottom: "1px solid #e8e8e8",
      padding: "0"
    },
    "& > .ReactTable .rt-thead .rt-th": {
      padding: "10px 15px",
      borderRight: "1px solid #F2F2F2"
    },
    "& > .ReactTable .rt-thead .rt-th:last-child": {
      borderRight: "1px solid transparent"
    },
    "& > .ReactTable .rt-tbody .rt-td": {
      borderRight: "1px solid #F2F2F2"
    },
    "& > .ReactTable .rt-tbody .rt-td:last-child": {
      borderRight: "1px solid transparent"
    },
    "& > .ReactTable .rt-thead .rt-resizable-header-content": {
      color: theme.palette.secondary.dark,
      fontSize: "10px",
      fontWeight: "300"
    }
  }
}))

export default styles
