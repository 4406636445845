import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  section: {
    borderBottom: "1px solid #F2F2F2"
  },
  sectionTitle: {
    fontSize: "16px",
    color: theme.palette.body.main,
    marginBottom: "15px"
  },
  sectionItem: {
    paddingBottom: "15px",
    flex: 1
  },
  sectionPadding: {
    padding: "30px 30px 15px 30px"
  }
}))

export default styles
