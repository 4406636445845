import * as React from "react"

const Scheduled: React.FC = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 2C8.2 2 10 3.8 10 6C10 8.2 8.2 10 6 10C3.8 10 2 8.2 2 6C2 3.8 3.8 2 6 2ZM16 2V4H13.7383C13.9033 4.64 14 5.308 14 6H19V8H13.7383C13.5553 8.71 13.2809 9.381 12.9219 10H19L19.002 20H5V13.9316C4.301 13.8446 3.631 13.6681 3 13.4121V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H18V2H16ZM6.90039 3.19922L5.19922 6.09961L7.40039 8.30078L8.30078 7.30078L6.80078 5.90039L8 3.80078L6.90039 3.19922Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Scheduled
