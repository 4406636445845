import React, { useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

/** Context */
import { Context as ContextMain } from "context/Dashboard/UnitManagement/categories/main/Context"

/** Config */
import dateHelper from "utils/date"

/** Service */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"
import { Context } from "./Context"

/** Interface */
export interface EditPaymentScheduleProviderProps {
  data: Record<any, any>
}

const EditPaymentScheduleProvider: React.FC<EditPaymentScheduleProviderProps> = ({
  children,
  data
}) => {
  const { showMain } = React.useContext(ContextMain)
  const user = useSelector((state: any) => state.user)
  const [openDialog, setOpenDialog] = useState(false)
  const [psDetails, setPsDetails] = useState({
    unitUID: data?.unit_uid,
    userDetailUid: data?.payee_user_detail_uid,
    payeeAccountUID: data?.payee_account_uid,
    payeeName: data?.payee_name,
    payeePhone: data?.payee_phone,
    payeeEmail: data?.payee_email,
    payeeAddress: data?.payee_registered_address,
    paymentName: data?.payment_name,
    startDate: data?.start_date,
    endDate: data?.end_date,
    frequency: String(data?.frequency),
    amt: data?.amt,
    tax: data?.tax,
    notifyOnDaysBefore: data?.notify_on_days_before,
    flags: String(data?.flags),
    notifyOnDaysAfterDue: data?.notify_on_days_after_due,
    addedBy: ""
  })

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully updated Payment Schedule", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleCreatePS = async () => {
    const payload = {
      "unit_uid": psDetails.unitUID,
      "payee_user_detail_uid": psDetails.userDetailUid,
      "payee_account_uid": psDetails.payeeAccountUID,
      "payee_name": psDetails?.payeeName,
      "payee_phone": psDetails.payeePhone,
      "payee_email": psDetails.payeeEmail,
      "payee_registered_address": psDetails.payeeAddress,
      "payment_name": psDetails.paymentName,
      "start_date": dateHelper.toUnix(psDetails.startDate),
      "end_date": dateHelper.toUnix(psDetails.endDate),
      "frequency": +psDetails.frequency,
      "amt": +psDetails.amt,
      "tax": +psDetails.tax,
      "notify_on_days_before": +psDetails.notifyOnDaysBefore,
      "flags": +psDetails.flags,
      "notify_on_days_after_due": +psDetails.notifyOnDaysAfterDue,
      "added_by_account_uid": user?.account_uid
    }

    try {
      return await BookkeepingAPI.createSchedulePayment(payload).then(
        () => notifyCreate(),
        showMain && showMain()
      )
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        handleCreatePS,
        setOpenDialog,
        setPsDetails,
        data,
        openDialog,
        psDetails
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default EditPaymentScheduleProvider
