import React, { useContext } from "react"
/**
 * Component
 */
import { Context } from "context/Dashboard/Facilites/forms/addBooking/Context"
import FacilityInfo from "../../Common/FacilitiesBreakdown/FacilityInfo"
/**
 * Context
 */
/**
 * Styles
 */
import styles from "./styles"

const SelectFacility: React.FC = () => {
  const classes = styles()

  const { data } = useContext(Context)

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.facilityInfo}>
          <div className={classes.labelStyle}>CURRENT BOOKING</div>
        </div>
        <FacilityInfo facilityBooking={data} bookingDateValue={data?.booking_date} />
      </div>
    </div>
  )
}

export default SelectFacility
