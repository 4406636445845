import React from "react"

import AccountSettingsProvider from "context/Settings/AccountManagement/OwnersTenants/forms/accountSettings/Provider"
import AccountSettingsView from "context/Settings/AccountManagement/OwnersTenants/forms/accountSettings/View"

const AccountSettings: React.FC = () => (
  <AccountSettingsProvider>
    <AccountSettingsView />
  </AccountSettingsProvider>
)

export default AccountSettings
