import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getCondoInfo() {
  return request({
    url: `${API.CONDO_INFO}/${Cookies.get("condoUID")}`,
    method: "GET",
    headers: headers()
  })
}

function updateCondoInfo(data: Object, uid: string) {
  return request({
    url: `${API.CONDO_INFO_UPDATE}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

const condoInfoSettings = {
  getCondoInfo,
  updateCondoInfo
}

export default condoInfoSettings
