import * as React from "react"

interface BookkeepingProps {
  color: string
}

const Bookkeeping: React.FC<BookkeepingProps> = ({ color }) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166992 0.333008V15.4997C0.166992 16.6968 1.13658 17.6663 2.33366 17.6663H9.13412C9.50924 18.3116 10.1998 18.7497 11.0003 18.7497C11.8009 18.7497 12.4914 18.3116 12.8665 17.6663H19.667C20.8641 17.6663 21.8337 16.6968 21.8337 15.4997V0.333008H13.167C12.3307 0.333008 11.5767 0.658329 11.0003 1.17725C10.424 0.658329 9.66999 0.333008 8.83366 0.333008H0.166992ZM2.33366 2.49967H8.83366C9.43058 2.49967 9.91699 2.98609 9.91699 3.58301V15.4997H2.33366V2.49967ZM13.167 2.49967H19.667V15.4997H12.0837V3.58301C12.0837 2.98609 12.5701 2.49967 13.167 2.49967ZM15.3337 3.58301V4.72347C14.7493 4.84764 13.4674 5.29969 13.4674 7.01074C13.4674 9.92274 16.7682 9.05992 16.7682 11.0034C16.7682 11.9763 16.0786 12 15.9388 12C15.811 12 14.9697 12.0624 14.9697 10.5739H13.167C13.167 13.0948 15.169 13.2951 15.467 13.333H15.3337V14.4163H16.417V13.333H16.4255C16.7483 13.2962 18.571 13.0219 18.571 10.9928C18.571 8.19892 15.2713 8.7352 15.2702 7.01921C15.2702 6.03662 15.8618 6.0057 15.9896 6.0057C16.2225 6.0057 16.7809 6.19452 16.7809 7.3916H18.5837C18.5837 5.10252 16.8706 4.74378 16.5651 4.68962L16.417 4.68538V3.58301H15.3337Z"
        fill={color}
      />
    </svg>
  )
}

export default Bookkeeping
