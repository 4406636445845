import { Machine } from "xstate"

export const StaffMachine = Machine({
  id: "SettingsStaff",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ACCOUNT_SETTINGS: "accountSettings",
        ADD_ADMIN: "addAdmin",
        EDIT_ADMIN: "editAdmin"
      }
    },
    accountSettings: {
      on: {
        BACK: "tableView"
      }
    },
    addAdmin: {
      on: {
        BACK: "tableView"
      }
    },
    editAdmin: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_ADMIN: "addAdmin",
    EDIT_ADMIN: "editAdmin",
    ADD_SETTINGS: "accountSettings"
  }
})
