import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import Label from "components/Forms/Label"
import Drawer from "components/Drawer"
import Header from "components/Header"
import StatusBlock from "components/Table/components/StatusBlock"
import FileLink from "components/Table/components/FileLink"

/** Config */
import {
  paymentType as paymentTypeOptions,
  paymentMethod,
  status,
  statusColor
} from "config/Dashboard/Bookkeeping"

/** Utils */
import { checkValue } from "utils/helpers"
import { fromUnix } from "utils/date"

/** Styles */

/** Context */
import withContext from "context/Dashboard/Bookkeeping/categories/details/withContext"
import styles from "./styles"

interface Props {
  openState: boolean
  setopenState: Function
  data: Record<string, any>
}

const DetailsSummary: React.FC<Props> = ({ openState, setopenState, data }) => {
  const { twoCol } = styles()

  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header subtitle="Payment Details" title={data?.txn_id} subtitleAbove />
      <Box height="100%">
        <Box>
          <Box
            padding="30px"
            borderBottom="1px solid #F2F2F2"
            width="60%"
            textAlign="center"
          >
            <StatusBlock
              status={status[data?.payment_status]}
              color={statusColor[data?.payment_status]}
            />
          </Box>
          <Box width="100%" padding="0 30px 30px" borderBottom="1px solid #F2F2F2">
            <FormWrapper title="Payer’s Information">
              <Box className={twoCol}>
                <Box>
                  <Label label="block/unit no." />
                  <Box>{checkValue(data?.unit?.short_name)}</Box>
                </Box>
                <Box>
                  <Label label="name" />
                  <Box>{checkValue(data?.payee_name)}</Box>
                </Box>
              </Box>
            </FormWrapper>
          </Box>
          <Box width="100%" padding="0 30px 30px" borderBottom="1px solid #F2F2F2">
            <FormWrapper title="Payment Details">
              <Box marginBottom="30px">
                <Box>
                  <Label label="Payment Description" />
                  <Box>{checkValue(data?.description)}</Box>
                </Box>
              </Box>
              <Box className={twoCol} marginBottom="30px">
                <Box>
                  <Label label="payment category" />
                  <Box>{data?.category}</Box>
                </Box>
                <Box>
                  <Label label="payment type" />
                  <Box>
                    {checkValue(
                      data?.payment_type,
                      paymentTypeOptions[data?.payment_type]
                    )}
                  </Box>
                </Box>
              </Box>

              <Box className={twoCol} marginBottom="30px">
                <Box>
                  <Label label="payment method" />
                  <Box>
                    {checkValue(
                      data?.payment_method,
                      paymentMethod[data?.payment_method]
                    )}
                  </Box>
                </Box>
                <Box>
                  <Label label="payment agent" />
                  <Box>{checkValue(data?.e_payment_agent)}</Box>
                </Box>
              </Box>

              <Box className={twoCol} marginBottom="30px">
                <Box>
                  <Label label="amount" />
                  <Box>
                    {checkValue(
                      data?.amt,
                      `${data?.currency} ${data?.amt?.toFixed(2)}`
                    )}
                  </Box>
                </Box>
                <Box>
                  <Label label="TAX" />
                  <Box>
                    {checkValue(
                      data?.tax,
                      `${data?.currency} ${data?.tax?.toFixed(2)}`
                    )}
                  </Box>
                </Box>
              </Box>

              {data?.refund_policy !== 0 && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="refund to" />
                    <Box>
                      {data?.refund_policy === 1 ? "Resident" : "Available Balance"}
                    </Box>
                  </Box>
                </Box>
              )}

              {data?.payment_photo?.photos !== null && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="Payment Photo" />
                    <Box>
                      {checkValue(
                        data?.payment_photo?.photos,
                        data?.payment_photo?.photos?.map((photo: any) => {
                          return (
                            <FileLink
                              key={photo?.url}
                              url={photo?.url}
                              name={photo?.file_name}
                              fileType={2}
                            />
                          )
                        })
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {data?.closed_by_account_name !== "" && (
                <Box className={twoCol} marginBottom="30px">
                  <Box>
                    <Label label="closed by" />
                    <Box>{data?.closed_by_account_name}</Box>
                  </Box>
                  <Box>
                    <Label label="Closed date" />
                    <Box>
                      {fromUnix(
                        data?.status_update_time?.status_changes?.slice(-1)[0]
                          ?.updated_at
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              {data?.payment_status === 3 && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="Remarks" />
                    <Box>
                      {
                        data?.status_update_time?.status_changes?.slice(-1)[0]
                          ?.closed_remarks
                      }
                    </Box>
                  </Box>
                </Box>
              )}
              {data?.payment_status === 4 && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="Remarks" />
                    <Box>
                      {
                        data?.status_update_time?.status_changes?.slice(-1)[0]
                          ?.refunded_remarks
                      }
                    </Box>
                  </Box>
                </Box>
              )}
              {data?.payment_status === 5 && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="Remarks" />
                    <Box>
                      {
                        data?.status_update_time?.status_changes?.slice(-1)[0]
                          ?.rejected_remarks
                      }
                    </Box>
                  </Box>
                </Box>
              )}
              {data?.payment_status === 6 && (
                <Box marginBottom="30px">
                  <Box>
                    <Label label="Remarks" />
                    <Box>
                      {
                        data?.status_update_time?.status_changes?.slice(-1)[0]
                          ?.cancelled_remarks
                      }
                    </Box>
                  </Box>
                </Box>
              )}
            </FormWrapper>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
export default withContext(DetailsSummary)
