import React, { FunctionComponent } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Drawer from "components/Drawer"
import Header from "components/Header"
import Form from "./form"

interface Props {
  openState: boolean
  setopenState: Function
  formType: number
}

const AdminFormDrawer: FunctionComponent<Props> = ({
  openState,
  setopenState,
  formType
}) => {
  const title = formType === 1 ? "Add New Admin" : "Update Admin"
  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header title={title} subtitle="account management" subtitleAbove />
      <Box height="100%">
        <Form />
      </Box>
    </Drawer>
  )
}

export default AdminFormDrawer
