import React from "react"

import VehiclesProvider from "context/Dashboard/Applications/categories/vehicles/Provider"
import VehiclesView from "context/Dashboard/Applications/categories/vehicles/View"

export default () => (
  <VehiclesProvider>
    <VehiclesView />
  </VehiclesProvider>
)
