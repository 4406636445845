import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  btn: {
    background: "#F2F2F2",
    color: theme.palette.body.gray,
    fontSize: "16px",
    padding: "0 60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "52px",
    cursor: "pointer",
    marginLeft: "8px",
    borderRadius: "10px 10px 0px 0px",
    "&.active": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  }
}))

export default styles
