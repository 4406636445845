import React from "react"

import DetailsProvider, {
  DetailsProvidersProps
} from "context/Dashboard/Feedback/details/DetailsProvider"
import DetailsView from "context/Dashboard/Feedback/details/DetailsView"

const DetailsMain: React.FC<DetailsProvidersProps> = ({ showForm, data }) => {
  return (
    <DetailsProvider showForm={showForm} data={data}>
      <DetailsView />
    </DetailsProvider>
  )
}

export default DetailsMain
