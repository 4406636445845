import React, { useState } from "react"
import Box from "@material-ui/core/Box"
/** Components */
import Drawer from "components/Drawer"
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"
import MobilePreview from "components/MobilePreview"
import { fromUnix } from "utils/date"
/** Styles */
import { IconLeft, IconRight } from "components/Icons/Common"
import styles from "./styles"
/** Icons */

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
}

const Details: React.FC<DetailsProps> = ({ openState, setopenState, data }) => {
  const { title, sectionTitle, previewButtons } = styles()
  const imageObj = data?.announcement_photo?.photos[0]?.url
  const [activePreview, setActivePreview] = useState(1)

  function ViewPostExcerpt() {
    if (activePreview !== 1) {
      return null
    }

    return (
      <MobilePreview
        header="Post Excerpt Preview"
        title={data?.title}
        bgImage={imageObj}
        content={data?.shortDescription || ""}
      />
    )
  }

  function ViewPostPreview() {
    if (activePreview !== 2) {
      return null
    }

    return (
      <MobilePreview
        header="Post Preview"
        title={data?.title}
        bgImage={imageObj}
        content={data?.description || ""}
      />
    )
  }

  function ViewButtons(): JSX.Element {
    return (
      <Box className={previewButtons}>
        <Box>
          {activePreview === 2 ? (
            <Box onClick={() => setActivePreview(1)}>{IconRight}</Box>
          ) : (
            <Box />
          )}
        </Box>
        <Box>
          {activePreview === 1 ? (
            <Box onClick={() => setActivePreview(2)}>{IconLeft}</Box>
          ) : (
            <Box />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Drawer openState={openState} setopenState={setopenState} closeBtnDark>
      <Box display="flex" height="100%">
        <Box bgcolor="#F2F2F2" padding="0 60px">
          {ViewPostExcerpt()}
          {ViewPostPreview()}

          <Box className={sectionTitle}>{ViewButtons()}</Box>
        </Box>

        <Box display="flex" flexDirection="column" padding="100px 50px">
          <Box className={title}>{data?.title}</Box>

          {data?.shortDescription !== "" ||
            (data?.shortDescription !== null && (
              <Box marginBottom="25px">
                <Label label="Short Description" />
                <Value val={data?.shortDescription} />
              </Box>
            ))}

          {data?.attachment?.files && (
            <Box marginBottom="25px">
              <Label label="File Attachment" />
              <Value val={data?.attachment?.files[0]?.file_name} />
            </Box>
          )}
          <Box marginBottom="25px">
            <Label label="Start Date" />
            <Value val={fromUnix(data?.start_date)} />
          </Box>
          <Box marginBottom="25px">
            <Label label="End Date" />
            <Value val={fromUnix(data?.end_date)} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
export default Details
