import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "flex-end"
  },
  label: {
    paddingLeft: "20px",
    fontSize: "24px",
    color: theme.palette.secondary.dark
  }
}))

export default styles
