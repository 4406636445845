import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

/** Service */
import service from "services/Dashboard/Applications"

/** Utils */
import { toUnix, fromUnix, displayDate, dayName } from "utils/date"
import { UploadImage } from "utils/uploadFile"
import { checkBooking } from "utils/functions"
import { Context } from "./Context"

export interface AddApplicationsProviderProps {
  data?: Record<string, any>
  type?: number
  activeTable: number
  showMain?: Function
  settings: Record<string, any>
}

const Provider: React.FC<AddApplicationsProviderProps> = ({
  children,
  data,
  type,
  showMain,
  settings,
  activeTable
}) => {
  const isForApproving = type === 1
  const hasData = data !== undefined
  const rData = data?.request_data
  const rVehicle = rData?.vehicle
  const rMoving = rData?.move_in_out
  const rAccessCard = rData?.access_card
  const rRenovation = rData?.renovation

  const [bookingAllowed, setBookingAllowed] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [forApproving] = useState(isForApproving)
  const [initalData, setInitalData] = useState(hasData)
  const [openDialog, setOpenDialog] = useState(false)
  const [photos, setPhotos] = useState<any>(rVehicle?.vehicle_photo?.photos || Array)
  const [localPhotos, setLocalPhotos] = useState<Array<string>>(
    rVehicle?.vehicle_photo?.photos?.map((photo: any) => {
      return photo?.url
    }) || []
  )
  const [files, setFiles] = useState<any>(data?.supporting_doc?.files || Array)
  const [attaching, setAttaching] = useState(false)
  const [fileAttaching, setFileAttaching] = useState(false)
  const [submittedData, setSubmittedData] = useState({})
  const [applicationDetails, setApplicationDetails] = useState({
    unitName: data?.unit?.short_name || "",
    unitUID: data?.unit_uid || "",
    applicantName: data?.applicant_name || "",
    status: data?.status || 1,
    applicantUID: data?.applicant_account_uid || "",
    startDate: hasData && data?.start_date !== 0 ? fromUnix(data?.start_date) : null,
    endDate: hasData && data?.end_date !== 0 ? fromUnix(data?.end_date) : null,
    serialNo: data?.serial_no || "",
    remarks: data?.remarks || ""
  })

  const defaultMultiple = rMoving?.multiple_persons ? "2" : "1"

  const [detailsInfo, setDetailsInfo] = useState({})
  const [vehicleInfo, setVehicleInfo] = useState({
    carLabel: rVehicle?.car_label || "",
    vehicleType: rVehicle?.vehicle_type || "",
    vehicleNo: rVehicle?.vehicle_no || "",
    ownerName: rVehicle?.owner_name || "",
    IUNo: rVehicle?.iu_no || "",
    model: rVehicle?.vehicle_model || "",
    photos: rVehicle?.photos || []
  })
  const [movingInfo, setMovingInfo] = useState({
    name: rMoving?.name || "",
    email: rMoving?.email || "",
    eta: rMoving?.eta_ms || "",
    mobile: rMoving?.mobile || "",
    purpose: rMoving?.purpose || "",
    remarks: rMoving?.remarks || "",
    numberOfPersons: rMoving?.no_of_persons || 2,
    multiplePersons: defaultMultiple
  })
  const [accessCardInfo, setAccessCardInfo] = useState({
    applicationType: data?.application_type?.toString() || "3"
  })
  const [renovationInfo, setRenovationInfo] = useState({
    contractorName: rRenovation?.contractor_name?.toString() || "",
    contractorContact: rRenovation?.contractor_contact?.toString() || "",
    startDate: rRenovation?.start_date?.toString() || 0
  })

  const ad = applicationDetails

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully created the application.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyUpdate = () =>
    toast("Successfully updated the application.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyApprove = () =>
    toast("Successfully approved the application.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyFail = () =>
    toast("Error approving the application.", {
      type: toast.TYPE.ERROR
    })

  const applicationType = (category: number) => {
    switch (category) {
      case 0:
        return 1
      case 1:
        return 2
      case 2:
        return parseInt(accessCardInfo?.applicationType, 10)
      case 3:
        return 6
      default:
        return 1
    }
  }

  /** Methods */

  const vehiclePayload = {
    "vehicle": {
      "iu_no": vehicleInfo?.IUNo,
      "owner_name": vehicleInfo?.ownerName,
      "vehicle_model": vehicleInfo?.model,
      "vehicle_no": vehicleInfo?.vehicleNo,
      "vehicle_type": Number(vehicleInfo?.vehicleType),
      "vehicle_photo": {
        "photos": photos
      }
    }
  }

  const accessCardPayload = {
    "access_card": {
      "owner_name": applicationDetails?.applicantName,
      "unit_uid": applicationDetails?.unitUID,
      "applicant_account_uid": applicationDetails?.applicantUID,
      "status": rAccessCard?.status || 1,
      "remarks": ad?.remarks
    }
  }

  const renovationPayload = {
    "renovation": {
      "contractor_name": renovationInfo?.contractorName,
      "contractor_contact": renovationInfo?.contractorContact,
      "unit_uid": ad?.unitUID,
      "remarks": ad?.remarks,
      "start_date": ad?.startDate !== null ? toUnix(ad?.startDate) : 0,
      "end_date": ad?.endDate !== null ? toUnix(ad?.endDate) : 0
    }
  }

  const movingInOutPayload = {
    "move_in_out": {
      "unit_uid": ad?.unitUID,
      "contact_person": applicationDetails?.applicantName,
      "name": movingInfo?.name,
      "email": movingInfo?.email,
      "eta_ms": toUnix(movingInfo.eta),
      "mobile": movingInfo?.mobile,
      "purpose": movingInfo?.purpose,
      "remarks": movingInfo?.remarks,
      "no_of_persons":
        Number(movingInfo?.numberOfPersons) === 0
          ? 1
          : Number(movingInfo?.numberOfPersons),
      "multiple_persons": movingInfo?.multiplePersons !== "1"
    }
  }

  const requestPayload = (payloadType: number) => {
    switch (payloadType) {
      case 1:
        return movingInOutPayload
      case 0:
        return vehiclePayload
      case 2:
        return accessCardPayload
      case 3:
        return renovationPayload
      default:
        return {}
    }
  }

  const payload = {
    "application_type": applicationType(activeTable || 0),
    "unit_uid": ad?.unitUID,
    "applicant_account_uid": ad?.applicantUID,
    "applicant_name": applicationDetails?.applicantName,
    "start_date": ad?.startDate !== null ? toUnix(ad?.startDate) : 0,
    "end_date": ad?.endDate !== null ? toUnix(ad?.endDate) : 0,
    "remarks": ad?.remarks,
    "application_date": toUnix(new Date().toString()),
    "serial_no": ad?.serialNo,
    "supporting_doc": {
      "files": files
    },
    "request_data": requestPayload(activeTable || 0)
  }

  const handleCreateApplication = async () => {
    setSubmitting(true)
    const endpoint = hasData
      ? service.updateApplication(payload, data?._uid)
      : service.createApplication(payload)

    const notify = () => (hasData ? notifyUpdate() : notifyCreate())

    try {
      const response = await endpoint
      setSubmittedData(response?.data?._data)
      notify()
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const handleApproveApplication = async () => {
    setSubmitting(true)

    try {
      return service
        .updateApplication(payload, data?._uid)
        .then(async (response) => {
          let approved = null
          if (response?.data?._data) {
            approved = await service.approveApplication({ "_uid": data?._uid })
            if (approved?.data?._data) {
              setSubmittedData(approved?.data?._data)
              notifyApprove()
            } else {
              notifyFail()
            }
          }
          setSubmitting(false)
          return {
            approved,
            response
          }
        })
        .catch((e) => {
          notifyFail()
          setSubmitting(false)
          return e
        })
    } catch (e) {
      notifyFail()
      setSubmitting(false)
      return e
    }
  }

  const attachingFile = (docType: number, value: boolean): void => {
    if (docType === 1) {
      setAttaching(value)
    } else {
      setFileAttaching(value)
    }
  }

  const setDocs = (docType: number, docs: any): void => {
    if (docType === 1) {
      setPhotos(docs)
    } else {
      setFiles(docs)
    }
  }

  function handleDocsUpload(event: any, docType: number) {
    const docs = docType === 1 ? photos : files
    const action = docType === 1 ? setPhotos : setFiles
    attachingFile(docType, true)

    if (docType === 1) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    UploadImage(event, docType)
      .then((output: any) => {
        action([...docs, output])
        attachingFile(docType, false)
      })
      .catch(() => attachingFile(docType, false))
  }

  const removeDoc = (value: number, fileType: number) => {
    /** Type 1 Image; Type 2 Docs */
    const group = fileType === 1 ? photos : files

    if (fileType === 1) {
      const photosDoc = localPhotos.filter((item: any, index: number) => {
        return index !== value
      })

      setLocalPhotos(photosDoc)
    }

    const docsFiltered = group.filter((item: any, index: number) => {
      return index !== value
    })
    setDocs(fileType, docsFiltered)
  }

  const dayCheck = (datetime: string, settingsItem: Record<string, any>) => {
    switch (dayName(datetime || new Date())) {
      case "Sunday":
        return settingsItem?.holiday_time_slots
      case "Saturday":
        return settingsItem?.saturday_time_slots
      default:
        return settingsItem?.weekday_time_slots
    }
  }

  const allowedTime = (sTime: string) => {
    const appTypeDate = activeTable === 3 ? ad?.startDate : movingInfo?.eta
    switch (sTime) {
      case "":
        return "Booking time for the selected date is restricted."
      case "00:00:00":
        return "Booking time for the selected date has no restriction"
      default:
        return `Allowed time for the selected date is between ${
          dayCheck(appTypeDate, settings[applicationType(activeTable || 0)])[0]
            ?.start_time
        } - ${
          dayCheck(appTypeDate, settings[applicationType(activeTable || 0)])[0]
            ?.end_time
        }`
    }
  }

  const allowedDateMessage = () => {
    if (settings[applicationType(activeTable || 0)]?.advance_booking_days === 0) {
      return ""
    }

    return `Allowed date is ${displayDate(
      settings[applicationType(activeTable || 0)]?.allowedDate,
      "MMM DD, YYYY"
    )} onwards.`
  }

  useEffect(() => {
    if (ad?.startDate && applicationType(activeTable || 0) === 6) {
      if (initalData) {
        setInitalData(false)
        return
      }
      checkBooking(
        ad?.startDate || "",
        settings[applicationType(activeTable || 0)],
        setBookingAllowed,
        true
      )
    } else if (movingInfo?.eta && applicationType(activeTable || 0) === 2) {
      checkBooking(
        movingInfo?.eta,
        settings[applicationType(activeTable || 0)],
        setBookingAllowed
      )
    }
  }, [movingInfo?.eta, ad?.startDate])

  return (
    <Context.Provider
      value={{
        hasData,
        forApproving,
        openDialog,
        applicationDetails,
        detailsInfo,
        attaching,
        submitting,
        photos,
        vehicleInfo,
        accessCardInfo,
        files,
        fileAttaching,
        movingInfo,
        renovationInfo,
        submittedData,
        localPhotos,
        bookingAllowed,
        showMain,
        setBookingAllowed,
        setOpenDialog,
        setApplicationDetails,
        setDetailsInfo,
        handleDocsUpload,
        removeDoc,
        setVehicleInfo,
        setAccessCardInfo,
        setMovingInfo,
        setRenovationInfo,
        handleCreateApplication,
        handleApproveApplication,
        allowedDateMessage: allowedDateMessage(),
        allowedTimeMessage: allowedTime(
          dayCheck(
            activeTable === 3 ? renovationInfo?.startDate : movingInfo?.eta,
            settings[activeTable + 1]
          )[0]?.start_time
        )
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
