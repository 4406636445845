import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Box from "@material-ui/core/Box"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import DatePicker from "components/Forms/DatePicker"
import FormControl from "@material-ui/core/FormControl"
import { Formik } from "formik"

/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
import Label from "components/Forms/Label"
import Switch from "components/Forms/Switch"
import Spacer from "components/Spacer"

/** Config */
import validation from "config/Dashboard/Bookkeeping/validation"
import { frequency } from "config/Dashboard/Bookkeeping"

/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/Context"
import withContext from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/withContext"

/** Ref */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/View"

/** Styles */
import styles from "./styles"

const PSSchedule: React.FC<CtxType> = ({ psDetails, setPsDetails }) => {
  const { personalDetails, spaceBottom, twoCol, inputDescription } = styles()

  /** Methods */
  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPsDetails &&
      setPsDetails({
        ...psDetails,
        [key]: e?.target?.value
      })
    setFieldValue(key, e?.target?.value)
  }

  const handleFormValue = (
    setFieldValue: Function,
    key: string,
    value: string
  ): void => {
    setPsDetails &&
      setPsDetails({
        ...psDetails,
        [key]: value
      })
    setFieldValue(key, value)
  }

  return (
    <div className={personalDetails}>
      <Box margin="auto" padding="0 0 40px 0">
        <FormWrapper title="Payment Schedule" width="60%">
          <Formik
            initialValues={{
              paymentName: psDetails.paymentName,
              amt: psDetails.amt,
              tax: psDetails.tax,
              startDate: psDetails.startDate,
              endDate: psDetails.endDate,
              notifyOnDaysAfterDue: psDetails.notifyOnDaysAfterDue,
              notifyOnDaysBefore: psDetails.notifyOnDaysBefore
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={validation.paymentSchedule}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue
            }): JSX.Element => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box width="60%" margin="auto" className={spaceBottom}>
                    <Box className={spaceBottom}>
                      <FormInput
                        name="paymentName"
                        label="Payment Name"
                        placeholder="Payment Name"
                        value={psDetails?.paymentName}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "paymentName", e)}
                        onBlur={handleBlur}
                        error={touched.paymentName && Boolean(errors.paymentName)}
                        helperText={
                          errors.paymentName &&
                          touched.paymentName &&
                          errors.paymentName
                        }
                      />
                    </Box>
                    <Box className={`${spaceBottom} ${twoCol}`}>
                      <Box flex="1">
                        <DatePicker
                          label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          value={psDetails?.startDate}
                          placeholder="mm/dd/yyyy"
                          handleDateChange={(value: string): void =>
                            handleFormValue(setFieldValue, "startDate", value)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={
                            errors.startDate && touched.startDate && errors.startDate
                          }
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <DatePicker
                          label="End Date"
                          name="endDate"
                          format="MM/dd/yyyy"
                          value={psDetails?.endDate}
                          placeholder="mm/dd/yyyy"
                          minDate={psDetails.startDate}
                          handleDateChange={(value: string): void =>
                            handleFormValue(setFieldValue, "endDate", value)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={
                            errors.endDate && touched.endDate && errors.endDate
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={spaceBottom}>
                      <Label label="Frequency" />
                      <FormControl>
                        <RadioGroup
                          aria-label="frequency"
                          name="frequency"
                          value={psDetails?.frequency}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            handleFormValue(
                              setFieldValue,
                              "frequency",
                              e.target.value
                            )}
                          style={{ flexDirection: "row" }}
                        >
                          <>
                            {Object.keys(frequency).map((i) => {
                              const item = frequency
                              if (frequency[i] === "All") {
                                return <div />
                              }
                              return (
                                <FormControlLabel
                                  value={i}
                                  control={<Radio color="primary" />}
                                  label={item[i]}
                                  labelPlacement="end"
                                  style={{ width: "30%" }}
                                />
                              )
                            })}
                          </>
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box className={`${spaceBottom} ${twoCol}`}>
                      <Box flex="1">
                        <FormInput
                          type="number"
                          name="amt"
                          label="Amount"
                          value={psDetails?.amt}
                          placeholder="$ 00.00"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleFormChange(setFieldValue, "amt", e)}
                          onBlur={handleBlur}
                          error={touched.amt && Boolean(errors.amt)}
                          helperText={errors.amt && touched.amt && errors.amt}
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <FormInput
                          type="number"
                          name="tax"
                          label="Tax"
                          value={psDetails?.tax}
                          placeholder="$ 00.00"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleFormChange(setFieldValue, "tax", e)}
                          onBlur={handleBlur}
                          error={touched.tax && Boolean(errors.tax)}
                          helperText={errors.tax && touched.tax && errors.tax}
                        />
                      </Box>
                    </Box>

                    <Box className={`${spaceBottom} ${twoCol}`}>
                      <Box className={inputDescription}>
                        Email reminder (number of days before due date)
                      </Box>
                      <Box width="30%">
                        <FormInput
                          type="number"
                          labelShrink
                          name="notifyOnDaysBefore"
                          label="Email Reminder"
                          value={psDetails?.notifyOnDaysBefore}
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(setFieldValue, "notifyOnDaysBefore", e)}
                          adorment="| days"
                          onBlur={handleBlur}
                          error={
                            touched.notifyOnDaysBefore &&
                            Boolean(errors.notifyOnDaysBefore)
                          }
                          helperText={
                            errors.notifyOnDaysBefore &&
                            touched.notifyOnDaysBefore &&
                            errors.notifyOnDaysBefore
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={`${spaceBottom} ${twoCol}`}>
                      <Box className={inputDescription}>Send Email</Box>
                      <Box
                        width="30%"
                        display="flex"
                        justifyContent="flex-end"
                        marginRight="-20px"
                      >
                        <Switch
                          action={(): void =>
                            handleFormValue(
                              setFieldValue,
                              "flags",
                              psDetails.flags === "1" ? "2" : "1"
                            )}
                          label=""
                          checked={psDetails.flags === "1"}
                        />
                      </Box>
                    </Box>

                    <Box className={`${spaceBottom} ${twoCol}`}>
                      <Box className={inputDescription}>Re-send email every</Box>
                      <Box width="30%">
                        <FormInput
                          labelShrink
                          type="number"
                          name="notifyOnDaysAfterDue"
                          label="Email Reminder"
                          value={psDetails.notifyOnDaysAfterDue}
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(
                              setFieldValue,
                              "notifyOnDaysAfterDue",
                              e
                            )}
                          adorment="| days"
                          onBlur={handleBlur}
                          error={
                            touched.notifyOnDaysAfterDue &&
                            Boolean(errors.notifyOnDaysAfterDue)
                          }
                          helperText={
                            errors.notifyOnDaysAfterDue &&
                            touched.notifyOnDaysAfterDue &&
                            errors.notifyOnDaysAfterDue
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </form>
              )
            }}
          </Formik>
        </FormWrapper>
      </Box>
    </div>
  )
}

export default withContext(PSSchedule)
