import React from "react"
import Box from "@material-ui/core/Box"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

/** Styles */
import styles from "./styles"

const IconLink = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C7.68294 0 7 0.682937 7 1.5V8H2.58594L10 15.4141L17.4141 8H13V1.5C13 0.682937 12.3171 0 11.5 0H8.5ZM9 2H11V10H12.5859L10 12.5859L7.41406 10H9V2ZM0 18V20H20V18H0Z"
      fill="#777E86"
    />
  </svg>
)

interface ItemProps {
  title: string
  file: string
}

const Item: React.FC<ItemProps> = ({ title, file }) => {
  const { link } = styles()
  return (
    <Box display="flex" flexDirection="column" paddingBottom="15px">
      <FormControlLabel
        value="1"
        control={<Radio color="primary" />}
        label={title}
        labelPlacement="end"
      />
      <Box paddingLeft="35px" display="flex" alignItems="flex-start">
        <Box padding="0 10px 0 0">{IconLink}</Box>
        <Box className={link} component="span">
          {file}
        </Box>
      </Box>
    </Box>
  )
}
export default Item
