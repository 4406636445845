import React from "react"

import EditPaymentProvider, {
  EditPaymentProviderProps
} from "context/Dashboard/Bookkeeping/forms/editPayment/Provider"
import EditPaymentView from "context/Dashboard/Bookkeeping/forms/editPayment/View"

/** Interface */
interface EditPaymentProps {
  showPayment: boolean
  setShowPayment: Function
  data: Record<any, any>
}

const EditPayment: React.FC<EditPaymentProviderProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <EditPaymentProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <EditPaymentView />
  </EditPaymentProvider>
)

export default EditPayment
