import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getRoles(params: Record<string, any>) {
  return request({
    url: API.LIST_ROLES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function removeRole(roleID: string) {
  return request({
    url: `${API.REMOVE_ROLE}/${roleID}`,
    method: "DELETE",
    headers: headers()
    // params: {
    //   "condo_uid": Cookies.get("condoUID"),
    // },
  })
}

function createAdminRole(payload: Record<string, any>) {
  return request({
    url: API.CREATE_ROLE,
    method: "POST",
    headers: headers(),
    data: {
      ...payload,
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function updateAdminRole(payload: Record<string, any>, uid: string) {
  return request({
    url: `${API.CREATE_ROLE}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: payload
  })
}

function getAdmins() {
  return request({
    url: API.LIST_ADMINS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function getRolePermissions(roleId: string) {
  return request({
    url: `${API.LIST_ROLE_PERMISSION}/${roleId}`,
    method: "GET",
    headers: headers()
  })
}

function updatePermission(payload: Record<string, any>, roleId: string) {
  return request({
    url: `${API.UPDATE_PERMISSION}/${roleId}`,
    method: "PUT",
    headers: headers(),
    data: {
      "permission": payload
    }
  })
}

function createAdminUser(payload: Record<string, any>) {
  return request({
    url: API.ADMIN_USER,
    method: "POST",
    headers: headers(),
    data: {
      ...payload,
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function updateAdminUser(payload: Record<string, any>, uid: string) {
  return request({
    url: `${API.ADMIN_USER}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: payload
  })
}

function deleteAdminUser(uid: string) {
  return request({
    url: `${API.DELETE_USER}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

function resetAdminPassword(payload: Record<string, any>) {
  return request({
    url: API.RESET_PASSWORD,
    method: "POST",
    headers: headers(),
    data: {
      ...payload
    }
  })
}

const AdminSettings = {
  getRoles,
  getRolePermissions,
  createAdminRole,
  updateAdminRole,
  updatePermission,
  createAdminUser,
  updateAdminUser,
  getAdmins,
  resetAdminPassword,
  deleteAdminUser,
  removeRole
}

export default AdminSettings
