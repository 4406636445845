import React, { useContext } from "react"

/** Components */
import ContextMenu from "components/ContextMenu"

/** Config */
import { fromUnix } from "utils/date"
import { checkDate, checkValue, checkDay, toFixed } from "utils/helpers"
import { entitlement } from "config/Settings/PropertyManagement/Facilities"

/** Context */
import { AppContext } from "App"
import ContextSettings from "../../ContextMenu"

const TableHeaderColumns = () => {
  const { condoInfo } = useContext(AppContext)

  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any) => {
            return (
              <ContextMenu>
                <ContextSettings data={row.original} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: "Category Name",
          id: "categoryName",
          accessor: (c: any) => checkValue(c?.facility_category?.category_name),
          Cell: ({ original }: any) =>
            checkValue(original?.facility_category?.category_name)
        },
        {
          Header: "Effective Date",
          id: "effectiveDate",
          accessor: (c: any) =>
            checkDate(c?.effective_date, fromUnix(c?.effective_date)),
          Cell: ({ original }: any) =>
            checkDate(original?.effective_date, fromUnix(original?.effective_date))
        },
        {
          Header: "Advance Booking",
          id: "advanceBooking",
          accessor: (c: any) => checkDay(c?.advance_booking_days),
          Cell: ({ original }: any) => checkDay(original?.advance_booking_days)
        },
        {
          Header: "Cancel Booking",
          id: "cancelBooking",
          accessor: (c: any) => checkDay(c?.cancel_booking_days),
          Cell: ({ original }: any) => checkDay(original?.cancel_booking_days)
        },
        {
          Header: "Last Minute Booking",
          id: "lastMinuteBooking",
          accessor: (c: any) => checkDay(c?.last_minute_booking_hours, "hrs"),
          Cell: ({ original }: any) =>
            checkDay(original?.last_minute_booking_hours, "hrs")
        },
        {
          Header: "Entitlement Period Per Unit",
          id: "entitlementPeriod",
          accessor: (c: any) =>
            checkValue(c?.entitlement_period, entitlement[c?.entitlement_period]),
          Cell: ({ original }: any) =>
            checkValue(
              original?.entitlement_period,
              entitlement[original?.entitlement_period]
            )
        },
        {
          Header: "Entitlement Slot Per Period Per Unit",
          id: "entitlementPeriod",
          accessor: (c: any) => checkValue(c?.slots_per_unit),
          Cell: ({ original }: any) => checkValue(original?.slots_per_unit)
        },
        {
          Header: "Deposit",
          id: "depositAmount",
          accessor: (c: any) =>
            `${checkValue(
              c?.deposit_amt,
              toFixed(c?.deposit_amt, condoInfo?.currency)
            )}`,
          Cell: ({ original }: any) =>
            `${checkValue(
              original?.deposit_amt,
              toFixed(original?.deposit_amt, condoInfo?.currency)
            )}`
        },
        {
          Header: "Usage Fee",
          id: "usageFee",
          accessor: (c: any) =>
            `${checkValue(
              c?.usage_fee_amt,
              toFixed(c?.usage_fee_amt, condoInfo?.currency)
            )}`,
          Cell: ({ original }: any) =>
            `${checkValue(
              original?.usage_fee_amt,
              toFixed(original?.usage_fee_amt, condoInfo?.currency)
            )}`
        }
      ]
    }
  ]
}

export default TableHeaderColumns
