import React from "react"
import Image from "material-ui-image"

/** Components */
import Label from "components/Forms/Label"

/** Styles */

/** Context */
import withDetailsContext from "context/Dashboard/Feedback/details/withDetailsContext"
import styles from "./styles"

interface InfoProps {
  reply: string
  type: string
  date: string
  time: string
  assignedTo: string
  byAdmin: boolean
  description: any
  images: Array<{}>
}

const Info: React.FC<InfoProps> = ({
  type,
  date,
  time,
  description,
  images,
  byAdmin,
  assignedTo
}) => {
  const { container, field, value, photoList, photoItem } = styles()

  return (
    <>
      <div className={`${container}`}>
        {byAdmin && <div className={field}>Feedback by Admin</div>}
        <div className="two-col">
          <div className={field}>
            <Label label="Date" />
            <div className={value}>{date}</div>
          </div>

          <div className={field}>
            <Label label="Time" />
            <div className={value}>{time}</div>
          </div>
        </div>
        <div className="two-col">
          <div className={field}>
            <Label label="feedback type" />
            <div className={value}>{type}</div>
          </div>
          <div className={field}>
            <Label label="assigned to" />
            <div className={value}>{assignedTo === "" ? "N/A" : assignedTo}</div>
          </div>
        </div>
        <div className={field}>
          <Label label="Description" />
          <div className={value}>{description === "null" ? "N/A" : description}</div>
        </div>

        <div className={photoList}>
          {images !== null &&
            images !== undefined &&
            images.map((photo: any) => {
              return (
                <div className={photoItem}>
                  <Image src={photo.url} aspectRatio={1 / 1} />
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default withDetailsContext(Info)
