import React from "react"

export type CtxType = {
  handleCreateAccount: Function
  setOpenDialog: Function
  setAccountRequestData: Function
  setShowSideDetails: Function
  showDetailsView: Function
  handleRejectRequest: Function
  showSideDetails: boolean
  openDialog: boolean
  accountRequestData: Record<string, any>
}

export const Context = React.createContext<Partial<CtxType>>({})
