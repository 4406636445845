import React, { useState, useContext } from "react"

/** Component */
import Toast from "components/Toast"

/** Context */
import { Context as RootContext } from "context/Settings/PropertyManagement/Applications/main/Context"

/** Service */
import service from "services/Settings/PropertyManagement/Applications"

/** Utils */
import { checkTime, formatTime, checkValidTimeRange } from "utils/date"
import { Context } from "./Context"

/** Interface */
export interface ProviderProps {
  data?: Record<string, any>
  sendBack: Function
}

const Provider: React.FC<ProviderProps> = ({ children, sendBack }) => {
  const { activeData } = useContext(RootContext)
  const hasData = activeData !== undefined

  const dateString = (timeString: string) => {
    return `Jan 01 2020 ${timeString}`
  }

  const [applicationDetails, setApplicationDetails] = useState({
    name: activeData?.application_type_name || "",
    daysAdvanceBooking: activeData?.advance_booking_days || 0,
    daysCancelBooking: activeData?.cancel_booking_days || 0,
    allowOnline: activeData?.allow_online_application || false
  })
  const [weekdayTime, setWeekdayTime] = useState({
    startTime:
      activeData?.weekday_time_slots[0]?.start_time !== ""
        ? dateString(activeData?.weekday_time_slots[0]?.start_time)
        : null,
    endTime:
      activeData?.weekday_time_slots[0]?.end_time !== ""
        ? dateString(activeData?.weekday_time_slots[0]?.end_time)
        : null
  })
  const [saturdayTime, setSaturdayTime] = useState({
    startTime:
      activeData?.saturday_time_slots[0]?.start_time !== ""
        ? dateString(activeData?.saturday_time_slots[0]?.start_time)
        : null,
    endTime:
      activeData?.saturday_time_slots[0]?.end_time !== ""
        ? dateString(activeData?.saturday_time_slots[0]?.end_time)
        : null
  })
  const [holidayTime, setHolidayTime] = useState({
    startTime:
      activeData?.holiday_time_slots[0]?.start_time !== ""
        ? dateString(activeData?.holiday_time_slots[0]?.start_time)
        : null,
    endTime:
      activeData?.holiday_time_slots[0]?.end_time !== ""
        ? dateString(activeData?.holiday_time_slots[0]?.end_time)
        : null
  })

  const payload = {
    "advance_booking_days": Number(applicationDetails?.daysAdvanceBooking),
    "cancel_booking_days": Number(applicationDetails?.daysCancelBooking),
    "allow_online_application": applicationDetails?.allowOnline,
    "weekday_time_slots": [
      {
        "start_time": `${
          weekdayTime?.startTime !== null
            ? `${formatTime(weekdayTime?.startTime)}:00`
            : ""
        }`,
        "end_time": `${
          weekdayTime?.endTime !== null
            ? `${formatTime(weekdayTime?.endTime)}:00`
            : ""
        }`
      }
    ],
    "saturday_time_slots": [
      {
        "start_time":
          saturdayTime?.startTime !== null
            ? `${formatTime(saturdayTime?.startTime)}:00`
            : "",
        "end_time": `${
          saturdayTime?.endTime !== null
            ? `${formatTime(saturdayTime?.endTime)}:00`
            : ""
        }`
      }
    ],
    "holiday_time_slots": [
      {
        "start_time":
          holidayTime?.startTime !== null
            ? `${formatTime(holidayTime?.startTime)}:00`
            : "",
        "end_time": `${
          holidayTime?.endTime !== null
            ? `${formatTime(holidayTime?.endTime)}:00`
            : ""
        }`
      }
    ]
  }

  /** Notification */
  function notifySuccess() {
    const message = !hasData ? "created" : "updated"
    return Toast(`Successfully ${message} application category.`, true)
  }

  async function createCategory() {
    /** 1: create; 2: update */

    const serviceCall = !hasData
      ? service.createApplication(payload)
      : service.updateApplication(payload, activeData?._uid)

    try {
      const response = await serviceCall
      notifySuccess()
      return response
    } catch (e) {
      return e
    }
  }

  function handleTimeChange(e: any, day: string, time: string): any {
    const isStartTime = time === "start"

    function values() {
      switch (day) {
        case "weekday":
          return {
            time: weekdayTime,
            setTime: setWeekdayTime
          }
        case "saturday":
          return {
            time: saturdayTime,
            setTime: setSaturdayTime
          }
        default:
          return {
            time: holidayTime,
            setTime: setHolidayTime
          }
      }
    }

    if (isStartTime) {
      return values()?.setTime({
        ...values().time,
        startTime: checkTime(e)?.activeTime
      })
    }

    if (!isStartTime) {
      if (checkTime(e)?.isZero) {
        return values()?.setTime({
          ...values().time,
          endTime: checkTime(e)?.activeLastHour
        })
      }

      if (checkValidTimeRange(values()?.time.startTime, checkTime(e)?.activeTime)) {
        return values()?.setTime({
          ...values().time,
          endTime: checkTime(e)?.activeTime
        })
      }
      return Toast("End time must be after the start time.", false)
    }

    return values()
  }

  return (
    <Context.Provider
      value={{
        applicationDetails,
        weekdayTime,
        saturdayTime,
        holidayTime,
        sendBack,
        setApplicationDetails,
        setWeekdayTime,
        setSaturdayTime,
        setHolidayTime,
        createCategory,
        handleTimeChange
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
