import React, { lazy, Suspense } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"
/** Components */
import Stepper from "components/Stepper"
import Loader from "components/Loader"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer"
/** Service */
import { AddFeedbackMachine } from "machines/Dashboard/Feedback/addFeedback"
import withContext from "./withContext"
import { CtxType } from "./Context"
/** Machine */

const GeneralInfo = lazy(() =>
  import("pages/Dashboard/Feedback/components/EditFeedback/GeneralInfo")
)
const FeedbackDetails = lazy(() =>
  import("pages/Dashboard/Feedback/components/EditFeedback/FeedbackDetails")
)

/** Ref */
export const refSubmit = React.createRef<HTMLDivElement>()

const EditFeedbackView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  showForm,
  feedbackDetails,
  handleAddFeedback
}) => {
  const [current, send] = useMachine(AddFeedbackMachine)
  const xValue = current?.value
  const {
    unitUID,
    byAdmin,
    type,
    description,
    staffName,
    feedbackBy
  } = feedbackDetails

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    /** Step 1 */
    if (xValue === "generalInfo") {
      if (byAdmin) {
        send("NEXT")
      }

      if (!byAdmin && unitUID !== "" && feedbackBy !== "") {
        send("NEXT")
      }
    }
    /** end of Step 1 */

    /** Step 2 */
    if (
      xValue === "feedback" &&
      type !== "" &&
      description !== "" &&
      staffName !== ""
    ) {
      handleAddFeedback().then(() => showForm())
    }
    /** end of Step 2 */
  }

  /** Views */
  const ViewGeneralInfo = (): JSX.Element => {
    if (xValue !== "generalInfo") {
      return <div />
    }

    return <GeneralInfo />
  }

  const ViewPersonalDetails = (): JSX.Element => {
    if (xValue !== "feedback") {
      return <div />
    }

    return <FeedbackDetails />
  }

  const StepperView = ({ value }: any): JSX.Element => {
    const activeStep: Record<string, number> = {
      "generalInfo": 1,
      "feedback": 2
    }

    return (
      <Stepper
        items={["general info", "feedback"]}
        activeStep={activeStep[value]}
        justifyStart
      />
    )
  }

  return (
    <>
      <Card>
        <StepperView value={current.value} />
        <Box>
          <Suspense fallback={<Loader forContent />}>
            {ViewGeneralInfo()}
            {ViewPersonalDetails()}
          </Suspense>
        </Box>
        <Box margin="0 0 30px">
          <Footer
            handleNext={() => handleNext()}
            handleBack={xValue === "generalInfo" ? null : () => send("BACK")}
            handleCancel={() => setOpenDialog && setOpenDialog(true)}
            label="Next"
          />
        </Box>
      </Card>
      <Dialog
        action={() => showForm(false)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="OK"
        title=""
        message="Are you sure you want to cancel?"
      />
    </>
  )
}

export default withContext(EditFeedbackView)
