import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  title: {
    fontSize: "20px",
    lineHeight: "18px",
    letterSpacing: "0.0015em;",
    color: "#454B57",
    padding: "32px 37px 33px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottom: "1px solid #F2F2F2",
    "& span": {
      margin: "0 0 0 17px"
    }
  }
}))

export default styles
