import React from "react"

export type CtxType = {
  photos: any
  attaching: boolean
  handleImageUpload: Function
  removePhoto: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
