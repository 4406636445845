import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"

import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import Table from "pages/Settings/PropertyManagement/Feedback/tables/main"
import CategoryForm from "pages/Settings/PropertyManagement/Feedback/components/CategoryForm/CategoryDetails"
import PermissionMessage from "components/PermissionMessage"

/** Machine */
import { SettingsFeedbackMachine } from "machines/Settings/PropertyManagement/Feedback"

/** Context */
import { AppContext } from "App"
import { Context } from "./Context"
import styles from "./styles"

const SettingsFeedback: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { activeData, showForm, setShowForm } = useContext(Context)
  const [current] = useMachine(SettingsFeedbackMachine)
  const xValue = current?.value
  const { title } = styles()

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  /** Views */
  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <PageHeaderTitle title="Feedback" breadcrumbs="SETTINGS / Feedback" />
          </Box>
        </Box>

        <WithTable>
          <Box>
            <div className={title}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0V2H18V12H20V2C20 0.895 19.105 0 18 0H4ZM2 4C0.9 4 0.00976562 4.9 0.00976562 6L0 21L4 17H14C15.1 17 16 16.1 16 15V6C16 4.9 15.1 4 14 4H2ZM2 6H14V15H4H3.17188L2.58594 15.5859L2 16.1719V6Z"
                  fill="#454B57"
                />
              </svg>
              <span>Feedback</span>
            </div>
          </Box>
          <Table />
        </WithTable>
      </>
    )
  }

  const ViewUpdateCategory = () => {
    return (
      <>
        <CategoryForm
          data={activeData}
          openState={showForm || false}
          setopenState={(value: boolean) => setShowForm && setShowForm(value)}
          action={() => null}
        />
      </>
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        {ViewTable()}
        {ViewUpdateCategory()}
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.manage_condo_settings) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default SettingsFeedback
