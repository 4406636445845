import React, { useState, FunctionComponent } from "react"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { UploadImage } from "utils/uploadFile"

/** Context */
import service from "services/Settings/Profile"
import { setUserDetails as setUserDetailsAction } from "redux/user/actions"
import { Context } from "./Context"

/* Redux */

/** Service */

const Provider: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch()

  const { userData } = useSelector((state: any) => state.user)
  const [userDetails, setUserDetails] = useState({
    displayName: userData?.account_name
  })
  const [photos, setPhotos] = useState(userData?.account_profile_photo)
  const [attachingPhoto, setAttachingPhoto] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState("")

  /**
   * Notification
   */
  const notifyUpdate = () =>
    toast(`Successfully updated your profile.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  function handleImageUpload(event: any) {
    if (event) {
      setPreviewImage(window?.URL?.createObjectURL(event[0]))
    }

    setAttachingPhoto(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos(output)
        setAttachingPhoto(false)
      })
      .catch((e) => {
        setAttachingPhoto(false)

        return e
      })
  }

  async function handleUpdateProfile() {
    const payload = {
      "name": userDetails?.displayName,
      "profile_photo": {
        ...photos,
        "file_type": 1
      }
    }

    try {
      const response = await service.updateUserInfo(payload, userData?.account_uid)
      await dispatch(
        setUserDetailsAction({
          ...userData,
          "account_profile_photo": response?.data?._data?.profile_photo
        })
      )
      notifyUpdate()
      return response
    } catch (e) {
      return e
    }
  }

  function resetProfile() {
    setPhotos(userData?.account_profile_photo)
    setUserDetails({ displayName: userData?.account_name })
    setPreviewImage("")
  }

  return (
    <Context.Provider
      value={{
        userData,
        userDetails,
        photos,
        attachingPhoto,
        previewImage,
        handleImageUpload,
        handleUpdateProfile,
        setUserDetails,
        resetProfile
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
