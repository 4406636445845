import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

const UnitDetailsProvider: React.FC = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [activeData, setActiveData] = useState([])
  const [activeVehicle, setActiveVehicle] = useState([])
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Context.Provider
      value={{
        setOpenDialog,
        setActiveData,
        setActiveVehicle,
        setShowDetails,
        showDetails,
        activeVehicle,
        activeData,
        openDialog
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default UnitDetailsProvider
