import React, { FunctionComponent, useState, useEffect, useContext } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"
import { useSelector } from "react-redux"

/** Context */
import service from "services/Settings/AccountManagement/Admin"
import { Context as MainContext } from "context/Settings/AccountManagement/Admin/main/Context"
import { Context } from "./Context"

/** Service */

export interface Props {
  data?: any
}

const Provider: FunctionComponent<Props> = ({ data, children }) => {
  const hasData = Object.keys(data).length !== 0
  const { userData } = useSelector((state: any) => state.user)
  const { setShowForm } = useContext(MainContext)
  const condoSuffix = `@${userData?.condos[0]?.username_suffix}`

  const [adminDetails, setAdminDetails] = useState({
    adminName: hasData ? data?.account_name : "",
    adminEmail: hasData ? data?.account_email : "",
    adminUsername: data?.account_username?.replace(condoSuffix, "") || "",
    adminMobile: hasData ? data?.account_mobile_no : "",
    methodType: "EMAIL_PASSWORD",
    adminPassword: "",
    role: hasData ? data?.condo?.user_role?._uid : "",
    suffix: condoSuffix
  })

  useEffect(() => {
    setAdminDetails({
      adminName: hasData ? data?.account_name : "",
      adminEmail: hasData ? data?.account_email : "",
      adminUsername: data?.account_username || "",
      adminMobile: hasData ? data?.account_mobile_no : "",
      methodType: "EMAIL_PASSWORD",
      adminPassword: "",
      role: hasData ? data?.condo?.user_role?._uid : "",
      suffix: hasData ? "" : condoSuffix
    })
  }, [data])

  /**
   * Notification
   */
  const notifyCreate = (adminName: string, action: number) =>
    toast(`Successfully ${action === 1 ? "created" : "updated"} ${adminName}.`, {
      type: toast.TYPE.SUCCESS
    })

  const notifyError = (msg: string) =>
    toast(`${msg}`, {
      type: toast.TYPE.ERROR
    })

  /** Method */
  async function handleFormSubmit() {
    const ad = adminDetails
    const payload = {
      "name": ad?.adminName,
      "email": ad?.adminEmail,
      "mobile_no": ad?.adminMobile,
      "method_type": "EMAIL_PASSWORD",
      "user_role_uid": ad?.role,
      "account_username": ad?.adminUsername + condoSuffix,
      "account_pw": ad?.adminPassword
    }

    const updatePayload = {
      "name": ad?.adminName,
      "email": ad?.adminEmail,
      "mobile_no": ad?.adminMobile,
      "user_role_uid": ad?.role
    }

    const endpoint = hasData
      ? service.updateAdminUser(updatePayload, data?.account_uid)
      : service.createAdminUser(payload)

    try {
      const response = await endpoint
      const resData = await response?.data?._data
      if (resData) {
        notifyCreate(ad?.adminName, hasData ? 2 : 1)
        setShowForm && setShowForm(false)
        mutate("fetchSettingsAdmin")
      } else {
        const message = await response?.data?._message
        if (message.includes("uq_access_method_type_username")) {
          notifyError("Username is already taken")
        } else if (message.includes("uq_user_detail_admin_condo_uid_name_email")) {
          notifyError("Admin name and email must be unique")
        } else {
          const rawMsg = message.split('"')[1]
          notifyError(rawMsg)
        }
      }
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        adminDetails,
        setAdminDetails,
        handleFormSubmit
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
