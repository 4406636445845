import React from "react"

export type CtxType = {
  handleAddFacility: Function
  setOpenDialog: Function
  showFacilities: Function
  setFacility: Function
  handlePhotoUpload: Function
  removePhoto: Function
  photos: any
  facility: Record<string, any>
  openDialog: boolean
  submitting: boolean
  photoAttaching: boolean
  uploadError: boolean
  hasData: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
