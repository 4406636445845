import React from "react"

import FeedbackAllProvider from "context/Dashboard/Feedback/categories/all/Provider"
import FeedbackAllView from "context/Dashboard/Feedback/categories/all/View"

const FeedbackAllProviderMain: React.FC = (): JSX.Element => (
  <FeedbackAllProvider>
    <FeedbackAllView />
  </FeedbackAllProvider>
)

export default FeedbackAllProviderMain
