import React, { ReactInstance } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ReactToPrint from "react-to-print"

/** Components */
import Label from "components/Forms/Label"
import FormWrapper from "components/Forms/FormWrapper"

/** Context */
import { Context } from "context/Dashboard/Applications/forms/addApplication/Context"
// import {Context as MainContext} from "context/Dashboard/Applications/main/Context"

/** Utils */
import { fromUnix, fromUnixTime } from "utils/date"

/** Refs */
import { refPrintBtn } from "context/Dashboard/Applications/forms/addApplication/View"
import VehicleRegistration from "./VehicleRegistration"
import SummaryMovingInOut from "./MovingInOut"
import AddAccessCardSummary from "./AddAccessCard"
import RenovationSummary from "./Renovation"
import styles from "./styles"

/* eslint-disable react/prefer-stateless-function */
class EmptyComponent extends React.Component {
  render() {
    return null
  }
}

const Acknowledgement: React.FC = () => {
  const { value, values } = styles()
  const ele: ReactInstance = new EmptyComponent({})
  const refReport = React.useRef<any>(ele)
  const { submittedData } = React.useContext(Context)
  const sd = submittedData

  function SummaryApplication(type: number): JSX.Element {
    switch (type) {
      case 1:
        return <VehicleRegistration info={submittedData} />
      case 2:
        return <SummaryMovingInOut info={submittedData} />
      case 3:
      case 4:
      case 5:
        return <AddAccessCardSummary info={submittedData} />
      case 6:
        return <RenovationSummary info={submittedData} />
      default:
        return <div />
    }
  }

  return (
    <Box>
      <div ref={refReport}>
        <Box borderBottom="1px solid #F2F2F2">
          <FormWrapper title="Applicant Details" width="60%">
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="initial"
              paddingBottom="30px"
              width="60%"
              margin="auto"
            >
              <Box className={values}>
                <Label label="Date" />
                <Box className={value}>{fromUnix(sd?._created)}</Box>
              </Box>
              <Box className={values}>
                <Label label="Time" />
                <Box className={value}>{fromUnixTime(sd?._created)}</Box>
              </Box>
              <Box className={values}>
                <Label label="Name" />
                <Box className={value}>{sd?.applicant_name}</Box>
              </Box>
              <Box className={values}>
                <Label label="Condo" />
                <Box className={value}>Alps</Box>
              </Box>
              <Box className={values}>
                <Label label="block/unit no." />
                <Box className={value}>
                  {sd?.unit?.short_name === "" ? "N/A" : sd?.unit?.short_name}
                </Box>
              </Box>
            </Box>
          </FormWrapper>
        </Box>

        <Box>
          <FormWrapper title="Application Details" width="60%">
            {SummaryApplication(submittedData?.application_type)}
          </FormWrapper>
        </Box>

        <Box width="60%" margin="auto">
          <Box padding="30px 0" width="300px">
            <Label label="received by:" />
            <Box borderTop="1px solid #F2F2F2" marginTop="50px" textAlign="center">
              <Box marginTop="20px">Signature over printed name</Box>
            </Box>
          </Box>

          <Box>
            <ReactToPrint
              trigger={() => <Button onClick={() => null} ref={refPrintBtn} />}
              content={() => (refReport.current ? refReport.current : ele)}
            />
          </Box>
        </Box>
      </div>
    </Box>
  )
}
export default Acknowledgement
