import { Machine } from "xstate"

export const CreateUserMachine = Machine({
  id: "createUserMachine",
  initial: "generalInfo",
  states: {
    generalInfo: {
      on: {
        NEXT: "personalDetails"
      }
    },
    personalDetails: {
      on: {
        NEXT: "uploadImage",
        BACK: "generalInfo"
      }
    },
    uploadImage: {
      on: {
        NEXT: "supportingDocuments",
        BACK: "personalDetails"
      }
    },
    supportingDocuments: {
      on: {
        BACK: "uploadImage"
      }
    }
  }
})
