import React from "react"

import CreateVehicleProvider, {
  CreateVehicleProviderProps
} from "context/Dashboard/UnitManagement/forms/vehicles/createVehicle/Provider"
import CreateVehicleView from "context/Dashboard/UnitManagement/forms/vehicles/createVehicle/View"

const CreateVehicle: React.FC<CreateVehicleProviderProps> = ({
  data,
  successCallback
}) => (
  <CreateVehicleProvider data={data} successCallback={successCallback}>
    <CreateVehicleView />
  </CreateVehicleProvider>
)

export default CreateVehicle
