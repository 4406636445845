import React from "react"
import Box from "@material-ui/core/Box"

import "./styles.css"

interface LoaderProps {
  forContent?: boolean
  dark?: boolean
  noLoadingText?: boolean
}

const LoaderComponent = (
  <Box
    style={{
      padding: "200px 100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <h3>Loading</h3>
  </Box>
)

const Loader: React.FC<LoaderProps> = ({
  forContent,
  dark,
  noLoadingText = true
}) => {
  return (
    <>
      {forContent ? (
        LoaderComponent
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="10px 0"
        >
          <div
            className="spinner"
            style={{ backgroundColor: dark ? "#000000" : "#FFFFFF" }}
          />
          {!noLoadingText && <Box padding="15px 0">Loading...</Box>}
        </Box>
      )}
    </>
  )
}
export default Loader
