import * as React from "react"
import { DetailsContext } from "./DetailsContext"

function withReplyContext(Component: any) {
  return function contextComponent(props: any) {
    return (
      <DetailsContext.Consumer>
        {(contexts: any) => <Component {...props} {...contexts} />}
      </DetailsContext.Consumer>
    )
  }
}

export default withReplyContext
