import React, { useState } from "react"
import { useMachine } from "@xstate/react"
import { mutate } from "swr"
/** Component */
import Toast from "components/Toast"
/** Machine */
import { SettingsFacilitiesMachine } from "machines/Settings/PropertyManagement/Facilities"
/** Context */
import service from "services/Settings/PropertyManagement/Facilities"
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeFacility, setActiveFacility] = useState({})

  const [current, send] = useMachine(SettingsFacilitiesMachine)

  function showTable() {
    send("TABLE_VIEW")
  }

  function showAddFacility() {
    send("ADD_FACILITY")
  }

  function showFacilities(activeItems: Record<string, any>) {
    setActiveFacility(activeItems || activeFacility)
    send("SHOW_FACILITIES")
  }

  function showAddCategory(activeItems: Record<string, any>) {
    setActiveFacility(activeItems)
    send("ADD_CATEGORY")
  }

  function showUpdateFacility(activeItems: Record<string, any>) {
    setActiveFacility(activeItems)
    send("UPDATE_FACILITY")
  }

  async function removeSettings(settingsUID: string) {
    try {
      const response = await service.removeSettings(settingsUID)

      if (response?.data?._statusCode === -283) {
        Toast(
          `Cannot delete this settings because there's a booking attached to it.`,
          false
        )
        return response
      }

      Toast(`Successfully removed the setting.`, true)
      mutate("fetchFacilitiesListSettings")
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        showTable,
        showAddFacility,
        showFacilities,
        showAddCategory,
        setActiveTab,
        setActiveFacility,
        showUpdateFacility,
        removeSettings,
        activeFacility,
        activeTab,
        xStateValue: current?.value
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
