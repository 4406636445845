import React from "react"

import CreateAccessCardProvider, {
  CreateAccessCardProviderProps
} from "context/Dashboard/UnitManagement/forms/accessCards/createAccessCard/Provider"
import CreateAccessCardView from "context/Dashboard/UnitManagement/forms/accessCards/createAccessCard/View"

const CreateVehicle: React.FC<CreateAccessCardProviderProps> = ({
  data,
  successCallback
}) => (
  <CreateAccessCardProvider data={data} successCallback={successCallback}>
    <CreateAccessCardView />
  </CreateAccessCardProvider>
)

export default CreateVehicle
