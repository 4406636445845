import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "60%",
    margin: "0 auto"
  },
  section: {
    borderBottom: "1px solid #F2F2F2",
    margin: "0 auto 25px auto"
  },
  sectionLast: {
    borderBottom: "1px solid transparent",
    padding: "0 0 25px 0",
    margin: "0 auto 0 auto"
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.dark,
    paddingBottom: "20px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
      maxWidth: "48%"
    }
  }
}))

export default styles
