import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Component */
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"

/** Components */
import AccessCardsDetails from "pages/Dashboard/UnitManagement/components/AccessCards/CreateAccessCards/AccessCardsDetails"
/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Refs */
export const refSubmit = React.createRef<HTMLDivElement>()

const CreateOwnerView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  accessCardDetails,
  handleRegisterAccessCard
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const vd = accessCardDetails

  const handleNext = () => {
    if (
      vd?.blockUnit !== "" &&
      vd?.ownerName !== "" &&
      vd?.serialNumber !== "" &&
      vd?.activationDate
    ) {
      handleRegisterAccessCard()
    } else {
      refSubmit.current?.click()
    }

    return refSubmit.current?.click()
  }

  const ViewAccessCardDetails = (): JSX.Element => {
    return <AccessCardsDetails />
  }

  return (
    <>
      <Card>
        <Box>
          <div>{ViewAccessCardDetails()}</div>
          <Box margin="30px 0">
            <Footer
              handleNext={() => handleNext()}
              handleBack={null}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label="Submit"
            />
          </Box>
        </Box>
        <Dialog
          action={() => null}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message="Are you sure you want to cancel?"
        />
      </Card>
    </>
  )
}

export default withContext(CreateOwnerView)
