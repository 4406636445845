import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getScheduledPayments(isArchive: boolean) {
  return request({
    url: API.SCHEDULED_PAYMENTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      "is_archived": isArchive
    }
  })
}

function archiveSchedulePayment(data: Object) {
  return request({
    url: API.ARCHIVE_SCHEDULE_PAYMENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function createSchedulePayment(data: Object) {
  return request({
    url: API.CREATE_SCHEDULED_PAYMENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function createPayment(data: Object) {
  return request({
    url: API.CREATE_PAYMENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function editPayment(data: Object, uid: number) {
  return request({
    url: `${API.EDIT_PAYMENT}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function closePayment(data: Object) {
  return request({
    url: API.CLOSE_PAYMENTS,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function cancelPayment(data: Object) {
  return request({
    url: API.CANCEL_PAYMENTS,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function rejectPayment(data: Object) {
  return request({
    url: API.REJECT_PAYMENTS,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateDepositLock(data: Object) {
  return request({
    url: API.UPDATE_DEPOSIT_LOCK,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function refundRequest(data: Object) {
  return request({
    url: API.REFUND_REQUEST,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function refundClose(data: Object) {
  return request({
    url: API.REFUND_CLOSE,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function refundPayment(data: Object) {
  return request({
    url: API.REFUND_CLOSE_PAYMENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function refundBalance(data: Object) {
  return request({
    url: API.REFUND_CLOSE_BALANCE,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function getPayments(params?: Record<string, string>) {
  return request({
    url: API.FETCH_PAYMENTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function getStatsOutstandingPayments(params?: Record<string, string>) {
  return request({
    url: API.OUTSTANDING_PAYMENTS,
    method: "GET",
    headers: headers(),
    params
  })
}

function getTotalCollections(params?: Record<string, string>) {
  return request({
    url: API.TOTAL_COLLECTIONS,
    method: "GET",
    headers: headers(),
    params
  })
}

const BookkeepingAPI = {
  getScheduledPayments,
  createSchedulePayment,
  createPayment,
  editPayment,
  getPayments,
  archiveSchedulePayment,
  closePayment,
  cancelPayment,
  rejectPayment,
  updateDepositLock,
  refundRequest,
  refundClose,
  refundPayment,
  refundBalance,
  getStatsOutstandingPayments,
  getTotalCollections
}

export default BookkeepingAPI
