import React from "react"

import CancelRefundProvider, {
  CancelRefundProviderProps
} from "context/Dashboard/Bookkeeping/forms/cancelRefund/Provider"
import CancelRefundView from "context/Dashboard/Bookkeeping/forms/cancelRefund/View"

const ClosePaymentSchedule: React.FC<CancelRefundProviderProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <CancelRefundProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <CancelRefundView />
  </CancelRefundProvider>
)

export default ClosePaymentSchedule
