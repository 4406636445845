import React from "react"

export type CtxType = {
  photos: any
  condoInfo: any
  activeTab: number
  attaching: boolean
  submitting: boolean
  isLoading: boolean
  setActiveTab: Function
  handleImageUpload: Function
  removePhoto: Function
  setCondoInfo: Function
  setSubmitting: Function
  updateCondoInfo: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
