import * as React from "react"

const Vehicles: React.FC = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.27344 2.94746e-07C6.09358 -0.000137765 5.91702 0.0482275 5.76234 0.140002C5.60767 0.231777 5.4806 0.363564 5.39453 0.521485L3.11523 4.70117L1.70703 3.29297L0.292969 4.70703L2.11719 6.53125C2.0404 6.67557 2.00016 6.83652 2 7V15V17C2 17.552 2.448 18 3 18H5L4.99414 16H19.0059L19 18H21C21.55 18 22 17.55 22 17V15V7C22 6.84475 21.9639 6.69162 21.8945 6.55273L21.8828 6.53125L23.707 4.70703L22.293 3.29297L20.9414 4.64453L18.8945 0.552735C18.8115 0.38663 18.6838 0.24694 18.5258 0.149315C18.3678 0.0516901 18.1857 -1.23358e-05 18 2.94746e-07H6.27344ZM6.86719 2H17.3828L19.3828 6H14.5H13.5781H9.5H4.68555L6.86719 2ZM9.5 6C9.89782 6 10.2794 5.84197 10.5607 5.56066C10.842 5.27936 11 4.89783 11 4.5C11 4.10218 10.842 3.72064 10.5607 3.43934C10.2794 3.15804 9.89782 3 9.5 3C9.10218 3 8.72064 3.15804 8.43934 3.43934C8.15804 3.72064 8 4.10218 8 4.5C8 4.89783 8.15804 5.27936 8.43934 5.56066C8.72064 5.84197 9.10218 6 9.5 6ZM14.5 6C14.8978 6 15.2794 5.84197 15.5607 5.56066C15.842 5.27936 16 4.89783 16 4.5C16 4.10218 15.842 3.72064 15.5607 3.43934C15.2794 3.15804 14.8978 3 14.5 3C14.1022 3 13.7206 3.15804 13.4393 3.43934C13.158 3.72064 13 4.10218 13 4.5C13 4.89783 13.158 5.27936 13.4393 5.56066C13.7206 5.84197 14.1022 6 14.5 6ZM4 8H13.5781H20V14H4V8ZM7 10C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12C7.26522 12 7.51957 11.8946 7.70711 11.7071C7.89464 11.5196 8 11.2652 8 11C8 10.7348 7.89464 10.4804 7.70711 10.2929C7.51957 10.1054 7.26522 10 7 10ZM17 10C16.7348 10 16.4804 10.1054 16.2929 10.2929C16.1054 10.4804 16 10.7348 16 11C16 11.2652 16.1054 11.5196 16.2929 11.7071C16.4804 11.8946 16.7348 12 17 12C17.2652 12 17.5196 11.8946 17.7071 11.7071C17.8946 11.5196 18 11.2652 18 11C18 10.7348 17.8946 10.4804 17.7071 10.2929C17.5196 10.1054 17.2652 10 17 10Z"
        fill="white"
      />
    </svg>
  )
}

export default Vehicles
