import React from "react"

export type CtxType = {
  handleDocument: Function
  handleImageUpload: Function
  handleFormChange: Function
  setOpenDialog: Function
  setDocDetails: Function
  setFiles: Function
  handleFileUpload: Function
  removeFile: Function
  setUploadError: Function
  sendBack: Function
  hasData: boolean
  uploadError: boolean
  fileAttaching: boolean
  openDialog: boolean
  isSubmitting: boolean
  files: any
  docDetails: any
  filterTabs: any
}

export const Context = React.createContext<Partial<CtxType>>({})
