const version = "v1"
const baseFacility = "svc-facility"

const API = {
  FETCH_ESTATE_MATTERS_FOLDER: `${baseFacility}/${version}/estate-matter-folders`,
  FETCH_ESTATE_MATTERS: `${baseFacility}/${version}/estate-matters`,
  CREATE_DOCUMENT: `${baseFacility}/${version}/estate-matter`,
  DELETE_DOCUMENT: `${baseFacility}/${version}/estate-matter`
}

export default API
