import React from "react"

export type CtxType = {
  addVisitorsActive: boolean
  canCancel: boolean
  showDetails: boolean
  tableData: Record<any, any>
  filteredData: Record<any, any>
  activeData: Record<any, any>
  settings: Record<any, any>
  activeTable: number
  tabsFilter: number
  activeTitle: string
  checkExpired: Function
  checkCancel: Function
  settableData: Function
  setFilteredData: Function
  setactiveTable: Function
  setTabsFilter: Function
  setactiveTitle: Function
  setAddVisitorsActive: Function
  fecthVms: Function
  filterData: Function
  filterActiveTable: Function
  fetchVms: Function
  tabChange: Function
  setShowDetails: Function
  setDetails: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
