import React from "react"
import Box from "@material-ui/core/Box"
import Skeleton from "@material-ui/lab/Skeleton"

/** Components */
import FilterButtons from "components/FilterButtons"

import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    position: "relative",
    borderRadius: "28px",
    minHeight: "78px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    minWidth: "150px",
    margin: "0 10px 10px",
    padding: "0 16px",
    flex: "1",
    color: "transparent"
  }
}))

interface Props {
  menu: Record<string, any>
  action: Function
  activeMenu: Record<string, any>
}

const CategoryFilter: React.FC<Props> = ({ menu, action, activeMenu }) => {
  const classes = styles()

  return (
    <Box display="flex" overflow="auto">
      {menu ? (
        menu?.map((item: Record<string, any>) => {
          return (
            <FilterButtons
              key={item?.category_name}
              label={item?.category_name}
              onClick={() => action(item)}
              isActive={activeMenu?._uid === item?._uid}
            />
          )
        })
      ) : (
        <>
          <Skeleton className={classes.container}>.</Skeleton>
          <Skeleton className={classes.container}>.</Skeleton>
          <Skeleton className={classes.container}>.</Skeleton>
          <Skeleton className={classes.container}>.</Skeleton>
          <Skeleton className={classes.container}>.</Skeleton>
          <Skeleton className={classes.container}>.</Skeleton>
        </>
      )}
    </Box>
  )
}
export default CategoryFilter
