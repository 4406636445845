import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
/** Components */
import Table from "pages/Dashboard/Applications/tables/movingInOut"
import TableHeader from "components/TableHeader"
import WithTable from "layout/WithTable"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
/** Context */
import { Context } from "context/Dashboard/Applications/categories/main/Context"
import { AppContext } from "App"
/** Icons */
import { MenuIcons } from "pages/Dashboard/Applications/config/Menu"
/* Config */
import { status } from "config/Dashboard/Applications"

const View: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { activeFilter, filterTable, showAddApplication, downloadData } = useContext(
    Context
  )

  const currentFilter = activeFilter !== undefined ? activeFilter : 0

  const { permission } = useContext(AppContext)
  const csvFileName = `Applications-MovingInOut-${status[currentFilter + 1]}.csv`

  const MainView = () => {
    return (
      <WithTable>
        <TableHeader
          addActionLabel="Add New"
          addAction={() => showAddApplication && showAddApplication()}
          title="Moving In/Out"
          titleIcon={MenuIcons.movinginout}
          actionDisable={permission?.application_create}
          data={downloadData}
          fileName={csvFileName}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={activeFilter || 0}
            handleChange={(e: any) => filterTable && filterTable(e)}
            options={status}
          />
        </Box>
        <Table />
      </WithTable>
    )
  }

  return (
    <>
      <div>{MainView()}</div>
    </>
  )
}

export default View
