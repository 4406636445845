import React, { useContext, useState } from "react"
import Fade from "@material-ui/core/Fade"
import useSWR from "swr"
import feedbackApi from "services/Dashboard/Feedback"

/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Config */
import { Context } from "context/Dashboard/Feedback/categories/main/Context"
import tableHeaders from "../tableHeaders"

/** Context */

const TableLandscape: React.FC = () => {
  const { activeFilter, activeTable, setDownloadData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchLandscapeFeedback = async () =>
    feedbackApi.getFeedbacks({
      "posted_date_sort": "desc"
    })

  const { data, isValidating } = useSWR(
    `fetchLandscapeFeedback-${activeTable}-${activeFilter}`,
    fetchLandscapeFeedback,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []
  const filteredAll = tableData?.filter((i: any) => i.feedback_type === 4)

  const filteredData = filteredAll?.filter((i: any) => i.status === activeFilter)

  const activeData = activeFilter === 0 ? filteredAll : filteredData

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating}>
      <div>
        <Table
          data={activeData || []}
          columns={tableHeaders(
            `fetchLandscapeFeedback-${activeTable}-${activeFilter}`
          )}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Status": i?.status,
                    "Block/Unit No.": i?.feedback_unit_short_name,
                    "Owner Name": i?.feedback_owner_account_name,
                    "Type": i?.feedback_type,
                    "Assigned To": i?.assigned_to_staff_account_name,
                    "Posted Date": i?.posted_date_ms,
                    "Urgent": i?.is_urgent,
                    "Remarks":
                      i?.remarks === "" || i?.remarks === null ? "N/A" : i?.remarks
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TableLandscape
