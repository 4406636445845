import { Machine } from "xstate"

export const UnitDetailsMachine = Machine({
  id: "unitDetailsMachine",
  initial: "udMain",
  states: {
    udMain: {
      on: {
        EDIT_REGISTER_UNIT_MEMBER: "udEditOwner",
        EDIT_REGISTER_VEHICLE_MEMBER: "udEditVehicle"
      }
    },
    udEditOwner: {
      on: {
        BACK: "udMain"
      }
    },
    udEditVehicle: {
      on: {
        BACK: "udMain"
      }
    }
  },
  on: {
    EDIT_REGISTER_UNIT_MEMBER: "udEditOwner",
    EDIT_REGISTER_VEHICLE_MEMBER: "udEditOwner",
    UNIT_DETAILS_MAIN: "udMain"
  }
})
