import React from "react"

export type CtxType = {
  paymentDetails: Record<string, any>
  setOpenDialog: Function
  setPaymentDetails: Function
  handleEditPayment: Function
  setShowPayment: Function
  showPayment: boolean
  submitting: boolean
  openDialog: boolean
  hasData: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
