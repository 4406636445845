import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toUnix } from "utils/date"

export function TotalFeedbackHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalReportFeedback = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalReportFeedback-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedback,
    {
      revalidateOnFocus: false
    }
  )
  // Cleanliness
  const fetchTotalReportFeedbackCleanliness = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 1
    })

  const { data: cleanliness, isValidating: isValidatingCleanliness } = useSWR(
    `fetchTotalReportFeedbackCleanliness-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedbackCleanliness,
    {
      revalidateOnFocus: false
    }
  )
  // Security
  const fetchTotalReportFeedbackSecurity = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 2
    })
  const { data: security, isValidating: isValidatingSecurity } = useSWR(
    `fetchTotalReportFeedbackSecurity-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedbackSecurity,
    {
      revalidateOnFocus: false
    }
  )
  // Defects
  const fetchTotalReportFeedbackDefects = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 3
    })
  const { data: defects, isValidating: isValidatingFeedbacks } = useSWR(
    `fetchTotalReportFeedbackDefects-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedbackDefects,
    {
      revalidateOnFocus: false
    }
  )
  // Landscape
  const fetchTotalReportFeedbackLandscape = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 4
    })
  const { data: landscape, isValidating: isValidatingLandscape } = useSWR(
    `fetchTotalReportFeedbackLandscape-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedbackLandscape,
    {
      revalidateOnFocus: false
    }
  )
  // Others
  const fetchTotalReportFeedbackOthers = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 5
    })
  const { data: others, isValidating: isValidatingOthers } = useSWR(
    `fetchTotalReportFeedbackOthers-${unixFrom}-${unixTo}`,
    fetchTotalReportFeedbackOthers,
    {
      revalidateOnFocus: false
    }
  )

  const totalFeedback = data?.data?._data?.total
  const totalCleanliness = cleanliness?.data?._data?.total
  const totalSecurity = security?.data?._data?.total
  const totalDefects = defects?.data?._data?.total
  const totalLandscape = landscape?.data?._data?.total
  const totalOthers = others?.data?._data?.total

  const validatingRequests =
    isValidatingCleanliness &&
    isValidating &&
    isValidatingSecurity &&
    isValidatingFeedbacks &&
    isValidatingLandscape &&
    isValidatingOthers

  return {
    totalFeedback,
    totalCleanliness,
    totalSecurity,
    totalLandscape,
    totalDefects,
    totalOthers,
    isValidating,
    error,
    validatingRequests
  }
}
