import * as React from "react"

interface PostProps {
  color: string
}

const Post: React.FC<PostProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V15C0 16.645 1.35503 18 3 18H17C18.645 18 20 16.645 20 15V4H18V15C18 15.565 17.565 16 17 16C16.435 16 16 15.565 16 15V0H0ZM2 2H14V15C14 15.3883 14.279 15.658 14.416 16H3C2.43497 16 2 15.565 2 15V2ZM4 4V7H12V4H4ZM4 9V11H12V9H4ZM4 13V15H12V13H4Z"
        fill={color}
      />
    </svg>
  )
}

export default Post
