import React from "react"

import Provider, {
  ProviderProps
} from "context/Dashboard/Announcements/Posts/forms/post/Provider"
import View from "context/Dashboard/Announcements/Posts/forms/post/View"

const PostForm: React.FC<ProviderProps> = ({ sendBack, data }) => (
  <Provider sendBack={sendBack} data={data}>
    <View />
  </Provider>
)

export default PostForm
