import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const Attendance: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("attendance")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* View */}
        <Checkbox
          label={null}
          value={Boolean(data?.attendance_view)}
          onChange={() =>
            handleUpdate("attendance_view", Boolean(!data?.attendance_view))}
        />
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.attendance_create)}
          onChange={() =>
            handleUpdate("attendance_create", Boolean(!data?.attendance_create))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.attendance_edit)}
          onChange={() =>
            handleUpdate("attendance_edit", Boolean(!data?.attendance_edit))}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.attendance_remove)}
          onChange={() =>
            handleUpdate("attendance_remove", Boolean(!data?.attendance_remove))}
        />
      </Box>
    </Box>
  )
}
export default Attendance
