import React, { useState } from "react"
import { toast } from "react-toastify"
import { UploadImage } from "utils/uploadFile"

/* Service */
import FeedbackAPI from "services/Dashboard/Feedback"

/* Context */
import { ReplyContext } from "./ReplyContext"

export interface ReplyProviderProps {
  showForm: Function
  isCreate: boolean
  data: Record<string, any>
}

const ReplyProvider: React.FC<ReplyProviderProps> = ({
  showForm,
  isCreate,
  data,
  children
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [photos, setPhotos] = useState(Array)
  const [attaching, setAttaching] = useState(false)
  const [previewImage, setPreviewImage] = useState({})
  const [replyDetails, setReplyDetails] = useState({
    message: "",
    status: "",
    isUrgent: "",
    staffName: "",
    role: ""
  })
  const [activeFeedback, setActiveFeedback] = React.useState([])
  const [messages] = React.useState([])

  /** For editing feedback reply */
  const [isEditing, setIsEditing] = React.useState(false)
  const [activeReply, setActiveReply] = React.useState({
    uid: "",
    reply: "",
    photos: []
  })

  /** Notification */
  const notify = () =>
    toast("Successfully replied to the feedback.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyUpdates = () =>
    toast("Successfully updated your reply.", {
      type: toast.TYPE.SUCCESS
    })

  const getFeedback = () => {
    FeedbackAPI.getFeedback(data?._uid)
      .then((response) => {
        const responseData = response.data._data
        setActiveFeedback(responseData)
      })
      .catch((error) => error)
  }

  const handleUpdateStatus = async (uid: string, status: string) => {
    if (replyDetails.status === "") {
      return null
    }

    const payload = {
      "feedback_uid": uid,
      "status": parseInt(status, 0)
    }

    try {
      const response = await FeedbackAPI.updateStatus(payload)
      return response
    } catch (e) {
      return e
    }
  }

  const handleIsUrgent = async (uid: string, status: boolean) => {
    const payload = {
      "feedback_uid": uid,
      "is_urgent": status
    }

    try {
      const response = await FeedbackAPI.setUrgency(payload)
      window.scrollTo(0, 0)
      return response
    } catch (e) {
      return e
    }
  }

  const handleAssignToStaff = async (uid: string, accountId: string) => {
    if (replyDetails?.staffName === "") {
      return
    }

    const payload = {
      "feedback_uid": uid,
      "assigned_to_staff_account_uid": accountId
    }

    try {
      await FeedbackAPI.assignToStaff(payload)
    } catch (error) {
      throw Error(error)
    }
  }

  function handleImageUpload(event: any) {
    if (event) {
      setPreviewImage(window?.URL?.createObjectURL(event[0]))
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  const removePhoto = (value: number) => {
    const fileFiltered = photos.filter((_item: any, index: number) => {
      return index !== value
    })
    setPreviewImage({})
    setPhotos(fileFiltered)
  }

  const handleFeedbackReply = async () => {
    const payload = {
      "feedback_uid": data?._uid,
      "reply": replyDetails.message,
      "feedback_reply_photo": {
        "photos": photos
      }
    }

    try {
      const response = await FeedbackAPI.createFeedbackReply(payload)
      setReplyDetails({
        ...replyDetails,
        message: ""
      })
      setPhotos([])
      handleAssignToStaff(data?._uid, replyDetails?.staffName)
      handleUpdateStatus(data?._uid, replyDetails?.status)
      handleIsUrgent(data?._uid, Boolean(replyDetails?.isUrgent))

      notify()
      return response
    } catch (e) {
      return e
    }
  }

  const handleFeedbackReplyUpdate = async () => {
    const payload = {
      "reply": activeReply?.reply,
      "feedback_reply_photo": {
        "photos": photos
      }
    }

    try {
      const response = await FeedbackAPI.updateReply(payload, activeReply?.uid)
      setActiveReply({
        ...activeReply,
        reply: "",
        uid: ""
      })
      setIsEditing(false)
      setPhotos([])
      getFeedback()
      notifyUpdates()
      return response
    } catch (e) {
      return e
    }
  }

  const handleActiveMessage = (message: any) => {
    if (isEditing) {
      setIsEditing(false)
      setActiveReply({
        uid: "",
        reply: "",
        photos: []
      })
      setPhotos([])
    } else {
      setIsEditing(true)
      setActiveReply({
        ...activeReply,
        uid: message._uid,
        reply: message.reply
      })
      setPhotos(
        message.feedback_reply_photo.photos !== null
          ? message.feedback_reply_photo.photos
          : []
      )
    }
  }

  return (
    <>
      <ReplyContext.Provider
        value={{
          data,
          showForm,
          isCreate,
          activeFeedback,
          openDialog,
          attaching,
          photos,
          replyDetails,
          messages,
          isEditing,
          activeReply,
          previewImage,
          setActiveReply,
          handleActiveMessage,
          handleFeedbackReplyUpdate,
          handleImageUpload,
          handleFeedbackReply,
          removePhoto,
          getFeedback,
          setOpenDialog,
          setPhotos,
          setReplyDetails,
          setIsEditing
        }}
      >
        {children}
      </ReplyContext.Provider>
    </>
  )
}

export default ReplyProvider
