import * as React from "react"

const Cleaners = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2398 0V11.1445C12.5661 11.5944 11.3133 13.1497 11.3133 15V16.9277C11.3133 17.642 11.1175 17.7197 10.8656 17.8535C10.6137 17.9873 10.3379 18 10.3379 18V20H16.1907C17.7954 20 19.1172 18.645 19.1172 17V15C19.1172 13.1497 17.8645 11.5944 16.1907 11.1445V0H14.2398ZM3.2066 0.976562L1.53953 2.01562L3.9001 6H0.405914L2.68266 20H8.38692V18H4.3364L2.71124 8H5.08516L6.57695 10.5195H6.57885C6.66578 10.6661 6.78803 10.7873 6.93384 10.8714C7.07965 10.9556 7.24411 10.9998 7.41144 11C7.67015 11 7.91827 10.8946 8.1012 10.7071C8.28414 10.5196 8.38692 10.2652 8.38692 10C8.38664 9.81667 8.33721 9.63694 8.24402 9.48047H8.24593L3.2066 0.976562ZM6.9237 1C6.47693 1 6.0624 1.13266 5.70245 1.34766L7.86869 5H12.2393C12.2705 4.838 12.2888 4.671 12.2888 4.5C12.2888 3.119 11.1973 2 9.85013 2C9.54286 2 9.25155 2.06588 8.98135 2.17188C8.54921 1.46987 7.79285 1 6.9237 1ZM8.44979 6L9.63484 8H11.1381L10.597 11.3281C11.193 10.5421 11.9715 9.91186 12.8718 9.50586L13.4415 6H8.44979ZM15.2153 13C16.3045 13 17.1662 13.8833 17.1662 15V17C17.1662 17.565 16.7419 18 16.1907 18H12.9633C13.0901 17.6588 13.2643 17.3523 13.2643 16.9277V15C13.2643 13.8833 14.126 13 15.2153 13Z"
      fill="#454B57"
    />
  </svg>
)

export default Cleaners
