import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  totalStyle: {
    color: theme.palette.body.gray,
    fontSize: "64px",
    fontWeight: 500,
    "& > small": {
      fontSize: "30px",
      marginLeft: "-10px"
    }
  },
  body: {
    color: theme.palette.body.dark,
    fontSize: "14px",
    display: "flex"
  },
  tfrInfo: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "26px 30px"
  },
  feedbackList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 30px",
    borderTop: "1px solid #F2F2F2"
  },
  subtitleStyle: {
    color: theme.palette.body.dark,
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 300,
    padding: "3px"
  },
  container: {
    display: "flex",
    "& > div": {
      width: "calc(100% /3)",
      borderRight: "1px solid #F2F2F2"
    }
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  options: {
    display: "flex",
    flexWrap: "wrap",
    padding: "12px 50px 26px",
    justifyContent: "center"
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
    paddingBottom: "8px"
  },
  optionName: {
    fontSize: "10px",
    color: theme.palette.body.secondary
  },
  optionColor: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px"
  }
}))

export default styles
