import * as React from "react"

const BulkyDelivery: React.FC = () => {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5704 0V4H8.67753L12.5347 8L16.3918 4H13.499V0H11.5704ZM5.0671 3.08203L0.867188 5.08789L3.8561 8.34766V15.9414L11.6344 19.7695C11.9208 19.9265 12.2364 20.0039 12.546 20.0039C12.8459 20.0039 13.1397 19.9301 13.3991 19.7871L21.2132 15.9395V8.34766L24.2022 5.08789L20.0041 3.08203L18.5483 4.5918L20.9458 5.73438L19.6331 7.16797L17.213 5.97656L15.7665 7.47656L17.9286 8.54102L12.5648 10.9844L12.5347 11L12.5045 10.9844L7.15011 8.54688L9.31411 7.49023L7.85449 5.97461L5.43436 7.16797L4.12165 5.73438L6.51918 4.5918L5.0671 3.08203ZM5.78467 10.1074L11.5704 12.7441V17.5234L5.78467 14.6758V10.1074ZM19.2847 10.1074V14.6719L13.499 17.4922V12.7422L19.2847 10.1074Z"
        fill="white"
      />
    </svg>
  )
}

export default BulkyDelivery
