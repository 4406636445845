import * as React from "react"

const StrataRoll: React.FC = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C6.794 0 5 1.794 5 4C5 6.206 6.794 8 9 8C11.206 8 13 6.206 13 4C13 1.794 11.206 0 9 0ZM19 0C17.355 0 16 1.35499 16 3C16 4.29316 16.8427 5.39514 18 5.8125V8H16V10H18V12H20V5.8125C21.1573 5.39514 22 4.29316 22 3C22 1.35499 20.645 0 19 0ZM9 2C10.103 2 11 2.897 11 4C11 5.103 10.103 6 9 6C7.897 6 7 5.103 7 4C7 2.897 7.897 2 9 2ZM19 2C19.5641 2 20 2.43587 20 3C20 3.56413 19.5641 4 19 4C18.4359 4 18 3.56413 18 3C18 2.43587 18.4359 2 19 2ZM9 11C5.859 11 0 12.545 0 15.5V18H18V15.5C18 12.545 12.141 11 9 11ZM7.66797 13.0859L8.125 14L7.79102 16H2V15.5C2 14.7472 4.78357 13.4267 7.66797 13.0859ZM10.332 13.0859C13.2164 13.4267 16 14.7472 16 15.5V16H10.209L9.875 14L10.332 13.0859Z"
        fill="white"
      />
    </svg>
  )
}

export default StrataRoll
