import * as React from "react"

interface StaffProps {
  color: string
}

const Staff: React.FC<StaffProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0V1.10156C6.04893 1.50205 4.50205 3.04893 4.10156 5H3V7H5C5 9.19733 6.80267 11 9 11C11.1973 11 13 9.19733 13 7H15V5H13.8984C13.498 3.04893 11.9511 1.50205 10 1.10156V0H8ZM9 3C10.3148 3 11.4088 3.83293 11.8184 5H6.18164C6.59123 3.83293 7.68515 3 9 3ZM7 7H11C11 8.11667 10.1167 9 9 9C7.88333 9 7 8.11667 7 7ZM9 12C7.25505 12 5.18716 12.4098 3.44922 13.0957C2.58025 13.4387 1.7947 13.846 1.16602 14.3691C0.537335 14.8922 0 15.5994 0 16.5V19H18V18V16.5C18 15.5994 17.4627 14.8922 16.834 14.3691C16.2053 13.846 15.4198 13.4387 14.5508 13.0957C12.8128 12.4098 10.745 12 9 12ZM9 14C9.87963 14 10.9548 14.157 12 14.4062V17H6V14.4062C7.04516 14.157 8.12037 14 9 14ZM4 15.0391V17H2V16.5C2 16.4346 2.06759 16.2205 2.44531 15.9062C2.78885 15.6204 3.35018 15.3118 4 15.0391ZM14 15.0391C14.6498 15.3118 15.2112 15.6204 15.5547 15.9062C15.9324 16.2205 16 16.4346 16 16.5V17H14V15.0391Z"
        fill={color}
      />
    </svg>
  )
}

export default Staff
