import * as React from "react"

const AccessCard: React.FC = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57031 0V1H2.74888C1.68528 1 0.820312 1.897 0.820312 3V18C0.820312 19.103 1.68528 20 2.74888 20H14.3203C15.3839 20 16.2489 19.103 16.2489 18V3C16.2489 1.897 15.3839 1 14.3203 1H9.49888V0H7.57031ZM2.74888 3H7.57031V4H9.49888V3H14.3203V18L2.74888 18.002V3ZM8.5346 7C8.02311 7 7.53257 7.21071 7.17089 7.58579C6.80922 7.96086 6.60603 8.46957 6.60603 9C6.60603 9.53043 6.80922 10.0391 7.17089 10.4142C7.53257 10.7893 8.02311 11 8.5346 11C9.04609 11 9.53663 10.7893 9.8983 10.4142C10.26 10.0391 10.4632 9.53043 10.4632 9C10.4632 8.46957 10.26 7.96086 9.8983 7.58579C9.53663 7.21071 9.04609 7 8.5346 7ZM8.5346 12C6.42763 12 4.67746 12.9088 4.67746 14.2168V15H12.3917V14.2168C12.3917 12.9088 10.6416 12 8.5346 12Z"
        fill="white"
      />
    </svg>
  )
}

export default AccessCard
