import React from "react"

import SettingsStaffProvider from "context/Settings/AccountManagement/Staff/main/Provider"
import SettingsStaffView from "context/Settings/AccountManagement/Staff/main/View"

const SettingsStaff: React.FC = () => (
  <SettingsStaffProvider>
    <SettingsStaffView />
  </SettingsStaffProvider>
)

export default SettingsStaff
