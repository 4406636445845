import React from "react"

import CreatePaymentProvider from "context/Dashboard/Bookkeeping/forms/createPayment/Provider"
import CreatePaymentView from "context/Dashboard/Bookkeeping/forms/createPayment/View"

/** Interface */
interface CreatePaymentProps {
  showPayment: boolean
  setShowPayment: Function
}

const CreatePaymentSchedule: React.FC<CreatePaymentProps> = ({
  showPayment,
  setShowPayment
}) => (
  <CreatePaymentProvider showPayment={showPayment} setShowPayment={setShowPayment}>
    <CreatePaymentView />
  </CreatePaymentProvider>
)

export default CreatePaymentSchedule
