import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
/** Components */
import Table from "pages/Dashboard/VisitorManagement/tables/vmsAll"
import TableHeader from "components/TableHeader"
/** Context */
import { MenuIcons } from "pages/Dashboard/VisitorManagement/config/Menu"
import { AppContext } from "App"
import { Context as ParentContext } from "context/Dashboard/VMS/categories/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"

const VMSAllView: React.FC<CtxType> = ({ activeFilter, showAddForm }) => {
  const { permission } = useContext(AppContext)
  const { downloadData } = useContext(ParentContext)

  const csvFilename = `VMS-All.csv`

  return (
    <Card>
      <Box>
        <TableHeader
          addActionLabel="Add New"
          hasSearch
          addAction={() => showAddForm()}
          title="All VMS"
          titleIcon={MenuIcons[0]}
          actionDisable={permission?.vms_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Table activeFilter={activeFilter} />
      </Box>
    </Card>
  )
}

export default withContext(VMSAllView)
