import React from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Formik } from "formik"

/** Component */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"

/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/autoCreateUser/Context"
import withContext from "context/Dashboard/UnitManagement/forms/autoCreateUser/withContext"

/** Config */
import {
  addUserPersonalDetailsOwner,
  addUserPersonalDetailsTenant
} from "config/Dashboard/UnitManagement/validation"

/** Refs */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/autoCreateUser/View"
import styles from "./styles"

const PersonalDetails: React.FC<CtxType> = ({ userDetails, setuserDetails }) => {
  const { generalInfo, spaceBottom, errorMessage } = styles()

  const {
    name,
    phoneNo,
    mobileNo,
    strataTitle,
    email,
    registeredAddress,
    formType,
    isResident
  } = userDetails

  /** Methods */
  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFieldValue(key, e?.target?.value)
    setuserDetails &&
      setuserDetails({
        ...userDetails,
        [key]: e?.target?.value
      })
  }

  const handleFormValue = (
    setFieldValue: Function,
    key: string,
    value: string | boolean
  ): void => {
    setFieldValue(key, value)
    setuserDetails &&
      setuserDetails({
        ...userDetails,
        [key]: value
      })
  }

  const isOwner = formType === "owner"
  const validation = isOwner
    ? addUserPersonalDetailsOwner
    : addUserPersonalDetailsTenant

  return (
    <div className={generalInfo}>
      <FormWrapper title="Personal Details" width="50%">
        <Formik
          initialValues={{
            name,
            mobileNo,
            phoneNo,
            email,
            registeredAddress
          }}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={validation}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form onSubmit={handleSubmit}>
                <Box width="50%" margin="auto" padding="0 0 30px 0">
                  {isOwner && (
                    <div className={spaceBottom}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={strataTitle}
                              checked={strataTitle}
                              onChange={(): void =>
                                handleFormValue(
                                  setFieldValue,
                                  "strataTitle",
                                  !strataTitle
                                )}
                              color="primary"
                            />
                          }
                          label="Name in Title-Deed"
                        />
                      </FormGroup>
                      {!isResident && !strataTitle ? (
                        <small className={errorMessage}>
                          Owner must be either residing or strata title.
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <Box display="flex" marginBottom="30px">
                    <Box flex="1">
                      <FormInput
                        name="name"
                        value={name}
                        idValue="name"
                        label="Name"
                        placeholder="Name"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "name", e)}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" marginBottom="30px">
                    <Box flex="1" marginRight="15px">
                      <FormInput
                        name="mobileNo"
                        value={mobileNo}
                        idValue="mobileNo"
                        label="Contact Number"
                        placeholder="Contact Number"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "mobileNo", e)}
                        onBlur={handleBlur}
                        error={touched.mobileNo && Boolean(errors.mobileNo)}
                        helperText={
                          errors.mobileNo && touched.mobileNo && errors.mobileNo
                        }
                      />
                    </Box>
                    <Box flex="1">
                      <FormInput
                        name="phoneNo"
                        value={phoneNo}
                        idValue="phoneNo"
                        label="Phone Number"
                        placeholder="Phone Number"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "phoneNo", e)}
                        onBlur={handleBlur}
                        error={touched.phoneNo && Boolean(errors.phoneNo)}
                        helperText={
                          errors.phoneNo && touched.phoneNo && errors.phoneNo
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex" marginBottom="30px">
                    <Box flex="1">
                      <FormInput
                        name="email"
                        value={email}
                        idValue="email"
                        label="Email Address"
                        placeholder="Email Address"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "email", e)}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </Box>
                  </Box>

                  {formType === "owner" && (
                    <Box display="flex" marginBottom="30px">
                      <Box flex="1">
                        <FormInput
                          name="registeredAddress"
                          value={registeredAddress}
                          idValue="registeredAddress"
                          label="Registered Address"
                          placeholder="Registered Address"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(setFieldValue, "registeredAddress", e)}
                          onBlur={handleBlur}
                          error={
                            touched.registeredAddress &&
                            Boolean(errors.registeredAddress)
                          }
                          helperText={
                            errors.registeredAddress &&
                            touched.registeredAddress &&
                            errors.registeredAddress
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <RefButton action={handleSubmit} refValue={refSubmit} />
              </form>
            )
          }}
        </Formik>
      </FormWrapper>
    </div>
  )
}

export default withContext(PersonalDetails)
