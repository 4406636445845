import React from "react"
import Box from "@material-ui/core/Box"
import Image from "material-ui-image"
/** Styles */
import { toUnix, checkDateBetween } from "utils/date"
import styles from "./styles"
/**
 * Utils
 */

/** Interface */
interface FacilitiesCardsProps {
  facilities: Record<string, any>
}

const FacilitiesCards: React.FC<FacilitiesCardsProps> = ({ facilities }) => {
  const classes = styles()

  if (facilities === undefined) {
    return <div />
  }

  return (
    <div className={classes.facilityContainer}>
      {facilities &&
        Object.keys(facilities).map((item: any) => {
          const fItem = facilities[item]
          const fPhoto = fItem?.facility_photo?.photos[0]?.url

          const suspendedStartDate = fItem?.suspended_start_date
          const suspendedEndDate = fItem?.suspended_end_date
          const isInBetween = checkDateBetween(
            toUnix(new Date()),
            suspendedStartDate,
            suspendedEndDate
          )

          function isOpen(): boolean {
            if (fItem?.status === 1) {
              return true
            }

            if (fItem?.status === 2 && !isInBetween) {
              return true
            }

            if (fItem?.status === 2 && isInBetween) {
              return false
            }

            return true
          }

          return (
            <Box className={classes.container}>
              <Box className={classes.imageContainer}>
                <Image src={fPhoto} aspectRatio={1 / 1} />
              </Box>
              <Box>
                <Box>
                  <Box className={classes.nameStyle}>{fItem?.name}</Box>
                </Box>
                <Box>
                  <Box className={classes.status}>
                    {`${isOpen() ? `Open` : `Suspended`}`}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
    </div>
  )
}

export default FacilitiesCards
