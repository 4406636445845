import React from "react"

export type CtxType = {
  handleImageUpload: Function
  setOpenDialog: Function
  setuserDetails: Function
  handleRegisterUser: Function
  setPhotos: Function
  removePhoto: Function
  setFiles: Function
  handleFileUpload: Function
  removeFile: Function
  openDialog: boolean
  attaching: boolean
  fileAttaching: boolean
  userDetails: Record<string, any>
  files: any
  photos: any
  localPhotos: any
}

export const Context = React.createContext<Partial<CtxType>>({})
