const RolesConfig: any = {
  RolesCategory: {
    1: "Admin",
    2: "Security",
    3: "Cleaning",
    4: "Landscape",
    5: "Maintenance",
    6: "Others"
  }
}

export const { RolesCategory } = RolesConfig
