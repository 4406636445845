import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

import FormReset from "pages/Settings/AccountManagement/Staff/components/FormReset/main"

/** Context */
import { Context } from "context/Settings/AccountManagement/Staff/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"

const View: React.FC<CtxType> = () => {
  const { resetForm, showResetForm } = useContext(Context)

  const MainView = (): JSX.Element => {
    return (
      <Box>
        <FormReset
          openState={resetForm || false}
          setopenState={() => {
            showResetForm && showResetForm(false)
          }}
        />
      </Box>
    )
  }

  return <>{MainView()}</>
}

export default withContext(View)
