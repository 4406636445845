import React from "react"
import CsvDownload from "react-json-to-csv"
import Button from "@material-ui/core/Button"

/**
 * Styles
 */
import styles from "./styles"

const IconDownload = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C7.68294 0 7 0.682937 7 1.5V8H2.58594L10 15.4141L17.4141 8H13V1.5C13 0.682937 12.3171 0 11.5 0H8.5ZM9 2H11V10H12.5859L10 12.5859L7.41406 10H9V2ZM0 18V20H20V18H0Z"
      fill="#09707B"
    />
  </svg>
)

interface DownloadCSVProps {
  data: any
  filename: string
}

const DownloadCSV: React.FC<DownloadCSVProps> = ({ data, filename }) => {
  const classes = styles()
  return (
    <CsvDownload data={data} filename={filename} className={classes.container}>
      <Button size="medium" color="primary" startIcon={IconDownload} tabIndex={0}>
        Download CSV
      </Button>
    </CsvDownload>
  )
}
export default DownloadCSV
