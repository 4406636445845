import React from "react"

import AddUpdateProvider, {
  AddVisitorProps
} from "context/Dashboard/VMS/add-update/AddUpdateProvider"
import AddUpdateView from "context/Dashboard/VMS/add-update/AddUpdateView"

const AddUpdateMain: React.FC<AddVisitorProps> = ({ showform, data, settings }) => (
  <AddUpdateProvider showform={showform} data={data} settings={settings}>
    <AddUpdateView />
  </AddUpdateProvider>
)

export default AddUpdateMain
