import React from "react"
import ContextMenu from "components/ContextMenu"
import { checkValue } from "utils/helpers"
import ContextItems from "./ContextMenu"

const tableHeaders = () => {
  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any): JSX.Element => {
            return (
              <ContextMenu>
                <ContextItems data={row?.original} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: "",
      columns: [
        {
          Header: "Block/Unit No",
          id: "unit_no",
          accessor: (c: any) => checkValue(c?.unit[0]?.short_name),
          Cell: ({ original }: any) => checkValue(original?.unit[0]?.short_name),
          width: 150
        },
        {
          Header: "Name",
          id: "name",
          accessor: (c: any) => checkValue(c?.name),
          Cell: ({ original }: any) => checkValue(original?.name)
        },
        {
          Header: "Email",
          id: "email",
          accessor: (c: any) => checkValue(c?.email),
          Cell: ({ original }: any) => checkValue(original?.email)
        },
        {
          Header: "Mobile No.",
          id: "mobile_no",
          accessor: (c: any) => checkValue(c?.mobile_no),
          Cell: ({ original }: any) => checkValue(original?.mobile_no)
        },
        {
          Header: "Registered Address",
          id: "registered_address",
          accessor: (c: any) => checkValue(c?.registered_address),
          Cell: ({ original }: any) => checkValue(original?.registered_address)
        },
        {
          Header: "Access Code",
          id: "access_code",
          accessor: (c: any) => checkValue(c?.access_code),
          Cell: ({ original }: any) => checkValue(original?.access_code)
        }
      ]
    }
    // {
    //   fixed: "right",
    //   disableFilters: true,
    //   columns: [
    //     {
    //       Header: "Action",
    //       Cell: (row: any): JSX.Element => {
    //         return (
    //           <Button
    //             color="primary"
    //             onClick={() => resetCode && resetCode(row?.original?._uid)}
    //           >
    //             RESET CODE
    //           </Button>
    //         )
    //       },
    //       filterable: false
    //     }
    //   ]
    // }
  ]
}

export default tableHeaders
