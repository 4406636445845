import React, { useContext } from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
/**
 * Components
 */
import Logo from "assets/images/Cora_logo.png"
import { AppContext } from "App"
import HeaderDots from "./Components/HeaderDots"
import UserBox from "./Components/UserBox"
/**
 * Context
 */
/**
 * Styles
 */
import styles from "./styles"

const Header: React.FC = () => {
  const classes = styles()
  const { condoInfo } = useContext(AppContext)

  return (
    <AppBar position="fixed" className={classes.header}>
      <Toolbar style={{ padding: 0 }}>
        <Grid
          justify="space-between"
          alignItems="center"
          container
          style={{ height: "81px", flexWrap: "nowrap" }}
        >
          <Box className={classes.logoContainer}>
            <img src={Logo} alt="Cora" />
          </Box>

          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
            padding="0 30px"
          >
            <Box className={classes.condoName}>{condoInfo?.name}</Box>
            <Box display="flex" alignItems="center" style={{ height: "100%" }}>
              <HeaderDots />
              <Divider
                orientation="vertical"
                style={{ height: "30px", backgroundColor: "#454B57" }}
              />
              <UserBox />
            </Box>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
