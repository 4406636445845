import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

import FormWrapper from "components/Forms/FormWrapper"

interface SelectCategoryProps {
  options: any
  value: string
  action: Function
}

const SelectCategory: React.FC<SelectCategoryProps> = ({
  value,
  action,
  options
}) => {
  return (
    <FormWrapper title="Select Category">
      <Box paddingLeft="30px">
        <FormControl>
          <RadioGroup
            aria-label="entry"
            name="entry"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => action(e)}
          >
            {options !== undefined &&
              Object.keys(options).map((item: any) => {
                return (
                  <FormControlLabel
                    key={options[item]?._uid}
                    value={options[item]?._uid}
                    control={<Radio color="primary" />}
                    label={options[item]?.title}
                    labelPlacement="end"
                  />
                )
              })}

            {/* <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Single"
            labelPlacement="end"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Multiple"
            labelPlacement="end"
          /> */}
          </RadioGroup>
        </FormControl>
      </Box>
    </FormWrapper>
  )
}

export default SelectCategory
