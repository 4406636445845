import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  container: {
    backgroundColor: theme.palette.common.white,
    minHeight: "101px"
  },
  totalStyle: {
    color: theme.palette.body.brown,
    fontSize: "64px",
    fontWeight: 500
  },
  bodyStyle: {
    color: theme.palette.body.dark,
    fontSize: "14px"
  },
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  body: {
    color: theme.palette.body.dark,
    fontSize: "14px",
    display: "flex"
  },
  subtitleStyle: {
    color: theme.palette.body.dark,
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 300,
    padding: "3px"
  },
  buttonItem: {
    lineHeight: "18px",
    fontSize: "14px",
    color: theme.palette.body.secondary,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "30px",
    height: "125px",
    textAlign: "center",
    cursor: "pointer",
    width: "calc(100% / 3)",
    "&.active": {
      backgroundColor: "rgba(73, 91, 111, 0.3)",
      color: theme.palette.body.secondary
    }
  },
  content: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "26px 50px",
    flex: "1",
    borderRight: "1px solid #F2F2F2"
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 50px",
    borderTop: "1px solid #F2F2F2",
    "& > div:first-child": {
      borderTop: "1px solid transparent"
    },
    "& .feedbackListName": {
      paddingLeft: "20px"
    }
  },
  listContainer: {
    "& > div:first-child": {
      borderTop: "1px solid transparent"
    }
  }
}))

export default styles
