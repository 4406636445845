import React from "react"
import ContextMenu from "components/ContextMenu"
import { fromUnix } from "utils/date"
import ContextItems from "./ContextMenu"
/** utils */

const tableHeaders = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Block/Unit No",
        id: "blockUnitNo",
        accessor: "unit.short_name",
        width: 150
      },
      {
        Header: "Owner Name",
        id: "owner_name",
        accessor: "owner_name",
        width: 150
      },
      {
        Header: "Vehicle Number",
        id: "vehicle_no",
        accessor: "vehicle_no",
        width: 150
      },
      {
        Header: "Vehicle Model",
        id: "vehicle_model",
        accessor: "vehicle_model",
        width: 200
      },
      {
        Header: "Car Label",
        id: "car_label",
        accessor: "car_label",
        Cell: ({ original }: Record<string, any>) => {
          return original?.car_label === "" ? "N/A" : original?.car_label
        },
        width: 150
      },
      {
        Header: "Vehicle Electronic Tag Number",
        id: "iu_no",
        accessor: "iu_no",
        width: 200
      },
      {
        Header: "Activation Date",
        id: "activationDate",
        accessor: (c: Record<string, any>) => {
          return fromUnix(c?.activation_date)
        },
        Cell: ({ original }: Record<string, any>) => {
          return fromUnix(original?.activation_date)
        },
        width: 150
      },
      {
        Header: "Expiry Date",
        id: "expiryDate",
        accessor: (c: Record<string, any>) => {
          return c?.expiry_date === 0 || c?.expiry_date === null
            ? "N/A"
            : fromUnix(c?.expiry_date)
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.expiry_date === 0 || original?.expiry_date === null
            ? "N/A"
            : fromUnix(original?.expiry_date)
        }
      }
    ]
  }
]

export default tableHeaders
