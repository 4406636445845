import React from "react"

import CreatePaymentScheduleProvider from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/Provider"
import CreatePaymentScheduleView from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/View"

const CreatePaymentSchedule: React.FC = () => (
  <CreatePaymentScheduleProvider>
    <CreatePaymentScheduleView />
  </CreatePaymentScheduleProvider>
)

export default CreatePaymentSchedule
