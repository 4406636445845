import React, { useState } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Context */
import service from "services/Dashboard/UnitManagement"
import bookKeepingService from "services/Dashboard/Bookkeeping"
import { Context } from "./Context"

/**
 * Service
 */

/** Interface */
export interface UMMainProviderProps {
  showUpdateUser: Function
  showCreateFromData: Function
  showCreateVehicle: Function
  showEditVehicle: Function
  showVehicleDetails: Function
  showCreateAccessCards: Function
  showEditAccessCards: Function
  showAccessCardsDetails: Function
  showCreateOwner: Function
  showEditOwner: Function
  showCreateResident: Function
  showEditResident: Function
  showAddPS: Function
  showEditPS: Function
  showPSDetails: Function
  showDetails: Function
  showMain: Function
  activeTable: number
  activeView: any
  activeData: Record<string, any>
}

const UMMainProvider: React.FC<UMMainProviderProps> = ({
  showUpdateUser,
  showCreateFromData,
  showCreateVehicle,
  showEditVehicle,
  showVehicleDetails,
  showCreateAccessCards,
  showEditAccessCards,
  showAccessCardsDetails,
  showCreateResident,
  showEditResident,
  showCreateOwner,
  showEditOwner,
  showAddPS,
  showEditPS,
  showPSDetails,
  // archivePS,
  showDetails,
  showMain,
  children,
  activeTable,
  activeView,
  activeData
}) => {
  /** State */
  const [activeFilter, setActiveFilter] = useState(0)
  const [downloadData, setDownloadData] = useState([])
  const [resetting, setResetting] = useState(false)

  const toastMessageSuccess = `Successfully reset code.`
  const toastMessageError = `Error in resetting the code.`

  const notifyReset = (message: string, type: string) =>
    toast(message, {
      type: type === "SUCCESS" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
    })

  const notifyArchived = () =>
    toast("Successfully archived.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  async function handleResetCode(accountUID: string, endpoint: string) {
    setResetting(true)
    try {
      const response = await service.resetCode(accountUID)
      notifyReset(toastMessageSuccess, "SUCCESS")
      mutate(endpoint)
      setResetting(false)
      return response
    } catch (e) {
      notifyReset(toastMessageError, "ERROR")
      setResetting(false)
      return e
    }
  }

  async function handleArchived(itemId: string, endpoint: string) {
    try {
      const response = await service.archivedResident(itemId)
      notifyArchived()
      mutate(endpoint)
      return response
    } catch (e) {
      return e
    }
  }

  async function handlePSArchive(itemId: string) {
    try {
      const response = await bookKeepingService.archiveSchedulePayment({
        "_uid": itemId
      })
      notifyArchived()
      mutate(`fetchScheduledPayment-${activeFilter}`)
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        filterTable,
        setActiveFilter,
        setDownloadData,
        handleResetCode,
        handleArchived,
        resetting,
        downloadData,
        activeFilter,
        activeTable,
        showAddPS,
        showEditPS,
        showPSDetails,
        handlePSArchive,
        showDetails,
        showMain,
        showCreateOwner,
        showEditOwner,
        showCreateResident,
        showEditResident,
        activeView,
        activeData,
        showCreateVehicle,
        showEditVehicle,
        showVehicleDetails,
        showCreateAccessCards,
        showEditAccessCards,
        showAccessCardsDetails,
        showCreateFromData,
        showUpdateUser
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default UMMainProvider
