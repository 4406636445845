import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"
import ReactToPrint from "react-to-print"

/** Components */
import DateFilter from "pages/Dashboard/Reports/components/DateFilter"

/** Assets */
import IconPrint from "../../icons"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end"
  },
  printButton: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "14px",
    cursor: "pointer"
  }
}))

// interface
interface CTAProps {
  refElement: any
  ele: any
  activeDate: any
  setActiveDate: Function
}

const CTA: React.FC<CTAProps> = ({ refElement, ele, activeDate, setActiveDate }) => {
  const { container, printButton } = useStyles()
  return (
    <Box className={container}>
      <Box>
        <DateFilter
          month={activeDate?.month}
          year={activeDate?.year}
          setActiveDate={setActiveDate}
        />
      </Box>
      <ReactToPrint
        trigger={() => (
          <Box display="flex" alignItems="center" marginLeft="15px">
            {IconPrint}
            <Box marginLeft="15px" className={printButton}>
              PRINT REPORT
            </Box>
          </Box>
        )}
        content={() => (refElement.current ? refElement.current : ele)}
      />
    </Box>
  )
}

export default CTA
