import React from "react"
import Box from "@material-ui/core/Box"
import useSWR from "swr"
import Cookie from "js-cookie"

/** API */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Context */
import dateHelper from "utils/date"
import { toNumeral } from "utils/helpers"

/** Styles */
import styles from "../../styles"

/** Icons */
import { IconFacilities } from "../../icons"

interface TotalPaymentsMaintenanceProps {
  dates: any
  currency: string
}

const TotalPaymentsMaintenance: React.FC<TotalPaymentsMaintenanceProps> = ({
  dates,
  currency
}) => {
  const { section, iconContainer, type, amount } = styles()

  const fetchMaintenanceStats = async () =>
    BookkeepingAPI.getTotalCollections({
      "condo_uid": Cookie.get("condoUID"),
      "start_date": `${dateHelper.toUnix(dates.first)}`,
      "end_date": `${dateHelper.toUnix(dates.last)}`,
      "payment_type": "1",
      "category": "Maintenance"
    })

  const { data } = useSWR("fetchMaintenanceStats", fetchMaintenanceStats, {
    revalidateOnFocus: true
  })

  const oPayments = data?.data?._data !== undefined && data?.data?._data

  return (
    <Box className={section} justifyContent="space-between" paddingBottom="15px">
      <Box display="flex" alignItems="center">
        <Box className={iconContainer} bgcolor="#0E61A2">
          {IconFacilities}
        </Box>
        <Box className={type}>Maintenance</Box>
      </Box>
      <Box>
        <Box className={amount}>
          <small>{currency}</small>
          {toNumeral(oPayments?.amt)}
        </Box>
      </Box>
    </Box>
  )
}
export default TotalPaymentsMaintenance
