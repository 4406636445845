import React from "react"
import Box from "@material-ui/core/Box"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
/**
 * Styles
 */
import styles from "./styles"

const Icon = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C7.172 0 6.5 0.672 6.5 1.5V2.19531C3.91318 2.86209 2 5.2048 2 8V14L0 16V17H6.26953C6.09344 17.3039 6.00048 17.6488 6 18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20C8.53043 20 9.03914 19.7893 9.41421 19.4142C9.78929 19.0391 10 18.5304 10 18C9.99893 17.6486 9.90529 17.3037 9.72852 17H16V16L14 14V8C14 5.2048 12.0868 2.86209 9.5 2.19531V1.5C9.5 0.672 8.828 0 8 0ZM8 4C10.206 4 12 5.794 12 8V14V14.8281L12.1719 15H3.82812L4 14.8281V14V8C4 5.794 5.794 4 8 4Z"
      fill="#464646"
    />
  </svg>
)

interface Props {
  value: number
  handleChange: Function
}

const FacilitiesHeader: React.FC<Props> = ({ value, handleChange }) => {
  const { container, title } = styles()

  const selectTab = (event: any, newValue: number) => {
    handleChange(newValue)
  }

  return (
    <Box className={container}>
      <Box className={title}>
        <Box marginRight="20px">{Icon}</Box>
        Expiring Soon
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={selectTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Contracts" />
          <Tab label="Licence and Permit" />
          <Tab label="Cyclic Service" />
          <Tab label="Others" />
        </Tabs>
      </Box>
    </Box>
  )
}

export default FacilitiesHeader
