import React, { useEffect } from "react"
/** Component */
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
/** Utils */
import { isEmpty } from "utils/functions"
import Forms from "./Forms"
import Facilities from "./Facilities"
/** Styles */
import styles from "./styles"

const Payment: React.FC<CtxType> = ({
  facilityBookings,
  setSelectedForm,
  selectedForm
}) => {
  const classes = styles()
  const fBooking = facilityBookings[0] as any

  useEffect(() => {
    isEmpty(selectedForm) &&
      setSelectedForm({
        facilityUID: fBooking?.facility?._uid,
        timeslotUID: fBooking?.facility_time_slot_uid,
        isDeposit: true
      })
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.containerDetails}>
        <Facilities />
      </div>
      <div>
        <Forms />
      </div>
    </div>
  )
}

export default withContext(Payment)
