import React, { FunctionComponent } from "react"

import AccountSettingsProvider, {
  Props
} from "context/Settings/AccountManagement/Staff/forms/accountSettings/Provider"
import AccountSettingsView from "context/Settings/AccountManagement/Staff/forms/accountSettings/View"

const AccountSettings: FunctionComponent<Props> = ({ sendBack }) => (
  <AccountSettingsProvider sendBack={sendBack}>
    <AccountSettingsView />
  </AccountSettingsProvider>
)

export default AccountSettings
