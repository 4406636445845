import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import BlockUnit from "components/Common/BlockUnit/new"
import Names from "components/Common/Names"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"

/** Context */
import { Context as MainContext } from "context/Dashboard/Applications/forms/addApplication/Context"

/** Validation */
import { addApplicationsResidentInfo } from "config/Dashboard/Applications/validation"

/** Styles */
import { refSubmit } from "context/Dashboard/Applications/forms/addApplication/View"
import { handleResidentName, handleBlockUnit } from "utils/functions"
import styles from "./styles"

const ResidentInfo: React.FC = () => {
  const { generalInfo } = styles()
  const { setApplicationDetails, applicationDetails } = React.useContext(MainContext)
  const ad = applicationDetails

  return (
    <div className={generalInfo}>
      <Formik
        initialValues={{
          unitUID: ad?.unitUID,
          applicantName: ad?.applicantName
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addApplicationsResidentInfo}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <FormWrapper title="Personal Details" width="40%">
                <div
                  className="section"
                  style={{
                    width: "40%",
                    margin: "auto"
                  }}
                >
                  <Box>
                    <Box marginBottom="25px">
                      <BlockUnit
                        iValue={ad?.unitUID}
                        onChange={(value: string) =>
                          handleBlockUnit(
                            setFieldValue,
                            value,
                            setApplicationDetails,
                            applicationDetails,
                            "unitUID",
                            "applicantUID"
                          )}
                        onBlur={(e: Event): void => handleBlur(e)}
                        error={touched.unitUID && Boolean(errors.unitUID)}
                        helperText={
                          errors.unitUID && touched.unitUID && String(errors.unitUID)
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <Names
                        value={ad?.applicantName}
                        onChange={(value: Event) =>
                          handleResidentName(
                            setFieldValue,
                            value,
                            setApplicationDetails,
                            applicationDetails,
                            "applicantName",
                            "applicantUID"
                          )}
                        unitUID={applicationDetails?.unitUID}
                        onBlur={(e: Event): void => handleBlur(e)}
                        error={
                          touched.applicantName && Boolean(errors.applicantName)
                        }
                        helperText={
                          errors.applicantName &&
                          touched.applicantName &&
                          String(errors.applicantName)
                        }
                      />
                    </Box>
                  </Box>
                </div>
                <RefButton refValue={refSubmit} action={handleSubmit} />
              </FormWrapper>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ResidentInfo
