import React, { FunctionComponent } from "react"

import AddAdminProvider, {
  Props
} from "context/Settings/AccountManagement/Admin/forms/admin/Provider"
import AddAdminView from "context/Settings/AccountManagement/Admin/forms/admin/View"

const AddAdmin: FunctionComponent<Props> = ({ data }) => (
  <AddAdminProvider data={data}>
    <AddAdminView />
  </AddAdminProvider>
)

export default AddAdmin
