import React, { useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/accountsRequests/Context"

/** Styles */
import { IconView } from "components/Icons/ContextMenu"
import styles from "./styles"

/** Icons */

interface ContextNewAccountRequestsProps {
  data: Record<string, any>
}

const ContextNewAccountRequests: React.FC<ContextNewAccountRequestsProps> = ({
  data
}) => {
  const { listItem } = styles()
  const { showDetailsView } = useContext(ParentContext)

  function handleShowDetails() {
    showDetailsView && showDetailsView(true, data)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails()}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
      </List>
    </>
  )
}

export default ContextNewAccountRequests
