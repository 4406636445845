import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import UploadBox from "components/UploadBox/new"

/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/autoCreateUser/Context"
import withContext from "context/Dashboard/UnitManagement/forms/autoCreateUser/withContext"

/** Styles */
import styles from "./styles"

const UploadImage: React.FC<CtxType> = ({
  handleImageUpload,
  photos,
  removePhoto,
  attaching,
  localPhotos
}) => {
  const { root } = styles()

  return (
    <div className={root}>
      <FormWrapper title="Upload Image" width="50%">
        <Box width="50%" margin="auto" padding="0 0 40px 0">
          <UploadBox
            onDrop={handleImageUpload}
            files={photos}
            previewImages={localPhotos}
            removePhoto={removePhoto}
            attaching={attaching}
            hasTitle={false}
          />
        </Box>
      </FormWrapper>
    </div>
  )
}

export default withContext(UploadImage)
