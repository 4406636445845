import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const MyAttendance: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("my attendance")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.my_attendance_edit)}
          onChange={() =>
            handleUpdate("my_attendance_edit", Boolean(!data?.my_attendance_edit))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.my_attendance_view_self)}
          onChange={() =>
            handleUpdate(
              "my_attendance_view_self",
              Boolean(!data?.my_attendance_view_self)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        {/* <Checkbox
          label={null}
          value={Boolean(data?.my_attendance_view_all)}
          onChange={() =>
            handleUpdate(
              "my_attendance_view_all",
              Boolean(!data?.my_attendance_view_all)
            )}
        /> */}
        <Box />
      </Box>
    </Box>
  )
}
export default MyAttendance
