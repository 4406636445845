import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import { Context as MainContext } from "context/Dashboard/UnitManagement/categories/main/Context"
// import {Context as MainContext} from "context/Dashboard/UnitManagement/categories/unitDetails/Context"

import Users from "./components/RegisteredUsers"
import Vehicles from "./components/RegisteredVehicles"

interface UnitDetailsProps {
  showEdit: Function
  showEditVehicle: Function
  unitUID?: string
}

export const WrapperContext = React.createContext<Partial<UnitDetailsProps>>({})

const UnitDetails: React.FC<UnitDetailsProps> = ({ showEdit, showEditVehicle }) => {
  const { activeData } = React.useContext(MainContext)

  const MainView = (): JSX.Element => {
    return (
      <WrapperContext.Provider
        value={{
          showEditVehicle,
          showEdit,
          unitUID: activeData?.unit_uid
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box flex="1" marginRight="25px">
              {/* <Details /> */}
            </Box>
            <Box flex="1">{/* <DetailsUnit /> */}</Box>
          </Box>
          {/* Registered Users */}
          <Box paddingTop="25px">
            <Users />
          </Box>
          <Box paddingTop="25px">
            <Vehicles />
          </Box>
        </Box>
      </WrapperContext.Provider>
    )
  }

  return <>{MainView()}</>
}

export default UnitDetails
