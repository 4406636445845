import { Machine } from "xstate"

export const ApplicationsMachine = Machine({
  id: "applicationsMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_APPLICATION: "addApplication"
      }
    },
    addApplication: {
      on: {
        BACK: "tableView"
      }
    },
    updateApplication: {
      on: {
        BACK: "tableView"
      }
    },
    approveApplication: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_APPLICATION: "addApplication",
    UPDATE_APPLICATION: "updateApplication",
    APPROVE_APPLICATION: "approveApplication"
  }
})
