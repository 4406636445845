import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: "16px",
    backgroundColor: "rgba(119, 126, 134, 0.08)",
    border: "1px solid rgba(69, 75, 87, 0.12)",
    color: theme.palette.body.dark,
    marginBottom: "5px",
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.common.white
    },
    "&.current": {
      backgroundColor: "rgba(225, 126, 35, 0.5) !important",
      border: `rgba(225, 126, 35, 0.5) !important`,
      color: theme.palette.common.white
    }
  }
}))

export default styles
