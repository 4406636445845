/* eslint global-require: "warn"*/
import React from "react"
/**
 * Component
 */
import Facilityitem from "./FacilityItem"
/**
 * Service
 */
/**
 * styles
 */
import styles from "./styles"

interface Props {
  facilitiesList: Record<string, any>
  settings: Record<string, any>
}

function FacilityList({ facilitiesList, settings }: Props): JSX.Element {
  const classes = styles()

  return (
    <div className={classes.facilityGrid}>
      {Object.keys(facilitiesList).map((facility: string) => {
        return (
          <Facilityitem facility={facilitiesList[facility]} settings={settings} />
        )
      })}
    </div>
  )
}
export default FacilityList
