import React, { useContext, useState } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
import { mutate } from "swr"
/** Components */
import Drawer from "components/Drawer/new"
import Header from "components/Header"
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import Label from "components/Forms/Label"
/**
 * Utils
 */
import { fromUnix } from "utils/date"
/**
 * Validation
 */
import { rejectBooking } from "config/Dashboard/Facilities/validation"
/**
 * Context
 */
import { Context } from "context/Dashboard/Facilites/main/Context"
/**
 * Utils
 */
import { checkValue, checkDate } from "utils/helpers"
/**
 * Styles
 */
import styles from "./styles"

interface Props {
  openState: boolean
  setOpenState: Function
  data?: Record<string, any>
}

const RejectBooking: React.FC<Props> = ({ openState, setOpenState, data }) => {
  const { value } = styles()
  const [remarks, setRemarks] = useState<string>("")
  const {
    isFormSubmitting,
    handleUpdateStatusBooking,
    activeMenu,
    activeFilter
  } = useContext(Context)

  const categoryName = activeMenu?.category_name
  const dataType = data?.type
  const isNoshow = dataType === "noshow"
  const drawerTitle = `${dataType === "noshow" ? "No Show" : dataType} Booking`

  /** Methods */
  function handleReject(handleSubmit: Function) {
    handleSubmit()

    const payloadReject = {
      "_uid": data?._uid,
      "rejected_remarks": remarks
    }

    const payloadCancel = {
      "_uid": data?._uid,
      "cancelled_remarks": remarks
    }

    const payloadNoshow = {
      "_uid": data?._uid,
      "no_show": true
    }

    function payload(type: string) {
      switch (type) {
        case "reject":
          return payloadReject
        case "cancel":
          return payloadCancel
        case "noshow":
          return payloadNoshow
        default:
          return payloadReject
      }
    }

    if (!isNoshow) {
      if (remarks !== "") {
        return (
          handleUpdateStatusBooking &&
          handleUpdateStatusBooking(payload(dataType)).then(() => [
            setOpenState(false),
            setRemarks(""),
            mutate(`fetchBookings-${categoryName}-${activeFilter}`)
          ])
        )
      }
    } else {
      handleUpdateStatusBooking &&
        handleUpdateStatusBooking(payload("noshow")).then(() => [
          setOpenState(false),
          setRemarks(""),
          mutate(`fetchBookings-${categoryName}-${activeFilter}`)
        ])
    }

    return payloadReject
  }

  return (
    <>
      <Drawer openState={openState} setOpenState={setOpenState}>
        <Header title={drawerTitle} />

        <Box padding="40px 30px">
          <Box borderBottom="1px solid #F2F2F2">
            <Box marginBottom="15px">
              <Label label="Booking by" />
              <Box className={value}>{checkValue(data?.booking_by_name)}</Box>
            </Box>

            <Box marginBottom="15px">
              <Label label="Booking Date" />
              <Box className={value}>
                {checkDate(data?.booking_date, fromUnix(data?.booking_date))}
              </Box>
            </Box>

            <Box marginBottom="15px">
              <Label label="Reference No." />
              <Box className={value}>
                {checkValue(data?.facility_booking_reference_no)}
              </Box>
            </Box>
          </Box>

          <Formik
            initialValues={{
              remarks
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={rejectBooking}
            isInitialValid={false}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue
            }): JSX.Element => {
              return (
                <form>
                  <FormWrapper>
                    <Box marginBottom="25px">
                      {!isNoshow && (
                        <FormInput
                          name="remarks"
                          value={remarks}
                          idValue="remarks"
                          label="Remarks"
                          placeholder="Remarks"
                          multiline
                          rows={3}
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): any => [
                            setRemarks(e?.target?.value),
                            setFieldValue("remarks", e?.target?.value)
                          ]}
                          onBlur={handleBlur}
                          error={touched.remarks && Boolean(errors.remarks)}
                          helperText={
                            errors.remarks && touched.remarks && errors.remarks
                          }
                        />
                      )}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box flex="1" maxWidth="49%">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => setOpenState(false)}
                          className="btn-cancel"
                          fullWidth
                          disabled={isFormSubmitting}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box flex="1" maxWidth="49%">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => handleReject(handleSubmit)}
                          className="btn-cancel"
                          fullWidth
                          disabled={isFormSubmitting}
                        >
                          {isFormSubmitting ? "Submitting..." : "Submit"}
                        </Button>
                      </Box>
                    </Box>
                  </FormWrapper>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Drawer>
    </>
  )
}

export default RejectBooking
