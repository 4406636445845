import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import UploadBox from "components/UploadBox/new"

/** Context */
import { Context } from "context/Dashboard/EstateMatters/PublicDocuments/forms/document/Context"

const UploadDoc: React.FC = () => {
  const {
    handleFileUpload,
    files,
    removeFile,
    fileAttaching,
    uploadError
  } = useContext(Context)

  return (
    <FormWrapper title="Upload Document">
      <Box padding="0 0 40px 0">
        <UploadBox
          hasTitle={false}
          onDrop={(event: any) => handleFileUpload && handleFileUpload(event)}
          files={files}
          removePhoto={(event: any) => removeFile && removeFile(event)}
          attaching={fileAttaching}
          title="Choose file to upload"
          acceptedFile="application/pdf"
          hasError={uploadError}
        />
        <Box component="small" color="red">
          * Document is required.
        </Box>
      </Box>
    </FormWrapper>
  )
}

export default UploadDoc
