import * as React from "react"

const VehicleRegistration = (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.20417 0C3.58703 0 3.03645 0.407672 2.83684 1.01367L0.857422 7V14V15C0.857422 15.552 1.28942 16 1.82171 16H2.78599C3.31828 16 3.75028 15.552 3.75028 15V14H15.3217V15C15.3217 15.552 15.7537 16 16.286 16H17.2503C17.7826 16 18.2146 15.552 18.2146 15V13V7L16.2351 1.01367C16.0355 0.407672 15.485 0 14.8678 0H4.20417ZM4.54883 2H14.5232L15.5157 5H3.55629L4.54883 2ZM2.89523 7H16.1768L16.286 7.33203V12H2.78599V7.33203L2.89523 7ZM5.19671 8C4.81309 8 4.44519 8.15804 4.17393 8.43934C3.90267 8.72064 3.75028 9.10218 3.75028 9.5C3.75028 9.89782 3.90267 10.2794 4.17393 10.5607C4.44519 10.842 4.81309 11 5.19671 11C5.58033 11 5.94823 10.842 6.21949 10.5607C6.49075 10.2794 6.64314 9.89782 6.64314 9.5C6.64314 9.10218 6.49075 8.72064 6.21949 8.43934C5.94823 8.15804 5.58033 8 5.19671 8ZM13.8753 8C13.4917 8 13.1238 8.15804 12.8525 8.43934C12.5812 8.72064 12.4289 9.10218 12.4289 9.5C12.4289 9.89782 12.5812 10.2794 12.8525 10.5607C13.1238 10.842 13.4917 11 13.8753 11C14.2589 11 14.6268 10.842 14.8981 10.5607C15.1693 10.2794 15.3217 9.89782 15.3217 9.5C15.3217 9.10218 15.1693 8.72064 14.8981 8.43934C14.6268 8.15804 14.2589 8 13.8753 8Z"
      fill="#454B57"
    />
  </svg>
)

export default VehicleRegistration
