const version = "v1"
const base = "svc-account"

const root = `${base}/${version}`

const API = {
  LIST_ROLES: `${root}/user-roles`,
  CREATE_ROLE: `${root}/user-role`,
  UPDATE_PERMISSION: `${root}/user-role-permission`,
  LIST_ROLE_PERMISSION: `${root}/user-role-permission`,
  LIST_ADMINS: `${root}/admin/users`,
  RESET_PASSWORD: `${root}/capba`,
  ADMIN_USER: `${root}/admin-user`,
  UPDATE_USER: `${root}/admin-user`,
  DELETE_USER: `${root}/admin-user`,
  REMOVE_ROLE: `${root}/user-role`
}

export default API
