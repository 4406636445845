import * as React from "react"

interface AttendanceProps {
  color: string
}

const Attendance: React.FC<AttendanceProps> = ({ color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9996 0C11.6996 0 8.99963 2.7 8.99963 6C8.99963 9.3 11.6996 12 14.9996 12C18.2996 12 20.9996 9.3 20.9996 6C20.9996 2.7 18.2996 0 14.9996 0ZM9.70471 0.0136719C5.71271 0.151672 2.46474 3.20519 2.03674 7.11719L0.144165 10.0879C-0.160835 10.5649 0.0372276 11.2009 0.558228 11.4199L1.98401 12.0215V15C1.98401 16.105 2.87901 17 3.98401 17H5.98401V20H14.984V17.2402C14.984 15.7302 15.2821 14.7538 15.6871 13.9648C15.4601 13.9848 15.2316 14 14.9996 14C14.5036 14 14.0195 13.9483 13.5485 13.8613C13.2185 14.7303 12.984 15.8072 12.984 17.2402V18H7.98401V15H3.98401V10.6934L2.76135 10.1777L2.52112 10.0762L3.72229 8.19336L3.97229 7.80078L4.02307 7.33789C4.27507 5.04289 5.80515 3.19497 7.85315 2.41797C8.31415 1.50097 8.94371 0.686672 9.70471 0.0136719ZM14.9996 2C17.1996 2 18.9996 3.8 18.9996 6C18.9996 8.2 17.1996 10 14.9996 10C12.7996 10 10.9996 8.2 10.9996 6C10.9996 3.8 12.7996 2 14.9996 2ZM15.9 3.19922L14.1989 6.09961L16.4 8.30078L17.3004 7.30078L15.8004 5.90039L16.9996 3.80078L15.9 3.19922Z"
        fill={color}
      />
    </svg>
  )
}

export default Attendance
