import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import UnitManagementAPI from "services/Dashboard/UnitManagement"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import tableHeaders from "../../components/tableHeaders/registered"

const TableActive: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const { setDownloadData } = useContext(ParentContext)

  /** Methods */
  const fetchActiveOwners = async () =>
    UnitManagementAPI.getResidents({
      "owner_or_tenant": "1",
      "strata_title": true,
      "is_deactivated": false,
      "is_registered": false
    })

  const { data, isValidating } = useSWR("fetchActiveOwners", fetchActiveOwners, {
    revalidateOnFocus: true
  })

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data).reverse()
      : []

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData}
          columns={tableHeaders(`fetchActiveOwners`)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              values.length !== 0 &&
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Block/Unit No.": i?.unitNumber,
                    "Name": i?.name,
                    "Email": i?.email,
                    "Mobile No.": i?.mobileNumber,
                    "Registered Address": i?.registeredAddress,
                    "Access Code": i?.accessCode
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TableActive
