import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
/** components */
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import WithTable from "layout/WithTable"
import DetailsSummary from "pages/Dashboard/UnitManagement/components/AccessCards/Details"
import CreateAccessCards from "pages/Dashboard/UnitManagement/components/AccessCards/CreateAccessCards"
import ActiveTable from "pages/Dashboard/UnitManagement/tables/AccessCards/Active"
import ArchivedTable from "pages/Dashboard/UnitManagement/tables/AccessCards/Archived"
/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"
/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { CtxType } from "context/Dashboard/UnitManagement/main/Context"
import { AppContext } from "App"
import withMainContext from "context/Dashboard/UnitManagement/main/withContext"
/** Config */
import { filterAccessCards } from "config/Dashboard/UnitManagement"
import { Context as SelfContext } from "./Context"

const AccessCardsView: React.FC<CtxType> = ({ activeTableFilter }) => {
  const { activeView, showCreateAccessCards, downloadData } = useContext(
    ParentContext
  )
  const { showDetails, setShowDetails, activeData } = useContext(SelfContext)
  const { permission } = useContext(AppContext)
  const { accessCardsTab, setAccessCardsTab } = activeTableFilter
  const csvFilename = `AccessCards-${filterAccessCards[accessCardsTab]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <ActiveTable />
      case 1:
        return <ArchivedTable />
      default:
        break
    }
    return activeTableParam
  }

  const ViewDetails = (): JSX.Element => {
    return (
      <DetailsSummary
        openState={showDetails}
        setopenState={(val: any) => setShowDetails && setShowDetails(val)}
      />
    )
  }

  const ViewCreateAccessCards = (): JSX.Element => {
    if (activeView !== "createAccessCards") {
      return <div />
    }
    return <CreateAccessCards />
  }

  const ViewEditAccessCards = (): JSX.Element => {
    if (activeView !== "editAccessCards") {
      return <div />
    }
    return <CreateAccessCards data={activeData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }
    return (
      <Box>
        <TableHeader
          addActionLabel="Add Access Card"
          title="Access Cards"
          titleIcon={MenuIcons.accesscards}
          addAction={() => showCreateAccessCards && showCreateAccessCards()}
          actionDisable={permission?.unit_mgt_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={accessCardsTab}
            handleChange={setAccessCardsTab}
            options={filterAccessCards}
          />
        </Box>
        <Box>
          <Box>{TableView(accessCardsTab)}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewDetails()}
      {ViewCreateAccessCards()}
      {ViewEditAccessCards()}
    </>
  )
}

export default withMainContext(AccessCardsView)
