import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

// GET {{base_url}}/svc-facility/v1/estate-matter-folders?condo_uid=95e16b2c-f377-474d-9b9e-c24f3b62e336&type=1

function getEstateMattersFolder(params: Object) {
  return request({
    url: API.FETCH_ESTATE_MATTERS_FOLDER,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getEstateMatters(params: Object) {
  return request({
    url: API.FETCH_ESTATE_MATTERS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function createEstateMatter(data: Object) {
  return request({
    url: API.CREATE_DOCUMENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateEstateMatter(data: Object, uid: number) {
  return request({
    url: `${API.CREATE_DOCUMENT}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function deleteEstateMatter(uid: string) {
  return request({
    url: `${API.CREATE_DOCUMENT}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

const EstateMatters = {
  getEstateMattersFolder,
  getEstateMatters,
  createEstateMatter,
  updateEstateMatter,
  deleteEstateMatter
}

export default EstateMatters
