import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    width: "316px",
    margin: "0 auto 15px",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: 500
  },
  previewButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer"
  }
}))

export default styles
