import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"
import { Formik } from "formik"
import * as Yup from "yup"

/** Components */
import FormInput from "components/Forms/FormInput"
import Footer from "components/Forms/Footer"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Bookkeeping/main/Context"

interface Props {
  activeView: number
}

const CategoryForm: React.FC<Props> = ({ activeView }) => {
  const { categories, setCategories, updateBookkeeping } = useContext(Context)
  const cv = categories[activeView]

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    handleChange: Function,
    setFieldValue: Function,
    target: number
  ) => {
    handleChange(e)
    setFieldValue(name, e?.target?.value)
    setCategories &&
      setCategories({
        ...categories,
        [target]: {
          ...categories[target],
          [name]: e?.target?.value
        }
      })
  }

  const handleFormSubmit = (handleSubmit: Function) => {
    handleSubmit()
    if (cv?.category !== "") {
      updateBookkeeping && updateBookkeeping()
    }
  }

  return (
    <Box>
      <Formik
        initialValues={{
          typeName: cv?.category,
          isEnabled: cv?.is_enabled
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          typeName: Yup.string().required("Required")
        })}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange
        }): JSX.Element => {
          return (
            <form>
              <Box marginBottom="25px">
                <FormInput
                  name="typeName"
                  value={cv?.category}
                  idValue="typeName"
                  label="Type Name"
                  placeholder="Type Name"
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(
                      "category",
                      e,
                      handleChange,
                      setFieldValue,
                      activeView
                    )}
                  onBlur={handleBlur}
                  error={touched.typeName && Boolean(errors.typeName)}
                  helperText={errors.typeName && touched.typeName && errors.typeName}
                />
              </Box>
              <Box>
                <Checkbox
                  label="Disabled"
                  value={cv?.is_enabled || false}
                  onChange={() =>
                    setCategories &&
                    setCategories({
                      ...categories,
                      [activeView]: {
                        ...categories[activeView],
                        "is_enabled": !cv?.is_enabled
                      }
                    })}
                />
              </Box>
              <Box marginTop="40px">
                <Footer
                  handleNext={() => handleFormSubmit(handleSubmit)}
                  handleBack={null}
                  handleCancel={() => null}
                  hideCancel
                  label="Submit"
                  stretch
                  noBorder
                />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default CategoryForm
