import React from "react"

/** Components */
import StatusBlock from "components/Table/components/StatusBlock"
import ContextMenu from "components/ContextMenu"

/** Config */
import { status, statusColor } from "config/Dashboard/Applications"

/** Utils */
import { fromUnix, fromUnixTime } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import ContextApplications from "./ContextMenu"

const TableHeaderColumns = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: any) => {
          return (
            <ContextMenu data-testid="context-moveinout">
              <ContextApplications data={row?._original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Status",
        id: "status",
        sortable: false,
        filterable: false,
        accessor: (c: any) => checkValue(c.status, status[c.status]),
        Cell: ({ original }: any) =>
          checkValue(
            original.status,
            <StatusBlock
              status={status[original.status]}
              color={statusColor[original.status]}
            />
          ),

        width: 150
      },
      {
        Header: "Reference No.",
        id: "referenceNumber",
        accessor: (c: any) => checkValue(c?.reference_no),
        Cell: ({ original }: any) => checkValue(original?.reference_no),
        width: 200
      },
      {
        Header: "Date Received",
        id: "dateReceived",
        accessor: (c: any) =>
          checkDate(c.application_date, fromUnix(c.application_date)),
        Cell: ({ original }: any) =>
          checkDate(original.application_date, fromUnix(original.application_date)),
        width: 170
      },
      {
        Header: "Block/Unit No.",
        id: "blockUnitNumber",
        accessor: (c: any) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Name",
        id: "applicantName",
        accessor: (c: any) => checkValue(c?.applicant_name),
        Cell: ({ original }: any) => checkValue(original?.applicant_name),
        width: 250
      },
      {
        Header: "Start Date",
        id: "startDate",
        accessor: (c: any) =>
          checkDate(
            c?.request_data?.move_in_out?.eta_ms,
            fromUnix(c?.request_data?.move_in_out?.eta_ms)
          ),
        Cell: ({ original }: any) =>
          checkDate(
            original?.request_data?.move_in_out?.eta_ms,
            fromUnix(original?.request_data?.move_in_out?.eta_ms)
          ),
        width: 170
      },
      {
        Header: "Start Time",
        id: "applicationTime",
        accessor: (c: any) =>
          checkDate(
            c?.request_data?.move_in_out?.eta_ms,
            fromUnixTime(c?.request_data?.move_in_out?.eta_ms, true)
          ),
        Cell: ({ original }: any) =>
          checkDate(
            original.request_data?.move_in_out?.eta_ms,
            fromUnixTime(original.request_data?.move_in_out?.eta_ms, true, "HH:mm")
          ),
        width: 170
      },
      {
        Header: "Purpose",
        id: "purpose",
        accessor: (c: any) => checkValue(c?.request_data?.move_in_out?.purpose),
        Cell: ({ original }: any) =>
          checkValue(original?.request_data?.move_in_out?.purpose),
        width: 200
      },
      {
        Header: "Remarks",
        id: "remarks",
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => checkValue(original?.remarks),
        width: 300
      },
      {
        Header: "Reject Remarks",
        id: "remarks",
        width: 300,
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => {
          const statuses = original?.status_update_time?.status_changes
          let rejRemarks = ""
          if (statuses && statuses.length > 0) {
            statuses.forEach((ele: any) => {
              if (ele.status_change_to === 3 && original?.status === 3) {
                rejRemarks = ele.rejected_remarks
              }
            })
          }
          return checkValue(rejRemarks)
        }
      }
    ]
  }
]

export default TableHeaderColumns
