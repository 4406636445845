import React from "react"
// import Drawer from "react-motion-drawer"
import Drawer from "@material-ui/core/Drawer"
import { makeStyles } from "@material-ui/core/styles"

// components
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    minWidth: "350px",
    display: "flex",
    flexDirection: "column"
  },
  closeButton: {},
  drawer: {
    flexShrink: 0
  },
  closeBtn: {
    "&.light": {
      color: theme.palette.common.white
    },
    "&.dark": {
      color: theme.palette.common.black
    }
  }
}))

// interface
interface DrawerContainerProps {
  openState: boolean
  setopenState: Function
  children: any
  wide?: boolean
  width?: number
  closeBtnDark?: boolean
}

const DrawerContainer: React.FC<DrawerContainerProps> = ({
  openState,
  setopenState,
  children,
  closeBtnDark = false
}) => {
  const { drawer, content, closeBtn } = useStyles()
  const closeBtnColor = closeBtnDark ? "dark" : "light"

  return (
    <Drawer
      anchor="right"
      open={openState}
      onClose={() => setopenState(false)}
      className={drawer}
      transitionDuration={400}
    >
      <Box
        component="div"
        display="flex"
        position="absolute"
        top="10px"
        right="5px"
        zIndex="2"
        bgcolor="#FFFFF"
      >
        <IconButton
          className={`${closeBtn} ${closeBtnColor}`}
          onClick={() => setopenState(false)}
          aria-label="close"
        >
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Box component="div" className={content}>
        {children}
      </Box>
    </Drawer>
  )
}

export default DrawerContainer
