const AnnouncementsConfig: any = {
  FilterTabs: {
    0: "All",
    1: "Active",
    2: "Expired"
  },
  StatusValue: {
    1: "Draft",
    2: "Active",
    3: "Expired"
  }
}

export const { FilterTabs, StatusValue } = AnnouncementsConfig
