import React from "react"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"

interface ActionButtonProps {
  url: string
  name: string
  fileType?: number
}

const FileIcon = (
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.79171 0.708374C2.23526 0.708374 0.958374 1.98526 0.958374 3.54171V12.3959C0.958374 14.539 2.71109 16.2917 4.85421 16.2917C6.99732 16.2917 8.75004 14.539 8.75004 12.3959V4.25004H7.33337V12.3959C7.33337 13.7732 6.23151 14.875 4.85421 14.875C3.47691 14.875 2.37504 13.7732 2.37504 12.3959V3.54171C2.37504 2.75074 3.00074 2.12504 3.79171 2.12504C4.58268 2.12504 5.20837 2.75074 5.20837 3.54171V10.9792C5.20837 11.1825 5.05745 11.3334 4.85421 11.3334C4.65096 11.3334 4.50004 11.1825 4.50004 10.9792V4.25004H3.08337V10.9792C3.08337 11.949 3.88445 12.75 4.85421 12.75C5.82396 12.75 6.62504 11.949 6.62504 10.9792V3.54171C6.62504 1.98526 5.34815 0.708374 3.79171 0.708374Z"
      fill="#979797"
    />
  </svg>
)

const ImageIcon = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.906937 0 0 0.906937 0 2V14C0 15.0931 0.906937 16 2 16H18C19.0931 16 20 15.0931 20 14V2C20 0.906937 19.0931 0 18 0H2ZM2 2H18V14H2V2ZM8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5C7 5.26522 7.10536 5.51957 7.29289 5.70711C7.48043 5.89464 7.73478 6 8 6C8.26522 6 8.51957 5.89464 8.70711 5.70711C8.89464 5.51957 9 5.26522 9 5C9 4.73478 8.89464 4.48043 8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4ZM12.5 7L9 11L6.5 8.5L3.77734 12H16.25L12.5 7Z"
      fill="#777E86"
    />
  </svg>
)

const FileLink: React.FC<ActionButtonProps> = ({ url, name, fileType = 1 }) => {
  const Icon = fileType === 1 ? FileIcon : ImageIcon

  const FileView = (type: number): JSX.Element => {
    if (type !== 1) {
      return <div />
    }

    return <Box color="#777E86">{name}</Box>
  }

  return (
    <Link
      href={url}
      target="_blank"
      style={{ display: "flex", alignItems: "center" }}
    >
      <i style={{ display: "flex", paddingRight: "10px" }}>{Icon}</i>
      {FileView(fileType)}
    </Link>
  )
}
export default FileLink
