import React from "react"

import AccessCardsProvider from "context/Dashboard/Applications/categories/accessCards/Provider"
import AccessCardsView from "context/Dashboard/Applications/categories/accessCards/View"

export default () => (
  <AccessCardsProvider>
    <AccessCardsView />
  </AccessCardsProvider>
)
