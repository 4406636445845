import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Spacer from "components/Spacer"
import Attendance from "./components/Attendance"
import Application from "./components/Application"
import TotalRegisteredUnits from "./components/TotalRegisteredUnits"
import ExpiringSoon from "./components/ExpiringSoon"
import TotalRegisteredUsers from "./components/TotalRegisteredUsers"
import TotalResidents from "./components/TotalResidents"
import TotalTenantedUnits from "./components/TotalTenantedUnits"
import OutstandingPayments from "./components/OutstandingPayments"
import VisitorManagement from "./components/VisitorManagement"
import TotalVehicles from "./components/TotalVehicles"
import TotalAmountCollected from "./components/TotalAmountCollected"
import Feedback from "./components/Feedback"
import Facilities from "./components/Facilities"

interface Props {
  printRef: any
}

const Reports: React.FC<Props> = ({ printRef }) => {
  const spaceHeight = "23px"
  return (
    <>
      <div ref={printRef} style={{ width: "100%" }}>
        <Box display="flex">
          {/* Left */}
          <Box flex="2">
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              paddingRight="23px"
            >
              <Box flex="1" maxWidth="49%">
                <OutstandingPayments />
              </Box>
              <Box flex="1" maxWidth="49%">
                <TotalAmountCollected />
              </Box>
            </Box>
            <Spacer height={spaceHeight} />
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              paddingRight="23px"
            >
              <Box flex="1" maxWidth="49%">
                <VisitorManagement />
              </Box>
              <Box flex="1" maxWidth="49%">
                <Application />
              </Box>
            </Box>
            <Spacer height={spaceHeight} />
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              paddingRight="23px"
            >
              <Box flex="1">
                <Attendance />
              </Box>
            </Box>
          </Box>
          {/* Right */}
          <Box flex="1">
            <ExpiringSoon />
            <Spacer height={spaceHeight} />
            <TotalRegisteredUnits />
            <Spacer height={spaceHeight} />
            <TotalTenantedUnits />
            <Spacer height={spaceHeight} />
            <TotalRegisteredUsers />
            <Spacer height={spaceHeight} />
            <TotalResidents />
            <Spacer height={spaceHeight} />
            <TotalVehicles />
          </Box>
        </Box>
        <Spacer height={spaceHeight} />
        <Box>
          <Feedback />
        </Box>
        <Spacer height={spaceHeight} />
        <Box>
          <Facilities />
        </Box>
      </div>
    </>
  )
}

export default Reports
