import React, { useContext, useState } from "react"
import Fade from "@material-ui/core/Fade"

/** Components */
import Loader from "components/Loader"
import Table from "components/Table"
import { Context } from "context/Settings/AccountManagement/Admin/main/Context"
import tableHeaders from "./table/tableHeaders"

import AdminSettingsTableHook from "./hooks"

/** Context */

const TableSettingAdmin: React.FC = () => {
  const { showResetForm, setUserData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)
  /** Methods */
  const { isValidating, tableData } = AdminSettingsTableHook()

  function handleResetPassword(data: any) {
    showResetForm && showResetForm(true)
    setUserData && setUserData(data)
  }

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData}
          columns={tableHeaders(handleResetPassword)}
          minRows={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
        />
      </div>
    </Fade>
  )
}

export default TableSettingAdmin
