import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Table from "pages/Dashboard/UnitManagement/tables/PaymentSchedules/main"
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import WithTable from "layout/WithTable"
import CreatePaymentSchedule from "pages/Dashboard/UnitManagement/components/paymentSchedule/CreatePaymentSchedule"
import UpdatePaymentSchedule from "pages/Dashboard/UnitManagement/components/paymentSchedule/EditPaymentSchedule"
import DetailsSummary from "pages/Dashboard/UnitManagement/components/paymentSchedule/Details/Summary"

/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"

/** Config */
import { frequencyPS } from "config/Dashboard/Bookkeeping"

/** Context */
import withMainContext from "context/Dashboard/UnitManagement/categories/main/withContext"
import { CtxType } from "context/Dashboard/UnitManagement/categories/main/Context"
import { AppContext } from "App"

const PaymentScheduleView: React.FC<CtxType> = ({
  filterTable,
  activeFilter,
  showAddPS,
  activeView,
  activeData,
  downloadData
}) => {
  const { permission } = useContext(AppContext)

  const csvName = `PaymentSchedule-${frequencyPS[activeFilter]}.csv`

  const FilterTable = () => {
    return (
      <TabsFilter
        value={activeFilter}
        handleChange={filterTable}
        options={frequencyPS}
      />
    )
  }

  const ViewCreatePS = (): JSX.Element => {
    if (activeView !== "createPs") {
      return <div />
    }
    return <CreatePaymentSchedule />
  }

  const ViewEditPS = (): JSX.Element => {
    if (activeView !== "editPs") {
      return <div />
    }
    return <UpdatePaymentSchedule data={activeData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }

    return (
      <Box>
        <TableHeader
          addActionLabel="Create New"
          title="Payment Schedule"
          titleIcon={MenuIcons.paymentschedule}
          addAction={() => showAddPS()}
          actionDisable={permission?.payment_schedule_create}
          data={downloadData}
          fileName={csvName}
        />
        <Box padding="0 0 0 90px" borderBottom="1px solid #F2F2F2">
          {FilterTable()}
        </Box>
        <Table activeFilter={activeFilter} />
      </Box>
    )
  }

  const ViewDetails = (): JSX.Element => {
    if (activeView !== "detailsPS") {
      return <div />
    }
    return <DetailsSummary />
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewCreatePS()}
      {ViewEditPS()}
      {ViewDetails()}
    </>
  )
}

export default withMainContext(PaymentScheduleView)
