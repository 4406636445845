import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const TakeAttendance: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("take attendance")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.take_attendance_edit)}
          onChange={() =>
            handleUpdate(
              "take_attendance_edit",
              Boolean(!data?.take_attendance_edit)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Box />
        {/* <Checkbox
          label={null}
          value={Boolean(data?.take_attendance_view_self)}
          onChange={() =>
            handleUpdate(
              "take_attendance_view_self",
              Boolean(!data?.take_attendance_view_self)
            )}
        /> */}
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.take_attendance_view_all)}
          onChange={() =>
            handleUpdate(
              "take_attendance_view_all",
              Boolean(!data?.take_attendance_view_all)
            )}
        />
      </Box>
    </Box>
  )
}
export default TakeAttendance
