import React from "react"

import SettingsOthersProvider from "context/Settings/Others/CondoInfo/main/Provider"
import SettingsOthersView from "context/Settings/Others/CondoInfo/main/View"

const SettingsSOS: React.FC = () => (
  <SettingsOthersProvider>
    <SettingsOthersView />
  </SettingsOthersProvider>
)

export default SettingsSOS
