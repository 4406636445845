import * as React from "react"

const Landscape: React.FC = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6667 0.666664V15.526C17.3789 16.1259 15.6667 18.1996 15.6667 20.6667V23.237C15.6667 24.1893 15.399 24.293 15.0547 24.4714C14.7104 24.6497 14.3333 24.6667 14.3333 24.6667V27.3333H22.3333C24.5266 27.3333 26.3333 25.5266 26.3333 23.3333V20.6667C26.3333 18.1996 24.6211 16.1259 22.3333 15.526V0.666664H19.6667ZM4.58594 1.96875L2.30729 3.35416L5.53385 8.66666H0.757812L3.86979 27.3333H11.6667V24.6667H6.13021L3.90885 11.3333H7.15365L9.19271 14.6927H9.19531C9.31413 14.8881 9.48122 15.0497 9.68052 15.1619C9.87982 15.2741 10.1046 15.3331 10.3333 15.3333C10.687 15.3333 11.0261 15.1929 11.2761 14.9428C11.5262 14.6928 11.6667 14.3536 11.6667 14C11.6663 13.7556 11.5987 13.5159 11.4714 13.3073H11.474L4.58594 1.96875ZM9.66667 2C9.056 2 8.4894 2.17687 7.9974 2.46354L10.9583 7.33333H16.9323C16.975 7.11733 17 6.89466 17 6.66666C17 4.82533 15.508 3.33333 13.6667 3.33333C13.2467 3.33333 12.8485 3.42116 12.4792 3.5625C11.8885 2.6265 10.8547 2 9.66667 2ZM11.7526 8.66666L13.3724 11.3333H15.4271L14.6875 15.7708C15.5022 14.7228 16.5662 13.8825 17.7969 13.3411L18.5755 8.66666H11.7526ZM21 18C22.4889 18 23.6667 19.1778 23.6667 20.6667V23.3333C23.6667 24.0867 23.0867 24.6667 22.3333 24.6667H17.9219C18.0952 24.2117 18.3333 23.8031 18.3333 23.237V20.6667C18.3333 19.1778 19.5111 18 21 18Z"
        fill="white"
      />
    </svg>
  )
}

export default Landscape
