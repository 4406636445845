import React from "react"
import Button from "@material-ui/core/Button"
/** Components */
import ContextMenu from "components/ContextMenu"
/** Config */
import { checkValue } from "utils/helpers"
import ContextSettingsFeedback from "../../ContextMenu"

const TableHeaderColumns = (handleResetPassword: Function) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any) => {
          return (
            <ContextMenu>
              <ContextSettingsFeedback data={row.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Username",
        id: "username",
        accessor: (original: Record<string, any>) =>
          checkValue(original?.account_username),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.account_username)
      },
      {
        Header: "Name",
        id: "name",
        accessor: (original: Record<string, any>) =>
          checkValue(original?.account_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.account_name)
      },
      {
        Header: "Email",
        id: "email",
        accessor: (original: Record<string, any>) =>
          checkValue(original?.account_email),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.account_email)
      },
      {
        Header: "Phone Number",
        id: "phone_no",
        accessor: (original: Record<string, any>) =>
          checkValue(original?.account_mobile_no),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.account_mobile_no)
      },
      {
        Header: "User Roles",
        id: "user_role",
        accessor: (original: Record<string, any>) =>
          checkValue(original?.condo.user_role?.role_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.condo.user_role?.role_name)
      }
    ]
  },
  {
    fixed: "right",
    disableFilters: true,
    columns: [
      {
        Header: "",
        Cell: ({ original }: any): JSX.Element => {
          return (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleResetPassword(original)}
            >
              RESET PASSWORD
            </Button>
          )
        },
        filterable: false
      }
    ]
  }
]

export default TableHeaderColumns
