import * as React from "react"

const ReportsDelivery = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.02627 0C3.33858 0 2.75846 0.398438 2.36659 1L0.184409 4.59375C0.0845214 4.79297 0 5.19922 0 5.5V18C0 19.1016 0.883628 20 1.96703 20H17.7033C18.7867 20 19.6703 19.1016 19.6703 18V5.5C19.6703 5.19922 19.5858 4.80078 19.4859 4.5L17.3037 1C16.9119 0.398438 16.3318 0 15.6441 0H4.02627ZM4.02627 2H15.6441L16.812 4H2.85834L4.02627 2ZM1.96703 6H17.7033V18H1.96703V6ZM7.68372 8C7.14202 8.05078 6.74247 8.54297 6.79241 9.09375C6.84235 9.64453 7.32643 10.0508 7.86813 10H11.8022C12.1556 10.0039 12.486 9.81641 12.6666 9.50391C12.8433 9.19141 12.8433 8.80859 12.6666 8.49609C12.486 8.18359 12.1556 7.99609 11.8022 8H7.86813C7.8374 8 7.80666 8 7.77593 8C7.74519 8 7.71446 8 7.68372 8Z"
      fill="#454B57"
    />
  </svg>
)

export default ReportsDelivery
