import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"

/** Service */
import Service from "services/Dashboard/EstateMatters"

/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/EstateMatters/ConstructionManuals/main/Context"
import { toUnix } from "utils/date"
import tableHeaders from "./tableHeaders"

interface Props {
  showUpdate: Function
}

const TablePublicDocuments: React.FC<Props> = ({ showUpdate }) => {
  const {
    activeDoc,
    setActiveData,
    setShowDocument,
    accountRequestsTab,
    setDownloadData
  } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  const folderId = activeDoc?._uid

  const unixDateNow = toUnix(new Date().toString())

  const fetchManagementDocuments = async () =>
    Service.getEstateMatters({
      "estate_matter_folder_uid": folderId
    })

  const { data, isValidating } = useSWR(
    folderId ? `fetchManagementDocuments-${folderId}-${accountRequestsTab}` : null,
    fetchManagementDocuments,
    {
      revalidateOnFocus: true
    }
  )

  const tableData = data?.data?._data

  const FilteredData = () => {
    const active: Array<{}> = []
    const expired: Array<{}> = []
    tableData !== undefined &&
      tableData !== null &&
      Object.keys(tableData).map((i: any) => {
        if (tableData[i]?.start_date === 0) {
          return active.push(tableData[i])
        }

        if (
          unixDateNow >= tableData[i]?.start_date &&
          unixDateNow <= tableData[i]?.end_date
        ) {
          return active.push(tableData[i])
        }

        return expired.push(tableData[i])
      })

    return {
      active,
      expired
    }
  }

  const TableDocs = (tab: number) => {
    switch (tab) {
      case 0:
        return tableData
      case 1:
        return FilteredData()?.active
      case 2:
        return FilteredData()?.expired
      default:
        return tableData
    }
  }

  const viewFile = (activeData: Record<string, any>) => {
    setActiveData && setActiveData(activeData)
    setShowDocument && setShowDocument(true)
  }

  const showUpdateForm = (activeData: Record<string, any>) => {
    setActiveData && setActiveData(activeData)
    showUpdate && showUpdate()
  }

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={TableDocs(accountRequestsTab || 0) || []}
          columns={tableHeaders(viewFile, showUpdateForm)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          minRows={4}
          action={(values: any) => {
            return (
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "S/No.": i?.serialNumber,
                    "Description": i?.description,
                    "Title": i?.title,
                    "Remarks": i?.remarks,
                    "Frequency": i?.frequency,
                    "Quantity": i?.quantity,
                    "Start Date": i?.startDate,
                    "End Date": i?.endDate,
                    "Attachment": i?.attachment
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TablePublicDocuments
