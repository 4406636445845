import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { Formik } from "formik"
/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import BlockUnit from "components/Common/BlockUnit/new"
import Spacer from "components/Spacer"
import RefButton from "components/Forms/RefButton"
import DatePicker from "components/Forms/DatePicker"
/** Context */
import { CtxType as RegisterCtxTye } from "context/Dashboard/UnitManagement/forms/autoCreateUser/Context"
import withContext from "context/Dashboard/UnitManagement/forms/autoCreateUser/withContext"
/** Refs */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/autoCreateUser/View"
/** Config */
import {
  addOwnerGeneralInfo,
  addTenantGeneralInfo
} from "config/Dashboard/UnitManagement/validation"
import { handleBlockUnit } from "utils/functions"
import styles from "./styles"
/** helpers */

const GeneralInfo: React.FC<RegisterCtxTye> = ({ userDetails, setuserDetails }) => {
  const { userOptions, spaceBottom } = styles()

  const isOwner = userDetails?.formType === "owner"
  const { tenancyStart, tenancyEnd, formType, blockUnit, isResident } = userDetails
  const validation = isOwner ? addOwnerGeneralInfo : addTenantGeneralInfo

  /** Methods */
  const handleFormValue = (
    setFieldValue: Function,
    key: string,
    value: string | boolean
  ): void => {
    setFieldValue(key, value)
    setuserDetails &&
      setuserDetails({
        ...userDetails,
        [key]: value
      })
  }

  return (
    <div>
      <Box margin="auto" padding="0 0 40px 0">
        <FormWrapper title="General Info" width="40%">
          <Formik
            initialValues={{
              blockUnit,
              tenancyStart,
              tenancyEnd
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={validation}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue
            }): JSX.Element => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box margin="auto" width="40%" padding="0 0 30px 0">
                    <Box>
                      <RadioGroup
                        value={formType}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                          setuserDetails &&
                          setuserDetails({
                            ...userDetails,
                            formType: e.target.value
                          })}
                        className={userOptions}
                      >
                        <FormControlLabel
                          value="owner"
                          control={<Radio color="primary" />}
                          label="Owner"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="tenant"
                          control={<Radio color="primary" />}
                          label="Tenant"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </Box>

                    <Box padding="10px 0 0">
                      {isOwner && (
                        <div className={spaceBottom}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isResident}
                                  onChange={(): void =>
                                    handleFormValue(
                                      setFieldValue,
                                      "isResident",
                                      !isResident
                                    )}
                                  value={isResident}
                                  color="primary"
                                />
                              }
                              label="Residing in Unit"
                            />
                          </FormGroup>
                        </div>
                      )}
                    </Box>

                    <Box padding="10px 0 0">
                      <BlockUnit
                        iValue={blockUnit}
                        onChange={(value: string) =>
                          handleBlockUnit(
                            setFieldValue,
                            value,
                            setuserDetails,
                            userDetails,
                            "blockUnit"
                          )}
                        onBlur={(e: Event): void => handleBlur(e)}
                        error={touched.blockUnit && Boolean(errors.blockUnit)}
                        helperText={
                          errors.blockUnit &&
                          touched.blockUnit &&
                          String(errors.blockUnit)
                        }
                      />
                    </Box>

                    <Box>
                      {!isOwner && (
                        <Box display="flex" padding="30px 0 0">
                          <Box display="flex" marginBottom="25px">
                            <DatePicker
                              placeholder="mm/dd/yyyy"
                              format="MM/dd/yyyy"
                              label="Start Date"
                              name="startDate"
                              value={tenancyStart}
                              handleDateChange={(value: string): void =>
                                handleFormValue(setFieldValue, "tenancyStart", value)}
                              onBlur={(e: Event): void => handleBlur(e)}
                              error={
                                touched.tenancyStart && Boolean(errors.tenancyStart)
                              }
                              helperText={
                                touched.tenancyStart &&
                                errors.tenancyStart?.toString()
                              }
                            />
                          </Box>
                          <Spacer isDefault />
                          <Box display="flex" marginBottom="25px">
                            <DatePicker
                              placeholder="mm/dd/yyyy"
                              format="MM/dd/yyyy"
                              minDate={tenancyStart}
                              label="End Date"
                              name="endDate"
                              value={tenancyEnd}
                              handleDateChange={(value: string): void =>
                                handleFormValue(setFieldValue, "tenancyEnd", value)}
                              onBlur={(e: Event): void => handleBlur(e)}
                              error={
                                touched.tenancyEnd && Boolean(errors.tenancyEnd)
                              }
                              helperText={
                                touched.tenancyEnd && errors.tenancyEnd?.toString()
                              }
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <RefButton action={handleSubmit} refValue={refSubmit} />
                </form>
              )
            }}
          </Formik>
        </FormWrapper>
      </Box>
    </div>
  )
}

export default withContext(GeneralInfo)
