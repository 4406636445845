import React from "react"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import MetisMenu from "react-metismenu"
import classnames from "classnames"
import Box from "@material-ui/core/Box"
/** Components */
import Label from "components/Forms/Label"
import Applications from "assets/images/icons/sidebar/Applications"
import Bookkeeping from "assets/images/icons/sidebar/Bookkeeping"
import Facilities from "assets/images/icons/sidebar/Facilities"
import Feedback from "assets/images/icons/sidebar/Feedback"
import VisitorManagement from "assets/images/icons/sidebar/VisitorManagement"
import Admin from "assets/images/icons/sidebar/Admin"
import Staff from "assets/images/icons/sidebar/Staff"
import OwnersTenant from "assets/images/icons/sidebar/OwnersTenant"
import UploadCsv from "assets/images/icons/sidebar/UploadCsv"
import Sos from "assets/images/icons/sidebar/Sos"
import CondoInfo from "assets/images/icons/sidebar/CondoInfo"
/** Context */
// import { AppContext } from "App"
/** Config */
import { AMNav, OthersNav, PMNav } from "./NavItems"
/** Styles */
import "react-metismenu/dist/react-metismenu-standart.min.css"
import "../styles.scss"
import styles from "./styles"

interface LinkProps {
  className: any
  classNameActive: any
  classNameHasActiveChild: any
  active: any
  hasActiveChild: any
  to: any
  externalLink: any
  hasSubMenu: any
  toggleSubMenu: any
  activateMe: any
  children: any
  label: any
  test: string
  icon: string
}

const Item: React.FC<LinkProps> = ({
  className,
  classNameActive,
  classNameHasActiveChild,
  active,
  hasActiveChild,
  to,
  externalLink,
  hasSubMenu,
  toggleSubMenu,
  activateMe,
  label
}) => {
  const { link, labelStyle } = styles()

  const IconType = (iconName: string, color: string) => {
    switch (iconName) {
      case "Facilities":
        return <Facilities color={color} />
      case "Applications":
        return <Applications color={color} />
      case "Feedback":
        return <Feedback color={color} />
      case "Bookkeeping":
        return <Bookkeeping color={color} />
      case "Visitor Management":
        return <VisitorManagement color={color} />
      case "Admin":
        return <Admin color={color} />
      case "Staff":
        return <Staff color={color} />
      case "Owners/Tenants":
        return <OwnersTenant color={color} />
      case "Condo Info":
        return <CondoInfo color={color} />
      case "SOS":
        return <Sos color={color} />
      case "Upload CSV":
        return <UploadCsv color={color} />
      default:
        break
    }

    return iconName
  }
  const iconColor = active ? "#09707B" : "#FFFFFF"

  // const { permission } = useContext(AppContext)

  // const condo = PMNav.findIndex((ele) => {
  //   return ele.label === label
  // })

  // const admin = label === "Admin" || label === "Staff"

  // const ownerTenant = label === "Owners/Tenants"

  // const others = OthersNav.findIndex((ele) => {
  //   return ele.label === label
  // })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classnames(
        link,
        className,
        active && classNameActive,
        hasActiveChild && classNameHasActiveChild
      )}
      onClick={hasSubMenu ? toggleSubMenu : activateMe}
    >
      <a
        // href={
        //   (condo > -1 && permission?.manage_condo_settings) ||
        //   (admin && permission?.account_management) ||
        //   (ownerTenant && permission?.account_settings) ||
        //   (others > -1 && permission?.manage_condo_settings)
        //     ? to
        //     : null
        // }
        href={to}
        target={externalLink ? "_blank" : undefined}
        style={{ width: "100%", display: "flex", alignItems: "center" }}
      >
        <i className="metismenu-icon">{IconType(label, iconColor)}</i>
        <span className={`menu-label ${labelStyle}`} style={{ paddingLeft: "15px" }}>
          {label}
        </span>
      </a>

      <Box margin="0 20px 0 0" display="flex" alignItems="center">
        {hasSubMenu ? (
          <KeyboardArrowDownIcon style={{ fill: "#FFFFFF" }} />
        ) : (
          <Box>
            <Box />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Nav: React.FunctionComponent = () => {
  return (
    <Box margin="30px 0 0 0">
      <Box marginBottom="30px">
        <Box paddingLeft="20px">
          <Label label="property management" light />
        </Box>
        <MetisMenu
          content={PMNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix="fm"
          LinkComponent={Item}
        />
      </Box>
      <Box marginBottom="30px">
        <Box paddingLeft="20px">
          <Label label="account management" light />
        </Box>
        <MetisMenu
          content={AMNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix="fm"
          LinkComponent={Item}
        />
      </Box>
      <Box marginBottom="30px">
        <Box paddingLeft="20px">
          <Label label="others" light />
        </Box>
        <MetisMenu
          content={OthersNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix="fm"
          LinkComponent={Item}
        />
      </Box>
    </Box>
  )
}

export default Nav
