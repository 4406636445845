import React from "react"

export type CtxType = {
  setActiveFilter: Function
  filterTable: Function
  activeFilter: number
  contextActions: Record<any, any>
}

export const Context = React.createContext<Partial<CtxType>>({})
