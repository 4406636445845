import React from "react"

export const IconDelete = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L6 1H0V3H1.10938L2.90234 18.2344C3.01959 19.234 3.882 20 4.88867 20H13.1113C14.118 20 14.9804 19.234 15.0977 18.2344L16.8906 3H18V1H16H12L11 0H7ZM3.12305 3H4H14.877L13.1113 18H4.88867L3.12305 3ZM6 5V15C6 15.552 6.448 16 7 16H8V5H6ZM10 5V16H11C11.552 16 12 15.552 12 15V5H10Z"
      fill="#09707B"
    />
  </svg>
)

export const IconDetails = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM2 2H16V16H2V2ZM9 5C5 5 3 9 3 9C3 9 5 13 9 13C13 13 15 9 15 9C15 9 13 5 9 5ZM9 7C10.104 7 11 7.896 11 9C11 10.104 10.104 11 9 11C7.896 11 7 10.104 7 9C7 7.896 7.896 7 9 7Z"
      fill="#09707B "
    />
  </svg>
)
