import React from "react"

/** Components */
import ContextMenu from "components/ContextMenu"
import { fromUnix } from "utils/date"

/** Config */
import ContextSettingsFeedback from "../../ContextMenu"

const TableHeaderColumns = () => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any) => {
          return (
            <ContextMenu>
              <ContextSettingsFeedback data={row.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Category Name",
        id: "feedback_type_name",
        accessor: "feedback_type_name"
      },
      {
        Header: "BCC Email",
        id: "bcc_emails",
        accessor: (c: Record<string, any>) => {
          c?.bcc_emails?.join(";")
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.bcc_emails?.join("; ")
        }
      },
      {
        Header: "Last Update",
        id: "last_update",
        accessor: (c: Record<string, any>) => {
          return fromUnix(c?._updated)
        },
        Cell: ({ original }: Record<string, any>) => {
          return fromUnix(original?._updated)
        }
      }
    ]
  }
]

export default TableHeaderColumns
