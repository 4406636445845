import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Component */
/** Component */
import {
  IconView,
  IconCancel,
  IconEdit,
  IconApprove,
  IconReject
} from "components/Icons/ContextMenu"
import Dialog from "components/Dialog"

/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { Context as MainContext } from "context/Dashboard/Applications/categories/main/Context"
import { Context as RootContext } from "context/Dashboard/Applications/main/Context"
import { AppContext } from "App"

import message from "config/Text/Dashboard/Applications"

/** Context */
import styles from "./styles"

interface ContextProps {
  data: Record<string, any>
}

const ContextNewAccountRequests: React.FC<ContextProps> = ({ data }) => {
  const { listItem } = styles()
  const status = data?.status

  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const {
    showUpdateApplication,
    showApproveApplication,
    setShowDetails,
    setActiveDetails,
    handleCancelItem,
    activeFilter
  } = useContext(MainContext)
  const { setShowReject, setActiveData } = useContext(RootContext)
  const canEdit = permission?.application_edit

  /** Methods */
  function handleReject() {
    setShowReject && setShowReject(true)
    setActiveData && setActiveData(data)
    openContext && openContext(false)
  }

  function handleShowDetails(items: Record<string, any>) {
    openContext && openContext(false)
    setShowDetails && setShowDetails(true)
    setActiveDetails && setActiveDetails(items)
  }

  function handleDialog() {
    setOpenDialog(true)
    openContext && openContext(false)
  }

  function handleCancel() {
    openContext && openContext(false)
    handleCancelItem &&
      handleCancelItem(data?._uid, `fetchApplicationsAccessCard-${activeFilter}`)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        {status === 1 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() =>
              !canEdit ? null : showUpdateApplication && showUpdateApplication(data)}
          >
            <i>{IconEdit}</i>
            Edit
          </ListItem>
        )}
        {status !== 2 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() =>
              !canEdit
                ? null
                : showApproveApplication && showApproveApplication(data)}
          >
            <i>{IconApprove}</i>
            Approve
          </ListItem>
        )}
        {status !== 3 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() => (!canEdit ? null : handleReject())}
          >
            <i>{IconReject}</i>
            Reject
          </ListItem>
        )}
      </List>
      {status === 1 && (
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={() => (!canEdit ? null : handleDialog())}
        >
          <i>{IconCancel}</i>
          Cancel
        </ListItem>
      )}
      <Dialog
        action={() => handleCancel()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message={message?.confirmCancel}
      />
    </>
  )
}

export default ContextNewAccountRequests
