import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"

/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Styles
 */
import { IconRegisteredUnits } from "components/Icons/Dashboard"
import { toPercentage } from "utils/helpers"
import styles from "./styles"
/**
 * Icon
 */
/**
 * Hooks
 */
import { TotalRegisteredUnitsHook, TotalUnitsHook } from "./hooks"
/**
 * Utils
 */

const TotalRegisteredUnits: React.FC = () => {
  const classes = styles()
  const { totalRegisteredUnits, isValidating } = TotalRegisteredUnitsHook()
  const { totalUnits } = TotalUnitsHook()
  const percentValue = `${toPercentage(totalRegisteredUnits, totalUnits)}%`

  function ViewHeader(): JSX.Element {
    return (
      <Box className={classes.header}>
        <CardTitle title="Total Registered Units" icon={IconRegisteredUnits} />
      </Box>
    )
  }

  function ViewInfo(): JSX.Element {
    return (
      <Box className={classes.info}>
        <Box className={classes.total}>{totalRegisteredUnits}</Box>
        <Box>
          <Box className={classes.percentage}>{percentValue}</Box>
          <Box className={classes.body}>of total units</Box>
        </Box>
      </Box>
    )
  }

  function ViewTotalUnits(): JSX.Element {
    return (
      <Box className={classes.info}>
        <Box className={classes.body} />
        <Box className={classes.body}>{`Total Units: ${totalUnits}`}</Box>
      </Box>
    )
  }

  function ViewHighlight(): JSX.Element {
    return (
      <Box className={classes.bar} margin="0 23px">
        <Box className={classes.barHighlight} width={percentValue} />
      </Box>
    )
  }

  return (
    <Card>
      <ViewHeader />
      {isValidating ? (
        <Loader dark />
      ) : (
        <Fade in timeout={1000}>
          <Box>
            <ViewInfo />
            <ViewHighlight />
            <ViewTotalUnits />
          </Box>
        </Fade>
      )}
    </Card>
  )
}

export default TotalRegisteredUnits
