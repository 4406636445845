import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  section: {
    borderBottom: "1px solid #F2F2F2",
    padding: "0 0 40px 0",
    margin: "0 auto 40px auto",
    width: "50%"
  },
  sectionLast: {
    borderBottom: "1px solid transparent",
    padding: "0 0 40px 0",
    margin: "0 auto 40px auto",
    width: "50%"
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.dark,
    paddingBottom: "20px"
  }
}))

export default styles
