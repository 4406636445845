import React, { lazy, Suspense } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Layout */
import WithHeader from "layout/WithHeader"

/** Component */
import Loader from "components/Loader"
import PageHeaderTitle from "components/PageHeaderTitle"
import Dialog from "components/Dialog"
import Stepper from "components/Stepper"
import Footer from "components/Forms/Footer/new"

/** Machine */
import { ClosePaymentMachine } from "machines/Dashboard/Bookkeeping/closePayment"

/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

const PaymentSummary = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/ClosePayment/PaymentSummary")
)

const ModePayment = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/ClosePayment/ModeOfPayment")
)

const Acknowledgement = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/ClosePayment/Acknowledgement")
)

export const refSubmit = React.createRef<HTMLDivElement>()
export const refPrintBtn = React.createRef<HTMLButtonElement>()

const CreateOwnerView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  setShowPayment,
  paymentDetails,
  handleClosePayment,
  handleRefundBalance,
  submitting
}) => {
  const [current, send] = useMachine(ClosePaymentMachine)
  const xValue = current.value
  const pd = paymentDetails

  const refForm = React.createRef()

  /** Methods */
  const handleNext = () => {
    if (xValue === "payerinfo") {
      send("NEXT")
      return
    }

    if (
      xValue === "paymentdetails" &&
      pd.paymentStatus === 1 &&
      (pd.paymentMethod === 1 || pd.paymentMethod === "1") &&
      pd.remarks !== ""
    ) {
      handleClosePayment().then(() => send("NEXT"))
      return
    }
    refSubmit.current?.click()

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 1 || pd.paymentMethod === "1") &&
      pd.paymentStatus === 2 &&
      pd.remarks !== ""
    ) {
      handleClosePayment().then(() => send("NEXT"))
      return
    }
    refSubmit.current?.click()

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 2 || pd.paymentMethod === "2") &&
      pd.paymentStatus === 1
    ) {
      if (
        pd.remarks !== "" &&
        pd.ePaymentAgent !== "" &&
        pd.paymentReference !== ""
      ) {
        handleClosePayment().then(() => send("NEXT"))
        return
      }
      refSubmit.current?.click()
    }

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 3 || pd.paymentMethod === "3") &&
      pd.paymentStatus === 1
    ) {
      if (
        pd.remarks !== "" &&
        pd.ePaymentAgent !== "" &&
        pd.paymentReference !== ""
      ) {
        handleClosePayment()
        send("NEXT")
        return
      }
      refSubmit.current?.click()
    }

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 2 || pd.paymentMethod === "2") &&
      pd.paymentStatus === 2
    ) {
      if (pd.remarks !== "") {
        handleClosePayment()
        send("NEXT")
        return
      }
      refSubmit.current?.click()
    }

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 3 || pd.paymentMethod === "3") &&
      pd.paymentStatus === 2
    ) {
      if (pd.remarks !== "") {
        handleClosePayment()
        send("NEXT")
        return
      }
      refSubmit.current?.click()
    }

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 2 || pd.paymentMethod === "2") &&
      pd.remarks !== ""
    ) {
      handleClosePayment()
      send("NEXT")
      return
    }
    refSubmit.current?.click()

    if (
      xValue === "paymentdetails" &&
      (pd.paymentMethod === 4 || pd.paymentMethod === "4") &&
      pd.remarks !== ""
    ) {
      handleRefundBalance()
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }
  }

  const ViewPaymentSummary = () => {
    if (current.value !== "payerinfo") {
      return null
    }

    return (
      <Suspense fallback={<Loader forContent />}>
        <PaymentSummary ref={refSubmit} />
      </Suspense>
    )
  }

  const ViewModePayment = () => {
    if (current.value !== "paymentdetails") {
      return null
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <ModePayment ref={refForm} />
      </Suspense>
    )
  }

  const ViewAcknowledgement = () => {
    if (current.value !== "acknowledgement") {
      return null
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <Acknowledgement />
      </Suspense>
    )
  }

  const CurrentStep = (step: any) => {
    switch (step) {
      case "payerinfo":
        return 1
      case "paymentdetails":
        return 2
      case "acknowledgement":
        return 3
      default:
        break
    }
    return step
  }

  const labelValue = () => {
    if (xValue === "acknowledgement") {
      return "Print"
    }

    if (submitting) {
      return "Submitting..."
    }

    return "Next"
  }

  return (
    <>
      <WithHeader>
        <PageHeaderTitle
          title="Close Payment"
          breadcrumbs="property management / bookkeeping / close payment"
          backAction={() => setShowPayment()}
        />
        <Card>
          <Stepper
            items={["Payer's Info", "Payment Details", "Acknowledgement"]}
            activeStep={CurrentStep(xValue) || 1}
            justifyStart
          />
          <Box>
            <div>
              {ViewPaymentSummary()}
              {ViewModePayment()}
              {ViewAcknowledgement()}
            </div>
            <Box margin="30px 0">
              <Footer
                handleNext={() => handleNext()}
                handleBack={xValue === "payerinfo" ? null : () => send("BACK")}
                handleCancel={() => setOpenDialog && setOpenDialog(true)}
                label={labelValue()}
                forAcknowledge={xValue === "acknowledgement"}
                handlePrint={() => refPrintBtn.current?.click()}
                handleSendEmail={() => null}
                handleDone={() => setShowPayment()}
                disabled={submitting}
              />
            </Box>
          </Box>
          <Dialog
            action={() => setShowPayment()}
            isOpen={openDialog}
            setOpen={setOpenDialog}
            actionLabel="OK"
            title=""
            message="Are you sure you want to cancel?"
          />
        </Card>
      </WithHeader>
    </>
  )
}

export default withContext(CreateOwnerView)
