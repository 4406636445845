import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0 0 40px"
  },
  container: {
    width: "610px",
    margin: "0 auto 100px"
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "18px",
    letterSpacing: "0.0015em",
    color: theme.palette.body.main
  },
  uploadContainer: {
    width: "100%",
    height: "239px",
    border: "3px dashed #F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 20px 0",
    "& .uploadButton": {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      width: "247px",
      height: "40px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 10px"
    },
    "& .info": {
      fontSize: "12px",
      letterSpacing: "0.004em",
      color: theme.palette.body.dark
    }
  },
  documentContainer: {
    width: "100%",
    height: "80px",
    background: theme.palette.common.white,
    border: "1px solid #F2F2F2",
    boxSizing: "border-box",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px"
  },
  documentDetails: {
    display: "flex",
    alignItems: "center",
    "& .filetype": { marginRight: "17px", paddingTop: "3px" },
    "& .filename": {
      fontSize: "12px",
      letterSpacing: "0.004em",
      color: theme.palette.body.main
    },
    "& .filesize": {
      fontSize: "10px",
      letterSpacing: "0.004em",
      color: theme.palette.body.dark
    }
  },
  uploadBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    cursor: "pointer"
  },
  uploadContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}))

export default styles
