import React, { FunctionComponent } from "react"

import ResetPasswordProvider from "context/Settings/AccountManagement/Staff/forms/resetPassword/Provider"
import ResetPasswordView from "context/Settings/AccountManagement/Staff/forms/resetPassword/View"

const ResetPassword: FunctionComponent = () => (
  <ResetPasswordProvider>
    <ResetPasswordView />
  </ResetPasswordProvider>
)

export default ResetPassword
