import { makeStyles } from "@material-ui/core/styles"

// TODO: Create a Theme
const styles = makeStyles(() => ({
  container: {
    margin: "0 30px"
  },
  iconInactive: {
    marginRight: "10px",
    paddingTop: "5px"
  },
  iconActive: {
    marginRight: "10px",
    paddingTop: "5px",
    display: "none"
  },
  name: {
    fontWeight: 500,
    fontSize: "14px"
  },
  nameRoot: {
    fontFamily: "inherit"
  },
  activeMenu: {
    display: "none",
    "& .iconActive": {
      display: "block"
    }
  },
  menuHeader: {
    "&.activeMenu": {
      "& small": {
        display: "block"
      },
      "& small:first-child": {
        display: "none"
      }
    }
  },
  modalTrigger: {
    border: "0px solid transparent",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    color: "rgb(69, 75, 87)",
    cursor: "pointer",
    outline: "none"
  }
}))

export default styles
