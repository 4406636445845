import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { toast } from "react-toastify"
import Fade from "@material-ui/core/Fade"
/** Components */
import Dialog from "components/Dialog"
/**
 * Context
 */
import { AppContext } from "App"
/** Service */
import service from "services/Settings/AccountManagement/Admin"
/** Categories */
import { CtxType } from "context/Settings/AccountManagement/Admin/forms/accountSettings/Context"
import withContext from "context/Settings/AccountManagement/Admin/forms/accountSettings/withContext"
import Loader from "components/Loader"
import Dashboard from "./components/Dashboard"
import Reports from "./components/Reports"
import Announcement from "./components/Announcement"
import Applications from "./components/Applications"
import Attendance from "./components/Attendance"
import Bookkeeping from "./components/Bookkeeping"
import EstateMatters from "./components/EstateMatters"
import Facilities from "./components/Facilities"
import Feedback from "./components/Feedback"
import PaymentSchedule from "./components/PaymentSchedule"
import UnitManagement from "./components/UnitManagement"
import VisitorManagement from "./components/VisitorManagement"
import Others from "./components/Others"

/** Styles */
import styles from "./styles"

const AccountPermission: React.FC<CtxType> = ({
  payloads,
  setPayloads,
  activeRole,
  isFetchingRoles,
  initSetup
}) => {
  const { sectionHead, sectionItems } = styles()
  const [submitting, setSubmitting] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const { getUserPermission } = useContext(AppContext)

  /** Notification */
  const notifyUpdate = () =>
    toast("Successfully updated the permission.", {
      type: toast.TYPE.SUCCESS
    })

  const notifySuccessRemoveRole = () =>
    toast("Successfully remove the role.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyErrorRemoveRole = () =>
    toast("Delete failed because there are users with this role.", {
      type: toast.TYPE.ERROR
    })

  function handleUpdate(key: string, value: boolean) {
    // setPayloads &&
    //   setPayloads({
    //     ...payloads,
    //     [key]: value
    //   })
    if (value && setPayloads) {
      if (key.includes("remove")) {
        const editKey = key.replace("remove", "edit")
        const createKey = key.replace("remove", "create")
        const viewKey = key.replace("remove", "view")
        setPayloads({
          ...payloads,
          [editKey]: value,
          [createKey]: value,
          [viewKey]: value,
          [key]: value
        })
      } else if (key.includes("edit")) {
        const createKey = key.replace("edit", "create")
        const viewKey = key.replace("edit", "view")
        setPayloads({
          ...payloads,
          [createKey]: value,
          [viewKey]: value,
          [key]: value
        })
      } else if (key.includes("create")) {
        const viewKey = key.replace("create", "view")
        setPayloads({
          ...payloads,
          [viewKey]: value,
          [key]: value
        })
      } else {
        setPayloads({
          ...payloads,
          [key]: value
        })
      }
    } else if (!value && setPayloads) {
      if (key.includes("view")) {
        const createKey = key.replace("view", "create")
        const editKey = key.replace("view", "edit")
        const removeKey = key.replace("view", "remove")
        setPayloads({
          ...payloads,
          [createKey]: value,
          [editKey]: value,
          [removeKey]: value,
          [key]: value
        })
      } else if (key.includes("create")) {
        const editKey = key.replace("create", "edit")
        const removeKey = key.replace("create", "remove")
        setPayloads({
          ...payloads,
          [removeKey]: value,
          [editKey]: value,
          [key]: value
        })
      } else if (key.includes("edit")) {
        const removeKey = key.replace("edit", "remove")
        setPayloads({
          ...payloads,
          [removeKey]: value,
          [key]: value
        })
      } else {
        setPayloads({
          ...payloads,
          [key]: value
        })
      }
    }
  }

  async function handleRemoveRole(roleID: string) {
    setSubmitting(true)
    try {
      const response = await service.removeRole(roleID)

      if (response?.data?._statusCode === -292) {
        notifyErrorRemoveRole()
        setSubmitting(false)
      } else {
        notifySuccessRemoveRole()
        await initSetup().then(() => setSubmitting(false))
      }

      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  function handleDialog() {
    setOpenDialog(true)
  }

  async function updatePermissions() {
    setSubmitting(true)
    try {
      const response = await service.updatePermission(payloads, activeRole)
      setSubmitting(false)
      getUserPermission && getUserPermission()
      notifyUpdate()
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  if (isFetchingRoles) {
    return <Loader dark />
  }

  return (
    <>
      <Box>
        <Box display="flex" className={sectionHead}>
          <Box flex="3" />
          <Box flex="1">View</Box>
          <Box flex="1">Create</Box>
          <Box flex="1">Edit</Box>
          <Box flex="1">Remove</Box>
        </Box>

        <Fade in={!isFetchingRoles} timeout={1000}>
          <Box className={sectionItems}>
            <Box>
              <Dashboard data={payloads} handleUpdate={handleUpdate} />
              <Reports data={payloads} handleUpdate={handleUpdate} />
              <Attendance data={payloads} handleUpdate={handleUpdate} />
              <Announcement data={payloads} handleUpdate={handleUpdate} />
              <Applications data={payloads} handleUpdate={handleUpdate} />
              <Bookkeeping data={payloads} handleUpdate={handleUpdate} />
              <Facilities data={payloads} handleUpdate={handleUpdate} />
              <Feedback data={payloads} handleUpdate={handleUpdate} />
              <UnitManagement data={payloads} handleUpdate={handleUpdate} />
              <VisitorManagement data={payloads} handleUpdate={handleUpdate} />
              <EstateMatters data={payloads} handleUpdate={handleUpdate} />
              <PaymentSchedule data={payloads} handleUpdate={handleUpdate} />
              <Others data={payloads} handleUpdate={handleUpdate} />
            </Box>
          </Box>
        </Fade>
        <Box
          padding="30px 40px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Box width="150px">
              <Button
                fullWidth
                size="large"
                onClick={() => handleDialog()}
                style={{ color: "#E12323" }}
              >
                REMOVE ROLE
              </Button>
            </Box>
          </Box>
          <Box width="150px">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={submitting}
              onClick={() => updatePermissions()}
            >
              {submitting ? "SUBMITTING" : "SAVE"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        action={() => handleRemoveRole(activeRole)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to remove this role?"
      />
    </>
  )
}
export default withContext(AccountPermission)
