import * as Yup from "yup"

const validation: Record<any, {}> = {
  rejectApplication: Yup.object().shape({
    remarks: Yup.string().required("Required")
  }),
  addApplicationDetails: Yup.object().shape({
    serialNo: Yup.string(),
    remarks: Yup.string(),
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable()
  }),
  approveVehiclesDetails: Yup.object().shape({
    serialNo: Yup.string().required("Required"),
    remarks: Yup.string(),
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable()
  }),
  approveAccessCardDetails: Yup.object().shape({
    serialNo: Yup.string().required("Required"),
    remarks: Yup.string(),
    startDate: Yup.string()
      .nullable()
      .required("Required"),
    endDate: Yup.string().nullable()
  }),
  approveRenovationDetails: Yup.object().shape({
    serialNo: Yup.string(),
    remarks: Yup.string(),
    startDate: Yup.string()
      .nullable()
      .required("Required"),
    endDate: Yup.string()
      .nullable()
      .required("Required")
  }),
  addApplicationsResidentInfo: Yup.object().shape({
    unitUID: Yup.string().required("Required"),
    applicantName: Yup.string().required("Required")
  }),
  addApplicationsVehicle: Yup.object().shape({
    vehicleType: Yup.string().required("Required"),
    vehicleNo: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    IUNo: Yup.string().required("Required")
  }),
  addApplicationsMovingInOut: Yup.object().shape({
    name: Yup.string().required("Required"),
    eta: Yup.string().required("Required"),
    purpose: Yup.string().required("Required"),
    remarks: Yup.string().required("Required")
  }),
  addApplicationsAccessCard: Yup.object().shape({
    applicationType: Yup.string().required("Required")
  }),
  addApplicationsRenovation: Yup.object().shape({
    contractorName: Yup.string().required("Required"),
    contractorContact: Yup.string().required("Required")
  })
}

export function ApplicationCheck(args: Record<string, any>): boolean {
  const argsLength = Object.values(args).length
  if (argsLength === 0) {
    return false
  }

  return !Object.values(args).includes("")
}

export const {
  addApplicationsResidentInfo,
  addApplicationsVehicle,
  addApplicationsMovingInOut,
  rejectApplication,
  addApplicationDetails,
  addApplicationsAccessCard,
  approveAccessCardDetails,
  approveVehiclesDetails,
  approveRenovationDetails,
  addApplicationsRenovation
} = validation
