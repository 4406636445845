import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Logo from "assets/images/Cora_logo_2x.png"
import CenterDesign from "../assets/centerDesign.png"

const styles = makeStyles(() => ({
  container: {
    width: "100%",
    background: "linear-gradient(157.32deg, #004E8B 3.02%, #002848 91.85%)",
    borderRadius: "0px 500px 0px 0px"
  }
}))

const Brand: React.FC = () => {
  const { container } = styles()
  return (
    <Box className={container} position="relative">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        position="relative"
      >
        <img src={Logo} alt="Cora" />
      </Box>
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        right="0"
      >
        <img src={CenterDesign} alt="design" />
      </Box>
    </Box>
  )
}

export default Brand
