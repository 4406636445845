import * as Yup from "yup"

export const bookingDetailsAdmin: Record<any, any> = Yup.object().shape({
  remarks: Yup.string().required("Required")
})

export const bookingDetails: Record<any, any> = Yup.object().shape({
  unitUID: Yup.string().required("Required"),
  bookingByName: Yup.string().required("Required"),
  remarks: Yup.string().required("Required")
})

export const rejectBooking: Record<any, any> = Yup.object().shape({
  remarks: Yup.string().required("Required")
})
