const version = "v1"
const baseFacility = "svc-facilities"

const API = {
  FETCH_BOOKINGS: `${baseFacility}/${version}/facility-bookings`,
  FETCH_FACILITIES: `${baseFacility}/${version}/facilities`,
  CREATE_BOOKING: `${baseFacility}/${version}/facility-booking-admin`,
  APPROVE_BOOKING: `${baseFacility}/${version}/facility-booking/approve`,
  REJECT_BOOKING: `${baseFacility}/${version}/facility-booking/reject`,
  CANCEL_BOOKING: `${baseFacility}/${version}/facility-booking/cancel`,
  UPDATE_TIMESLOT: `${baseFacility}/${version}/facility-booking/date-timeslot`,
  UPDATE_PAYMENT: `${baseFacility}/${version}/facility-booking/payments`,
  UPDATE_REMARKS: `${baseFacility}/${version}/facility-booking/remarks`,
  NO_SHOW: `${baseFacility}/${version}/facility-booking/no-show`
}

export default API
