import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const Announcement: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("feedback")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.feedback_view_self)}
          onChange={() =>
            handleUpdate("feedback_view_self", Boolean(!data?.feedback_view_self))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.announcement_view_self)}
          onChange={() =>
            handleUpdate(
              "announcement_view_self",
              Boolean(!data?.announcement_view_self)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.feedback_view_all)}
          onChange={() =>
            handleUpdate("feedback_view_all", Boolean(!data?.feedback_view_all))}
        />
      </Box>
    </Box>
  )
}
export default Announcement
