import * as React from "react"

const Security = (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28881 0L0.509521 4C0.509521 4 0.509521 8 0.509521 10C0.509521 17.83 6.79062 21.486 9.28881 22C11.787 21.486 18.0681 17.83 18.0681 10C18.0681 8 18.0681 4 18.0681 4L9.28881 0ZM16.1172 10C16.1172 16.134 11.4495 19.254 9.28881 19.937C7.12813 19.254 2.46048 16.134 2.46048 10V5.3L9.28881 2.189L16.1172 5.3V10Z"
      fill="#454B57"
    />
  </svg>
)

export default Security
