import { Machine } from "xstate"

export const UnitManagementMachine = Machine({
  id: "unitManagementMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        CREATE_PS: "createPs"
      }
    },
    details: {
      initial: "unitDetailsMain",
      states: {
        unitDetailsMain: {
          on: {
            UPDATE_USER: "updateUser"
          }
        },
        updateUser: {
          on: {
            UNIT_DETAILS_MAIN: "unitDetailsMain"
          }
        }
      },
      on: {
        BACK: "tableView",
        UNIT_DETAILS_MAIN: "unitDetailsMain",
        UPDATE_USER: "updateUser"
      }
    },
    unitDetailsMain: {
      on: {
        UNIT_DETAILS_MAIN: "unitDetailsMain"
      }
    },
    updateUser: {
      on: {
        UPDATE_USER: "updateUser"
      }
    },
    detailsPS: {
      on: {
        BACK: "tableView"
      }
    },
    createOwner: {
      on: {
        BACK: "tableView"
      }
    },
    editOwner: {
      on: {
        BACK: "tableView"
      }
    },
    createResident: {
      on: {
        BACK: "tableView"
      }
    },
    editResident: {
      on: {
        BACK: "tableView"
      }
    },
    createPs: {
      on: {
        BACK: "tableView"
      }
    },
    editPs: {
      on: {
        BACK: "tableView"
      }
    },
    createVehicle: {
      on: {
        BACK: "tableView"
      }
    },
    editVehicle: {
      on: {
        BACK: "tableView"
      }
    },
    detailsVehicle: {
      on: {
        BACK: "tableView"
      }
    },
    createAccessCards: {
      on: {
        BACK: "tableView"
      }
    },
    editAccessCards: {
      on: {
        BACK: "tableView"
      }
    },
    detailsAccessCards: {
      on: {
        BACK: "tableView"
      }
    },
    createFromData: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    DETAILS: "details",
    DETAILS_PS: "detailsPS",
    CREATE_PS: "createPs",
    EDIT_PS: "editPs",
    TABLE_VIEW: "tableView",
    CREATE_FROM_DATA: "createFromData",
    CREATE_OWNER: "createOwner",
    EDIT_OWNER: "editOwner",
    CREATE_RESIDENT: "createResident",
    EDIT_RESIDENT: "editResident",
    CREATE_VEHICLE: "createVehicle",
    EDIT_VEHICLE: "editVehicle",
    DETAILS_VEHICLE: "detailsVehicle",
    CREATE_ACCESS_CARDS: "createAccessCards",
    EDIT_ACCESS_CARDS: "editAccessCards",
    DETAILS_ACCESS_CARDS: "detailsAccessCards",
    UNIT_DETAILS_MAIN: "unitDetailsMain",
    UPDATE_USER: "updateUser"
  }
})
