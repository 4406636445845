import React from "react"

/** Context */
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [photos, setPhotos] = React.useState(Array)
  const [attaching, setAttaching] = React.useState(false)

  /** Methods */
  const removePhoto = (value: number) => {
    const photoFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })
    setPhotos(photoFiltered)
  }

  const handleImageUpload = (): void => {
    // const fileToUpload = event[0]
    // const items: any = []
    // setAttaching(true)

    setAttaching(false)
  }

  return (
    <Context.Provider
      value={{
        photos,
        attaching,
        removePhoto,
        handleImageUpload
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
