import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"

import { Context } from "context/Dashboard/Dashboard/main/Context"
/**
 * Components
 */
import Loader from "components/Loader"
/**
 * Styles
 */
import {
  IconAttendance,
  IconOffice,
  IconSecurity,
  IconMaintenance,
  IconCleaners,
  IconLandscape,
  IconOthers
} from "components/Icons/Dashboard"

import styles from "./styles"

import {
  AttendanceToday,
  LastTimein,
  AttendanceAdmin,
  AttendanceSecurity,
  AttendanceCleaning,
  AttendanceLandscape,
  AttendanceMaintenance,
  AttendanceOthers
} from "./hooks"

const Attendance: React.FC = () => {
  const {
    headerWrapper,
    title,
    body,
    totalStyle,
    totalWrapper,
    listWrapper
  } = styles()

  const { dateRange } = useContext(Context)
  const { totalEmployee, isValidating } = AttendanceToday(dateRange)
  const { countAdmin } = AttendanceAdmin(dateRange)
  const { countSecurity } = AttendanceSecurity(dateRange)
  const { countCleaning } = AttendanceCleaning(dateRange)
  const { countLandscape } = AttendanceLandscape(dateRange)
  const { countMaintenance } = AttendanceMaintenance(dateRange)
  const { countOthers } = AttendanceOthers(dateRange)
  const { lastTimein } = LastTimein()

  const attendanceBreakdown = [
    {
      name: "Office / Admin",
      total: countAdmin,
      icon: IconOffice
    },
    {
      name: "Security",
      total: countSecurity,
      icon: IconSecurity
    },
    {
      name: "Maintenance",
      total: countMaintenance,
      icon: IconMaintenance
    },
    {
      name: "Cleaners",
      total: countCleaning,
      icon: IconCleaners
    },
    {
      name: "Landscape",
      total: countLandscape,
      icon: IconLandscape
    },
    {
      name: "Others",
      total: countOthers,
      icon: IconOthers
    }
  ]

  function ViewHeader(): JSX.Element {
    return (
      <Box className={headerWrapper}>
        <Box className={title} display="flex">
          <Box marginRight="10px">{IconAttendance}</Box>
          Attendance
        </Box>
      </Box>
    )
  }

  function ViewTotal(): JSX.Element {
    return (
      <Box className={totalWrapper}>
        <Box className={title}>Total Employees</Box>
        <Box className={body}>{`Last Timein: ${lastTimein}`}</Box>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade in timeout={1000}>
            <Box className={totalStyle}>{totalEmployee || 0}</Box>
          </Fade>
        )}
      </Box>
    )
  }

  function ViewList(): JSX.Element {
    return (
      <>
        {attendanceBreakdown.map((item) => (
          <Box key={item.name} className={listWrapper}>
            <Box className={body} display="flex">
              <Box>{item.icon}</Box>
              <Box paddingLeft="20px">{item.name}</Box>
            </Box>
            <Box className={body}>{item.total}</Box>
          </Box>
        ))}
      </>
    )
  }

  return (
    <Card>
      <ViewHeader />
      <ViewTotal />
      <ViewList />
    </Card>
  )
}

export default Attendance
