import React, { useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

/** Service */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"
import { Context as ContextMain } from "context/Dashboard/UnitManagement/categories/main/Context"
import { dateDefaultTime } from "utils/date"
import { Context } from "./Context"

const CreatePaymentScheduleProvider: React.FC = ({ children }) => {
  const { showMain } = React.useContext(ContextMain)
  const user = useSelector((state: any) => state.user)
  const [activeStep, setActiveStep] = useState(1)
  const [openDialog, setOpenDialog] = useState(false)
  // /svc-bookkeeping/v1/scheduled-payment
  const [psDetails, setPsDetails] = useState({
    unitUID: "",
    userDetailUid: "",
    payeeAccountUID: "",
    payeeName: "",
    payeePhone: "",
    payeeEmail: "",
    payeeAddress: "",
    paymentName: "",
    startDate: "",
    endDate: "",
    frequency: "1",
    amt: "",
    tax: "",
    notifyOnDaysBefore: "",
    flags: "1",
    notifyOnDaysAfterDue: "",
    addedBy: ""
  })

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully created Payment Schedule", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleCreatePS = async () => {
    const payload = {
      "unit_uid": psDetails.unitUID,
      "payee_user_detail_uid": psDetails.userDetailUid,
      "payee_account_uid": psDetails.payeeAccountUID,
      "payee_name": psDetails?.payeeName,
      "payee_phone": psDetails.payeePhone,
      "payee_email": psDetails.payeeEmail,
      "payee_registered_address": psDetails.payeeAddress,
      "payment_name": psDetails.paymentName,
      "start_date": dateDefaultTime(psDetails?.startDate),
      "end_date": dateDefaultTime(psDetails?.startDate, false),
      "frequency": +psDetails.frequency,
      "amt": +psDetails.amt,
      "tax": +psDetails.tax,
      "notify_on_days_before": +psDetails.notifyOnDaysBefore,
      "flags": +psDetails.flags,
      "notify_on_days_after_due": +psDetails.notifyOnDaysAfterDue,
      "added_by_account_uid": user?.account_uid
    }

    try {
      return await BookkeepingAPI.createSchedulePayment(payload).then(
        () => notifyCreate(),
        showMain && showMain()
      )
    } catch (e) {
      return e
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getSteps = () => {
    return ["select vms type", "visitor's information", "requester's information"]
  }

  return (
    <Context.Provider
      value={{
        handleCreatePS,
        handleNext,
        handleBack,
        setActiveStep,
        setOpenDialog,
        activeStep,
        steps: getSteps,
        openDialog,
        psDetails,
        setPsDetails
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreatePaymentScheduleProvider
