import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import Label from "components/Forms/Label"

/** Config */
// import {paymentType as paymentTypeOptions} from "config/Dashboard/UnitManagement"

/** Config */
import { frequency } from "config/Dashboard/Bookkeeping"

/** Utils */
import { toFixed } from "utils/helpers"
import { fromUnix } from "utils/date"

/** Styles */
import { Context } from "context/Dashboard/UnitManagement/categories/main/Context"
import styles from "./styles"

/** Context */

const DetailsSummary: React.FC = () => {
  const { twoCol } = styles()

  const { activeData } = React.useContext(Context)
  const data = activeData

  // const {
  //   currency,
  //   unit,
  //   payee_name,
  //   description,
  //   category,
  //   payment_type,
  //   amt,
  //   tax,
  // } = data

  return (
    <Card>
      <Box>
        <FormWrapper title="Payer’s Information" width="90%">
          <Box width="90%" margin="auto">
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="block/unit no." />
                <Box>{data?.unit?.short_name}</Box>
              </Box>
              <Box>
                <Label label="name" />
                <Box>{data?.payee_name === "" ? "N/A" : data?.payee_name}</Box>
              </Box>
            </Box>
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="phone" />
                <Box>{data?.payee_phone === "" ? "N/A" : data?.payee_phone}</Box>
              </Box>
              <Box>
                <Label label="email" />
                <Box>{data?.payee_email === "" ? "N/A" : data?.payee_email}</Box>
              </Box>
            </Box>
            <Box marginBottom="30px">
              <Box>
                <Label label="address" />
                <Box>
                  {data?.payee_registered_address === ""
                    ? "N/A"
                    : data?.payee_registered_address}
                </Box>
              </Box>
            </Box>
          </Box>
        </FormWrapper>
        <FormWrapper title="Payment Details" width="90%">
          <Box width="90%" margin="auto">
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="start date" />
                <Box>{fromUnix(data?.start_date)}</Box>
              </Box>
              <Box>
                <Label label="end date" />
                <Box>{fromUnix(data?.end_date)}</Box>
              </Box>
            </Box>
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="amount" />
                <Box>{toFixed(data?.amt, data?.currency)}</Box>
              </Box>
              <Box>
                <Label label="TAX" />
                <Box>{toFixed(data?.tax, data?.currency)}</Box>
              </Box>
            </Box>
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="notify days before" />
                <Box>{data?.notify_on_days_before}</Box>
              </Box>
              <Box>
                <Label label="notify days after" />
                <Box>{data?.notify_on_days_after_due}</Box>
              </Box>
            </Box>
            <Box className={twoCol} marginBottom="30px">
              <Box>
                <Label label="frequency" />
                <Box>{frequency[data?.frequency]}</Box>
              </Box>
              <Box>
                <Label label="send email" />
                <Box>{data?.flags === 1 ? "Yes" : "No"}</Box>
              </Box>
            </Box>
          </Box>
        </FormWrapper>
      </Box>
    </Card>
  )
}
export default DetailsSummary
