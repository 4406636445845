import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/Reports/main/Context"
import FeedbackResponseTime from "./components/FeedbackResponsetime"
import TotalFeedbackPerCategory from "./components/TotalFeedbackPerCategory"
import TotalFeedbackReceived from "./components/TotalFeedbackReceived"
/**
 * Hooks
 */
import { TotalFeedbackHook } from "./hooks"
/**
 * Context
 */
/**
 * Styles
 */
import styles from "./styles"

const Icon = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0V2H18V12H20V2C20 0.895 19.105 0 18 0H4ZM2 4C0.9 4 0.00976562 4.9 0.00976562 6L0 21L4 17H14C15.1 17 16 16.1 16 15V6C16 4.9 15.1 4 14 4H2ZM2 6H14V15H4H3.17188L2.58594 15.5859L2 16.1719V6Z"
      fill="#454B57"
    />
  </svg>
)

const Feedback: React.FC = () => {
  const { container, titleContainer } = styles()
  const { selectedDate } = useContext(Context)
  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`
  const {
    totalFeedback,
    totalCleanliness,
    totalSecurity,
    totalDefects,
    totalLandscape,
    totalOthers,
    isValidating
  } = TotalFeedbackHook(selectedDate)

  const totalData = {
    cleanliness: totalCleanliness,
    security: totalSecurity,
    defects: totalDefects,
    landscape: totalLandscape,
    others: totalOthers
  }

  function ViewContent(): JSX.Element {
    return (
      <div>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade in timeout={1000}>
            <div className={container}>
              <TotalFeedbackReceived
                total={totalFeedback}
                currentReport={currentReport}
                totalData={totalData}
              />
              <TotalFeedbackPerCategory
                totalData={totalData}
                isValidating={isValidating}
              />
              <FeedbackResponseTime />
            </div>
          </Fade>
        )}
      </div>
    )
  }

  return (
    <div style={{ width: "100%" }}>
      <Card>
        <div className={titleContainer}>
          <CardTitle title="Feedback" icon={Icon} />
        </div>
        <ViewContent />
      </Card>
    </div>
  )
}
export default Feedback
