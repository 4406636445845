import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"

/** Component */
import { Context } from "context/Dashboard/Bookkeeping/main/Context"
import { AppContext } from "App"
import Header from "./Header"
import OutstandingPayments from "./components/OutstandingPayments"
import TotalRefund from "./components/TotalRefund"
import TotalPayments from "./components/TotalPayments"
import TotalPaymentsPayment from "./components/TotalPaymentsPayment"
import TotalPaymentsDeposit from "./components/TotalPaymentsDeposit"

import StatsFacilities from "./components/PaymentBreakdown/Facilities"
import StatsApplications from "./components/PaymentBreakdown/Applications"
import StatsScheduled from "./components/PaymentBreakdown/Scheduled"
import StatsMaintenance from "./components/PaymentBreakdown/Maintenance"
import StatsOthers from "./components/PaymentBreakdown/Others"
/**
 * Context
 */
/** Styles */
import styles from "./styles"

// interface
interface RemindersProps {
  dates: any
  showDate: Function
  isDateShown: boolean
}

const TotalPaymentsRecieved: React.FC<RemindersProps> = ({ dates, showDate }) => {
  const { section, titleBreakdown } = styles()
  const { downloadDataStats } = useContext(Context)

  const { condoInfo } = useContext(AppContext)
  const currency = condoInfo?.currency

  const ViewHeader = () => {
    return <Header dates={dates} action={showDate} data={downloadDataStats} />
  }

  const ViewTotal = () => <TotalPayments dates={dates} currency={currency} />

  const ViewDepositPayment = () => {
    return (
      <Box display="flex" flex="1" flexDirection="column" padding="25px 30px">
        <Box className={section}>
          <TotalPaymentsDeposit dates={dates} currency={currency} />
        </Box>
        <Box className={section}>
          <TotalPaymentsPayment dates={dates} currency={currency} />
        </Box>
      </Box>
    )
  }

  const ViewPaymentBreakdown = () => (
    <Box flex="1" padding="20px 30px 20px 40px">
      <Box className={titleBreakdown}>Payment Breakdown</Box>
      <Box>
        {/** Facilities */}
        <StatsFacilities dates={dates} currency={currency} />

        {/** Applications */}
        <StatsApplications dates={dates} currency={currency} />

        {/** Scheduled */}
        <StatsScheduled dates={dates} currency={currency} />

        {/** Maintenance */}
        <StatsMaintenance dates={dates} currency={currency} />

        {/** Others */}
        <StatsOthers dates={dates} currency={currency} />
      </Box>
    </Box>
  )

  const ViewRefund = () => <TotalRefund dates={dates} currency={currency} />
  const ViewOutstandingPayments = () => <OutstandingPayments currency={currency} />

  return (
    <Box>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginBottom: "20px"
        }}
      >
        <ViewHeader />
        <Box display="flex">
          <Box flex="2" borderRight="1px solid #F2F2F2">
            <Box display="flex" borderBottom="1px solid #F2F2F2" minHeight="139px">
              <ViewTotal />
              <ViewDepositPayment />
            </Box>

            <ViewRefund />
          </Box>
          <Box display="flex" flex="1">
            <ViewPaymentBreakdown />
          </Box>
        </Box>
      </Card>

      <Box>
        <ViewOutstandingPayments />
      </Box>
    </Box>
  )
}

export default TotalPaymentsRecieved
