import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"
/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import FilterWithInfo from "components/FilterButtons/FilterWithInfo"
import LinkButton from "components/LinkButton"
import VMSMain from "pages/Dashboard/VisitorManagement/categories/main"
import Details from "pages/Dashboard/VisitorManagement/components/Details"
import PermissionMessage from "components/PermissionMessage"
/** Categories */
import AddVisitor from "pages/Dashboard/VisitorManagement/components/AddVisitor"
/** Config */
import { MenuIcons } from "pages/Dashboard/VisitorManagement/config/Menu"
/** Machine */
import { VMSMachine } from "machines/Dashboard/VMS"
/** Context */
import { AppContext } from "App"
import withContext from "./withContext"
import { CtxType } from "./Context"

const FilterMenu = [
  {
    name: "All VMS",
    type: 0,
    info: "10 new",
    color: "#004E8B",
    icon: MenuIcons[0]
  },
  {
    name: "Visitors",
    type: 1,
    info: "10 new",
    color: "#007B83",
    icon: MenuIcons[1]
  },
  {
    name: "Deliveries / Pickup",
    type: 2,
    info: "10 new",
    color: "#D8B469",
    icon: MenuIcons[2]
  },
  {
    name: "Service Providers",
    type: 3,
    info: "10 new",
    color: "#D05611",
    icon: MenuIcons[3]
  }
]

const VMSView: React.FC<CtxType> = ({
  filterData,
  activeTable,
  showDetails,
  setShowDetails,
  activeData,
  settings
}) => {
  const [current, send] = useMachine(VMSMachine)
  const xValue = current.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const ViewPageTitle = (): JSX.Element => {
    if (xValue === "addVisitor") {
      return (
        <PageHeaderTitle
          title="Add New VMS"
          breadcrumbs="property management / visitor management / add new vms"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "updateVisitor") {
      return (
        <PageHeaderTitle
          title="Update VMS"
          breadcrumbs="property management / visitor management / update vms"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    return (
      <>
        <PageHeaderTitle
          title="Visitor Management"
          breadcrumbs="property management / visitor management"
        />
        <Box>
          <LinkButton
            name="VISITOR MANAGEMENT SETTINGS"
            url={
              permission?.manage_condo_settings
                ? "/settings/property-management/visitor-management"
                : "#"
            }
          />
        </Box>
      </>
    )
  }

  const ViewFilter = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <Box display="flex" flexWrap="wrap" padding="20px 0">
        {FilterMenu.map((item: any) => {
          return (
            <FilterWithInfo
              type={item.type}
              name={item.name}
              color={item.color}
              icon={item.icon}
              action={filterData}
              hasViewAll
            />
          )
        })}
      </Box>
    )
  }

  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <VMSMain
        showAddForm={() => send("ADD_VISITOR")}
        showUpdateForm={() => send("UPDATE_VISITOR")}
        activeTable={activeTable}
      />
    )
  }

  const AddVisitorView = (): JSX.Element => {
    if (xValue !== "addVisitor") {
      return <div />
    }

    return <AddVisitor showform={() => send("TABLE_VIEW")} settings={settings} />
  }

  const UpdateVisitorView = (): JSX.Element => {
    if (xValue !== "updateVisitor") {
      return <div />
    }

    return (
      <AddVisitor
        showform={() => send("TABLE_VIEW")}
        data={activeData}
        settings={settings}
      />
    )
  }

  const ViewDetails = (): JSX.Element => {
    return (
      <Details
        openState={showDetails}
        data={activeData}
        setopenState={setShowDetails}
      />
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box display="flex" justifyContent="space-between">
          <ViewPageTitle />
        </Box>
        <Box>
          <ViewFilter />
        </Box>
        <Box>
          {ViewTable()}
          {AddVisitorView()}
          {UpdateVisitorView()}
        </Box>
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.vms_view) {
    return (
      <>
        {MainView()}
        {ViewDetails()}
      </>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(VMSView)
