import React from "react"
/** Components */
import ContextMenu from "components/ContextMenu"
import StatusBlock from "components/Table/components/StatusBlock"
/** Config */
import vmsConfig from "config/Dashboard/VMS"
/** Utils */
import ColorStatus from "utils/status"
import { fromUnix, fromUnixTime } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import ContextVMS from "../../../components/ContextMenu/All"

const TableHeaderColumns = (mutateValue: string) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextVMS data={row.original} mutateValue={mutateValue} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Status",
        id: "status",
        accessor: (c: any) => {
          const type =
            c.vms_type === 1 || c.vms_type === 3
              ? vmsConfig?.status?.visitor
              : vmsConfig?.status?.delivery

          return checkValue(c?.vms_type, type[c?.vms.status])
        },
        Cell: ({ original }: any): JSX.Element => {
          const type =
            original?.vms_type === 1 || original?.vms_type === 3
              ? vmsConfig?.status?.visitor
              : vmsConfig?.status?.delivery
          const { status } = original.vms
          return checkValue(
            original?.vms_type,
            <StatusBlock
              status={type[status]}
              color={ColorStatus.code(type[status])}
            />
          )
        },
        width: 150
      },
      {
        Header: "Block/Unit No.",
        id: "blockUnitNo",
        accessor: (c: any) => {
          if (c?.vms?.unit_uid === "") {
            return "Admin"
          }

          return c?.unit?.short_name
        },
        Cell: ({ original }: any) => {
          if (original?.vms?.unit_uid === "") {
            return "Admin"
          }

          return checkValue(original?.unit?.short_name)
        },
        width: 150
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c: any) => checkValue(c?.vms?.name),
        Cell: ({ original }: any) => checkValue(original?.vms?.name),
        width: 200
      },
      {
        Header: "Mobile",
        id: "mobile",
        accessor: (c: any) => checkValue(c?.vms?.mobile),
        Cell: ({ original }: any) => checkValue(original?.vms?.mobile),
        width: 200
      },
      {
        Header: "Email",
        id: "email",
        accessor: (c: any) => checkValue(c?.vms?.email),
        Cell: ({ original }: any) => checkValue(original?.vms?.email),
        width: 200
      },
      {
        Header: "Category",
        id: "category",
        accessor: (c: any): string => {
          if (c?.vms_type === 1) {
            return "Visitors"
          }
          if (c?.vms_type === 2) {
            return "Deliveries/Pickup"
          }
          return "Service Providers"
        },
        Cell: ({ original }: any): string => {
          if (original?.vms_type === 1) {
            return "Visitors"
          }
          if (original?.vms_type === 2) {
            return "Deliveries/Pickup"
          }
          return "Service Providers"
        },
        width: 150
      },
      {
        Header: "Purpose",
        id: "purpose",
        accessor: (c: any) => checkValue(c?.vms?.purpose),
        Cell: ({ original }: any) => checkValue(original?.vms?.purpose),
        width: 150
      },
      {
        Header: "Start Date",
        id: "startDate",
        accessor: (c: any) =>
          checkDate(c?.vms?.start_date_ms, fromUnix(c?.vms?.start_date_ms)),
        Cell: ({ original }: any) =>
          checkDate(
            original?.vms?.start_date_ms,
            fromUnix(original?.vms?.start_date_ms)
          ),
        width: 150
      },
      {
        Header: "End Date",
        id: "endDate",
        accessor: (c: any) =>
          checkDate(c?.vms?.end_date_ms, fromUnix(c?.vms?.end_date_ms)),
        Cell: ({ original }: any) =>
          checkDate(
            original?.vms?.end_date_ms,
            fromUnix(original?.vms?.end_date_ms)
          ),
        width: 150
      },
      {
        Header: "Vehicle Number",
        id: "vehicleNo",
        accessor: (c: any) => {
          return c?.vms?.vehicle_no ? String(c?.vms?.vehicle_no) : ""
        },
        Cell: ({ original }: any) => {
          return original?.vms?.vehicle_no ? String(original?.vms?.vehicle_no) : ""
        },
        width: 150
      },
      {
        Header: "Multiple Visitors",
        id: "multipleVisitors",
        accessor: (c: any): string => {
          return c?.vms?.multiple_persons === true ? "Yes" : "No"
        },
        Cell: ({ original }: any): string => {
          return original.vms?.multiple_persons === true ? "Yes" : "No"
        },
        width: 150
      },
      {
        Header: "Visitors Count",
        id: "visitorsCount",
        accessor: (c: any) => {
          return c?.vms?.multiple_persons ? String(c?.vms?.no_of_persons) : "1"
        },
        Cell: ({ original }: any) => {
          return original?.vms?.multiple_persons
            ? String(original?.vms?.no_of_persons)
            : "1"
        },
        width: 150
      },
      {
        Header: "Visitation Date",
        id: "visitationDate",
        accessor: (c: any) => checkDate(c?.vms?.eta_ms, fromUnix(c?.vms?.eta_ms)),
        Cell: ({ original }: any) =>
          checkDate(original?.vms?.eta_ms, fromUnix(original?.vms?.eta_ms))
      },
      {
        Header: "Visitation Time",
        id: "visitationTime",
        accessor: (c: any) =>
          checkDate(c?.vms?.eta_ms, fromUnixTime(c?.vms?.eta_ms, true)),
        Cell: ({ original }: any) =>
          checkDate(
            original?.vms?.eta_ms,
            fromUnixTime(original?.vms?.eta_ms, true)
          ),
        width: 150
      },
      {
        Header: "OTP",
        id: "otp",
        accessor: (c: any) => checkValue(c?.vms?.otp),
        Cell: ({ original }: any) => checkValue(original?.vms?.otp),
        width: 150
      },
      {
        Header: "Resident Name",
        id: "residentName",
        accessor: (c: any) => checkValue(c?.vms?.contact_person),
        Cell: ({ original }: any) => checkValue(original?.vms?.contact_person),
        width: 150
      },
      {
        Header: "Remarks",
        id: "remarks",
        accessor: (c: any) => checkValue(c?.vms?.remarks),
        Cell: ({ original }: any) => checkValue(original?.vms?.remarks),
        width: 300
      }
    ]
  }
]

export default TableHeaderColumns
