import React from "react"
import Box from "@material-ui/core/Box"

import styles from "./styles"

const IconChevronRight = (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.790001 11.1035C0.594739 10.9083 0.594739 10.5917 0.790001 10.3964L5.25659 5.92985L0.790001 1.46326C0.594739 1.268 0.594739 0.951418 0.790001 0.756155L1.15451 0.391648C1.34977 0.196386 1.66635 0.196386 1.86161 0.391648L6.94165 5.47169C7.06605 5.59608 7.1112 5.76972 7.0771 5.92985C7.1112 6.08999 7.06605 6.26362 6.94166 6.38801L1.86161 11.4681C1.66635 11.6633 1.34977 11.6633 1.15451 11.4681L0.790001 11.1035Z"
      fill="#454B57"
    />
  </svg>
)

interface Props {
  name: string
  icon: any
  onClick: Function
}

const ListItem: React.FC<Props> = ({ name, icon, onClick }) => {
  const { container, title, wrapper } = styles()

  const MainView = (): JSX.Element => {
    return (
      <Box className={wrapper} onClick={() => onClick()}>
        <Box className={container}>
          <i>{icon}</i>
          <Box paddingLeft="10px" className={title}>
            {name}
          </Box>
        </Box>
        <Box>{IconChevronRight}</Box>
      </Box>
    )
  }

  return <>{MainView()}</>
}

export default ListItem
