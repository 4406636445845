import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import { Formik } from "formik"
/** Components */
import Roles from "components/Common/Roles"
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import FormInputPassword from "components/Forms/FormInput/password"

/** Validation */
import {
  addAdmin,
  updateAdmin
} from "config/Settings/AccountManagement/Admin/validation"

/** Context */
import withContext from "context/Settings/AccountManagement/Admin/forms/admin/withContext"
import { Context } from "context/Settings/AccountManagement/Admin/forms/admin/Context"
import { Context as MainContext } from "context/Settings/AccountManagement/Admin/main/Context"
import styles from "./styles"

const AdminForm: React.FC = () => {
  const { container, section, label, footer, wrapper, containerDefault } = styles()
  const { setAdminDetails, adminDetails, handleFormSubmit } = useContext(Context)
  const { setShowForm, formType } = useContext(MainContext)
  const ad = adminDetails
  const validation = formType === 1 ? addAdmin : updateAdmin

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setAdminDetails && setAdminDetails((data: any) => ({ ...data, [name]: e }))
    } else {
      setFieldValue(name, e?.target?.value)
      setAdminDetails &&
        setAdminDetails((data: any) => ({ ...data, [name]: e?.target?.value }))
    }
  }

  const handleSubmitForm = (handleSubmit: Function, isFormValid: boolean): void => {
    handleSubmit()

    if (isFormValid) {
      handleFormSubmit && handleFormSubmit()
    }
  }

  return (
    <Formik
      initialValues={{
        adminName: ad?.adminName,
        adminUsername: ad?.adminUsername,
        adminEmail: ad?.adminEmail,
        adminMobile: ad?.adminMobile,
        role: ad?.role,
        adminPassword: ad?.adminPassword
      }}
      onSubmit={(values, actions): void => {
        JSON.stringify(values, null, 2)
        actions.setSubmitting(false)
      }}
      validationSchema={validation}
    >
      {({
        touched,
        errors,
        isValid,
        handleBlur,
        handleSubmit,
        setFieldValue
      }): JSX.Element => {
        return (
          <form style={{ height: "100%" }}>
            <Box className={wrapper}>
              <Box flex="3">
                <FormWrapper>
                  <Box className={container}>
                    <Box className={section}>
                      <Box className={label}>Personal Details</Box>
                      <Box>
                        <Box marginBottom="25px">
                          <FormInput
                            name="adminName"
                            value={ad?.adminName}
                            idValue="adminName"
                            label="Name"
                            placeholder="Name"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("adminName", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={touched.adminName && Boolean(errors.adminName)}
                            helperText={
                              errors.adminName &&
                              touched.adminName &&
                              errors.adminName
                            }
                          />
                        </Box>

                        <Box marginBottom="25px">
                          <FormInput
                            disabled={formType === 2}
                            name="adminUsername"
                            value={ad?.adminUsername}
                            idValue="adminUsername"
                            label="Username"
                            userAdorment={ad?.suffix}
                            placeholder="Username"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("adminUsername", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.adminUsername && Boolean(errors.adminUsername)
                            }
                            helperText={
                              errors.adminUsername &&
                              touched.adminUsername &&
                              errors.adminUsername
                            }
                          />
                        </Box>

                        <Box marginBottom="25px">
                          <FormInput
                            name="adminEmail"
                            value={ad?.adminEmail}
                            idValue="adminEmail"
                            label="Email"
                            placeholder="Email"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("adminEmail", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={touched.adminEmail && Boolean(errors.adminEmail)}
                            helperText={
                              errors.adminEmail &&
                              touched.adminEmail &&
                              errors.adminEmail
                            }
                          />
                        </Box>
                        <Box marginBottom="25px">
                          <FormInput
                            name="adminMobile"
                            value={ad?.adminMobile}
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("adminMobile", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.adminMobile && Boolean(errors.adminMobile)
                            }
                            helperText={
                              errors.adminMobile &&
                              touched.adminMobile &&
                              errors.adminMobile
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={containerDefault}>
                    <Box className={section}>
                      <Box marginBottom="25px">
                        <Roles
                          label="Position"
                          value={ad?.role}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            handleFormChange("role", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={touched.role && Boolean(errors.role)}
                          helperText={
                            touched.role && Boolean(errors.role) && errors.role
                          }
                        />
                      </Box>

                      {formType === 1 && (
                        <Box marginBottom="25px">
                          <FormInputPassword
                            name="adminPassword"
                            value={ad?.adminPassword}
                            label="Password"
                            placeholder="Password"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("adminPassword", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.adminPassword && Boolean(errors.adminPassword)
                            }
                            helperText={
                              errors.adminPassword &&
                              touched.adminPassword &&
                              errors.adminPassword
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box className={footer}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => setShowForm && setShowForm(false)}
                  >
                    CANCEL
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm(handleSubmit, isValid)}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default withContext(AdminForm)
