import React from "react"
import Box from "@material-ui/core/Box"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"

import Label from "components/Forms/Label"

interface FormInputProps {
  label?: string
  value: any
  handleDateChange: Function
  onBlur?: Function
  error?: boolean | undefined
  helperText?: any
  name: string
  placeholder?: string
  format?: string
  minDate?: any
  maxDate?: any
  shrinkLabel?: boolean
  disabled?: boolean
  inlineStyles?: Record<string, any>
}

const DatePicker: React.FC<FormInputProps> = ({
  label,
  value,
  handleDateChange,
  onBlur,
  error,
  helperText,
  name,
  placeholder,
  format,
  minDate,
  maxDate,
  shrinkLabel,
  disabled = false,
  inlineStyles = {}
}) => {
  return (
    <Box flex="1">
      {!shrinkLabel && <Label label={label || ""} />}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={disabled}
          label={shrinkLabel ? label : ""}
          defaultValue={placeholder}
          placeholder={placeholder}
          margin="normal"
          name={name}
          inputVariant="outlined"
          value={value}
          onChange={(e) => handleDateChange(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          onError={(e: any) => e}
          error={error}
          helperText={helperText}
          format={format}
          minDate={minDate}
          maxDate={maxDate}
          InputLabelProps={{ shrink: true }}
          InputProps={{ style: inlineStyles }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}
export default DatePicker
