import React from "react"

export type CtxType = {
  showPayment: Function
  setActiveFilter: Function
  filterTable: Function
  filterActiveTable: Function
  setDownloadData: Function
  activeFilter: number
  activeTable: number
  contextActions: Record<any, any>
  initData: Record<any, any>
  downloadData: any
}

export const Context = React.createContext<Partial<CtxType>>({})
