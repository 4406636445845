import React from "react"
/**
 * Component
 */
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
/** Utils */
import FacilityPayments from "./FacilityPayments"
import FacilityInfo from "./FacilityInfo"
/** Styles */
import styles from "./styles"

interface Props extends CtxType {
  facilityBooking: Record<string, any>
  forSummary?: boolean
}

const FacilitiesBreakdown: React.FC<Props> = ({
  facilityBooking,
  bookingDate
}): JSX.Element => {
  const classes = styles()

  function View() {
    return (
      <div className={classes.facility}>
        <FacilityInfo
          facilityBooking={facilityBooking}
          bookingDateValue={bookingDate}
        />
        <FacilityPayments facilityBooking={facilityBooking} />
      </div>
    )
  }

  return <div className={classes.container}>{View()}</div>
}

export default withContext(FacilitiesBreakdown)
