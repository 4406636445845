import * as React from "react"

const EPayment: React.FC = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 0C1.12 0 0 1.12 0 2.5V19.5C0 20.88 1.12 22 2.5 22H11.5C12.88 22 14 20.88 14 19.5V17H2V3H14V2.5C14 1.12 12.88 0 11.5 0H2.5ZM7 5V7H13.0879C13.1625 7.21031 13.2831 7.40131 13.4409 7.55911C13.5987 7.7169 13.7897 7.83749 14 7.91211V12.0879C13.7897 12.1625 13.5987 12.2831 13.4409 12.4409C13.2831 12.5987 13.1625 12.7897 13.0879 13H7V15H14.5H15C15.552 15 16 14.552 16 14V13.5V6.5V6C16 5.448 15.552 5 15 5H14.5H7ZM7 8V12C8.105 12 9 11.105 9 10C9 8.895 8.105 8 7 8ZM11 9C10.448 9 10 9.447 10 10C10 10.553 10.448 11 11 11C11.552 11 12 10.553 12 10C12 9.447 11.552 9 11 9ZM7 17.875C7.622 17.875 8.125 18.378 8.125 19C8.125 19.622 7.622 20.125 7 20.125C6.378 20.125 5.875 19.622 5.875 19C5.875 18.378 6.378 17.875 7 17.875Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default EPayment
