import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getApplications(params: Record<string, any>) {
  return request({
    url: API.LIST_APPLICATIONS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function createApplication(data: Object) {
  return request({
    url: API.CREATE_APPLICATION,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateApplication(data: Object, uid: string) {
  return request({
    url: `${API.CREATE_APPLICATION}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function deleteApplication(uid: string) {
  return request({
    url: `${API.DELETE_APPLICATION}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

function cancelApplication(uid: string) {
  return request({
    url: API.CANCEL_APPLICATION,
    method: "POST",
    headers: headers(),
    data: {
      "_uid": uid
    }
  })
}

function approveApplication(data: Object) {
  return request({
    url: API.APPROVE_APPLICATION,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function rejectApplication(data: Object) {
  return request({
    url: API.REJECT_APPLICATION,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function getVehicles(params?: Record<string, any>) {
  return request({
    url: API.LIST_VEHICLES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function createVehicle(data: Object) {
  return request({
    url: API.CREATE_VEHICLE,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateVehicle(data: Object, uid: string) {
  return request({
    url: `${API.UPDATE_VEHICLE}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function deleteVehicle(uid: string) {
  return request({
    url: `${API.DELETE_VEHICLE}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

function archivedVehicle(uid: string) {
  return request({
    url: API.ARCHIVED_VEHICLE,
    method: "POST",
    headers: headers(),
    data: {
      "_uid": uid
    }
  })
}

function getAccessCards(params?: Record<string, any>) {
  return request({
    url: API.LIST_ACCESS_CARDS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function createAccessCard(data: Object) {
  return request({
    url: API.CREATE_ACCESS_CARD,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateAccessCard(data: Object, uid: string) {
  return request({
    url: `${API.UPDATE_ACCESS_CARD}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function deleteAccessCard(uid: string) {
  return request({
    url: `${API.DELETE_ACCESS_CARD}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

function archivedAccessCard(uid: string) {
  return request({
    url: API.ARCHIVED_ACCESS_CARD,
    method: "POST",
    headers: headers(),
    data: {
      "_uid": uid
    }
  })
}

const BookkeepingAPI = {
  getApplications,
  approveApplication,
  rejectApplication,
  createApplication,
  updateApplication,
  cancelApplication,
  getVehicles,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  deleteApplication,
  archivedVehicle,
  getAccessCards,
  createAccessCard,
  updateAccessCard,
  deleteAccessCard,
  archivedAccessCard
}

export default BookkeepingAPI
