import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts"

/**
 * Component
 */
import Loader from "components/Loader"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Hooks
 */
import TotalFeedbackResolvedTimeHook from "./hooks"

const OPTIONS = [
  {
    "name": "Cleanliness",
    "color": "#09707B"
  },
  {
    "name": "Security",
    "color": "#A56300"
  },
  {
    "name": "Defects",
    "color": "#D8B469"
  },
  {
    "name": "Landscape",
    "color": "#0E61A2"
  },
  {
    "name": "Others",
    "color": "#454B57"
  }
]

const FeedbackResponse: React.FC = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const {
    isValidating,
    isValidating1to3Weeks,
    isValidatingMoreThan3Weeks,
    isValidatingCleanliness,
    isValidatingSecurity,
    isValidatingDefects,
    isValidatingLandscape,
    isValidatingOthers,
    newdata
  }: any = TotalFeedbackResolvedTimeHook(selectedDate)

  const isLoading =
    isValidating ||
    isValidating1to3Weeks ||
    isValidatingMoreThan3Weeks ||
    isValidatingCleanliness ||
    isValidatingSecurity ||
    isValidatingDefects ||
    isValidatingLandscape ||
    isValidatingOthers

  function ViewChart(): JSX.Element {
    if (isLoading) {
      return <Loader dark />
    }
    return (
      <Fade in timeout={2000}>
        <Box>
          <LineChart
            width={500}
            height={300}
            data={newdata}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />

            <Line
              type="monotone"
              dataKey="cleanliness"
              stroke="#09707B"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="security" stroke="#A56300" />
            <Line type="monotone" dataKey="defects" stroke="#D8B469" />
            <Line type="monotone" dataKey="landscape" stroke="#0E61A2" />
            <Line type="monotone" dataKey="others" stroke="#454B57" />
          </LineChart>
        </Box>
      </Fade>
    )
  }

  function ViewOptions(): JSX.Element {
    return (
      <div className={classes.options}>
        {OPTIONS.map((option: Record<string, any>) => (
          <div className={classes.optionContainer} key={`${option?.name}`}>
            <div
              className={classes.optionColor}
              style={{ backgroundColor: `${option.color}` }}
            />
            <div className={classes.optionName}>{option?.name}</div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div className={classes.tfrInfo}>
        <div className={classes.title}>Feedback Response Time per category</div>
      </div>
      <div>{ViewOptions()}</div>
      {ViewChart()}
    </div>
  )
}
export default FeedbackResponse
