import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"
import { Formik } from "formik"
import * as Yup from "yup"

/** Components */
import FormInput from "components/Forms/FormInput"
import Footer from "components/Forms/Footer"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Bookkeeping/main/Context"

interface Props {
  activeView: number
}

const CategoryForm: React.FC<Props> = ({ activeView }) => {
  const { modesOfPayments, setModesOfPayment, updateBookkeeping } = useContext(
    Context
  )
  const mop = modesOfPayments[activeView]

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    handleChange: Function,
    setFieldValue: Function,
    target: number
  ) => {
    handleChange(e)
    setFieldValue(name, e?.target?.value)
    setModesOfPayment &&
      setModesOfPayment({
        ...modesOfPayments,
        [target]: {
          ...modesOfPayments[target],
          [name]: e?.target?.value
        }
      })
  }

  const handleFormSubmit = (handleSubmit: Function) => {
    handleSubmit()
    if (mop?.type_name !== "" && mop?.instructions) {
      updateBookkeeping && updateBookkeeping()
    }
  }

  return (
    <Box>
      <Formik
        initialValues={{
          typeName: mop?.type_name,
          instructions: mop?.instructions,
          isEnabled: mop?.is_enabled
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          typeName: Yup.string().required("Required"),
          instructions: Yup.string().required("Required")
        })}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange
        }): JSX.Element => {
          return (
            <form>
              <Box marginBottom="25px">
                <FormInput
                  name="typeName"
                  value={mop?.type_name}
                  idValue="typeName"
                  label="Type Name"
                  placeholder="Type Name"
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(
                      "type_name",
                      e,
                      handleChange,
                      setFieldValue,
                      activeView
                    )}
                  onBlur={handleBlur}
                  error={touched.typeName && Boolean(errors.typeName)}
                  helperText={errors.typeName && touched.typeName && errors.typeName}
                />
              </Box>
              <Box marginBottom="25px">
                <FormInput
                  name="instructions"
                  value={mop?.instructions}
                  idValue="instructions"
                  label="Instructions"
                  placeholder="Instructions"
                  rows={3}
                  multiline
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(
                      "instructions",
                      e,
                      handleChange,
                      setFieldValue,
                      activeView
                    )}
                  onBlur={handleBlur}
                  error={touched.instructions && Boolean(errors.instructions)}
                  helperText={
                    errors.instructions &&
                    touched.instructions &&
                    errors.instructions
                  }
                />
              </Box>
              <Box>
                <Checkbox
                  label="Disabled"
                  value={mop?.is_enabled || false}
                  onChange={() =>
                    setModesOfPayment &&
                    setModesOfPayment({
                      ...modesOfPayments,
                      [activeView]: {
                        ...modesOfPayments[activeView],
                        "is_enabled": !mop?.is_enabled
                      }
                    })}
                />
              </Box>
              <Box marginTop="40px">
                <Footer
                  handleNext={() => handleFormSubmit(handleSubmit)}
                  handleBack={null}
                  handleCancel={() => null}
                  hideCancel
                  label="Submit"
                  stretch
                  noBorder
                />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default CategoryForm
