import React, { useState } from "react"
import Cookie from "js-cookie"
import { toast } from "react-toastify"

/** Config */
import { fromUnix, toUnix } from "utils/date"
/** Service */
import service from "services/Dashboard/Announcements/Post"
/** Utils */
import { UploadImage } from "utils/uploadFile"
import { Context } from "./Context"

export interface ProviderProps {
  data?: Record<string, any>
  sendBack: Function
}

const Provider: React.FC<ProviderProps> = ({ children, data, sendBack }) => {
  const hasData = data !== undefined
  const [postDetails, setPostDetails] = useState({
    title: data?.title || "",
    shortDescription: data?.short_description || "",
    description: data?.description || "",
    announcementType: data?.announcement_type || 1,
    status: data?.status || 1,
    startDate: data?.start_date !== undefined ? fromUnix(data?.start_date) : "",
    endDate: data?.end_date !== undefined ? fromUnix(data?.end_date) : ""
  })
  const [photos, setPhotos] = useState<Array<{}>>(
    data?.announcement_photo?.photos || []
  )
  const [files, setFiles] = useState(data?.attachment?.files || [])
  const [attachingPhoto, setAttachingPhoto] = useState<boolean>(false)
  const [attachingFile, setAttachingFile] = useState<boolean>(false)
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState({})

  /** Notification */
  const notifyCreate = () =>
    toast(`Successfully ${hasData ? "updated" : "created"} the Announcement Post.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleCreatePost = async (status: number) => {
    const pd = postDetails
    const payload = {
      "condo_uid": Cookie.get("condoUID"),
      "title": pd?.title,
      "short_description": pd?.shortDescription,
      "description": pd?.description,
      "announcement_type": 1,
      "status": status,
      "start_date": pd?.startDate !== "" ? toUnix(pd?.startDate.toString()) : "",
      "end_date": pd?.endDate !== "" ? toUnix(pd?.endDate.toString()) : "",
      "announcement_photo": {
        "photos": photos
      },
      "attachment": {
        "files": [
          {
            ...files[0],
            "file_type": 2
          }
        ]
      }
    }
    const endpoint = hasData
      ? service.updatePost(payload, data?._uid)
      : service.createPost(payload)
    setFormSubmitting(true)

    try {
      const response = await endpoint
      setFormSubmitting(false)
      notifyCreate()
      sendBack()
      return response
    } catch (e) {
      setFormSubmitting(false)
      return e
    }
  }

  function handleImageUpload(event: any) {
    if (event) {
      setPreviewImage(window?.URL?.createObjectURL(event[0]))
    }

    setAttachingPhoto(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttachingPhoto(false)
      })
      .catch((e) => {
        setAttachingPhoto(false)
        return e
      })
  }

  const removeImage = (value: number) => {
    const fileFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })
    setPreviewImage({})
    setPhotos(fileFiltered)
  }

  function handleFileUpload(event: any) {
    setAttachingFile(true)
    UploadImage(event, 2)
      .then((output: any) => {
        setFiles([...files, output])
        setAttachingFile(false)
      })
      .catch(() => setAttachingFile(false))
  }

  const removeFile = (value: number) => {
    const fileFiltered = files.filter((item: any, index: number) => {
      return index !== value
    })
    setFiles(fileFiltered)
  }

  return (
    <Context.Provider
      value={{
        hasData,
        previewImage,
        photos,
        files,
        formSubmitting,
        attachingPhoto,
        attachingFile,
        postDetails,
        setPostDetails,
        handleCreatePost,
        handleFileUpload,
        removeFile,
        handleImageUpload,
        removeImage,
        sendBack
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
