import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import FormLabel from "components/Forms/Label"

interface FormInputProps {
  label?: string
  placeholder?: string
  handleOnChange: Function
  name: string
  onBlur?: Function
  error?: boolean | undefined
  helperText?: any
  value?: string | number | null | boolean
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  handleOnChange,
  name,
  onBlur,
  error,
  helperText,
  value
}) => {
  const [isVisible, setVisibility] = useState(false)
  const activeIcon = isVisible ? <Visibility /> : <VisibilityOff />

  return (
    <Box>
      {label && <FormLabel label={label} />}
      <TextField
        label=""
        name={name}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        onChange={(e: any) => handleOnChange(e)}
        onBlur={(e) => onBlur && onBlur(e)}
        error={error}
        helperText={helperText}
        type={isVisible ? "text" : "password"}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setVisibility(!isVisible)}>
                {activeIcon}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}
export default FormInput
