import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "24px",
    lineHeight: "28px",
    marginBottom: "20px"
  },
  sectionTitle: {
    width: "316px",
    margin: "0 auto 15px",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: 500
  },
  previewButtons: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 30px",
    borderBottom: "1px solid #F2F2F2"
  },
  block: {
    marginBottom: "17px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "28px",
    marginBottom: "12px"
  }
}))

export default styles
