import React from "react"

import RefundProvider, {
  RefundProviderProps
} from "context/Dashboard/Bookkeeping/forms/closeRefund/Provider"
import RefundView from "context/Dashboard/Bookkeeping/forms/closeRefund/View"

const ClosePaymentSchedule: React.FC<RefundProviderProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <RefundProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <RefundView />
  </RefundProvider>
)

export default ClosePaymentSchedule
