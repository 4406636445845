import React from "react"

import AddApplicationsProvider, {
  AddApplicationsProviderProps
} from "context/Dashboard/Applications/forms/addApplication/Provider"
import ApplicationsView from "context/Dashboard/Applications/forms/addApplication/View"

const AddApplications: React.FC<AddApplicationsProviderProps> = ({
  data,
  type,
  showMain,
  settings,
  activeTable
}) => (
  <AddApplicationsProvider
    data={data}
    type={type}
    showMain={showMain}
    settings={settings}
    activeTable={activeTable}
  >
    <ApplicationsView />
  </AddApplicationsProvider>
)

export default AddApplications
