import { type } from "config/Dashboard/Applications"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "Reference Number",
        accessor: "reference_no",
        filterable: false,
        sortable: false
      },
      {
        Header: "Block/ Unit No.",
        accessor: "unit.short_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Name",
        accessor: "applicant_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Application Type",
        Cell: ({ original }: any) => {
          return type[original?.application_type] || "N/A"
        },
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
