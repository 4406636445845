import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "28px 0"
  },

  totalWrapper: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 23px"
  },
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  totalStyle: {
    color: theme.palette.body.greenLight100,
    fontSize: "64px",
    fontWeight: 500
  },
  body: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  viewAll: {
    marginTop: "30px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  }
}))

export default styles
