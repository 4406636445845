import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"

/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import Label from "components/Forms/Label"
import RefButton from "components/Forms/RefButton"

/** Config */
import { addCategory } from "config/Settings/PropertyManagement/Feedback/validation"

/** Ref */
import { refSubmit } from "."

/** Styles */
import styles from "./styles"

interface Props {
  details: Record<string, any>
  formChange: Function
}

const CategoryFormWrapper: React.FC<Props> = ({ details, formChange }) => {
  const { section } = styles()

  return (
    <Box margin="auto" padding="0 0 40px 0">
      <FormWrapper>
        <Formik
          initialValues={{
            name: details?.name || "",
            bccEmailFirst: details?.bccEmailFirst || ""
          }}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={addCategory}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched
          }): JSX.Element => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className={section}>
                  <Label label="Category Name" />
                  <Box>
                    <FormInput
                      name="name"
                      placeholder="Category Name"
                      value={values?.name || ""}
                      handleOnChange={(e: any): void =>
                        formChange("name", e, handleChange, setFieldTouched)}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={errors.name && touched.name && errors.name}
                    />
                  </Box>
                </Box>

                <Box className={section}>
                  <Label label="BCC Email" />
                  <Box paddingBottom="15px">
                    <FormInput
                      name="bccEmailFirst"
                      placeholder="Email Address"
                      value={values?.bccEmailFirst}
                      handleOnChange={(e: any): void =>
                        formChange("bccEmailFirst", e, handleChange, setFieldTouched)}
                      onBlur={handleBlur}
                      error={touched.bccEmailFirst && Boolean(errors.bccEmailFirst)}
                      helperText={
                        errors.bccEmailFirst &&
                        touched.bccEmailFirst &&
                        errors.bccEmailFirst
                      }
                    />
                  </Box>
                </Box>

                <RefButton refValue={refSubmit} action={handleSubmit} />
              </form>
            )
          }}
        </Formik>
      </FormWrapper>
    </Box>
  )
}

export default CategoryFormWrapper
