import * as React from "react"

interface FacilitiesProps {
  color: string
}

const Facilities: React.FC<FacilitiesProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58301 0.166992V3.41699H5.74967V0.166992H3.58301ZM7.91634 0.166992V3.41699H10.083V0.166992H7.91634ZM12.2497 0.166992V3.41699H14.4163V0.166992H12.2497ZM0.333008 4.50033V5.58366C0.333008 8.46134 2.14071 10.8978 4.75098 12.1852L0.333008 21.8337H2.68587L4.68327 17.5003H13.3013L13.7287 18.4229C13.472 18.787 13.3338 19.2215 13.333 19.667C13.333 20.2416 13.5613 20.7927 13.9676 21.1991C14.3739 21.6054 14.925 21.8337 15.4997 21.8337C16.0743 21.8337 16.6254 21.6054 17.0317 21.1991C17.4381 20.7927 17.6663 20.2416 17.6663 19.667C17.6659 19.1264 17.4633 18.6054 17.0985 18.2065C16.7336 17.8076 16.2328 17.5595 15.6943 17.5109L13.2293 12.1958C15.8502 10.911 17.6663 8.46854 17.6663 5.58366V4.50033H0.333008ZM2.75993 6.66699H15.2394C14.6292 9.07276 12.2045 11.0003 8.99967 11.0003C5.79482 11.0003 3.37018 9.07276 2.75993 6.66699ZM6.80127 12.9089C7.50766 13.0716 8.24234 13.167 8.99967 13.167C9.74863 13.167 10.4755 13.0745 11.1748 12.9152L12.2962 15.3337H5.68197L6.80127 12.9089Z"
        fill={color}
      />
    </svg>
  )
}

export default Facilities
