import Cookies from "js-cookie"

import request from "services/request"
import API from "./endpoints"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function getCountAccountRequests(params?: Record<string, any>) {
  return request({
    url: API.COUNT_ACCOUNT_REQUESTS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getCountChangeAddress(params?: Record<string, any>) {
  return request({
    url: API.CHANGE_ADDRESS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getCountFeedback(params?: Record<string, any>) {
  return request({
    url: API.FEEDBACK_NO_REPLY,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalCollections(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_COLLECTIONS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      "payment_type": 1,
      ...params
    },
    headers: headers()
  })
}

function getTotalDeposits(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_COLLECTIONS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      "payment_type": 2,
      ...params
    },
    headers: headers()
  })
}

function getListApplications(params?: Record<string, any>) {
  return request({
    url: API.LIST_APPLICATIONS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getServiceProviders(params?: Record<string, any>) {
  return request({
    url: API.LIST_SERVICE_PROVIDERS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getDeliveries(params?: Record<string, any>) {
  return request({
    url: API.LIST_DELIVERIES,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getVisitors(params?: Record<string, any>) {
  return request({
    url: API.LIST_VISITORS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getCountAttendance(params?: Record<string, any>) {
  return request({
    url: API.COUNT_ATTENDANCE,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getTotalEmployee(params?: Record<string, any>) {
  return request({
    url: API.TOTAL_EMPLOYEE,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getLastTimein(params?: Record<string, any>) {
  return request({
    url: API.LAST_ATTENDANCE,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getExpiringSoon(params?: Record<string, any>) {
  return request({
    url: API.EXPIRING_SOON,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getScheduleServicing(params?: Record<string, any>) {
  return request({
    url: API.SCHEDULE_SERVICING,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

const DashboardApi = {
  getCountAccountRequests,
  getCountChangeAddress,
  getCountFeedback,
  getTotalCollections,
  getTotalDeposits,
  getListApplications,
  getServiceProviders,
  getDeliveries,
  getVisitors,
  getCountAttendance,
  getTotalEmployee,
  getLastTimein,
  getExpiringSoon,
  getScheduleServicing
}

export default DashboardApi
