import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  generalInfo: {
    // width: "40%",
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      borderBottom: "1px solid #F2F2F2",
      marginBottom: "40px",
      // width: "40%",
      margin: "0 auto"
    }
  },
  userOptions: {
    display: "flex",
    flexDirection: "row"
  },
  spaceBottom: {
    paddingBottom: "20px"
  }
}))

export default styles
