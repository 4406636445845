import React, { lazy, Suspense, createContext, useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

// Layout
import WithHeader from "layout/WithHeader"

// components
import PageHeaderTitle from "components/PageHeaderTitle"
import Stepper from "components/Stepper"
import Footer from "./components/Footer"

export type SelectFacilityCtxType = {
  activeFacilities: Array<string>
  setactiveFacilities: Function
}

// context
export const AddBookingContext = createContext<Partial<SelectFacilityCtxType>>({})

const SelectFacility = lazy(() => import("./components/SelectFacility"))
const BookingDetails = lazy(() => import("./components/BookingDetails"))
const Confirmation = lazy(() => import("./components/Confirmation"))
const Payment = lazy(() => import("./components/Payment"))

function getSteps() {
  return ["Select Facility", "Booking Details", "Confirmation"]
}

function getStepContent(step: any) {
  switch (step) {
    case 1:
      return <SelectFacility />
    case 2:
      return <BookingDetails />
    case 3:
      return <Payment />
    case 4:
      return <Confirmation />
    default:
      return null
  }
}

const AddBookingForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1)
  const [activeFacilities, setactiveFacilities] = useState<Array<string>>(["bbqpit"])
  const steps = getSteps()

  // Methods
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <WithHeader>
        <PageHeaderTitle
          title="Add New Booking"
          breadcrumbs="property management / facilities / add new booking"
        />
        <Card style={{ padding: "0 0 40px 0" }}>
          <Stepper
            items={["Select Facility", "Booking Details", "Payment", "Confirmation"]}
            activeStep={activeStep}
            justifyStart
          />
          <Box>
            <Suspense fallback={<h1>Loading</h1>}>
              <AddBookingContext.Provider
                value={{
                  activeFacilities,
                  setactiveFacilities
                }}
              >
                {getStepContent(activeStep)}
              </AddBookingContext.Provider>
            </Suspense>
          </Box>

          <Footer
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={activeStep === 1 ? () => null : handleBack}
            steps={steps}
            endButton="Submit"
          />
        </Card>
      </WithHeader>
    </>
  )
}

export default AddBookingForm
