import React, { useState, useEffect } from "react"
import Cookie from "js-cookie"
/**
 * Components
 */
import Toast from "components/Toast"
/** Services */
import UnitManagementAPI from "services/Dashboard/UnitManagement"
/** Context */
import { Context as ContextMain } from "context/Dashboard/UnitManagement/categories/main/Context"
import { toUnix } from "utils/date"
import { UploadImage } from "utils/uploadFile"
import { Context } from "./Context"

/** Utils */

interface CreateUserProps {
  data?: Record<string, any>
}

const CreateUserProvider: React.FC<CreateUserProps> = ({ children, data }) => {
  const { showMain } = React.useContext(ContextMain)
  /** check if its from new request or owners/tenants */
  /** this will be used for the profile photos/attachments */
  const hasData = data !== undefined
  const fromData = data?.attachments !== undefined

  const [openDialog, setOpenDialog] = useState(false)
  const [attaching, setAttaching] = useState(false)
  const [fileAttaching, setFileAttaching] = useState(false)
  const [photos, setPhotos] = useState<any>(Array)
  const [localPhotos, setLocalPhotos] = useState<any>(Array)
  const [files, setFiles] = useState<any>(data?.supporting_doc?.files || Array)
  const [userDetails, setuserDetails] = useState({
    uid: data?._uid,
    name: data?.name || "",
    firstName: "",
    lastName: "",
    phoneNo: data?.phone_no || "",
    mobileNo: data?.mobile_no || "",
    unitUid: data?.unit_uid || "",
    email: data?.email || "",
    isResident: data?.resident_user_type !== 1,
    registeredAddress: data?.registered_address || "",
    strataTitle: data?.resident_user_type === 1 || data?.resident_user_type === 2,
    tenancyStart: data?.tenancy_start === undefined ? "" : data?.tenancy_start,
    tenancyEnd: data?.tenancy_end === undefined ? "" : data?.tenancy_end,
    formType: data?.resident_user_type === 3 ? "tenant" : "owner",
    blockUnit: data?.unit_uid || data?.unit?._uid || ""
  })

  useEffect(() => {
    if (fromData) {
      setPhotos(data?.attachments)
      setLocalPhotos(data?.attachments)
    }
    if (data?.profile_photo || data?.profile_photo?.key !== "") {
      setPhotos([data?.profile_photo])
      setLocalPhotos([data?.profile_photo])
    }
    if (!hasData) {
      setPhotos([])
      setLocalPhotos([])
    }
    if (data?.attachments === undefined) {
      setPhotos([])
      setLocalPhotos([])
    }
  }, [])

  /** Notification */
  // const notifyCreate = () =>
  //   toast(`Successfully created the owner.`, {
  //     type: toast.TYPE.SUCCESS,
  //   })

  // const notifyError = (errorMsg: string) =>
  //   toast(`${errorMsg}`, {
  //     type: toast.TYPE.ERROR,
  //   })

  /** Methods */
  async function handleRegisterUser() {
    const ownerData = {
      "name": userDetails.name,
      "phone_no": userDetails.phoneNo,
      "mobile_no": userDetails.mobileNo,
      "unit_uid": userDetails.blockUnit,
      "email": userDetails.email,
      "is_resident": userDetails.isResident,
      "registered_address": userDetails.registeredAddress,
      "strata_title": userDetails.strataTitle,
      "profile_photo": {
        "key": photos[0]?.key || "",
        "file_name": photos[0]?.file_name || "",
        "encoding": photos[0]?.encoding || ""
      },
      "supporting_doc": {
        "files": files
      },
      "condo_uid": Cookie.get("condoUID"),
      "account_request_uid": data?._uid
    }
    const tenantData = {
      "name": userDetails.name,
      "phone_no": userDetails.phoneNo,
      "mobile_no": userDetails.mobileNo,
      "unit_uid": userDetails.blockUnit,
      "email": userDetails.email,
      "registered_address": userDetails.registeredAddress,
      "tenancy_start": toUnix(userDetails?.tenancyStart),
      "tenancy_end": toUnix(userDetails?.tenancyEnd),
      "profile_photo": {
        "key": photos[0]?.key || "",
        "file_name": photos[0]?.file_name || "",
        "encoding": photos[0]?.encoding || ""
      },
      "supporting_doc": {
        "files": files
      },
      "condo_uid": Cookie.get("condoUID"),
      "account_request_uid": data?._uid
    }
    const formData = userDetails.formType === "owner" ? ownerData : tenantData

    try {
      const response =
        userDetails.formType === "owner"
          ? await UnitManagementAPI.createOwner(formData)
          : await UnitManagementAPI.createTenant(formData)

      const statusCode = response?.data?._statusCode

      if (statusCode === -114) {
        return Toast(response?.data?._message, false)
      }

      if (statusCode === -113) {
        return Toast(response?.data?._message, false)
      }

      if (statusCode === -121) {
        return Toast("Email or Name already exists. Please try again.", false)
      }

      Toast(
        `${userDetails.strataTitle ? "OWNER" : "RESIDENT"} successfully created.`,
        true
      )

      showMain && showMain()

      return response
    } catch (e) {
      Toast("Error creating a user.", false)
      return e
    }
  }

  function handleImageUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  function handleFileUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 2)
      .then((output: any) => {
        setFiles([...files, output])
        setFileAttaching(false)
      })
      .catch((e) => {
        setFileAttaching(false)
        return e
      })
  }

  const removePhoto = (value: number) => {
    const photoFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })

    const fileLocalFiltered = localPhotos.filter((item: any, index: number) => {
      return index !== value
    })

    setPhotos(photoFiltered)
    setLocalPhotos(fileLocalFiltered)
  }

  const removeFile = (value: number) => {
    const fileFiltered = files.filter((item: any, index: number) => {
      return index !== value
    })
    setFiles(fileFiltered)
  }

  return (
    <Context.Provider
      value={{
        handleRegisterUser,
        handleImageUpload,
        setPhotos,
        removePhoto,
        setOpenDialog,
        setuserDetails,
        setFiles,
        handleFileUpload,
        removeFile,
        userDetails,
        attaching,
        fileAttaching,
        photos,
        localPhotos,
        openDialog,
        files
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreateUserProvider
