import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Drawer from "components/Drawer"
import Header from "components/Header"
import Form from "./Form"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
  hasTimeout: boolean
}

const LogStaff: React.FC<DetailsProps> = ({ openState, setopenState }) => {
  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header title="Time-Out Staff" subtitle="Attendance" subtitleAbove />
      <Box height="100%">
        <Form />
      </Box>
    </Drawer>
  )
}
export default LogStaff
