import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: 500
  },
  total: {
    color: theme.palette.body.greenLight,
    fontSize: "48px",
    fontWeight: 500
  },
  body: {
    color: theme.palette.body.secondary,
    fontSize: "14px"
  },
  percentage: {
    color: theme.palette.body.greenLight,
    fontSize: "14px"
  },
  bar: {
    height: "18px",
    borderRadius: "20px",
    backgroundColor: "#F2F2F2"
  },
  barHighlight: {
    height: "18px",
    background: theme.palette.body.greenLight,
    borderRadius: "20px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 23px"
  }
}))

export default styles
