import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { Link } from "react-router-dom"

interface FooterProps {
  activeStep: number
  handleNext: Function
  handleBack: Function
  steps: any
  endButton: string
  disabled?: boolean
}

const Footer: React.FC<FooterProps> = ({
  activeStep,
  handleNext,
  handleBack,
  steps,
  endButton,
  disabled
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="30px 40px 0"
      borderTop="1px solid #F2F2F2"
    >
      <Box>
        <Button
          size="large"
          color="primary"
          component={Link}
          to="/property-management/facilities"
        >
          Back
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        <Box marginRight="20px">
          <Button
            color="primary"
            size="large"
            disabled={activeStep === 0}
            onClick={() => handleBack()}
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext()}
            size="large"
            style={{ width: "169px" }}
            disabled={disabled}
          >
            {activeStep === steps.length ? endButton : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
