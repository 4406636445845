import request from "../request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function loginUser(data: Object) {
  return request({
    url: API.LOGIN_USER,
    method: "POST",
    headers: {
      "Content-Type": "text/plain"
    },
    data: data
  })
}

function loginStaff(data: Object) {
  return request({
    url: API.LOGIN_STAFF,
    method: "POST",
    headers: {
      "Content-Type": "text/plain"
    },
    data: data
  })
}

function forgotPassword(email: String) {
  return request({
    url: API.FORGOT_PASSWORD,
    method: "POST",
    data: {
      "email": email,
      "device_id": "web",
      "method_type": "ACCESS_CODE"
    }
  })
}

function getUserPermission(roleUID: String) {
  return request({
    url: `${API.GET_ROLE}/${roleUID}`,
    method: "GET",
    headers: headers()
  })
}

function resetPassword(password: String, accessToken: String, deviceId: String) {
  return request({
    url: API.RESET_PASSWORD,
    method: "POST",
    headers: {
      "Authorization": "Bearer " + accessToken,
      "X-Device-Identifier": deviceId
    },
    data: {
      "new_password": password
    }
  })
}

const OnboardAPI = {
  loginUser,
  forgotPassword,
  loginStaff,
  getUserPermission,
  resetPassword
}

export default OnboardAPI
