import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Component */
import {
  IconView,
  IconCancel,
  IconEdit,
  IconApprove,
  IconReject
  // IconPending,
} from "components/Icons/ContextMenu"
import Dialog from "components/Dialog"
/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { Context as MainContext } from "context/Dashboard/Applications/categories/main/Context"
import { Context as RootContext } from "context/Dashboard/Applications/main/Context"
import { AppContext } from "App"
import message from "config/Text/Dashboard/Applications"
import { dateSameOrAfter } from "utils/date"

/** Styles */
import styles from "./styles"

interface ContextProps {
  data: Record<string, any>
}

const ContextRenovation: React.FC<ContextProps> = ({ data }) => {
  const { listItem } = styles()
  const status = data?.status
  const appType = data?.application_type
  const startDate = data?.start_date

  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const {
    showUpdateApplication,
    showApproveApplication,
    setShowDetails,
    setActiveDetails,
    handleCancelItem,
    activeFilter
  } = useContext(MainContext)
  const { setShowReject, setActiveData, settings } = useContext(RootContext)
  const canEdit = permission?.application_edit
  const canCancel = !dateSameOrAfter(
    settings[appType].allowedCancellation,
    startDate
  )

  // const allowedDateMessage = () => {
  //   if (settings[6]?.advance_booking_days === 0) {
  //     return ""
  //   }

  //   return `Cancel days + today <= ${displayDate(
  //     settings[6]?.allowedCancellation,
  //     "MMM DD, YYYY"
  //   )}.`
  // }

  /** Methods */
  function handleReject() {
    setShowReject && setShowReject(true)
    setActiveData && setActiveData(data)
    openContext && openContext(false)
  }

  function handleShowDetails(items: Record<string, any>) {
    openContext && openContext(false)
    setShowDetails && setShowDetails(true)
    setActiveDetails && setActiveDetails(items)
  }

  function handleDialog() {
    setOpenDialog(true)
    openContext && openContext(false)
  }

  function handleCancel() {
    openContext && openContext(false)
    handleCancelItem &&
      handleCancelItem(data?._uid, `fetchApplicationsRenovation-${activeFilter}`)
  }

  function showMessage() {
    // if (checkExpired(data?.request_data?.move_in_out?.eta_ms)) {
    //   return message?.expiredBookingCancel
    // }

    if (canCancel) {
      return message?.confirmCancel
    }

    return message?.cancelBookingNotAllowed
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        {status === 1 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={(): void | null =>
              !canEdit ? null : showUpdateApplication && showUpdateApplication(data)}
          >
            <i>{IconEdit}</i>
            Edit
          </ListItem>
        )}
        {status !== 2 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() =>
              !canEdit
                ? null
                : showApproveApplication && showApproveApplication(data)}
          >
            <i>{IconApprove}</i>
            Approve
          </ListItem>
        )}
        {status !== 3 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() => (!canEdit ? null : handleReject())}
          >
            <i>{IconReject}</i>
            Reject
          </ListItem>
        )}
        {status === 1 && (
          <ListItem
            className={listItem}
            button
            disabled={!canEdit}
            onClick={() => (!canEdit ? null : handleDialog())}
          >
            <i>{IconCancel}</i>
            Cancel
          </ListItem>
        )}
      </List>

      <Dialog
        action={() => handleCancel()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message={showMessage()}
      />
    </>
  )
}

export default ContextRenovation
