import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  timelineTitle: {
    color: theme.palette.body.main,
    fontSize: "14px"
  },
  timelineSubtitle: {
    color: theme.palette.body.secondary,
    fontSize: "12px"
  },
  dot: {
    width: "8px",
    height: "8px",
    backgroundColor: "#D14339",
    position: "absolute",
    top: "5px",
    left: "-25px",
    borderRadius: "50%"
  },
  totalStyle: {
    color: theme.palette.body.gold,
    fontSize: "34px",
    fontWeight: 500,
    "& small": {
      fontSize: "12px",
      paddingRight: "10px"
    }
    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "green",
    // },
    // [theme.breakpoints.up("md")]: {
    //   backgroundColor: "red",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   backgroundColor: "orange",
    // },
  },
  iconContainer: {
    minWidth: "24px",
    minHeight: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px"
  },
  iconContainerLarge: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  amount: {
    fontSize: "20px",
    lineHeight: "22px",
    color: theme.palette.body.secondary,
    "& small": {
      fontSize: "12px",
      paddingRight: "5px",
      color: theme.palette.body.gray
    }
  },
  type: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.body.dark
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    flex: "1",
    alignItems: "center"
  },
  totalSubtitle: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  titleBreakdown: {
    color: theme.palette.secondary.dark,
    fontSize: "12px",
    paddingBottom: "20px"
  }
}))

export default styles
