import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    padding: "30px 50px 0",
    "& .submitBtn": {
      minWidth: "150px",
      marginLeft: "20px"
    },
    "& .footer": {
      borderTop: "1px solid #F2F2F2",
      display: "flex",
      justifyContent: "flex-end",
      padding: "30px 0",
      margin: "80px 0 0 0"
    }
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
    color: theme.palette.secondary.dark,
    marginBottom: "30px"
  },
  uploadContainer: {
    minHeight: "240px"
  },
  field: {
    marginBottom: "30px"
  }
}))

export default styles
