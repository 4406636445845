import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "72px",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottom: "1px solid #F2F2F2"
  },
  stepper: {
    fontSize: "14px",
    textTransform: "uppercase",
    letterSpacing: "0.0125em",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderBottom: "2px solid transparent",
    padding: "0 20px",
    cursor: "pointer",
    "& > span": {
      fontSize: "20px",
      fontWeight: 600,
      marginRight: "8px"
    },
    "&.active": {
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    }
  },
  defaultContainer: {
    display: "flex",
    height: "72px",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #F2F2F2"
  },
  defaultStepper: {
    fontSize: "14px",
    textTransform: "uppercase",
    letterSpacing: "0.0125em",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    height: "100%",
    margin: "0 40px",
    borderBottom: "2px solid transparent",
    padding: "0 20px",
    cursor: "pointer",
    "& > span": {
      fontSize: "20px",
      fontWeight: 600,
      marginRight: "8px"
    },
    "&.active": {
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    }
  }
}))

export default styles
