import React from "react"
import Box from "@material-ui/core/Box"
/** Components */
import All from "pages/Dashboard/Feedback/categories/all"
import Cleanliness from "pages/Dashboard/Feedback/categories/cleanliness"
import Security from "pages/Dashboard/Feedback/categories/security"
import Defects from "pages/Dashboard/Feedback/categories/defects"
import Landscape from "pages/Dashboard/Feedback/categories/landscape"
import Others from "pages/Dashboard/Feedback/categories/others"
/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

const FeedbackMainView: React.FC<CtxType> = ({ activeTable, isValidating }) => {
  const TableView = (value: number) => {
    switch (value) {
      case 0:
        return <All />
      case 1:
        return <Cleanliness />
      case 2:
        return <Security />
      case 3:
        return <Defects />
      case 4:
        return <Landscape />
      case 5:
        return <Others />
      default:
        break
    }
    return value
  }

  return (
    <Box
      style={{
        paddingBottom: isValidating ? "100vh" : "10px"
      }}
    >
      {TableView(activeTable)}
    </Box>
  )
}

export default withContext(FeedbackMainView)
