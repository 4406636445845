import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Machine */
import { CategoryFormMachine } from "machines/Settings/PropertyManagement/Facilities/categoryForm"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import TabsButtons from "components/Table/components/TabsButtons/static"
import FormDetails from "pages/Settings/PropertyManagement/Facilities/components/CategoryForm/Details"
import FormEntitlement from "pages/Settings/PropertyManagement/Facilities/components/CategoryForm/Entitlement"
import FormTimeSlot from "pages/Settings/PropertyManagement/Facilities/components/CategoryForm/TimeSlot"
import FormEffectiveDate from "pages/Settings/PropertyManagement/Facilities/components/CategoryForm/EffectiveDate"
import Footer from "components/Forms/Footer/new"
import Dialog from "components/Dialog"

/** Config */
import { tabs as OptionTabs } from "config/Settings/PropertyManagement/Facilities"

/** Context */
import { StateValue } from "xstate"
import withContext from "./withContext"
import { CtxType } from "./Context"

export const refSubmit = React.createRef<HTMLDivElement>()

const SettingsFacilitiesView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  submitting,
  showTable,
  handleAddCategory,
  handleUpdateCategory,
  hasData,
  timeoverlap,
  timeSlots,
  checkHasBlank
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [validEntitlement, setValidEntitlement] = useState(false)
  const [validDetails, setValidDetails] = useState(false)
  const [validEffectiveDate, setValidEffectiveDate] = useState(false)
  const [current, send] = useMachine(CategoryFormMachine)
  const xValue = current?.value

  function steps(step: StateValue) {
    switch (step) {
      case "details":
        return 0
      case "entitlement":
        return 1
      case "timeSlots":
        return 2
      case "effectiveDate":
        return 3
      default:
        return 0
    }
  }

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    /** Step 1 */
    if (xValue === "details" && validDetails) {
      send("NEXT")
    }

    /** Step 2*/
    if (xValue === "entitlement" && validEntitlement) {
      send("NEXT")
    }

    /** Step 3*/
    if (
      xValue === "timeSlots" &&
      !timeoverlap?.overlap &&
      timeSlots[0]?.startTime &&
      !checkHasBlank()
    ) {
      send("NEXT")
    }

    /** Step 4*/
    if (xValue === "effectiveDate" && validEffectiveDate) {
      hasData ? handleUpdateCategory() : handleAddCategory()
    }
  }

  function ViewDetails(): JSX.Element {
    if (xValue !== "details") {
      return <div />
    }
    return <FormDetails setValid={setValidDetails} />
  }

  function ViewEntitlement(): JSX.Element {
    if (xValue !== "entitlement") {
      return <div />
    }
    return <FormEntitlement setValid={setValidEntitlement} />
  }

  function ViewTimeSlots(): JSX.Element {
    if (xValue !== "timeSlots") {
      return <div />
    }
    return <FormTimeSlot />
  }

  function ViewEffectiveDate(): JSX.Element {
    if (xValue !== "effectiveDate") {
      return <div />
    }
    return <FormEffectiveDate setValid={setValidEffectiveDate} />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box display="flex">
          <Box>
            <PageHeaderTitle
              title={hasData ? "Edit Category" : "Add Category"}
              breadcrumbs={
                hasData
                  ? "settings / facilities / edit category"
                  : "settings / facilities / add category"
              }
              backAction={() => showTable()}
            />
          </Box>
        </Box>
        <Box>
          <TabsButtons options={OptionTabs} activeTab={steps(xValue)} />
        </Box>
        <Box>
          <Card>
            {ViewDetails()}
            {ViewEntitlement()}
            {ViewTimeSlots()}
            {ViewEffectiveDate()}
            <Box margin="0 0 30px">
              <Footer
                handleNext={() => handleNext()}
                handleBack={xValue === "details" ? null : () => send("BACK")}
                handleCancel={() => setOpenDialog && setOpenDialog(true)}
                label={submitting ? "Submitting..." : "Next"}
                disabled={submitting}
              />
            </Box>
          </Card>
          <Dialog
            action={() => showTable()}
            isOpen={openDialog}
            setOpen={setOpenDialog}
            actionLabel="OK"
            title=""
            message="Are you sure you want to cancel?"
          />
        </Box>
      </WithHeader>
    )
  }

  return <>{MainView()}</>
}

export default withContext(SettingsFacilitiesView)
