import * as React from "react"

const Cash: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C12.239 0 10 2.239 10 5C10 7.761 12.239 10 15 10C17.761 10 20 7.761 20 5C20 2.239 17.761 0 15 0ZM15 2C16.657 2 18 3.343 18 5C18 6.657 16.657 8 15 8C13.343 8 12 6.657 12 5C12 3.343 13.343 2 15 2ZM15.4082 3L13.5098 3.77734V4.81445L14.3027 4.50781V7H15.6621V3H15.4082ZM1 8C0.448 8 0 8.448 0 9V19C0 19.552 0.448 20 1 20H19C19.552 20 20 19.552 20 19V9.89062C19.426 10.4766 18.749 10.9575 18 11.3145V17.0879C17.7897 17.1625 17.5987 17.2831 17.4409 17.4409C17.2831 17.5987 17.1625 17.7897 17.0879 18H2.91211C2.83749 17.7897 2.7169 17.5987 2.55911 17.4409C2.40132 17.2831 2.21031 17.1625 2 17.0879V10.9121C2.21031 10.8375 2.40132 10.7169 2.55911 10.5591C2.7169 10.4013 2.83749 10.2103 2.91211 10H10.1094C9.52238 9.426 9.04255 8.749 8.68555 8H1ZM10 11C9.20435 11 8.44129 11.3161 7.87868 11.8787C7.31607 12.4413 7 13.2044 7 14C7 14.7956 7.31607 15.5587 7.87868 16.1213C8.44129 16.6839 9.20435 17 10 17C10.7956 17 11.5587 16.6839 12.1213 16.1213C12.6839 15.5587 13 14.7956 13 14C13 13.2044 12.6839 12.4413 12.1213 11.8787C11.5587 11.3161 10.7956 11 10 11ZM5 13C4.448 13 4 13.447 4 14C4 14.553 4.448 15 5 15C5.552 15 6 14.553 6 14C6 13.447 5.552 13 5 13ZM15 13C14.448 13 14 13.447 14 14C14 14.553 14.448 15 15 15C15.552 15 16 14.553 16 14C16 13.447 15.552 13 15 13Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Cash
