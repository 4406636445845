import { useState, useEffect } from "react"
import useSWR from "swr"
import { toUnix, formatDate, checkSameMonth } from "utils/date"
/**
 * Service
 */
import serviceSettings from "services/Settings/PropertyManagement/Facilities"
import service from "services/Dashboard/Reports"

export function TotalFacilitiesHook(date: any) {
  const isSameMonth = checkSameMonth(date?.from)
  const endTimeToday = toUnix(`${formatDate(new Date())} 11:59`)

  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = isSameMonth ? endTimeToday : toUnix(`${date?.to} 11:59`)

  const [stats, setStats] = useState<Array<{}>>([])

  const fetchCategories = async () => serviceSettings.getCategories()

  const { data } = useSWR(`fetchCategories-${unixFrom}-${unixTo}`, fetchCategories, {
    revalidateOnFocus: false
  })

  async function fetchStats(categoryObj: Record<string, any>): Promise<Response> {
    const categoryUID = categoryObj?._uid

    try {
      const response = await service.getFacilitiesBookings({
        "facility_category_uid": categoryUID,
        "start_timestamp": unixFrom,
        "end_timestamp": unixTo
      })
      const responseData = response?.data?._data
      return responseData
    } catch (e) {
      return e
    }
  }

  useEffect(() => {
    setStats([])
    const sortedData = data?.data?._data?.sort(
      (a: Record<string, any>, b: Record<string, any>) =>
        a.category_name.localeCompare(b.category_name)
    )

    data !== undefined &&
      sortedData &&
      sortedData.map(async (sData: any) =>
        fetchStats(sData).then((categoryData: Record<string, any>) =>
          setStats((statsArray) => [
            ...statsArray,
            {
              categoryData,
              category: sData
            }
          ])
        )
      )
  }, [data])

  return {
    isValidating: false,
    stats
  }
}
