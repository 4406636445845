import React from "react"

import CancelPaymentProvider, {
  CancelPaymentProps
} from "context/Dashboard/Bookkeeping/forms/cancelPayment/Provider"
import CancelPaymentView from "context/Dashboard/Bookkeeping/forms/cancelPayment/View"

const CancelPaymentSchedule: React.FC<CancelPaymentProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <CancelPaymentProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <CancelPaymentView />
  </CancelPaymentProvider>
)

export default CancelPaymentSchedule
