import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme: Theme) => ({
  titleStyle: {
    color: theme.palette.secondary.dark,
    fontSize: "20px",
    fontWeight: 500,
    alignItems: "center"
  },
  subtitleStyle: {
    color: theme.palette.body.dark,
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 300,
    padding: "3px"
  }
}))

export type CardTitleProps = {
  title: string
  subtitle?: string
  icon?: any
}

export const CardTitle: React.FC<CardTitleProps> = ({ subtitle, title, icon }) => {
  const { titleStyle, subtitleStyle } = useStyles()
  return (
    <Box className={titleStyle} display="flex">
      {icon && (
        <Box marginRight="20px" display="flex" alignItems="center">
          {icon}
        </Box>
      )}
      {" "}
      {subtitle ? (
        <Box display="flex" flexDirection="column">
          <Box>{title}</Box>
          <Box className={subtitleStyle}>{subtitle}</Box>
        </Box>
      ) : (
        <Box>{title}</Box>
      )}
    </Box>
  )
}

export default CardTitle
