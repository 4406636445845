import React from "react"

// interface
interface SpacerProps {
  width?: string
  height?: string
  isDefault?: boolean
}

const Spacer: React.FC<SpacerProps> = ({
  width = "100%",
  height = "20px",
  isDefault = false
}) => {
  return (
    <>
      {isDefault ? (
        <div style={{ width: "20px", height: "20px" }} />
      ) : (
        <div style={{ width, height }} />
      )}
    </>
  )
}

export default Spacer
