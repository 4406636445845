import * as React from "react"

const ReportsVisitors = (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.044 0C10.1428 0 8.60165 1.567 8.60165 3.5C8.60165 5.433 10.1428 7 12.044 7C13.9451 7 15.4863 5.433 15.4863 3.5C15.4863 1.567 13.9451 0 12.044 0ZM12.044 2C12.8573 2 13.5192 2.673 13.5192 3.5C13.5192 4.327 12.8573 5 12.044 5C11.2306 5 10.5687 4.327 10.5687 3.5C10.5687 2.673 11.2306 2 12.044 2ZM4.66758 3C3.30935 3 2.20879 4.119 2.20879 5.5C2.20879 6.881 3.30935 8 4.66758 8C6.02582 8 7.12638 6.881 7.12638 5.5C7.12638 4.119 6.02582 3 4.66758 3ZM19.4203 3C18.0621 3 16.9615 4.119 16.9615 5.5C16.9615 6.881 18.0621 8 19.4203 8C20.7786 8 21.8791 6.881 21.8791 5.5C21.8791 4.119 20.7786 3 19.4203 3ZM4.66758 4.72266C5.08951 4.72266 5.43211 5.071 5.43211 5.5C5.43211 5.929 5.08951 6.27734 4.66758 6.27734C4.24566 6.27734 3.90305 5.929 3.90305 5.5C3.90305 5.071 4.24566 4.72266 4.66758 4.72266ZM19.4203 4.72266C19.8423 4.72266 20.1849 5.071 20.1849 5.5C20.1849 5.929 19.8423 6.27734 19.4203 6.27734C18.9984 6.27734 18.6558 5.929 18.6558 5.5C18.6558 5.071 18.9984 4.72266 19.4203 4.72266ZM12.044 9C10.5935 9 8.23365 9.46827 6.6884 10.3984C6.05782 10.1553 5.36711 10 4.66758 10C2.46746 10 0.24176 11.3976 0.24176 12.9316V16H4.66758H5.15934H6.14286H18.9286H19.4203H23.8462V12.9316C23.8462 11.3976 21.6205 10 19.4203 10C18.7208 10 18.0301 10.1553 17.3995 10.3984C15.8543 9.46827 13.4944 9 12.044 9ZM12.044 11C14.1428 11 16.5898 11.9614 16.9615 12.5664V14H7.12638V12.5664C7.49814 11.9614 9.94513 11 12.044 11ZM4.66758 12C4.86227 12 5.03807 12.034 5.21697 12.0625C5.18043 12.2034 5.15934 12.3494 5.15934 12.5V14H4.66758H2.20879V13.0098C2.38976 12.6898 3.38705 12 4.66758 12ZM19.4203 12C20.7009 12 21.6982 12.6898 21.8791 13.0098V14H19.4203H18.9286V12.5C18.9286 12.3494 18.9075 12.2034 18.8709 12.0625C19.0498 12.034 19.2256 12 19.4203 12Z"
      fill="#454B57"
    />
  </svg>
)

export default ReportsVisitors
