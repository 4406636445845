import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { toast } from "react-toastify"

/** Service */
import service from "services/Settings/AccountManagement/Resident"

/** Categories */
import { CtxType } from "context/Settings/AccountManagement/OwnersTenants/forms/accountSettings/Context"
import withContext from "context/Settings/AccountManagement/OwnersTenants/forms/accountSettings/withContext"
import Feeds from "./components/Feeds"
import VMSDeliveries from "./components/VMSDeliveries"
import Units from "./components/Units"
import Facilities from "./components/Facilities"
import Applications from "./components/Applications"
import Payment from "./components/Payment"
import Feedback from "./components/Feedback"
import Profile from "./components/Profile"
import PublicDocuments from "./components/PublicDocuments"

/** Styles */
import styles from "./styles"

const AccountPermission: React.FC<CtxType> = ({
  payloads,
  setPayloads,
  activeRole
}) => {
  const { sectionHead, sectionItems } = styles()

  /** Notification */
  const notifyUpdate = () =>
    toast("Successfully updated the permission.", {
      type: toast.TYPE.SUCCESS
    })

  function handleUpdate(key: string, value: boolean) {
    setPayloads &&
      setPayloads({
        ...payloads,
        [key]: value
      })
  }

  async function updatePermissions() {
    try {
      const response = await service.updatePermission(payloads, activeRole)
      notifyUpdate()
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Box>
      <Box display="flex" className={sectionHead}>
        <Box flex="3" />
        <Box flex="1">Edit</Box>
        <Box flex="1">View</Box>
      </Box>

      <Box className={sectionItems}>
        <Box>
          <Feeds data={payloads} handleUpdate={handleUpdate} />
          <VMSDeliveries data={payloads} handleUpdate={handleUpdate} />
          <Units data={payloads} handleUpdate={handleUpdate} />
          <Facilities data={payloads} handleUpdate={handleUpdate} />
          <Applications data={payloads} handleUpdate={handleUpdate} />
          <Payment data={payloads} handleUpdate={handleUpdate} />
          <Feedback data={payloads} handleUpdate={handleUpdate} />
          <Profile data={payloads} handleUpdate={handleUpdate} />
          <PublicDocuments data={payloads} handleUpdate={handleUpdate} />
        </Box>
      </Box>

      <Box
        padding="30px 40px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <div />
        </Box>
        <Box width="150px">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => updatePermissions()}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default withContext(AccountPermission)
