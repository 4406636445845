import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  totalStyle: {
    color: theme.palette.body.greenLight100,
    fontSize: "48px",
    fontWeight: 500,
    "& span": {
      fontSize: "10px",
      paddingRight: "5px"
    }
  },
  body: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  viewAll: {
    marginTop: "30px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  }
}))

export default styles
