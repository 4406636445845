import { SET_USER_DATA, SET_USER_PHOTO } from "../constants"

const initialState = {
  userData: {}
}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        userData: action.userData
      }
    default:
      return state
  }
}
