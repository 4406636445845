import React, { useContext, useState, useEffect } from "react"
import useSWR, { mutate } from "swr"

import Toast from "components/Toast"

/** Context */
import service from "services/Settings/PropertyManagement/Facilities"
import { Context as MainContext } from "context/Settings/PropertyManagement/Facilities/main/Context"
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [facilitiesList, setFacilitiesList] = useState([])

  const { activeFacility } = useContext(MainContext)
  const categoryUID = activeFacility?.facility_category_uid

  const fetchCategory = async () =>
    service.getCategory({
      "facility_category_uid": categoryUID
    })

  const { data, isValidating } = useSWR(`fetchCategory`, fetchCategory, {
    revalidateOnFocus: true
  })

  async function removeFacility(uid: string) {
    try {
      const response = await service.removeFacility(uid)
      Toast(`Successfully removed the facility.`, true)
      mutate(`fetchCategory`)
      return response
    } catch (e) {
      return e
    }
  }

  useEffect(() => {
    setFacilitiesList(data === undefined ? [] : data?.data?._data)
  }, [data])

  return (
    <Context.Provider
      value={{
        facilitiesList: facilitiesList === null ? [] : facilitiesList,
        isValidating,
        removeFacility
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
