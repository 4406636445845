import React, { useState } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import {
  IconDeposit,
  IconPayment,
  IconRefund
} from "components/Icons/Settings/PropertyManagement/Bookkeeping/PaymentTypes"
import withContext from "context/Settings/PropertyManagement/Bookkeeping/main/withContext"
import { CtxType } from "context/Settings/PropertyManagement/Bookkeeping/main/Context"
import ListItem from "../Common/ListItem"
import Form from "../Common/Forms/PaymentType"
import FormTitle from "../Common/FormTitle"

/** Context */

import styles from "./styles"

const Icons = [<IconPayment />, <IconDeposit />, <IconRefund />]

const View: React.FC<CtxType> = ({ paymentTypes }) => {
  const { container, navigation } = styles()
  const [activeView, setActiveView] = useState(0)

  const MainView = (): JSX.Element => {
    return (
      <Box className={container}>
        <Box flex="1" className={navigation}>
          {paymentTypes !== null &&
            Object.keys(paymentTypes).map((i: any, index: number) => {
              return (
                <ListItem
                  name={paymentTypes[i]?.type_name}
                  icon={Icons[index]}
                  onClick={() => setActiveView(index)}
                />
              )
            })}
        </Box>
        <Box flex="1">
          <Box padding="60px 60px 40px 60px">
            <Box paddingBottom="40px">
              <FormTitle
                title={paymentTypes[activeView]?.type_name}
                icon={Icons[activeView]}
              />
            </Box>
            <Box>
              <Form activeView={activeView} />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return <>{MainView()}</>
}

export default withContext(View)
