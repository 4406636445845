import React, { useContext, useState } from "react"

import Fade from "@material-ui/core/Fade"

/** Components */
import Loader from "components/Loader"
import Table from "components/Table"
import { Context } from "context/Settings/AccountManagement/Staff/main/Context"
import tableHeaders from "./table/tableHeaders"

/** Context */

import StaffSettingsTableHook from "./hooks"

const TableSettingStaff: React.FC = () => {
  const { showResetForm, setUserData } = useContext(Context)
  const { isValidating, tableData } = StaffSettingsTableHook()
  const [currentPage, setCurrentPage] = useState(0)

  function handleResetPassword(data: any) {
    showResetForm && showResetForm(true)
    setUserData && setUserData(data)
  }

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData || []}
          columns={tableHeaders(handleResetPassword)}
          minRows={2}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
        />
      </div>
    </Fade>
  )
}

export default TableSettingStaff
