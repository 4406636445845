import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%"
  },
  titleStyle: {
    fontSize: "16px",
    paddingBottom: "20px",
    color: "#646464"
  },
  container: {
    padding: "40px 0",
    maxHeight: "439px",
    height: "100%",
    border: "3px dashed #F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&.error": {
      border: "3px dashed #FF0000"
    },
    "& .uploadButton": {
      color: theme.palette.primary.main,
      border: "2px solid #09707B",
      width: "247px",
      height: "40px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 10px"
    },
    "& .info": {
      fontSize: "12px",
      letterSpacing: "0.004em",
      color: "#777E86"
    }
  },
  uploadBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    cursor: "pointer"
  },
  uploadContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  uploadNote: {
    fontSize: "12px",
    padding: "10px 0",
    color: theme.palette.body.gray
  }
}))

export default styles
