import React from "react"
import Button from "@material-ui/core/Button"

/** Components */
import ContextMenu from "components/ContextMenu"
import FileLink from "components/Table/components/FileLink"
/** Utils */
import { fromUnix } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
/**
 * Config
 */
import { FrequencyType } from "config/Dashboard/EstateMatters/ConstructionManuals"
import ContextItems from "./ContextMenu"

const tableHeaders = (viewFile: Function, showUpdateForm: Function) => {
  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any): JSX.Element => {
            return (
              <ContextMenu>
                <ContextItems data={row?.original} showUpdateForm={showUpdateForm} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: "",
      columns: [
        {
          Header: "S/No.",
          id: "serialNumber",
          accessor: (c: any) => checkValue(c?.serial_no),
          Cell: ({ original }: any) => checkValue(original?.serial_no)
        },
        {
          Header: "Title",
          id: "title",
          accessor: (c: any) => checkValue(c?.title),
          Cell: ({ original }: any) => checkValue(original?.title)
        },
        {
          Header: "Description",
          id: "description",
          accessor: (c: any) => checkValue(c?.description),
          Cell: ({ original }: any) => checkValue(original?.description)
        },
        {
          Header: "Start Date",
          id: "startDate",
          accessor: (c: any) => checkDate(c?.start_date, fromUnix(c?.start_date)),
          Cell: ({ original }: any) =>
            checkDate(original?.start_date, fromUnix(original?.start_date))
        },
        {
          Header: "End Date",
          id: "endDate",
          accessor: (c: any) => checkDate(c?.end_date, fromUnix(c?.end_date)),
          Cell: ({ original }: any) =>
            checkDate(original?.end_date, fromUnix(original?.end_date))
        },
        {
          Header: "Remarks",
          id: "remarks",
          accessor: (c: any) => checkValue(c?.remarks),
          Cell: ({ original }: any) => checkValue(original?.remarks)
        },
        {
          Header: "Quantity",
          id: "quantity",
          accessor: (c: any) => checkValue(String(c?.quantity)),
          Cell: ({ original }: any) => checkValue(original?.quantity)
        },
        {
          Header: "Frequency",
          id: "frequency",
          accessor: (c: any) =>
            checkValue(c?.frequency, FrequencyType[c?.frequency]),
          Cell: ({ original }: any) =>
            checkValue(original?.frequency, FrequencyType[original?.frequency])
        },
        {
          Header: "Attachment",
          id: "attachment",
          accessor: (c: any) => checkValue(c?.file?.file_name),
          Cell: ({ original }: any) =>
            checkValue(
              original?.file?.url,
              <FileLink url={original?.file?.url} name={original?.file?.file_name} />
            )
        }
      ]
    },
    {
      fixed: "right",
      disableFilters: true,
      columns: [
        {
          Cell: ({ original }: any) =>
            checkValue(
              original?.file?.url,
              <Button color="primary" onClick={() => viewFile(original)}>
                View File
              </Button>
            ),
          filterable: false
        }
      ]
    }
  ]
}

export default tableHeaders
