import * as Yup from "yup"

export const addStaff: Record<any, any> = Yup.object().shape({
  staffName: Yup.string().required("Required"),
  staffEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  staffMobile: Yup.string().required("Required"),
  staffPassword: Yup.string().required("Required"),
  role: Yup.string().required("Required")
})

export const updateStaff: Record<any, any> = Yup.object().shape({
  staffName: Yup.string().required("Required"),
  staffEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  staffMobile: Yup.string().required("Required"),
  role: Yup.string().required("Required")
})

export const addRole: Record<any, any> = Yup.object().shape({
  roleName: Yup.string().required("Required")
})

export const staffResetPassword: Record<any, any> = Yup.object().shape({
  staffPassword: Yup.string().required("Required")
})

const validation = {
  addStaff,
  updateStaff,
  addRole,
  staffResetPassword
}

export default validation
