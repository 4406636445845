import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** components */
import WithTable from "layout/WithTable"
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import NewAccountRequests from "pages/Dashboard/UnitManagement/tables/AccountsRequests/NewAccountRequests"
import RejectedRequests from "pages/Dashboard/UnitManagement/tables/AccountsRequests/RejectedRequests"
import ChangeRequests from "pages/Dashboard/UnitManagement/tables/AccountsRequests/ChangeRequests"
import CreateOwnerForm from "pages/Dashboard/UnitManagement/components/Common/UserForm"
import AutoCreateOwnerForm from "pages/Dashboard/UnitManagement/components/Common/UserFormAuto"
import RequestDetails from "pages/Dashboard/UnitManagement/components/AccountRequests/Details"

/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"

/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/main/Context"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import withMainContext from "context/Dashboard/UnitManagement/main/withContext"
import { filterAccountRequests } from "config/Dashboard/UnitManagement"
import { AppContext } from "App"
import { Context as SelfContext } from "./Context"

/** Config */

const AccountRequestView: React.FC<CtxType> = ({ activeTableFilter }) => {
  const { accountRequestsTab, setAccountRequestsTab } = activeTableFilter
  const {
    activeView,
    showCreateOwner,
    showCreateFromData,
    downloadData
  } = useContext(ParentContext)
  const { accountRequestData, setShowSideDetails, showSideDetails } = useContext(
    SelfContext
  )
  const { permission } = useContext(AppContext)

  const csvFilename = `Account-Requests-${filterAccountRequests[accountRequestsTab]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <NewAccountRequests />
      case 1:
        return <ChangeRequests />
      case 2:
        return <RejectedRequests />
      default:
        break
    }
    return activeTableParam
  }

  const ViewDetails = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }
    return (
      <RequestDetails
        data={accountRequestData}
        openState={showSideDetails || false}
        setopenState={(value: boolean) =>
          setShowSideDetails && setShowSideDetails(value)}
        action={showCreateFromData}
      />
    )
  }

  const ViewCreateOwner = (): JSX.Element => {
    if (activeView !== "createOwner") {
      return <div />
    }
    return <CreateOwnerForm />
  }

  const ViewCreateFromData = (): JSX.Element => {
    if (activeView !== "createFromData") {
      return <div />
    }
    return <AutoCreateOwnerForm data={accountRequestData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView === "tableView") {
      return (
        <Box>
          <TableHeader
            addActionLabel="Add New User"
            title="Account Request"
            titleIcon={MenuIcons.accountsrequests}
            addAction={() => showCreateOwner && showCreateOwner()}
            actionDisable={permission?.unit_mgt_create}
            data={downloadData}
            fileName={csvFilename}
          />
          <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
            <TabsFilter
              value={accountRequestsTab}
              handleChange={setAccountRequestsTab}
              options={filterAccountRequests}
            />
          </Box>
          <Box>{TableView(accountRequestsTab)}</Box>
        </Box>
      )
    }
    return <div />
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewDetails()}
      {ViewCreateOwner()}
      {ViewCreateFromData()}
    </>
  )
}

export default withMainContext(AccountRequestView)
