import React from "react"

export type CtxType = {
  logDetails: any
  activeData: Record<string, any>
  activeDate: string | null
  CSVFileName: string
  showStaff: boolean
  isSubmitting: boolean
  isValidating: boolean
  timeinOpen: boolean
  timeoutOpen: boolean
  isEdit: boolean
  activeTable: number
  activeStatus: number
  currentAction: number
  downloadData: Array<{}>
  withTimeout: Array<{}>
  setactiveTable: Function
  setActiveStatus: Function
  showDrawer: Function
  setActiveDate: Function
  setActiveData: Function
  handleTimeinStaff: Function
  handleTimeoutStaff: Function
  setLogDetails: Function
  resetForm: Function
  setCurrentAction: Function
  setDownloadData: Function
  handleDeleteTimein: Function
  handleDeleteTimeout: Function
  showTimein: Function
  showTimeout: Function
  showDetails: Function
  setIsEdit: Function
  timeinData: any
  timeoutData: any
}

export const Context = React.createContext<Partial<CtxType>>({})
