import React, { useState, useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
/** Components */
import Dialog from "components/Dialog"
/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import withContext from "context/Dashboard/Attendance/main/withContext"
import { CtxType } from "context/Dashboard/Attendance/main/Context"
import { AppContext } from "App"
/** Styles */
import { IconView, IconEdit, IconDelete } from "components/Icons/ContextMenu"
import styles from "./styles"
/** Config */

interface ContextAttendance extends CtxType {
  data: Record<string, any>
  setIsEdit: Function
  showDrawer: Function
  handleDeleteTimeout: Function
  setCurrentAction: Function
  showTimeout: Function
  showDetails: Function
}

const ContextAttendance: React.FC<ContextAttendance> = ({
  data,
  handleDeleteTimeout,
  showTimeout,
  showDetails,
  setIsEdit
}) => {
  const { listItem } = styles()
  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.attendance_edit
  const canDelete = permission?.attendance_remove

  function handleShowDetails(items: Record<string, any>) {
    showDetails && showDetails(true, items)
    openContext && openContext(false)
  }

  function handleShowUpdate(items: Record<string, any>) {
    setIsEdit && setIsEdit(true)
    showTimeout && showTimeout(true, items)
    openContext && openContext(false)
  }

  function handleDelete(logUID: string) {
    handleDeleteTimeout && handleDeleteTimeout(logUID)
    openContext && openContext(false)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={() => handleShowDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={() => (!canEdit ? null : handleShowUpdate(data))}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canDelete}
          onClick={() => (!canDelete ? null : setOpenDialog(true))}
        >
          <i>{IconDelete}</i>
          Delete
        </ListItem>
      </List>
      <Dialog
        action={() => handleDelete(data?.time_out?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to delete this time log?"
      />
    </>
  )
}
export default withContext(ContextAttendance)
