import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
/**
 * Icon
 */
import { AuthContext } from "context/auth-context"
import BrandIcon from "./BrandIcon"
import ContentIcon from "./ContentIcon"
/**
 * Context
 */
/**
 * Styles
 */
import styles from "./styles"

function PageNotFound(): JSX.Element {
  const classes = styles()
  const { isAuth } = useContext(AuthContext)

  const link = isAuth ? "/property-management/dashboards" : "/"
  const label = isAuth ? "GO TO DASHBOARD" : "GO TO LOGIN"

  return (
    <Box>
      <Box className={classes.header}>
        <Box>
          <BrandIcon />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box>
          <Box className={classes.contentIcon}>
            <ContentIcon />
          </Box>
          <Box className={classes.title}>Page Not Found</Box>
          <Box className={classes.subtitle}>
            We’re sorry, the page you requested cannot be found.
          </Box>
          <Box className={classes.cta}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              to={link}
              fullWidth
            >
              {label}
            </Button>
          </Box>
          <Box className={classes.subtitle}>Copyright © CORA 2020</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PageNotFound
