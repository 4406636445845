import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [activeData, setActiveData] = useState()
  const [showForm, setShowForm] = useState()

  return (
    <Context.Provider
      value={{
        activeData,
        showForm,
        setActiveData,
        setShowForm
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
