import * as Yup from "yup"

const validation: Record<any, {}> = {
  documentInfoValidation: Yup.object().shape({
    serialNo: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    frequency: Yup.lazy((value: any) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return Yup.number().required()
      }
      return Yup.number().notRequired()
    }),
    quantity: Yup.string().when("frequency", {
      is: (val: any) => val === 0,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required("Required")
    })
  })
}

export const { documentInfoValidation } = validation
