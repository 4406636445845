import { Machine } from "xstate"

export const PublicDocumentsMachine = Machine({
  id: "publicDocumentsMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_DOCS: "addDocs",
        UPDATE_DOCS: "updateDocs",
        VIEW_FILE: "viewFile"
      }
    },
    addDocs: {
      on: {
        BACK: "tableView"
      }
    },
    updateDocs: {
      on: {
        BACK: "tableView"
      }
    },
    viewFile: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_DOCS: "addDocs",
    UPDATE_DOCS: "updateDocs",
    VIEW_FILE: "viewFile"
  }
})

export const ManagementDocumentsMachine = Machine({
  id: "managementDocumentsMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_DOCS: "addDocs",
        UPDATE_DOCS: "updateDocs",
        VIEW_FILE: "viewFile"
      }
    },
    addDocs: {
      on: {
        BACK: "tableView"
      }
    },
    updateDocs: {
      on: {
        BACK: "tableView"
      }
    },
    viewFile: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_DOCS: "addDocs",
    UPDATE_DOCS: "updateDocs",
    VIEW_FILE: "viewFile"
  }
})
