import React, { useState } from "react"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"

import OnboardAPI from "services/Onboard"
import service from "services/Dashboard/Common"
import appConfig from "config/appConfig"

function AppInit() {
  const { userData } = useSelector((state: any) => state.user)
  const hasUserData = Object.keys(userData).length !== 0
  const [permission, setPermission] = useState({})
  const [condoInfo, setCondoInfo] = useState({})
  const [fetchingPermission, setFetchingPermission] = useState(false)
  const [fetchingCondo, setFetchingCondo] = useState(false)

  async function getUserRolePermission(roleUID: string) {
    setFetchingPermission(true)
    try {
      const response = await OnboardAPI.getUserPermission(roleUID)
      setPermission(response?.data?._data?.permission)
      setFetchingPermission(false)
      return response
    } catch (e) {
      setFetchingPermission(false)
      return e
    }
  }

  async function getCondoInfo() {
    setFetchingCondo(true)
    try {
      const response = await service.getCondoInfo(Cookies.get("condoUID"))
      setCondoInfo(response?.data?._data)
      setFetchingCondo(false)
      return response
    } catch (e) {
      setFetchingCondo(false)
      return e
    }
  }

  React.useEffect(() => {
    if (hasUserData && userData?.condos !== null) {
      getUserRolePermission(userData?.condos[0]?.user_role?._uid)
    } else {
      setPermission(appConfig.SUPER_ADMIN)
    }

    getCondoInfo()
  }, [userData])

  return {
    permission,
    condoInfo,
    fetchingPermission,
    fetchingCondo,
    userData,
    getUserPermission: () =>
      getUserRolePermission(userData?.condos[0]?.user_role?._uid)
  }
}

export default AppInit
