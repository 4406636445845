import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    padding: "30px 50px",
    "& .two-col": {
      display: "flex"
    },
    "& .two-col > div:first-child": {
      marginRight: "80px"
    },
    "& .actions": {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    }
  },
  containerAdmin: {
    backgroundColor: "#FAFAFA"
  },
  value: {
    fontSize: "14px",
    color: theme.palette.secondary.dark
  },
  field: {
    marginBottom: "20px"
  },
  photoList: {
    display: "flex",
    justifyContent: "space-between"
  },
  photoItem: {
    width: "194px",
    height: "194px"
  }
}))

export default styles
