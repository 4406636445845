import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import { Formik } from "formik"
/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import Loader from "components/Loader"

/** Validation */
import { addRole } from "config/Settings/AccountManagement/Admin/validation"

/** Context */
import withContext from "context/Settings/AccountManagement/Admin/forms/accountSettings/withContext"
import { Context } from "context/Settings/AccountManagement/Admin/forms/accountSettings/Context"
import styles from "./styles"

const AdminForm: React.FC = () => {
  const { container, section, footer, wrapper } = styles()
  const {
    formDetails,
    setFormDetails,
    handleFormSubmit,
    showFormType,
    submitting
  } = useContext(Context)
  const fd = formDetails

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setFormDetails && setFormDetails((data: any) => ({ ...data, [name]: e }))
    } else {
      setFieldValue(name, e?.target?.value)
      setFormDetails &&
        setFormDetails((data: any) => ({ ...data, [name]: e?.target?.value }))
    }
  }

  const handleSubmitForm = (handleSubmit: Function, isFormValid: boolean): void => {
    handleSubmit()

    if (isFormValid) {
      handleFormSubmit && handleFormSubmit()
    } else {
      handleSubmit()
    }
  }

  return (
    <Formik
      initialValues={{
        roleName: fd?.roleName
      }}
      onSubmit={(values, actions): void => {
        JSON.stringify(values, null, 2)
        actions.setSubmitting(false)
      }}
      validationSchema={addRole}
    >
      {({
        touched,
        errors,
        isValid,
        handleBlur,
        handleSubmit,
        setFieldValue
      }): JSX.Element => {
        return (
          <form style={{ height: "100%" }}>
            <Box className={wrapper}>
              <Box flex="3">
                <FormWrapper>
                  <Box className={container}>
                    <Box className={section}>
                      <Box>
                        <Box marginBottom="25px">
                          <FormInput
                            name="roleName"
                            value={fd?.roleName}
                            idValue="roleName"
                            label="User Role Name"
                            placeholder="User Role Name"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("roleName", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={touched.roleName && Boolean(errors.roleName)}
                            helperText={
                              errors.roleName && touched.roleName && errors.roleName
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box className={footer}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => showFormType && showFormType(false, 1)}
                    disabled={submitting}
                  >
                    CANCEL
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm(handleSubmit, isValid)}
                    disabled={submitting}
                  >
                    {submitting ? <Loader noLoadingText /> : "SUBMIT"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default withContext(AdminForm)
