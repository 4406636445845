import React from "react"

import Provider from "context/Settings/PropertyManagement/Facilities/categories/facilities/Provider"
import View from "context/Settings/PropertyManagement/Facilities/categories/facilities/View"

const Facilities: React.FC = () => {
  return (
    <Provider>
      <View />
    </Provider>
  )
}

export default Facilities
