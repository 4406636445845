import React from "react"
import ContextMenu from "components/ContextMenu"
import { checkValue } from "utils/helpers"
import ContextItems from "../../contextMenus/default"

const tableHeaders = (endpoint: string) => {
  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any): JSX.Element => {
            return (
              <ContextMenu>
                <ContextItems data={row?.original} endpoint={endpoint} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: "",
      columns: [
        {
          Header: "Block/Unit No",
          id: "unitNumber",
          accessor: (c: any) => checkValue(c?.unit[0]?.short_name),
          Cell: ({ original }: any) => checkValue(original?.unit[0]?.short_name),
          width: 150
        },
        {
          Header: "Name",
          id: "name",
          accessor: (c: any) => checkValue(c?.name),
          Cell: ({ original }: any) => checkValue(original?.name)
        },
        {
          Header: "Email",
          id: "email",
          accessor: (c: any) => checkValue(c?.email),
          Cell: ({ original }: any) => checkValue(original?.email)
        },
        {
          Header: "Mobile No.",
          id: "mobileNumber",
          accessor: (c: any) => checkValue(c?.mobile_no),
          Cell: ({ original }: any) => checkValue(original?.mobile_no)
        },
        {
          Header: "Registered Address",
          id: "registeredAddress",
          accessor: (c: any) => checkValue(c?.registered_address),
          Cell: ({ original }: any) => checkValue(original?.registered_address)
        },
        {
          Header: "Access Code",
          id: "accessCode",
          accessor: (c: any) => checkValue(c?.access_code),
          Cell: ({ original }: any) => checkValue(original?.access_code)
        }
      ]
    }
  ]
}

export default tableHeaders
