import React, { ReactInstance, useRef, useContext } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import ViewReports from "pages/Dashboard/Reports/view"
import DateFilter from "pages/Dashboard/Reports/components/CTA"
import PermissionMessage from "components/PermissionMessage"
import WithTable from "layout/WithTable"
// import DateFilter from "pages/Dashboard/Reports/components/DateFilter"

/** Context */
import WithHeader from "layout/WithHeader"
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

/* eslint-disable react/prefer-stateless-function, @typescript-eslint/explicit-function-return-type */
class EmptyComponent extends React.Component {
  render() {
    return null
  }
}

const View: React.FC<CtxType> = ({ activeDate, setActiveDate }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ele: ReactInstance = new EmptyComponent({})
  const refReport = useRef<any>(ele)

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const MainView = () => {
    if (!hasPermission) {
      return <div />
    }

    if (permission?.reports_view) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="20px"
          >
            <PageHeaderTitle
              title="Report"
              breadcrumbs="property management / Report"
            />
            <DateFilter
              ele={ele}
              refElement={refReport}
              setActiveDate={setActiveDate}
              activeDate={activeDate}
            />
          </Box>
          <ViewReports printRef={refReport} />
        </>
      )
    }

    return (
      <WithTable>
        <PermissionMessage />
      </WithTable>
    )
  }

  return (
    <>
      <div>
        <WithHeader>{MainView()}</WithHeader>
      </div>
    </>
  )
}

export default withContext(View)
