import React from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** components */
import PageHeaderTitle from "components/PageHeaderTitle"
import UnitDetails from "pages/Dashboard/UnitManagement/components/UnitDetails"
import CreateOwnerForm from "pages/Dashboard/UnitManagement/components/Common/UserForm"
import VehiclesDetails from "pages/Dashboard/UnitManagement/components/Vehicles/CreateVehicle"
import AccountDetails from "pages/Dashboard/UnitManagement/components/UnitDetails/components/RegisteredUsers/Details"
import VehicleDetails from "pages/Dashboard/UnitManagement/components/UnitDetails/components/RegisteredVehicles/Details"

/** Context */
import { Context as SelfContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { UnitDetailsMachine } from "machines/Dashboard/UnitManagement/unitDetails"
import withMainContext from "./withContext"
import { CtxType } from "./Context"

/** Machines */

const UnitDetailsView: React.FC<CtxType> = ({
  activeData,
  activeVehicle,
  setActiveData,
  setActiveVehicle,
  setShowDetails,
  showDetails
}) => {
  const { showMain } = React.useContext(SelfContext)
  const [current, send] = useMachine(UnitDetailsMachine)
  const xValue = current?.value

  const isVehicleDetails = activeData?.vehicle_type !== undefined

  const ViewDetails = (): JSX.Element => {
    return (
      <AccountDetails
        data={activeData || []}
        openState={showDetails && !isVehicleDetails}
        setopenState={() => setShowDetails(false)}
        action={() => null}
      />
    )
  }

  const ViewVehicleDetails = (): JSX.Element => {
    return (
      <VehicleDetails
        openState={showDetails && isVehicleDetails}
        setopenState={() => setShowDetails(false)}
      />
    )
  }

  const ViewEditOwner = (): JSX.Element => {
    if (xValue !== "udEditOwner") {
      return <div />
    }
    // return
    return (
      <Box>
        <PageHeaderTitle
          title="Edit Registered User"
          breadcrumbs="property management / unit management / unit details"
          backAction={() => send("UNIT_DETAILS_MAIN")}
        />
        <CreateOwnerForm
          data={activeData}
          successCallback={() => send("UNIT_DETAILS_MAIN")}
        />
      </Box>
    )
  }

  const ViewEditVehicle = (): JSX.Element => {
    if (xValue !== "udEditVehicle") {
      return <div />
    }
    // return
    return (
      <Box>
        <PageHeaderTitle
          title="Edit Registered Vehicle"
          breadcrumbs="property management / unit management / unit details"
          backAction={() => send("UNIT_DETAILS_MAIN")}
        />
        <VehiclesDetails
          data={activeVehicle}
          successCallback={() => send("UNIT_DETAILS_MAIN")}
        />
      </Box>
    )
  }

  const MainView = (): JSX.Element => {
    if (xValue !== "udMain") {
      return <div />
    }
    return (
      <Box>
        <PageHeaderTitle
          title="Unit Details"
          breadcrumbs="property management / unit management / unit details"
          backAction={() => showMain && showMain()}
        />
        <UnitDetails
          showEdit={(dataItems: Record<string, any>) => {
            send("EDIT_REGISTER_UNIT_MEMBER")
            setActiveData(dataItems)
          }}
          showEditVehicle={(dataItems: Record<string, any>) => {
            send("EDIT_REGISTER_VEHICLE_MEMBER")
            setActiveVehicle(dataItems)
          }}
        />
      </Box>
    )
  }

  return (
    <>
      {MainView()}
      {ViewEditOwner()}
      {ViewEditVehicle()}
      {ViewDetails()}
      {ViewVehicleDetails()}
    </>
  )
}

export default withMainContext(UnitDetailsView)
