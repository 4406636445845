import React from "react"
import ReactDOM from "react-dom"
import { HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { ThemeProvider } from "@material-ui/core/styles"
import { PersistGate } from "redux-persist/lib/integration/react"
import CssBaseline from "@material-ui/core/CssBaseline"

import { store, persistor } from "config/configureStore"
import * as serviceWorker from "./serviceWorker"

import App from "./App"

// theme
import theme from "./theme"

import "./assets/styles/base.scss"
import "react-toastify/dist/ReactToastify.css"

const rootElement = document.getElementById("root")

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </HashRouter>
      </PersistGate>
    </Provider>
  </>,
  rootElement
)

serviceWorker.unregister()
