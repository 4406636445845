import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Context */
import service from "services/Dashboard/EstateMatters"
import { Context } from "./Context"

/** Service */

const Provider: React.FC = ({ children }) => {
  const [docsFilters, setDocsFilter] = useState([])
  const [activeDoc, setActiveDoc] = useState(null)
  const [activeTable, setActiveTable] = useState(0)
  const [accountRequestsTab, setAccountRequestsTab] = useState(0)
  const [filterTabs, setFilterTabs] = useState([])
  const [activeData, setActiveData] = useState([])
  const [showDocument, setShowDocument] = useState(false)
  const [downloadData, setDownloadData] = useState([])

  /** Notification */
  const notifyDelete = () =>
    toast(`Successfully deleted the document.`, {
      type: toast.TYPE.SUCCESS
    })

  async function getFolders() {
    try {
      const response = await service.getEstateMattersFolder({
        "type": 1
      })
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  async function deleteDocument(uid: string, activeDocValue: string) {
    try {
      const response = await service.deleteEstateMatter(uid)
      notifyDelete()
      mutate(`fetchPublicDocuments-${activeDocValue}-${accountRequestsTab}`)
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  function selectTab(indexValue: number, item: any) {
    setActiveTable(indexValue)
    setActiveDoc(item)
  }

  useEffect(() => {
    getFolders().then((res) => {
      setFilterTabs(res)

      if (activeDoc === null) {
        selectTab(0, res[0])
      }
    })
  }, [])

  return (
    <Context.Provider
      value={{
        activeDoc,
        activeTable,
        accountRequestsTab,
        docsFilters,
        filterTabs,
        activeData,
        showDocument,
        downloadData,
        setShowDocument,
        setActiveData,
        setDocsFilter,
        setAccountRequestsTab,
        setActiveTable,
        setActiveDoc,
        selectTab,
        deleteDocument,
        setDownloadData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
