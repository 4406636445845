import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontSize: "12px",
    color: theme.palette.body.gray,
    paddingRight: "10px"
  },
  formControlClass: {
    minHeight: "40px",
    height: "40px",
    backgroundColor: theme.palette.common.white,
    "& > *": {
      backgroundColor: theme.palette.common.white,
      minHeight: "40px",
      height: "40px"
    },
    "& .MuiSelect-root": {
      border: "none"
    }
  }
}))

export default styles
