import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import TableHeader from "components/TableHeader"
import IconForm from "components/Icons/Documents/Forms"
import Table from "pages/Dashboard/EstateMatters/PublicDocuments/tables"
import Filters from "pages/Dashboard/EstateMatters/PublicDocuments/Filters"
import DocumentForm from "pages/Dashboard/EstateMatters/PublicDocuments/components/DocumentForm"
import PermissionMessage from "components/PermissionMessage"

import ViewFileDrawer from "pages/Dashboard/EstateMatters/PublicDocuments/components/ViewFile"

/** Config */
import { filterDocs } from "config/Dashboard/EstateMatters"

/** Machine */
import { PublicDocumentsMachine } from "machines/Dashboard/EstateMatters"

/** Context */
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

const PublicDocuments: React.FC<CtxType> = ({
  accountRequestsTab,
  setAccountRequestsTab,
  activeDoc,
  activeData,
  showDocument,
  setShowDocument,
  downloadData
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(PublicDocumentsMachine)
  const xValue = current.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const csvFileName = `PublicDocuments-${activeDoc?.title}-${filterDocs[accountRequestsTab]}.csv`

  const ViewTable = (): JSX.Element => {
    return <Table showUpdate={() => send("UPDATE_DOCS")} />
  }

  const ViewMain = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }
    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Public Documents"
            breadcrumbs="property management / Estate matters / public documents "
          />
        </Box>

        <Box display="flex" marginBottom="15px">
          <Filters />
        </Box>

        <Box>
          <WithTable>
            <TableHeader
              addActionLabel="Add New"
              title={activeDoc?.title}
              titleIcon={IconForm}
              addAction={() => send("ADD_DOCS")}
              actionDisable={permission?.estate_matter_public_documents_create}
              data={downloadData}
              fileName={csvFileName}
            />
            <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
              <TabsFilter
                value={accountRequestsTab}
                handleChange={setAccountRequestsTab}
                options={filterDocs}
              />
            </Box>
            <Box>{ViewTable()}</Box>
          </WithTable>
        </Box>
      </Box>
    )
  }

  const ViewForm = (): JSX.Element => {
    if (xValue !== "addDocs") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Add New Document"
            breadcrumbs="property management / estate matters / public documents / add new document"
            backAction={() => send("BACK")}
          />
        </Box>
        <DocumentForm sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const ViewUpdateForm = (): JSX.Element => {
    if (xValue !== "updateDocs") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Update Document"
            breadcrumbs="property management / estate matters / public documents / update document"
            backAction={() => send("BACK")}
          />
        </Box>
        <DocumentForm data={activeData} sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const ViewFile = (): JSX.Element => {
    return (
      <ViewFileDrawer
        openState={showDocument}
        setopenState={(value: boolean) => setShowDocument(value)}
        data={activeData}
      />
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.estate_matter_public_documents_view) {
    return (
      <>
        <WithHeader>
          {ViewMain()}
          {ViewForm()}
          {ViewUpdateForm()}
        </WithHeader>
        {ViewFile()}
      </>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(PublicDocuments)
