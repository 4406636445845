import React, { useState } from "react"
import { toast } from "react-toastify"

/** Service */
import service from "services/Dashboard/Announcements/Post"

/** Context */
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [activeTable, setActiveTable] = useState(0)
  const [activeFolder, setActiveFolder] = useState("")
  const [activeData, setActiveData] = useState({})
  const [showDocument, setShowDocument] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [downloadData, setDownloadData] = useState([])

  /** Notification */
  const notifyCreate = () =>
    toast(`Successfully deleted the Announcement Post.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleDeletePost = async (postId: string) => {
    try {
      const response = await service.deletePost(postId)
      notifyCreate()
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        activeTable,
        activeFolder,
        activeData,
        showDocument,
        showDetails,
        downloadData,
        setActiveData,
        setActiveTable,
        setActiveFolder,
        handleDeletePost,
        setShowDocument,
        setShowDetails,
        setDownloadData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
