import React from "react"

import ResidentsProvider from "context/Dashboard/UnitManagement/categories/residents/Provider"
import ResidentsView from "context/Dashboard/UnitManagement/categories/residents/View"

export default (): JSX.Element => (
  <ResidentsProvider>
    <ResidentsView />
  </ResidentsProvider>
)
