import React from "react"

import RejectPaymentProvider, {
  RejectPaymentProps
} from "context/Dashboard/Bookkeeping/forms/rejectPayment/Provider"
import RejectPaymentView from "context/Dashboard/Bookkeeping/forms/rejectPayment/View"

const CancelPaymentSchedule: React.FC<RejectPaymentProps> = ({
  showPayment,
  setShowPayment,
  data
}) => (
  <RejectPaymentProvider
    showPayment={showPayment}
    setShowPayment={setShowPayment}
    data={data}
  >
    <RejectPaymentView />
  </RejectPaymentProvider>
)

export default CancelPaymentSchedule
