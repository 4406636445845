import React, { lazy, Suspense } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Layout */
import WithHeader from "layout/WithHeader"

/** Component */
import Loader from "components/Loader"
import PageHeaderTitle from "components/PageHeaderTitle"
import Dialog from "components/Dialog"
import Stepper from "components/Stepper"
import Footer from "components/Forms/Footer/new"

/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

const PaymentSummary = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/CancelPayment/PaymentSummary")
)

export const refSubmit = React.createRef<HTMLDivElement>()

const CreateOwnerView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  setShowPayment,
  paymentDetails,
  handleCancelPayment,
  submitting
}) => {
  const pd = paymentDetails

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()
    if (pd.remarks !== "") {
      handleCancelPayment().then(() => setShowPayment(false))
    } else {
      refSubmit.current?.click()
    }
  }

  const ViewPaymentSummary = () => {
    return (
      <Suspense fallback={<Loader forContent />}>
        <PaymentSummary />
      </Suspense>
    )
  }

  return (
    <>
      <WithHeader>
        <PageHeaderTitle
          title="Cancel Payment"
          breadcrumbs="property management / bookkeeping / cancel payment"
          backAction={() => setShowPayment()}
        />
        <Card>
          <Stepper
            items={["Payment summary"]}
            activeStep={0}
            justifyStart
            showNumber={false}
          />
          <Box>
            <div>{ViewPaymentSummary()}</div>
            <Box margin="30px 0">
              <Footer
                handleNext={() => handleNext()}
                handleBack={null}
                handleCancel={() => setOpenDialog && setOpenDialog(true)}
                label={submitting ? "Submitting..." : "Cancel Payment"}
                disabled={submitting}
              />
            </Box>
          </Box>
          <Dialog
            action={() => setShowPayment()}
            isOpen={openDialog}
            setOpen={setOpenDialog}
            actionLabel="OK"
            title=""
            message="Are you sure you want to cancel?"
          />
        </Card>
      </WithHeader>
    </>
  )
}

export default withContext(CreateOwnerView)
