import React from "react"

/**
 * Styles
 */
import {
  IconCleaners,
  IconSecurity,
  IconDefects,
  IconLandscape,
  IconOthers
} from "components/Icons/Dashboard"
import styles from "./styles"

interface TotalFeedbackReceivedProps {
  total: string
  currentReport: string
  totalData: Record<string, number>
}

const TotalFeedbackReceived: React.FC<TotalFeedbackReceivedProps> = ({
  total,
  currentReport,
  totalData
}) => {
  const classes = styles()
  const td = totalData

  const FeedbackMock = [
    {
      name: "Cleanliness",
      total: td?.cleanliness,
      icon: IconCleaners
    },
    {
      name: "Security",
      total: td?.security,
      icon: IconSecurity
    },
    {
      name: "Defects",
      total: td?.defects,
      icon: IconDefects
    },
    {
      name: "Landscape",
      total: td?.landscape,
      icon: IconLandscape
    },
    {
      name: "Others",
      total: td?.others,
      icon: IconOthers
    }
  ]

  return (
    <div>
      <div className={classes.tfrInfo}>
        <div className={classes.title}>Total Feedback Received</div>
        <div className={classes.subtitleStyle}>{currentReport}</div>
        <div className={classes.totalStyle}>{total}</div>
      </div>
      <div>
        {FeedbackMock.map((item) => (
          <div className={classes.feedbackList} key={item.name}>
            <div className={classes.body}>
              <div>{item.icon}</div>
              <div className="feedbackListName">{item.name}</div>
            </div>
            <div className={classes.body}>{item.total}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default TotalFeedbackReceived
