import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import BrandIcon from "./BrandIcon"
import ContentIcon from "./ContentIcon"

import styles from "./styles"

const ConnectionError: React.FC = () => {
  const classes = styles()
  const [checking, setChecking] = useState(false)

  function checkConnection() {
    setChecking(true)
    setTimeout(() => {
      setChecking(false)
    }, 10000)
  }

  return (
    <Box>
      <Box className={classes.header}>
        <Box>
          <BrandIcon />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box>
          <Box className={classes.contentIcon}>
            <ContentIcon />
          </Box>
          <Box className={classes.title}>You are currently offline</Box>
          <Box className={classes.subtitle}>
            Check your connection and try again.
          </Box>
          <Box className={classes.cta}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ width: "250px" }}
              onClick={() => checkConnection()}
              disabled={checking}
            >
              {checking ? "Checking Connection..." : "RETRY"}
            </Button>
          </Box>
          <Box className={classes.subtitle}>Copyright © CORA 2020</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ConnectionError
