import React, { FunctionComponent, useState, useContext } from "react"
import { toast } from "react-toastify"

/** Context */
import { Context as MainContext } from "context/Settings/AccountManagement/Staff/main/Context"
import service from "services/Settings/AccountManagement/Admin"
import { Context } from "./Context"

const Provider: FunctionComponent = ({ children }) => {
  const [userPassword, setUserPassword] = useState("")
  const [resetData, setResetData] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showResetForm, userData } = useContext(MainContext)

  /**
   * Notification
   */
  const notifyCreate = () =>
    toast(`Successfully changed the password.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Method */
  async function handleFormSubmit() {
    const payload = {
      "account_uid": userData?.account_uid,
      "pw": userPassword
    }
    setIsSubmitting(true)
    try {
      const response = await service.resetAdminPassword(payload)
      setIsSubmitting(false)
      notifyCreate()
      showResetForm && showResetForm(false)
      setUserPassword("")
      return response
    } catch (e) {
      setIsSubmitting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        handleFormSubmit,
        setResetData,
        setUserPassword,
        userPassword,
        resetData,
        isSubmitting
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
