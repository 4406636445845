import * as React from "react"

const AirconCleaning: React.FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75251 0V2.59375L7.48689 1.3125L6.16099 2.6875L8.75251 5.40625V8.3125L6.3418 6.90625L5.37751 3.1875L3.53934 3.6875L4.02149 5.40625L1.82171 4.09375L0.857422 5.90625L3.0572 7.1875L1.33956 7.59375L1.82171 9.59375L5.37751 8.59375L7.78823 10L5.37751 11.4062L1.82171 10.4062L1.33956 12.4062L3.0572 12.8125L0.857422 14.0938L1.82171 15.9062L4.02149 14.5938L3.53934 16.3125L5.37751 16.9062L6.3418 13.1875L8.75251 11.6875V14.5938L6.16099 17.3125L7.48689 18.6875L8.75251 17.4062V20H10.6811V17.4062L11.9467 18.6875L13.2726 17.3125L10.6811 14.5938V11.6875L13.0918 13.1875L14.0561 16.9062L15.8943 16.4062L15.4121 14.5938L17.6119 15.9062L18.5762 14.0938L16.3764 12.9062L18.094 12.4062L17.6119 10.4062L14.0561 11.4062L11.6454 10L14.0561 8.59375L17.6119 9.59375L18.094 7.59375L16.3764 7.1875L18.5762 5.90625L17.6119 4.09375L15.4121 5.40625L15.8943 3.6875L14.0561 3.1875L13.0918 6.8125L10.6811 8.3125V5.40625L13.2726 2.6875L11.9467 1.3125L10.6811 2.59375V0H8.75251Z"
        fill="white"
      />
    </svg>
  )
}

export default AirconCleaning
