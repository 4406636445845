import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const Application: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("application")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* View */}
        <Checkbox
          label={null}
          value={Boolean(data?.application_view)}
          onChange={() =>
            handleUpdate("application_view", Boolean(!data?.application_view))}
        />
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.application_create)}
          onChange={() =>
            handleUpdate("application_create", Boolean(!data?.application_create))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.application_edit)}
          onChange={() =>
            handleUpdate("application_edit", Boolean(!data?.application_edit))}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.application_remove)}
          onChange={() =>
            handleUpdate("application_remove", Boolean(!data?.application_remove))}
        />
      </Box>
    </Box>
  )
}
export default Application
