import React from "react"

export type CtxType = {
  setActiveTab: Function
  setActiveFacility: Function
  showTable: Function
  showAddFacility: Function
  showFacilities: Function
  showAddCategory: Function
  showUpdateFacility: Function
  removeSettings: Function
  activeFacility: Record<string, any>
  activeTab: number
  xStateValue: any
}

export const Context = React.createContext<Partial<CtxType>>({})
