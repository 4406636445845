export function statusDisplay(
  dateNow: Date | string | number,
  startDate: Date | number,
  endDate: Date | number,
  status: string
) {
  // check if expired
  if (dateNow <= endDate) {
    return status
  }
  return "Expired"
}
