import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.secondary.dark,
    fontSize: "20px"
  },
  content: {
    "& > div": {
      flex: 1,
      minWidth: "calc(100% / 3)"
    }
  }
}))

export default styles
