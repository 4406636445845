import React, { FunctionComponent, useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
import * as Yup from "yup"

/** Components */
import FormInput from "components/Forms/FormInput"
import UploadButton from "components/UploadButton"
import { IconSupport } from "components/Icons/Settings/Common"

/** Context */
import { Context } from "context/Settings/Profile/main/Context"

/** Styles */
import styles from "./styles"

const ProfileView: FunctionComponent = () => {
  const { avatar, contact } = styles()

  const {
    userDetails,
    setUserDetails,
    photos,
    handleImageUpload,
    previewImage,
    attachingPhoto,
    handleUpdateProfile,
    resetProfile
  } = useContext(Context)
  const ud = userDetails

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue(name, e?.target?.value)
    setUserDetails &&
      setUserDetails((data: any) => ({ ...data, [name]: e?.target?.value }))
  }

  const handleSubmitForm = () => {
    handleUpdateProfile && handleUpdateProfile()
  }

  const ViewForm = () => {
    return (
      <Formik
        initialValues={{
          displayName: ud?.displayName
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          displayName: Yup.string().required("Required")
        })}
      >
        {({ touched, errors, handleBlur, setFieldValue }): JSX.Element => {
          return (
            <form style={{ height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                width="50%"
                margin="auto auto 50px"
                paddingBottom="50px"
                borderBottom="1px solid #F2F2F2"
              >
                <Box width="100%">
                  <FormInput
                    name="displayName"
                    value={ud?.displayName}
                    idValue="displayName"
                    label="Display Name"
                    placeholder="Display Name"
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleFormChange("displayName", e, setFieldValue)}
                    onBlur={handleBlur}
                    error={touched.displayName && Boolean(errors.displayName)}
                    helperText={
                      errors.displayName && touched.displayName && errors.displayName
                    }
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                width="50%"
                margin="auto"
                paddingBottom="60px"
              >
                <Box width="100%">
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignContent="center"
                    alignItems="center"
                  >
                    {IconSupport}
                    <span className={contact}>Contact CORA Support</span>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end" padding="40px">
                <Box width="170px" marginRight="15px">
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => resetProfile && resetProfile()}
                    disabled={attachingPhoto}
                  >
                    DISCARD CHANGES
                  </Button>
                </Box>
                <Box width="180px">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm()}
                    disabled={attachingPhoto}
                  >
                    {attachingPhoto ? "Attaching Image..." : "SAVE"}
                  </Button>
                </Box>
              </Box>
            </form>
          )
        }}
      </Formik>
    )
  }

  const ViewImagePlaceholder = () => {
    if (previewImage === "" && photos?.url === "") {
      return (
        <Box
          width="150px"
          height="150px"
          borderRadius="50%"
          border="1px solid #CCCCCC"
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize="100px" fontWeight="700" color="#CCCCCC">
            {ud?.displayName[0]}
          </Box>
        </Box>
      )
    }

    return null
  }

  const ViewDefaultImage = () => {
    if (previewImage === "" && photos?.url === "") {
      return ViewImagePlaceholder()
    }
    if (previewImage !== "") {
      return <img className={avatar} src={previewImage} alt={ud?.displayName} />
    }
    return <img className={avatar} src={photos?.url} alt={ud?.displayName} />
  }

  return (
    <Box>
      <Box>
        {/** Image Preview */}
        <Box display="flex" justifyContent="center" padding="100px 0 0 0">
          <Box
            display="flex"
            margin="0 0 70px"
            alignContent="center"
            alignItems="center"
          >
            <Box margin="0 36px 0 0">
              <Box>{ViewDefaultImage()}</Box>
            </Box>
            <Box>
              <UploadButton
                onDrop={(event: React.ChangeEvent): void =>
                  handleImageUpload && handleImageUpload(event)}
                disabled={attachingPhoto || false}
                label={attachingPhoto ? "Attaching Image..." : "CHANGE PICTURE"}
              />
            </Box>
          </Box>
        </Box>
        {/** End Image Preview */}

        <Box>{ViewForm()}</Box>
      </Box>
    </Box>
  )
}
export default ProfileView
