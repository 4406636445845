import React from "react"

import OwnersProvider from "context/Dashboard/UnitManagement/categories/owners/Provider"
import OwnersView from "context/Dashboard/UnitManagement/categories/owners/View"

const OwnersTable: React.FC = () => (
  <OwnersProvider>
    <OwnersView />
  </OwnersProvider>
)

export default OwnersTable
