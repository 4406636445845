import useSWR from "swr"
/** Service */
import service from "services/Settings/AccountManagement/Staff"

function StaffSettingsTableHook() {
  /** Methods */
  const fetchSettingsStaff = async () => service.getStaff()

  const { data, isValidating } = useSWR("fetchSettingsStaff", fetchSettingsStaff, {
    revalidateOnFocus: true
  })
  const tableData = data?.data?._data

  return {
    tableData,
    isValidating
  }
}

export default StaffSettingsTableHook
