import React from "react"

export type DetailsCtxType = {
  getFeedbackMessages: Function
  showForm: Function
  photos: any
  messages: any
  data: any
}

export const DetailsContext = React.createContext<Partial<DetailsCtxType>>({})
