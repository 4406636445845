import * as Yup from "yup"

const validation: Record<any, {}> = {
  addOwnerGeneralInfo: Yup.object().shape({
    blockUnit: Yup.string().required("Required")
  }),
  addTenantGeneralInfo: Yup.object().shape({
    blockUnit: Yup.string().required("Required"),
    tenancyStart: Yup.string().required("Required"),
    tenancyEnd: Yup.string().required("Required")
  }),
  addUserPersonalDetailsOwner: Yup.object().shape({
    name: Yup.string().required("Required"),
    mobileNo: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        {
          message: "Please enter valid number.",
          excludeEmptyString: false
        }
      )
      .required("Required"),
    phoneNo: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        {
          message: "Please enter valid number.",
          excludeEmptyString: false
        }
      )
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    registeredAddress: Yup.string().required("Required")
  }),
  addUserPersonalDetailsTenant: Yup.object().shape({
    name: Yup.string().required("Required"),
    mobileNo: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        {
          message: "Please enter valid number.",
          excludeEmptyString: false
        }
      )
      .required("Required"),
    phoneNo: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        {
          message: "Please enter valid number.",
          excludeEmptyString: false
        }
      )
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
  }),
  addVehiclePersonal: Yup.object().shape({
    blockUnit: Yup.string().required("Required"),
    ownerName: Yup.string().required("Required")
  }),
  addVehicleDetails: Yup.object().shape({
    blockUnit: Yup.string().required("Required"),
    ownerName: Yup.string().required("Required"),
    carLabel: Yup.string().required("Required"),
    activationDate: Yup.string()
      .nullable()
      .required("Required"),
    vehicleModel: Yup.string().required("Required"),
    vehicleNumber: Yup.string().required("Required"),
    IUnumber: Yup.string().required("Required"),
    vehicleType: Yup.string().required("Required")
  }),
  addAccessCardPersonal: Yup.object().shape({
    blockUnit: Yup.string().required("Required"),
    ownerName: Yup.string().required("Required")
  }),
  addAccessCardDetails: Yup.object().shape({
    blockUnit: Yup.string().required("Required"),
    ownerName: Yup.string().required("Required"),
    activationDate: Yup.string()
      .nullable()
      .required("Required"),
    serialNumber: Yup.string().required("Required")
  })
}

export const {
  addOwnerGeneralInfo,
  addTenantGeneralInfo,
  addUserPersonalDetailsOwner,
  addUserPersonalDetailsTenant,
  addVehiclePersonal,
  addVehicleDetails,
  addAccessCardPersonal,
  addAccessCardDetails
} = validation
