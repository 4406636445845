import React from "react"
import Box from "@material-ui/core/Box"

import PublicDocuments from "./PublicDocuments"
import ConstructionManuals from "./ConstructionManuals"
import ManagementDocuments from "./ManagementDocuments"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const EstateMatters: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  return (
    <>
      <Box display="flex" alignItems="center" className={section}>
        <Box flex="3" className={category}>
          Estate Matters
        </Box>
      </Box>
      <PublicDocuments data={data} handleUpdate={handleUpdate} />
      <ConstructionManuals data={data} handleUpdate={handleUpdate} />
      <ManagementDocuments data={data} handleUpdate={handleUpdate} />
    </>
  )
}
export default EstateMatters
