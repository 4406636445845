import React from "react"

import ContextMenu from "components/ContextMenu"
import { fromUnix } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import ContextItems from "../../ContextMenu"

const tableHeaders = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Block/Unit No",
        id: "blockUnitNo",
        accessor: (c: Record<string, any>) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Owner Name",
        id: "ownerName",
        accessor: (c: Record<string, any>) => checkValue(c?.owner_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.owner_name),
        width: 150
      },
      {
        Header: "Activation Date",
        id: "activationDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.activation_date, fromUnix(c?.activation_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.activation_date, fromUnix(original?.activation_date)),
        width: 150
      },
      {
        Header: "Serial Number",
        id: "serialNumber",
        accessor: (c: Record<string, any>) => checkValue(c?.serial_no),
        Cell: ({ original }: Record<string, any>) => checkValue(original?.serial_no),
        width: 150
      },
      {
        Header: "Expiry Date",
        id: "expiryDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.expiry_date, fromUnix(c?.expiry_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.expiry_date, fromUnix(original?.expiry_date)),
        width: 150
      },
      {
        Header: "Remarks",
        id: "remarks",
        accessor: (c: Record<string, any>) => checkValue(c?.remarks),
        Cell: ({ original }: Record<string, any>) => checkValue(original?.remarks),
        width: 300
      }
    ]
  }
]

export default tableHeaders
