import React, { useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Cookies from "js-cookie"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Fade from "@material-ui/core/Fade"
import FormHelperText from "@material-ui/core/FormHelperText"

import Label from "components/Forms/Label"

/** Service */
import vmsAPI from "services/Dashboard/VMS"

interface BlockUnitProps {
  onChange: Function
  onBlur?: Function
  error?: boolean
  disabled?: boolean
  iValue: string
  helperText?: string | undefined | boolean
  disablePortal?: boolean
  options?: Array<{}>
}

const BlockUnit: React.FC<BlockUnitProps> = ({
  iValue,
  onChange,
  helperText,
  error = false,
  disabled = false,
  disablePortal = false,
  options = []
}) => {
  const [units, setUnits]: any = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [value, setValue] = useState([])

  const fetchUnits = async () => {
    setIsLoading(true)
    try {
      const { data } = await vmsAPI.getUnits({
        "condo_uid": Cookies.get("condoUID")
      })
      setUnits(data._data)
      setIsLoading(false)
      setValue(data._data?.find((i: any) => i?._uid === iValue))
      return data._data
    } catch (e) {
      setIsLoading(false)
      return e
    }
  }

  React.useEffect(() => {
    fetchUnits()
  }, [iValue])

  return (
    <Box>
      <Label label="Block/Unit No." />

      <Fade in={!isLoading} timeout={1000}>
        <FormControl variant="outlined" error={error}>
          <Autocomplete
            disablePortal={disablePortal}
            disabled={disabled}
            value={value || ""}
            onChange={(_event: any, newValue: any) =>
              newValue === null ? onChange("") : onChange(newValue?._uid)}
            getOptionLabel={(option: any) => option?.short_name || ""}
            loading={isLoading}
            options={units || options}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Select Block Unit"
                    data-testid="block-unit-input"
                    error={error}
                  />
                  <FormHelperText>{helperText}</FormHelperText>
                </>
              )
            }}
          />
        </FormControl>
      </Fade>
    </Box>
  )
}
export default BlockUnit
