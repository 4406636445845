import React, { useContext, useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import UploadBox from "components/UploadBox/new"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/facility/Context"
/** Config */
import { addFacilityDetails } from "config/Settings/PropertyManagement/Facilities/validation"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/facility/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormDetails: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const {
    facility,
    handlePhotoUpload,
    photos,
    removePhoto,
    photoAttaching,
    uploadError
  } = useContext(Context)

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return {
            facilityName: facility?.facilityName,
            description: facility?.description,
            location: facility?.location,
            capacity: facility?.capacity
          }
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addFacilityDetails}
      >
        {({ isValid, handleSubmit }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper>
                  <Box className={classes.section}>
                    <UploadBox
                      hasTitle={false}
                      onDrop={(event: any) =>
                        handlePhotoUpload && handlePhotoUpload(event)}
                      files={photos}
                      removePhoto={(event: any) => removePhoto && removePhoto(event)}
                      attaching={photoAttaching}
                      title="Choose file to upload"
                      acceptedFile="image/*"
                      hasError={uploadError}
                      disabled={photos.length === 4}
                      note="Note: maximum number of photos is 4"
                    />
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormDetails
