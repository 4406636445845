import React from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
// import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Select from "@material-ui/core/Select"
// import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"

// components
import FormLabel from "components/Forms/Label"

interface HeaderProps {
  condo: string
  loginDetails: Record<string, string>
  keepLoggedIn: boolean
  setloginDetails: Function
  setcondo: Function
  handleChange: Function
  setkeepLoggedIn: Function
}

const Form: React.FC<HeaderProps> = ({
  // setcondo,
  // condo,
  loginDetails,
  handleChange,
  keepLoggedIn,
  setkeepLoggedIn
}) => {
  return (
    <Box>
      {/* <Box marginBottom="30px">
        <FormControl variant="outlined">
          <FormLabel label="Select Condo" />
          <Select
            displayEmpty
            value={condo}
            onChange={(e: React.): void => setcondo(e.target.value)}
          >
            <MenuItem value="" disabled>
              The Alps
            </MenuItem>
            <MenuItem value="condo1">Condo 1</MenuItem>
            <MenuItem value="condo2">Condo 2</MenuItem>
            <MenuItem value="condo3">Condo 3</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      <Box marginBottom="30px">
        <FormLabel label="email" />
        <TextField
          placeholder="email@email.com"
          variant="outlined"
          value={loginDetails.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange("email", e.target.value)}
        />
      </Box>

      <Box marginBottom="30px">
        <FormLabel label="Position" />
        <TextField
          placeholder="Condo Officer"
          variant="outlined"
          value={loginDetails.pw}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange("position", e.target.value)}
        />
      </Box>

      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={keepLoggedIn}
              onChange={(): void => setkeepLoggedIn(!keepLoggedIn)}
              value="checkedB"
              color="primary"
            />
          }
          label="I agree to the CORA Terms"
        />
      </Box>
    </Box>
  )
}
export default Form
