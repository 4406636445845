import React from "react"

import ContextMenu from "components/ContextMenu"
import FileLink from "components/Table/components/FileLink"

/** Utils */
import { fromUnixDateTime } from "utils/date"
import { RolesCategory } from "config/Common/Roles"
import { checkValue, checkDate } from "utils/helpers"
import ContextItems from "./ContextMenu"

const TableHeaderColumns = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Name",
        id: "name",
        accessor: (c: any) => checkValue(c?.name),
        Cell: ({ original }: any) => checkValue(original?.name),
        width: 250
      },
      {
        Header: "Category",
        id: "category",
        accessor: (c: any) =>
          checkValue(c?.user_role_category, RolesCategory[c?.user_role_category]),
        Cell: ({ original }: any) =>
          checkValue(
            original?.user_role_category,
            RolesCategory[original?.user_role_category]
          ),
        width: 250
      },
      {
        Header: "Position",
        id: "position",
        accessor: (c: any) => checkValue(c?.user_role_name),
        Cell: ({ original }: any) => checkValue(original?.user_role_name),
        width: 200
      },
      {
        Header: "Time-in",
        id: "timein",
        accessor: (c: any) =>
          checkDate(
            c?.attendance_date_time,
            fromUnixDateTime(c?.attendance_date_time)
          ),
        Cell: ({ original }: any) =>
          checkDate(
            original?.attendance_date_time,
            fromUnixDateTime(original?.attendance_date_time)
          ),
        width: 250
      },
      {
        Header: "Photo",
        id: "position",
        sortable: false,
        filterable: false,
        accessor: (c: any) => checkValue(c?.photo?.key, c?.photo?.file_name),
        Cell: ({ original }: any) =>
          checkValue(
            original?.photo?.key,
            <FileLink
              url={original?.photo?.url}
              name={original?.photo?.file_name}
              fileType={2}
            />
          )
      },
      {
        Header: "Time-out",
        id: "timeout",
        accessor: (c: any) =>
          checkDate(
            c?.time_out?.attendance_date_time,
            fromUnixDateTime(c?.time_out?.attendance_date_time)
          ),
        Cell: ({ original }: any) =>
          checkDate(
            original?.time_out?.attendance_date_time,
            fromUnixDateTime(original?.time_out?.attendance_date_time)
          ),
        width: 250
      },
      {
        Header: "Photo",
        id: "position",
        sortable: false,
        filterable: false,
        accessor: (c: any) =>
          checkValue(c?.time_out?.photo?.key, c?.time_out?.photo?.file_name),
        Cell: ({ original }: any) =>
          checkValue(
            original?.photo?.key,
            <FileLink
              url={original?.time_out?.photo?.url}
              name={original?.time_out?.photo?.file_name}
              fileType={2}
            />
          )
      },
      {
        Header: "Remarks",
        id: "remarks",
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => checkValue(original?.remarks),
        width: 250
      }
    ]
  }
]

export default TableHeaderColumns
