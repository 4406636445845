import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
/** Components */
import Table from "pages/Dashboard/Feedback/tables/security"
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
/** Context */
import { TableIcons } from "pages/Dashboard/Feedback/config/Menu"
import withMainContext from "context/Dashboard/Feedback/categories/main/withContext"
import { CtxType } from "context/Dashboard/Feedback/categories/main/Context"
import { AppContext } from "App"

/** Config */
import { feedbackConfig } from "config/Dashboard/Feedback"

const FeedbackAllView: React.FC<CtxType> = ({
  activeFilter,
  showAddForm,
  filterTable,
  downloadData
}) => {
  const { permission } = useContext(AppContext)
  const csvFileName = `Feedback-Security-${feedbackConfig?.status[activeFilter]}.csv`

  return (
    <Card>
      <Box>
        <TableHeader
          addActionLabel="Add New"
          hasSearch
          addAction={() => showAddForm()}
          title="Security"
          titleIcon={TableIcons[2]}
          actionDisable={permission?.feedback_create}
          data={downloadData}
          fileName={csvFileName}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={activeFilter}
            handleChange={filterTable}
            options={feedbackConfig?.status}
          />
        </Box>
        <Table />
      </Box>
    </Card>
  )
}

export default withMainContext(FeedbackAllView)
