import React from "react"
import Box from "@material-ui/core/Box"
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
/** Styles */
import FacilityForm from "./Form"

const FacilitiesPaymentForm: React.FC<CtxType> = ({
  facilityBookings,
  selectedForm
}) => {
  const isDeposit = selectedForm?.isDeposit
  const selectedTimeslot = selectedForm?.timeslotUID
  const selectedFacility = selectedForm?.facilityUID

  return (
    <div>
      {facilityBookings?.map((fBooking: Record<string, any>, index: number) => {
        const activeTimeslot =
          selectedTimeslot === fBooking?.facility_time_slot_uid &&
          selectedFacility === fBooking?.facility?._uid

        return (
          <Box>
            {/* Deposit */}
            {activeTimeslot && isDeposit && (
              <Box>
                <FacilityForm
                  index={index}
                  feeBooking={fBooking && fBooking?.deposit_for_booking}
                  isDeposit
                />
              </Box>
            )}
            {/* Usage */}
            {activeTimeslot && !isDeposit && (
              <Box>
                <FacilityForm
                  index={index}
                  feeBooking={fBooking && fBooking?.usage_fee_for_booking}
                />
              </Box>
            )}
          </Box>
        )
      })}
    </div>
  )
}

export default withContext(FacilitiesPaymentForm)
