import * as React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

import "./styles.css"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "103px",
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  userContainer: {
    minHeight: "214px",
    backgroundColor: theme.palette.primary.main,
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  titleStyle: {
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: "24px",
    color: theme.palette.common.white
  },
  subtitleStyle: {
    fontWeight: 300,
    fontSize: "10px",
    color: theme.palette.common.white,
    textTransform: "uppercase"
  },
  avatar: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    background: "#006aff",
    border: "4px solid #ffffff",
    boxSizing: "border-box",
    backgroundSize: "cover",
    marginBottom: "10px"
  },
  subtitleUp: {
    display: "flex",
    flexDirection: "column-reverse"
  }
}))

// interface
interface HeaderProps {
  title: string
  subtitle?: string
  userData?: any
  subtitleAbove?: boolean
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  userData,
  subtitleAbove
}) => {
  const {
    avatar,
    container,
    titleStyle,
    subtitleStyle,
    subtitleUp,
    userContainer
  } = useStyles()
  const wrapper = userData ? userContainer : container
  const subtitlePosition = subtitleAbove ? subtitleUp : ""
  return (
    <Box className={`${wrapper} header-bg`}>
      {userData ? (
        <>
          <Box
            className={avatar}
            style={{
              backgroundImage: 'url("http://lorempixel.com/400/200/")'
            }}
          />
          <Box className={titleStyle}>{title}</Box>
          <Box className={subtitleStyle}>{subtitle}</Box>
        </>
      ) : (
        <>
          <Box className={subtitlePosition}>
            <Box className={titleStyle}>{title}</Box>
            <Box className={subtitleStyle}>{subtitle}</Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Header
