import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  tfrInfo: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "26px 50px 15px"
  },
  options: {
    display: "flex",
    flexWrap: "wrap",
    padding: "12px 50px 26px"
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
    paddingBottom: "8px"
  },
  optionName: {
    fontSize: "10px",
    color: theme.palette.body.secondary
  },
  optionColor: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px"
  }
}))

export default styles
