import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import Checkbox from "components/Forms/Checkbox"
import ModeOfPayment from "components/Common/Forms/ModeOfPayment"
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
/** Validation */
import { addApplicationDetails } from "config/Dashboard/Applications/validation"

/** Styles */
import { refSubmitApplicationDetails } from "context/Dashboard/Applications/forms/addApplication/View"
import styles from "./styles"

interface Props extends CtxType {
  index: number
  isDeposit?: boolean
  feeBooking: Record<string, any>
  selectedForm: Record<string, any>
  handleFormChange: Function
}

const FacilitiesPaymentForm: React.FC<Props> = ({
  index,
  feeBooking,
  isDeposit = false,
  handleFormChange
}) => {
  const classes = styles()

  const paymentMethod = Number(feeBooking?.payment_method)
  const paymentAgent = feeBooking?.e_payment_agent
  const paymentReference = feeBooking?.payment_reference

  return (
    <div>
      <Formik
        initialValues={{
          paymentDescription: feeBooking?.description,
          amount: feeBooking?.amt,
          tax: feeBooking?.tax,
          remarks: feeBooking?.admin_remarks,
          paymentAgent,
          paymentReference
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addApplicationDetails}
      >
        {({ touched, errors, handleBlur, handleSubmit }): JSX.Element => {
          return (
            <form>
              <div className={classes.formWrapper}>
                <FormWrapper title="Payment Details" titleSmall>
                  <Box className={classes.spaceBottom}>
                    <FormInput
                      name="paymentDescription"
                      value={feeBooking?.description}
                      idValue="paymentDescription"
                      label="Payment Description"
                      placeholder="Details"
                      multiline
                      rows={3}
                      handleOnChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void =>
                        handleFormChange(
                          e?.target?.value,
                          index,
                          "description",
                          isDeposit
                        )}
                      onBlur={handleBlur}
                      error={
                        touched.paymentDescription &&
                        Boolean(errors.paymentDescription)
                      }
                      helperText={
                        errors.paymentDescription &&
                        touched.paymentDescription &&
                        errors.paymentDescription
                      }
                    />
                  </Box>

                  <Box className={classes.spaceBottom}>
                    <FormInput
                      name="amount"
                      value={Number(feeBooking?.amt)}
                      idValue="amount"
                      label="Amount"
                      placeholder="$00.00"
                      handleOnChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void =>
                        handleFormChange(
                          Number(e?.target?.value),
                          index,
                          "amt",
                          isDeposit
                        )}
                      onBlur={handleBlur}
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={errors.amount && touched.amount && errors.amount}
                    />
                  </Box>

                  {!isDeposit && (
                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="tax"
                        value={Number(feeBooking?.tax)}
                        idValue="tax"
                        label="Tax"
                        placeholder="$00.00"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(
                            Number(e?.target?.value),
                            index,
                            "tax",
                            isDeposit
                          )}
                        onBlur={handleBlur}
                        error={touched.tax && Boolean(errors.tax)}
                        helperText={errors.tax && touched.tax && errors.tax}
                      />
                    </Box>
                  )}

                  <Box className={classes.spaceBottom}>
                    <ModeOfPayment
                      label="mode of payment"
                      value={String(feeBooking?.payment_method)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFormChange(
                          e?.target?.value,
                          index,
                          "payment_method",
                          isDeposit
                        )}
                    />
                  </Box>

                  <>
                    {paymentMethod === 2 && (
                      <>
                        <Box className={classes.spaceBottom}>
                          <FormInput
                            name="paymentAgent"
                            label="bank name"
                            placeholder="Bank Name"
                            value={paymentAgent}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={
                              touched.paymentAgent && Boolean(errors.paymentAgent)
                            }
                            helperText={
                              errors.paymentAgent &&
                              touched.paymentAgent &&
                              String(errors.paymentAgent)
                            }
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleFormChange(
                                e?.target?.value,
                                index,
                                "e_payment_agent",
                                isDeposit
                              )}
                          />
                        </Box>
                        <Box className={classes.spaceBottom}>
                          <FormInput
                            name="paymentReference"
                            label="cheque no."
                            placeholder="Cheque No."
                            value={paymentReference}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={
                              touched.paymentReference &&
                              Boolean(errors.paymentReference)
                            }
                            helperText={
                              errors.paymentReference &&
                              touched.paymentReference &&
                              String(errors.paymentReference)
                            }
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleFormChange(
                                e?.target?.value,
                                index,
                                "payment_reference",
                                isDeposit
                              )}
                          />
                        </Box>
                      </>
                    )}
                  </>

                  <>
                    {paymentMethod === 3 && (
                      <>
                        <Box className={classes.spaceBottom}>
                          <FormInput
                            name="paymentAgent"
                            label="payment agent"
                            placeholder="Payment Agent"
                            value={paymentAgent}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={
                              touched.paymentAgent && Boolean(errors.paymentAgent)
                            }
                            helperText={
                              errors.paymentAgent &&
                              touched.paymentAgent &&
                              String(errors.paymentAgent)
                            }
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleFormChange(
                                e?.target?.value,
                                index,
                                "e_payment_agent",
                                isDeposit
                              )}
                          />
                        </Box>
                        <Box className={classes.spaceBottom}>
                          <FormInput
                            name="paymentReference"
                            label="payment reference"
                            placeholder="Payment Reference"
                            value={paymentReference}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={
                              touched.paymentReference &&
                              Boolean(errors.paymentReference)
                            }
                            helperText={
                              errors.paymentReference &&
                              touched.paymentReference &&
                              String(errors.paymentReference)
                            }
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleFormChange(
                                e?.target?.value,
                                index,
                                "payment_reference",
                                isDeposit
                              )}
                          />
                        </Box>
                      </>
                    )}
                  </>

                  <Box className={classes.spaceBottomLarge}>
                    <Checkbox
                      label="Auto-approve payment"
                      value={feeBooking?.auto_approve}
                      onChange={() =>
                        handleFormChange(
                          !feeBooking?.auto_approve,
                          index,
                          "auto_approve",
                          isDeposit
                        )}
                    />
                  </Box>

                  <Box className={classes.spaceBottom}>
                    <FormInput
                      name="remarks"
                      value={feeBooking?.admin_remarks}
                      idValue="remarks"
                      label="Remarks"
                      placeholder="Remarks"
                      multiline
                      rows={3}
                      handleOnChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void =>
                        handleFormChange(
                          e?.target?.value,
                          index,
                          "admin_remarks",
                          isDeposit
                        )}
                      onBlur={handleBlur}
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={
                        errors.remarks && touched.remarks && errors.remarks
                      }
                    />
                  </Box>

                  <RefButton
                    refValue={refSubmitApplicationDetails}
                    action={handleSubmit}
                  />
                </FormWrapper>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withContext(FacilitiesPaymentForm)
