import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import styles from "./styles"

interface Props {
  images: Record<string, any>
  name: string
  status: string
  description: string
  editAction: Function
  removeAction: Function
  suspendedDates?: string | null
}

const CardLayout: React.FC<Props> = ({
  name,
  status,
  description,
  images,
  editAction,
  removeAction,
  suspendedDates
}) => {
  const classes = styles()

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {images !== null && (
          <CardMedia
            className={classes.media}
            image={images[0]?.url}
            title="Contemplative Reptile"
          />
        )}
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Box paddingBottom="25px">
          <Typography variant="body2" color="textSecondary" component="p">
            <small>
              {`${status} ${suspendedDates !== null ? `- ${suspendedDates}` : ``}`}
            </small>
          </Typography>
        </Box>
        <Box paddingBottom="25px">
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </Box>
      </CardContent>

      <CardActions>
        <Button size="small" color="primary" onClick={() => editAction()}>
          EDIT
        </Button>
        <Button size="small" color="primary" onClick={() => removeAction()}>
          REMOVE
        </Button>
      </CardActions>
    </Card>
  )
}
export default CardLayout
