import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const UnitManagement: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("unit management")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.unit_mgt_view)}
          onChange={() =>
            handleUpdate("unit_mgt_view", Boolean(!data?.unit_mgt_view))}
        />
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.unit_mgt_create)}
          onChange={() =>
            handleUpdate("unit_mgt_create", Boolean(!data?.unit_mgt_create))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.unit_mgt_edit)}
          onChange={() =>
            handleUpdate("unit_mgt_edit", Boolean(!data?.unit_mgt_edit))}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.unit_mgt_remove)}
          onChange={() =>
            handleUpdate("unit_mgt_remove", Boolean(!data?.unit_mgt_remove))}
        />
      </Box>
    </Box>
  )
}
export default UnitManagement
