import React from "react"
import ContextMenu from "components/ContextMenu"
import Lock from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"

/** Components */
import StatusBlock from "components/Table/components/StatusBlock"
import ContextBookKeeping from "pages/Dashboard/Bookkeeping/components/ContextMenu"

/** Utils */
import { fromUnix } from "utils/date"
import { binaryString, checkValue, checkDate } from "utils/helpers"

/** Config */
import {
  paymentType,
  paymentMethod,
  status,
  statusColor
} from "config/Dashboard/Bookkeeping"

const TableHeaderColumns = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: any) => {
          return (
            <ContextMenu>
              <ContextBookKeeping data={row._original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "",
        id: "isLock",
        accessor: (c: any) => {
          const isRefundLock = +binaryString(c?.flags) === 1
          return isRefundLock ? "Locked" : "Unlocked"
        },
        Cell: ({ original }: any) => {
          const isRefundLock = +binaryString(original?.flags) === 1
          return isRefundLock ? <Lock /> : <LockOpenIcon />
        },
        sortable: false,
        filterable: false,
        width: 80
      },
      {
        Header: "Status",
        id: "status",
        accessor: (c: any) =>
          checkValue(c?.payment_status, status[c?.payment_status]),
        Cell: ({ original }: any) =>
          checkValue(
            original?.payment_status,
            <StatusBlock
              status={status[original?.payment_status]}
              color={statusColor[original?.payment_status]}
            />
          ),
        width: 170
      },
      {
        Header: "Receipt No.",
        id: "txnId",
        accessor: (c: any) => checkValue(c?.txn_id),
        Cell: ({ original }: any) => checkValue(original?.txn_id),
        width: 200
      },
      {
        Header: "Block/Unit No",
        id: "blockUnitNo",
        accessor: (c: any) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Name",
        id: "payeeName",
        accessor: (c: any) => checkValue(c?.payee_name),
        Cell: ({ original }: any) => checkValue(original?.payee_name),
        width: 200
      },
      {
        Header: "Type",
        id: "type",
        accessor: (c: any) =>
          checkValue(c?.payment_type, paymentType[c?.payment_type]),
        Cell: ({ original }: any) =>
          checkValue(original?.payment_type, paymentType[original?.payment_type]),
        width: 150
      },
      {
        Header: "Category",
        id: "category",
        accessor: (c: any) => checkValue(c?.category),
        Cell: ({ original }: any) => checkValue(original?.category),
        width: 150
      },
      {
        Header: "Payment Description",
        id: "paymentDescription",
        accessor: (c: any) => checkValue(c?.description),
        Cell: ({ original }: any) => checkValue(original?.description),
        width: 250
      },
      {
        Header: "Payment Date",
        id: "paymentDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?._created, fromUnix(c?._created)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?._created, fromUnix(original?._created)),
        width: 150
      },
      {
        Header: "Payment Method",
        id: "paymentMethod",
        accessor: (c: any) =>
          checkValue(c?.payment_method, paymentMethod[c?.payment_method]),
        Cell: ({ original }: any) =>
          checkValue(
            original?.payment_method,
            paymentMethod[original?.payment_method]
          ),
        width: 200
      },
      {
        Header: "Payment Agent",
        id: "paymentAgent",
        accessor: (c: any) => checkValue(c?.e_payment_agent),
        Cell: ({ original }: any) => checkValue(original?.e_payment_agent),
        width: 200
      },
      {
        Header: "Payment Reference",
        id: "paymentReference",
        accessor: (c: any) => checkValue(c?.payment_reference),
        Cell: ({ original }: any) => checkValue(original?.payment_reference),
        width: 200
      },
      {
        Header: "Amount",
        id: "amount",
        accessor: (c: any) => checkValue(c?.amt, c?.amt?.toFixed(2)),
        Cell: ({ original }: any) =>
          checkValue(original?.amt, original?.amt?.toFixed(2)),
        width: 150
      },
      {
        Header: "Tax",
        id: "tax",
        accessor: (c: any) => checkValue(c?.tax, c?.tax.toFixed(2)),
        Cell: ({ original }: any) =>
          checkValue(original?.tax, original?.tax?.toFixed(2)),
        width: 150
      },
      {
        Header: "Closed By",
        id: "closedBy",
        accessor: (c: any) => checkValue(c?.closed_by_account_name),
        Cell: ({ original }: any) => checkValue(original?.closed_by_account_name),
        width: 250
      },
      {
        Header: "Closed Date",
        id: "closeDate",
        accessor: (c: Record<string, any>) =>
          checkDate(
            c?.updated_at,
            fromUnix(c?.status_update_time?.status_changes?.slice(-1)[0]?.updated_at)
          ),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(
            original?.updated_at,
            fromUnix(
              original?.status_update_time?.status_changes?.slice(-1)[0]?.updated_at
            )
          ),
        width: 150
      }
    ]
  }
]

export default TableHeaderColumns
