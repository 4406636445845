import React, { Suspense, useContext, useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
/** Layout */
import SelectFacility from "pages/Dashboard/Facilities/components/AddBooking/SelectFacility"
import BookingDetails from "pages/Dashboard/Facilities/components/AddBooking/BookingDetails"
import Payment from "pages/Dashboard/Facilities/components/AddBooking/Payment"
import Confirmation from "pages/Dashboard/Facilities/components/AddBooking/Confirmation"
/** Components */
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"
import Stepper from "components/Stepper"
import Loader from "components/Loader"
/** Context */
import { Context } from "context/Dashboard/Facilites/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"
/** Ref */
export const refSubmit = React.createRef<HTMLDivElement>()

function getSteps(foradmin = false) {
  if (foradmin) {
    return ["Booking Details", "Select Facility"]
  }

  return ["Booking Details", "Select Facility", "Payment", "Confirmation"]
}

const activeStep: Record<string, number> = {
  "bookingDetails": 1,
  "selectFacility": 2,
  "payment": 3,
  "confirmation": 4
}

export const refPrintBtn = React.createRef<HTMLButtonElement>()

const AddBookingForm: React.FC<CtxType> = ({
  bookingDetails,
  xValue,
  goNext,
  goBack,
  openDialog,
  submitting,
  setOpenDialog,
  handleAdminAddBooking,
  handleAddBooking,
  handleEditBooking,
  handleApproveBooking,
  mainView
  // returnedBooking,
}): JSX.Element => {
  const { showTable } = useContext(Context)

  const [isValid, setIsDetailsValid] = useState(false)
  const isForAdmin = bookingDetails?.forAdmin

  function submitLabel(): string {
    if (xValue === "payment") {
      return "Submit"
    }

    return "Next"
  }

  function getStepContent(step: any) {
    switch (step) {
      case 1:
        return <BookingDetails setValid={setIsDetailsValid} />
      case 2:
        return <SelectFacility />
      case 3:
        return <Payment />
      case 4:
        return <Confirmation />
      default:
        return null
    }
  }

  function handleNext(): void {
    refSubmit.current?.click()

    if (xValue === "bookingDetails" && isValid) {
      goNext()
    }

    if (xValue === "selectFacility") {
      if (isForAdmin) {
        handleAdminAddBooking &&
          handleAdminAddBooking().then(() => showTable && showTable())
      } else {
        goNext()
      }
    }

    if (xValue === "payment") {
      if (mainView === "addBooking") {
        if (isForAdmin) {
          handleAdminAddBooking && handleAdminAddBooking().then(() => goNext())
        } else {
          handleAddBooking && handleAddBooking().then(() => goNext())
        }
      }

      if (mainView === "editBooking") {
        handleEditBooking && handleEditBooking().then(() => goNext())
      }

      if (mainView === "approveBooking") {
        handleApproveBooking && handleApproveBooking().then(() => goNext())
      }
    }
  }

  return (
    <Card style={{ padding: "0 0 40px 0" }}>
      <Stepper
        items={getSteps(isForAdmin)}
        activeStep={activeStep[xValue as string]}
        justifyStart
      />
      <Box>
        <Suspense fallback={<Loader forContent />}>
          {getStepContent(activeStep[xValue as string])}
        </Suspense>
        <Box margin="30px 0 0 0">
          <Footer
            handleNext={() => handleNext()}
            handleBack={
              xValue === "bookingDetails" || xValue === "confirmation"
                ? null
                : () => goBack()
            }
            handleCancel={() => setOpenDialog && setOpenDialog(true)}
            label={submitLabel()}
            forAcknowledge={xValue === "confirmation"}
            handlePrint={() => refPrintBtn.current?.click()}
            handleDone={() => showTable && showTable()}
            handleSendEmail={() => null}
            disabled={submitting}
          />
        </Box>
      </Box>

      <Dialog
        action={() => showTable && showTable()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="OK"
        title=""
        message="Are you sure you want to cancel?"
      />
    </Card>
  )
}

export default withContext(AddBookingForm)
