import React, { useEffect, useState } from "react"
import { mutate } from "swr"
import { toast } from "react-toastify"

/** Context */
import Service from "services/Settings/AccountManagement/Admin"
import { Context } from "./Context"

/** Services */

const Provider: React.FC = ({ children }) => {
  const [showForm, setShowForm] = useState(false)
  const [resetForm, showResetForm] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [userData, setUserData] = useState({})
  const [activeData, setActiveData] = useState<Record<string, any>>({})
  const [formType, setFormType] = useState(1)

  /**
   * Notification
   */
  const notifyDelete = () =>
    toast(`Successfully deleted the User.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  function showFormType(state: boolean, type: number) {
    setShowForm(state)
    setFormType(type)
  }

  function resetData() {
    setActiveData({})
  }

  async function handleDeleteUser(itemUID: string, apiName: string) {
    setIsDeleting(true)
    try {
      const response = await Service.deleteAdminUser(itemUID)
      await mutate(apiName)
      notifyDelete()
      setIsDeleting(false)
      return response
    } catch (e) {
      setIsDeleting(false)
      return e
    }
  }

  useEffect(() => {
    resetData()
  }, [])

  return (
    <Context.Provider
      value={{
        showForm,
        isDeleting,
        activeData,
        resetForm,
        userData,
        setShowForm,
        setActiveData,
        showFormType,
        resetData,
        showResetForm,
        setUserData,
        handleDeleteUser,
        formType
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
