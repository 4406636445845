import React from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Cookie from "js-cookie"
/** Service */
import service from "services/Settings/PropertyManagement/Feedback"
/** Components */
import Loader from "components/Loader"
import Table from "components/Table"
import tableHeaders from "./table/tableHeaders"

const TableSettingFeedback: React.FC = () => {
  const condoUID = Cookie.get("condoUID")

  /** Methods */
  const fetchSettingsFeedback = async () =>
    service.getFeedback({
      "condo_uid": condoUID
    })

  const { data, isValidating } = useSWR(
    "fetchSettingsFeedback",
    fetchSettingsFeedback,
    {
      revalidateOnFocus: true
    }
  )
  const tableData = data?.data?._data

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table data={tableData || []} columns={tableHeaders()} minRows={2} />
      </div>
    </Fade>
  )
}

export default TableSettingFeedback
