import * as React from "react"

const PetRegistration: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4283 0V3L11.0176 8H4.17153L2.24295 6L0.892578 7.40039L2.82115 9.40039V11V13L1.85686 15L0.892578 21H3.06222L3.78544 17L5.71401 15H12.0365L14.2476 21H16.3117L14.8747 15.8145V15L16.3211 12V7H17.0519C17.8195 7 18.5553 6.68409 19.0972 6.12109L20.1783 5V3.5C20.1783 3.224 19.9623 3 19.6961 3H18.2497L17.5679 2.29297C17.3867 2.10497 17.1417 2 16.8862 2H15.839L14.9708 0.800781C14.6063 0.296781 14.0358 0 13.4283 0ZM13.6562 7H14.3926V11.5273L13.6825 13H11.3001H4.74972V10H12.2098L13.6562 7ZM6.51256 17L6.134 17.3926L7.40151 21H9.57115L8.25467 17H6.51256Z"
        fill="white"
      />
    </svg>
  )
}

export default PetRegistration
