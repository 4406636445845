import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  labelStyle: {
    color: theme.palette.secondary?.dark,
    fontSize: "10px",
    marginBottom: "4px",
    textTransform: "uppercase",
    "&.light": {
      color: theme.palette.common?.white
    },
    "&.large": {
      fontSize: "12px"
    }
  },
  valueStyle: {
    color: theme.palette.body?.dark,
    fontSize: "14px"
  },
  titleStyle: {
    color: theme.palette.secondary?.dark,
    fontSize: "16px",
    marginBottom: "15px"
  }
}))

export default styles
