import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getAnnouncementPost() {
  return request({
    url: API.ANNOUNCEMENT_POSTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function createPost(data: Object) {
  return request({
    url: API.POST,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updatePost(data: Object, uid: string) {
  return request({
    url: `${API.POST}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function deletePost(uid: string) {
  return request({
    url: `${API.POST}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

const AnnouncementAPI = {
  getAnnouncementPost,
  createPost,
  updatePost,
  deletePost
}

export default AnnouncementAPI
