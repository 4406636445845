import React from "react"

export type CtxType = {
  setOpenDialog: Function
  setActiveData: Function
  setActiveVehicle: Function
  setShowDetails: Function
  activeVehicle: Record<string, any>
  activeData: Record<string, any>
  openDialog: boolean
  showDetails: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
