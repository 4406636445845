import * as React from "react"

interface ConstructionProps {
  color: string
}

const Construction: React.FC<ConstructionProps> = ({ color }) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 0C1.346 0 0 1.346 0 3V5H2V18H7V16H4V5H15V3C15 2.449 15.448 2 16 2C16.565 2 17 2.43497 17 3V18H19V3C19 1.35503 17.645 0 16 0H3ZM6 8V10H15V8H6ZM6 12V14H8V12H6ZM12 12C10.355 12 9 13.355 9 15C9 15.8764 9.39184 16.6622 10 17.2129V20.8047L12 19.5547L14 20.8047V17.2129C14.6082 16.6622 15 15.8764 15 15C15 13.355 13.645 12 12 12ZM12 14C12.5641 14 13 14.4359 13 15C13 15.5641 12.5641 16 12 16C11.4359 16 11 15.5641 11 15C11 14.4359 11.4359 14 12 14Z"
        fill={color}
      />
    </svg>
  )
}

export default Construction
