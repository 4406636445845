import React from "react"
import Box from "@material-ui/core/Box"

import styles from "./styles"

interface Props {
  title: string
  icon: any
}

const FormTitle: React.FC<Props> = ({ title, icon }) => {
  const { label, wrapper } = styles()
  return (
    <Box className={wrapper}>
      <Box>{icon}</Box>
      <Box className={label}>{title}</Box>
    </Box>
  )
}

export default FormTitle
