import React, { useState, useEffect } from "react"

/** Components */
import Toast from "components/Toast"
/** Service */
import service from "services/Settings/PropertyManagement/Facilities"
/** Utils */
import { CondoUID } from "config/Common/CondoInfo"
import {
  toUnix,
  fromUnixTime,
  checkTime,
  checkValidTimeRange,
  dateDefaultTime
} from "utils/date"
import { overlap } from "utils/functions"
import { Context } from "./Context"

export interface Props {
  showTable: Function
  data?: Record<string, any>
}

const Provider: React.FC<Props> = ({ children, showTable, data }) => {
  const hasData = data !== undefined

  const [timeoverlap, setTimeoverlap] = useState<Record<string, any>>({})
  const [activeTab, setActiveTab] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [category, setCategory] = useState({
    categoryUID: data?.facility_category?._uid || "",
    categoryName: data?.facility_category?.category_name || "",
    categoryType: data?.facility_category?.category_type || "",
    advanceBookingDays: data?.advance_booking_days || 0,
    cancelBookingDays: data?.cancel_booking_days || 0,
    lastMinuteBooking: data?.last_minute_booking_hours || 0,
    entitlementPeriod: (data && String(data?.entitlement_period)) || "3",
    slotsPerUnit: data?.slots_per_unit || "",
    isDeposit: (data && data?.deposit_amt !== 0) || false,
    isUsageFee: (data && data?.usage_fee_amt !== 0) || false,
    amountDeposit: data?.deposit_amt || 0,
    amountUsageFee: data?.usage_fee_amt || 0,
    effectiveDate: new Date()
  })
  const blankTimeSlot = { startTime: null, endTime: null }
  const [timeSlots, setTimeSlots] = useState(
    data && data?.facility_time_slots !== null
      ? data?.facility_time_slots.map((timeslot: any) => {
          return {
            startTime: checkTime(fromUnixTime(timeslot?.start_time, false, "HH:mm"))
              ?.activeTime,
            endTime: checkTime(fromUnixTime(timeslot?.end_time, false, "HH:mm"))
              ?.activeTime
          }
        })
      : [{ ...blankTimeSlot }]
  )
  const [selectedTimes, setSelectedTimes] = useState<any>([])

  useEffect(() => {
    if (!category?.isUsageFee) {
      setCategory({
        ...category,
        amountUsageFee: 0
      })
    }
  }, [category?.isUsageFee])

  useEffect(() => {
    if (!category?.isDeposit) {
      setCategory({
        ...category,
        amountDeposit: 0
      })
    }
  }, [category?.isDeposit])

  /*
   * Methods
   */
  function checkTimeslots(sValues: Record<string, any>) {
    const tSlots = [] as any

    sValues.map((sTimes: Record<string, any>) => {
      if (sTimes?.startTime !== null && sTimes?.endTime !== null) {
        tSlots.push(sTimes?.startTime)
        tSlots.push(sTimes?.endTime)
      }

      return sTimes
    })

    setSelectedTimes([...selectedTimes, ...tSlots])
  }

  function handleTimeChange(e: any, idxValue: number, name: string): any {
    const updatedTimes = [...timeSlots]
    const isStartTime = name === "startTime"

    if (isStartTime) {
      updatedTimes[idxValue].startTime = checkTime(e)?.activeTime
      return setTimeSlots(updatedTimes)
    }

    if (!isStartTime) {
      if (checkTime(e)?.isZero) {
        updatedTimes[idxValue].endTime = checkTime(e)?.activeLastHour
        return setTimeSlots(updatedTimes)
      }

      if (
        checkValidTimeRange(
          updatedTimes[idxValue].startTime,
          checkTime(e)?.activeTime
        )
      ) {
        updatedTimes[idxValue].endTime = checkTime(e)?.activeTime
      } else {
        return Toast("End time must be after the start time.", false)
      }
    }

    setTimeSlots(updatedTimes)
    return updatedTimes
  }

  function addTimeSlot(): void {
    if (timeSlots.length !== 0) {
      setTimeSlots([
        ...timeSlots,
        { startTime: timeSlots[timeSlots?.length - 1]?.endTime, endTime: null }
      ])
    }
  }

  function removeTimeSlot(idx: number): void {
    const updatedTimes = [...timeSlots]

    if (updatedTimes.length !== 1) {
      updatedTimes.splice(idx, 1)
      setTimeSlots(updatedTimes)
    }

    setTimeoverlap(overlap(updatedTimes))
  }

  function checkHasBlank(): boolean {
    let hasBlank = false
    timeSlots?.map((time: any) => {
      if (time?.startTime === null || time?.endTime === null) {
        return (hasBlank = true)
      }

      return time
    })

    return hasBlank
  }

  async function handleAddCategory(): Promise<void> {
    const payload = {
      "condo_uid": CondoUID,
      "category_name": category?.categoryName,
      "category_type": Number(category?.categoryType),
      "facility_setting": {
        "advance_booking_days": Number(category?.advanceBookingDays),
        "cancel_booking_days": Number(category?.cancelBookingDays),
        "entitlement_period": Number(category?.entitlementPeriod),
        "slots_per_unit": Number(category?.slotsPerUnit),
        "usage_fee_amt": Number(category?.amountUsageFee),
        "deposit_amt": Number(category?.amountDeposit),
        "effective_date": dateDefaultTime(String(category?.effectiveDate)),
        "last_minute_booking_hours": Number(category?.lastMinuteBooking),
        "facility_time_slots": timeSlots.map((time: Record<string, any>) => ({
          "start_time": toUnix(time?.startTime),
          "end_time": toUnix(time?.endTime)
        }))
      }
    }

    try {
      const response = await service.createCategory(payload)
      const statusCode = response?.data?._statusCode

      if (statusCode === -121) {
        return Toast("Duplicate Category, please try again.", false)
      }

      showTable()
      Toast(`Successfully created the category.`, true)

      return response
    } catch (e) {
      return e
    }
  }

  async function handleUpdateCategory(): Promise<any> {
    const categoryPayload = {
      "category_name": category?.categoryName,
      "category_type": Number(category?.categoryType)
    }

    const settingsPayload = {
      "condo_uid": CondoUID,
      "facility_category_uid": category?.categoryUID,
      "advance_booking_days": Number(category?.advanceBookingDays),
      "cancel_booking_days": Number(category?.cancelBookingDays),
      "entitlement_period": Number(category?.entitlementPeriod),
      "slots_per_unit": Number(category?.slotsPerUnit),
      "usage_fee_amt": Number(category?.amountUsageFee),
      "deposit_amt": Number(category?.amountDeposit),
      "effective_date": dateDefaultTime(String(category?.effectiveDate)),
      "last_minute_booking_hours": Number(category?.lastMinuteBooking),
      "facility_time_slots": timeSlots.map((time: Record<string, any>) => ({
        "start_time": toUnix(time?.startTime),
        "end_time": toUnix(time?.endTime)
      }))
    }

    try {
      const responseCategory = await service.updateCategory(
        categoryPayload,
        data?.facility_category?._uid
      )
      const responseSettings = await service.createSettings(settingsPayload)
      Toast(`Successfully updated the category.`, true)
      showTable()

      return {
        responseCategory,
        responseSettings
      }
    } catch (e) {
      return e
    }
  }

  useEffect(() => {
    checkTimeslots(timeSlots)
    setTimeoverlap(overlap(timeSlots))
  }, [timeSlots])

  return (
    <Context.Provider
      value={{
        setActiveTab,
        setCategory,
        setOpenDialog,
        setSubmitting,
        setTimeSlots,
        handleTimeChange,
        addTimeSlot,
        removeTimeSlot,
        checkHasBlank,
        handleAddCategory,
        handleUpdateCategory,
        timeSlots,
        showTable,
        submitting,
        openDialog,
        activeTab,
        category,
        hasData,
        timeoverlap
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
