import * as React from "react"

const VMSService = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57813 0.0117245C7.29526 0.0259351 7.00988 0.0549813 6.72266 0.0996152C2.48486 0.759591 -0.329485 4.71577 0.0390707 8.93946C0.196035 10.7502 0.900428 11.8285 1.52149 12.7246C2.29769 13.8459 3.00001 14.764 3.00001 17.2402V20H12V17H14C15.0931 17 16 16.0931 16 15V12.0215L17.2266 11.5039C17.7872 11.2679 18.0465 10.4154 17.7246 9.90821L15.9473 7.11915V7.11719C15.4887 2.93152 11.8213 -0.201436 7.57813 0.0117245ZM7.00001 2.08008V7.27149C6.69649 7.44673 6.44435 7.69865 6.26884 8.00201C6.09333 8.30538 6.00063 8.64953 6.00001 9.00001C6.00001 9.53044 6.21072 10.0391 6.58579 10.4142C6.96087 10.7893 7.46957 11 8.00001 11C8.17478 10.9999 8.34879 10.9769 8.51759 10.9316L10.0039 12.418C10.0019 12.4453 10.0006 12.4726 10 12.5C10 12.8978 10.158 13.2794 10.4393 13.5607C10.7207 13.842 11.1022 14 11.5 14C11.8978 14 12.2794 13.842 12.5607 13.5607C12.842 13.2794 13 12.8978 13 12.5C13 12.1022 12.842 11.7206 12.5607 11.4393C12.2794 11.158 11.8978 11 11.5 11C11.4726 11.0006 11.4453 11.0019 11.418 11.0039L9.9297 9.51563C9.97549 9.34755 9.99913 9.17421 10 9.00001C9.99973 8.6492 9.90719 8.30464 9.73166 8.0009C9.55614 7.69717 9.30381 7.44494 9.00001 7.26954V2.08008C11.621 2.50934 13.6614 4.6199 13.959 7.33594L14.0098 7.79883L14.2598 8.19337L15.4609 10.0781L14 10.6953V11.3574V15H10V18H5.00001V17.2402C5.00001 14.3285 3.94183 12.7067 3.16602 11.5859C2.54509 10.69 2.15429 10.1869 2.03126 8.76758V8.76563C1.74876 5.52818 3.84549 2.58879 7.00001 2.08008Z"
      fill="#454B57"
    />
  </svg>
)

export default VMSService
