import React, { lazy, Suspense } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Component */
import Loader from "components/Loader"
import Stepper from "components/Stepper"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer"

/** Context */
import { CreatePSMachine } from "machines/Dashboard/UnitManagement/createPS"
import { Context as MainContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Machines */

/** Ref */
export const refSubmit = React.createRef<HTMLDivElement>()

const PersonalDetails = lazy(() =>
  import(
    "pages/Dashboard/UnitManagement/components/paymentSchedule/EditPaymentSchedule/PersonalDetails"
  )
)
const PaymentSchedule = lazy(() =>
  import(
    "pages/Dashboard/UnitManagement/components/paymentSchedule/EditPaymentSchedule/PaymentSchedule"
  )
)

const CreatePaymentScheduleView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  psDetails,
  handleCreatePS
}) => {
  const { showMain } = React.useContext(MainContext)
  const [current, send] = useMachine(CreatePSMachine)
  const xValue = current?.value
  const {
    unitUID,
    payeePhone,
    payeeEmail,
    payeeAddress,
    paymentName,
    startDate,
    endDate,
    amt,
    tax,
    notifyOnDaysBefore,
    flags,
    notifyOnDaysAfterDue
  } = psDetails

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()
    /** Step 1 */
    if (
      xValue === "personalDetails" &&
      unitUID !== "" &&
      payeeEmail !== "" &&
      payeePhone !== "" &&
      payeeAddress !== ""
    ) {
      send("NEXT")
    }
    /** end of Step 1 */

    /** Step 2 */
    if (
      xValue === "paymentSchedule" &&
      paymentName !== "" &&
      startDate !== "" &&
      endDate !== "" &&
      amt !== "" &&
      tax !== "" &&
      notifyOnDaysBefore !== "" &&
      flags !== "" &&
      notifyOnDaysAfterDue !== ""
    ) {
      handleCreatePS()
    }
    /** end of Step 2 */
  }

  /** Views */
  const ViewPersonalDetails = (): JSX.Element => {
    if (xValue !== "personalDetails") {
      return <div />
    }

    return <PersonalDetails />
  }

  const ViewPaymentSchedule = (): JSX.Element => {
    if (xValue !== "paymentSchedule") {
      return <div />
    }

    return <PaymentSchedule />
  }

  const StepperView = ({ value }: any): JSX.Element => {
    const activeStep: Record<string, number> = {
      "personalDetails": 1,
      "paymentSchedule": 2
    }

    return (
      <Stepper
        items={["personal details", "Payment schedule"]}
        activeStep={activeStep[value]}
        justifyStart
      />
    )
  }

  return (
    <>
      <Card>
        <StepperView value={current?.value} />
        <Box>
          <Suspense fallback={<Loader forContent />}>
            {ViewPersonalDetails()}
            {ViewPaymentSchedule()}
          </Suspense>
          <Box margin="0 0 30px">
            <Footer
              handleNext={() => handleNext()}
              handleBack={xValue === "personalDetails" ? null : () => send("BACK")}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label="Next"
            />
          </Box>
        </Box>
        <Dialog
          action={() => showMain && showMain()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message="Are you sure you want to cancel?"
        />
      </Card>
    </>
  )
}

export default withContext(CreatePaymentScheduleView)
