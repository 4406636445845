import { fromUnixTime } from "utils/date"
import vmsConfig from "config/Dashboard/VMS"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "Block/ Unit No.",
        accessor: "unit.short_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Name",
        accessor: "contact_person",
        filterable: false,
        sortable: false
      },
      {
        Header: "Time",
        id: "eta",
        accessor: (original: Record<string, any>) => {
          return fromUnixTime(original?.eta_ms)
        },
        Cell: ({ original }: Record<string, any>) => {
          return fromUnixTime(original?.eta_ms)
        },
        filterable: false,
        sortable: false
      },
      {
        Header: "Status",
        id: "status",
        Cell: ({ original }: Record<string, any>) => {
          return vmsConfig.status.serviceProviders[original.status]
        },
        accessor: "status",
        filterable: false,
        sortable: false
      },
      {
        Header: "Purpose",
        id: "purpose",
        Cell: ({ original }: Record<string, any>) => {
          return original?.purpose === "" ||
            original?.purpose === "null" ||
            original?.purpose === null
            ? "N/A"
            : original?.purpose
        },
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
