import { SET_FEEDBACK_TABLE, SET_TAB_STATUS } from "../constants"

const initialState = {
  tableData: [],
  tabStatus: 0
}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_FEEDBACK_TABLE:
      return {
        ...state,
        tableData: action.table
      }
    case SET_TAB_STATUS:
      return {
        ...state,
        tabStatus: action.status
      }
    default:
      return state
  }
}
