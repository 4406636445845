import React from "react"

export type ReplyCtxType = {
  getFeedbacks: Function
  setReplyActive: Function
  handleImageUpload: Function
  handleFeedbackReply: Function
  removePhoto: Function
  getFeedback: Function
  setOpenDialog: Function
  setReplyDetails: Function
  setPhotos: Function
  setTabsFilter: Function
  setIsEditing: Function
  setActiveReply: Function
  handleActiveMessage: Function
  handleFeedbackReplyUpdate: Function
  showForm: Function
  isCreate: boolean
  openDialog: boolean
  attaching: boolean
  isEditing: boolean
  activeFeedback: Record<string, any>
  data: Record<string, any>
  replyDetails: Record<string, any>
  messages: Record<string, any>
  activeReply: Record<string, any>
  photos: any
  previewImage: any
}

export const ReplyContext = React.createContext<Partial<ReplyCtxType>>({})
