import React from "react"
import Box from "@material-ui/core/Box"
import { Link } from "react-router-dom"

import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "48px",
    color: theme.palette.body.dark
  },
  subtitle: {
    fontSize: "16px",
    color: "#204B82",
    "& span": {
      color: theme.palette.body.dark
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    }
  }
}))

const Header: React.FC = () => {
  const { title, subtitle } = styles()
  return (
    <Box marginBottom="30px">
      <Box className={title}>Sign up</Box>
      <Box className={subtitle}>
        <span>or</span> 
        {' '}
        <Link to="/login">Sign in to your account.</Link>
      </Box>
    </Box>
  )
}
export default Header
