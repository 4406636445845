import React from "react"

export type CtxType = {
  setOpenDialog: Function
  handleRegisterVehicle: Function
  setVehicleDetails: Function
  setPhotos: Function
  setAttaching: Function
  handleDocsUpload: Function
  removeDoc: Function
  attaching: boolean
  openDialog: boolean
  vehicleDetails: Record<string, any>
  photos: any
  localPhotos: any
}

export const Context = React.createContext<Partial<CtxType>>({})
