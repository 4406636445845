import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  section: {
    margin: "0 auto 40px auto",
    width: "50%"
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.dark,
    paddingBottom: "20px"
  }
}))

export default styles
