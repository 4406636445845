import * as React from "react"

const BikeTag: React.FC = () => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4291 0C15.1734 2.65169e-05 14.9281 0.105392 14.7473 0.292922C14.5665 0.480453 14.4649 0.734792 14.4648 1V1.18555C14.4649 1.29448 14.482 1.40267 14.5157 1.50586L15.4084 4.23828L10.5644 12H10.5041C10.1836 10.4161 9.11706 9.12776 7.71108 8.49219L9.21212 6H11.572V4H5.78627V6H6.94078L5.73354 8.00195C3.11034 8.032 0.964844 10.273 0.964844 13C0.964844 15.7455 3.13887 18 5.78627 18C8.10402 18 10.0589 16.2726 10.5098 14H11.0898C11.2509 14 11.4094 13.9582 11.5508 13.8784C11.6923 13.7985 11.8122 13.6832 11.8997 13.543L16.1975 6.65625L16.8812 8.75C15.449 9.60474 14.4648 11.171 14.4648 13C14.4648 15.7455 16.6389 18 19.2863 18C21.9337 18 24.1077 15.7455 24.1077 13C24.1077 10.381 22.1113 8.26682 19.6309 8.07227C19.5213 8.02648 19.4045 8.00198 19.2863 8C19.2542 8.00029 19.2221 8.00225 19.1902 8.00586C19.1579 8.01004 19.1259 8.0159 19.0942 8.02344C19.0415 8.03521 18.9898 8.05154 18.9397 8.07227C18.8609 8.07848 18.7877 8.10324 18.71 8.11328L16.7136 2H21.2148V0H15.4291ZM5.78627 10C7.04181 10 8.10274 10.8386 8.50209 12H5.78627V14H8.50209C8.10274 15.1614 7.04181 16 5.78627 16C4.19082 16 2.89342 14.6545 2.89342 13C2.89342 11.3455 4.19082 10 5.78627 10ZM19.3258 10.0039C20.9026 10.0265 22.1791 11.3595 22.1791 13C22.1791 14.6545 20.8817 16 19.2863 16C17.6908 16 16.3934 14.6545 16.3934 13C16.3934 12.0466 16.8324 11.2061 17.5027 10.6562L18.3728 13.3203L20.1997 12.6797L19.3258 10.0039Z"
        fill="white"
      />
    </svg>
  )
}

export default BikeTag
