import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import useSWR from "swr"
import Cookie from "js-cookie"

/** Component */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"

/** API */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Utils */
import { toNumeral } from "utils/helpers"

/** Styles */
import styles from "../styles"

/** Icons */
import { IconOutstandingPayment } from "../icons"

interface Props {
  currency: string
}

const OutstandingPayments: React.FC<Props> = ({ currency }) => {
  const { amount, iconContainerLarge } = styles()

  const fetchOutstandingPaymentsStats = async () =>
    BookkeepingAPI.getStatsOutstandingPayments({
      "condo_uid": Cookie.get("condoUID")
    })
  const { data, isValidating } = useSWR(
    "fetchOutstandingPaymentsStats",
    fetchOutstandingPaymentsStats
  )

  const oPayments = data?.data?._data !== undefined && data?.data?._data

  return (
    <Card>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0 30px"
        height="68px"
        bgcolor="#EDF1F5"
      >
        <CardTitle
          title="Outstanding Payments"
          icon={
            <Box bgcolor="#D3B474" className={iconContainerLarge}>
              {IconOutstandingPayment}
            </Box>
          }
        />
        {isValidating ? (
          <Box width="40px">
            <Loader dark />
          </Box>
        ) : (
          <Box className={amount}>
            <small>{currency}</small> 
            {' '}
            {toNumeral(oPayments?.amt)}
          </Box>
        )}
      </Box>
    </Card>
  )
}
export default OutstandingPayments
