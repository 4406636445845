import * as React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"
import ReactToPrint from "react-to-print"

import PageHeaderTitle from "components/PageHeaderTitle"

const useStyles = makeStyles((theme: Theme) => ({
  cta: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "uppercase",
    fontWeight: 500
  }
}))

const IconPrint = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0V4H3C1.35 4 0 5.35 0 7V14H4V18H16V14H20V7C20 5.35 18.65 4 17 4H16V0H4ZM6 2H14V4H6V2ZM3 6H17C17.552 6 18 6.448 18 7V12H16V10H4V12H2V7C2 6.448 2.448 6 3 6ZM16 7C15.7348 7 15.4804 7.10536 15.2929 7.29289C15.1054 7.48043 15 7.73478 15 8C15 8.26522 15.1054 8.51957 15.2929 8.70711C15.4804 8.89464 15.7348 9 16 9C16.2652 9 16.5196 8.89464 16.7071 8.70711C16.8946 8.51957 17 8.26522 17 8C17 7.73478 16.8946 7.48043 16.7071 7.29289C16.5196 7.10536 16.2652 7 16 7ZM6 12H14V16H6V12Z"
      fill="#09707B"
    />
  </svg>
)

interface PageTitleProps {
  title: string
  breadcrumbs: string
  refElement: any
  ele: any
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  breadcrumbs,
  refElement,
  ele
}) => {
  const { cta } = useStyles()
  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <PageHeaderTitle title={title} breadcrumbs={breadcrumbs} />

      <Box display="flex" alignItems="flex-start" data-test-id="print-dashboard">
        <ReactToPrint
          trigger={() => (
            <Box display="flex" alignItems="center" marginRight="25px">
              {IconPrint}
              <Box marginLeft="15px" className={cta}>
                PRINT DASHBOARD
              </Box>
            </Box>
          )}
          content={() => (refElement.current ? refElement.current : ele)}
        />

        {/* <Box display="flex" alignItems="center" onClick={() => setopenStatus(true)}>
          {IconManage}
          <Box marginLeft="15px" className={cta}>
            Manage dashboard widgets
          </Box>
        </Box> */}
      </Box>
    </Box>
  )
}

export default PageTitle
