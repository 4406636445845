import { Machine } from "xstate"

export const BookkeepingMachine = Machine({
  id: "bookkeeping",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_PAYMENT: "addPayment",
        EDIT_PAYMENT: "editPayment",
        CLOSE_PAYMENT: "closePayment",
        REFUND_DEPOSIT: "refundPayment",
        CANCEL_PAYMENT: "cancelPayment",
        REJECT_PAYMENT: "rejectPayment",
        VIEW_PAYMENT: "rejectPayment",
        CLOSE_REFUND: "closeRefund",
        CANCEL_REFUND: "cancelRefund",
        REJECT_REFUND: "rejectRefund"
      }
    },
    addPayment: {
      on: {
        BACK: "tableView"
      }
    },
    editPayment: {
      on: {
        BACK: "tableView"
      }
    },
    closePayment: {
      on: {
        BACK: "tableView"
      }
    },
    refundPayment: {
      on: {
        BACK: "tableView"
      }
    },
    cancelPayment: {
      on: {
        Back: "tableView"
      }
    },
    rejectPayment: {
      on: {
        Back: "tableView"
      }
    },
    viewDetails: {
      on: {
        Back: "tableView"
      }
    },
    closeRefund: {
      on: {
        Back: "tableView"
      }
    },
    cancelRefund: {
      on: {
        Back: "tableView"
      }
    },
    rejectRefund: {
      on: {
        Back: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_PAYMENT: "addPayment",
    CLOSE_PAYMENT: "closePayment",
    REFUND_PAYMENT: "refundPayment",
    CANCEL_PAYMENT: "cancelPayment",
    REJECT_PAYMENT: "rejectPayment",
    VIEW_DETAILS: "viewDetails",
    EDIT_PAYMENT: "editPayment",
    CLOSE_REFUND: "closeRefund",
    CANCEL_REFUND: "cancelRefund",
    REJECT_REFUND: "rejectRefund"
  }
})
