import React, { useState, useEffect } from "react"
import moment from "moment"

/** Context */
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  const [activeDate, setActiveDate] = useState({
    month: moment().format("MMM"),
    year: moment().format("YYYY")
  })

  const [selectedDate, setSelectedDate] = useState({
    from: moment(`${activeDate?.month} ${activeDate?.year}`)
      .startOf("month")
      .format("MMMM DD, YYYY"),
    to: moment(`${activeDate?.month} ${activeDate?.year}`)
      .endOf("month")
      .format("MMMM DD, YYYY")
  })

  useEffect(() => {
    setSelectedDate(() => ({
      from: moment(`${activeDate?.month} ${activeDate?.year}`)
        .startOf("month")
        .format("MMMM DD, YYYY"),
      to: moment(`${activeDate?.month} ${activeDate?.year}`)
        .endOf("month")
        .format("MMMM DD, YYYY")
    }))
  }, [activeDate])

  return (
    <Context.Provider
      value={{
        activeDate,
        selectedDate,
        setActiveDate,
        setSelectedDate
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
