import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import service from "services/Dashboard/Announcements/Post"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
import { toUnix } from "utils/date"
import { Context } from "context/Dashboard/Announcements/Posts/main/Context"
import tableHeaders from "./tableHeaders"

interface Props {
  showUpdate: Function
  activeTable: number
}

const TablePublicDocuments: React.FC<Props> = ({ showUpdate, activeTable }) => {
  const unixDateNow = toUnix(new Date().toString())

  const { setActiveData, setShowDocument, setDownloadData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchAnnouncementPost = async () => service.getAnnouncementPost()

  const { data, isValidating } = useSWR(
    `fetchAnnouncementPost-${activeTable}`,
    fetchAnnouncementPost,
    {
      revalidateOnFocus: true
    }
  )

  const tableData = data?.data?._data

  const FilteredData = () => {
    const active: Array<{}> = []
    const expired: Array<{}> = []

    tableData !== undefined &&
      tableData !== null &&
      Object.keys(tableData).map((i: any) => {
        if (
          unixDateNow >= tableData[i]?.start_date &&
          unixDateNow <= tableData[i]?.end_date
        ) {
          return active.push(tableData[i])
        }
        return expired.push(tableData[i])
      })

    return {
      active,
      expired
    }
  }

  const TablePosts = (tab: number) => {
    switch (tab) {
      case 0:
        return tableData
      case 1:
        return FilteredData()?.active
      case 2:
        return FilteredData()?.expired
      default:
        return tableData
    }
  }

  const viewFile = (activeData: Record<string, any>) => {
    setActiveData && setActiveData(activeData)
    setShowDocument && setShowDocument(true)
  }

  const showUpdateForm = (activeData: Record<string, any>) => {
    showUpdate && showUpdate(activeData)
  }

  /**
   * View
   */
  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={TablePosts(activeTable || 0) || []}
          columns={tableHeaders(viewFile, showUpdateForm)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          minRows={4}
          isFilterable
          action={(values: any) => {
            return (
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Created by": i?.createdBy,
                    "Title": i?.title,
                    "Start Date": i?.startDate,
                    "End Date": i?.endDate,
                    "Status": i?.status,
                    "Attachment": i?.attachment
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TablePublicDocuments
