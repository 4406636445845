import * as React from "react"

const View = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM2 2H16V16H2V2ZM9 5C5 5 3 9 3 9C3 9 5 13 9 13C13 13 15 9 15 9C15 9 13 5 9 5ZM9 7C10.104 7 11 7.896 11 9C11 10.104 10.104 11 9 11C7.896 11 7 10.104 7 9C7 7.896 7.896 7 9 7Z"
      fill="#09707B "
    />
  </svg>
)

export default View
