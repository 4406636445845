import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    "& > div": {
      width: "calc(100% /3)",
      borderRight: "1px solid #F2F2F2"
    }
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  }
}))

export default styles
