import React from "react"
import ContextMenu from "components/ContextMenu"
/** Components */
import StatusBlock from "components/Table/components/StatusBlock"
/** Config */
import ColorStatus from "utils/status"
import { status, type } from "config/Dashboard/Feedback"
/** Utils */
import { fromUnix, diffDays } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import CtxMenuFeedback from "../../components/ContextMenu"

interface DataProps {
  row: {
    _original: Record<string, string | number>
  }
}

const TableHeaderColumns = (apiCallback: string) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: DataProps): JSX.Element => {
          return (
            <ContextMenu setopenDetails={(): null => null} openDetails>
              <CtxMenuFeedback rowData={row._original} apiCallback={apiCallback} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Status",
        id: "status",
        sortable: false,
        filterable: false,
        accessor: (c: any) => checkValue(c?.status, status[c?.status]),
        Cell: ({ original }: any) =>
          checkValue(
            original.status,
            <StatusBlock
              status={status[original?.status]}
              color={ColorStatus.code(status[original?.status])}
            />
          ),
        width: 170
      },
      {
        Header: "Urgent",
        id: "isUrgent",
        accessor: (c: any) => checkValue(c.is_urgent, c.is_urgent ? "Yes" : "No"),
        Cell: ({ original }: any) =>
          checkValue(original.is_urgent, original.is_urgent ? "Yes" : "No"),
        width: 100
      },
      {
        Header: "Date",
        id: "postedDate",
        accessor: (c: any) =>
          checkDate(c?.posted_date_ms, fromUnix(c?.posted_date_ms)),
        Cell: ({ original }: any) =>
          checkDate(original?.posted_date_ms, fromUnix(original?.posted_date_ms)),
        width: 150
      },
      {
        Header: "Block/Unit No.",
        id: "feedbackUnitShortName",
        accessor: (c: any) => {
          if (c?.by_admin) {
            return "By Admin"
          }

          return checkValue(c?.feedback_unit_short_name)
        },
        Cell: ({ original }: any) => {
          if (original?.by_admin) {
            return "By Admin"
          }

          return checkValue(original?.feedback_unit_short_name)
        },
        width: 150
      },
      {
        Header: "Name",
        id: "feedbackOwnerAccountName",
        accessor: (c: any) => {
          return checkValue(c?.feedback_owner_account_name)
        },
        Cell: ({ original }: any) => {
          return checkValue(original?.feedback_owner_account_name)
        },
        width: 150
      },
      {
        Header: "Category",
        id: "feedbackType",
        accessor: (c: any) => checkValue(c?.feedback_type, type[c?.feedback_type]),
        Cell: ({ original }: any) =>
          checkValue(original?.feedback_type, type[original?.feedback_type])
      },
      {
        Header: "Assign To",
        id: "assignedToStaffAccountName",
        accessor: (c: any) => checkValue(c?.assigned_to_staff_account_name),
        Cell: ({ original }: any) =>
          checkValue(original?.assigned_to_staff_account_name)
      },
      {
        Header: "Remarks",
        id: "remarks",
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => {
          return checkValue(original?.remarks)
        }
      },
      {
        Header: "Aging (number of days)",
        id: "aging",
        accessor: (c: any) =>
          checkDate(c?.posted_date_ms, String(diffDays(c?.posted_date_ms))),
        Cell: ({ original }: any) =>
          checkDate(original?.posted_date_ms, diffDays(original?.posted_date_ms))
      }
    ]
  }
]

export default TableHeaderColumns
