import React from "react"
import Box from "@material-ui/core/Box"

const CoraIcon = (
  <svg
    width="114"
    height="33"
    viewBox="0 0 114 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.4998 10.9343C53.115 10.4632 52.6382 10.1015 52.0693 9.84914C51.5172 9.59678 50.8731 9.4706 50.137 9.4706C49.4009 9.4706 48.7149 9.6136 48.0792 9.89961C47.4602 10.1856 46.9165 10.5894 46.448 11.1109C45.9963 11.6156 45.6366 12.2213 45.3689 12.9279C45.118 13.6345 44.9925 14.4 44.9925 15.2244C44.9925 16.0655 45.118 16.8394 45.3689 17.546C45.6366 18.2358 45.9963 18.8415 46.448 19.363C46.8997 19.8677 47.4267 20.2631 48.029 20.5491C48.648 20.8351 49.3089 20.9781 50.0115 20.9781C50.8146 20.9781 51.5256 20.8099 52.1446 20.4734C52.7636 20.1369 53.2739 19.6658 53.6754 19.0602L56.9378 21.508C56.185 22.568 55.2313 23.3503 54.077 23.855C52.9226 24.3597 51.7347 24.612 50.5134 24.612C49.1248 24.612 47.845 24.3933 46.6739 23.9559C45.5028 23.5185 44.4906 22.896 43.6373 22.0885C42.7841 21.2641 42.1149 20.2715 41.6297 19.1107C41.1613 17.9498 40.9271 16.6544 40.9271 15.2244C40.9271 13.7943 41.1613 12.4989 41.6297 11.338C42.1149 10.1772 42.7841 9.19301 43.6373 8.38547C44.4906 7.5611 45.5028 6.9302 46.6739 6.49278C47.845 6.05537 49.1248 5.83666 50.5134 5.83666C51.0153 5.83666 51.534 5.88713 52.0693 5.98807C52.6214 6.07219 53.1568 6.21519 53.6754 6.41708C54.2108 6.61896 54.7211 6.88814 55.2062 7.22462C55.6914 7.5611 56.1264 7.97328 56.5112 8.46117L53.4998 10.9343Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M58.1065 15.2244C58.1065 13.7943 58.3407 12.4989 58.8092 11.338C59.2943 10.1772 59.9636 9.19301 60.8168 8.38547C61.67 7.5611 62.6822 6.9302 63.8533 6.49278C65.0244 6.05537 66.3043 5.83666 67.6929 5.83666C69.0815 5.83666 70.3614 6.05537 71.5325 6.49278C72.7036 6.9302 73.7158 7.5611 74.569 8.38547C75.4222 9.19301 76.0831 10.1772 76.5515 11.338C77.0367 12.4989 77.2793 13.7943 77.2793 15.2244C77.2793 16.6544 77.0367 17.9498 76.5515 19.1107C76.0831 20.2715 75.4222 21.2641 74.569 22.0885C73.7158 22.896 72.7036 23.5185 71.5325 23.9559C70.3614 24.3933 69.0815 24.612 67.6929 24.612C66.3043 24.612 65.0244 24.3933 63.8533 23.9559C62.6822 23.5185 61.67 22.896 60.8168 22.0885C59.9636 21.2641 59.2943 20.2715 58.8092 19.1107C58.3407 17.9498 58.1065 16.6544 58.1065 15.2244ZM62.1719 15.2244C62.1719 16.0655 62.2974 16.8394 62.5484 17.546C62.816 18.2358 63.1925 18.8415 63.6777 19.363C64.1628 19.8677 64.74 20.2631 65.4092 20.5491C66.0952 20.8351 66.8564 20.9781 67.6929 20.9781C68.5294 20.9781 69.2823 20.8351 69.9515 20.5491C70.6374 20.2631 71.223 19.8677 71.7081 19.363C72.1933 18.8415 72.5614 18.2358 72.8123 17.546C73.08 16.8394 73.2138 16.0655 73.2138 15.2244C73.2138 14.4 73.08 13.6345 72.8123 12.9279C72.5614 12.2213 72.1933 11.6156 71.7081 11.1109C71.223 10.5894 70.6374 10.1856 69.9515 9.89961C69.2823 9.6136 68.5294 9.4706 67.6929 9.4706C66.8564 9.4706 66.0952 9.6136 65.4092 9.89961C64.74 10.1856 64.1628 10.5894 63.6777 11.1109C63.1925 11.6156 62.816 12.2213 62.5484 12.9279C62.2974 13.6345 62.1719 14.4 62.1719 15.2244Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M79.9401 6.2909H86.8162C87.7197 6.2909 88.5729 6.38343 89.376 6.56849C90.1957 6.73673 90.9068 7.03115 91.5091 7.45174C92.1281 7.85552 92.6132 8.4107 92.9646 9.1173C93.3326 9.80708 93.5167 10.6735 93.5167 11.7166C93.5167 12.9784 93.1904 14.0551 92.538 14.9468C91.8855 15.8216 90.9319 16.3768 89.6771 16.6123L94.1943 24.1578H89.5014L85.7873 17.0161H83.855V24.1578H79.9401V6.2909ZM83.855 13.685H86.1638C86.5151 13.685 86.8832 13.6766 87.268 13.6597C87.6695 13.6261 88.0292 13.5504 88.3471 13.4326C88.6649 13.298 88.9242 13.0961 89.125 12.827C89.3425 12.5578 89.4512 12.1792 89.4512 11.6913C89.4512 11.2371 89.3592 10.8754 89.1752 10.6062C88.9912 10.337 88.757 10.1351 88.4725 10.0006C88.1881 9.84914 87.8619 9.74819 87.4938 9.69772C87.1258 9.64725 86.7661 9.62202 86.4147 9.62202H83.855V13.685Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M102.06 6.2909H105.297L113.027 24.1578H108.61L107.079 20.3724H100.178L98.6973 24.1578H94.3809L102.06 6.2909ZM103.566 11.4895L101.408 17.0413H105.749L103.566 11.4895Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M11.8814 27.3873C14.3344 28.4162 17.0622 28.5761 19.6174 27.8408C22.1726 27.1054 24.4033 25.5184 25.9435 23.3403C27.4837 21.1622 28.2419 18.5223 28.0937 15.8538C27.9454 13.1853 26.8996 10.6467 25.1277 8.65465C23.3558 6.66259 20.9632 5.33536 18.3425 4.89075C15.7217 4.44615 13.0285 4.91059 10.7048 6.20785C8.38113 7.50511 6.56496 9.55814 5.55434 12.03C4.54373 14.5019 4.3987 17.2459 5.14306 19.8116C5.18461 19.9718 5.25774 20.1221 5.35811 20.2534C5.45848 20.3847 5.58405 20.4944 5.72737 20.5761C5.87069 20.6577 6.02884 20.7095 6.19246 20.7285C6.35607 20.7476 6.52182 20.7334 6.67988 20.6868C6.83793 20.6402 6.98507 20.5621 7.11259 20.4573C7.2401 20.3525 7.34538 20.223 7.4222 20.0764C7.49901 19.9299 7.5458 19.7694 7.55978 19.6044C7.57376 19.4393 7.55465 19.2732 7.50358 19.1157C7.25613 18.2605 7.13026 17.3745 7.12969 16.4838C7.13283 14.484 7.77548 12.5383 8.9627 10.9339C10.1499 9.32962 11.8188 8.1518 13.7228 7.57445C15.6267 6.9971 17.6649 7.05084 19.5362 7.72772C21.4075 8.40461 23.0126 9.66875 24.1146 11.3334C25.2166 12.9981 25.757 14.9751 25.6559 16.9723C25.5549 18.9695 24.8177 20.8811 23.5534 22.4247C22.289 23.9682 20.5645 25.062 18.6346 25.5443C16.7046 26.0267 14.6716 25.8721 12.8357 25.1034C12.6866 25.0407 12.5266 25.0082 12.365 25.0078C12.2034 25.0073 12.0433 25.0388 11.8938 25.1006C11.7443 25.1623 11.6083 25.2531 11.4937 25.3676C11.3791 25.4822 11.288 25.6184 11.2257 25.7684C11.1634 25.9183 11.1311 26.0792 11.1307 26.2417C11.1302 26.4042 11.1615 26.5652 11.2229 26.7156C11.2843 26.8659 11.3746 27.0026 11.4885 27.1179C11.6025 27.2331 11.7379 27.3247 11.887 27.3873H11.8814Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M31.0391 20.7263C31.1976 20.7582 31.3607 20.7583 31.5193 20.7268C31.6778 20.6953 31.8287 20.6327 31.9631 20.5425C32.0976 20.4524 32.2131 20.3364 32.303 20.2014C32.393 20.0664 32.4555 19.9148 32.4872 19.7554C32.7004 18.6819 32.8079 17.5898 32.8081 16.4951C32.8107 13.2314 31.8501 10.0404 30.0481 7.32605C28.2461 4.61171 25.6836 2.49614 22.6852 1.24721C19.6868 -0.00172286 16.3872 -0.327836 13.2044 0.310161C10.0215 0.948157 7.09849 2.52157 4.80544 4.83117C1.93088 7.72678 0.228815 11.5932 0.029685 15.6798C-0.169445 19.7664 1.14857 23.7816 3.72784 26.946C6.30712 30.1103 9.96364 32.198 13.9875 32.8038C18.0114 33.4095 22.1156 32.4901 25.5033 30.224C25.7749 30.0417 25.9633 29.7584 26.0272 29.4363C26.091 29.1143 26.025 28.78 25.8437 28.5069C25.6624 28.2338 25.3806 28.0443 25.0604 27.9801C24.7402 27.9159 24.4077 27.9822 24.1361 28.1645C21.4537 29.9595 18.2354 30.7637 15.0295 30.4399C11.8237 30.1161 8.82863 28.6845 6.55477 26.389C4.28091 24.0935 2.86895 21.0762 2.55947 17.8511C2.24999 14.6261 3.06215 11.3929 4.85756 8.70253C6.65297 6.01215 9.32053 4.03106 12.4057 3.09684C15.4908 2.16262 18.8027 2.33307 21.7768 3.57915C24.751 4.82523 27.2035 7.06984 28.7164 9.93048C30.2293 12.7911 30.709 16.0908 30.0737 19.2672C30.0096 19.5894 30.0752 19.924 30.2562 20.1976C30.4372 20.4711 30.7188 20.6613 31.0391 20.7263Z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M16.4044 23.6471C15.1928 23.647 14.0013 23.3356 12.9429 22.7425C11.8845 22.1494 10.9944 21.2942 10.3569 20.2581C9.71938 19.222 9.35568 18.0393 9.30027 16.8221C9.24487 15.605 9.49958 14.3938 10.0403 13.3034C10.581 12.2131 11.3897 11.2797 12.3897 10.5918C13.3898 9.90394 14.548 9.48438 15.7546 9.37294C16.9611 9.2615 18.176 9.46186 19.2839 9.95503C20.3918 10.4482 21.3561 11.2178 22.0853 12.1909C22.288 12.4614 22.3756 12.8018 22.3288 13.1373C22.282 13.4727 22.1045 13.7757 21.8355 13.9796C21.5666 14.1835 21.228 14.2716 20.8944 14.2245C20.5609 14.1774 20.2595 13.999 20.0568 13.7285C19.4807 12.9595 18.6791 12.3918 17.7651 12.1053C16.851 11.8188 15.8706 11.828 14.9621 12.1316C14.0535 12.4352 13.2626 13.0179 12.7009 13.7975C12.1392 14.5771 11.8349 15.5144 11.8311 16.4772C11.8272 17.4399 12.1239 18.3796 12.6794 19.1638C13.2348 19.948 14.021 20.537 14.9271 20.848C15.8332 21.159 16.8135 21.1761 17.7298 20.897C18.6461 20.618 19.4522 20.0567 20.0345 19.2925C20.2454 19.0415 20.5445 18.8819 20.8695 18.8471C21.1945 18.8123 21.5203 18.9048 21.7792 19.1054C22.038 19.3061 22.2099 19.5994 22.2591 19.9243C22.3084 20.2492 22.2311 20.5807 22.0434 20.8497C21.3818 21.7218 20.5284 22.4282 19.5502 22.9135C18.5719 23.3988 17.4952 23.6499 16.4044 23.6471Z"
      fill="url(#paint6_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="467.68"
        y1="-27.991"
        x2="1052.76"
        y2="1386.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F727C" />
        <stop offset="1" stopColor="#183A66" />
      </linearGradient>
    </defs>
  </svg>
)

const PreLoader: React.FC = () => {
  return (
    <>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {CoraIcon}
      </Box>
    </>
  )
}
export default PreLoader
