import React from "react"
import Box from "@material-ui/core/Box"
/** Components */
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"
import StatusBlock from "components/Table/components/StatusBlock"
import Checkbox from "components/Forms/Checkbox"
import Image from "material-ui-image"
/** Config */
import vmsConfig from "config/Dashboard/VMS"
import ColorStatus from "utils/status"
import { fromUnix, fromUnixTime } from "utils/date"
/** Styles */
import styles from "./styles"

interface DetailsProps {
  data: Record<string, any> | undefined
}

const DetailsDelivery: React.FC<DetailsProps> = ({ data }) => {
  const classes = styles()

  function StatusType() {
    const type =
      data?.vms_type === 1 || data?.vms_type === 3
        ? vmsConfig.status.visitor
        : vmsConfig.status.delivery

    const currentStatus = data?.vms?.status

    return (
      <StatusBlock
        status={type[currentStatus]}
        color={ColorStatus.code(type[currentStatus])}
      />
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box width="60%" textAlign="center">
          {StatusType()}
        </Box>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.title}>Personal Details</Box>

        <Box className={classes.twoCol}>
          <Box>
            <Label label="block/unit no." />
            <Value val={data?.vms?.for_admin ? "Admin" : data?.unit?.short_name} />
          </Box>
          <Box>
            <Label label="name" />
            <Value val={data?.vms?.contact_person} />
          </Box>
        </Box>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.title}>Visitor's Inforrmation</Box>
        <Box className={classes.twoCol}>
          <Box>
            <Label label="name" />
            <Value val={data?.vms?.name} />
          </Box>
          <Box>
            <Label label="OTP" />
            <Value val={data?.vms?.otp} />
          </Box>
        </Box>
        <Box className={classes.twoCol}>
          <Box>
            <Label label="start date" />
            <Value val={fromUnix(data?.vms?.start_date_ms)} />
          </Box>
          <Box>
            <Label label="end date" />
            <Value val={fromUnix(data?.vms?.end_date)} />
          </Box>
        </Box>
        {data?.vms?.vehicle_no && (
          <Box className={classes.block}>
            <Label label="vehicle number" />
            <Value val={data?.vms?.vehicle_no} />
          </Box>
        )}

        <Box className={classes.block}>
          <Label label="remarks" />
          <Value val={data?.vms?.remarks} />
        </Box>

        {data?.vms?.allow_condo_to_receive && (
          <Box className={classes.block}>
            <Checkbox
              label="Receive on behalf of resident"
              value={data?.vms?.allow_condo_to_receive}
              onChange={() => null}
            />
          </Box>
        )}
      </Box>

      {data?.vms?.status === 6 && (
        <>
          <Box className={classes.content}>
            <Box className={classes.twoCol}>
              <Box>
                <Label label="date collected" />
                <Value
                  val={fromUnix(
                    data?.vms?.status_update_time?.status_changes?.slice(-1)[0]
                      ?.updated_at
                  )}
                />
              </Box>
              <Box>
                <Label label="time collected" />
                <Value
                  val={fromUnixTime(
                    data?.vms?.status_update_time?.status_changes?.slice(-1)[0]
                      ?.updated_at
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.block}>
              <Label label="collection point" />
              <Value
                val={
                  data?.vms?.collection_point === ""
                    ? "N/A"
                    : data?.vms?.collection_point
                }
              />
            </Box>
          </Box>
        </>
      )}

      <Box className={classes.content}>
        {data?.vms?.collection_photo?.photos !== null && (
          <Box className={classes.block}>
            <Label label="photo of collection" />
            <Image
              src={data?.vms?.collection_photo?.photos[0]?.url}
              aspectRatio={1 / 1}
            />
          </Box>
        )}
        {data?.vms?.signature_photo?.photos !== null && (
          <Box className={classes.block}>
            <Label label="signature of collector" />
            <Image
              src={data?.vms?.signature_photo?.photos[0]?.url}
              aspectRatio={1 / 1}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default DetailsDelivery
