import React from "react"

import VMSDeliveriesProvider, {
  VMSDeliveriesProviderProps
} from "context/Dashboard/VMS/categories/vmsDeliveries/Provider"
import VMSDeliveriesView from "context/Dashboard/VMS/categories/vmsDeliveries/View"

const VMSDeliveriesMain: React.FC<VMSDeliveriesProviderProps> = ({
  showAddForm
}): JSX.Element => (
  <VMSDeliveriesProvider showAddForm={showAddForm}>
    <VMSDeliveriesView />
  </VMSDeliveriesProvider>
)

export default VMSDeliveriesMain
