import React from "react"

import CreateUserProvider from "context/Dashboard/UnitManagement/forms/autoCreateUser/Provider"
import CreateUserView from "context/Dashboard/UnitManagement/forms/autoCreateUser/View"

interface CreateUserProps {
  data?: Record<string, any>
}

const CreateUser: React.FC<CreateUserProps> = ({ data }) => (
  <CreateUserProvider data={data}>
    <CreateUserView />
  </CreateUserProvider>
)

export default CreateUser
