import { Machine } from "xstate"

export const SettingsVMSMachine = Machine({
  id: "SettingsVMSMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_CATEGORY: "addCategory",
        UPDATE_CATEGORY: "updateCategory"
      }
    },
    addCategory: {
      on: {
        BACK: "tableView"
      }
    },
    updateCategory: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_CATEGORY: "addCategory",
    UPDATE_CATEGORY: "updateCategory"
  }
})
