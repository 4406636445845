import React from "react"

export type CtxType = {
  viewSettings: any
  dateRange: any
  dateOptions: Record<string, any>
  setViewSettings: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
