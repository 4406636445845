import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    borderBottom: "1px solid #F0F3F7",
    padding: "10px 10px 10px 20px",
    width: "100%",
    "& i": {
      marginRight: "15px",
      paddingTop: "3px"
    },
    "&.disabled": {
      color: "rgba(0, 0, 0, 0.26);",
      cursor: "not-allowed"
    }
  },
  innerList: {
    padding: "10px",
    "& > div": {
      paddingLeft: "55px"
    }
  },
  collapseControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& i": {
      paddingTop: "3px"
    },
    "& > div": {
      display: "flex",
      alignItems: "center"
    }
  }
}))

export default styles
