import React, { useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"

// components
import Label from "components/Forms/Label"

// service
import CommonAPI from "services/Settings/AccountManagement/Staff"

interface StaffProps {
  value: string
  onChange?: Function
  onBlur?: Function
  error?: boolean
  helperText?: string
  label?: string
  disabled?: boolean
}

const Staff: React.FC<StaffProps> = ({
  value,
  onChange,
  onBlur,
  helperText,
  error = false,
  label,
  disabled
}) => {
  const [staffs, setStaffs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchStaff = async () => {
    setIsLoading(true)
    try {
      const { data } = await CommonAPI.getStaff()
      setIsLoading(false)
      if (data._data !== null) {
        setStaffs(data._data)
        return data._data
      }
      setStaffs([])
      return data
    } catch (e) {
      setIsLoading(false)
      return e
    }
  }

  React.useEffect(() => {
    fetchStaff()
  }, [])

  return (
    <>
      <Box marginTop="10px">
        {label && <Label label={label} />}
        <FormControl variant="outlined" error={error}>
          {isLoading ? (
            <Select
              disabled={disabled}
              displayEmpty
              value={value}
              onChange={(e) => onChange && onChange(e)}
              onBlur={(e) => onBlur && onBlur(e)}
              error={error}
              name="staffName"
            >
              <MenuItem value="" disabled>
                Select Name
              </MenuItem>
            </Select>
          ) : (
            <>
              <Select
                displayEmpty
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}
                onBlur={(e) => onBlur && onBlur(e)}
                error={error}
                name="staffName"
              >
                <MenuItem value="" disabled>
                  Select Name
                </MenuItem>
                {staffs.map((staff: any) => {
                  return (
                    <MenuItem value={staff?.account_uid}>
                      {staff?.account_name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>{helperText}</FormHelperText>
            </>
          )}
        </FormControl>
      </Box>
    </>
  )
}
export default Staff
