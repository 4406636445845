import * as React from "react"
import { Context } from "./Context"

function withContext(Component: any) {
  return function contextComponent(props: any) {
    return (
      <Context.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </Context.Consumer>
    )
  }
}

export default withContext
