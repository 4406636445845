import * as React from "react"

const Forms: React.FC = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0H2C0.894531 0 0 0.894531 0 2V18C0 19.1055 0.894531 20 2 20H14C15.1055 20 16 19.1055 16 18V6L10 0ZM5 18H3V16H5V18ZM5 15H3V13H5V15ZM5 12H3V10H5V12ZM13 18H7V16H13V18ZM13 15H7V13H13V15ZM13 12H7V10H13V12ZM9 7V1.5L14.5 7H9Z"
        fill="white"
      />
    </svg>
  )
}

export default Forms
