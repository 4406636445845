import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function resetCode(userUID: string) {
  return request({
    url: API.RESET_CODE,
    method: "POST",
    data: {
      "user_detail_uid": userUID
    },
    headers: headers()
  })
}

function createOwner(data: Object) {
  return request({
    url: API.CREATE_OWNER,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateOwner(data: Object, uid: number) {
  return request({
    url: `${API.UPDATE_OWNER}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function createTenant(data: Object) {
  return request({
    url: API.CREATE_TENANT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateTenant(data: Object, uid: number) {
  return request({
    url: `${API.UPDATE_TENANT}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function getOwners() {
  return request({
    url: API.LIST_OWNERS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function getResidents(params?: Record<string, any>) {
  return request({
    url: API.LIST_RESIDENTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function getResidentsByUnit(params: Record<string, any>) {
  return request({
    url: API.RESIDENTS_BY_UNITID,
    method: "GET",
    params: params,
    headers: headers()
  })
}

function getAccountRequests(params?: Record<string, any>) {
  return request({
    url: API.ACCOUNTS_REQUESTS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getChangeAddressRequests(params: Record<string, any>) {
  return request({
    url: API.CHANGE_ADDRESS_REQUESTS,
    method: "GET",
    params: params,
    headers: headers()
  })
}

function approveChangeAddressRequests(data: Record<string, any>) {
  return request({
    url: `${API.CHANGE_ADDRESS_REQUEST}/approve`,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function rejectAccountRequest(uid: string) {
  return request({
    url: API.REJECT_ACCOUNT_REQUEST,
    method: "POST",
    data: {
      "_uid": uid
    },
    headers: headers()
  })
}

function getPresigned() {
  return request({
    url: API.PRESIGN_URL,
    method: "GET",
    headers: {
      "Content-Type": "image/jpeg, image/png"
    }
  })
}

function getArchivedResidents(params?: Record<string, any>) {
  return request({
    url: API.LIST_ARCHIVED_RESIDENTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function archivedResident(uid: string) {
  return request({
    url: API.ARCHIVED_RESIDENT,
    method: "POST",
    data: {
      "_uid": uid
    },
    headers: headers()
  })
}

const UnitManagementAPI = {
  createOwner,
  createTenant,
  getOwners,
  getResidents,
  getPresigned,
  getResidentsByUnit,
  getAccountRequests,
  getChangeAddressRequests,
  approveChangeAddressRequests,
  updateOwner,
  updateTenant,
  resetCode,
  rejectAccountRequest,
  archivedResident,
  getArchivedResidents
}

export default UnitManagementAPI
