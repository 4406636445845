import React from "react"
import { StateValue } from "xstate"

export type CtxType = {
  handleUpdateStatusBooking: Function
  setactiveTable: Function
  setActiveFilter: Function
  setActiveMenu: Function
  showAddBooking: Function
  showApproveForm: Function
  showEditForm: Function
  showUpdateStatusForm: Function
  showApproveBooking: Function
  showTable: Function
  showBookingDetails: Function
  setShowDetails: Function
  setShowUpdateStatus: Function
  activeFilter: number
  activeTable: number
  facilities: Record<string, any>
  categories: Record<string, any>
  activeMenu: Record<string, any>
  activeData: Record<string, any>
  xValue: StateValue
  isFormSubmitting: boolean
  isLoading: boolean
  isFacilityLoading: boolean
  showDetails: boolean
  showUpdateStatus: boolean
}
export const Context = React.createContext<Partial<CtxType>>({})
