import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
// import gql from "graphql-tag"
// import { useQuery } from "@apollo/react-hooks"

// context
import { Context } from "context/Dashboard/Dashboard/main/Context"

/**
 * Hooks
 */

// components
import Table from "components/Table"
import Loader from "components/Loader"
import {
  ExpiringSoonContracts,
  ExpiringSoonLicensePermits,
  ExpiringSoonOthers,
  ExpiringSoonCyclicService
} from "./hooks"
import Header from "./component/Header"

import TableHeaderColumns from "./config/TableHeaderColumns"

const ExpiringSoon: React.FC = () => {
  const [value, setValue] = useState(0)
  const { dateRange } = useContext(Context)

  const { expiringSoonList, isValidating } = ExpiringSoonContracts(dateRange)
  const {
    expiringSoonListPermits,
    isValidatingPermits
  } = ExpiringSoonLicensePermits(dateRange)
  const { expiringSoonListOthers, isValidatingOthers } = ExpiringSoonOthers(
    dateRange
  )
  const { expiringSoonListCyclic, isValidatingCyclic } = ExpiringSoonCyclicService(
    dateRange
  )

  function TableData(query: number) {
    switch (query) {
      case 0:
        return expiringSoonList
      case 1:
        return expiringSoonListPermits
      case 2:
        return expiringSoonListCyclic
      default:
        return expiringSoonListOthers
    }
  }

  if (
    isValidating ||
    isValidatingPermits ||
    isValidatingOthers ||
    isValidatingCyclic
  ) {
    return <Loader dark />
  }

  return (
    <>
      <Box>
        <Card>
          <Header value={value} handleChange={setValue} />
          <Box>
            <Table
              data={TableData(value) || []}
              columns={TableHeaderColumns}
              showPagination={false}
              minRows={3}
            />
            <Box display="flex" justifyContent="flex-end" padding="10px 10px">
              <Button
                color="primary"
                size="large"
                component={Link}
                to="/property-management/estate-matters/management-documents"
              >
                <Box fontWeight="600">VIEW ALL</Box>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  )
}

export default ExpiringSoon
