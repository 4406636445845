import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

// components
import ForgotPasswordCard from "./ForgotPasswordCard"
import Brand from "./Brand"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh"
  },
  section: {
    display: "flex",
    height: "100%",
    flex: "1"
  }
}))

const ForgotPassword: React.FC = () => {
  const { container, section } = useStyles()

  return (
    <Box className={container}>
      <Box className={section}>
        <Brand />
      </Box>
      <Box className={section} justifyContent="center" alignItems="center">
        <ForgotPasswordCard />
      </Box>
    </Box>
  )
}

export default withRouter(ForgotPassword)
