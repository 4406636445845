import { Machine } from "xstate"

export const AddApplicationsMachine = Machine({
  id: "addApplication",
  initial: "personalDetails",
  states: {
    personalDetails: {
      on: {
        NEXT: "applicationDetails"
      }
    },
    applicationDetails: {
      on: {
        BACK: "personalDetails",
        NEXT: "supportingDocs"
      }
    },
    supportingDocs: {
      on: {
        BACK: "applicationDetails",
        NEXT: "receipt"
      }
    },
    receipt: {
      on: {
        BACK: "receipt"
      }
    }
  }
})
