import React, { useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles, Theme } from "@material-ui/core/styles"

// components
import Label from "components/Forms/Label"

// service
import CommonAPI from "services/Settings/AccountManagement/Staff"

const styles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    borderBottom: "1px solid #F0F3F7",
    padding: "10px 10px 10px 20px",
    width: "100%",
    "& i": {
      marginRight: "15px",
      paddingTop: "3px",
      width: "25px",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}))

interface StaffProps {
  value: string
  onChange?: Function
  onBlur?: Function
  error?: boolean
  helperText?: string
  label?: string
  roleId?: string
  type?: string /* 1. select 2. context */
  action?: Function
  disabled?: boolean
}

const Staff: React.FC<StaffProps> = ({
  value,
  onChange,
  onBlur,
  helperText,
  error = false,
  label,
  roleId,
  type = "select",
  action,
  disabled
}) => {
  const { listItem } = styles()
  const [staffs, setStaffs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchStaff = async () => {
    setIsLoading(true)
    if (roleId === "") {
      return null
    }

    try {
      const { data } = await CommonAPI.getStaff(roleId)
      setIsLoading(false)

      if (data._data !== null) {
        setStaffs(data._data)
        return data._data
      }
      setStaffs([])
      return data
    } catch (e) {
      setIsLoading(false)
      return e
    }
  }

  function noStaff() {
    if (staffs.length !== 0) {
      return null
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <h2>No Staffs found.</h2>
      </div>
    )
  }

  React.useEffect(() => {
    fetchStaff()
  }, [roleId])

  return (
    <>
      {noStaff()}
      {type === "select" && (
        <Box marginTop="10px">
          {label && <Label label={label} />}
          <FormControl variant="outlined" error={error}>
            {isLoading ? (
              <Select
                disabled={disabled}
                displayEmpty
                value={value}
                onChange={(e) => onChange && onChange(e)}
                onBlur={(e) => onBlur && onBlur(e)}
                error={error}
                name="staffName"
              >
                <MenuItem value="" disabled>
                  Select Name
                </MenuItem>
              </Select>
            ) : (
              <>
                <Select
                  displayEmpty
                  value={value}
                  onChange={(e) => onChange && onChange(e)}
                  onBlur={(e) => onBlur && onBlur(e)}
                  error={error}
                  name="staffName"
                >
                  <MenuItem value="" disabled>
                    Select Name
                  </MenuItem>
                  {staffs.map((staff: any) => {
                    return (
                      <MenuItem value={staff?.account_uid}>
                        {staff?.account_name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{helperText}</FormHelperText>
              </>
            )}
          </FormControl>
        </Box>
      )}
      {type === "context" && (
        <>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              Loading ....
            </Box>
          ) : (
            <List component="div" disablePadding style={{ overflow: "auto" }}>
              {staffs.map((staff: any) => {
                return (
                  <ListItem
                    className={listItem}
                    button
                    onClick={() => action && action(staff?.account_uid)}
                  >
                    {staff?.account_name}
                  </ListItem>
                )
              })}
            </List>
          )}
        </>
      )}
    </>
  )
}
export default Staff
