import React from "react"
import Button from "@material-ui/core/Button"

import ContextMenu from "components/ContextMenu"
import { checkValue } from "utils/helpers"
import ContextItems from "./ContextMenu"

const tableHeaders = (createAccount: Function) => {
  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any): JSX.Element => {
            return (
              <ContextMenu>
                <ContextItems data={row?.original} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: "",
      columns: [
        {
          Header: "Block/Unit No",
          id: "unitNumber",
          accessor: (c: any) => checkValue(c?.unit?.short_name),
          Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
          width: 150
        },
        {
          Header: "Name",
          id: "name",
          accessor: (c: any) => checkValue(c?.name),
          Cell: ({ original }: any) => checkValue(original?.name)
        },
        {
          Header: "Email",
          id: "email",
          accessor: (c: any) => checkValue(c?.email),
          Cell: ({ original }: any) => checkValue(original?.email)
        },
        {
          Header: "Mobile No.",
          id: "mobileNumber",
          accessor: (c: any) => checkValue(c?.mobile_no),
          Cell: ({ original }: any) => checkValue(original?.mobile_no)
        }
      ]
    },
    {
      fixed: "right",
      disableFilters: true,
      columns: [
        {
          Header: "Action",
          Cell: (row: any): JSX.Element => {
            return (
              <Button
                color="primary"
                onClick={() => createAccount(row?.original)}
                disabled={row?.original?.user_detail_public_uid !== ""}
              >
                CREATE ACCOUNT
              </Button>
            )
          },
          filterable: false
        }
      ]
    }
  ]
}

export default tableHeaders
