import React, { lazy, Suspense } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Layout */
import WithHeader from "layout/WithHeader"

/** Component */
import Loader from "components/Loader"
import PageHeaderTitle from "components/PageHeaderTitle"
import Dialog from "components/Dialog"
import Stepper from "components/Stepper"
import Footer from "components/Forms/Footer/new"

/** Machine */
import { RefundPaymentMachine } from "machines/Dashboard/Bookkeeping/refundPayment"

/** Context */
import { isFromAvailableBalance } from "utils/helpers"
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Utils */

const PayerInfo = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/CloseRefund/PayerInfo")
)

const ModePayment = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/CloseRefund/Details")
)

const Acknowledgement = lazy(() =>
  import("pages/Dashboard/Bookkeeping/components/CloseRefund/Acknowledgement")
)

export const refSubmit = React.createRef<HTMLDivElement>()
export const refPrintBtn = React.createRef<HTMLButtonElement>()

const RefundView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  setShowPayment,
  paymentDetails,
  handleRefundPayment,
  handleRefund,
  handleRefundBalance,
  submitting
}) => {
  const [current, send] = useMachine(RefundPaymentMachine)
  const xValue = current.value
  const { remarks, paymentType, amt, tax, refundTo, flags } = paymentDetails

  const fromBalance = +isFromAvailableBalance(flags) === 1

  /** Methods */
  const handleNext = () => {
    if (xValue === "payerInfo") {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (
      xValue === "details" &&
      remarks !== "" &&
      amt !== "" &&
      tax !== "" &&
      refundTo !== "" &&
      refundTo !== "0" &&
      !fromBalance
    ) {
      if (paymentType !== 3) {
        handleRefundPayment().then(() => send("NEXT"))
      } else {
        handleRefund().then(() => send("NEXT"))
      }
    } else {
      refSubmit.current?.click()
    }

    if (xValue === "details" && fromBalance && remarks !== "") {
      handleRefundBalance().then(() => send("NEXT"))
    }
  }

  const ViewPayerInfo = () => {
    if (current.value !== "payerInfo") {
      return null
    }

    return (
      <Suspense fallback={<Loader forContent />}>
        <PayerInfo />
      </Suspense>
    )
  }

  const ViewDetails = () => {
    if (current.value !== "details") {
      return null
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <ModePayment />
      </Suspense>
    )
  }

  const ViewAcknowledgement = () => {
    if (current.value !== "acknowledgement") {
      return null
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <Acknowledgement />
      </Suspense>
    )
  }

  const CurrentStep = (step: any) => {
    switch (step) {
      case "payerInfo":
        return 1
      case "details":
        return 2
      case "acknowledgement":
        return 3
      default:
        break
    }
    return step
  }

  const labelValue = () => {
    if (xValue === "acknowledgement") {
      return "Print"
    }

    if (submitting) {
      return "Submitting..."
    }

    return "Next"
  }

  return (
    <WithHeader>
      <PageHeaderTitle
        title="Refund"
        breadcrumbs="property management / bookkeeping / refund"
        backAction={() => setShowPayment()}
      />
      <Card>
        <Stepper
          items={["Payer's Information", "Payment Details", "Acknowledgement"]}
          activeStep={CurrentStep(xValue) || 1}
          justifyStart
        />
        <Box>
          <div>
            {ViewPayerInfo()}
            {ViewDetails()}
            {ViewAcknowledgement()}
          </div>
          <Box margin="30px 0">
            <Footer
              handleNext={() =>
                xValue === "acknowledgement"
                  ? refPrintBtn.current?.click()
                  : handleNext()}
              handleBack={xValue === "payerInfo" ? null : () => send("BACK")}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label={labelValue()}
              forAcknowledge={xValue === "acknowledgement"}
              handlePrint={() => refPrintBtn.current?.click()}
              handleSendEmail={() => null}
              handleDone={() => setShowPayment()}
              disabled={submitting}
            />
          </Box>
        </Box>
        <Dialog
          action={() => setShowPayment()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message="Are you sure you want to cancel?"
        />
      </Card>
    </WithHeader>
  )
}

export default withContext(RefundView)
