import React from "react"

import ApplicationsProvider from "context/Dashboard/Applications/main/Provider"
import ApplicationsView from "context/Dashboard/Applications/main/View"

export default (): JSX.Element => (
  <ApplicationsProvider>
    <ApplicationsView />
  </ApplicationsProvider>
)
