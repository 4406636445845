import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dropzone from "react-dropzone"

import Loader from "components/Loader"
import styles from "./styles"

// components

const IconUpload = (
  <svg
    width="64"
    height="48"
    viewBox="0 0 64 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 0C25.7747 0 20.535 3.33199 17.1458 8.03646C7.69455 8.14371 0 15.8576 0 25.3333C0 34.8754 7.79129 42.6667 17.3333 42.6667H24V37.3333H17.3333C10.6727 37.3333 5.33333 31.994 5.33333 25.3333C5.33333 18.6727 10.6727 13.3333 17.3333 13.3333C17.4266 13.3333 17.6439 13.352 18 13.3698L19.6302 13.4531L20.4427 12.0365C22.747 8.03294 27.0344 5.33333 32 5.33333C38.6787 5.33333 44.1439 10.2013 45.151 16.5729L45.5573 19.1354L48.1302 18.8021C48.7258 18.7245 49.1064 18.6667 49.3333 18.6667C54.5185 18.6667 58.6667 22.8149 58.6667 28C58.6667 33.1851 54.5185 37.3333 49.3333 37.3333H40V42.6667H49.3333C57.4015 42.6667 64 36.0682 64 28C64 20.0257 57.5388 13.5365 49.599 13.3854C47.2798 5.71087 40.4056 0 32 0ZM32 16L21.3333 26.6667H29.3333V48H34.6667V26.6667H42.6667L32 16Z"
      fill="#777E86"
    />
  </svg>
)

const IconPdf = (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0C1.35862 0 0 1.35862 0 3V27C0 28.6414 1.35862 30 3 30H21C22.6414 30 24 28.6414 24 27V9L15 0H3ZM3 3H13.5V10.5H21V27H3V3ZM11.748 10.5C10.923 10.5 10.3051 11.0602 10.1836 12.0352C10.0636 13.0087 10.5462 14.711 11.3877 16.292C11.0262 17.387 10.6651 18.3595 10.1836 19.333C8.61909 19.819 7.17572 20.5478 6.57422 21.2783C5.61272 22.3718 6.09398 23.1023 6.33398 23.4668C6.57548 23.8313 6.93647 24 7.41797 24C7.65947 24 7.9001 23.9516 8.1416 23.8301C9.1046 23.4656 10.0643 22.0052 11.0273 20.3027C11.8688 20.0597 12.7112 19.8178 13.5527 19.6963C14.3942 20.6683 15.2394 21.2785 15.9609 21.5215C16.8024 21.7645 17.5242 21.3979 17.8857 20.5459C18.1257 19.8154 18.0038 19.2097 17.4023 18.8438C16.6793 18.3577 15.4762 18.356 14.0332 18.4775C13.5517 17.7485 13.0734 17.0196 12.7119 16.2891C13.3134 14.4651 13.5525 12.8856 13.3125 11.9121C13.071 11.0601 12.573 10.5 11.748 10.5Z"
      fill="#646464"
    />
  </svg>
)

const IconImage = (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33329 0.333344C1.87588 0.333344 0.666626 1.54259 0.666626 3.00001V19C0.666626 20.4574 1.87588 21.6667 3.33329 21.6667H24.6666C26.124 21.6667 27.3333 20.4574 27.3333 19V3.00001C27.3333 1.54259 26.124 0.333344 24.6666 0.333344H3.33329ZM3.33329 3.00001H24.6666V19H3.33329V3.00001ZM11.3333 5.66668C10.9797 5.66668 10.6405 5.80715 10.3905 6.0572C10.1404 6.30725 9.99996 6.64639 9.99996 7.00001C9.99996 7.35363 10.1404 7.69277 10.3905 7.94282C10.6405 8.19287 10.9797 8.33334 11.3333 8.33334C11.6869 8.33334 12.0261 8.19287 12.2761 7.94282C12.5262 7.69277 12.6666 7.35363 12.6666 7.00001C12.6666 6.64639 12.5262 6.30725 12.2761 6.0572C12.0261 5.80715 11.6869 5.66668 11.3333 5.66668ZM17.3333 9.66668L12.6666 15L9.33329 11.6667L5.70308 16.3333H22.3333L17.3333 9.66668Z"
      fill="#454B57"
    />
  </svg>
)

const IconDelete = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L6 1H0V3H1.10938L2.90234 18.2344C3.01959 19.234 3.882 20 4.88867 20H13.1113C14.118 20 14.9804 19.234 15.0977 18.2344L16.8906 3H18V1H16H12L11 0H7ZM3.12305 3H4H14.877L13.1113 18H4.88867L3.12305 3ZM6 5V15C6 15.552 6.448 16 7 16H8V5H6ZM10 5V16H11C11.552 16 12 15.552 12 15V5H10Z"
      fill="#777E86"
    />
  </svg>
)

interface UploadBoxProps {
  title?: string
  onDrop?: Function
  removePhoto: Function | undefined
  files: Array<any>
  attaching: boolean | undefined
  acceptedFile?: string
  note?: string
  hasTitle?: boolean
  hasError?: boolean
  hasDelete?: boolean
  isShown?: boolean
  disabled?: boolean
  previewImages?: Array<string>
}

const defaultTitle = "Choose photo to upload"

const UploadBox: React.FC<UploadBoxProps> = ({
  title = defaultTitle,
  onDrop,
  files,
  removePhoto,
  attaching,
  acceptedFile = "image/*",
  hasTitle = true,
  hasError,
  hasDelete = true,
  isShown = true,
  disabled = false,
  note,
  previewImages
}) => {
  const classes = styles()

  const thumbs =
    files &&
    files.map((file: any, index: number) => (
      <Box
        border="1px solid #F2F2F2"
        borderRadius="10px"
        width="100%"
        height="70px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 40px"
        margin="10px 0"
        key={file.file_name}
      >
        <Box display="flex" alignItems="center" maxWidth="80%" flex="2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={file?.url || (previewImages && previewImages[index])}
            style={{ color: "#454B57", textDecoration: "none", width: "100%" }}
          >
            <Box display="flex">
              <Box marginRight="20px">
                {acceptedFile === "application/pdf" ? IconPdf : IconImage}
              </Box>

              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {file.file_name}
              </Box>
            </Box>
          </a>
        </Box>

        <Box>
          {hasDelete && (
            <Button onClick={() => removePhoto && removePhoto(index)}>
              {IconDelete}
            </Button>
          )}
        </Box>
      </Box>
    ))

  const LoadingView = (
    <Box
      border="1px solid #F2F2F2"
      borderRadius="10px"
      height="100px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="0 40px"
      margin="10px 0"
    >
      <div>Attaching Image... </div>
      <Loader dark noLoadingText />
    </Box>
  )

  return (
    <>
      {isShown && (
        <div className={classes.root}>
          {title && hasTitle && <div className={classes.titleStyle}>{title}</div>}
          <div className={`${classes.container} ${hasError ? "error" : ""}`}>
            <Dropzone
              disabled={disabled}
              accept={acceptedFile}
              onDrop={(acceptedFiles) => [
                onDrop &&
                  onDrop(
                    acceptedFiles.map((file) =>
                      Object.assign(file, { preview: URL.createObjectURL(file) })
                    )
                  )
              ]}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.uploadBox}>
                  <input {...getInputProps()} />
                  <div className={classes.uploadContent}>
                    <div>{IconUpload}</div>
                    <div className="uploadButton">{title}</div>
                    <div className="info">or drag & drop them here</div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          {note !== undefined && <div className={classes.uploadNote}>{note}</div>}
        </div>
      )}

      <aside>{thumbs}</aside>
      {/* <aside>
        {previewImages &&
          previewImages.map((imgPreview: any) => {
            return <img src={imgPreview} />
          })}
      </aside> */}
      {attaching && <div>{LoadingView}</div>}
    </>
  )
}

export default UploadBox
