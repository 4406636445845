const version = "v1"
const baseFacility = "svc-facility"
const baseFeedback = "svc-feedback"
const baseBookkeeping = "svc-bookkeeping"
const baseApplications = "svc-application"
const baseVMS = "svc-vms"
const baseAttendance = "svc-attendance"
const baseAccount = "svc-account"

const API = {
  COUNT_ACCOUNT_REQUESTS: `${baseFacility}/${version}/account-requests-count`,
  CHANGE_ADDRESS: `${baseFacility}/${version}/change-address-requests-count`,
  FEEDBACK_NO_REPLY: `${baseFeedback}/${version}/feedback-no-reply-count`,
  TOTAL_COLLECTIONS: `${baseBookkeeping}/${version}/condo-total-collections`,
  LIST_APPLICATIONS: `${baseApplications}/${version}/applications`,
  LIST_DELIVERIES: `${baseVMS}/${version}/vms-deliveries`,
  LIST_SERVICE_PROVIDERS: `${baseVMS}/${version}/vms-service-providers`,
  LIST_VISITORS: `${baseVMS}/${version}/vms-visitors`,
  COUNT_ATTENDANCE: `${baseAttendance}/${version}/count-attendances`,
  TOTAL_EMPLOYEE: `${baseAccount}/${version}/admin-staff-users-count`,
  LAST_ATTENDANCE: `${baseAttendance}/${version}/attendance-last-time-in`,
  EXPIRING_SOON: `${baseFacility}/${version}/expiring-estate-matter`,
  SCHEDULE_SERVICING: `${baseFacility}/${version}/scheduled-servicing`
}

export default API
