import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "60%",
    margin: "20px auto"
  },
  section: {
    margin: "0 auto 25px auto"
  },
  sectionLast: {
    padding: "35px 0",
    marginTop: "15px",
    borderTop: "1px solid #F2F2F2"
  },
  title: {
    fontSize: "12px",
    color: theme.palette.secondary.dark,
    paddingBottom: "20px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
      maxWidth: "48%"
    }
  }
}))

export default styles
