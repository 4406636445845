import React, { useState, useEffect, useContext } from "react"
import Box from "@material-ui/core/Box"
import Modal from "@material-ui/core/Modal"
import { useMachine } from "@xstate/react"
/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import LinkButton from "components/LinkButton"
import DateRangePicker from "components/DateRangePicker"
import AddPayment from "pages/Dashboard/Bookkeeping/components/AddPayment"
import EditPayment from "pages/Dashboard/Bookkeeping/components/EditPayment"
import ClosePayment from "pages/Dashboard/Bookkeeping/components/ClosePayment"
import CancelPayment from "pages/Dashboard/Bookkeeping/components/CancelPayment"
import RejectPayment from "pages/Dashboard/Bookkeeping/components/RejectPayment"
import DetailsPayment from "pages/Dashboard/Bookkeeping/components/DetailsPayment/Summary"
import PermissionMessage from "components/PermissionMessage"

import CloseRefund from "pages/Dashboard/Bookkeeping/components/CloseRefund"
import CancelRefund from "pages/Dashboard/Bookkeeping/components/CancelRefund"
import RejectRefund from "pages/Dashboard/Bookkeeping/components/RejectRefund"
import TotalPaymentsRecieved from "pages/Dashboard/Bookkeeping/components/Stats/TotalPaymentsRecieved"
/** Machine */
import { BookkeepingMachine } from "machines/Dashboard/Bookkeeping"
/** Table */
import BookkeepingTable from "pages/Dashboard/Bookkeeping/tables/main"
/** Context */
import withContext from "context/Dashboard/Bookkeeping/main/withContext"
import { CtxType } from "context/Dashboard/Bookkeeping/main/Context"
import { AppContext } from "App"

const Bookkeeping: React.FC<CtxType> = ({
  startDateState,
  endDateState,
  focusedInput,
  isopenModal,
  setisopenModal,
  onFocusChange,
  handleDateRange,
  dateValue,
  filterActiveTable,
  activeTable,
  showDetails,
  setShowDetails
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(BookkeepingMachine)
  const [activeData, setActiveData] = useState({})

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const MainView = () => {
    if (current.value !== "tableView") {
      return false
    }

    return (
      <WithHeader>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <PageHeaderTitle
              title="Bookkeeping"
              breadcrumbs="property management / bookkeeping"
            />
            <Box>
              <LinkButton
                name="BOOKKEEPING SETTINGS"
                url={
                  permission?.manage_condo_settings
                    ? "/settings/property-management/bookkeeping"
                    : "#"
                }
              />
            </Box>
          </Box>
          <Box display="flex" marginBottom="20px">
            <Box flex="1" height="100%">
              <TotalPaymentsRecieved
                dates={dateValue(startDateState, endDateState)}
                showDate={setisopenModal}
                isDateShown={isopenModal}
              />
            </Box>
          </Box>
          <BookkeepingTable
            filterActiveTable={filterActiveTable}
            activeTable={activeTable}
            showPayment={() => send("ADD_PAYMENT")}
            contextActions={{
              showClosePayment: (data: any): void => {
                setActiveData(data)
                send("CLOSE_PAYMENT")
              },
              showRefundPayment: (data: any): void => {
                send("REFUND_PAYMENT")
                setActiveData(data)
              },
              showCancelPayment: (data: any): void => {
                send("CANCEL_PAYMENT")
                setActiveData(data)
              },
              showRejectPayment: (data: any): void => {
                send("REJECT_PAYMENT")
                setActiveData(data)
              },
              showDetails: (data: any): void => {
                setActiveData(data)
              },
              showCancelRefund: (data: any): void => {
                send("CANCEL_REFUND")
                setActiveData(data)
              },
              showCloseRefund: (data: any): void => {
                send("CLOSE_REFUND")
                setActiveData(data)
              },
              showRejectRefund: (data: any): void => {
                send("REJECT_REFUND")
                setActiveData(data)
              },
              showEditPayment: (data: any): void => {
                send("EDIT_PAYMENT")
                setActiveData(data)
              }
            }}
          />
        </Box>
      </WithHeader>
    )
  }

  const ViewAddPayment = () => {
    if (current.value !== "addPayment") {
      return false
    }

    return (
      <AddPayment
        showPayment={current.value === "addPayment"}
        setShowPayment={() => send("TABLE_VIEW")}
      />
    )
  }

  const ViewEditPayment = () => {
    if (current.value !== "editPayment") {
      return false
    }

    return (
      <EditPayment
        showPayment={current.value === "editPayment"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewClosePayment = () => {
    if (current.value !== "closePayment") {
      return false
    }
    return (
      <ClosePayment
        showPayment={current.value === "closePayment"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewCloseRefund = () => {
    if (current.value !== "closeRefund") {
      return false
    }
    return (
      <CloseRefund
        showPayment={current.value === "closeRefund"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewCancelPayment = () => {
    if (current.value !== "cancelPayment") {
      return false
    }
    return (
      <CancelPayment
        showPayment={current.value === "cancelPayment"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewCancelRefund = () => {
    if (current.value !== "cancelRefund") {
      return false
    }
    return (
      <CancelRefund
        showPayment={current.value === "cancelRefund"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewRejectPayment = () => {
    if (current.value !== "rejectPayment") {
      return false
    }
    return (
      <RejectPayment
        showPayment={current.value === "rejectPayment"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewRejectRefund = () => {
    if (current.value !== "rejectRefund") {
      return false
    }
    return (
      <RejectRefund
        showPayment={current.value === "rejectRefund"}
        setShowPayment={() => send("TABLE_VIEW")}
        data={activeData}
      />
    )
  }

  const ViewDetails = (): JSX.Element => {
    return (
      <DetailsPayment
        openState={showDetails}
        setopenState={(value: boolean) => setShowDetails(value)}
        data={activeData}
      />
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.bookkeeping_view) {
    return (
      <>
        <div>{MainView()}</div>
        <div>
          {ViewAddPayment()}
          {ViewEditPayment()}
          {ViewClosePayment()}
          {ViewCancelPayment()}
          {ViewRejectPayment()}
          {ViewCloseRefund()}
          {ViewCancelRefund()}
          {ViewRejectRefund()}
        </div>
        <Modal open={isopenModal} onClose={() => setisopenModal(false)}>
          <DateRangePicker
            handleDateRange={handleDateRange}
            onFocusChange={onFocusChange}
            focusedInput={focusedInput}
            startDate={startDateState}
            endDate={endDateState}
            handleClose={() => {
              setisopenModal(false)
            }}
          />
        </Modal>
        {ViewDetails()}
      </>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(Bookkeeping)
