import React from "react"
/** Components */
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
import FacilitiesBreakdown from "../../Common/FacilitiesBreakdown"
/** Styles */
import styles from "./styles"

function PaymentFacilities({ facilityBookings }: CtxType): JSX.Element {
  const classes = styles()

  function ViewList() {
    return (
      <>
        {facilityBookings?.map((facilityBooking: Record<string, any>) => {
          return <FacilitiesBreakdown facilityBooking={facilityBooking} />
        })}
      </>
    )
  }

  return <div className={classes.container}>{ViewList()}</div>
}

export default withContext(PaymentFacilities)
