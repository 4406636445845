import React, { useContext, useState } from "react"
import Fade from "@material-ui/core/Fade"
import Box from "@material-ui/core/Box"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import { Context } from "context/Dashboard/Attendance/main/Context"

import tableHeaders from "./tableHeaders"

interface Props {
  data: Record<string, any>
  setIsEdit: Function
}

const TableAttendances: React.FC<Props> = ({ data, setIsEdit }) => {
  const { setDownloadData, isValidating, showTimeout } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  /**
   * Methods
   */
  function handleTimeout(dataItems: Record<string, any>) {
    setIsEdit(false)
    showTimeout && showTimeout(true, dataItems)
  }

  return (
    <Box>
      {isValidating ? (
        <Loader dark />
      ) : (
        <Fade in={!isValidating} timeout={2000}>
          <Box>
            <Table
              data={data || []}
              columns={tableHeaders(handleTimeout)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              minRows={3}
              action={(values: any) =>
                setDownloadData &&
                setDownloadData(
                  values?.map((i: any) => {
                    return {
                      "Date": i?.date,
                      "Name": i?.name,
                      "Category": i?.category,
                      "Position": i?._original?.user_role_name,
                      "Time in": i?.timein
                    }
                  })
                )}
            />
          </Box>
        </Fade>
      )}
    </Box>
  )
}

export default TableAttendances
