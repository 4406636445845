import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"

/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
import Switch from "components/Forms/Switch"

/** Config */
import { addApplication } from "config/Settings/PropertyManagement/Applications/validation"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Applications/forms/category/Context"

/** Styles */
import { refSubmit } from "context/Settings/PropertyManagement/Applications/forms/category/View"
import styles from "./styles"

/** Ref */

const CategoryDetails: React.FC = () => {
  const { section, sectionLast, title } = styles()
  const { applicationDetails, setApplicationDetails } = useContext(Context)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: any,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)
    setApplicationDetails &&
      setApplicationDetails({
        ...applicationDetails,
        [name]: e.target.value
      })
  }

  const handleSwitch = (
    setFieldValue: Function,
    key: string,
    value: string | boolean
  ): void => {
    return setFieldValue(key, value).then(
      () =>
        setApplicationDetails &&
        setApplicationDetails({
          ...applicationDetails,
          [key]: value
        })
    )
  }

  return (
    <div>
      <Box margin="auto" padding="0 0 40px 0">
        <FormWrapper title="Category Details & Booking Settings" width="50%">
          <Formik
            initialValues={{
              name: applicationDetails?.name,
              daysAdvanceBooking: applicationDetails?.daysAdvanceBooking,
              daysCancelBooking: applicationDetails?.daysCancelBooking,
              allowOnline: applicationDetails?.allowOnline
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={addApplication}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              handleChange,
              setFieldTouched,
              setFieldValue
            }): JSX.Element => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box className={section}>
                    <Box className={title}>Category Name</Box>
                    <Box>
                      <FormInput
                        name="name"
                        placeholder="Category Name"
                        value={applicationDetails?.name}
                        handleOnChange={(e: any): void =>
                          handleFormChange("name", e, handleChange, setFieldTouched)}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Box>
                  </Box>

                  <Box className={sectionLast}>
                    <Box className={title}>Booking Settings</Box>
                    <Box marginBottom="20px">
                      <FormInput
                        type="number"
                        labelShrink
                        name="daysAdvanceBooking"
                        label="Advance Booking"
                        placeholder="Advance Booking"
                        value={applicationDetails?.daysAdvanceBooking}
                        handleOnChange={(e: any): void =>
                          handleFormChange(
                            "daysAdvanceBooking",
                            e,
                            handleChange,
                            setFieldTouched
                          )}
                        adorment="| days"
                        onBlur={handleBlur}
                        error={
                          touched.daysAdvanceBooking &&
                          Boolean(errors.daysAdvanceBooking)
                        }
                        helperText={
                          errors.daysAdvanceBooking &&
                          touched.daysAdvanceBooking &&
                          errors.daysAdvanceBooking
                        }
                      />
                    </Box>
                    <Box marginBottom="20px">
                      <FormInput
                        type="number"
                        labelShrink
                        name="daysCancelBooking"
                        label="Cancel Booking"
                        value={applicationDetails?.daysCancelBooking}
                        handleOnChange={(e: any): void =>
                          handleFormChange(
                            "daysCancelBooking",
                            e,
                            handleChange,
                            setFieldTouched
                          )}
                        adorment="| days"
                        onBlur={handleBlur}
                        error={
                          touched.daysCancelBooking &&
                          Boolean(errors.daysCancelBooking)
                        }
                        helperText={
                          errors.daysCancelBooking &&
                          touched.daysCancelBooking &&
                          errors.daysCancelBooking
                        }
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Box className={title}>Allow Online Application</Box>
                      <Box
                        width="30%"
                        display="flex"
                        justifyContent="flex-end"
                        marginRight="-20px"
                      >
                        <Switch
                          action={(): void =>
                            handleSwitch(
                              setFieldValue,
                              "allowOnline",
                              !applicationDetails?.allowOnline
                            )}
                          label=""
                          checked={applicationDetails?.allowOnline}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </form>
              )
            }}
          </Formik>
        </FormWrapper>
      </Box>
    </div>
  )
}

export default CategoryDetails
