import React, { useContext } from "react"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import MetisMenu from "react-metismenu"
import classnames from "classnames"
import Box from "@material-ui/core/Box"

// icons
import Dashboard from "assets/images/icons/sidebar/Dashboard"
import Announcements from "assets/images/icons/sidebar/Announcements"
import Applications from "assets/images/icons/sidebar/Applications"
import Attendance from "assets/images/icons/sidebar/Attendance"
import Banner from "assets/images/icons/sidebar/Banner"
import Bookkeeping from "assets/images/icons/sidebar/Bookkeeping"
import Contracts from "assets/images/icons/sidebar/Contracts"
import Construction from "assets/images/icons/sidebar/Construction"
import EstateMatters from "assets/images/icons/sidebar/EstateMatters"
import Event from "assets/images/icons/sidebar/Event"
import Documents from "assets/images/icons/sidebar/Documents"
import Facilities from "assets/images/icons/sidebar/Facilities"
import Feedback from "assets/images/icons/sidebar/Feedback"
import Post from "assets/images/icons/sidebar/Post"
import Reports from "assets/images/icons/sidebar/Reports"
import UnitManagement from "assets/images/icons/sidebar/UnitManagement"
import VisitorManagement from "assets/images/icons/sidebar/VisitorManagement"
import Back from "assets/images/icons/menus/Back"
import styles from "./styles"
import { SideBarContext } from "../../AppSidebar"

// styles
import "react-metismenu/dist/react-metismenu-standart.min.css"
import "../styles.scss"

// config
import { MainNav, SettingsNav } from "./NavItems"

interface LinkProps {
  className: any
  classNameActive: any
  classNameHasActiveChild: any
  active: any
  hasActiveChild: any
  to: any
  externalLink: any
  hasSubMenu: any
  toggleSubMenu: any
  activateMe: any
  children: any
  label: any
  test: string
  icon: string
}

const Item: React.FC<LinkProps> = ({
  className,
  classNameActive,
  classNameHasActiveChild,
  active,
  hasActiveChild,
  to,
  externalLink,
  hasSubMenu,
  toggleSubMenu,
  activateMe,
  label
}) => {
  const { link, labelStyle } = styles()

  const IconType = (iconName: string, color: string): JSX.Element => {
    switch (iconName) {
      case "Announcements":
        return <Announcements color={color} />
      case "Applications":
        return <Applications color={color} />
      case "Attendance":
        return <Attendance color={color} />
      case "Banner":
        return <Banner color={color} />
      case "Bookkeeping":
        return <Bookkeeping color={color} />
      case "Management Documents":
        return <Contracts color={color} />
        break
      case "Construction / Manuals":
        return <Construction color={color} />
      case "Dashboard":
        return <Dashboard color={color} />
      case "Event":
        return <Event color={color} />
      case "Public Documents":
        return <Documents color={color} />
      case "Estate Matters":
        return <EstateMatters color={color} />
      case "Facilities":
        return <Facilities color={color} />
      case "Feedback":
        return <Feedback color={color} />
      case "Post":
        return <Post color={color} />
      case "Reports":
        return <Reports color={color} />
      case "Unit Management":
        return <UnitManagement color={color} />
      case "Visitor Management":
        return <VisitorManagement color={color} />
      case "Back to Dashboard":
        return <Back />
      default:
        return <div />
    }
  }
  const iconColor = active ? "#09707B" : "#FFFFFF"

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classnames(
        link,
        className,
        active && classNameActive,
        hasActiveChild && classNameHasActiveChild
      )}
      onClick={hasSubMenu ? toggleSubMenu : activateMe}
    >
      <a
        href={to}
        target={externalLink ? "_blank" : undefined}
        style={{ width: "100%", display: "flex", alignItems: "center" }}
      >
        <i className="metismenu-icon">{IconType(label, iconColor)}</i>
        <span className={`menu-label ${labelStyle}`} style={{ paddingLeft: "15px" }}>
          {label}
        </span>
      </a>

      <Box margin="0 20px 0 0" display="flex" alignItems="center">
        {hasSubMenu ? (
          <KeyboardArrowDownIcon style={{ fill: "#FFFFFF" }} />
        ) : (
          <Box />
        )}
      </Box>
    </Box>
  )
}

const Nav: React.FunctionComponent = () => {
  const currentPage = useContext(SideBarContext)
  const currentConfig = currentPage === "settings" ? SettingsNav : MainNav
  return (
    <MetisMenu
      content={currentConfig}
      activeLinkFromLocation
      className="vertical-nav-menu"
      iconNamePrefix="fm"
      LinkComponent={Item}
    />
  )
}

export default Nav
