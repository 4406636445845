import { Machine } from "xstate"

export const CategoryFormMachine = Machine({
  id: "CategoryFormMachine",
  initial: "details",
  states: {
    details: {
      on: {
        NEXT: "entitlement"
      }
    },
    entitlement: {
      on: {
        NEXT: "timeSlots",
        BACK: "details"
      }
    },
    timeSlots: {
      on: {
        NEXT: "effectiveDate",
        BACK: "entitlement"
      }
    },
    effectiveDate: {
      on: {
        BACK: "timeSlots"
      }
    }
  }
})
