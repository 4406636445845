import * as React from "react"

interface UploadCsvProps {
  color: string
}

const UploadCsv: React.FC<UploadCsvProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.585938L2.58594 8H7V14.5C7 15.3171 7.68294 16 8.5 16H11.5C12.3171 16 13 15.3171 13 14.5V8H17.4141L10 0.585938ZM10 3.41406L12.5859 6H11V14H9V6H7.41406L10 3.41406ZM0 19V21H20V19H0Z"
        fill={color}
      />
    </svg>
  )
}

export default UploadCsv
