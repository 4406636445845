import * as React from "react"

const VMSDelivery = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 0C2.57531 9.33959e-05 2.4037 0.0459415 2.25223 0.13298C2.10077 0.220018 1.97475 0.34521 1.88672 0.496094L0.136719 3.49609C0.0473304 3.64899 0.000149602 3.82289 0 4V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V4C17.9999 3.82289 17.9527 3.64899 17.8633 3.49609L16.1133 0.496094C16.0252 0.345211 15.8992 0.220018 15.7478 0.13298C15.5963 0.0459415 15.4247 9.34308e-05 15.25 0H2.75ZM3.32422 2H8V4H2.1582L3.32422 2ZM10 2H14.6758L15.8418 4H10V2ZM2 6H16V16H2V6ZM6 8V10H12V8H6Z"
      fill="#454B57"
    />
  </svg>
)

export default VMSDelivery
