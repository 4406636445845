import React from "react"
import ReactTable from "react-table"
import withFixedColumns from "react-table-hoc-fixed-columns"
import Box from "@material-ui/core/Box"

import "./styles/index.scss"
import "react-table-hoc-fixed-columns/lib/styles.css"

const ReactTableFixedColumns = withFixedColumns(ReactTable)

// interface
interface TableDataProps {
  data: Record<string, any>
  columns: Record<string, any>
  children?: Record<string, any>
  isFilterable?: boolean
  showPagination?: boolean
  minRows?: number
  hasHeaderGroup?: boolean
  tableClass?: string
  action?: Function
  ref?: any
  currentPage?: number
  setCurrentPage?: Function
}

const TableData: React.FC<TableDataProps> = ({
  action,
  data,
  columns,
  children,
  isFilterable,
  showPagination = true,
  minRows,
  hasHeaderGroup,
  currentPage = 0,
  tableClass = `-striped -highlight ${hasHeaderGroup ? "hasHeaderGroup" : ""}`,
  setCurrentPage
}) => {
  const filterCaseInsensitive = ({ id, value }: any, row: any) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true

  const memoData = React.useMemo(() => data, [data])

  return (
    <>
      <Box>
        {children && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            {children}
          </Box>
        )}
        <ReactTableFixedColumns
          className={tableClass}
          data={memoData}
          columns={columns}
          minRows={minRows}
          defaultPageSize={10}
          pageSizeOptions={[5, 10]}
          showPagination={showPagination}
          getHeaderProps={() => ({ style: { padding: 0 } })}
          defaultFilterMethod={filterCaseInsensitive}
          filterable={isFilterable}
          page={currentPage}
          onPageChange={(page: any) => setCurrentPage && setCurrentPage(page)}
          NoDataComponent={() => <Box className="rt-noData">No Data Found</Box>}
          onFetchData={(dataFetched: any) => {
            return action && action(dataFetched?.sortedData)
          }}
        >
          {(state: any, makeTable: any) => {
            return makeTable()
          }}
        </ReactTableFixedColumns>
      </Box>
    </>
  )
}

export default TableData
