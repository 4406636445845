import React, { useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import service from "services/Settings/PropertyManagement/Facilities"
/** Components */
import Loader from "components/Loader"
import Table from "components/Table"
import tableHeaders from "./table/tableHeaders"

interface Props {
  showEdit: Function
  showFacilities: Function
}

const TableSettingsApplications: React.FC<Props> = () => {
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchFacilitiesListSettings = async () => service.getSettings()

  const { data, isValidating } = useSWR(
    "fetchFacilitiesListSettings",
    fetchFacilitiesListSettings,
    {
      revalidateOnFocus: true
    }
  )
  const tableData = data?.data?._data

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData || []}
          columns={tableHeaders()}
          minRows={3}
          isFilterable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Fade>
  )
}

export default TableSettingsApplications
