import * as React from "react"

const Refunds: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C6.46988 0 3.37511 1.83386 1.5957 4.5957L0 3V8H5L3.05469 6.05469C4.42999 3.63616 7.01934 2 10 2C14.418 2 18 5.582 18 10C18 14.418 14.418 18 10 18C9.404 18 8.81133 17.9347 8.23633 17.8047L7.79688 19.7559C8.51587 19.9179 9.257 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM9.71289 4V5.07812C9.37389 5.12113 7.47656 5.47758 7.47656 7.76758C7.47656 11.1086 10.9355 10.1206 10.9355 12.3496C10.9355 13.4656 10.2134 13.4922 10.0664 13.4922C9.93241 13.4922 9.05273 13.5635 9.05273 11.8555H7.16211C7.16211 14.7475 9.26027 14.9765 9.57227 15.0195V16H10.5742V15.0195C10.9122 14.9775 12.8242 14.6639 12.8242 12.3359C12.8242 9.12994 9.36623 9.74334 9.36523 7.77734C9.36523 6.65034 9.98514 6.61523 10.1191 6.61523C10.3641 6.61523 10.9473 6.83012 10.9473 8.20312H12.8379C12.8379 5.57613 11.0417 5.16647 10.7207 5.10547V4H9.71289ZM0 10L0.00195312 10.1582C0.0129531 10.9002 0.106297 11.6395 0.279297 12.3555L2.22461 11.8887C2.08561 11.3157 2.01095 10.722 2.00195 10.127L2 10H0ZM2.82812 13.5527L1.03711 14.4434C1.36711 15.1044 1.77028 15.7326 2.23828 16.3066L3.78906 15.0449C3.41506 14.5849 3.09212 14.0827 2.82812 13.5527ZM5.05469 16.2891L3.81641 17.8594C4.39941 18.3184 5.03222 18.7132 5.69922 19.0312L6.56055 17.2266C6.02655 16.9716 5.52069 16.6561 5.05469 16.2891Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Refunds
