import React from "react"
import Box from "@material-ui/core/Box"
import Switch from "@material-ui/core/Switch"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const Others: React.FC<Props> = ({ handleUpdate, data }) => {
  const classes = styles()

  return (
    <Box>
      <Box display="flex" className={classes.sectionHead}>
        <Box flex="5" className={classes.otherTitle}>
          Other Settings
        </Box>
        <Box flex="1" />
      </Box>

      <Box className={classes.section}>
        <Box flex="5">Manage Condo Settings</Box>
        <Box flex="1">
          <Switch
            onChange={(): void =>
              handleUpdate("manage_condo_settings", !data?.manage_condo_settings)}
            checked={data?.manage_condo_settings}
            name={data?.manage_condo_settings}
            color="primary"
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box flex="5">Manage Accounts Settings</Box>
        <Box flex="1">
          <Switch
            onChange={(): void =>
              handleUpdate("account_settings", !data?.account_settings)}
            checked={data?.account_settings}
            name={data?.account_settings}
            color="primary"
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box flex="5">Manage Accounts Management</Box>
        <Box flex="1">
          <Switch
            onChange={(): void =>
              handleUpdate("account_management", !data?.account_management)}
            checked={data?.account_management}
            name={data?.account_management}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  )
}
export default Others
