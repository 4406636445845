import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Cookie from "js-cookie"
/** Service */
import vmsAPI from "services/Dashboard/VMS"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/VMS/categories/main/Context"
import tableHeaders from "./table/tableHeaders"
/** Context */

interface TableServiceProvidersProps {
  activeFilter: number
}

const TableServiceProviders: React.FC<TableServiceProvidersProps> = ({
  activeFilter
}) => {
  const { setDownloadData } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchAllServiceProviders = async () =>
    vmsAPI.getVMS({
      "vms_type": 3,
      "condo_uid": Cookie.get("condoUID")
    })

  const { data, isValidating } = useSWR(
    `fetchAllServiceProviders-${activeFilter}`,
    fetchAllServiceProviders,
    {
      revalidateOnFocus: true
    }
  )
  const tableData = data?.data?._data
  const filteredData = tableData?.filter((i: any) => i.vms.status === activeFilter)
  const activeData = activeFilter !== 0 ? filteredData : tableData

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1500}>
      <div>
        <Table
          data={activeData || []}
          columns={tableHeaders(`fetchAllServiceProviders-${activeFilter}`)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Status": i?.status,
                    "Category": i?.category,
                    "Block/Unit No.": i?.blockUnitNo,
                    "Name": i?.name,
                    "Mobile": i?.mobile,
                    "Email": i?.email,
                    "Purpose": i?.purpose,
                    "Visitation Date": i?.visitationDate,
                    "Visitation Time": i?.visitationTime,
                    "Resident Name": i?.residentName,
                    "Remarks": i?.remarks,
                    "OTP": i?.otp
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default TableServiceProviders
