import { Machine } from "xstate"

export const FeedbackMachine = Machine({
  id: "feedbackMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_FEEDBACK: "addFeedback",
        DETAILS: "details",
        ADD_REPLY: "addReply"
      }
    },
    addFeedback: {
      on: {
        BACK: "tableView"
      }
    },
    editFeedback: {
      on: {
        BACK: "tableView"
      }
    },
    details: {
      on: {
        BACK: "tableView"
      }
    },
    addReply: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_FEEDBACK: "addFeedback",
    EDIT_FEEDBACK: "editFeedback",
    DETAILS: "details",
    ADD_REPLY: "addReply"
  }
})
