import React from "react"
import Box from "@material-ui/core/Box"

import styles from "./styles"

interface TabRolesProps {
  activeRole: string
  activeItem: number
  selectItem: Function
  options: any
}

const TabRoles: React.FC<TabRolesProps> = ({ activeItem, selectItem, options }) => {
  const { btn } = styles()

  return (
    <>
      <Box display="flex" paddingRight="10px">
        {options !== undefined &&
          Object.keys(options).map((option: any, index: number) => {
            return (
              <Box
                className={`${btn} ${activeItem === index ? "active" : ""}`}
                onClick={() =>
                  selectItem(index, options[option]._uid, options[option].role_name)}
              >
                {options[option].role_name}
              </Box>
            )
          })}
      </Box>
    </>
  )
}
export default TabRoles
