import * as React from "react"
import { ReplyContext } from "./ReplyContext"

function withReplyContext(Component: any) {
  return function contextComponent(props: any) {
    return (
      <ReplyContext.Consumer>
        {(contexts: any) => <Component {...props} {...contexts} />}
      </ReplyContext.Consumer>
    )
  }
}

export default withReplyContext
