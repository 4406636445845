import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunkMiddleware from "redux-thunk"

import { persistStore, persistCombineReducers } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from "../redux"

const persistConfig = {
  key: "root",
  storage
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
)

export const persistor = persistStore(store)

export default {
  store,
  persistor
}
