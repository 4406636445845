import { SET_PAYMENTS_TABLE } from "../constants"

const initialState = {
  tablePayments: []
}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_PAYMENTS_TABLE:
      return {
        tablePayments: action.table
      }
    default:
      return state
  }
}
