import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderBottom: "1px solid #F2F2F2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 30px",
    cursor: "pointer"
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.dark
  }
}))

export default styles
