import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const PublicDocuments: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("public documents")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* <Checkbox
          label={null}
          value={Boolean(data?.payment_edit)}
          onChange={() => handleUpdate("payment_edit", Boolean(!data?.payment_edit))}
        /> */}
      </Box>
      <Box flex="1">
        <Checkbox
          label={null}
          value={Boolean(data?.public_document_view)}
          onChange={() =>
            handleUpdate(
              "public_document_view",
              Boolean(!data?.public_document_view)
            )}
        />
      </Box>
    </Box>
  )
}
export default PublicDocuments
