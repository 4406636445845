import React from "react"
import { Route } from "react-router-dom"

// DASHBOARDS
import PublicDocuments from "./PublicDocuments"
import ConstructionManuals from "./ConstructionManuals"
import ManagementDocuments from "./ManagementDocuments"

interface EstateMattersProps {
  match: {
    url: string
  }
  history: any
}

const EstateMatters: React.FC<EstateMattersProps> = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/public-documents`} component={PublicDocuments} />
      <Route
        path={`${match.url}/construction-and-manuals`}
        component={ConstructionManuals}
      />
      <Route
        path={`${match.url}/management-documents`}
        component={ManagementDocuments}
      />
    </>
  )
}

export default EstateMatters
