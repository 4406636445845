import React from "react"
import Box from "@material-ui/core/Box"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Button from "@material-ui/core/Button"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "block",
    paddingBottom: "20px"
  },
  defaultContainer: {
    display: "block"
  },
  pageName: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#454B57",
    lineHeight: "36px",
    textTransform: "capitalize"
  },
  breadcrumbsStyle: {
    fontSize: "10px",
    letterSpacing: "0.015em",
    color: theme.palette.body.dark,
    textTransform: "uppercase",
    lineHeight: "18px"
  },
  backBtn: {
    minWidth: "auto",
    padding: "0",
    marginBottom: "10px"
  }
}))

interface PageHeaderTitleProps {
  title: string
  breadcrumbs?: string
  children?: any
  backAction?: Function
  noBottomGap?: boolean
}

const PageHeaderTitle: React.FC<PageHeaderTitleProps> = ({
  breadcrumbs,
  children,
  title,
  backAction,
  noBottomGap
}) => {
  const {
    breadcrumbsStyle,
    container,
    pageName,
    backBtn,
    defaultContainer
  } = useStyles()
  const containerClass = noBottomGap ? defaultContainer : container
  return (
    <Box className={containerClass}>
      {backAction && (
        <Button onClick={() => backAction()} size="small" className={backBtn}>
          <ArrowBackIcon />
        </Button>
      )}
      <Box className={breadcrumbsStyle}>{breadcrumbs}</Box>
      <Box data-test-id="page-header-title" className={pageName}>
        {title}
      </Box>
      {children && <Box marginTop="5px">{children}</Box>}
    </Box>
  )
}

export default PageHeaderTitle
