import React, { useState, useEffect } from "react"

/** Context */
import { Context } from "./Context"

const UMProvider: React.FC = (props: any) => {
  const { children } = props
  const [activeTable, setactiveTable] = useState(1)
  const [accountRequestsTab, setAccountRequestsTab] = React.useState(0)
  const [ownersTab, setOwnersTab] = React.useState(0)
  const [residentsTab, setResidentsTab] = React.useState(0)
  const [vehiclesTab, setVehiclesTab] = React.useState(0)
  const [accessCardsTab, setAccessCardsTab] = React.useState(0)

  /** States for Forms */
  const [psActive, setPsActive] = useState(false)
  const [detailsActive, setDetailsActive] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Context.Provider
      value={{
        activeTable,
        psActive,
        detailsActive,
        setPsActive,
        setactiveTable,
        setDetailsActive,
        activeTableFilter: {
          accountRequestsTab,
          ownersTab,
          residentsTab,
          vehiclesTab,
          accessCardsTab,
          setAccountRequestsTab,
          setOwnersTab,
          setResidentsTab,
          setVehiclesTab,
          setAccessCardsTab
        }
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default UMProvider
