const appConfig = {
  CONTACT_NUMBER: "+6596786250",
  CONTACT_EMAIL: "admin@cora.pro",
  BASE_URL: process.env.REACT_APP_API_URL,
  BASE_URL_DEV: "https://stg-api.cora.pro/",
  SUPER_ADMIN: {
    "account_management": true,
    "account_settings": true,
    "manage_condo_settings": true,
    "announcement_create": true,
    "announcement_edit": true,
    "announcement_remove": true,
    "application_create": true,
    "application_edit": true,
    "application_remove": true,
    "attendance_create": true,
    "attendance_edit": true,
    "attendance_remove": true,
    "bookkeeping_create": true,
    "bookkeeping_edit": true,
    "bookkeeping_remove": true,
    "estate_matter_construction_manuals_create": true,
    "estate_matter_construction_manuals_edit": true,
    "estate_matter_construction_manuals_remove": true,
    "estate_matter_management_documents_create": true,
    "estate_matter_management_documents_edit": true,
    "estate_matter_management_documents_remove": true,
    "estate_matter_public_documents_create": true,
    "estate_matter_public_documents_edit": true,
    "estate_matter_public_documents_remove": true,
    "facility_create": true,
    "facility_edit": true,
    "facility_remove": true,
    "feedback_create": true,
    "feedback_edit": true,
    "feedback_remove": true,
    "payment_schedule_create": true,
    "payment_schedule_edit": true,
    "payment_schedule_remove": true,
    "unit_mgt_create": true,
    "unit_mgt_edit": true,
    "unit_mgt_remove": true,
    "vms_create": true,
    "vms_edit": true,
    "vms_remove": true
  }
}

export default appConfig
