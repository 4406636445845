import { mutate } from "swr"

const ApplicationsMutate: any = {
  FetchApplicationsMovingInOut: (activeFilter: number) =>
    mutate(`fetchApplicationsMovingInOut-${activeFilter}`),
  FetchApplicationsVehicle: (activeFilter: number) =>
    mutate(`fetchApplicationsVehicle-${activeFilter}`),
  FetchApplicationsAccessCard: (activeFilter: number) =>
    mutate(`fetchApplicationsAccessCard-${activeFilter}`),
  FetchApplicationsRenovation: (activeFilter: number) =>
    mutate(`fetchApplicationsRenovation-${activeFilter}`)
}

export const {
  FetchApplicationsMovingInOut,
  FetchApplicationsVehicle,
  FetchApplicationsAccessCard,
  FetchApplicationsRenovation
} = ApplicationsMutate
