import React from "react"

export type CtxType = {
  showDetails: Function
  showAddForm: Function
  showEditForm: Function
  showReplyForm: Function
  setActiveFilter: Function
  filterTable: Function
  setActiveStatus: Function
  filterTabStatus: Function
  setDownloadData: Function
  activeStatus: number
  activeFilter: number
  activeTable: number
  tabStatus: number
  downloadData: Array<{}>
  initData: Record<any, any>
  data: Record<any, any>
  isValidating: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
