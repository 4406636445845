import React from "react"
import Box from "@material-ui/core/Box"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
/**
 * Styles
 */
import styles from "./styles"

interface Props {
  value: number
  handleChange: Function
  tableName: string
  icon: any
}

const Header: React.FC<Props> = ({ value, handleChange, tableName, icon }) => {
  const { container, title } = styles()

  const selectTab = (event: any, newValue: number) => {
    handleChange(newValue)
  }

  return (
    <Box className={container}>
      <Box className={title}>
        <Box marginRight="20px">{icon}</Box>
        {tableName}
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={selectTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Today" />
          <Tab label="Next Day" />
          {/* <Tab label="Pending Approval" /> */}
        </Tabs>
      </Box>
    </Box>
  )
}

export default Header
