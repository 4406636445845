import React from "react"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import { Formik } from "formik"
import * as Yup from "yup"

/** Components */
import Label from "components/Forms/Label"
import UploadBox from "components/UploadBox/new"
import FormInput from "components/Forms/FormInput"
import StaffList from "components/Common/StaffList"
import RefButton from "components/Forms/RefButton"

/* Context */
import withReplyContext from "context/Dashboard/Feedback/reply/withReplyContext"
import { ReplyCtxType } from "context/Dashboard/Feedback/reply/ReplyContext"

import { refSubmit } from "context/Dashboard/Feedback/reply/ReplyView"

/* Styles */
import styles from "./styles"

const Form: React.FC<ReplyCtxType> = ({
  handleImageUpload,
  removePhoto,
  photos,
  attaching,
  setReplyDetails,
  replyDetails
}) => {
  const { container, field, title, uploadContainer } = styles()

  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFieldValue(key, e?.target?.value)
    setReplyDetails &&
      setReplyDetails({
        ...replyDetails,
        [key]: e?.target?.value
      })
  }

  const handleFormValue = (
    setFieldValue: Function,
    key: string,
    value: string | boolean
  ): void => {
    setFieldValue(key, value)
    setReplyDetails &&
      setReplyDetails({
        ...replyDetails,
        [key]: value
      })
  }

  return (
    <div className={container}>
      <div className={title}>Reply</div>
      <Formik
        initialValues={{
          message: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string().required("Required")
        })}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <div className={field}>
                <FormInput
                  label="Message"
                  placeholder="Message"
                  value={replyDetails?.message}
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFormChange(setFieldValue, "message", e)}
                  name="message"
                  idValue="message"
                  multiline
                  rows={3}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={errors.message && touched.message && errors.message}
                />
              </div>
              <div className={field}>
                <Label label="photos" />
                <div className={uploadContainer}>
                  <UploadBox
                    onDrop={handleImageUpload}
                    files={photos}
                    removePhoto={removePhoto}
                    attaching={attaching}
                    hasTitle={false}
                  />
                </div>
              </div>
              <div className={field}>
                <Label label="Assign Staff" />
                <div>
                  <StaffList
                    value={replyDetails?.staffName}
                    onChange={(value: string) =>
                      handleFormValue(setFieldValue, "staffName", value)}
                  />
                </div>
              </div>

              <div className={field} style={{ marginTop: "15px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={replyDetails.urgent}
                        onChange={() =>
                          handleFormValue(
                            setFieldValue,
                            "isUrgent",
                            !replyDetails.urgent
                          )}
                        value={replyDetails.urgent}
                        color="primary"
                      />
                    }
                    label="Set as urgent"
                  />
                </FormGroup>
              </div>
              <div className={field}>
                <Label label="Mark as" />
                <FormControl>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    value={replyDetails.status}
                    onChange={(e) =>
                      setReplyDetails &&
                      setReplyDetails({
                        ...replyDetails,
                        status: e.target.value
                      })}
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Pending"
                      labelPlacement="end"
                      style={{ margin: 0, width: "50%" }}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="In Progress"
                      labelPlacement="end"
                      style={{ margin: 0, width: "50%" }}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="Resolved"
                      labelPlacement="end"
                      style={{ margin: 0, width: "50%" }}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio color="primary" />}
                      label="Cancelled"
                      labelPlacement="end"
                      style={{ margin: 0, width: "50%" }}
                    />
                  </RadioGroup>
                </FormControl>
                <RefButton refValue={refSubmit} action={handleSubmit} />
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
export default withReplyContext(Form)
