import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Components
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
/**
 * Styles
 */
import {
  IconApplications,
  IconVehicleRegistration,
  IconMovingInOut,
  IconAccessCard,
  IconRenovation
} from "components/Icons/Dashboard"
import styles from "./styles"
/**
 * Icons
 */
/**
 * Hooks
 */
import {
  TotalApplicationsHook,
  TotalMovingHook,
  TotalVehiclesHook,
  TotalAccessCardHook,
  TotalRenovationHook
} from "./hooks"

const Applications = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const { totalApplications, isValidating } = TotalApplicationsHook(selectedDate)
  const { totalMoving } = TotalMovingHook(selectedDate)
  const { totalVehicles } = TotalVehiclesHook(selectedDate)
  const { totalAccessCards } = TotalAccessCardHook(selectedDate)
  const { totalRenovations } = TotalRenovationHook(selectedDate)
  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`

  const options = [
    {
      name: "Moving In / Out",
      total: totalMoving,
      icon: IconMovingInOut
    },
    {
      name: "Vehicle Registration",
      total: totalVehicles,
      icon: IconVehicleRegistration
    },
    {
      name: "Access Card",
      total: totalAccessCards,
      icon: IconAccessCard
    },
    {
      name: "Renovation",
      total: totalRenovations,
      icon: IconRenovation
    }
  ]

  function ViewContent(): JSX.Element {
    return (
      <Box>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade in timeout={1000}>
            <Box>
              <Box
                alignItems="center"
                justifyContent="space-between"
                padding="26px 50px"
              >
                <Box className={classes.title}>Total Applications</Box>
                <Box className={classes.subtitleStyle}>{currentReport}</Box>
                <Box className={classes.totalStyle}>{totalApplications}</Box>
              </Box>

              <Box>
                {options.map((item) => (
                  <div className={classes.feedbackList} key={item.name}>
                    <div className={classes.body}>
                      <div>{item.icon}</div>
                      <div className="feedbackListName">{item.name}</div>
                    </div>
                    <div className={classes.body}>{item.total}</div>
                  </div>
                ))}
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    )
  }

  return (
    <Card>
      <Box className={classes.header}>
        <CardTitle title="Application" icon={IconApplications} />
      </Box>

      <ViewContent />
    </Card>
  )
}

export default Applications
