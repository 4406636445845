/* eslint global-require: "warn"*/
import React, { useEffect, useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
/**
 * Components
 */
/**
 * Context
 */
import { AppContext } from "App"
import { Context } from "context/Dashboard/Facilites/forms/addBooking/Context"
/**
 * Service
 */
import service from "services/Settings/PropertyManagement/Facilities"
/**
 * Utils
 */
import { toUnix, checkDateBetween, formatDate, toUnixTimeZone } from "utils/date"
import Timeslots from "./Timeslots"
/**
 * Styles
 */
import styles from "./styles"

interface Props {
  settings: Record<string, any>
  facility: Record<string, any>
}

function FacilityItem({ facility, settings }: Props): JSX.Element {
  const classes = styles()
  const [timeslots, setTimeSlots] = useState<Array<{}>>([])
  // const [timeslotsLoading, setTimeSlotsLoading] = useState<boolean>(false)
  const { bookingDate, categories } = useContext(Context)
  const { condoInfo } = useContext(AppContext)

  const suspendedStartDate = facility?.suspended_start_date
  const suspendedEndDate = facility?.suspended_end_date
  const isInBetween = checkDateBetween(
    toUnix(bookingDate as string),
    suspendedStartDate,
    suspendedEndDate
  )

  function isOpen(): boolean {
    if (facility?.status === 1) {
      return true
    }

    if (facility?.status === 2 && !isInBetween) {
      return true
    }

    if (facility?.status === 2 && isInBetween) {
      return false
    }

    return true
  }

  const fetchTimeslots = async (): Promise<Response | null> => {
    // setTimeSlotsLoading(true)
    try {
      const response = await service.getTimeSlots({
        "facility_uid": facility?._uid,
        "facility_setting_uid": settings?._uid,
        "booking_date": toUnixTimeZone(
          `${formatDate(bookingDate as string)} 00:00:00`,
          condoInfo?.timezone
        )
      })

      const data = response?.data?._data

      setTimeSlots(data)
      // setTimeSlotsLoading(false)
      return response
    } catch (e) {
      // setTimeSlotsLoading(false)
      return e
    }
  }

  useEffect(() => {
    fetchTimeslots()
  }, [])

  useEffect(() => {
    fetchTimeslots()
  }, [categories])

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={facility?.facility_photo?.photos[0]?.url || ""}
          title={facility?.name}
        />
      </CardActionArea>
      <CardContent>
        <div className={classes.infoMain}>
          <Typography variant="h6" component="h6">
            {facility?.name}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            <Box component="small" color="#777E86">
              {isOpen() ? "Open" : "Suspended"}
            </Box>
          </Typography>

          <div className={classes.info}>
            <Typography gutterBottom variant="body2">
              <Box component="span" color="#777E86">
                {`Location: ${facility?.location}`}
              </Box>
            </Typography>
            <Typography gutterBottom variant="body2">
              <Box component="span" color="#777E86">
                {`Capacity: ${facility?.capacity}`}
              </Box>
            </Typography>
          </div>
        </div>
      </CardContent>

      <CardActions>
        {isOpen() ? (
          <Timeslots timeslots={timeslots} settings={settings} facility={facility} />
        ) : (
          <Box padding="0 8px">
            <Typography gutterBottom variant="body2">
              <Box component="span" color="#777E86">
                {facility?.comments}
              </Box>
            </Typography>
          </Box>
        )}
      </CardActions>
    </Card>
  )
}
export default FacilityItem
