import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import RoleTabs from "components/Table/components/Tabs"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PermissionMessage from "components/PermissionMessage"

import FormCondoInfo from "pages/Settings/Others/CondoInfo/components/FormCondoInfo"
import FormTermsConditions from "pages/Settings/Others/CondoInfo/components/FormTermsConditions"

/** Context */
import { AppContext } from "App"
import withContext from "./withContext"
import { CtxType } from "./Context"

const SettingsOthersView: React.FC<CtxType> = ({ activeTab, setActiveTab }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  function ViewCondoInfo() {
    if (activeTab !== 0) {
      return <div />
    }

    return <FormCondoInfo />
  }

  function ViewTerms() {
    if (activeTab !== 1) {
      return <div />
    }

    return <FormTermsConditions />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box>
          <PageHeaderTitle
            title="Condo Information"
            breadcrumbs="SETTINGS / account settings / condo information"
          />
        </Box>

        <Box>
          <Box display="flex" minHeight="52px">
            <RoleTabs
              activeItem={activeTab}
              selectItem={setActiveTab}
              options={["Condo Info", "Terms & Conditions"]}
            />
          </Box>
          <Card>
            <Box padding="50px 0 0">
              {ViewCondoInfo()}
              {ViewTerms()}
            </Box>
          </Card>
        </Box>
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.manage_condo_settings) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(SettingsOthersView)
