import React from "react"

import VehiclesProvider from "context/Dashboard/UnitManagement/categories/vehicles/Provider"
import VehiclesView from "context/Dashboard/UnitManagement/categories/vehicles/View"

export default (): JSX.Element => (
  <VehiclesProvider>
    <VehiclesView />
  </VehiclesProvider>
)
