import React, { useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"

import Label from "components/Forms/Label"
import { CondoUID } from "config/Common/CondoInfo"
/** Service */
import UnitManagementAPI from "services/Dashboard/UnitManagement"

interface NamesProps {
  value: string
  onChange: Function
  onBlur?: Function
  unitUID: string
  error?: boolean
  disabled?: boolean
  helperText?: string | boolean | undefined
  placeholder?: string
}

const Names: React.FC<NamesProps> = ({
  value,
  onChange,
  onBlur,
  unitUID,
  helperText,
  error = false,
  disabled = false,
  placeholder
}) => {
  const [names, setNames] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectPlaceholder = placeholder || "Select Resident Name"

  const fetchResidents = async () => {
    setIsLoading(true)
    try {
      /** Params: condoUId / unitUID */
      const response = await UnitManagementAPI.getResidentsByUnit({
        "condo_uid": CondoUID,
        "unit_uid": unitUID
      })
      if (response.data._data) {
        let available = false
        response.data._data.forEach((ele: any) => {
          if (ele.name === value) {
            available = true
          }
        })
        if (!available) {
          response.data._data.push({
            name: value,
            "account_info_uid": ""
          })
        }
      }
      setNames(response.data._data)
      setIsLoading(false)
      return
    } catch (e) {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchResidents()
  }, [unitUID])

  return (
    <Box>
      <Label label="Name" />
      <FormControl variant="outlined" error={error} disabled={disabled}>
        {isLoading ? (
          <Select
            placeholder={selectPlaceholder}
            displayEmpty
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur && onBlur(e)}
            error={error}
            name="blockUnit"
          >
            <MenuItem value="" disabled>
              Loading...
            </MenuItem>
          </Select>
        ) : (
          <>
            <Select
              label={selectPlaceholder}
              placeholder={selectPlaceholder}
              displayEmpty
              value={value}
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur && onBlur(e)}
              name="residentName"
            >
              <MenuItem value="" disabled>
                {selectPlaceholder}
              </MenuItem>
              {names?.map((name: any) => (
                <MenuItem
                  className={name._uid}
                  value={name.name}
                  data-userInfo={JSON.stringify(name)}
                >
                  {name.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </>
        )}
      </FormControl>
    </Box>
  )
}
export default Names
