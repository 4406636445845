import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import { Typography } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
/**
 * Context
 */
// import { Context } from "context/Dashboard/Reports/main/Context"
/**
 * Components
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Hooks
 */
import { fromUnix } from "utils/date"
import { checkDate } from "utils/helpers"
import {
  ExpiringSoonContracts,
  ExpiringSoonLicensePermits,
  ExpiringSoonOthers,
  ExpiringSoonCyclicService
} from "./hooks"

const Icon = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C7.172 0 6.5 0.672 6.5 1.5V2.19531C3.91318 2.86209 2 5.2048 2 8V14L0 16V17H6.26953C6.09344 17.3039 6.00048 17.6488 6 18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20C8.53043 20 9.03914 19.7893 9.41421 19.4142C9.78929 19.0391 10 18.5304 10 18C9.99893 17.6486 9.90529 17.3037 9.72852 17H16V16L14 14V8C14 5.2048 12.0868 2.86209 9.5 2.19531V1.5C9.5 0.672 8.828 0 8 0ZM8 4C10.206 4 12 5.794 12 8V14V14.8281L12.1719 15H3.82812L4 14.8281V14V8C4 5.794 5.794 4 8 4Z"
      fill="#464646"
    />
  </svg>
)

const styles = makeStyles((theme: Theme) => ({
  timelineTitle: {
    color: theme.palette.body.main,
    fontSize: "14px"
  },
  timelineSubtitle: {
    color: theme.palette.body.secondary,
    fontSize: "12px"
  },
  dot: {
    width: "8px",
    height: "8px",
    backgroundColor: theme.palette.body.pink,
    position: "absolute",
    top: "5px",
    left: "-25px",
    borderRadius: "50%"
  },
  totalStyle: {
    color: theme.palette.body.green,
    fontSize: "48px",
    fontWeight: 500,
    "& span": {
      fontSize: "10px",
      paddingRight: "5px"
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2",
    borderTop: "1px solid #F2F2F2"
  },
  total: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 23px 0"
  },
  totalAmount: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 23px 0"
  },
  breakdown: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "15px",
    marginBottom: "15px",
    position: "relative"
  },
  content: {
    borderLeft: "3px solid #EDF0F1",
    padding: "10px 20px 0 20px",
    margin: "30px 30px 30px"
  }
}))

const ExpiringSoon: React.FC = () => {
  const classes = styles()

  const { expiringSoonList, isValidating } = ExpiringSoonContracts()
  const {
    expiringSoonListPermits,
    isValidatingPermits
  } = ExpiringSoonLicensePermits()
  const { expiringSoonListOthers, isValidatingOthers } = ExpiringSoonOthers()
  const { expiringSoonListCyclic, isValidatingCyclic } = ExpiringSoonCyclicService()

  function ViewHeader(): JSX.Element {
    return (
      <Box className={classes.header}>
        <CardTitle title="Expiring Soon" icon={Icon} />
      </Box>
    )
  }

  function ViewContracts(props: any): JSX.Element {
    const { data } = props
    if (data?.length === 0) {
      return (
        <Fade in timeout={1000}>
          <Box>
            <Typography>No data</Typography>
          </Box>
        </Fade>
      )
    }
    return (
      <Box>
        {data?.map((item: any) => {
          return (
            <Box className={classes.breakdown}>
              <Box className={classes.timelineTitle}>{item.title}</Box>
              <Box className={classes.timelineSubtitle}>
                Due on 
                {' '}
                {checkDate(item?.end_date, fromUnix(item?.end_date))}
              </Box>
              <Box className={classes.dot} />
            </Box>
          )
        })}
      </Box>
    )
  }

  function ViewContent(): JSX.Element {
    if (
      isValidating ||
      isValidatingPermits ||
      isValidatingOthers ||
      isValidatingCyclic
    ) {
      return <Loader dark />
    }
    return (
      <Fade in timeout={1000}>
        <Box>
          {/* <ViewTotal /> */}
          <Box className={classes.subHeader}>
            <Typography color="primary">Contracts</Typography>
          </Box>
          <Box className={classes.content}>
            <ViewContracts data={expiringSoonList} />
          </Box>
          <Box className={classes.subHeader}>
            <Typography color="primary">License and permit</Typography>
          </Box>
          <Box className={classes.content}>
            <ViewContracts data={expiringSoonListPermits} />
          </Box>
          <Box className={classes.subHeader}>
            <Typography color="primary">Cyclic Service</Typography>
          </Box>
          <Box className={classes.content}>
            <ViewContracts data={expiringSoonListCyclic} />
          </Box>
          <Box className={classes.subHeader}>
            <Typography color="primary">Others</Typography>
          </Box>
          <Box className={classes.content}>
            <ViewContracts data={expiringSoonListOthers} />
          </Box>
        </Box>
      </Fade>
    )
  }

  return (
    <Card>
      <Box>
        <ViewHeader />
        <ViewContent />
      </Box>
    </Card>
  )
}

export default ExpiringSoon
