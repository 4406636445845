import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "./styles.css"

interface Props {
  content: any
  onBlur?: Function
  setContent?: Function
  error?: boolean
}

const EditorForm: React.FC<Props> = ({ content, setContent, error, onBlur }) => {
  const [formContent, setFormContent] = useState<any>(null)
  const html = content
  const contentBlock = htmlToDraft(html)

  useEffect(() => {
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const blockState = EditorState.createWithContent(contentState)

      setFormContent(blockState)
    }
  }, [])

  const updateContent = (e: any) => {
    setFormContent(e)
    setContent && setContent(draftToHtml(convertToRaw(e?.getCurrentContent())))
  }

  return (
    <Box
      border={error ? "1px solid #FF0000" : "1px solid rgba(0, 0, 0, 0.23)"}
      borderRadius="4px"
      overflow="hidden"
      height="500px"
      onBlur={(e) => onBlur && onBlur(e)}
    >
      <Editor
        editorState={formContent}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"]
          },
          list: {
            inDropdown: false,
            options: ["unordered", "ordered"]
          },
          blockType: {
            // inDropdown: true,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              "H4",
              "H5",
              "H6",
              "Blockquote",
              "Code"
            ]
          },
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "remove",
            "history"
          ]
        }}
        onEditorStateChange={(e: any) => updateContent(e)}
      />
    </Box>
  )
}

export default EditorForm
