import React, { useState, useEffect, FunctionComponent } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Context */
import service from "services/Settings/AccountManagement/Staff"
import { Context } from "./Context"

export interface Props {
  sendBack?: Function
}

const Provider: FunctionComponent<Props> = ({ children, sendBack }) => {
  const [submitting, setSubmitting] = useState(false)
  const [staffPayload, setStaffPayload] = useState({})
  const [activeRole, setActiveRole] = useState("")
  const [activeRoleName, setActiveRoleName] = useState("")
  const [activeCategory, setActiveCategory] = useState("")
  const [isFetchingRoles, setisFetchingRoles] = useState(false)
  const [payloads, setPayloads] = useState({})
  const [showForm, setShowForm] = useState(false)
  const [formType, setFormType] = useState(1)
  const [formDetails, setFormDetails] = useState({
    roleName: "",
    category: ""
  })
  const [isRolesUpdating, setIsRolesUpdating] = useState(false)

  /**
   * Notification
   */
  const notifySuccess = (roleName: string, action: number) =>
    toast(`Successfully ${action === 1 ? "created" : "updated"} the role.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */

  async function init() {
    try {
      const response = await service.getRoles({
        "user_type": 2
      })

      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  function showFormType(state: boolean, type: number): void {
    setShowForm(state)
    setFormType(type)

    if (type === 2) {
      setFormDetails({
        ...formDetails,
        roleName: activeRoleName,
        category: activeCategory
      })
    } else {
      setFormDetails({
        ...formDetails,
        roleName: "",
        category: ""
      })
    }
  }

  async function fetchRolesPermission(roleId: string) {
    setisFetchingRoles(true)
    try {
      const response = await service.getRolePermissions(roleId)
      setPayloads(response?.data?._data?.permission)
      setisFetchingRoles(false)
      return response
    } catch (e) {
      setisFetchingRoles(false)
      return e
    }
  }

  function selectTab(roleData: string) {
    const roleString = roleData?.split("/")
    setActiveRole(roleString[0])
    setActiveRoleName(roleString[1])
    setActiveCategory(roleString[2])
    setFormDetails({ ...formDetails, category: roleString[2] })
    fetchRolesPermission(roleString[0])
  }

  const setup = () =>
    init().then((res) => {
      Object.keys(res).map(() => {
        const newlyCreated = res.filter(
          (nc: any) => nc?.role_name === formDetails?.roleName
        )

        if (formType === 1) {
          selectTab(
            `${newlyCreated[0]?._uid}/${newlyCreated[0]?.role_name}/${newlyCreated[0]?.category}`
          )
        } else {
          selectTab(
            `${activeRole}/${formDetails?.roleName}/${formDetails?.category}`
          )
        }

        return res
      })
    })

  async function handleFormSubmit() {
    const payload = {
      "role_name": formDetails?.roleName,
      "category": Number(formDetails?.category),
      "permission_type": 2
    }

    setSubmitting(true)

    const endpoint =
      formType === 1
        ? service.createStaffRole(payload)
        : service.updateStaffRole(payload, activeRole)

    try {
      const response = await endpoint
      await mutate("fetchRoles-usertype-2")
      await setup()
      showFormType(false, 1)
      notifySuccess(activeRoleName, formType)
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const initSetup = async () => {
    return init()
      .then((res) => {
        return Object.keys(res).map(() => {
          setActiveRole(res[0]?._uid)
          setActiveRoleName(res[0]?.role_name)
          setActiveCategory(res[0]?.category)
          setFormDetails({
            roleName: res[0]?.role_name,
            category: res[0]?.category
          })

          return res[0]
        })
      })
      .then((res) => fetchRolesPermission(res[0]?._uid))
  }

  useEffect(() => {
    init()
      .then((res) => {
        return Object.keys(res).map(() => {
          if (activeRole === "") {
            setActiveRole(res[0]?._uid)
            setActiveRoleName(res[0]?.role_name)
            setActiveCategory(res[0]?.category)
            setFormDetails({
              roleName: res[0]?.role_name,
              category: res[0]?.category
            })
          }

          return res[0]
        })
      })
      .then((res) => fetchRolesPermission(res[0]?._uid))
  }, [])

  return (
    <Context.Provider
      value={{
        staffPayload,
        activeCategory,
        activeRole,
        activeRoleName,
        showForm,
        formType,
        formDetails,
        setActiveRoleName,
        setStaffPayload,
        setActiveRole,
        selectTab,
        setPayloads,
        setShowForm,
        showFormType,
        handleFormSubmit,
        setFormDetails,
        initSetup,
        setIsRolesUpdating,
        isRolesUpdating,
        isFetchingRoles,
        submitting,
        sendBack,
        payloads
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
