import * as React from "react"

const Others: React.FC = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C4.448 0 4 0.448 4 1V1.5V3.49219L0.992188 5.24805C0.378863 5.60565 0 6.26468 0 6.97461V17C0 18.0931 0.906937 19 2 19H18C19.0931 19 20 18.0931 20 17V6.97461C20 6.26468 19.6211 5.60565 19.0078 5.24805L16 3.49219V1.5V1C16 0.448 15.552 0 15 0H14.5H5.5H5ZM6.91211 2H13.0879C13.1625 2.21031 13.2831 2.40132 13.4409 2.55911C13.5987 2.7169 13.7897 2.83749 14 2.91211V9.33398L12.998 9.95703C12.9868 9.16919 12.666 8.41741 12.105 7.86412C11.544 7.31082 10.7879 7.00043 10 7C9.21208 7.00043 8.45596 7.31082 7.89499 7.86412C7.33402 8.41741 7.01324 9.16919 7.00195 9.95703L6 9.33398V2.91211C6.21031 2.83749 6.40131 2.7169 6.55911 2.55911C6.7169 2.40132 6.83749 2.21031 6.91211 2ZM10 4C9.447 4 9 4.448 9 5C9 5.552 9.447 6 10 6C10.553 6 11 5.552 11 5C11 4.448 10.553 4 10 4ZM4 5.80859V8.08984L2.10742 6.91211L4 5.80859ZM16 5.80859L17.8926 6.91211L16 8.08984V5.80859ZM2 9.20117L10 14.1777L18 9.20117V17H2V9.20117Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Others
