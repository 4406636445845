import React from "react"

import UMProvider from "context/Dashboard/UnitManagement/main/Provider"
import UMView from "context/Dashboard/UnitManagement/main/View"

export default (): JSX.Element => (
  <UMProvider>
    <UMView />
  </UMProvider>
)
