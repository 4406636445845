import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

import FormLabel from "components/Forms/Label"

interface Props {
  value: string | number
  label: any
  name: string
  ariaLabel: string
  onChange: Function
  options: Record<string, any>
}

function RadioGroupList({
  value,
  label,
  name,
  ariaLabel,
  onChange,
  options
}: Props) {
  return (
    <FormControl>
      <FormLabel label={label} large />

      <RadioGroup
        row
        aria-label={ariaLabel}
        name={name}
        value={String(value)}
        onChange={(inputVal: any) => onChange(inputVal)}
      >
        {options &&
          Object.keys(options).map((option: any) => {
            return (
              <FormControlLabel
                value={option}
                control={<Radio color="primary" />}
                label={options[option]}
                labelPlacement="end"
              />
            )
          })}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioGroupList
