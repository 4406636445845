import React from "react"

import styles from "./styles"

interface FilterWithInfoProps {
  name: string
  info?: string
  type: string
  color: string
  action: Function
  icon: any
  hasViewAll?: boolean
  size?: string
  center?: boolean
  style?: any
  currency?: string
}

const FilterWithInfo: React.FC<FilterWithInfoProps> = ({
  action,
  name,
  info = "",
  type,
  color,
  icon,
  hasViewAll,
  size,
  center,
  style,
  currency
}) => {
  const { container, iconContainer, infoContainer, iconSmallContainer } = styles()
  const iconWidthStyle = size === "small" ? iconSmallContainer : iconContainer

  const isIconObject = typeof icon === "object"

  return (
    <div
      className={container}
      role="button"
      tabIndex={0}
      onClick={() => action(type, name)}
      style={{ justifyContent: center ? "center" : "flex-start", ...style }}
    >
      <div className={iconWidthStyle} style={{ background: color }}>
        {isIconObject ? icon : icon()}
      </div>
      <div className={infoContainer}>
        <div className="name">
          {currency && <span>{currency}</span>}
          {name}
        </div>
        {info !== "" && <div className="info">{info}</div>}
        {hasViewAll && <div className="viewall">view all</div>}
      </div>
    </div>
  )
}

export default FilterWithInfo
