import React from "react"

import FeedbackSecurityProvider from "context/Dashboard/Feedback/categories/security/Provider"
import FeedbackSecurityView from "context/Dashboard/Feedback/categories/security/View"

const FeedbackSecurityProviderMain: React.FC = (): JSX.Element => (
  <FeedbackSecurityProvider>
    <FeedbackSecurityView />
  </FeedbackSecurityProvider>
)

export default FeedbackSecurityProviderMain
