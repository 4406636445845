import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
/**
 * Components
 */
import Header from "components/Header"
import Drawer from "components/Drawer"
import Value from "components/Forms/Value"
import Label from "components/Forms/Label"
/**
 * Context
 */
import { AppContext } from "App"
/**
 * Config
 */
import { status } from "config/Dashboard/Facilities"
/**
 * utils
 */
import { fromUnix, fromUnixTime } from "utils/date"
import { checkValue, checkDate, toNumeral } from "utils/helpers"
/**
 * Styles
 */
import styles from "./styles"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
}

const BookingDetails: React.FC<DetailsProps> = ({
  openState,
  setopenState,
  data
}) => {
  const classes = styles()
  const { condoInfo } = useContext(AppContext)

  const startTime = fromUnixTime(
    data?.facility_time_slot?.start_time,
    false,
    "HH:mm"
  )
  const endTime = fromUnixTime(data?.facility_time_slot?.end_time, false, "HH:mm")

  const timeValue = `${startTime} - ${endTime}`

  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header title={data?.facility?.name} subtitle="Facilities" subtitleAbove />
      <Box display="flex" flexDirection="column" width="100%">
        <Box className={classes.section}>
          <Box>
            <Label label="Application Date" />
            <Value val={checkDate(data?._created, fromUnix(data?._created))} />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box>
            <Box className={classes.sectionTitle}>Personal Details</Box>
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Block/Unit no." />
            <Value val={checkValue(data?.unit?.short_name)} />
          </Box>
          <Box>
            <Label label="Name" />
            <Value val={checkValue(data?.booking_by_name)} />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box>
            <Box className={classes.sectionTitle}>Booking Details</Box>
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Facility Type" />
            <Value val={checkValue(data?.facility?.name)} />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Booking Date" />
            <Value
              val={checkDate(data?.booking_date, fromUnix(data?.booking_date))}
            />
          </Box>
          <Box>
            <Label label="Timings" />
            <Value val={checkValue(timeValue)} />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box>
            <Box className={classes.sectionTitle}>Payment Details</Box>
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Deposit Amount" />
            <Value
              val={checkValue(
                data?.deposit_for_booking?.amt,
                toNumeral(data?.deposit_for_booking?.amt, condoInfo?.currency)
              )}
            />
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Usage Amount" />
            <Value
              val={checkValue(
                data?.usage_fee_for_booking?.amt,
                toNumeral(data?.usage_fee_for_booking?.amt, condoInfo?.currency)
              )}
            />
          </Box>
          <Box>
            <Label label="Usage Tax" />
            <Value
              val={checkValue(
                data?.usage_fee_for_booking?.tax,
                toNumeral(data?.usage_fee_for_booking?.tax, condoInfo?.currency)
              )}
            />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box>
            <Box className={classes.sectionTitle}>Status & Remarks</Box>
          </Box>
          <Box className={classes.sectionItem}>
            <Label label="Status" />
            <Value val={checkValue(status[data?.status])} />
          </Box>
          <Box>
            <Label label="Remarks" />
            <Value val={checkValue(data?.remarks)} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
export default BookingDetails
