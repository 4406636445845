import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import BlockUnit from "components/Common/BlockUnit/new"
import FormInput from "components/Forms/FormInput"
import Names from "components/Common/Names"
import RefButton from "components/Forms/RefButton"
/** Config */
import validation from "config/Dashboard/Bookkeeping/validation"
/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/Context"
import withContext from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/withContext"
/** Ref */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/paymentSchedule/createPaymentSchedule/View"
import { handleBlockUnit, handleResidentName } from "utils/functions"
import styles from "./styles"
/** helpers */

const PersonalDetails: React.FC<CtxType> = ({ psDetails, setPsDetails }) => {
  const classes = styles()

  /** Methods */

  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFieldValue(key, e?.target?.value)
    setPsDetails &&
      setPsDetails({
        ...psDetails,
        [key]: e?.target?.value
      })
  }

  return (
    <div className={classes.personalDetails}>
      <Box margin="auto" padding="0 0 40px 0">
        <FormWrapper title="Personal Details" width="60%">
          <Formik
            initialValues={{
              unitUID: psDetails?.unitUID,
              payeeName: psDetails?.payeeName,
              payeeAddress: psDetails?.payeeAddress,
              payeePhone: psDetails?.payeePhone,
              payeeEmail: psDetails?.payeeEmail
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={validation.personalDetails}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue
            }): JSX.Element => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box width="60%" margin="auto" className={classes.spaceBottom}>
                    <Box className={classes.sectionRow}>
                      <Box className={classes.sectionRowLeft}>
                        <BlockUnit
                          iValue={psDetails.unitUID}
                          onChange={(value: string) =>
                            handleBlockUnit(
                              setFieldValue,
                              value,
                              setPsDetails,
                              psDetails,
                              "unitUID",
                              "payeeAccountUID"
                            )}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.unitUID && Boolean(errors.unitUID)}
                          helperText={
                            touched.unitUID &&
                            errors.unitUID &&
                            errors.unitUID.toString()
                          }
                        />
                      </Box>

                      <Box className={classes.sectionRowRight}>
                        <Names
                          placeholder="Select Name"
                          value={psDetails.payeeName}
                          onChange={(value: Event) =>
                            handleResidentName(
                              setFieldValue,
                              value,
                              setPsDetails,
                              psDetails,
                              "payeeName",
                              "payeeAccountUID",
                              "userDetailUid",
                              "payeeEmail",
                              "payeePhone",
                              "payeeAddress"
                            )}
                          unitUID={psDetails.unitUID}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.payeeName && Boolean(errors.payeeName)}
                          helperText={
                            errors.payeeName &&
                            touched.payeeName &&
                            String(errors.payeeName)
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="payeeEmail"
                        label="Email Address"
                        placeholder="Email Address"
                        value={psDetails.payeeEmail}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "payeeEmail", e)}
                        error={touched.payeeEmail && Boolean(errors.payeeEmail)}
                        helperText={
                          errors.payeeEmail &&
                          touched.payeeEmail &&
                          errors.payeeEmail
                        }
                      />
                    </Box>
                    <Box className={classes.spaceBottom}>
                      <Box flex="1">
                        <FormInput
                          name="payeePhone"
                          label="Phone Number"
                          placeholder="Phone Number"
                          value={psDetails.payeePhone}
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(setFieldValue, "payeePhone", e)}
                          onBlur={handleBlur}
                          error={touched.payeePhone && Boolean(errors.payeePhone)}
                          helperText={
                            errors.payeePhone &&
                            touched.payeePhone &&
                            errors.payeePhone
                          }
                        />
                      </Box>
                    </Box>
                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="payeeAddress"
                        label="Registered Address"
                        placeholder="Registered Address"
                        value={psDetails.payeeAddress}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(setFieldValue, "payeeAddress", e)}
                        onBlur={handleBlur}
                        error={touched.payeeAddress && Boolean(errors.payeeAddress)}
                        helperText={
                          errors.payeeAddress &&
                          touched.payeeAddress &&
                          errors.payeeAddress
                        }
                      />
                    </Box>
                  </Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </form>
              )
            }}
          </Formik>
        </FormWrapper>
      </Box>
    </div>
  )
}

export default withContext(PersonalDetails)
