const ApplicationsConfig: any = {
  status: {
    1: "Pending",
    2: "Approved",
    3: "Rejected",
    4: "Cancelled"
  },
  statusVehicles: {
    1: "Pending",
    2: "Approved",
    3: "Rejected",
    4: "Cancelled"
  },
  statusColor: {
    1: "#FBA11A",
    2: "#166CBB",
    3: "#DE4625",
    4: "#D05611"
  },
  tableName: {
    0: "fetchApplicationsMovingInOut",
    1: "fetchApplicationsVehicle",
    2: "fetchApplicationsAccessCard",
    3: "FetchApplicationsRenovation"
  },
  tableType: {
    1: "movinginout",
    0: "vehicleregistration",
    2: "addaccesscard",
    3: "renovation"
  },
  type: {
    1: "Vehicle Registration",
    2: "Moving In/Out",
    3: "Additional Card",
    4: "Replacement for Lost Card",
    5: "Replacement for Damaged Card",
    6: "Renovation"
  },
  accessCardType: {
    3: "Additional Card",
    4: "Replacement for Lost Card",
    5: "Replacement for Damaged Card"
  },
  vehicleType: {
    1: "Car",
    2: "Motorcycle",
    3: "Van",
    4: "Truck",
    5: "Others"
  }
}

export const {
  status,
  statusVehicles,
  statusColor,
  tableName,
  tableType,
  type,
  vehicleType,
  accessCardType
} = ApplicationsConfig
