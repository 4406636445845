import React from "react"

export type CtxType = {
  activeFilter: number
  showDetails: boolean
  isDeleting: boolean
  activeDetails: Record<string, any>
  downloadData: Array<{}>
  setShowDetails: Function
  setActiveDetails: Function
  setDownloadData: Function
  setActiveFilter: Function
  filterTable: Function
  showMain: Function
  showAddApplication: Function
  showUpdateApplication: Function
  showApproveApplication: Function
  showTable: Function
  handleCancelItem: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
