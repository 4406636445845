import * as React from "react"

const Security: React.FC = () => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.333344L0 5.66668C0 5.66668 0 11 0 13.6667C0 24.1067 8.58533 28.9813 12 29.6667C15.4147 28.9813 24 24.1067 24 13.6667C24 11 24 5.66668 24 5.66668L12 0.333344ZM21.3333 13.6667C21.3333 21.8453 14.9533 26.0053 12 26.916C9.04667 26.0053 2.66667 21.8453 2.66667 13.6667V7.40001L12 3.25201L21.3333 7.40001V13.6667Z"
        fill="white"
      />
    </svg>
  )
}

export default Security
