import React, { FunctionComponent } from "react"

import ResetPasswordProvider from "context/Settings/AccountManagement/Admin/forms/resetPassword/Provider"
import ResetPasswordView from "context/Settings/AccountManagement/Admin/forms/resetPassword/View"

const AddAdmin: FunctionComponent = () => (
  <ResetPasswordProvider>
    <ResetPasswordView />
  </ResetPasswordProvider>
)

export default AddAdmin
