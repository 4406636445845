import React from "react"

const IconCleanliness = () => (
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6667 0.666656V15.526C17.3789 16.1259 15.6667 18.1995 15.6667 20.6667V23.237C15.6667 24.1893 15.399 24.293 15.0547 24.4713C14.7104 24.6497 14.3333 24.6667 14.3333 24.6667V27.3333H22.3333C24.5266 27.3333 26.3333 25.5266 26.3333 23.3333V20.6667C26.3333 18.1995 24.6211 16.1259 22.3333 15.526V0.666656H19.6667ZM4.58594 1.96874L2.30729 3.35416L5.53385 8.66666H0.757812L3.86979 27.3333H11.6667V24.6667H6.13021L3.90885 11.3333H7.15365L9.19271 14.6927H9.19531C9.31413 14.8881 9.48122 15.0497 9.68052 15.1619C9.87982 15.2741 10.1046 15.3331 10.3333 15.3333C10.687 15.3333 11.0261 15.1928 11.2761 14.9428C11.5262 14.6928 11.6667 14.3536 11.6667 14C11.6663 13.7555 11.5987 13.5159 11.4714 13.3073H11.474L4.58594 1.96874ZM9.66667 1.99999C9.056 1.99999 8.4894 2.17686 7.9974 2.46353L10.9583 7.33332H16.9323C16.975 7.11732 17 6.89466 17 6.66666C17 4.82532 15.508 3.33332 13.6667 3.33332C13.2467 3.33332 12.8485 3.42116 12.4792 3.56249C11.8885 2.62649 10.8547 1.99999 9.66667 1.99999ZM11.7526 8.66666L13.3724 11.3333H15.4271L14.6875 15.7708C15.5022 14.7228 16.5662 13.8825 17.7969 13.3411L18.5755 8.66666H11.7526ZM21 18C22.4889 18 23.6667 19.1778 23.6667 20.6667V23.3333C23.6667 24.0867 23.0867 24.6667 22.3333 24.6667H17.9219C18.0952 24.2117 18.3333 23.8031 18.3333 23.237V20.6667C18.3333 19.1778 19.5111 18 21 18Z"
      fill="white"
    />
  </svg>
)

const IconSecurity = () => (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.333344L0 5.66668C0 5.66668 0 11 0 13.6667C0 24.1067 8.58533 28.9813 12 29.6667C15.4147 28.9813 24 24.1067 24 13.6667C24 11 24 5.66668 24 5.66668L12 0.333344ZM21.3333 13.6667C21.3333 21.8453 14.9533 26.0053 12 26.916C9.04667 26.0053 2.66667 21.8453 2.66667 13.6667V7.40001L12 3.25201L21.3333 7.40001V13.6667Z"
      fill="white"
    />
  </svg>
)

const IconDefects = () => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.95312 0.0677185L7.04688 1.9323L8.90365 3.82814C8.34674 4.51543 8 5.37972 8 6.33334C8 6.40498 8.01712 6.44775 8.02083 6.51303C7.37369 6.69793 6.76524 7.02186 6.27083 7.51564C5.80876 7.97644 5.38893 8.47696 5 9.00001H0V11.6667H3.48698C3.1371 12.5212 2.88581 13.4139 2.76302 14.3333H0V17H2.72917C2.80562 17.9272 2.95521 18.8232 3.19792 19.6667H0V22.3333H4.36979C4.55108 22.6217 4.74619 22.9015 4.95833 23.1667C6.53545 25.1381 9 26.3333 12 26.3333C15 26.3333 17.4646 25.1381 19.0417 23.1667C19.2538 22.9015 19.4489 22.6217 19.6302 22.3333H24V19.6667H20.8021C21.0449 18.8228 21.1981 17.9283 21.2734 17H24V14.3333H21.2266C21.0997 13.4314 20.8495 12.5356 20.4922 11.6667H24V9.00001H18.9896C18.6048 8.47623 18.189 7.97422 17.7292 7.51564C17.2348 7.02186 16.6263 6.69793 15.9792 6.51303C15.9829 6.44775 16 6.40498 16 6.33334C16 5.37972 15.6533 4.51543 15.0964 3.82814L16.9531 1.9323L15.0469 0.0677185L12.7552 2.40886C12.5105 2.36209 12.2583 2.33334 12 2.33334C11.7417 2.33334 11.4895 2.36209 11.2448 2.40886L8.95312 0.0677185ZM9.16406 9.00001H14.8359C15.2289 9.00001 15.5935 9.14816 15.8464 9.40105V9.40366C17.546 11.0987 18.6667 13.6598 18.6667 15.6667C18.6667 18.1111 18.0479 20.1381 16.9583 21.5C16.0927 22.5821 14.9384 23.3054 13.3333 23.5599V11.6667H10.6667V23.5599C9.06158 23.3054 7.90731 22.5821 7.04167 21.5C5.95212 20.1381 5.33333 18.1111 5.33333 15.6667C5.33333 13.4209 6.42093 11.1316 8.15365 9.40366V9.40105C8.40654 9.14816 8.77111 9.00001 9.16406 9.00001Z"
      fill="white"
    />
  </svg>
)

const IconLandscape = () => (
  <svg
    width="24"
    height="28"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5427 4.91466L22.372 3.776C21.7627 3.18533 20.7773 3.18533 20.168 3.776L20 3.936V3.33333C20 1.86 18.8067 0.666664 17.3333 0.666664C15.86 0.666664 14.6667 1.86 14.6667 3.33333V3.93733L14.4973 3.77733C13.888 3.18666 12.9027 3.18666 12.2947 3.77733L11.124 4.916C10.5147 5.50666 10.5147 6.46533 11.124 7.05733L11.444 7.368C10.0973 8.18266 9.27467 9.47466 8.77733 10.876C7.37067 8.95466 5.044 7.40933 1.36133 7.33333L0 7.30533V8.66666C0 12.4013 1.59867 14.9733 3.57467 16.6667H0V19.3333H1.33333L2.47733 25.192C2.728 26.432 3.828 27.3333 5.09333 27.3333H13.5733C14.84 27.3333 15.94 26.432 16.1893 25.1907L17.308 19.596L17.312 19.3333H18.6667V16.6667H10.6667C10.6667 12.5493 11.4013 10.3307 13.1013 9.524C12.728 10.6067 13.1653 11.8293 14.188 12.388C15.316 13.0027 16.7187 12.568 17.3213 11.4173L17.3653 11.3307C17.3907 11.3307 17.4147 11.324 17.4387 11.3227L17.5227 11.4667C18.1013 12.572 19.4467 12.9893 20.5293 12.3987C21.6133 11.808 22.0213 10.4347 21.4427 9.33066L21.084 8.684C21.0893 8.66933 21.0933 8.65466 21.0973 8.64C21.5427 8.68 22 8.55466 22.3427 8.224L23.5453 7.05733C24.152 6.464 24.152 5.50666 23.5427 4.91466ZM7.97867 16.2307C6.16667 15.536 3.37067 13.8747 2.78 10.144C7.148 10.9453 7.86267 14.8307 7.97867 16.2307ZM14.64 19.3333L13.572 24.6667H5.092L4.02533 19.3333H14.64ZM17.3333 8.66666C16.5973 8.66666 16 8.06933 16 7.33333C16 6.59733 16.5973 6 17.3333 6C18.0693 6 18.6667 6.59733 18.6667 7.33333C18.6667 8.06933 18.0693 8.66666 17.3333 8.66666Z"
      fill="white"
    />
  </svg>
)

const IconOthers = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99999 0.666664V3.33333H24.6667V16.6667H27.3333V3.33333C27.3333 1.86 26.14 0.666664 24.6667 0.666664H5.99999ZM3.33332 6C1.86666 6 0.679677 7.2 0.679677 8.66666L0.666656 28.6667L5.99999 23.3333H19.3333C20.8 23.3333 22 22.1333 22 20.6667V8.66666C22 7.2 20.8 6 19.3333 6H3.33332ZM3.33332 8.66666H19.3333V20.6667H5.99999H4.89582L4.11457 21.4479L3.33332 22.2292V8.66666Z"
      fill="white"
    />
  </svg>
)

const IconAll = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00002 0.666656V3.33332H24.6667V16.6667H27.3334V3.33332C27.3334 1.85999 26.14 0.666656 24.6667 0.666656H6.00002ZM3.33335 5.99999C1.86669 5.99999 0.679708 7.19999 0.679708 8.66666L0.666687 28.6667L6.00002 23.3333H19.3334C20.8 23.3333 22 22.1333 22 20.6667V8.66666C22 7.19999 20.8 5.99999 19.3334 5.99999H3.33335ZM3.33335 8.66666H19.3334V20.6667H6.00002H4.89585L4.1146 21.4479L3.33335 22.2292V8.66666Z"
      fill="white"
    />
  </svg>
)

export const Menu = [
  {
    label: "All Feedback",
    icon: IconAll,
    color: "#004E8B"
  },
  {
    label: "Cleanliness",
    icon: IconCleanliness,
    color: "#057686"
  },
  {
    label: "Security",
    icon: IconSecurity,
    color: "#EC4B19"
  },
  {
    label: "Defects",
    icon: IconDefects,
    color: "#62CB77"
  },
  {
    label: "Landscape",
    icon: IconLandscape,
    color: "#EC4B19"
  },
  {
    label: "Others",
    icon: IconOthers,
    color: "#62CB77"
  }
]

const MenuIcons: {
  [index: string]: {}
} = {
  "all": IconAll,
  "cleanliness": IconCleanliness,
  "security": IconSecurity,
  "defects": IconDefects,
  "landscape": IconLandscape,
  "others": IconOthers
}

const TableIcons: {
  [index: string]: {}
} = {
  0: IconAll,
  1: IconCleanliness,
  2: IconSecurity,
  3: IconDefects,
  4: IconLandscape,
  5: IconOthers
}

export { MenuIcons, TableIcons }

export default Menu
