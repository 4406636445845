import SettingsFacilities from "pages/Settings/PropertyManagement/Facilities"
import SettingsApplications from "pages/Settings/PropertyManagement/Applications"
import SettingsBookkeeping from "pages/Settings/PropertyManagement/Bookkeeping"
import SettingsFeedback from "pages/Settings/PropertyManagement/Feedback"
import SettingsVisitorManagement from "pages/Settings/PropertyManagement/VisitorManagement"
// account management
import SettingsAdmin from "pages/Settings/AccountManagement/Admin"
import SettingsStaff from "pages/Settings/AccountManagement/Staff"
import SettingsOwnersTenants from "pages/Settings/AccountManagement/OwnersTenants"
// Others
import SettingsCondoInfo from "pages/Settings/Others/CondoInfo"
import SettingsSOS from "pages/Settings/Others/SOS"
import SettingsUploadCSV from "pages/Settings/Others/UploadCSV"

import SettingsProfile from "pages/Settings/Profile"

const SettingsRoute = [
  {
    route: "/settings/profile",
    component: SettingsProfile
  },
  {
    route: "/settings/property-management/facilities",
    component: SettingsFacilities
  },
  {
    route: "/settings/property-management/visitor-management",
    component: SettingsVisitorManagement
  },
  {
    route: "/settings/property-management/applications",
    component: SettingsApplications
  },
  {
    route: "/settings/property-management/bookkeeping",
    component: SettingsBookkeeping
  },
  {
    route: "/settings/property-management/feedbacks",
    component: SettingsFeedback
  },
  // account management
  {
    route: "/settings/account-management/admin",
    component: SettingsAdmin
  },
  {
    route: "/settings/account-management/staff",
    component: SettingsStaff
  },
  {
    route: "/settings/account-management/owners-tenants",
    component: SettingsOwnersTenants
  },
  // others
  {
    route: "/settings/others/condo-info",
    component: SettingsCondoInfo
  },
  {
    route: "/settings/others/sos",
    component: SettingsSOS
  },
  {
    route: "/settings/others/upload-csv",
    component: SettingsUploadCSV
  }
]

export default SettingsRoute
