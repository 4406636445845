/* eslint global-require: "warn"*/
import React from "react"
import Button from "@material-ui/core/Button"
/** Styles */
import styles from "./styles"

interface Props {
  label: string
  action: Function
  isActive?: boolean
  disabled?: boolean
  currentTimeslot?: boolean
}

const TimeButton: React.FC<Props> = ({
  label,
  action,
  isActive = false,
  disabled = false,
  currentTimeslot = false
}): JSX.Element => {
  const classes = styles()

  const classStyle = `${classes.container} ${isActive ? "active" : ""} ${
    currentTimeslot ? "current" : ""
  }`

  return (
    <Button
      variant="outlined"
      onClick={() => action()}
      className={classStyle}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}
export default TimeButton
