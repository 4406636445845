import * as React from "react"

interface BannerProps {
  color: string
}

const Banner: React.FC<BannerProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V0H16V16H2V0H0ZM4 0V2H14V0H4ZM4 4V6H11V4H4ZM4 10V14H14V10H4Z"
        fill={color}
      />
    </svg>
  )
}

export default Banner
