import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Component */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import ProfileView from "pages/Settings/Profile/components/ProfileView"

/** Context */
import withContext from "context/Dashboard/Bookkeeping/main/withContext"
import { CtxType } from "context/Dashboard/Bookkeeping/main/Context"

const SettingsAdminView: React.FC<CtxType> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Edit Profile"
              breadcrumbs="Settings / Edit Profile"
            />
          </Box>
        </Box>
        <Box>
          <Card>
            <ProfileView />
          </Card>
        </Box>
      </WithHeader>
    )
  }

  return <>{MainView()}</>
}

export default withContext(SettingsAdminView)
