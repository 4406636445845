import * as Yup from "yup"

export const updateCondoInfo: Record<any, any> = Yup.object().shape({
  adminContact: Yup.string().required("Required"),
  adminEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  adminPhone: Yup.string().required("Required")
})

const validation = {
  updateCondoInfo
}

export default validation
