import React from "react"

export const IconEdit = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4141 0C15.1581 0 14.902 0.0979687 14.707 0.292969L12.707 2.29297L11.293 3.70703L0 15V19H4L18.707 4.29297C19.098 3.90197 19.098 3.26891 18.707 2.87891L16.1211 0.292969C15.9261 0.0979687 15.6701 0 15.4141 0ZM15.4141 2.41406L16.5859 3.58594L15.293 4.87891L14.1211 3.70703L15.4141 2.41406ZM12.707 5.12109L13.8789 6.29297L3.17188 17H2V15.8281L12.707 5.12109Z"
      fill="#09707B"
    />
  </svg>
)

export const IconDelete = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L6 1H0V3H1.10938L2.90234 18.2344C3.01959 19.234 3.882 20 4.88867 20H13.1113C14.118 20 14.9804 19.234 15.0977 18.2344L16.8906 3H18V1H16H12L11 0H7ZM3.12305 3H4H14.877L13.1113 18H4.88867L3.12305 3ZM6 5V15C6 15.552 6.448 16 7 16H8V5H6ZM10 5V16H11C11.552 16 12 15.552 12 15V5H10Z"
      fill="#09707B"
    />
  </svg>
)

export const IconView = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM2 2H16V16H2V2ZM9 5C5 5 3 9 3 9C3 9 5 13 9 13C13 13 15 9 15 9C15 9 13 5 9 5ZM9 7C10.104 7 11 7.896 11 9C11 10.104 10.104 11 9 11C7.896 11 7 10.104 7 9C7 7.896 7.896 7 9 7Z"
      fill="#09707B"
    />
  </svg>
)

export const IconDown = (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42969 0.5L0.929688 2L7 8.07031L13.0703 2L11.5703 0.5L7 5.07031L2.42969 0.5Z"
      fill="#004E8B"
    />
  </svg>
)
