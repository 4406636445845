import React, { useContext, useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import RadioGroupList from "components/Forms/RadioGroupList"
import DatePicker from "components/Forms/DatePicker"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/facility/Context"
/** Config */
import { addFacilityStatus } from "config/Settings/PropertyManagement/Facilities/validation"
import { facilityStatus } from "config/Settings/PropertyManagement/Facilities"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/facility/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormDetails: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const { facility, setFacility, hasData } = useContext(Context)

  /** Methods */
  const handleFormChange = (
    inputName: string,
    e: any,
    setFieldValue: Function
  ): void => {
    if (e?.target === undefined) {
      setFacility &&
        setFacility({
          ...facility,
          [inputName]: e
        })
      setFieldValue(inputName, e)
    } else {
      setFacility &&
        setFacility({
          ...facility,
          [inputName]: e?.target?.value
        })
      setFieldValue(inputName, e?.target?.value)
    }
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return {
            status: facility?.status,
            suspensionStart: facility?.suspensionStart,
            suspensionEnd: facility?.suspensionEnd,
            comments: facility?.comments
          }
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addFacilityStatus}
        isInitialValid={hasData || false}
      >
        {({
          touched,
          errors,
          isValid,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper>
                  <Box className={classes.section}>
                    <Box marginBottom="25px">
                      <RadioGroupList
                        label="STATUS"
                        value={facility?.status}
                        name="status"
                        ariaLabel="status"
                        onChange={(e: any): void =>
                          handleFormChange("status", e, setFieldValue)}
                        options={facilityStatus}
                      />
                    </Box>

                    {Number(facility?.status) === 2 && (
                      <Box className={classes.section}>
                        <Box className={classes.title}>SUSPENSION DATE</Box>
                        <Box className={classes.twoCol}>
                          <Box>
                            <DatePicker
                              label="Start Date"
                              name="startDate"
                              format="MM/dd/yyyy"
                              value={facility?.suspensionStart}
                              placeholder="mm/dd/yyyy"
                              handleDateChange={(value: string): void =>
                                handleFormChange(
                                  "suspensionStart",
                                  value,
                                  setFieldValue
                                )}
                              onBlur={(e: Event): void => handleBlur(e)}
                              error={
                                touched.suspensionStart &&
                                Boolean(errors.suspensionStart)
                              }
                              helperText={
                                errors.suspensionStart &&
                                touched.suspensionStart &&
                                errors.suspensionStart
                              }
                            />
                          </Box>
                          <Box>
                            <DatePicker
                              label="End Date"
                              name="endDate"
                              format="MM/dd/yyyy"
                              value={facility?.suspensionEnd}
                              placeholder="mm/dd/yyyy"
                              minDate={facility?.suspensionStart}
                              handleDateChange={(value: string): void =>
                                handleFormChange(
                                  "suspensionEnd",
                                  value,
                                  setFieldValue
                                )}
                              onBlur={(e: Event): void => handleBlur(e)}
                              error={
                                touched.suspensionEnd &&
                                Boolean(errors.suspensionEnd)
                              }
                              helperText={
                                errors.suspensionEnd &&
                                touched.suspensionEnd &&
                                errors.suspensionEnd
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    <Box className={classes.sectionLast}>
                      <Box className={classes.title}>COMMENTS</Box>
                      <FormInput
                        name="comments"
                        label=""
                        placeholder="Comments"
                        value={facility?.comments}
                        multiline
                        rows={4}
                        handleOnChange={(
                          value: React.ChangeEvent<HTMLInputElement>
                        ) => handleFormChange("comments", value, setFieldValue)}
                        onBlur={(e: Event) => handleBlur(e)}
                        error={touched.comments && Boolean(errors.comments)}
                        helperText={
                          errors.comments && touched.comments && errors.comments
                        }
                      />
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormDetails
