import React from "react"
/** Component */
import ContextMenu from "components/ContextMenu"
/** Config */
import { frequency } from "config/Dashboard/Bookkeeping"
/** Utils */
import { fromUnix } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import ContextItems from "../ContextMenu"

const tableHeaders = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row._original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Block/Unit No",
        id: "blockUnitNo",
        accessor: (c: any) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Payment Name",
        id: "paymentName",
        accessor: (c: any) => checkValue(c?.payment_name),
        Cell: ({ original }: any) => checkValue(original?.payment_name),
        width: 250
      },
      {
        Header: "Frequency",
        id: "frequency",
        accessor: (c: any) => checkValue(c?.frequency, frequency[c.frequency]),
        Cell: ({ original }: any) =>
          checkValue(original?.frequency, frequency[original.frequency]),
        width: 150
      },
      {
        Header: "Amount",
        id: "amount",
        accessor: (c: any) => checkValue(c?.amt, c.amt.toFixed(2)),
        Cell: ({ original }: any) =>
          checkValue(original?.amt, original.amt.toFixed(2)),
        width: 150
      },
      {
        Header: "Start Date",
        id: "startDate",
        accessor: (c: any) => checkDate(c?.start_date, fromUnix(c?.start_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.start_date, fromUnix(original?.start_date)),
        width: 150
      },
      {
        Header: "End Date",
        id: "endDate",
        accessor: (c: any) => checkDate(c?.end_date, fromUnix(c?.end_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.end_date, fromUnix(original?.end_date)),
        width: 150
      },
      {
        Header: "Email Reminder",
        id: "emailReminder",
        accessor: (c: any) =>
          checkValue(c.notify_on_days_before, `${c.notify_on_days_before} days`),
        Cell: ({ original }: any) =>
          checkValue(
            original.notify_on_days_before,
            `${original.notify_on_days_before} days`
          ),
        width: 200
      },
      {
        Header: "Send Email",
        id: "sendEmail",
        accessor: (c: any): string => {
          return c.flags === 1 ? "Yes" : "No"
        },
        Cell: ({ original }: any) => {
          return `${original.flags === 1 ? "Yes" : "No"}`
        },
        width: 150
      },
      {
        Header: "Re-send Email",
        id: "resendEmail",
        accessor: (c: any) =>
          checkValue(
            c.notify_on_days_after_due,
            `${c.notify_on_days_after_due} days`
          ),
        Cell: ({ original }: any) =>
          checkValue(
            original.notify_on_days_after_due,
            `${original.notify_on_days_after_due} days`
          ),
        width: 150
      },
      {
        Header: "Name",
        id: "payeeName",
        accessor: (c: any) => checkValue(c?.payee_name),
        Cell: ({ original }: any) => checkValue(original?.payee_name),
        width: 250
      },
      {
        Header: "Email",
        id: "payeeEmail",
        accessor: (c: any) => checkValue(c?.payee_email),
        Cell: ({ original }: any) => checkValue(original?.payee_email),
        width: 250
      },
      {
        Header: "Phone",
        id: "payeePhone",
        accessor: (c: any) => checkValue(c?.payee_phone),
        Cell: ({ original }: any) => checkValue(original?.payee_phone),
        width: 250
      },
      {
        Header: "Address",
        id: "payeeAddress",
        accessor: (c: any) => checkValue(c?.payee_registered_address),
        Cell: ({ original }: any) => checkValue(original?.payee_registered_address),
        width: 350
      }
    ]
  }
]

export default tableHeaders
