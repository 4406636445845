import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
/** Components */
import Label from "components/Forms/Label"
import UploadBox from "components/UploadBox/new"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
/* Context */
import withReplyContext from "context/Dashboard/Feedback/reply/withReplyContext"
import { ReplyCtxType } from "context/Dashboard/Feedback/reply/ReplyContext"
/* Styles */
import { refSubmit } from "context/Dashboard/Feedback/reply/ReplyView"
import styles from "./styles"

const EditForm: React.FC<ReplyCtxType> = ({
  handleImageUpload,
  removePhoto,
  photos,
  attaching,
  setActiveReply,
  activeReply
}) => {
  const { container, field, title, uploadContainer } = styles()

  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFieldValue(key, e?.target?.value)
    setActiveReply &&
      setActiveReply({
        ...activeReply,
        [key]: e?.target?.value
      })
  }

  return (
    <div className={container}>
      <div className={title}>Edit Reply</div>
      <Formik
        initialValues={{
          reply: activeReply?.reply
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          reply: Yup.string().required("Required")
        })}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <div className={field}>
                <FormInput
                  label="Message"
                  placeholder="Message"
                  value={activeReply?.reply}
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFormChange(setFieldValue, "reply", e)}
                  name="reply"
                  idValue="reply"
                  multiline
                  rows={3}
                  onBlur={handleBlur}
                  error={touched.reply && Boolean(errors.reply)}
                  helperText={errors.reply && touched.reply && errors.reply}
                />
              </div>
              <div className={field}>
                <Label label="photos" />
                <div className={uploadContainer}>
                  <UploadBox
                    onDrop={handleImageUpload}
                    files={photos}
                    removePhoto={removePhoto}
                    attaching={attaching}
                  />
                </div>
              </div>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
export default withReplyContext(EditForm)
