import useSWR from "swr"
import service from "services/Dashboard/Dashboard"
import { toUnixAddDay } from "utils/date"

export function VMSTodayPreview(date: any) {
  const todayStartDate = date?.startDate
  const todayEndDate = date?.endDate

  const fetchDashboardVisitors = async () =>
    service.getVisitors({
      "application_date_sort": "desc",
      "start_timestamp": todayStartDate,
      "end_timestamp": todayEndDate,
      "timestamp_filter_by": "startDate",
      // "status": 1,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardVisitors-${todayStartDate}-${todayEndDate}`,
    fetchDashboardVisitors,
    {
      revalidateOnFocus: true
    }
  )
  const vmsList = data?.data?._data
  return {
    vmsList,
    isValidating,
    error
  }
}

export function VMSTomorrowPreview(date: any) {
  const tomStartDate = toUnixAddDay(`${date?.currentDate} 00:00`)
  const tomEndDate = toUnixAddDay(`${date?.currentDate} 11:59`)

  const fetchDashboardVisitorsTomorrow = async () =>
    service.getVisitors({
      "application_date_sort": "desc",
      "start_timestamp": tomStartDate,
      "end_timestamp": tomEndDate,
      // "status": 1,
      "timestamp_filter_by": "startDate",
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardVisitorsTomorrow-${tomStartDate}-${tomEndDate}`,
    fetchDashboardVisitorsTomorrow,
    {
      revalidateOnFocus: true
    }
  )
  const vmsListTomorrow = data?.data?._data

  return {
    vmsListTomorrow,
    isValidatingTomorrow: isValidating,
    error
  }
}

export function VMSPending() {
  const fetchDashboardVisitorsPending = async () =>
    service.getVisitors({
      "application_date_sort": "desc",
      "status": 1,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardVisitorsPending`,
    fetchDashboardVisitorsPending,
    {
      revalidateOnFocus: true
    }
  )
  const vmsPendingList = data?.data?._data

  return {
    vmsPendingList,
    isValidatingPending: isValidating,
    error
  }
}
