import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
    cursor: "pointer"
  }
}))

export default styles
