import React from "react"

export type CtxType = {
  payloads: any
  adminPayload: any
  activeRole: string
  activeRoleName: string
  setActiveRoleName: Function
  setOpenState: Function
  setShowForm: Function
  setAdminPayload: Function
  setActiveRole: Function
  selectTab: Function
  setPayloads: Function
  sendBack: Function
  handleFormSubmit: Function
  showFormType: Function
  setFormDetails: Function
  initSetup: Function
  formDetails: Record<string, any>
  isFetchingRoles: boolean
  showForm: boolean
  openState: boolean
  submitting: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
