import React from "react"

export type CtxType = {
  setActiveTab: Function
  setCategory: Function
  setOpenDialog: Function
  setSubmitting: Function
  showTable: Function
  setTimeSlots: Function
  handleTimeChange: Function
  handleAddCategory: Function
  handleUpdateCategory: Function
  addTimeSlot: Function
  removeTimeSlot: Function
  checkHasBlank: Function
  timeSlots: Array<any>
  category: Record<string, any>
  timeoverlap: Record<string, any>
  activeTab: number
  openDialog: boolean
  submitting: boolean
  hasData: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
