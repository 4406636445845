import React from "react"

import VMSVisitorsProvider, {
  VMSVisitorsProviderProps
} from "context/Dashboard/VMS/categories/vmsVisitors/Provider"
import VMSVisitorsView from "context/Dashboard/VMS/categories/vmsVisitors/View"

const VMSVisitorsMain: React.FC<VMSVisitorsProviderProps> = ({
  showAddForm
}): JSX.Element => (
  <VMSVisitorsProvider showAddForm={showAddForm}>
    <VMSVisitorsView />
  </VMSVisitorsProvider>
)

export default VMSVisitorsMain
