import React, { useState } from "react"
import { toast } from "react-toastify"
import Cookies from "js-cookie"

/** API */
import FeedbackAPI from "services/Dashboard/Feedback"
import { UploadImage } from "utils/uploadFile"

import { AddFeedbackContext } from "./Context"

export interface EditFeedbackProviderProps {
  showForm: Function
  activeItem: Record<string, any>
}

const EditFeedbackProvider: React.FC<EditFeedbackProviderProps> = ({
  children,
  showForm,
  activeItem
}) => {
  const [activeFeedback] = React.useState([])

  /* Notification */
  const notifyCreate = () =>
    toast("Successfully updated a the Feedback.", {
      type: toast.TYPE.SUCCESS
    })

  const [openDialog, setOpenDialog] = useState(false)
  const [feedbackDetails, setFeedbackDetails] = useState({
    id: activeItem?._uid || "",
    unitName: activeItem?.feedback_unit_short_name || "",
    unitUID: activeItem?.feedback_unit_uid || "",
    byAdmin: activeItem?.by_admin || false,
    type: activeItem?.feedback_type || "",
    description: activeItem?.remarks || "",
    isUrgent: activeItem?.is_urgent || false,
    staffName: activeItem?.assigned_to_staff_account_uid || "",
    photo: activeItem?.feedback_photo?.photos || [],
    status: activeItem?.status || "",
    feedbackBy: activeItem?.feedback_by_name || "",
    feedbackById: activeItem?.feedback_by_account_uid || ""
  })
  const [photos, setPhotos] = useState(activeItem?.feedback_photo?.photos || [])
  const [localPhotos, setLocalPhotos] = useState<Array<string>>(
    activeItem?.feedback_photo?.photos || []
  )
  const [attaching, setAttaching] = useState(false)

  /** Methods */
  function handleImageUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  const removePhoto = (value: number) => {
    const fileFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })
    setPhotos(fileFiltered)
    setLocalPhotos(fileFiltered)
  }

  const handleAddFeedback = async () => {
    const fd = feedbackDetails

    const data = {
      "feedback_type": fd?.type,
      "remarks": fd?.description,
      "feedback_photo": { "photos": photos },
      "feedback_unit_uid": fd?.unitUID === "" ? null : fd?.unitUID,
      "is_urgent": fd?.isUrgent,
      "assigned_to_staff_account_uid": fd?.staffName,
      "feedback_condo_uid": Cookies.get("condoUID"),
      "status": fd?.status,
      "by_admin": fd?.byAdmin,
      "feedback_posted_by_admin": !fd?.byAdmin,
      "feedback_by_account_uid": fd?.byAdmin ? null : fd?.feedbackById,
      "feedback_by_name": fd?.byAdmin ? null : fd?.feedbackBy
    }

    if (feedbackDetails.staffName === "") {
      delete data.assigned_to_staff_account_uid
    }

    if (feedbackDetails.byAdmin) {
      if (feedbackDetails.staffName === "") {
        delete data.assigned_to_staff_account_uid
      }
    }

    try {
      const response = await FeedbackAPI.updateFeedback(data, feedbackDetails.id)
      notifyCreate()
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <AddFeedbackContext.Provider
      value={{
        feedbackDetails,
        photos,
        localPhotos,
        attaching,
        openDialog,
        activeFeedback,
        showForm,
        activeItem,
        setFeedbackDetails,
        handleAddFeedback,
        setOpenDialog,
        handleImageUpload,
        removePhoto
      }}
    >
      {children}
    </AddFeedbackContext.Provider>
  )
}

export default EditFeedbackProvider
