import React, { useState } from "react"
import { mutate } from "swr"

/** Context */
import Service from "services/Dashboard/Applications"
import { Context } from "./Context"

/** Service */

export interface ProviderProps {
  showMain: Function
  showAddApplication: Function
  showUpdateApplication: Function
  showApproveApplication: Function
  setActiveFilter: Function
  activeFilter: number
}

const Provider: React.FC<ProviderProps> = ({
  children,
  showMain,
  showAddApplication,
  showUpdateApplication,
  showApproveApplication,
  setActiveFilter,
  activeFilter
}) => {
  /** States */
  const [isDeleting, setIsDeleting] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [activeDetails, setActiveDetails] = useState({})
  const [downloadData, setDownloadData] = useState([])

  /** Methods */
  function filterTable(value: number) {
    setActiveFilter && setActiveFilter(value)
  }

  function showTable() {
    showMain()
  }

  async function handleCancelItem(itemUID: string, mutateString: string) {
    setIsDeleting(true)
    try {
      const response = await Service.cancelApplication(itemUID)
      setIsDeleting(false)
      mutate(mutateString)
      return response
    } catch (e) {
      setIsDeleting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        activeFilter,
        showMain,
        showAddApplication,
        showUpdateApplication,
        showApproveApplication,
        showDetails,
        activeDetails,
        downloadData,
        isDeleting,
        handleCancelItem,
        setShowDetails,
        setActiveDetails,
        showTable,
        setActiveFilter,
        filterTable,
        setDownloadData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
