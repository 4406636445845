import React from "react"

export type AddUpdateCtxType = {
  activeStep: number
  openDialog: boolean
  submitting: boolean
  bookingAllowed: boolean
  visitorDetails: Record<string, any>
  allowedDateMessage: string
  allowedTimeMessage: string
  setBookingAllowed: Function
  setActiveStep: Function
  setOpenDialog: Function
  setVisitorDetails: Function
  steps: Function
  handleAddVMS: Function
  showform: Function
}

export const AddUpdateContext = React.createContext<Partial<AddUpdateCtxType>>({})
