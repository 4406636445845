import React from "react"

export type CtxType = {
  activeTable: number
  focusedInput: string
  startDateState: any
  endDateState: any
  isopenModal: boolean
  setisopenModal: Function
  handleDateRange: Function
  dateValue: Function
  onFocusChange: Function
  filterActiveTable: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
