import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    width: "40%",
    margin: "30px auto ",
    padding: 0,
    display: "flex",
    "& > div": {
      flex: 1
    }
  },
  errorContainer: {
    width: "40%",
    margin: "30px auto ",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    "& > div": {
      flex: 1,
      paddingBottom: " 4px"
    }
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto 0px auto",
    "& > div": {
      flex: 1,
      margin: "0 15px 25px 0",
      maxWidth: "47%"
    },
    "& > div:last-child": {
      flex: 0,
      margin: "0 15px 10px 0"
    },
    "& > button": {
      minWidth: "30px",
      marginBottom: "10px"
    }
  },
  addTimeSlotBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "40px"
  }
}))

export default styles
