import * as React from "react"

const Maintenance = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.53405 0L0.583099 2L1.95486 4.10938C2.31677 4.66638 2.9265 5 3.57812 5H4.0811L7.53147 8.53711L2.03488 14.1699C1.39887 14.8219 1.39887 15.8792 2.03488 16.5312L2.9894 17.5098C3.30838 17.8368 3.72456 18 4.14207 18C4.55859 18 4.97672 17.8377 5.29473 17.5117L9.85013 12.8398L14.4055 17.5117C14.7235 17.8377 15.1417 18 15.5582 18C15.9747 18 16.3909 17.8377 16.709 17.5117L17.6635 16.5332C18.2995 15.8812 18.2995 14.8239 17.6635 14.1719L13.1081 9.5L14.6303 7.93945C14.8225 7.96845 15.0153 8 15.2153 8C17.3701 8 19.1172 6.209 19.1172 4C19.1172 3.414 18.988 2.86037 18.7666 2.35938L17.3872 3.77344L15.8707 3.32812L15.4363 1.77344L16.8156 0.359375C16.3269 0.132375 15.7869 0 15.2153 0C13.0604 0 11.3133 1.791 11.3133 4C11.3133 4.205 11.3441 4.40261 11.3724 4.59961L8.91085 7.12305L5.46048 3.58594V3.07031C5.46048 2.40231 5.13504 1.77725 4.5917 1.40625L2.53405 0ZM13.9407 1.86914L14.0283 2.1875L14.4627 3.74219L14.6856 4.54297L15.4667 4.77148L16.9833 5.2168L17.2939 5.30859C16.8637 6.02259 16.0932 6.5 15.2153 6.5C15.1304 6.5 15.0006 6.48003 14.8494 6.45703L14.1197 6.34375L13.5958 6.87891L12.0735 8.43945L12.064 8.42969L8.80607 11.7695L8.81559 11.7793L4.26019 16.4492C4.228 16.4822 4.18694 16.5 4.14207 16.5C4.09719 16.5 4.05804 16.4822 4.02585 16.4492L3.07133 15.4727C3.00695 15.4067 3.00695 15.2975 3.07133 15.2305L12.4069 5.66016L12.9271 5.10938L12.8166 4.35938C12.7961 4.21938 12.7766 4.087 12.7766 4C12.7766 3.1 13.2432 2.30914 13.9407 1.86914ZM12.0735 10.5605L16.6308 15.2305C16.6952 15.2965 16.6952 15.4037 16.6308 15.4707L15.6763 16.4492C15.6441 16.4822 15.605 16.498 15.5601 16.498C15.5143 16.498 15.4742 16.4822 15.442 16.4492L10.8866 11.7793L12.0735 10.5605Z"
      fill="#454B57"
    />
  </svg>
)

export default Maintenance
