import React from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"

import Label from "components/Forms/Label"
import RolesServiceHook from "./hooks"

/** Components */

interface RolesProps {
  value: any
  label?: string
  condoId?: string
  helperText?: any
  userType?: number
  onChange: Function
  onBlur?: Function
  error?: boolean
  withName?: boolean
}

const Roles: React.FC<RolesProps> = ({
  value,
  onChange,
  onBlur,
  helperText,
  error = false,
  label,
  userType = 1,
  withName = false
}) => {
  const { roles, isValidating } = RolesServiceHook(userType)

  const ViewWithName = () => {
    if (!withName) {
      return null
    }

    return (
      <Box width="300px">
        {label && <Label label={label} />}
        <FormControl variant="outlined" error={error}>
          <Select
            displayEmpty
            value={value}
            onChange={(e) => onChange(e?.target?.value)}
            onBlur={(e) => onBlur && onBlur(e)}
            error={error}
            name="role"
            disabled={isValidating}
          >
            <MenuItem value="" disabled>
              User Role
            </MenuItem>
            {roles &&
              Object.keys(roles).map((role: any) => {
                return (
                  <MenuItem
                    value={`${roles[role]?._uid}/${roles[role]?.role_name}/${roles[role]?.category}`}
                  >
                    {roles[role]?.role_name}
                  </MenuItem>
                )
              })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Box>
    )
  }

  const ViewDefault = () => {
    if (withName) {
      return null
    }

    return (
      <Box width="300px">
        {label && <Label label={label} />}
        <FormControl variant="outlined" error={error}>
          <Select
            displayEmpty
            value={value}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur && onBlur(e)}
            error={error}
            name="role"
            disabled={isValidating}
          >
            <MenuItem value="" disabled>
              User Role
            </MenuItem>
            {roles &&
              Object.keys(roles).map((role: any) => {
                return (
                  <MenuItem value={roles[role]?._uid}>
                    {roles[role]?.role_name}
                  </MenuItem>
                )
              })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Box>
    )
  }

  return (
    <>
      <ViewDefault />
      <ViewWithName />
    </>
  )
}
export default Roles
