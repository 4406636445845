import React from "react"

export type CtxType = {
  previewImage: any
  photos: any
  files: any
  postDetails: Record<string, string | number | boolean | null | undefined>
  hasData: boolean
  formSubmitting: boolean
  attachingPhoto: boolean
  attachingFile: boolean
  setPostDetails: Function
  handleCreatePost: Function
  handleFileUpload: Function
  removeFile: Function
  handleImageUpload: Function
  removeImage: Function
  sendBack: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
