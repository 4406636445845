import * as React from "react"

const VMSVisitors = (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C5.343 0 4 1.343 4 3C4 4.657 5.343 6 7 6C8.657 6 10 4.657 10 3C10 1.343 8.657 0 7 0ZM15 0C13.343 0 12 1.343 12 3C12 4.657 13.343 6 15 6C16.657 6 18 4.657 18 3C18 1.343 16.657 0 15 0ZM7 2C7.551 2 8 2.449 8 3C8 3.551 7.551 4 7 4C6.449 4 6 3.551 6 3C6 2.449 6.449 2 7 2ZM15 2C15.551 2 16 2.449 16 3C16 3.551 15.551 4 15 4C14.449 4 14 3.551 14 3C14 2.449 14.449 2 15 2ZM7 8C4 8 0 9.464 0 11.5V14H13.0781H14H22V11.5C22 9.464 18 8 15 8C13.7159 8 12.2617 8.28294 11.0039 8.75C9.74378 8.28175 8.28671 8 7 8ZM7 10C9.36 10 11.62 11.0256 12 11.5996V12H2V11.5996C2.38 11.0256 4.64 10 7 10ZM15 10C17.36 10 19.62 11.0256 20 11.5996V12H14V11.5C14 11.0182 13.7703 10.57 13.3887 10.1641C13.9083 10.0693 14.4448 10 15 10Z"
      fill="#454B57"
    />
  </svg>
)

export default VMSVisitors
