import * as React from "react"
import { Link } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

/**
 * Components
 */
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DownloadCSV from "components/DownloadCSV"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "25px 20px",
    borderBottom: "1px solid #F2F2F2"
  },
  search: {
    position: "relative"
  },
  cta: {
    margin: "0 5px",
    textTransform: "capitalize",
    "&.disabledButton": {
      cursor: "not-allowed"
    }
  },
  titlePage: {
    fontSize: "20px",
    color: theme.palette.secondary.dark,
    display: "flex",
    alignItems: "flex-start",
    textTransform: "capitalize",
    "& > i": {
      padding: "3px 10px 0 0"
    },
    "& svg path": {
      fill: theme.palette.secondary.dark
    }
  }
}))

// interface
interface TableHeaderProps {
  downloadFile?: Function | undefined
  addAction?: Function
  hasSearch?: boolean
  hasDownloadCSV?: boolean
  isLink?: boolean
  addActionLabel?: string
  className?: string
  title?: string
  titleIcon?: any
  urlLink?: any
  actionDisable?: boolean
  data?: any
  fileName?: string
}

const TableHeader: React.FC<TableHeaderProps> = ({
  addAction,
  addActionLabel,
  title,
  titleIcon,
  isLink,
  urlLink,
  actionDisable = false,
  data,
  fileName = "file.csv"
}) => {
  const { container, cta, titlePage } = useStyles()

  return (
    <Box className={container} justifyContent="space-between">
      <Box className={titlePage}>
        {titleIcon && <i>{titleIcon()}</i>}
        {title}
      </Box>
      <Box display="flex">
        <Box display="flex">
          <DownloadCSV data={data} filename={fileName} />
          {isLink && (
            <Button
              component={Link}
              to={urlLink}
              className={cta}
              size="medium"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
            >
              {addActionLabel}
            </Button>
          )}
          {addAction && (
            <Button
              className={cta}
              size="medium"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={(): void => addAction && addAction(true)}
              tabIndex={0}
              disabled={actionDisable === false}
            >
              {addActionLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default TableHeader
