import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
/** Components */
import Dialog from "components/Dialog"
/** Context */
import { Context as ParentContext } from "context/Dashboard/Announcements/Posts/main/Context"
import { CtxMenuContext } from "components/ContextMenu"
import { AppContext } from "App"
/** Styles */
import { IconView, IconEdit, IconDelete } from "components/Icons/ContextMenu"
import { FetchAnnouncementPosts } from "config/Dashboard/Announcements/apiName"
import styles from "./styles"
/** Config */

interface ContextAnnouncementPostsProps {
  data: Record<string, any>
  showUpdate: Function
}

const ContextAnnouncementPosts: React.FC<ContextAnnouncementPostsProps> = ({
  data,
  showUpdate
}) => {
  const { listItem } = styles()
  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = React.useContext(CtxMenuContext)
  const {
    handleDeletePost,
    setActiveData,
    setShowDetails,
    activeTable
  } = useContext(ParentContext)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.announcement_edit
  const canDelete = permission?.announcement_remove

  function handleShowUpdate(dataValue: Record<string, any>) {
    showUpdate && showUpdate(dataValue)
    setActiveData && setActiveData(dataValue)
  }

  function handleDelete(postId: string) {
    handleDeletePost && handleDeletePost(postId)
    openContext && openContext(false)
    FetchAnnouncementPosts(activeTable)
  }

  function handleShowDetails(dataValue: Record<string, any>) {
    setShowDetails && setShowDetails(true)
    setActiveData && setActiveData(dataValue)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={() => handleShowDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={(): void | null => (!canEdit ? null : handleShowUpdate(data))}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canDelete}
          onClick={(): void | null => (!canDelete ? null : setOpenDialog(true))}
        >
          <i>{IconDelete}</i>
          Delete
        </ListItem>
      </List>
      <Dialog
        action={() => handleDelete(data?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to delete this announcement post?"
      />
    </>
  )
}
export default ContextAnnouncementPosts
