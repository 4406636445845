import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

/** Context */
import service from "services/Settings/PropertyManagement/Bookkeeping"
import { Context } from "./Context"

/** Service */

const Provider: React.FC = ({ children }) => {
  const [uid, setUID] = useState("")
  const [activeTab, setActiveTab] = useState(0)
  const [payloads, setPayloads] = useState({})
  const [categories, setCategories] = useState({})
  const [paymentTypes, setPaymentTypes] = useState({})
  const [modesOfPayments, setModesOfPayment] = useState({})

  /** Notification */
  const notifySuccess = () =>
    toast(`Successfully updated the bookkeeping settings`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  async function getBookkeeping() {
    try {
      const response = await service.getBookkeeping()
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  async function updateBookkeeping() {
    const payload = {
      "payment_types": Array.isArray(paymentTypes)
        ? paymentTypes
        : Object.values(paymentTypes),
      "categories": Array.isArray(categories)
        ? categories
        : Object.values(categories),
      "modes_of_payment": Array.isArray(modesOfPayments)
        ? modesOfPayments
        : Object.values(modesOfPayments)
    }

    try {
      const response = await service.updateBookkeeping(payload, uid)
      notifySuccess()
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  useEffect(() => {
    getBookkeeping().then((res) => {
      return Object.keys(res).map(() => {
        setPayloads(res[0])
        setUID(res[0]?._uid)
        setModesOfPayment(
          res[0]?.modes_of_payment !== null ? res[0]?.modes_of_payment : []
        )
        setPaymentTypes(res[0]?.payment_types !== null ? res[0]?.payment_types : [])
        setCategories(res[0]?.categories !== null ? res[0]?.categories : [])
        return res
      })
    })
  }, [])

  return (
    <Context.Provider
      value={{
        setPayloads,
        setModesOfPayment,
        setPaymentTypes,
        setCategories,
        setActiveTab,
        updateBookkeeping,
        payloads,
        categories,
        paymentTypes,
        modesOfPayments,
        activeTab
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
