import React, { useState, useContext } from "react"
import { mutate } from "swr"

import Toast from "components/Toast"
/** Utils */
import { UploadImage } from "utils/uploadFile"
import { fromUnix, toUnix } from "utils/date"
/** Config */
import { CondoUID } from "config/Common/CondoInfo"
/** Context */
import { Context as MainContext } from "context/Settings/PropertyManagement/Facilities/main/Context"
/** Service */
import service from "services/Settings/PropertyManagement/Facilities"
import { Context } from "./Context"

export interface Props {
  showFacilities: Function
  data?: Record<string, any>
}

// daily = 1
// weekly = 2
// monthly = 3
// quarterly 4
// yearly = 5

const Provider: React.FC<Props> = ({ children, showFacilities, data }) => {
  const { activeFacility } = useContext(MainContext)

  const hasData = data !== undefined

  const [uploadError, setUploadError] = useState(false)
  const [photoAttaching, setPhotoAttaching] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [facility, setFacility] = useState({
    facilityCategoryUID: activeFacility?.facility_category_uid || "",
    facilityName: data?.name || "",
    description: data?.description || "",
    location: data?.location || "",
    capacity: data?.capacity || "",
    status: data?.status || 1,
    suspensionStart:
      data && data?.suspended_start_date !== 0
        ? fromUnix(data?.suspended_start_date)
        : "",
    suspensionEnd:
      data && data?.suspended_end_date !== 0
        ? fromUnix(data?.suspended_end_date)
        : "",
    comments: data?.comments || ""
  })

  const [photos, setPhotos] = useState<any>(data?.facility_photo?.photos || Array)

  function handlePhotoUpload(event: any) {
    setUploadError(false)
    setPhotoAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setPhotoAttaching(false)
      })
      .catch(() => setPhotoAttaching(false))
  }

  function removePhoto(value: number): void {
    const fileFiltered = photos.filter((_item: any, index: number) => {
      return index !== value
    })
    setPhotos(fileFiltered)
  }

  async function handleAddFacility(): Promise<Response> {
    const fc = facility

    setSubmitting(true)

    const payload = {
      "condo_uid": CondoUID,
      "facility_category_uid": fc?.facilityCategoryUID,
      "name": fc?.facilityName,
      "description": fc?.description,
      "location": fc?.location,
      "capacity": Number(fc?.capacity),
      "comments": fc?.comments,
      "status": Number(fc?.status),
      "suspended_end_date":
        Number(fc?.status) === 1 ? "" : toUnix(fc?.suspensionEnd),
      "suspended_start_date":
        Number(fc?.status) === 1 ? "" : toUnix(fc?.suspensionStart),
      "facility_photo": {
        "photos": photos
      }
    }

    const endpoint = hasData
      ? service.updateFacility(payload, data?._uid)
      : service.createFacility(payload)

    try {
      const response = await endpoint
      Toast(`Successfully ${hasData ? "updated" : "created"} the facility.`, true)
      showFacilities()
      mutate(`fetchCategory`)

      setSubmitting(false)

      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        setOpenDialog,
        setFacility,
        handlePhotoUpload,
        handleAddFacility,
        removePhoto,
        photos,
        facility,
        openDialog,
        submitting,
        showFacilities,
        photoAttaching,
        uploadError,
        hasData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
