import React from "react"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
/** Component */
import Label from "components/Forms/Label"
/** Config */
import { paymentMethodFacilities } from "config/Dashboard/Bookkeeping"
/** Styles */
import styles from "./styles"

interface BlockUnitProps {
  label: string
  value: string
  onChange: Function
}

const ModeOfPayment: React.FC<BlockUnitProps> = ({ label, value, onChange }) => {
  const classes = styles()

  return (
    <FormControl className={classes.formControl}>
      <Label label="Mode of Payment" />
      <RadioGroup
        aria-label={label}
        name={label}
        value={value}
        onChange={(e: any) => onChange(e)}
        style={{ flexDirection: "column" }}
      >
        {Object.keys(paymentMethodFacilities).map((pMethod) => {
          return (
            <FormControlLabel
              value={pMethod}
              control={<Radio color="primary" />}
              label={paymentMethodFacilities[pMethod]}
              labelPlacement="end"
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
export default ModeOfPayment
