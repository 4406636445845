import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import FacilitiesList from "pages/Settings/PropertyManagement/Facilities/components/FacilitiesList"

/** Context */
import { IconAdd } from "components/Icons/Common"
import { Context } from "context/Settings/PropertyManagement/Facilities/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"

const SettingsFacilitiesView: React.FC<CtxType> = ({ facilitiesList }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { showTable, showAddFacility, activeFacility } = useContext(Context)

  const categoryName = activeFacility?.facility_category?.category_name
  const breadcrumbs = `settings / facilities / ${categoryName}`

  const hasFacilities = Object.keys(facilitiesList)?.length !== 0

  return (
    <WithHeader>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" width="100%">
          <PageHeaderTitle
            title={categoryName}
            breadcrumbs={breadcrumbs}
            backAction={() => showTable && showTable()}
          />
          {hasFacilities && (
            <Box>
              <Button
                startIcon={IconAdd}
                onClick={() => showAddFacility && showAddFacility()}
                variant="contained"
                color="primary"
              >
                ADD FACILITY
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <WithTable>
        <FacilitiesList />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(SettingsFacilitiesView)
