import React from "react"
import Box from "@material-ui/core/Box"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import { Formik } from "formik"
/** Components */
import BlockUnit from "components/Common/BlockUnit/new"
import Names from "components/Common/Names"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
/** Context */
import withContext from "context/Dashboard/VMS/add-update/withContext"
import { AddUpdateCtxType } from "context/Dashboard/VMS/add-update/AddUpdateContext"
/** Validation */
import { vmsResidentInfo } from "config/Dashboard/VMS/validation"
/** Styles */
import { refSubmit } from "context/Dashboard/VMS/add-update/AddUpdateView"
/** helpers */
import { handleResidentName, handleBlockUnit } from "utils/functions"
import styles from "./styles"

const ResidentInfo: React.FC<AddUpdateCtxType> = ({
  setVisitorDetails,
  visitorDetails
}) => {
  const { generalInfo } = styles()
  const validationSchema = visitorDetails?.byAdmin ? null : vmsResidentInfo.default

  const handleFormChange = (
    setFieldValue: Function,
    key: string,
    value: string | boolean
  ): void =>
    setFieldValue(key, value).then(
      () =>
        setVisitorDetails &&
        setVisitorDetails({
          ...visitorDetails,
          [key]: value
        })
    )

  return (
    <div className={generalInfo}>
      <Formik
        initialValues={{
          unitUID: visitorDetails?.unitUID,
          contactPerson: visitorDetails?.contactPerson
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <FormWrapper title="Requester's Information" width="40%">
                <div className="section" style={{ width: "40%", margin: "auto" }}>
                  <Box marginBottom="25px">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visitorDetails.byAdmin}
                            onChange={(): void =>
                              handleFormChange(
                                setFieldValue,
                                "byAdmin",
                                !visitorDetails.byAdmin
                              )}
                            value={visitorDetails.byAdmin}
                            color="primary"
                          />
                        }
                        label="Admin Visitor"
                      />
                    </FormGroup>
                  </Box>
                  {!visitorDetails.byAdmin && (
                    <Box>
                      <Box marginBottom="25px">
                        <BlockUnit
                          iValue={visitorDetails?.unitUID}
                          onChange={(value: string) =>
                            handleBlockUnit(
                              setFieldValue,
                              value,
                              setVisitorDetails,
                              visitorDetails,
                              "unitUID",
                              "contactPerson"
                            )}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.unitUID && Boolean(errors.unitUID)}
                          helperText={
                            errors.unitUID &&
                            touched.unitUID &&
                            String(errors.unitUID)
                          }
                        />
                      </Box>

                      <Box marginBottom="25px">
                        <Names
                          value={visitorDetails?.contactPerson}
                          onChange={(value: Event) =>
                            handleResidentName(
                              setFieldValue,
                              value,
                              setVisitorDetails,
                              visitorDetails,
                              "contactPerson",
                              "contactPersonUID"
                            )}
                          unitUID={visitorDetails.unitUID}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={
                            touched.contactPerson && Boolean(errors.contactPerson)
                          }
                          helperText={
                            errors.contactPerson &&
                            touched.contactPerson &&
                            String(errors.contactPerson)
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </div>
                <RefButton refValue={refSubmit} action={handleSubmit} />
              </FormWrapper>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withContext(ResidentInfo)
