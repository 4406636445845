import React from "react"

import MovingInOutProvider from "context/Dashboard/Applications/categories/movingInOut/Provider"
import MovingInOutView from "context/Dashboard/Applications/categories/movingInOut/View"

export default () => (
  <MovingInOutProvider>
    <MovingInOutView />
  </MovingInOutProvider>
)
