import { makeStyles } from "@material-ui/core/styles"

// TODO: Create a Theme
const styles = makeStyles(() => ({
  container: {
    "& > .MuiButton-label": {
      textTransform: "uppercase"
    }
  }
}))

export default styles
