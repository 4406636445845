import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

const VehiclesProvider: React.FC = ({ children }) => {
  const [activeData, setActiveData] = useState({})
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Context.Provider
      value={{
        activeData,
        showDetails,
        setActiveData,
        setShowDetails
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default VehiclesProvider
