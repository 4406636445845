import React from "react"

import Provider, {
  ProviderProps
} from "context/Settings/PropertyManagement/VisitorManagement/forms/category/Provider"
import View from "context/Settings/PropertyManagement/VisitorManagement/forms/category/View"

const CategoryForm: React.FC<ProviderProps> = ({ data, sendBack }) => {
  return (
    <Provider data={data} sendBack={sendBack}>
      <View />
    </Provider>
  )
}

export default CategoryForm
