export const PMNav = [
  {
    label: "Applications",
    to: "#/settings/property-management/applications"
  },
  {
    label: "Feedback",
    to: "#/settings/property-management/feedbacks"
  },
  {
    label: "Facilities",
    to: "#/settings/property-management/facilities"
  },
  {
    label: "Bookkeeping",
    to: "#/settings/property-management/bookkeeping"
  },
  {
    label: "Visitor Management",
    to: "#/settings/property-management/visitor-management"
  }
]

export const AMNav = [
  {
    label: "Admin",
    to: "#/settings/account-management/admin"
  },
  {
    label: "Staff",
    to: "#/settings/account-management/staff"
  },
  {
    label: "Owners/Tenants",
    to: "#/settings/account-management/owners-tenants"
  }
]

export const OthersNav = [
  {
    label: "Condo Info",
    to: "#/settings/others/condo-info"
  }
]
