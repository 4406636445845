import * as React from "react"

const Pending = (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V2H2V5.41406L5.58594 9L2 12.5859V16H0V18H2H4H10H12H14V16H12V12.5859L8.41406 9L12 5.41406V2H14V0H0ZM4 2H10V4.58594L7 7.58594L4 4.58594V2ZM7 10.4141L10 13.4141V16H4V13.4141L7 10.4141Z"
      fill="#09707B"
    />
  </svg>
)

export default Pending
