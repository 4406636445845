import request from "services/request"
import Cookies from "js-cookie"
import API from "./endpoints"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function getFeedbacks(params: Object) {
  return request({
    url: API.FEEDBACK_FETCH,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFeedback(id: string) {
  return request({
    url: `${API.FEEDBACK_FETCH}/${id}`,
    method: "GET",
    headers: headers()
  })
}

function getFeedbackMessages(id: string) {
  return request({
    url: `${API.FEEDBACK_FETCH}-reply?feedback_uid=${id}`,
    method: "GET",
    headers: headers()
  })
}

function createFeedback(data: Object) {
  return request({
    url: API.FEEDBACK_CREATE,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateFeedback(data: Object, id: string) {
  return request({
    url: `${API.FEEDBACK_UPDATE}/${id}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function createFeedbackReply(data: Object) {
  return request({
    url: API.FEEDBACK_CREATE_REPLY,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function assignToStaff(data: Object) {
  return request({
    url: API.FEEDBACK_ASSIGNTO_STAFF,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function setUrgency(data: Object) {
  return request({
    url: API.FEEDBACK_SET_URGENT,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateStatus(data: Object) {
  return request({
    url: API.FEEDBACK_UPDATE_STATUS,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateReply(data: Object, uid: string) {
  return request({
    url: `${API.FEEDBACK_UPDATE_REPLY}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

const FeedbackAPI = {
  createFeedback,
  createFeedbackReply,
  getFeedbacks,
  getFeedback,
  assignToStaff,
  setUrgency,
  updateStatus,
  getFeedbackMessages,
  updateFeedback,
  updateReply
}

export default FeedbackAPI
