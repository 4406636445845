import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  section: {
    padding: "20px 40px 10px",
    borderBottom: "1px solid #F2F2F2"
  },
  sectionHead: {
    padding: "30px 40px",
    borderBottom: "3px solid #F2F2F2"
  },
  sectionItems: {
    borderBottom: "3px solid #F2F2F2"
  },
  category: {
    textTransform: "capitalize",
    fontSize: "14px"
  },
  removeLink: {
    color: "#E12323"
  }
}))

export default styles
