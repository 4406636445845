const version = "v1"
const base = "svc-feedback"

const API = {
  LIST_FEEDBACK: `${base}/${version}/settings`,
  CREATE_APPLICATION: `${base}/${version}/setting`,
  UPDATE_APPLICATION: `${base}/${version}/setting`,
  DELETE_APPLICATION: `${base}/${version}/setting`
}

export default API
