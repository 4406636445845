import React from "react"

export type CtxType = {
  userData: Record<string, any>
  userDetails: Record<string, any>
  setUserDetails: Function
  handleImageUpload: Function
  handleUpdateProfile: Function
  resetProfile: Function
  photos: any
  previewImage: any
  attachingPhoto: boolean
}

export const Context = React.createContext<Partial<CtxType>>({})
