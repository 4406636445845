import React from "react"
import Box from "@material-ui/core/Box"

/**
 * Components
 */
import Select from "components/Forms/Select"
/**
 * Styles
 */
/**
 * Config
 */
import { Dates, Year } from "config/Common/Dates"
import styles from "./styles"

interface Props {
  month: string
  year: string
  setActiveDate: Function
}

const DateFilter: React.FC<Props> = ({ month, year, setActiveDate }) => {
  const { title, wrapper, formControlClass } = styles()

  return (
    <Box display="flex">
      <Box className={wrapper} marginRight="15px">
        <Box className={title}>Month</Box>
        <Box>
          <Select
            selectName="Month"
            placeholder="Month"
            value={month}
            onChange={(e: any): void =>
              setActiveDate((data: any) => ({
                ...data,
                month: e?.target?.value
              }))}
            items={Dates}
            rootClass={formControlClass}
            noHelper={false}
          />
        </Box>
      </Box>
      <Box className={wrapper}>
        <Box className={title}>Year</Box>
        <Box>
          <Select
            selectName="Month"
            placeholder="Month"
            value={year}
            onChange={(e: any): void =>
              setActiveDate((data: any) => ({
                ...data,
                year: e?.target?.value
              }))}
            items={Year}
            rootClass={formControlClass}
            noHelper={false}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default DateFilter
