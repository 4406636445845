import React, { useContext, useMemo, useEffect, useState } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import Select from "components/Forms/Select"
import RefButton from "components/Forms/RefButton"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/category/Context"
/** Config */
import { categoryTypes } from "config/Settings/PropertyManagement/Facilities"
import { addCategoryDetails } from "config/Settings/PropertyManagement/Facilities/validation"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/category/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormDetails: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const { category, setCategory } = useContext(Context)
  const [isInitialValid, setIsInitialValid] = useState(false)

  const defaultValues = {
    categoryName: category?.categoryName,
    categoryType: category?.categoryType,
    advanceBookingDays: category?.advanceBookingDays,
    cancelBookingDays: category?.cancelBookingDays,
    lastMinuteBooking: category?.lastMinuteBooking
  }

  useEffect(() => {
    addCategoryDetails
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false))
  }, [defaultValues])

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue(name, e?.target?.value)
    setCategory &&
      setCategory({
        ...category,
        [name]: e?.target?.value
      })
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return defaultValues
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addCategoryDetails}
        isInitialValid={isInitialValid}
      >
        {({
          touched,
          errors,
          isValid,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper title="Category Details & Booking Settings">
                  <Box className={classes.section}>
                    <Box marginBottom="25px">
                      <FormInput
                        name="categoryName"
                        value={category?.categoryName}
                        idValue="categoryName"
                        label="Category Name"
                        placeholder="Category Name"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange("categoryName", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.categoryName && Boolean(errors.categoryName)}
                        helperText={
                          errors.categoryName &&
                          touched.categoryName &&
                          errors.categoryName
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <Select
                        idValue
                        label="Category Type"
                        selectName="categoryType"
                        placeholder="Select Category Type"
                        value={category?.categoryType}
                        items={categoryTypes}
                        error={touched.categoryType && Boolean(errors.categoryType)}
                        helperText={
                          errors.categoryType &&
                          touched.categoryType &&
                          String(errors.categoryType)
                        }
                        onChange={(e: any) =>
                          handleFormChange("categoryType", e, setFieldValue)}
                      />
                    </Box>
                  </Box>

                  <Box className={classes.sectionLast}>
                    <Box className={classes.title}>Booking Settings</Box>

                    <Box marginBottom="25px">
                      <FormInput
                        labelShrink
                        type="number"
                        adorment="| days"
                        name="advanceBookingDays"
                        value={category?.advanceBookingDays}
                        idValue="advanceBookingDays"
                        label="Advance Booking (days)"
                        placeholder="Advance Booking"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange("advanceBookingDays", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={
                          touched.advanceBookingDays &&
                          Boolean(errors.advanceBookingDays)
                        }
                        helperText={
                          errors.advanceBookingDays &&
                          touched.advanceBookingDays &&
                          errors.advanceBookingDays
                        }
                      />
                    </Box>

                    <Box className={classes.twoCol}>
                      <Box marginBottom="25px">
                        <FormInput
                          labelShrink
                          type="number"
                          name="cancelBookingDays"
                          value={category?.cancelBookingDays}
                          idValue="cancelBookingDays"
                          label="Cancel Booking (days)"
                          placeholder="Cancel Booking"
                          adorment="| days"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange("cancelBookingDays", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={
                            touched.cancelBookingDays &&
                            Boolean(errors.cancelBookingDays)
                          }
                          helperText={
                            errors.cancelBookingDays &&
                            touched.cancelBookingDays &&
                            errors.cancelBookingDays
                          }
                        />
                      </Box>

                      <Box marginBottom="25px">
                        <FormInput
                          type="number"
                          labelShrink
                          name="lastMinuteBooking"
                          value={category?.lastMinuteBooking}
                          idValue="lastMinuteBooking"
                          label="Last Minute Booking (hours)"
                          placeholder="Last Minute Booking"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange("lastMinuteBooking", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={
                            touched.lastMinuteBooking &&
                            Boolean(errors.lastMinuteBooking)
                          }
                          helperText={
                            (errors.lastMinuteBooking &&
                              touched.lastMinuteBooking &&
                              String(errors.lastMinuteBooking)) || (
                              <span>Note: Not checking entitlement</span>
                            )
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormDetails
