import React from "react"
import Fade from "@material-ui/core/Fade"
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts"
/**
 * Component
 */
import Loader from "components/Loader"
/**
 * Styles
 */
import styles from "./styles"

const OPTIONS = [
  {
    "name": "Cleanliness",
    "color": "#09707B"
  },
  {
    "name": "Security",
    "color": "#A56300"
  },
  {
    "name": "Defects",
    "color": "#D8B469"
  },
  {
    "name": "Landscape",
    "color": "#0E61A2"
  },
  {
    "name": "Others",
    "color": "#454B57"
  }
]

interface Props {
  totalData: Record<string, number>
  isValidating: boolean
}

const TotalFeedbackPerCategory: React.FC<Props> = ({ totalData, isValidating }) => {
  const classes = styles()
  const td = totalData

  const data = [
    {
      "name": "Cleanliness",
      "value": td?.cleanliness
    },
    {
      "name": "Security",
      "value": td?.security
    },
    {
      "name": "Defects",
      "value": td?.defects
    },
    {
      "name": "Landscape",
      "value": td?.landscape
    },
    {
      "name": "Others",
      "value": td?.others
    }
  ]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${
          Number((percent * 100).toFixed(0)) === 0
            ? ""
            : `${Number((percent * 100).toFixed(0))}%`
        }`}
      </text>
    )
  }

  function ViewChart(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    return (
      <Fade in timeout={2000}>
        <ResponsiveContainer width={450} height={250}>
          <PieChart width={400} height={250}>
            <Pie
              outerRadius={120}
              data={data}
              cx="50%"
              cy="50%"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell fill={OPTIONS[index]?.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Fade>
    )
  }

  function ViewOptions(): JSX.Element {
    return (
      <div className={classes.options}>
        {OPTIONS.map((option: Record<string, any>) => (
          <div className={classes.optionContainer} key={`${option.name}`}>
            <div
              className={classes.optionColor}
              style={{ backgroundColor: `${option.color}` }}
            />
            <div className={classes.optionName}>{option?.name}</div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div className={classes.tfrInfo}>
        <div className={classes.title}>Total Feedback per Category</div>
      </div>
      <div>{ViewOptions()}</div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {ViewChart()}
      </div>
    </div>
  )
}
export default TotalFeedbackPerCategory
