import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  header: {
    borderBottom: "3px solid #D5D5D5",
    height: "81px",
    display: "flex",
    alignItems: "center",
    padding: "0 58px"
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "calc(100vh - 81px)"
  },
  contentIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "48px",
    lineHeight: "56px",
    padding: "30px 0 10px"
  },
  subtitle: {
    color: theme.palette.body.gray,
    fontSize: "14px"
  },
  cta: {
    width: "90%",
    margin: "0 auto",
    padding: "70px 0 130px"
  }
}))

export default styles
