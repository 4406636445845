import * as Yup from "yup"

const validation: Record<any, {}> = {
  personalDetails: Yup.object().shape({
    unitUID: Yup.string().required("Required"),
    payeeName: Yup.string().required("Required"),
    payeeAddress: Yup.string().required("Required"),
    // payeePhone: Yup.string().required("Required"),
    payeePhone: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        {
          message: "Please enter valid number.",
          excludeEmptyString: false
        }
      )
      .required("Required"),
    payeeEmail: Yup.string()
      .email("Invalid email")
      .required("Required")
  }),
  paymentSchedule: Yup.object().shape({
    paymentName: Yup.string().required("Required"),
    amt: Yup.string().required("Required"),
    tax: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    notifyOnDaysAfterDue: Yup.string().required("Required"),
    notifyOnDaysBefore: Yup.string().required("Required")
  }),
  addPayment: Yup.object().shape({
    unitUID: Yup.string().required("Required"),
    payeeName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    amt: Yup.string().required("Required"),
    tax: Yup.string().required("Required"),
    dueDate: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    paymentType: Yup.string().required("Required")
  }),
  addPaymentRefund: Yup.object().shape({
    unitUID: Yup.string().required("Required"),
    payeeName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    amt: Yup.string().required("Required"),
    dueDate: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    paymentType: Yup.string().required("Required")
  }),
  closePayment: Yup.object().shape({
    remarks: Yup.string().required("Required")
  }),
  closePaymentAgent: Yup.object().shape({
    remarks: Yup.string().required("Required"),
    paymentReference: Yup.string().required("Required"),
    ePaymentAgent: Yup.string().required("Required")
  }),
  refundPayment: Yup.object().shape({
    description: Yup.string().required("Required"),
    remarks: Yup.string().required("Required"),
    paymentType: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    amt: Yup.string().required("Required"),
    tax: Yup.string().required("Required"),
    refundTo: Yup.string().required("Required")
  }),
  cancelPayment: Yup.object().shape({
    remarks: Yup.string().required("Required")
  }),
  cancelRefund: Yup.object().shape({
    remarks: Yup.string().required("Required")
  }),
  rejectPayment: Yup.object().shape({
    remarks: Yup.string().required("Required")
  })
}

export default validation
