import React from "react"

import SettingsVisitorManagementProvider from "context/Settings/PropertyManagement/VisitorManagement/main/Provider"
import SettingsVisitorManagementsView from "context/Settings/PropertyManagement/VisitorManagement/main/View"

const SettingsVisitorManagement: React.FC = () => {
  return (
    <SettingsVisitorManagementProvider>
      <SettingsVisitorManagementsView />
    </SettingsVisitorManagementProvider>
  )
}

export default SettingsVisitorManagement
