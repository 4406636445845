import useSWR from "swr"
import service from "services/Dashboard/Dashboard/"

export function CountFeedbackHook({ startDate, endDate }: any) {
  const fetchCountFeedback = async () =>
    service.getCountFeedback({
      "start_date": startDate,
      "end_date": endDate
    })

  const { data, isValidating, error } = useSWR(
    "fetchCountFeedback",
    fetchCountFeedback,
    {
      revalidateOnFocus: true
    }
  )
  const totalFeedback = data?.data?._data?.total

  return {
    totalFeedback,
    isValidating,
    error
  }
}
