import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Components */
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"

/** Config */
// import { accessCardType } from "config/Dashboard/Applications"

/** Utils */
import { fromUnix } from "utils/date"

/** Styles */
import { Context } from "context/Dashboard/UnitManagement/categories/accessCards/Context"
import styles from "./styles"

/** Context */

const DetailsSummary: React.FC = () => {
  const classes = styles()

  const { activeData } = React.useContext(Context)
  const ad = activeData

  function checkValue(value: any, output?: string) {
    if (value === 0 || value === "") {
      return "N/A"
    }

    return output !== undefined ? output : value
  }

  return (
    <Card>
      <Box>
        <Box>
          <Box className={classes.section}>
            <Box className={classes.sectionPadding}>
              <Box>
                <Box className={classes.sectionTitle}>Personal Info</Box>
              </Box>
              <Box className={classes.sectionItem}>
                <Label label="owner name" />
                <Value val={ad?.owner_name} />
              </Box>
            </Box>
          </Box>

          <Box className={classes.section}>
            <Box className={classes.sectionPadding}>
              <Box className={classes.sectionTitle}>Access Card Info</Box>
              <Box className={classes.sectionItem}>
                <Label label="serial number" />
                <Value val={checkValue(ad?.serial_no)} />
              </Box>
              <Box display="flex">
                <Box flex="1" className={classes.sectionItem}>
                  <Label label="activation date" />
                  <Value
                    val={checkValue(
                      ad?.activation_date,
                      fromUnix(ad?.activation_date)
                    )}
                  />
                </Box>
                <Box flex="1" className={classes.sectionItem}>
                  <Label label="expiry date" />
                  <Value
                    val={checkValue(ad?.expiry_date, fromUnix(ad?.expiry_date))}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
export default DetailsSummary
