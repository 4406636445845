import React from "react"

const IconPrint = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0V4H3C1.35 4 0 5.35 0 7V14H4V18H16V14H20V7C20 5.35 18.65 4 17 4H16V0H4ZM6 2H14V4H6V2ZM3 6H17C17.552 6 18 6.448 18 7V12H16V10H4V12H2V7C2 6.448 2.448 6 3 6ZM16 7C15.7348 7 15.4804 7.10536 15.2929 7.29289C15.1054 7.48043 15 7.73478 15 8C15 8.26522 15.1054 8.51957 15.2929 8.70711C15.4804 8.89464 15.7348 9 16 9C16.2652 9 16.5196 8.89464 16.7071 8.70711C16.8946 8.51957 17 8.26522 17 8C17 7.73478 16.8946 7.48043 16.7071 7.29289C16.5196 7.10536 16.2652 7 16 7ZM6 12H14V16H6V12Z"
      fill="#09707B"
    />
  </svg>
)

export default IconPrint
