import React from "react"

import SettingsOwnersTenantProvider from "context/Settings/AccountManagement/OwnersTenants/main/Provider"
import SettingsOwnersTenantView from "context/Settings/AccountManagement/OwnersTenants/main/View"

const OwnersTenant: React.FC = () => (
  <SettingsOwnersTenantProvider>
    <SettingsOwnersTenantView />
  </SettingsOwnersTenantProvider>
)

export default OwnersTenant
