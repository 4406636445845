const FaciltiesSettingsConfig: any = {
  tabs: {
    0: "Category Details & Booking Settings",
    1: "Entitlement Period & Payment",
    2: "Time Slot",
    3: "Effective Date"
  },
  tabsFacilities: {
    0: "Facility Details",
    1: "Photos",
    2: "Status"
  },
  categoryTypes: {
    1: "BBQPit",
    2: "BasketballCourt",
    3: "BadmintonCour",
    4: "DiningRoom",
    5: "FunctionRoom",
    6: "Gym",
    7: "KTVRoom",
    8: "SwimmingPool",
    9: "SquashCourt",
    10: "TennisCourt",
    11: "TableTennis",
    12: "Others"
  },
  facilityStatus: {
    1: "Open",
    2: "Suspended"
  },
  entitlement: {
    0: "All",
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Quarterly",
    5: "Half Yearly",
    6: "Yearly"
  }
}

export const {
  entitlement,
  tabs,
  tabsFacilities,
  categoryTypes,
  facilityStatus
} = FaciltiesSettingsConfig
