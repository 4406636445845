import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
/**
 * Components
 */
import { Context } from "context/Dashboard/Dashboard/main/Context"
import Block from "./Block"
/**
 * Context
 */
/**
 * Hooks
 */
import { CountAccountRequestHook, CountChangeAddress } from "./hooks"

const NewAccountsRequest: React.FC = () => {
  const { dateOptions } = useContext(Context)
  const {
    totalAccountRequest,
    isValidatingAccountRequest
  } = CountAccountRequestHook()

  const { totalChangeAddress, isValidatingChangeAddress } = CountChangeAddress()

  return (
    <Card>
      <Block
        hasIcon
        blockTitle="New Accounts Requests"
        isLoading={isValidatingAccountRequest}
        totalCount={totalAccountRequest !== undefined ? totalAccountRequest : "N/A"}
        date={dateOptions?.currentDate}
        url="/property-management/unit-management"
      />

      <Block
        blockTitle="Change Address Requests"
        isLoading={isValidatingChangeAddress}
        totalCount={totalChangeAddress !== undefined ? totalChangeAddress : "N/A"}
        date={dateOptions?.currentDate}
        url="/property-management/unit-management"
      />
    </Card>
  )
}

export default NewAccountsRequest
