const version = "v1"
const base = "svc-account"
const baseFacility = "svc-facility"

const API = {
  UPDATE_PERMISSION: `${base}/${version}/user-role-permission`,
  LIST_ROLE_PERMISSION: `${base}/${version}/user-role-permission`,
  LIST_RESIDENTS: `${baseFacility}/${version}/residents`
  // CREATE_APPLICATION: `${base}/${version}/setting`,
  // UPDATE_APPLICATION: `${base}/${version}/setting`,
  // DELETE_APPLICATION: `${base}/${version}/setting`
}

export default API
