import React from "react"

export type CtxType = {
  applicationDetails: any
  weekdayTime: any
  saturdayTime: any
  holidayTime: any
  setApplicationDetails: Function
  setWeekdayTime: Function
  setSaturdayTime: Function
  setHolidayTime: Function
  createCategory: Function
  sendBack: Function
  handleTimeChange: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
