import React from "react"
import Box from "@material-ui/core/Box"
/** Components */
import AccountsRequest from "pages/Dashboard/UnitManagement/tables/AccountsRequests"
import Owners from "pages/Dashboard/UnitManagement/tables/Owners"
import Residents from "pages/Dashboard/UnitManagement/tables/Residents"
import PaymentSchedules from "pages/Dashboard/UnitManagement/tables/PaymentSchedules"
import Vehicles from "pages/Dashboard/UnitManagement/tables/Vehicles"
import AccessCards from "pages/Dashboard/UnitManagement/tables/AccessCards"
/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

const UMMainView: React.FC<CtxType> = ({ activeTable }) => {
  const TableView = (value: number) => {
    switch (value) {
      case 1:
        return <AccountsRequest />
      case 2:
        return <Owners />
      case 3:
        return <Residents />
      case 4:
        return <PaymentSchedules />
      case 5:
        return <Vehicles />
      case 6:
        return <AccessCards />
      default:
        break
    }
    return value
  }

  return <Box>{TableView(activeTable)}</Box>
}

export default withContext(UMMainView)
