import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "60%",
    margin: "0 auto"
  },
  section: {
    margin: "25px auto 45px auto",
    "& > div": {
      marginBottom: "25px"
    }
  },
  title: {
    fontSize: "16px",
    color: theme.palette.body.gray,
    paddingBottom: "20px"
  }
}))

export default styles
