import useSWR from "swr"
import service from "services/Dashboard/Facilities"
import { toUnixAddDay } from "utils/date"

export function FacilitiesTodayPreview(date: any) {
  const todayStartDate = date?.startDate
  const todayEndDate = date?.endDate

  const fetchDashboardFacilities = async () =>
    service.getBookings({
      "booking_date": "desc",
      "start_timestamp": todayStartDate,
      "end_timestamp": todayEndDate,
      "booking_status": 2,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardFacilities-${todayStartDate}-${todayEndDate}`,
    fetchDashboardFacilities,
    {
      revalidateOnFocus: true
    }
  )
  const facilitiesList = data?.data?._data

  return {
    facilitiesList,
    isValidating,
    error
  }
}

export function FacilitiesTomorrowPreview(date: any) {
  const tomStartDate = toUnixAddDay(`${date?.currentDate} 00:00:00`)
  const tomEndDate = toUnixAddDay(`${date?.currentDate} 23:59:00`)

  const fetchDashboardFacilitiesTomorrow = async () =>
    service.getBookings({
      "booking_date": "desc",
      "start_timestamp": tomStartDate,
      "end_timestamp": tomEndDate,
      "booking_status": 2,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardFacilitiesTomorrow-${tomStartDate}-${tomEndDate}`,
    fetchDashboardFacilitiesTomorrow,
    {
      revalidateOnFocus: true
    }
  )
  const facilitiesListTomorrow = data?.data?._data

  return {
    facilitiesListTomorrow,
    isValidatingTomorrow: isValidating,
    error
  }
}

export function FacilitiesPending() {
  const fetchDashboardFacilitiesPending = async () =>
    service.getBookings({
      "booking_date": "desc",
      "booking_status": 1,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardFacilitiesPending`,
    fetchDashboardFacilitiesPending,
    {
      revalidateOnFocus: true
    }
  )
  const facilitiesPendingList = data?.data?._data

  return {
    facilitiesPendingList,
    isValidatingPending: isValidating,
    error
  }
}
