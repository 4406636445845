import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import TabsButtons from "components/Table/components/TabsButtons"
import PermissionMessage from "components/PermissionMessage"

/** Tabs */
import TabCategories from "pages/Settings/PropertyManagement/Bookkeeping/components/Categories"
import TabPaymentModes from "pages/Settings/PropertyManagement/Bookkeeping/components/PaymentModes"
import TabPaymentTypes from "pages/Settings/PropertyManagement/Bookkeeping/components/Types"

/** Config */
import { tabs as OptionTabs } from "config/Settings/PropertyManagement/Bookkeeping"

/** Context */
import { AppContext } from "App"
import withContext from "./withContext"
import { CtxType } from "./Context"

const SettingsAdminView: React.FC<CtxType> = ({ activeTab, setActiveTab }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const ViewTabCategories = (): JSX.Element => {
    if (activeTab !== 1) {
      return <div />
    }

    return <TabCategories />
  }

  const ViewTabPaymentModes = (): JSX.Element => {
    if (activeTab !== 2) {
      return <div />
    }

    return <TabPaymentModes />
  }

  const ViewTabPaymentTypes = (): JSX.Element => {
    if (activeTab !== 0) {
      return <div />
    }

    return <TabPaymentTypes />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box display="flex">
          <Box>
            <PageHeaderTitle
              title="Bookkeeping"
              breadcrumbs="SETTINGS / Bookkeeping"
            />
          </Box>
        </Box>
        <Box>
          <TabsButtons
            options={OptionTabs}
            action={setActiveTab}
            activeTab={activeTab}
          />
        </Box>
        <Box>
          <Card>
            {ViewTabCategories()}
            {ViewTabPaymentModes()}
            {ViewTabPaymentTypes()}
          </Card>
          {/* <Fade in={!isValidating} timeout={1000}>
            <Form Data={Data && Data} />
          </Fade> */}
        </Box>
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.manage_condo_settings) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(SettingsAdminView)
