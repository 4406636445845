import React from "react"

export type CtxType = {
  setOpenDialog: Function
  handleRegisterAccessCard: Function
  setAccessCardDetails: Function
  setPhotos: Function
  setAttaching: Function
  handleDocsUpload: Function
  removeDoc: Function
  attaching: boolean
  openDialog: boolean
  accessCardDetails: Record<string, any>
  photos: any
  localPhotos: any
}

export const Context = React.createContext<Partial<CtxType>>({})
