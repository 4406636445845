import React, { useState, useContext, useMemo } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
import { withRouter } from "react-router-dom"

import { AuthContext } from "context/auth-context"
/**
 * Components
 */
import FormInput from "components/Forms/FormInput"
/**
 * Config
 */
import { resetPassword as resetPasswordValidation } from "config/Onboard/validation"

const Form: React.FC = (props: any) => {
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: ""
  })
  const [isSubmitting, setSubmitting] = useState(false)
  const { resetPassword } = useContext(AuthContext)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)
    setPassword && setPassword((data: any) => ({ ...data, [name]: e.target.value }))
  }

  const handleFormSubmit = (handleSubmit: Function, isFormValid: boolean): void => {
    if (!isFormValid) {
      return
    }

    setSubmitting(true)

    if (password?.newPassword !== "" && password?.confirmPassword !== "") {
      const params = new URLSearchParams(props?.location?.search)
      const token = params.get("t")
      const email = params.get("u")

      resetPassword &&
        resetPassword(password?.newPassword, token, email).then(() =>
          setSubmitting(false)
        )
    } else {
      handleSubmit()
      setSubmitting(false)
    }
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return {
            newPassword: password?.newPassword,
            confirmPassword: password?.confirmPassword
          }
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={resetPasswordValidation}
        isInitialValid={false}
      >
        {({
          isValid,
          touched,
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldTouched
        }): JSX.Element => {
          return (
            <form>
              <div className="section">
                <Box marginBottom="25px">
                  <FormInput
                    type="password"
                    name="newPassword"
                    value={password?.newPassword}
                    idValue="newPassword"
                    label="New Password"
                    placeholder="New Password"
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleFormChange(
                        "newPassword",
                        e,
                        handleChange,
                        setFieldTouched
                      )}
                    onBlur={handleBlur}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={
                      errors.newPassword && touched.newPassword && errors.newPassword
                    }
                  />
                </Box>

                <Box marginBottom="25px">
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    value={password?.confirmPassword}
                    idValue="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleFormChange(
                        "confirmPassword",
                        e,
                        handleChange,
                        setFieldTouched
                      )}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                  />
                </Box>
              </div>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleFormSubmit(handleSubmit, isValid)}
                  size="large"
                  disabled={isSubmitting}
                  className="btn-next"
                >
                  {isSubmitting ? "Submitting." : "Submit"}
                </Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
export default withRouter(Form)
