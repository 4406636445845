/* eslint-disable @typescript-eslint/camelcase */
import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

/** Components */
import Label from "components/Forms/Label"

/** Utils */
import { fromUnix } from "utils/date"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  },
  values: {
    minWidth: "calc(100%/3)",
    marginBottom: "20px"
  }
}))

interface RenovationSummaryProps {
  info: Record<string, any>
}

const RenovationSummary: React.FC<RenovationSummaryProps> = ({ info }) => {
  const { value, values } = styles()

  const { contractor_name, contractor_contact } = info?.request_data?.renovation
  const { start_date, end_date, serial_no, remarks } = info

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="initial"
      paddingBottom="30px"
      width="60%"
      margin="auto"
    >
      <Box className={values}>
        <Label label="Contractor Name" />
        <Box className={value}>
          {contractor_name === "" ? "N/A" : contractor_name}
        </Box>
      </Box>
      <Box className={values}>
        <Label label="Contractor Contact" />
        <Box className={value}>
          {contractor_contact === "" ? "N/A" : contractor_contact}
        </Box>
      </Box>
      {start_date !== 0 && (
        <Box className={values}>
          <Label label="Start Date" />
          <Box className={value}>{fromUnix(start_date)}</Box>
        </Box>
      )}
      {end_date !== 0 && (
        <Box className={values}>
          <Label label="End Date" />
          <Box className={value}>{fromUnix(end_date)}</Box>
        </Box>
      )}
      <Box className={values}>
        <Label label="Serial No" />
        <Box className={value}>{serial_no === "" ? "N/A" : serial_no}</Box>
      </Box>
      <Box className={values}>
        <Label label="Remarks" />
        <Box className={value}>{remarks === "" ? "N/A" : remarks}</Box>
      </Box>
    </Box>
  )
}
export default RenovationSummary
