import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
/** components */
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
/** Tables */
import ActiveOwners from "pages/Dashboard/UnitManagement/tables/Owners/Active"
import ArchivedOwners from "pages/Dashboard/UnitManagement/tables/Owners/Archived"
import NewOwners from "pages/Dashboard/UnitManagement/tables/Owners/New"
import RegisteredOwners from "pages/Dashboard/UnitManagement/tables/Owners/Registered"
import ChangeRequests from "pages/Dashboard/UnitManagement/tables/Owners/ChangeRequests"
import UnitDetails from "pages/Dashboard/UnitManagement/categories/unitDetails"
import CreateOwnerForm from "pages/Dashboard/UnitManagement/components/Common/UserForm"
/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"
/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/main/Context"
import withMainContext from "context/Dashboard/UnitManagement/main/withContext"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { AppContext } from "App"
import { filterOwners } from "config/Dashboard/UnitManagement"
import { Context as SelfContext } from "./Context"

const OwnersView: React.FC<CtxType> = ({ activeTableFilter }) => {
  const { ownersTab, setOwnersTab } = activeTableFilter
  const {
    activeView,
    showCreateOwner,
    showDetails,
    downloadData
  } = React.useContext(ParentContext)
  const { accountRequestData, activeData } = React.useContext(SelfContext)
  const { permission } = useContext(AppContext)

  const csvFilename = `Owners-${filterOwners[ownersTab]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <ActiveOwners />
      case 1:
        return <NewOwners />
      case 2:
        return <RegisteredOwners />
      case 3:
        return <ChangeRequests />
      case 4:
        return <ArchivedOwners />
      default:
        break
    }
    return activeTableParam
  }

  const ViewDetails = (): JSX.Element => {
    if (activeView?.details !== "unitDetailsMain") {
      return <div />
    }
    return <UnitDetails />
  }

  const ViewEditFromUnit = (): JSX.Element => {
    if (activeView?.details !== "updateUser") {
      return <div />
    }
    return (
      <>
        <PageHeaderTitle
          title="Unit Details"
          breadcrumbs="property management / unit management / unit details"
          backAction={() => showDetails && showDetails()}
        />
        <CreateOwnerForm data={activeData} />
      </>
    )
  }

  const ViewCreateOwner = (): JSX.Element => {
    if (activeView !== "createOwner") {
      return <div />
    }
    return <CreateOwnerForm />
  }

  const ViewCreateFromData = (): JSX.Element => {
    if (activeView !== "createFromData") {
      return <div />
    }
    return <CreateOwnerForm data={accountRequestData} />
  }

  const ViewEditOwner = (): JSX.Element => {
    if (activeView !== "editOwner") {
      return <div />
    }
    return <CreateOwnerForm data={activeData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }
    return (
      <Box>
        <TableHeader
          addActionLabel="Add New User"
          title="Owners"
          titleIcon={MenuIcons.owners}
          addAction={() => showCreateOwner && showCreateOwner()}
          actionDisable={permission?.unit_mgt_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={ownersTab}
            handleChange={setOwnersTab}
            options={filterOwners}
          />
        </Box>
        <Box>{TableView(ownersTab)}</Box>
      </Box>
    )
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewDetails()}
      {ViewCreateOwner()}
      {ViewCreateFromData()}
      {ViewEditOwner()}
      {ViewEditFromUnit()}
    </>
  )
}

export default withMainContext(OwnersView)
