import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  generalInfo: {
    margin: "0 auto",
    padding: "0 0 20px 0",
    "& .section": {
      width: "40%",
      marginBottom: "40px"
    }
  },
  formWrapper: {
    padding: "0 50px 50px"
  },
  spaceBottom: {
    paddingBottom: "20px"
  },
  spaceBottomLarge: {
    paddingBottom: "30px"
  }
}))

export default styles
