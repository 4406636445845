import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: "1px solid #EDF0F1"
    },
    media: {
      height: 194
    }
  })
)

export default useStyles
