/* eslint-disable @typescript-eslint/camelcase */
import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

/** Components */
import Label from "components/Forms/Label"

/** Utils */
import { fromUnix } from "utils/date"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  },
  values: {
    minWidth: "calc(100%/3)",
    marginBottom: "20px"
  }
}))

interface MovingInOutSummaryProps {
  info: Record<string, any>
}

const MovingInOutSummary: React.FC<MovingInOutSummaryProps> = ({ info }) => {
  const { value, values } = styles()

  const {
    name,
    email,
    mobile,
    eta_ms,
    purpose,
    remarks
  } = info?.request_data?.move_in_out
  const { start_date, end_date, serial_no } = info

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="initial"
      paddingBottom="30px"
      width="60%"
      margin="auto"
    >
      <Box className={values}>
        <Label label="Name" />
        <Box className={value}>{name === "" ? "N/A" : name}</Box>
      </Box>
      <Box className={values}>
        <Label label="Email" />
        <Box className={value}>{email === "" ? "N/A" : email}</Box>
      </Box>
      <Box className={values}>
        <Label label="Mobile" />
        <Box className={value}>{mobile === "" ? "N/A" : mobile}</Box>
      </Box>
      <Box className={values}>
        <Label label="ETA" />
        <Box className={value}>{fromUnix(eta_ms)}</Box>
      </Box>

      {start_date !== 0 && (
        <Box className={values}>
          <Label label="Start Date" />
          <Box className={value}>{fromUnix(start_date)}</Box>
        </Box>
      )}
      {end_date !== 0 && (
        <Box className={values}>
          <Label label="End Date" />
          <Box className={value}>{fromUnix(end_date)}</Box>
        </Box>
      )}
      <Box className={values}>
        <Label label="Serial No." />
        <Box className={value}>{serial_no === "" ? "N/A" : serial_no}</Box>
      </Box>
      <Box className={values}>
        <Label label="Purpose" />
        <Box className={value}>{purpose === "" ? "N/A" : purpose}</Box>
      </Box>
      <Box className={values}>
        <Label label="Remarks" />
        <Box className={value}>{remarks === "" ? "N/A" : remarks}</Box>
      </Box>
    </Box>
  )
}
export default MovingInOutSummary
