import * as React from "react"

interface ApplicationsProps {
  color: string
}

const Applications: React.FC<ApplicationsProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM4 2C4.552 2 5 2.448 5 3C5 3.552 4.552 4 4 4C3.448 4 3 3.552 3 3C3 2.448 3.448 2 4 2ZM7 2C7.552 2 8 2.448 8 3C8 3.552 7.552 4 7 4C6.448 4 6 3.552 6 3C6 2.448 6.448 2 7 2ZM10 2H16V4H10V2ZM2 6H16V16H2V6ZM5.5 8C5.10218 8 4.72064 8.15804 4.43934 8.43934C4.15804 8.72064 4 9.10218 4 9.5C4 9.89782 4.15804 10.2794 4.43934 10.5607C4.72064 10.842 5.10218 11 5.5 11C5.89782 11 6.27936 10.842 6.56066 10.5607C6.84196 10.2794 7 9.89782 7 9.5C7 9.10218 6.84196 8.72064 6.56066 8.43934C6.27936 8.15804 5.89782 8 5.5 8ZM10 8V10H14V8H10ZM5.5 12C4.666 12 3 12.419 3 13.25V14H8V13.25C8 12.419 6.334 12 5.5 12ZM10 12V14H14V12H10Z"
        fill={color}
      />
    </svg>
  )
}

export default Applications
