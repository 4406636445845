import React from "react"

export type CtxType = {
  activeData: Record<string, any>
  userData: Record<string, any>
  formType: number
  showForm: boolean
  resetForm: boolean
  isDeleting: boolean
  setShowForm: Function
  setActiveData: Function
  showFormType: Function
  showResetForm: Function
  setUserData: Function
  resetData: Function
  handleDeleteUser: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
