import useSWR from "swr"
import service from "services/Dashboard/Dashboard/"

export function CountPaymentToday({ startDate, endDate }: any) {
  const fetchCountPaymentToday = async () =>
    service.getTotalCollections({
      "start_date": startDate,
      "end_date": endDate
    })

  const { data, isValidating, error } = useSWR(
    "fetchCountPaymentToday",
    fetchCountPaymentToday,
    {
      revalidateOnFocus: true
    }
  )
  const totalPayment = data?.data?._data

  return {
    totalPayment,
    isValidatingPayment: isValidating,
    error
  }
}

export function CountDepositToday({ startDate, endDate }: any) {
  const fetchCountDeposit = async () =>
    service.getTotalDeposits({
      "start_date": startDate,
      "end_date": endDate
    })

  const { data, isValidating, error } = useSWR(
    "fetchCountDeposit",
    fetchCountDeposit,
    {
      revalidateOnFocus: true
    }
  )
  const totalDeposit = data?.data?._data

  return {
    totalDeposit,
    isValidatingDeposit: isValidating,
    error
  }
}
