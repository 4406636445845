import * as React from "react"

const AccountRequest = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0V3H5V0H3ZM7 0V3H9V0H7ZM11 0V3H13V0H11ZM0 4V5C0 7.65632 1.66865 9.9054 4.07812 11.0938L0 20H2.17188L4.01562 16H11.9707L12.3652 16.8516C12.1283 17.1877 12.0008 17.5887 12 18C12 18.5304 12.2107 19.0391 12.5858 19.4142C12.9609 19.7893 13.4696 20 14 20C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18C15.9996 17.501 15.8126 17.0201 15.4758 16.6519C15.139 16.2836 14.6767 16.0546 14.1797 16.0098L11.9043 11.1035C14.3235 9.91754 16 7.66297 16 5V4H0ZM2.24023 6H13.7598C13.1965 8.22071 10.9583 10 8 10C5.04168 10 2.80354 8.22071 2.24023 6ZM5.9707 11.7617C6.62276 11.9119 7.30093 12 8 12C8.69134 12 9.36232 11.9146 10.0078 11.7676L11.043 14H4.9375L5.9707 11.7617Z"
      fill="#454B57"
    />
  </svg>
)

export default AccountRequest
