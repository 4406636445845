import React from "react"

import ContextMenu from "components/ContextMenu"
import { checkValue } from "utils/helpers"
import ContextItems from "./ContextMenu"

const tableHeaders = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Current Block/Unit No",
        id: "currentUnitShortName",
        accessor: (c: any) => checkValue(c?.current_unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.current_unit?.short_name),
        width: 200
      },
      {
        Header: "New Block/Unit No",
        id: "newUnitShortName",
        accessor: (c: any) => checkValue(c?.new_unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.new_unit?.short_name),
        width: 200
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c: any) => checkValue(c?.user_detail?.name),
        Cell: ({ original }: any) => checkValue(original?.user_detail?.name)
      },
      {
        Header: "Email",
        id: "email",
        accessor: (c: any) => checkValue(c?.user_detail?.email),
        Cell: ({ original }: any) => checkValue(original?.user_detail?.email)
      },
      {
        Header: "Mobile No.",
        id: "mobileNumber",
        accessor: (c: any) => checkValue(c?.user_detail?.mobile_no),
        Cell: ({ original }: any) => checkValue(original?.user_detail?.mobile_no)
      }
    ]
  }
]

export default tableHeaders
