import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "0 23px 0",
    borderBottom: "1px solid #F2F2F2"
  },
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    marginTop: 20
  }
}))

export default styles
