import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getAttendances(params: Record<string, any>) {
  return request({
    url: API.LIST_ATTENDANCES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function createAttendance(data: Object) {
  return request({
    url: API.CREATE_ATTENDANCE,
    method: "POST",
    data: {
      "condo_uid": Cookies.get("condoUID"),
      ...data
    },
    headers: headers()
  })
}

function updateAttendance(data: Object, uid: string) {
  return request({
    url: `${API.UPDATE_ATTENDANCE}/${uid}`,
    method: "PUT",
    data: {
      "condo_uid": Cookies.get("condoUID"),
      ...data
    },
    headers: headers()
  })
}

function deleteAttendance(uid: string) {
  return request({
    url: `${API.DELETE_ATTENDANCE}/${uid}`,
    method: "DELETE",
    headers: headers()
  })
}

const AttendanceAPI = {
  getAttendances,
  createAttendance,
  updateAttendance,
  deleteAttendance
}

export default AttendanceAPI
