import React from "react"
import Box from "@material-ui/core/Box"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers"

import FormLabel from "components/Forms/Label"

interface FormInputProps {
  label: string
  value: any
  handleDateChange: Function
  onBlur?: Function
  error?: boolean | undefined
  helperText?: boolean | string | undefined
  name: string
  ampm?: boolean
}

const DateTimePicker: React.FC<FormInputProps> = ({
  label = "date",
  value,
  handleDateChange,
  onBlur,
  error,
  helperText,
  ampm = true
}) => {
  return (
    <Box flex="1">
      <FormLabel label={label} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          inputVariant="outlined"
          ampm={ampm}
          value={value}
          onChange={(e: any) => handleDateChange(e)}
          onClose={() => document.body.removeAttribute("style")}
          onError={(e: any) => e}
          disablePast
          format="MMM dd, yyyy - HH:mm a"
          onBlur={(e) => onBlur && onBlur(e)}
          error={error}
          helperText={helperText}
          hideTabs
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}
export default DateTimePicker
