import * as React from "react"

const ScheduleServicing = (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60763 0V2H2.62398C1.53901 2 0.656677 2.897 0.656677 4V18C0.656677 19.103 1.53901 20 2.62398 20H16.3951C17.4801 20 18.3624 19.103 18.3624 18V4C18.3624 2.897 17.4801 2 16.3951 2H15.4114V0H13.4441V2H5.57493V0H3.60763ZM2.62398 4H16.3951V6H2.62398V4ZM2.62398 8H16.3951L16.397 18H2.62398V8ZM12.2645 9.30078L9.01771 12.5996L7.24637 10.8008L5.8708 12.1992L9.01771 15.4004L13.6401 10.6992L12.2645 9.30078Z"
      fill="#454B57"
    />
  </svg>
)

export default ScheduleServicing
