import React from "react"

/** Context */
import { Context } from "./Context"

const FeedbackCleanlinessProvider: React.FC = ({ children }) => {
  return <Context.Provider value={{}}>{children}</Context.Provider>
}

export default FeedbackCleanlinessProvider
