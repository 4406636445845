import React from "react"
/* Context */
import { DetailsContext } from "./DetailsContext"

export interface DetailsProvidersProps {
  showForm?: Function
  data?: Record<string, any>
}

const DetailsProvider: React.FC<DetailsProvidersProps> = ({
  data,
  children,
  showForm
}) => {
  const [photos] = React.useState(Array)

  return (
    <>
      <DetailsContext.Provider
        value={{
          photos,
          data,
          showForm
        }}
      >
        {children}
      </DetailsContext.Provider>
    </>
  )
}

export default DetailsProvider
