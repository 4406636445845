import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    "& > div": {
      flex: 1
    }
  },
  containerDetails: {
    borderRight: "1px solid #F2F2F2"
  },
  labelStyle: {
    fontSize: "10px",
    marginBottom: "8px",
    color: theme.palette.body.secondary,
    textTransform: "uppercase"
  },
  valueStyle: {
    fontSize: "16px",
    color: theme.palette.body.main
  },
  spaceBottom: {
    marginBottom: "20px"
  },
  twoCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}))

export default styles
