import * as React from "react"

const Payments: React.FC = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.906937 0 0 0.906937 0 2V14C0 15.0931 0.906937 16 2 16H20C21.0931 16 22 15.0931 22 14V2C22 0.906937 21.0931 0 20 0H2ZM2 2H14.7148V3.0293C14.3758 3.0723 12.4766 3.42875 12.4766 5.71875C12.4766 9.05975 15.9355 8.07078 15.9355 10.3008C15.9355 11.4168 15.2154 11.4434 15.0684 11.4434C14.9344 11.4434 14.0527 11.5136 14.0527 9.80664H12.1621C12.1621 12.6986 14.2622 12.9277 14.5742 12.9707V14H2V2ZM15.7227 2H20V14H15.5762V12.9707C15.9152 12.9277 17.8262 12.6141 17.8262 10.2871C17.8252 7.08111 14.3652 7.69356 14.3652 5.72656C14.3652 4.59956 14.9871 4.56445 15.1211 4.56445C15.3661 4.56445 15.9492 4.7813 15.9492 6.1543H17.8398C17.8398 3.5263 16.0437 3.11764 15.7227 3.05664V2ZM4 5V7H10V5H4ZM4 9V11H9V9H4Z"
        fill="#454B57"
      />
    </svg>
  )
}

export default Payments
