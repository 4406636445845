import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import withMainContext from "context/Dashboard/UnitManagement/categories/main/withContext"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import tableHeaders from "./table/tableHeaders"

interface TableAllProps {
  activeFilter: number
}

const TableAll: React.FC<TableAllProps> = ({ activeFilter }) => {
  const { setDownloadData } = useContext(ParentContext)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const isArchive = activeFilter === 7
  const fetchScheduledPayment = async () =>
    BookkeepingAPI.getScheduledPayments(isArchive)

  const { data, isValidating } = useSWR(
    `fetchScheduledPayment-${activeFilter}`,
    fetchScheduledPayment,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []
  const filteredData = tableData?.filter((i: any) => {
    if (activeFilter === 7) {
      return true
    }
    return i.frequency === activeFilter
  })
  const activeData = activeFilter !== 0 ? filteredData : tableData

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={activeData || []}
          columns={tableHeaders}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              values.length !== 0 &&
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Block/Unit_No.": i?.blockUnitNo,
                    "Payment Name": i?.paymentName,
                    "Frequency": i?.frequency,
                    "Amount": i?.amount,
                    "Start Date": i?.startDate,
                    "Email Reminder": i?.emailReminder,
                    "Send Email": i?.sendEmail,
                    "Re-send Email": i?.resendEmail,
                    "Name": i?.payeeName,
                    "Email": i?.payeeEmail,
                    "Phone": i?.payeePhone,
                    "Address": i?.payeeAddress
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default withMainContext(TableAll)
