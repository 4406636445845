import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  btn: {
    borderColor: "transparent",
    background: "#F2F2F2",
    color: theme?.palette?.body?.gray,
    fontSize: "16px",
    padding: "0 40px",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    minHeight: "76px",
    cursor: "pointer",
    position: "relative",
    outline: "none",
    borderRadius: "0px 4px 4px 0px",
    "&.active": {
      background: theme?.palette?.secondary?.main,
      color: theme?.palette?.common?.white
    },
    "&:first-child": {
      borderRadius: "10px 4px 4px 0px"
    },
    "&:nth-child(2)": {
      borderRadius: "0px 4px 4px 0px"
    },
    "&:last-child": {
      borderRadius: "0px 10px 0px 0px"
    },
    "& > .arrow-right": {
      width: 0,
      height: 0,
      borderTop: "38px solid transparent",
      borderBottom: "38px solid transparent",
      borderLeftWidth: "25px",
      borderLeftColor: "#E17E23",
      borderLeftStyle: "solid",
      position: "absolute",
      right: "-25px",
      zIndex: 1,
      "&.wide": {
        right: "-23px"
      }
    },
    "&:last-child > .arrow-right": {
      display: "none"
    }
  }
}))

export default styles
