import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import LinkButton from "components/LinkButton"
import PageHeaderTitle from "components/PageHeaderTitle"
import FilterButtons from "components/FilterButtons"
import MainCategories from "pages/Dashboard/Applications/categories/main"
import AddApplication from "pages/Dashboard/Applications/categories/forms/addApplication"
import RejectApplication from "pages/Dashboard/Applications/components/RejectApplication"
// import ViewDetails from "pages/Dashboard/Announcements/Post/components/ViewDetails"
import PermissionMessage from "components/PermissionMessage"

/** Context */
import withContext from "context/Dashboard/Applications/main/withContext"
import { CtxType } from "context/Dashboard/Applications/main/Context"
import { AppContext } from "App"

/** Config */
import Menu from "pages/Dashboard/Applications/config/Menu"

/** Machine */
import { ApplicationsMachine } from "machines/Dashboard/Applications"

const Bookkeeping: React.FC<CtxType> = ({
  setActiveTable,
  activeTable,
  setActiveData,
  activeData,
  setShowReject,
  showReject,
  activeFilter,
  setActiveFilter,
  settings
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(ApplicationsMachine)
  const xValue = current?.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const ViewTitle = (): JSX.Element => {
    if (xValue === "tableView") {
      return (
        <PageHeaderTitle
          title="Applications"
          breadcrumbs="property management / applications"
        />
      )
    }

    if (xValue === "addApplication") {
      return (
        <PageHeaderTitle
          title="Add Application"
          breadcrumbs="property management / applications / add new application"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "updateApplication") {
      return (
        <PageHeaderTitle
          title="Edit Application"
          breadcrumbs="property management / applications / edit application"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "approveApplication") {
      return (
        <PageHeaderTitle
          title="Approve Application"
          breadcrumbs="property management / applications / approve application"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    return <div />
  }

  const ViewMenu = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <>
        {Menu.map((item: Record<string, any>, index: number) => {
          const { label, value } = item
          return (
            <FilterButtons
              key={value}
              label={label}
              onClick={() => setActiveTable(index)}
              isActive={activeTable === index}
            />
          )
        })}
      </>
    )
  }

  const MainView = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <Box>
        <MainCategories
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          showMain={() => send("TABLE_VIEW")}
          showAddApplication={() => send("ADD_APPLICATION")}
          showUpdateApplication={(data: Record<string, any>) => {
            send("UPDATE_APPLICATION")
            setActiveData(data)
          }}
          showApproveApplication={(data: Record<string, any>) => {
            send("APPROVE_APPLICATION")
            setActiveData(data)
          }}
        />
      </Box>
    )
  }

  const ViewAddApplication = (): JSX.Element => {
    if (xValue !== "addApplication") {
      return <div />
    }

    return (
      <AddApplication
        showMain={() => send("TABLE_VIEW")}
        settings={settings}
        activeTable={activeTable}
      />
    )
  }

  const ViewUpdateApplication = (): JSX.Element => {
    if (xValue !== "updateApplication") {
      return <div />
    }
    return (
      <AddApplication
        data={activeData}
        showMain={() => send("TABLE_VIEW")}
        settings={settings}
        activeTable={activeTable}
      />
    )
  }

  const ViewReject = (): JSX.Element => {
    return (
      <RejectApplication
        openState={showReject}
        setOpenState={setShowReject}
        data={activeData}
      />
    )
  }

  const ViewApproveApplication = (): JSX.Element => {
    if (xValue !== "approveApplication") {
      return <div />
    }
    return (
      <AddApplication
        data={activeData}
        type={1}
        showMain={() => send("TABLE_VIEW")}
        settings={settings}
        activeTable={activeTable}
      />
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.application_view) {
    return (
      <WithHeader>
        <Box display="flex" justifyContent="space-between">
          <ViewTitle />
          <Box>
            <LinkButton
              name="APPLICATION SETTINGS"
              url={
                permission?.manage_condo_settings
                  ? "/settings/property-management/applications"
                  : "#"
              }
            />
          </Box>
        </Box>
        <Box display="flex" marginBottom="15px">
          {ViewMenu()}
        </Box>
        {MainView()}
        {ViewAddApplication()}
        {ViewUpdateApplication()}
        {ViewReject()}
        {ViewApproveApplication()}
      </WithHeader>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(Bookkeeping)
