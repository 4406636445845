import React, { Suspense, useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Components */
import Loader from "components/Loader"
import Stepper from "components/Stepper/tab"
import Footer from "components/Forms/Footer/new"
import Dialog from "components/Dialog"

import CategoryDetails from "pages/Settings/PropertyManagement/VisitorManagement/components/CategoryForm/CategoryDetails"
import TimeSlots from "pages/Settings/PropertyManagement/VisitorManagement/components/CategoryForm/TimeSlots"

/** Context */
import { CategoryFormMachine } from "machines/Settings/PropertyManagement/VisitorManagement/categoryForm"
import { Context } from "./Context"

/** Machine */

export const refSubmit = React.createRef<HTMLDivElement>()

const View: React.FC = () => {
  const [current, send] = useMachine(CategoryFormMachine)
  const xValue = current.value
  const [openDialog, setOpenDialog] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const { applicationDetails, createCategory, sendBack } = useContext(Context)
  const { name, daysAdvanceBooking, daysCancelBooking } = applicationDetails

  const CurrentStep = (step: any) => {
    switch (step) {
      case "details":
        return 1
      case "timeSlots":
        return 2
      default:
        break
    }
    return step
  }

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()

    if (
      xValue === "details" &&
      name !== "" &&
      daysAdvanceBooking !== "" &&
      daysCancelBooking !== ""
    ) {
      send("NEXT")
    }

    if (xValue === "timeSlots" && isValid) {
      createCategory && createCategory().then(() => sendBack && sendBack())
    } else {
      refSubmit.current?.click()
    }
  }

  const ViewCategoryDetails = (): JSX.Element => {
    if (current.value !== "details") {
      return <div />
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <CategoryDetails />
      </Suspense>
    )
  }

  const ViewTimeSlots = (): JSX.Element => {
    if (current.value !== "timeSlots") {
      return <div />
    }
    return (
      <Suspense fallback={<Loader forContent />}>
        <TimeSlots setIsValid={setIsValid} />
      </Suspense>
    )
  }

  return (
    <Card>
      <Box>
        <Stepper
          items={["Category Details & Booking Settings", "Time Slot"]}
          activeStep={CurrentStep(xValue) || 1}
        />
      </Box>
      <Box>
        {ViewCategoryDetails()}
        {ViewTimeSlots()}
      </Box>
      <Box margin="30px 0">
        <Footer
          handleNext={() => handleNext()}
          handleBack={xValue !== "details" ? () => send("BACK") : null}
          handleCancel={() => setOpenDialog(true)}
          label="Next"
        />
      </Box>
      <Dialog
        action={() => handleNext()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="OK"
        title=""
        message="Are you sure you want to cancel?"
      />
    </Card>
  )
}

export default View
