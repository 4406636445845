import React from "react"

// styles
import styles from "./styles"

interface ValueProps {
  val: string | number
}

const Value: React.FC<ValueProps> = ({ val }) => {
  const { valueStyle } = styles()
  return <div className={valueStyle}>{val}</div>
}
export default Value
