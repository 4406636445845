/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from "react"
import { useMachine } from "@xstate/react"

/**
 * Component
 */
import Toast from "components/Toast"
/**
 * Context
 */
import { AppContext } from "App"
/** Machine */
import { AddBookingMachine } from "machines/Dashboard/Facilities/addBooking"
/** Config */
import { CondoUID } from "config/Common/CondoInfo"
/** Service */
import service from "services/Settings/PropertyManagement/Facilities"
import serviceAPI from "services/Dashboard/Facilities"
/** Utils */
import { toUnix, toUnixTimeZone, formatDate, fromUnixTimeZone } from "utils/date"
import { Context } from "./Context"

export interface Props {
  data?: any
  mainView?: string
}

const Provider: React.FC<Props> = ({ children, data, mainView }) => {
  const hasData = data !== undefined
  const { userData, condoInfo } = useContext(AppContext)

  /** State */
  const [loaded, setLoaded] = useState(false)
  const [selectedFacilities, setSelectedFacilities] = useState<Array<{}>>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [submitting] = useState(false)
  const [bookingDate, setBookingDate] = useState(
    hasData
      ? fromUnixTimeZone(data?.booking_date, condoInfo?.timezone)
      : new Date().toString()
  )
  const [categories, setCategories] = useState<Array<{}>>([])
  const [selectedForm, setSelectedForm] = useState<Array<string>>([])
  const [selectedMenu, setSelectedMenu] = useState<Array<string>>([])
  const [categoryMenu, setCategoryMenu] = useState<Array<{}>>([])
  const [selectedFacility, setSelectedFacility] = useState<Array<{}>>([])
  const [returnedBooking, setReturnedBooking] = useState<Record<string, any>>({})
  const [bookingDetails, setBookingDetails] = useState<Record<string, any>>({
    condoUID: CondoUID,
    unitUID: data?.unit?._uid || "",
    bookingDate: data?.booking_date || "",
    forAdmin: data?.for_admin || false,
    remarks: data?.remarks || "",
    bookingByName: data?.booking_by_name || "",
    bookingByUserDetailUID: data?.booking_by_user_detail_uid || "",
    bookingByAccountUID: data?.booking_by_account_uid || ""
  })
  const [booking, setBooking] = useState<Record<string, any>>({})
  const [facilityBookings, setFacilityBookings] = useState<Array<{}>>(
    data === undefined ? [] : [data]
  )

  /** Machine */
  const [current, send] = useMachine(AddBookingMachine)

  /** Methods */
  function goNext() {
    send("NEXT")
  }

  function goBack() {
    send("BACK")
  }

  function selectTimeslot(timeslot: Record<string, any>) {
    const filterSome = facilityBookings?.some(
      (fBooking: any) =>
        fBooking?.facility_time_slot_uid === timeslot?.facility_time_slot_uid &&
        fBooking?.facility_uid === timeslot?.facility_uid &&
        fBooking?.facility_setting_uid === timeslot?.facility_setting_uid
    )

    if (hasData) {
      if (filterSome) {
        return setFacilityBookings([data])
      }
      return setFacilityBookings([timeslot])
    }

    if (filterSome) {
      const filteredData = facilityBookings?.filter(
        (fData: Record<string, any>) =>
          fData?.facility_time_slot_uid !== timeslot?.facility_time_slot_uid ||
          fData?.facility_uid !== timeslot?.facility_uid
      )
      return setFacilityBookings(filteredData)
    }
    return setFacilityBookings([...facilityBookings, timeslot])
  }

  function setMenu(categoriesList: Array<any>) {
    categoriesList !== null &&
      setCategoryMenu(
        categoriesList?.map((category: Record<string, any>) => category)
      )

    categoriesList !== null && setSelectedMenu([categoriesList[0]?._uid])
  }

  function selectMenu(menu: any): void {
    if (!menu) {
      return
    }

    if (selectedMenu.includes(menu) && selectedMenu.length !== 0) {
      setSelectedMenu(selectedMenu?.filter((sMenu: string) => sMenu !== menu))
    } else {
      setSelectedMenu([...selectedMenu, menu])
    }
  }

  function handleFormChange(
    e: any,
    idxValue: number,
    name: string,
    isDeposit: boolean
  ): void {
    const extractedValue = name === "payment_method" ? Number(e) : e

    const objName = isDeposit ? "deposit_for_booking" : "usage_fee_for_booking"
    const updatedFBookings = [...facilityBookings] as any
    updatedFBookings[idxValue][objName][name] = extractedValue
    setFacilityBookings(updatedFBookings)
  }

  function selectFacility(facility: Record<string, any>): void {
    if (selectedFacilities?.includes(facility)) {
      const facilitiesFiltered = selectedFacilities.filter((item: any) => {
        return item?._uid !== facility?._uid
      })

      return setSelectedFacilities(facilitiesFiltered)
    }

    return setSelectedFacilities([...selectedFacilities, facility])
  }

  async function fetchFacilities(categoriesList: Array<any>): Promise<any> {
    return (
      categoriesList !== null &&
      Promise.all(
        categoriesList?.map(async (cList: any) => {
          const response = await serviceAPI.getFacilities({
            "facility_category_uid": cList?._uid
          })

          return {
            ...cList,
            "settings": cList?.facility_settings[0],
            "facilities": response?.data?._data?.sort(
              (a: Record<string, any>, b: Record<string, any>) =>
                a.name.localeCompare(b.name)
            )
          }
        })
      )
    )
  }

  async function fetchCategories(bookingDateVal: number): Promise<Response> {
    try {
      const response = await service.getCategories({
        "booking_date": toUnix(bookingDateVal),
        "effective_setting_only": true,
        "facility_category_uid": data?.facility_category_uid
      })

      const categoryData = response?.data?._data

      fetchFacilities(categoryData).then((res: any) => setCategories(res))

      return categoryData
    } catch (e) {
      return e
    }
  }

  async function handleAddBooking(): Promise<Response | void> {
    const message = `Successfully created the facility booking.`
    const messageError = `Error creating the facility booking.`

    try {
      const response = await serviceAPI.createBooking({
        "facility_bookings": facilityBookings
      })
      if (!response?.data?._data) {
        Toast(messageError, false)
        return response?.data
      }
      Toast(message, true)
      setReturnedBooking(response?.data?._data)
      return response
    } catch (e) {
      Toast(messageError, false)
      return e
    }
  }

  async function handleAdminAddBooking(): Promise<Response | void> {
    const message = `Successfully created the facility booking.`
    const messageError = `Error creating the facility booking.`

    const payloadForAdmin = facilityBookings?.map(
      (fAdminBooking: Record<string, any>) => {
        delete fAdminBooking?.settings
        delete fAdminBooking?.facility
        delete fAdminBooking?.timeValue

        fAdminBooking.deposit_for_booking = null
        fAdminBooking.usage_fee_for_booking = null
        fAdminBooking.booking_by_name = userData?.account_name
        fAdminBooking.booking_by_account_uid = userData?.account_uid

        return fAdminBooking
      }
    )

    try {
      const response = await serviceAPI.createBooking({
        "facility_bookings": payloadForAdmin
      })
      if (!response?.data?._data) {
        Toast(messageError, false)
        return response?.data
      }
      Toast(message, true)
      return response
    } catch (e) {
      Toast(messageError, false)
      return e
    }
  }

  async function handleEditBooking(
    forApprove = false
  ): Promise<Response | void | Record<string, any>> {
    const bd = bookingDetails
    const fb: any = facilityBookings[0]
    const fbUsage = fb?.usage_fee_for_booking
    const fbDeposit = fb?.deposit_for_booking
    const message = `Successfully updated the facility booking.`
    const messageError = `Error updating the facility booking.`

    const payloadTimeslot = {
      "_uid": data?._uid,
      "facility_uid": fb?.facility_uid,
      "facility_setting_uid": fb?.facility_setting_uid,
      "facility_time_slot_uid": fb?.facility_time_slot_uid,
      "booking_date": toUnixTimeZone(
        `${formatDate(bookingDate as string)} 00:00:00`,
        condoInfo?.timezone
      )
    }

    const payloadPayment = {
      "_uid": data?._uid,
      "usage_fee_for_booking": {
        "_uid": data?.usage_fee_for_booking?._uid,
        "description": fbUsage?.description,
        "amt": fbUsage?.amt,
        "tax": fbUsage?.tax,
        "admin_remarks": fbUsage?.admin_remarks,
        "payment_method": fbUsage?.payment_method,
        "e_payment_agent": fbUsage?.e_payment_agent,
        "payment_reference": fbUsage?.payment_reference,
        "auto_approve": fbUsage?.auto_approve
      },
      "deposit_for_booking": {
        "_uid": data?.deposit_for_booking?._uid,
        "description": fbDeposit?.description,
        "amt": fbDeposit?.amt,
        "tax": 0,
        "admin_remarks": fbDeposit?.admin_remarks,
        "payment_method": fbDeposit?.payment_method,
        "e_payment_agent": fbDeposit?.e_payment_agent,
        "payment_reference": fbDeposit?.payment_reference,
        "auto_approve": fbDeposit?.auto_approve
      }
    }

    const payloadRemarks = {
      "_uid": data?._uid,
      "remarks": bd?.remarks
    }

    const responseTimeslot = serviceAPI.updateTimeslot(payloadTimeslot)
    const responsePayment = serviceAPI.updatePayment(payloadPayment)
    const responseRemarks = serviceAPI.updateRemarks(payloadRemarks)

    try {
      const response = await Promise.all([
        responseTimeslot,
        responsePayment,
        responseRemarks
      ]).then((values: Record<string, any>) => {
        return setReturnedBooking(values[2]?.data?._data)
      })
      if (forApprove) {
        return response
      }
      Toast(message, true)
      return response
    } catch (e) {
      Toast(messageError, false)
      return e
    }
  }

  async function handleApproveBooking(): Promise<Response | void> {
    const message = `Successfully approved the facility booking.`
    const messageError = `Error approving the facility booking.`
    try {
      const response = handleEditBooking(true).then(() => {
        return serviceAPI.approveBooking({
          "_uid": data?._uid
        })
      })
      Toast(message, true)
      return response
    } catch (e) {
      Toast(messageError, false)
      return e
    }
  }

  useEffect(() => {
    setLoaded(true)
    fetchCategories(toUnix(bookingDate)).then((res: any) => [setMenu(res)])
  }, [])

  useEffect(() => {
    if (!loaded) {
      fetchCategories(toUnix(bookingDate))
    }
    setFacilityBookings([])
  }, [bookingDate])

  useEffect(() => {
    hasData &&
    fromUnixTimeZone(data?.booking_date, condoInfo?.timezone) === String(bookingDate)
      ? setFacilityBookings([data])
      : setFacilityBookings([])
  }, [bookingDate])

  return (
    <Context.Provider
      value={{
        handleAdminAddBooking,
        handleAddBooking,
        handleEditBooking,
        handleApproveBooking,
        handleFormChange,
        fetchCategories,
        setSelectedFacilities,
        setBookingDate,
        setOpenDialog,
        selectFacility,
        selectTimeslot,
        setSelectedMenu,
        selectMenu,
        setSelectedFacility,
        setBookingDetails,
        setBooking,
        setSelectedForm,
        goNext,
        goBack,
        facilityBookings,
        bookingDetails,
        selectedFacility,
        selectedMenu,
        categories,
        data,
        openDialog,
        submitting,
        hasData,
        mainView,
        booking,
        selectedFacilities,
        xValue: current?.value,
        bookingDate,
        categoryMenu,
        selectedForm,
        returnedBooking
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
