import React from "react"

import SettingsFeedbackProvider from "context/Settings/PropertyManagement/Feedback/main/Provider"
import SettingsFeedbackView from "context/Settings/PropertyManagement/Feedback/main/View"

const SettingsFeedback: React.FC = () => {
  return (
    <SettingsFeedbackProvider>
      <SettingsFeedbackView />
    </SettingsFeedbackProvider>
  )
}

export default SettingsFeedback
