import React, { useEffect, useContext } from "react"

/** Components */
import ViewDashboard from "pages/Dashboard/Dashboards/view"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PermissionMessage from "components/PermissionMessage"
/** Context */
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

const View: React.FC<CtxType> = () => {
  const { permission } = useContext(AppContext)

  const hasPermission = permission && Object.keys(permission).length !== 0

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const MainView = () => {
    if (!hasPermission) {
      return <div />
    }

    if (permission?.dashboard_view) {
      return <ViewDashboard />
    }

    return (
      <WithTable>
        <PermissionMessage />
      </WithTable>
    )
  }

  return <WithHeader>{MainView()}</WithHeader>
}

export default withContext(View)
