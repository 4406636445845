import React from "react"

import SettingsBookkeepingProvider from "context/Settings/PropertyManagement/Bookkeeping/main/Provider"
import SettingsBookkeepingView from "context/Settings/PropertyManagement/Bookkeeping/main/View"

const SettingsBookkeeping: React.FC = () => (
  <SettingsBookkeepingProvider>
    <SettingsBookkeepingView />
  </SettingsBookkeepingProvider>
)

export default SettingsBookkeeping
