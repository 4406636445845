import * as React from "react"

const Payments = (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.0292969C8.98925 0.0292969 7.97919 0.3525 7.17969 1H4.02344L5.16797 7.22852C3.30093 8.91712 0 12.5262 0 17C0 19.002 0.608547 20.5926 1.81055 21.7266L2.09961 22H17.9004L18.1895 21.7266C19.3905 20.5916 20 19.002 20 17C20 12.5305 16.701 8.9208 14.834 7.23047L15.9785 1H12.8203C12.0208 0.3525 11.0107 0.0292969 10 0.0292969ZM10 2.02344C10.6355 2.02344 11.2702 2.25334 11.7422 2.71484L12.0352 3H13.5762L13.0254 6H6.97461L6.42383 3H7.96484L8.25781 2.71484C8.72981 2.25334 9.3645 2.02344 10 2.02344ZM7.3418 8H12.6602C13.7402 8.861 18 12.539 18 17C18 18.283 17.6962 19.269 17.0742 20H10.5234V19.0879C10.8324 19.0489 12.5762 18.7627 12.5762 16.6387C12.5762 13.7137 9.42087 14.2735 9.42188 12.4785C9.42188 11.4505 9.92037 11.418 10.1094 11.418C10.3514 11.418 10.8652 11.6142 10.8652 12.8672H12.5898C12.5898 10.4692 10.9502 10.097 10.6582 10.041V8.71289H9.73828V10.0176C9.42928 10.0566 7.69727 10.3817 7.69727 12.4707C7.69727 15.5187 10.8535 14.6164 10.8535 16.6504C10.8535 17.6684 10.1945 17.6934 10.0605 17.6934C9.93855 17.6934 9.13477 17.7572 9.13477 16.1992H7.41016C7.41016 18.8382 9.32438 19.0489 9.60938 19.0879V20H2.92383C2.30283 19.269 2 18.283 2 17C2 12.552 6.2618 8.863 7.3418 8Z"
      fill="#454B57"
    />
  </svg>
)

export default Payments
