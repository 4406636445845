import { Machine } from "xstate"

export const FacilityFormMachine = Machine({
  id: "FacilityFormMachine",
  initial: "details",
  states: {
    details: {
      on: {
        NEXT: "photos"
      }
    },
    photos: {
      on: {
        NEXT: "status",
        BACK: "details"
      }
    },
    status: {
      on: {
        BACK: "photos"
      }
    }
  }
})
