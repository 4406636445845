import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

/**
 * Styles
 */
import appConfig from "config/appConfig"
import styles from "./styles"
/**
 * Config
 */
/**
 * Interface
 */
interface Props {
  action: Function
}

const IconClose = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.489 2 2 6.489 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM12 4C16.4301 4 20 7.56988 20 12C20 16.4301 16.4301 20 12 20C7.56988 20 4 16.4301 4 12C4 7.56988 7.56988 4 12 4ZM8.70703 7.29297L7.29297 8.70703L10.5859 12L7.29297 15.293L8.70703 16.707L12 13.4141L15.293 16.707L16.707 15.293L13.4141 12L16.707 8.70703L15.293 7.29297L12 10.5859L8.70703 7.29297Z"
      fill="#777E86"
    />
  </svg>
)

const HeaderDots: React.FC<Props> = ({ action }) => {
  const classes = styles()
  const { CONTACT_NUMBER, CONTACT_EMAIL } = appConfig

  return (
    <>
      <div className={classes.modalWrapper}>
        <Button
          onClick={() => action()}
          color="primary"
          size="small"
          disableFocusRipple
          disableElevation
        >
          {IconClose}
        </Button>
        <h2 id="cora-modal_tile">Need additional help?</h2>
        <p id="cora-modal_description">
          <Box display="flex" justifyContent="center">
            <Box paddingRight="6px" component="span">
              call us
            </Box>
            <Box component="span">
              <a
                href={`tel:${CONTACT_NUMBER}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {CONTACT_NUMBER}
              </a>
            </Box>
          </Box>
          <span>
            <a
              href={`mailto:${CONTACT_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {CONTACT_EMAIL}
            </a>
          </span>
        </p>
      </div>
    </>
  )
}

export default HeaderDots
