import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Component */
import Stepper from "components/Stepper"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"

/** Context */
import { CreatePublicDocumentsMachine } from "machines/Dashboard/EstateMatters/createPublicDocuments"

import DocumentInfo from "pages/Dashboard/EstateMatters/ManagementDocuments/components/DocumentForm/components/DocumentInfo"
import DocumentUpload from "pages/Dashboard/EstateMatters/ManagementDocuments/components/DocumentForm/components/DocumentUpload"

/** Context */
import withContext from "./withContext"
import { CtxType } from "./Context"

/** Refs */
export const refSubmit = React.createRef<HTMLDivElement>()

const CreateOwnerView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  handleDocument,
  sendBack,
  files,
  isSubmitting
}) => {
  const [current, send] = useMachine(CreatePublicDocumentsMachine)
  const [isDocumentInfoValid, setIsDocumentInfoValid] = useState(false)
  const xValue = current.value

  const handleNext = () => {
    if (xValue === "documentInfo" && isDocumentInfoValid) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }

    if (xValue === "documentUpload" && Object.entries(files).length !== 0) {
      handleDocument().then(() => sendBack())
    } else {
      refSubmit.current?.click()
    }
  }

  const ViewDocumentInfo = (): JSX.Element => {
    if (xValue !== "documentInfo") {
      return <div />
    }
    return <DocumentInfo setIsValid={setIsDocumentInfoValid} />
  }

  const ViewDocumentUpload = (): JSX.Element => {
    if (xValue !== "documentUpload") {
      return <div />
    }
    return <DocumentUpload />
  }

  const CurrentStep = (step: any) => {
    switch (step) {
      case "documentInfo":
        return 1
      case "documentUpload":
        return 2
      default:
        break
    }
    return step
  }

  return (
    <>
      <Card>
        <Stepper
          items={["document info", "upload document"]}
          activeStep={CurrentStep(xValue || 1)}
          justifyStart
        />
        <Box>
          <div>
            {ViewDocumentInfo()}
            {ViewDocumentUpload()}
          </div>
          <Box margin="0 0 30px 0">
            <Footer
              handleNext={() => handleNext()}
              handleBack={xValue === "documentInfo" ? null : () => send("BACK")}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label="Next"
              submitting={isSubmitting}
            />
          </Box>
        </Box>
        <Dialog
          action={() => sendBack && sendBack()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message="Are you sure you want to cancel?"
        />
      </Card>
    </>
  )
}

export default withContext(CreateOwnerView)
