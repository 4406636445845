import * as React from "react"

const PaymentSchedule: React.FC = () => {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6667 0C17.9854 0 15 2.98533 15 6.66667C15 10.348 17.9854 13.3333 21.6667 13.3333C25.348 13.3333 28.3334 10.348 28.3334 6.66667C28.3334 2.98533 25.348 0 21.6667 0ZM21.6667 2.66667C23.876 2.66667 25.6667 4.45733 25.6667 6.66667C25.6667 8.876 23.876 10.6667 21.6667 10.6667C19.4574 10.6667 17.6667 8.876 17.6667 6.66667C17.6667 4.45733 19.4574 2.66667 21.6667 2.66667ZM22.211 4L19.6797 5.03646V6.41927L20.737 6.01042V9.33333H22.5495V4H22.211ZM7.00004 10.6667C6.61204 10.6667 6.24533 10.7385 5.89067 10.8411L0.333374 12.1432V21.7448L5.66671 20L16.5417 23.8047C16.895 23.9287 17.2623 23.9896 17.6276 23.9896C18.1636 23.9896 18.6971 23.8602 19.1797 23.6016L28.9506 18.3776L27.3802 15.0234L17.8282 19.8411L16.9844 19.5938L17.6771 17.9036C18.2838 16.5503 17.6699 14.9605 16.3099 14.3672L8.48702 10.9609C8.02569 10.7756 7.52671 10.6667 7.00004 10.6667ZM7.00004 13.3333C7.15204 13.3333 7.30885 13.3632 7.46619 13.4219L15.2422 16.8099L15.224 16.8516L15.2058 16.8932L14.5157 18.5833L13.9141 20.0599L6.54692 17.4818L5.69535 17.1849L4.83858 17.4635L3.00004 18.0677V14.2578L6.49744 13.4375L6.56775 13.4219L6.63285 13.4036C6.79285 13.3583 6.91604 13.3333 7.00004 13.3333Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default PaymentSchedule
