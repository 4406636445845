import { SET_USER_DATA, SET_USER_PHOTO } from "../constants"

export const setUserDetails = (userData: any) => ({
  type: SET_USER_DATA,
  userData
})

export const setUserPhoto = (userPhoto: any) => ({
  type: SET_USER_PHOTO,
  userPhoto
})
