import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const Announcement: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("profile")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.profile_edit)}
          onChange={() => handleUpdate("profile_edit", Boolean(!data?.profile_edit))}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Box />
        {/* <Checkbox
          label={null}
          value={Boolean(data?.profile_view_self)}
          onChange={() =>
            handleUpdate("profile_view_self", Boolean(!data?.profile_view_self))}
        /> */}
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Box />
        {/* <Checkbox
          label={null}
          value={Boolean(data?.profile_view_all)}
          onChange={() =>
            handleUpdate("profile_view_all", Boolean(!data?.profile_view_all))
          }
        /> */}
      </Box>
    </Box>
  )
}
export default Announcement
