import * as React from "react"

const Deposit = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 0C7.23912 0 5.00012 2.239 5.00012 5C5.00012 7.761 7.23912 10 10.0001 10C12.7611 10 15.0001 7.761 15.0001 5C15.0001 2.239 12.7611 0 10.0001 0ZM10.0001 2C11.6571 2 13.0001 3.343 13.0001 5C13.0001 6.657 11.6571 8 10.0001 8C8.34312 8 7.00012 6.657 7.00012 5C7.00012 3.343 8.34312 2 10.0001 2ZM10.4083 3L8.50989 3.77734V4.81445L9.30286 4.50781V7H10.6622V3H10.4083ZM0.00012207 6V17C0.00012207 18.103 0.897122 19 2.00012 19H18.0001C19.1031 19 20.0001 18.103 20.0001 17V6H18.0001V17H2.00012V6H0.00012207ZM6.00012 12L10.0001 16L14.0001 12H6.00012Z"
      fill="white"
    />
  </svg>
)

export default Deposit
