import themeOptions from "./themeOptons/reducers"
import user from "./user/reducers"

import roles from "./common/roles/reducers"
import feedback from "./dashboard/feedback/reducers"
import vms from "./dashboard/vms/reducers"
import unitManagement from "./dashboard/unitManagement/reducers"
import bookkeeping from "./dashboard/bookkeeping/reducers"

export default {
  themeOptions,
  user,
  roles,
  feedback,
  vms,
  unitManagement,
  bookkeeping
}
