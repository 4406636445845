import React from "react"

export type CtxType = {
  isSubmitting: boolean
  showReject: boolean
  checkExpired: any
  canCancel: boolean
  activeTable: number
  activeFilter: number
  remarks: string
  settings: any
  activeData: Record<string, any>
  setCanCancel: Function
  setActiveFilter: Function
  setActiveTable: Function
  setActiveData: Function
  setShowReject: Function
  setRemarks: Function
  handleRejectItem: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
