import { SET_TABLE_SCHEDULED_PAYMENT } from "../constants"

const initialState = {
  tablePsData: []
}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_TABLE_SCHEDULED_PAYMENT:
      return {
        tablePsData: action.table
      }
    default:
      return state
  }
}
