import React, { useContext, useState, FC } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Component */
import Dialog from "components/Dialog"

/** Context */
import { Context } from "context/Settings/AccountManagement/Admin/main/Context"
import { CtxMenuContext } from "components/ContextMenu"
import { AppContext } from "App"

/** Styles */
import { IconEdit, IconDelete } from "components/Icons/ContextMenu"
import styles from "./styles"

/** Icon */

interface Props {
  data: any
}

const ContextAdmin: FC<Props> = ({ data }) => {
  const { listItem } = styles()
  const { setActiveData, showFormType, handleDeleteUser } = useContext(Context)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const [openDialog, setOpenDialog] = useState(false)

  function showEditView(items: Record<string, any>) {
    setActiveData && setActiveData(items)
    showFormType && showFormType(true, 2)
  }

  function handleDialog() {
    setOpenDialog(true)
  }

  function handleDelete() {
    handleDeleteUser &&
      handleDeleteUser(data?.user_detail_uid, `fetchSettingsAdmin`).then(
        () => openContext && openContext(false)
      )
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={() =>
            !permission?.account_management ? null : showEditView(data)}
          disabled={!permission?.account_management}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        <ListItem
          className={listItem}
          button
          onClick={() => handleDialog()}
          disabled={!permission?.account_management}
        >
          <i>{IconDelete}</i>
          Delete
        </ListItem>
      </List>
      <Dialog
        action={() => handleDelete()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to delete this User?"
      />
    </>
  )
}

export default ContextAdmin
