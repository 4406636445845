import * as React from "react"

const AccessCards: React.FC = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0V1H2C0.897 1 0 1.897 0 3V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V3C16 1.897 15.103 1 14 1H9V0H7ZM2 3H7V4H9V3H14V18L2 18.002V3ZM8 7C7.46957 7 6.96086 7.21071 6.58579 7.58579C6.21071 7.96086 6 8.46957 6 9C6 9.53043 6.21071 10.0391 6.58579 10.4142C6.96086 10.7893 7.46957 11 8 11C8.53043 11 9.03914 10.7893 9.41421 10.4142C9.78929 10.0391 10 9.53043 10 9C10 8.46957 9.78929 7.96086 9.41421 7.58579C9.03914 7.21071 8.53043 7 8 7ZM8 12C5.815 12 4 12.9088 4 14.2168V15H12V14.2168C12 12.9088 10.185 12 8 12Z"
        fill="white"
      />
    </svg>
  )
}

export default AccessCards
