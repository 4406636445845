import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    borderRadius: "10px",
    minWidth: "31%",
    minHeight: "127px",
    display: "flex",
    alignItems: "center",
    padding: "25px",
    margin: "0 1% 1% 0",
    outline: "none",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      minWidth: "calc(100% / 4 - 1%)"
    }
  },
  iconContainer: {
    width: "71px",
    height: "71px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.body.green,
    marginRight: "25px"
  },
  iconSmallContainer: {
    width: "41px",
    height: "41px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.body.green,
    marginRight: "25px"
  },
  infoContainer: {
    "& > .name": {
      color: theme.palette.body.main,
      textTransform: "capitalize",
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 550,
      "& span": {
        fontSize: "10px",
        paddingRight: "5px"
      }
    },
    "& > .info": {
      color: theme.palette.body.dark,
      fontSize: "14px"
    },
    "& > .viewall": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      textTransform: "uppercase",
      paddingTop: "12px"
    }
  }
  // name: {
  //   fontWeight: 500,
  //   fontSize: "14px",
  //   lineHeight: "20px",
  //   color: theme.palette.primary.main,
  //   flex: 1,
  //   padding: "0 16px",
  //   textAlign: "center",
  //   "&.active": {
  //     color: theme.palette.common.white,
  //   },
  //   "&.default": {
  //     color: theme.palette.body.lightDark,
  //   },
  // },
  // mediumWidth: {
  //   minWidth: "140px",
  // },
  // smallWidth: {
  //   minWidth: "95px",
  // },
  // largeWidth: {
  //   minWidth: "180px",
  // },
}))

export default styles
