import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
import Select from "components/Forms/Select"
/** Component */
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import RefButton from "components/Forms/RefButton"
import BlockUnit from "components/Common/BlockUnit/new"
import Names from "components/Common/Names"
import DatePicker from "components/Forms/DatePicker"
import UploadBox from "components/UploadBox/new"
/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/forms/vehicles/createVehicle/Context"
import withContext from "context/Dashboard/UnitManagement/forms/vehicles/createVehicle/withContext"
/** Config */
import { addVehicleDetails } from "config/Dashboard/UnitManagement/validation"
import { vehicleType as vehicleTypeList } from "config/Dashboard/Applications"
/** Refs */
import { refSubmit } from "context/Dashboard/UnitManagement/forms/vehicles/createVehicle/View"
import { handleBlockUnit, handleResidentName } from "utils/functions"
import styles from "./styles"
/** helpers */

const PersonalDetails: React.FC<CtxType> = ({
  vehicleDetails,
  setVehicleDetails,
  handleDocsUpload,
  removeDoc,
  photos,
  localPhotos,
  attaching
}) => {
  const {
    carLabel,
    vehicleNumber,
    IUnumber,
    vehicleModel,
    blockUnit,
    ownerName,
    activationDate,
    expiryDate,
    vehicleType
  } = vehicleDetails

  const classes = styles(0)

  /** Methods */
  const handleFormChange = (setFieldValue: Function, key: string, e: any): void => {
    if (e.target === undefined) {
      setVehicleDetails &&
        setVehicleDetails({
          ...vehicleDetails,
          [key]: e
        })
      setFieldValue(key, e)
    } else {
      setVehicleDetails &&
        setVehicleDetails({
          ...vehicleDetails,
          [key]: e?.target?.value
        })
      setFieldValue(key, e?.target?.value)
    }
  }

  return (
    <div>
      <Box margin="auto" padding="0 0 40px 0">
        <Formik
          initialValues={{
            blockUnit,
            ownerName,
            carLabel,
            vehicleNumber,
            IUnumber,
            vehicleModel,
            activationDate,
            expiryDate,
            vehicleType
          }}
          onSubmit={(values: any) => {
            return values
          }}
          validationSchema={addVehicleDetails}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.container}>
                  <Box className={classes.section}>
                    <FormWrapper title="Personal Details">
                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <BlockUnit
                            iValue={blockUnit}
                            onChange={(value: string) =>
                              handleBlockUnit(
                                setFieldValue,
                                value,
                                setVehicleDetails,
                                vehicleDetails,
                                "blockUnit",
                                "ownerAccountUID"
                              )}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.blockUnit && Boolean(errors.blockUnit)}
                            helperText={
                              touched.blockUnit && errors.blockUnit?.toString()
                            }
                          />
                        </Box>

                        <Box className={classes.sectionRowRight}>
                          <Names
                            value={ownerName}
                            onChange={(value: Event) =>
                              handleResidentName(
                                setFieldValue,
                                value,
                                setVehicleDetails,
                                vehicleDetails,
                                "ownerName",
                                "ownerAccountUID"
                              )}
                            unitUID={blockUnit}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={touched.ownerName && Boolean(errors.ownerName)}
                            helperText={
                              errors.ownerName &&
                              touched.ownerName &&
                              errors.ownerName.toString()
                            }
                          />
                        </Box>
                      </Box>
                    </FormWrapper>
                  </Box>

                  <Box>
                    <FormWrapper title="Vehicle Details">
                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <Select
                            idValue
                            label="Vehicle Type"
                            selectName="vehicleType"
                            placeholder="Vehicle Type"
                            value={vehicleType}
                            items={vehicleTypeList}
                            error={
                              touched.vehicleType && Boolean(errors.vehicleType)
                            }
                            helperText={
                              errors.vehicleType &&
                              touched.vehicleType &&
                              String(errors.vehicleType)
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleFormChange(setFieldValue, "vehicleType", e)}
                          />
                        </Box>

                        <Box className={classes.sectionRowLeft}>
                          <FormInput
                            name="carLabel"
                            value={carLabel}
                            idValue="carLabel"
                            label="Car Label"
                            placeholder="Car Label"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange(setFieldValue, "carLabel", e)}
                            onBlur={handleBlur}
                            error={touched.carLabel && Boolean(errors.carLabel)}
                            helperText={
                              errors.carLabel && touched.carLabel && errors.carLabel
                            }
                          />
                        </Box>
                        <Box className={classes.sectionRowRight}>
                          <FormInput
                            name="IUnumber"
                            value={IUnumber}
                            idValue="IUnumber"
                            label="IU Number"
                            placeholder="IU Number"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange(setFieldValue, "IUnumber", e)}
                            onBlur={handleBlur}
                            error={touched.IUnumber && Boolean(errors.IUnumber)}
                            helperText={
                              errors.IUnumber && touched.IUnumber && errors.IUnumber
                            }
                          />
                        </Box>
                      </Box>

                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <FormInput
                            name="vehicleNumber"
                            value={vehicleNumber}
                            idValue="vehicleNumber"
                            label="Vehicle Number"
                            placeholder="Vehicle Number"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange(setFieldValue, "vehicleNumber", e)}
                            onBlur={handleBlur}
                            error={
                              touched.vehicleNumber && Boolean(errors.vehicleNumber)
                            }
                            helperText={
                              errors.vehicleNumber &&
                              touched.vehicleNumber &&
                              errors.vehicleNumber
                            }
                          />
                        </Box>
                        <Box className={classes.sectionRowRight}>
                          <FormInput
                            name="vehicleModel"
                            value={vehicleModel}
                            idValue="vehicleModel"
                            label="Vehicle Model"
                            placeholder="Vehicle Model"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange(setFieldValue, "vehicleModel", e)}
                            onBlur={handleBlur}
                            error={
                              touched.vehicleModel && Boolean(errors.vehicleModel)
                            }
                            helperText={
                              errors.vehicleModel &&
                              touched.vehicleModel &&
                              errors.vehicleModel
                            }
                          />
                        </Box>
                      </Box>

                      <Box className={classes.sectionRow}>
                        <Box className={classes.sectionRowLeft}>
                          <DatePicker
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            minDate={activationDate}
                            label="Activation Date"
                            name="activationDate"
                            value={activationDate}
                            handleDateChange={(value: string): void =>
                              handleFormChange(
                                setFieldValue,
                                "activationDate",
                                value
                              )}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={
                              touched.activationDate &&
                              Boolean(errors.activationDate)
                            }
                            helperText={
                              touched.activationDate &&
                              errors.activationDate?.toString()
                            }
                          />
                        </Box>
                        <Box className={classes.sectionRowRight}>
                          <DatePicker
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            minDate={expiryDate}
                            label="Expiry Date"
                            name="expiryDate"
                            value={expiryDate}
                            handleDateChange={(value: string): void =>
                              handleFormChange(setFieldValue, "expiryDate", value)}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.expiryDate && Boolean(errors.expiryDate)}
                            helperText={
                              touched.expiryDate && errors.expiryDate?.toString()
                            }
                          />
                        </Box>
                      </Box>
                    </FormWrapper>
                  </Box>
                  <Box>
                    <FormWrapper title="Photo">
                      <UploadBox
                        hasTitle={false}
                        onDrop={(event: any) => handleDocsUpload(event)}
                        files={photos}
                        previewImages={localPhotos}
                        removePhoto={(event: any) => removeDoc(event)}
                        attaching={attaching}
                        title="Choose file to upload"
                      />
                    </FormWrapper>
                  </Box>
                </Box>
                <RefButton action={handleSubmit} refValue={refSubmit} />
              </form>
            )
          }}
        </Formik>
      </Box>
    </div>
  )
}

export default withContext(PersonalDetails)
