import * as React from "react"

interface DashboardProps {
  color: string
}

const Dashboard: React.FC<DashboardProps> = ({ color }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34783 11.4348H3.08696C1.93496 11.4348 1 10.4998 1 9.34783V3.08696C1 1.93496 1.93496 1 3.08696 1H9.34783C10.4998 1 11.4348 1.93496 11.4348 3.08696V9.34783C11.4348 10.4998 10.4998 11.4348 9.34783 11.4348Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34783 25H3.08696C1.93496 25 1 24.0651 1 22.9131V16.6522C1 15.5002 1.93496 14.5652 3.08696 14.5652H9.34783C10.4998 14.5652 11.4348 15.5002 11.4348 16.6522V22.9131C11.4348 24.0651 10.4998 25 9.34783 25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9123 11.4348H16.6514C15.4994 11.4348 14.5645 10.4998 14.5645 9.34783V3.08696C14.5645 1.93496 15.4994 1 16.6514 1H22.9123C24.0643 1 24.9992 1.93496 24.9992 3.08696V9.34783C24.9992 10.4998 24.0643 11.4348 22.9123 11.4348Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9123 25H16.6514C15.4994 25 14.5645 24.0651 14.5645 22.9131V16.6522C14.5645 15.5002 15.4994 14.5652 16.6514 14.5652H22.9123C24.0643 14.5652 24.9992 15.5002 24.9992 16.6522V22.9131C24.9992 24.0651 24.0643 25 22.9123 25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Dashboard
