import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

/**
 * Settings
 */
function getSettings() {
  return request({
    url: API.LIST_SETTINGS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function createSettings(data: Record<string, any>) {
  return request({
    url: API.CREATE_SETTING,
    method: "POST",
    headers: headers(),
    data
  })
}

function removeSettings(settingsUID: string) {
  return request({
    url: `${API.REMOVE_SETTING}/${settingsUID}`,
    method: "DELETE",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

/**
 * Categories
 */

function getCategories(params?: Record<string, any>) {
  return request({
    url: API.LIST_CATEGORIES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function getCategory(params: Record<string, any>) {
  return request({
    url: API.LIST_FACILITIES,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    }
  })
}

function removeCategory(categoryUID: string) {
  return request({
    url: `${API.REMOVE_CATEGORY}/${categoryUID}`,
    method: "DELETE",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function createCategory(data: Record<string, any>) {
  return request({
    url: API.CREATE_CATEGORY,
    method: "POST",
    headers: headers(),
    data
  })
}

function updateCategory(data: Record<string, any>, categoryUID: string) {
  return request({
    url: `${API.UPDATE_CATEGORY}/${categoryUID}`,
    method: "PUT",
    headers: headers(),
    data
  })
}

/**
 * Facility
 */

function getFacility(facilityUID: string) {
  return request({
    url: `${API.GET_FACILITY}/${facilityUID}`,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function createFacility(data: Record<string, any>) {
  return request({
    url: API.CREATE_FACILITY,
    method: "POST",
    headers: headers(),
    data
  })
}

function updateFacility(data: Record<string, any>, facilityUID: string) {
  return request({
    url: `${API.UPDATE_FACILITY}/${facilityUID}`,
    method: "PUT",
    headers: headers(),
    data
  })
}

function removeFacility(facilityUID: string) {
  return request({
    url: `${API.UPDATE_FACILITY}/${facilityUID}`,
    method: "DELETE",
    headers: headers()
  })
}

function getTimeSlots(params: Record<string, any>) {
  return request({
    url: API.TIME_SLOTS,
    method: "GET",
    headers: headers(),
    params: {
      ...params,
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

const applicationSettings = {
  getCategories,
  getSettings,
  createSettings,
  removeSettings,
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
  getFacility,
  createFacility,
  updateFacility,
  removeFacility,
  getTimeSlots
}

export default applicationSettings
