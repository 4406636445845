import React from "react"

export type CtxType = {
  downloadData: Array<{}>
  downloadDataStats: Array<{}>
  activeTable: number
  focusedInput: string
  startDateState: any
  endDateState: any
  isopenModal: boolean
  showDetails: boolean
  setisopenModal: Function
  handleDateRange: Function
  dateValue: Function
  onFocusChange: Function
  filterActiveTable: Function
  setShowDetails: Function
  setDownloadData: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
