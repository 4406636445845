import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  labelStyle: {
    color: theme.palette.common.white,
    fontWeight: 600
  },
  link: {
    textShadow: "none",
    transition: "transform 0.2s, height 0.3s, color 0.3s, background-color 0.3s",
    lineHeight: "48px !important",
    padding: "0 0 0 20px",
    position: "relative",
    whiteSpace: "nowrap",
    cursor: "pointer",
    flex: 1,
    fontSize: "14px",
    fontWeight: 600,
    "& > a": {
      textDecoration: "none"
    },
    "&:hover": {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      "& .menu-label": {
        color: theme.palette.primary.main
      },
      "& svg > path": {
        fill: theme.palette.primary.main,
        stroke: "transparent"
      }
    },
    "&.active": {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      textShadow: "none",
      "& .menu-label": {
        color: theme.palette.primary.main
      }
    }
  }
}))

export default styles
