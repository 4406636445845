import { Machine } from "xstate"

export const ClosePaymentMachine = Machine({
  id: "closepayment",
  initial: "payerinfo",
  states: {
    payerinfo: {
      on: {
        NEXT: "paymentdetails"
      }
    },
    paymentdetails: {
      on: {
        NEXT: "acknowledgement",
        BACK: "payerinfo"
      }
    },
    acknowledgement: {
      on: {
        BACK: "paymentdetails"
      }
    }
  }
})
