import React from "react"
import Box from "@material-ui/core/Box"
import ReactHtmlParser from "react-html-parser"
import Image from "material-ui-image"

/** Styles */
import styles from "./styles"

interface MobilePreviewProps {
  bgImage: string
  header?: string
  title: any
  content: any
  isAttaching?: boolean
  bannerBottom?: boolean
}

const MobilePreview: React.FC<MobilePreviewProps> = ({
  bgImage,
  title,
  content,
  isAttaching,
  header,
  bannerBottom = false
}) => {
  const {
    container,
    previewContent,
    previewHeader,
    previewImage,
    previewTitle,
    wrapper
  } = styles()

  function ViewHeader() {
    if (header === "") {
      return null
    }

    return <Box className={previewHeader}>{header}</Box>
  }

  function ViewBannerBottom() {
    if (bannerBottom) {
      return (
        <>
          {content !== "" && (
            <Box className={previewContent}>{ReactHtmlParser(content)}</Box>
          )}
          <Box>
            <Box>
              {isAttaching && <Box padding="10px 20px">Attaching Image.....</Box>}
            </Box>
            {bgImage && !isAttaching && (
              <Box className={previewImage}>
                <Image
                  src={bgImage}
                  aspectRatio={1 / 1}
                  imageStyle={{ width: "100%", height: "100%" }}
                />
              </Box>
            )}
          </Box>
        </>
      )
    }

    return (
      <>
        <Box>
          <Box>
            {isAttaching && <Box padding="10px 20px">Attaching Image.....</Box>}
          </Box>
          {bgImage && !isAttaching && (
            <Box className={previewImage}>
              <Image
                src={bgImage}
                aspectRatio={1 / 1}
                imageStyle={{ width: "100%", height: "100%" }}
              />
            </Box>
          )}
        </Box>
        {content !== "" && (
          <Box className={previewContent}>{ReactHtmlParser(content)}</Box>
        )}
      </>
    )
  }

  return (
    <Box className={container}>
      <Box className={wrapper}>
        <ViewHeader />
        <Box className={previewTitle}>{title}</Box>
        <Box>{ViewBannerBottom()}</Box>
      </Box>
    </Box>
  )
}
export default MobilePreview
