import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
/**
 * Hooks
 */
import {
  IconAttendance,
  IconOffice,
  IconSecurity,
  IconMaintenance,
  IconCleaners,
  IconLandscape,
  IconOthers
} from "components/Icons/Dashboard"
import { TotalAttendanceHook } from "./hooks"
/**
 * Icons
 */
/**
 * Styles
 */
import styles from "./styles"

const VisitorManagement = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`
  const {
    totalAttendance,
    totalSecurity,
    totalOffice,
    totalCleaning,
    totalLandscape,
    totalMaintenance,
    totalOthers,
    isValidating
  } = TotalAttendanceHook(selectedDate)

  const options = [
    {
      name: "Office",
      total: totalOffice,
      icon: IconOffice
    },
    {
      name: "Security",
      total: totalSecurity,
      icon: IconSecurity
    },
    {
      name: "Maintenance",
      total: totalMaintenance,
      icon: IconMaintenance
    },
    {
      name: "Cleaners",
      total: totalCleaning,
      icon: IconCleaners
    },
    {
      name: "Landscape",
      total: totalLandscape,
      icon: IconLandscape
    },
    {
      name: "Others",
      total: totalOthers,
      icon: IconOthers
    }
  ]

  function ViewContent(): JSX.Element {
    return (
      <Box>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade in timeout={1000}>
            <Box display="flex" justifyContent="space-between">
              <Box className={classes.content}>
                <Box className={classes.title}>Total Employees</Box>
                <Box className={classes.subtitleStyle}>{currentReport}</Box>
                <Box className={classes.totalStyle}>{totalAttendance}</Box>
              </Box>

              <Box flex="1" className={classes.listContainer}>
                {options.map((item) => (
                  <div className={classes.listItem} key={item.name}>
                    <div className={classes.body}>
                      <div>{item.icon}</div>
                      <div className="feedbackListName">{item.name}</div>
                    </div>
                    <div className={classes.body}>{item.total}</div>
                  </div>
                ))}
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    )
  }

  return (
    <Card>
      <Box className={classes.header}>
        <CardTitle title="Attendance" icon={IconAttendance} />
      </Box>

      <ViewContent />
    </Card>
  )
}

export default VisitorManagement
