import React, { useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Component */
import {
  IconView,
  IconCancel,
  IconEdit,
  IconApprove,
  IconReject,
  IconNoShow
} from "components/Icons/ContextMenu"
// import Dialog from "components/Dialog"
/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { Context as RootContext } from "context/Dashboard/Facilites/main/Context"
import { AppContext } from "App"
// import message from "config/Text/Dashboard/Applications"

/** Styles */
import styles from "./styles"

interface ContextProps {
  data: Record<string, any>
}

const ContextMenu: React.FC<ContextProps> = ({ data }) => {
  const { listItem } = styles()

  // const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const {
    showBookingDetails,
    showEditForm,
    showApproveForm,
    showUpdateStatusForm
  } = useContext(RootContext)
  // const {setShowReject, setActiveData} = useContext(RootContext)

  const status = data?.status

  /** Methods */
  function handleShowDetails(items: Record<string, any>) {
    openContext && openContext(false)
    showBookingDetails && showBookingDetails(items)
  }

  function handleShowEdit(items: Record<string, any>) {
    openContext && openContext(false)
    showEditForm && showEditForm(items)
  }

  function handleShowApprove(items: Record<string, any>) {
    openContext && openContext(false)
    showApproveForm && showApproveForm(items)
  }

  function handleShowUpdateStatus(items: Record<string, any>) {
    openContext && openContext(false)
    showUpdateStatusForm && showUpdateStatusForm(items)
  }

  // function handleDialog() {
  //   setOpenDialog(true)
  //   openContext && openContext(false)
  // }

  // function handleCancel() {
  //   openContext && openContext(false)
  //   handleCancelItem &&
  //     handleCancelItem(
  //       data?._uid,
  //       `fetchApplicationsMovingInOut-${activeFilter}`,
  //     )
  // }

  // function showMessage() {
  //   if (checkExpired(data?.request_data?.move_in_out?.eta_ms)) {
  //     return message?.expiredBookingCancel
  //   }

  //   if (canCancel) {
  //     return message?.confirmCancel
  //   }

  //   return message?.cancelBookingNotAllowed
  // }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!permission?.facility_edit}
          onClick={() => handleShowEdit(data)}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        {status !== 2 && (
          <ListItem
            className={listItem}
            button
            disabled={!permission?.facility_edit}
            onClick={() => handleShowApprove(data)}
          >
            <i>{IconApprove}</i>
            Approve
          </ListItem>
        )}

        {status !== 3 && status !== 4 && (
          <ListItem
            className={listItem}
            button
            disabled={!permission?.facility_edit}
            onClick={() => handleShowUpdateStatus({ ...data, type: "reject" })}
          >
            <i>{IconReject}</i>
            Reject
          </ListItem>
        )}

        {status !== 3 && status !== 4 && (
          <ListItem
            className={listItem}
            button
            disabled={!permission?.facility_remove}
            onClick={() => handleShowUpdateStatus({ ...data, type: "cancel" })}
          >
            <i>{IconCancel}</i>
            Cancel
          </ListItem>
        )}
        {status !== 3 && (
          <ListItem
            className={listItem}
            button
            disabled={!permission?.facility_remove}
            onClick={() => handleShowUpdateStatus({ ...data, type: "noshow" })}
          >
            <i>{IconNoShow}</i>
            No Show
          </ListItem>
        )}
      </List>

      {/* <Dialog
        action={() => handleCancel()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message={showMessage()}
      /> */}
    </>
  )
}

export default ContextMenu
