import React from "react"
import Box from "@material-ui/core/Box"
import Image from "material-ui-image"

/** Components */
import Header from "components/Header"
import Drawer from "components/Drawer"
import Value from "components/Forms/Value"
import Label from "components/Forms/Label"

import { fromUnix, fromUnixDateTime } from "utils/date"

/** Config */
import { RolesCategory } from "config/Common/Roles"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
}

const StaffDetails: React.FC<DetailsProps> = ({ openState, setopenState, data }) => {
  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header title="Time-In Staff" subtitle="Attendance" subtitleAbove />
      <Box display="flex" flexDirection="column" width="100%">
        <Box width="100%" padding="30px" borderBottom="1px solid #F2F2F2">
          <Box paddingBottom="25px">
            <Label label="Date" />
            <Value val={fromUnix(data?.attendance_date_time)} />
          </Box>
          <Box display="flex">
            <Box>
              <Label label="Category" />
              <Value val={RolesCategory[data?.user_role_category]} />
            </Box>
            <Box paddingLeft="50px">
              <Label label="Position" />
              <Value val={data?.user_role_name} />
            </Box>
          </Box>
        </Box>

        <Box width="100%" padding="30px" borderBottom="1px solid #F2F2F2">
          <Box paddingBottom="25px">
            <Label label="Remarks" />
            <Value val={data?.remarks === "" ? "N/A" : data?.remarks} />
          </Box>
          <Box paddingBottom="25px">
            <Label label="Time In" />
            <Value val={fromUnixDateTime(data?.attendance_date_time)} />
          </Box>
          {data?.photo?.url !== "" && (
            <Box>
              <Label label="Photo" />
              <Box maxHeight="194px" overflow="hidden">
                <Image src={data?.photo?.url} aspectRatio={1 / 1} />
              </Box>
            </Box>
          )}
        </Box>

        {data?.time_out?._uid !== "" && (
          <Box width="100%" padding="30px" borderBottom="1px solid #F2F2F2">
            <Box paddingBottom="25px">
              <Label label="Remarks" />
              <Value val={data?.time_out?.remarks === "" ? "N/A" : data?.remarks} />
            </Box>
            <Box paddingBottom="25px">
              <Label label="Time Out" />
              <Value val={fromUnixDateTime(data?.time_out?.attendance_date_time)} />
            </Box>
            {data?.time_out?.photo?.url !== "" && (
              <Box>
                <Label label="Photo" />
                <Box maxHeight="194px" overflow="hidden">
                  <Image src={data?.time_out?.photo?.url} aspectRatio={1 / 1} />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  )
}
export default StaffDetails
