import React from "react"

/** Components */
import StatusBlock from "components/Table/components/StatusBlock"
import ContextMenu from "components/ContextMenu"

/** Utils */
import dateHelper from "utils/date"
import { checkValue, checkDate } from "utils/helpers"

/** Config */
import { status, statusColor, vehicleType } from "config/Dashboard/Applications"
import ContextApplications from "./ContextMenu"

/** Config */

const TableHeaderColumns = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: any) => {
          return (
            <ContextMenu>
              <ContextApplications data={row?._original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Status",
        id: "status",
        sortable: false,
        filterable: false,
        width: 150,
        accessor: (c: any) => checkValue(c.status, status[c.status]),
        Cell: ({ original }: any) =>
          checkValue(
            original.status,
            <StatusBlock
              status={status[original.status]}
              color={statusColor[original.status]}
            />
          )
      },
      {
        Header: "Reference No.",
        id: "referenceNumber",
        width: 200,
        accessor: (c: any) => checkValue(c?.reference_no),
        Cell: ({ original }: any) => checkValue(original?.reference_no)
      },
      {
        Header: "Date Received",
        id: "dateReceived",
        width: 150,
        accessor: (c: any) =>
          checkDate(c.application_date, dateHelper.fromUnix(c.application_date)),
        Cell: ({ original }: any) =>
          checkDate(
            original.application_date,
            dateHelper.fromUnix(original.application_date)
          )
      },
      {
        Header: "Block/Unit No.",
        id: "blockUnitNo",
        accessor: (c: any) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Name",
        id: "applicantName",
        accessor: (c: any) => checkValue(c?.applicant_name),
        Cell: ({ original }: any) => checkValue(original?.applicant_name),
        width: 250
      },
      {
        Header: "Owner Name",
        id: "ownerName",
        accessor: (c: any) => checkValue(c?.request_data?.vehicle?.owner_name),
        Cell: ({ original }: any) =>
          checkValue(original?.request_data?.vehicle?.owner_name),
        width: 250
      },
      {
        Header: "Start Date",
        id: "startDate",
        width: 150,
        accessor: (c: any) =>
          checkDate(c?.start_date, dateHelper.fromUnix(c.start_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.start_date, dateHelper.fromUnix(original.start_date))
      },
      {
        Header: "End Date",
        id: "endDate",
        width: 150,
        accessor: (c: any) =>
          checkDate(c?.end_date, dateHelper.fromUnix(c.end_date)),
        Cell: ({ original }: any) =>
          checkDate(original?.end_date, dateHelper.fromUnix(original.end_date))
      },
      {
        Header: "Vehicle Type",
        id: "vehicleType",
        width: 150,
        accessor: (c: any) => {
          return c?.request_data?.vehicle?.vehicle_type === 0 ||
            c?.request_data?.vehicle?.vehicle_type === undefined
            ? "N/A"
            : vehicleType[c?.request_data?.vehicle?.vehicle_type]
        },
        Cell: ({ original }: any) => {
          return original?.request_data?.vehicle?.vehicle_type === 0 ||
            original?.request_data?.vehicle?.vehicle_type === undefined
            ? "N/A"
            : vehicleType[original?.request_data?.vehicle?.vehicle_type]
        }
      },
      {
        Header: "Vehicle Model",
        id: "vehicleModel",
        width: 150,
        accessor: (c: any) => checkValue(c?.request_data?.vehicle?.vehicle_model),
        Cell: ({ original }: any) =>
          checkValue(original?.request_data?.vehicle?.vehicle_model)
      },
      {
        Header: "Vehicle #",
        id: "vehicleNumber",
        width: 150,
        accessor: (c: any) => checkValue(c?.request_data?.vehicle?.vehicle_no),
        Cell: ({ original }: any) =>
          checkValue(original?.request_data?.vehicle?.vehicle_no)
      },
      {
        Header: "Vehicle IU#",
        id: "vehicleIU",
        width: 150,
        accessor: (c: any) => checkValue(c?.request_data?.vehicle?.iu_no),
        Cell: ({ original }: any) =>
          checkValue(original?.request_data?.vehicle?.iu_no)
      },
      {
        Header: "Remarks",
        id: "remarks",
        width: 300,
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => checkValue(original?.remarks)
      },
      {
        Header: "Reject Remarks",
        id: "remarks",
        width: 300,
        accessor: (c: any) => checkValue(c?.remarks),
        Cell: ({ original }: any) => {
          const statuses = original?.status_update_time?.status_changes
          let rejRemarks = ""
          if (statuses && statuses.length > 0) {
            statuses.forEach((ele: any) => {
              if (ele.status_change_to === 3 && original?.status === 3) {
                rejRemarks = ele.rejected_remarks
              }
            })
          }
          return checkValue(rejRemarks)
        }
      }
    ]
  }
]

export default TableHeaderColumns
