import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

import { Formik } from "formik"

/** Component */
import FormInput from "components/Forms/FormInput"
import Footer from "components/Forms/Footer/new"
import Editor from "components/Editor"
import Label from "components/Forms/Label"
import Loader from "components/Loader"
import UploadBox from "components/UploadBox/new"

/** Contxt */
import { Context } from "context/Settings/Others/CondoInfo/main/Context"
import { AppContext } from "App"

/** Validation */
import { updateCondoInfo as updateCondoInfoValidation } from "config/Settings/Others/CondoInfo/validation"

const Form = () => {
  const {
    condoInfo,
    setCondoInfo,
    updateCondoInfo,
    submitting,
    isLoading,
    handleImageUpload,
    removePhoto,
    attaching,
    photos
  } = useContext(Context)
  const { permission } = useContext(AppContext)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)

    if (e?.target === undefined) {
      setCondoInfo &&
        setCondoInfo((data: any) => ({
          ...data,
          [name]: e
        }))
    } else {
      setCondoInfo &&
        setCondoInfo((data: any) => ({
          ...data,
          [name]: e?.target?.value
        }))
    }
  }

  return (
    <Box>
      <Box paddingBottom="100px">
        <Formik
          initialValues={{
            adminEmail: condoInfo?.email,
            adminContact: condoInfo?.adminContact,
            sosNumber: condoInfo?.sosNumber,
            description: condoInfo?.description
          }}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={updateCondoInfoValidation}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldTouched
          }): JSX.Element => {
            return (
              <form>
                <Box width="60%" margin="auto">
                  <Box
                    borderBottom="1px solid #F2F2F2"
                    marginBottom="30px"
                    paddingBottom="30px"
                  >
                    <Box marginBottom="20px">
                      <FormInput
                        name="adminEmail"
                        value={condoInfo?.email}
                        idValue="adminEmail"
                        label="Admin Email"
                        placeholder="admin@email.com"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange("email", e, handleChange, setFieldTouched)}
                        onBlur={handleBlur}
                        error={touched.adminEmail && Boolean(errors.adminEmail)}
                        helperText={
                          errors.adminEmail &&
                          touched.adminEmail &&
                          errors.adminEmail
                        }
                      />
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Box flex="1" maxWidth="49%">
                        <FormInput
                          name="adminContact"
                          value={condoInfo?.adminContact}
                          idValue="adminContact"
                          label="Admin Contact Number"
                          placeholder="123-456-7890"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(
                              "adminContact",
                              e,
                              handleChange,
                              setFieldTouched
                            )}
                          onBlur={handleBlur}
                          error={
                            touched.adminContact && Boolean(errors.adminContact)
                          }
                          helperText={
                            errors.adminContact &&
                            touched.adminContact &&
                            errors.adminContact
                          }
                        />
                      </Box>

                      <Box flex="1" maxWidth="49%">
                        <FormInput
                          name="sosPhoneNumber"
                          value={condoInfo?.sosNumber}
                          idValue="sosPhoneNumber"
                          label="SOS PHONE Number"
                          placeholder="123-456-7890"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(
                              "sosNumber",
                              e,
                              handleChange,
                              setFieldTouched
                            )}
                          onBlur={handleBlur}
                          error={touched.sosNumber && Boolean(errors.sosNumber)}
                          helperText={
                            errors.sosNumber && touched.sosNumber && errors.sosNumber
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    padding="0 0 30px 0"
                    marginBottom="30px"
                    borderBottom="1px solid #F2F2F2"
                  >
                    <Label label="Banner Image" />
                    <UploadBox
                      hasTitle={false}
                      title="Choose photos to upload"
                      files={photos && photos}
                      onDrop={(event: React.ChangeEvent): void =>
                        handleImageUpload && handleImageUpload(event)}
                      removePhoto={(event: React.ChangeEvent): void =>
                        removePhoto && removePhoto(event)}
                      attaching={attaching}
                    />
                  </Box>

                  <Box>
                    <Label label="About the Condo" />
                    {!isLoading ? (
                      <Editor
                        error={touched.description && condoInfo?.terms === "<p></p>"}
                        onBlur={handleBlur}
                        content={condoInfo?.description}
                        setContent={(content: any) =>
                          handleFormChange(
                            "description",
                            content,
                            handleChange,
                            setFieldTouched
                          )}
                      />
                    ) : (
                      <Loader dark />
                    )}
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Box>
      <Box margin="0 0 30px">
        <Footer
          handleNext={() => updateCondoInfo && updateCondoInfo()}
          handleBack={null}
          handleCancel={null}
          label={submitting ? "Submitting..." : "SAVE"}
          disabled={!permission?.manage_condo_settings}
        />
      </Box>
    </Box>
  )
}

export default Form
