import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import FilterWithInfo from "components/FilterButtons/FilterWithInfo"
import Loader from "components/Loader"
/**
 * Context
 */
import { Context } from "context/Dashboard/Reports/main/Context"
import { AppContext } from "App"
/**
 * Hooks
 */
import {
  IconPayments,
  IconPaymentsReport,
  IconDeposit
} from "components/Icons/Dashboard"
import {
  TotalAmountHook,
  TotalAmountDepositHook,
  TotalAmountPaymentHook
} from "./hooks"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Icon
 */

const TotalAmountCollected: React.FC = () => {
  const classes = styles()
  const { selectedDate } = useContext(Context)
  const { condoInfo } = useContext(AppContext)
  const currency = condoInfo?.currency

  const { totalAmount, isValidating } = TotalAmountHook(selectedDate)
  const { totalDeposit } = TotalAmountDepositHook(selectedDate)
  const { totalPayment } = TotalAmountPaymentHook(selectedDate)

  const currentReport = `${selectedDate?.from} - ${selectedDate?.to}`

  function ViewHeader(): JSX.Element {
    return (
      <Box className={classes.header}>
        <CardTitle
          title="Total Amount Collected"
          subtitle={currentReport}
          icon={IconPayments}
        />
      </Box>
    )
  }

  function ViewTotal(): JSX.Element {
    return (
      <Box className={classes.total}>
        {isValidating ? (
          <Loader dark />
        ) : (
          <Fade timeout={1000} in>
            <Box>
              <Box className={classes.totalStyle}>
                <span>{currency}</span>
                {totalAmount}
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    )
  }

  function ViewBreakdown(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    return (
      <Box display="flex" flexDirection="column" height="50%">
        <Box
          display="flex"
          paddingLeft="80px"
          flex="1"
          borderBottom="1px solid #F2F2F2"
          height="50%"
        >
          <FilterWithInfo
            currency={currency}
            name={totalDeposit}
            info="Deposits"
            color="#007B83"
            icon={IconDeposit}
            type="deposits"
            action={() => null}
            size="small"
            center
            style={{ minHeight: "auto", padding: 0, margin: 0 }}
          />
        </Box>
        <Box display="flex" paddingLeft="80px" flex="1" height="50%">
          <FilterWithInfo
            currency={currency}
            name={totalPayment}
            info="Payments"
            color="#D8B469"
            icon={IconPaymentsReport}
            type="payments"
            action={() => null}
            size="small"
            center
            style={{ minHeight: "auto", padding: 0, margin: 0 }}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Card className={classes.container}>
      <Box className={classes.container}>
        <ViewHeader />
        <ViewTotal />
        <ViewBreakdown />
      </Box>
    </Card>
  )
}

export default TotalAmountCollected
