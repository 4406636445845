import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"

/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import LinkButton from "components/LinkButton"
import PageHeaderTitle from "components/PageHeaderTitle"
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import CategoryFilters from "pages/Dashboard/Facilities/components/CategoryFilters"
import FacilitiesCards from "pages/Dashboard/Facilities/components/FacilitiesCards"
import Details from "pages/Dashboard/Facilities/components/Details"
import AddBooking from "pages/Dashboard/Facilities/components/AddBooking"
import UpdateStatusBooking from "pages/Dashboard/Facilities/components/UpdateStatusBooking"
import Table from "pages/Dashboard/Facilities/tables"
import Loader from "components/Loader"
import PermissionMessage from "components/PermissionMessage"
/** Context */
import { AppContext } from "App"
/** Machines */

/** Config */
import { text as t } from "config/Text/Dashboard/Facilities/main"
import { status } from "config/Dashboard/Facilities"
import { CtxType } from "./Context"
import withContext from "./withContext"

const Facilities: React.FC<CtxType> = ({
  setActiveFilter,
  setActiveMenu,
  setShowUpdateStatus,
  showAddBooking,
  showUpdateStatus,
  showTable,
  showDetails,
  setShowDetails,
  activeMenu,
  activeFilter,
  categories,
  facilities,
  activeData,
  isLoading,
  isFacilityLoading,
  xValue
}) => {
  const { permission } = useContext(AppContext)

  const ViewTitle = (): JSX.Element => {
    if (xValue === "tableView") {
      return <PageHeaderTitle title={t.TITLE} breadcrumbs={t.BREADCRUMB} />
    }

    if (xValue === "addBooking") {
      return (
        <PageHeaderTitle
          title={t.TITLE_ADD}
          breadcrumbs={t.BREADCRUMB_ADD}
          backAction={() => showTable()}
        />
      )
    }

    if (xValue === "editBooking") {
      return (
        <PageHeaderTitle
          title={t.TITLE_EDIT}
          breadcrumbs={t.BREADCRUMB_EDIT}
          backAction={() => showTable()}
        />
      )
    }

    if (xValue === "approveBooking") {
      return (
        <PageHeaderTitle
          title={t.TITLE_APPROVE}
          breadcrumbs={t.BREADCRUMB_APPROVE}
          backAction={() => showTable()}
        />
      )
    }

    if (xValue === "details") {
      return (
        <PageHeaderTitle
          title={t.TITLE_DETAILS}
          breadcrumbs={t.BREADCRUMB_DETAILS}
          backAction={() => showTable()}
        />
      )
    }

    return <div />
  }

  const AddBookingView = (): JSX.Element => {
    if (xValue !== "addBooking") {
      return <div />
    }

    return <AddBooking mainView={xValue} />
  }

  const EditBookingView = (): JSX.Element => {
    if (xValue !== "editBooking") {
      return <div />
    }

    return <AddBooking data={activeData} mainView={xValue} />
  }

  const ApproveBookingView = (): JSX.Element => {
    if (xValue !== "approveBooking") {
      return <div />
    }

    return <AddBooking data={activeData} mainView={xValue} />
  }

  const UpdateStatusBookingView = (): JSX.Element => {
    return (
      <UpdateStatusBooking
        openState={showUpdateStatus}
        setOpenState={setShowUpdateStatus}
        data={activeData}
      />
    )
  }

  const ViewDetails = (): JSX.Element => {
    return (
      <Details
        openState={showDetails}
        setopenState={(value: boolean) => {
          setShowDetails(value)
        }}
        data={activeData}
      />
    )
  }

  function ViewFilterMenu(): JSX.Element {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <Box display="flex" margin="25px 0 25px" height="108">
        <CategoryFilters
          menu={categories}
          action={(menu: Record<string, any>) => setActiveMenu(menu)}
          activeMenu={activeMenu}
        />
      </Box>
    )
  }

  function ViewFacilitiesCards(): JSX.Element {
    if (xValue !== "tableView") {
      return <div />
    }

    if (facilities === undefined) {
      return <div />
    }

    if (isFacilityLoading) {
      return <Loader dark />
    }

    return (
      <Fade in={!isFacilityLoading} timeout={1000}>
        <Box height={!isFacilityLoading ? "155px" : "0px"}>
          <FacilitiesCards facilities={facilities} />
        </Box>
      </Fade>
    )
  }

  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView" || isLoading) {
      return <div />
    }

    return (
      <WithTable>
        <TableHeader
          addActionLabel="Add New"
          addAction={() => showAddBooking()}
          title={activeMenu?.category_name}
          titleIcon={null}
          actionDisable={permission?.facility_create}
          // data={downloadData}
          // fileName={csvFileName}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={activeFilter || 0}
            handleChange={(e: any) => setActiveFilter(e)}
            options={status}
          />
        </Box>
        <Table />
      </WithTable>
    )
  }

  if (permission?.facility_view) {
    return (
      <WithHeader>
        <Box display="flex" justifyContent="space-between">
          <ViewTitle />
          <Box>
            <LinkButton
              name={t.TITLE_SETTINGS}
              url={permission?.manage_condo_settings ? t.URL_SETTINGS : "#"}
            />
          </Box>
        </Box>
        {ViewFilterMenu()}
        {ViewFacilitiesCards()}
        {ViewTable()}
        {AddBookingView()}
        {EditBookingView()}
        {UpdateStatusBookingView()}
        {ApproveBookingView()}
        {ViewDetails()}
      </WithHeader>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(Facilities)
