import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  avatar: {
    maxWidth: "150px",
    width: "100%",
    borderRadius: "100%",
    minHeight: "150px",
    maxHeight: "150px"
  },
  notice: {
    fontSize: "12px",
    lineHeight: "18px",
    margin: "14px 0 0"
  },
  contact: {
    fontSize: "12px",
    lineHeight: "18px",
    margin: "0 0 0 12px",
    color: "#777E86"
  },
  root: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiTextField-root": {
      flex: 1
    }
  },
  formControl: {
    width: "100%"
  },
  formControlButtons: {
    maxWidth: "400px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 20px"
  },
  formControlButton: {
    width: "49%"
  }
}))

export default styles
