import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
/**
 * Component
 */
import CardTitle from "components/CardTitle"
import Loader from "components/Loader"
/**
 * Utils
 */
import { dateToday } from "utils/date"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Hooks
 */
import { TotalVehiclesHook } from "./hooks"

const RegisteredItem = () => {
  const classes = styles()

  const { totalVehicles, isValidating } = TotalVehiclesHook()

  function ViewTotal(): JSX.Element {
    if (isValidating) {
      return <Loader dark />
    }
    return (
      <Fade in timeout={1000}>
        <Box className={classes.totalStyle}>{totalVehicles}</Box>
      </Fade>
    )
  }

  return (
    <Card className={classes.container}>
      <Box className={classes.header}>
        <Box flex="1">
          <CardTitle title="Registered Vehicles" />
          <Box className={classes.bodyStyle}>{`As of ${dateToday()}`}</Box>
        </Box>

        <ViewTotal />
      </Box>
    </Card>
  )
}

export default RegisteredItem
