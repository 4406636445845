import useSWR from "swr"
import UnitManagementAPI from "services/Dashboard/UnitManagement"

interface RegisteredUsersHookProps {
  dataID: any
}

function RegisteredUsersHook(dataID: string): Record<string, any> {
  const fetchUsersPerUnit = async () =>
    UnitManagementAPI.getResidents({
      "unit_uid": dataID,
      "is_registered": true,
      "is_resident": true
    })

  const { data, isValidating, error } = useSWR(
    `fetchUsersPerUnit-${dataID}`,
    fetchUsersPerUnit,
    {
      revalidateOnFocus: false
    }
  )

  return {
    tableData: data?.data?._data,
    isValidating,
    error
  }
}

export default RegisteredUsersHook
