import React from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"

import FormLabel from "components/Forms/Label"

interface FormInputProps {
  label?: string
  placeholder?: string
  handleOnChange: Function
  name: string
  idValue?: string
  multiline?: boolean
  rows?: number
  onBlur?: Function
  error?: boolean | undefined
  helperText?: any
  type?: string
  value?: string | number | null | boolean
  labelShrink?: boolean
  adorment?: string
  userAdorment?: string
  charLength?: number
  disabled?: boolean
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  handleOnChange,
  name,
  idValue,
  multiline,
  rows,
  onBlur,
  error,
  helperText,
  type,
  value,
  labelShrink,
  adorment,
  userAdorment,
  disabled = false,
  charLength = 1000
}) => {
  return (
    <Box>
      {labelShrink ? (
        <TextField
          disabled={disabled}
          label={label}
          name={name}
          id={idValue}
          placeholder={placeholder}
          fullWidth
          variant="outlined"
          onChange={(e: any) => handleOnChange(e)}
          multiline={multiline}
          rows={rows}
          onBlur={(e) => onBlur && onBlur(e)}
          error={error}
          helperText={helperText}
          type={type}
          value={value}
          inputProps={{
            endAdornment: adorment !== "" && (
              <InputAdornment position="end">
                <small style={{ color: "rgba(100, 100, 100, 0.6)" }}>
                  {adorment}
                </small>
              </InputAdornment>
            )
          }}
          InputProps={{
            endAdornment: userAdorment && (
              <InputAdornment position="end" variant="filled">
                <div
                  style={{
                    backgroundColor: "rgb(241, 241, 241)",
                    position: "absolute",
                    right: 0,
                    width: 100,
                    height: 54,
                    borderBottomRightRadius: 5,
                    display: "flex",
                    borderTopRightRadius: 5,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {/* <small style={{ color: "rgba(100, 100, 100, 0.6)" }}> */}
                  {userAdorment}
                  {/* </small> */}
                </div>
              </InputAdornment>
            )
          }}
        />
      ) : (
        <>
          {label && <FormLabel label={label} />}
          <TextField
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            fullWidth
            variant="outlined"
            multiline={multiline}
            rows={rows}
            error={error}
            helperText={helperText}
            type={type}
            inputProps={{
              type,
              maxLength: charLength,
              min: 0,
              pattern: "d+"
            }}
            InputProps={{
              endAdornment: userAdorment && (
                <InputAdornment position="end" variant="filled">
                  <div
                    style={{
                      backgroundColor: "rgb(241, 241, 241)",
                      position: "absolute",
                      right: 0,
                      width: 100,
                      height: 54,
                      borderBottomRightRadius: 5,
                      display: "flex",
                      borderTopRightRadius: 5,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {/* <small style={{ color: "rgba(100, 100, 100, 0.6)" }}> */}
                    {userAdorment}
                    {/* </small> */}
                  </div>
                </InputAdornment>
              )
            }}
            value={value}
            onBlur={(e) => onBlur && onBlur(e)}
            onChange={(e: any) => handleOnChange(e)}
            style={{ minHeight: "56px" }}
          />
        </>
      )}
    </Box>
  )
}
export default FormInput
