import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Components */
import Dialog from "components/Dialog"

/** Styles */
import { IconEdit, IconDelete } from "components/Icons/ContextMenu"

/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { Context } from "context/Dashboard/EstateMatters/ConstructionManuals/main/Context"
import { AppContext } from "App"
import styles from "./styles"

interface ContextNewAccountRequestsProps {
  data: Record<string, any>
  showUpdateForm: Function
}

const ContextPublicDocuments: React.FC<ContextNewAccountRequestsProps> = ({
  data,
  showUpdateForm
}) => {
  const { listItem } = styles()
  const { deleteDocument, activeDoc } = useContext(Context)
  const { openContext } = useContext(CtxMenuContext)
  const [openDialog, setOpenDialog] = useState(false)
  const { permission } = useContext(AppContext)

  const canEdit = permission?.estate_matter_construction_manuals_edit
  const canDelete = permission?.estate_matter_construction_manuals_remove

  function handleDeleteDocument(uid: string, activeDocValue: string) {
    openContext && openContext(false)
    deleteDocument && deleteDocument(uid, activeDocValue)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={(): void | null => (!canEdit ? null : showUpdateForm(data))}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>
        <ListItem
          className={listItem}
          button
          disabled={!canDelete}
          onClick={(): void | null => (!canDelete ? null : setOpenDialog(true))}
        >
          <i>{IconDelete}</i>
          Delete
        </ListItem>
      </List>
      <Dialog
        action={() => handleDeleteDocument(data?._uid, activeDoc?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the status?"
      />
    </>
  )
}

export default ContextPublicDocuments
