import vmsConfig from "config/Dashboard/VMS"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "Block/ Unit No.",
        accessor: "unit.short_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Status",
        id: "status",
        Cell: ({ original }: Record<string, any>) => {
          return vmsConfig.status.delivery[original.status]
        },
        filterable: false,
        sortable: false
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
