import axios from "axios"
import CommonAPI from "services/Dashboard/Common"

export const UploadImage = async (event: any, fileType?: number) => {
  const fileToUpload = event[0]
  const presigned = await CommonAPI.getPresigned(fileType)
  await axios.put(presigned?.data?._data?.presigned_url, fileToUpload)

  return {
    "key": presigned?.data?._data?.key,
    "file_name": fileToUpload.name,
    "encoding": fileToUpload.type,
    "file_type": fileType || 1
  }
}
