import React, { useEffect } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
/** Components */
import Info from "pages/Dashboard/Feedback/components/Details/Info"
import Messages from "pages/Dashboard/Feedback/components/Details/Message"
/** Context */
import { feedbackConfig } from "config/Dashboard/Feedback"
import dateHelper from "utils/date"
import { DetailsCtxType } from "./DetailsContext"
import withDetailsContext from "./withDetailsContext"

const DetailsView: React.FC<DetailsCtxType> = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /** Views */
  const InfoDetails = (values: any): JSX.Element => {
    return (
      <Info
        description={values?.remarks}
        type={feedbackConfig.type[values?.feedback_type]}
        date={dateHelper.fromUnix(values?.posted_date_ms)}
        time={dateHelper.fromUnixTime(values?.posted_date_ms)}
        assignedTo={values?.assigned_to_staff_account_name}
        byAdmin={values?.by_admin}
        images={values?.feedback_photo?.photos}
      />
    )
  }

  /** Views */
  const MessagesView = (): JSX.Element => {
    return <Messages feedbackId={data?._uid} />
  }

  return (
    <Card>
      <Box bgcolor="#FFFFFF">
        <Box display="flex">
          <Box
            flex="1"
            maxWidth="50%"
            height="100vh"
            overflow="auto"
            bgcolor="#F2F2F2"
          >
            <Box>{MessagesView()}</Box>
          </Box>
          <Box flex="1" maxWidth="50%">
            <Box>{InfoDetails(data)}</Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default withDetailsContext(DetailsView)
