import * as React from "react"

const Left = (
  <svg
    width="25"
    height="40"
    viewBox="0 0 25 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 4.92969L13.5 6.42969L18.0703 11H4V13H18.0703L13.5 17.5703L15 19.0703L22.0703 12L15 4.92969Z"
      fill="#09707B"
    />
    <path
      d="M0.146 39H1.286V35.79H3.23C5.054 35.79 6.296 34.8 6.296 33.066C6.296 31.278 4.94 30.324 3.08 30.324H0.146V39ZM1.28 34.854V31.248H3.152C4.37 31.248 5.216 31.848 5.216 33C5.216 34.14 4.394 34.854 3.206 34.854H1.28ZM9.96716 39.12C11.8152 39.12 12.7872 37.818 12.7872 35.922C12.7872 33.834 11.6772 32.676 9.96716 32.676C8.13116 32.676 7.11716 33.978 7.11716 35.922C7.11716 37.992 8.24516 39.12 9.96716 39.12ZM9.96716 38.244C8.72516 38.244 8.22116 37.206 8.22116 35.898C8.22116 34.422 8.84516 33.546 9.96716 33.546C11.1912 33.546 11.6892 34.572 11.6892 35.898C11.6892 37.356 11.0772 38.244 9.96716 38.244ZM16.4546 39.12C17.9606 39.12 18.8426 38.418 18.8426 37.278C18.8426 36.666 18.5306 35.91 17.3846 35.61L16.1426 35.298C15.4586 35.13 15.0986 34.908 15.0986 34.464C15.0986 33.804 15.6446 33.492 16.4366 33.492C17.1986 33.492 17.6606 33.864 17.7446 34.458H18.7226C18.6866 33.294 17.7926 32.676 16.4426 32.676C14.8766 32.676 14.0786 33.444 14.0846 34.53C14.0846 35.454 14.7266 35.928 15.7346 36.168L17.0186 36.486C17.5826 36.618 17.8406 36.882 17.8406 37.338C17.8406 37.986 17.2826 38.292 16.4486 38.292C15.6566 38.292 15.0386 37.92 14.8766 37.194H13.8686C14.0546 38.532 15.1526 39.12 16.4546 39.12ZM23.3585 33.606V32.79H21.9605V30.99H21.1445L20.8745 32.796H19.8365V33.606H20.8445V37.5C20.8445 38.496 21.1925 39.03 22.4765 39.03C22.9265 39.03 23.2385 38.988 23.3945 38.928V38.184H22.7165C22.1345 38.184 21.9605 38.124 21.9605 37.464V33.606H23.3585Z"
      fill="#09707B"
    />
  </svg>
)

export default Left
