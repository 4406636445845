import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

interface FooterProps {
  handleNext: Function | undefined
  handleBack: Function | null
  handleCancel: Function
  disabled?: boolean
  hideCancel?: boolean
  label?: string
  stretch?: boolean
  noBorder?: boolean
}

const Footer: React.FC<FooterProps> = ({
  handleNext,
  handleBack,
  handleCancel,
  disabled,
  label,
  hideCancel = false,
  stretch = false,
  noBorder = false
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={stretch ? "30px 0 0" : "30px 40px 0"}
      borderTop={noBorder ? "1px solid transparent" : "1px solid #F2F2F2"}
      width="100%"
    >
      <Box>
        {handleBack !== null && (
          <Button color="primary" size="large" onClick={() => handleBack()}>
            Back
          </Button>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Box marginRight="20px">
          {!hideCancel && (
            <Button
              color="primary"
              size="large"
              onClick={() => handleCancel && handleCancel()}
              className="btn-cancel"
            >
              Cancel
            </Button>
          )}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext && handleNext()}
            size="large"
            style={{ width: "169px" }}
            disabled={disabled}
            className="btn-next"
          >
            {label}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
