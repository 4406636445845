import React from "react"

import VMSMainProvider, {
  VMSMainProviderProps
} from "context/Dashboard/VMS/categories/main/Provider"
import VMSMainView from "context/Dashboard/VMS/categories/main/View"

const VMSMain: React.FC<VMSMainProviderProps> = ({
  showAddForm,
  activeTable,
  showUpdateForm
}) => (
  <VMSMainProvider
    showAddForm={showAddForm}
    showUpdateForm={showUpdateForm}
    activeTable={activeTable}
  >
    <VMSMainView />
  </VMSMainProvider>
)

export default VMSMain
