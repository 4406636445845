const version = "v1"
const baseFacility = "svc-facility"
const baseFeedback = "svc-feedback"
const baseBookkeeping = "svc-bookkeeping"
const baseApplications = "svc-application"
const baseVMS = "svc-vms"
const baseAttendance = "svc-attendance"
const baseAccount = "svc-account"
const baseFacilities = "svc-facilities"

const API = {
  COUNT_ATTENDANCE: `${baseAttendance}/${version}/count-attendances`,
  TOTAL_EMPLOYEE: `${baseAccount}/${version}/admin-staff-users-count`,
  /**
   * Bookkeeeping
   */
  OUTSTANDING_PAYMENT: `${baseBookkeeping}/${version}/condo-outstanding-payments`,
  TOTAL_COLLECTIONS: `${baseBookkeeping}/${version}/condo-total-collections`,
  /**
   * VMS
   */
  TOTAL_VMS_CREATED: `${baseVMS}/${version}/vms-count`,
  /**
   * Feedback
   */
  FEEDBACK_CLOSED_COUNT: `${baseFeedback}/${version}/feedback-closed-count`,
  FEEDBACK_COUNT: `${baseFeedback}/${version}/feedback-count`,
  FEEDBACK_RESOLVED_TIME_COUNT: `${baseFeedback}/${version}/feedback-resolved-time-count`,
  /**
   * Applications
   */
  TOTAL_VEHICLES: `${baseApplications}/${version}/vehicles-count`,
  TOTAL_APPLICATIONS_COUNT: `${baseApplications}/${version}/applications-count`,
  /**
   * Facility
   */
  TOTAL_UNITS: `${baseFacility}/${version}/units-count`,
  TOTAL_REGISTERED_UNITS: `${baseFacility}/${version}/units-with-residents-count`,
  TOTAL_TENANTED_UNITS: `${baseFacility}/${version}/units-with-tenants-count`,
  TOTAL_USERS: `${baseFacility}/${version}/registered-users-count`,
  TOTAL_RESIDENTS: `${baseFacility}/${version}/residents-count`,
  /**
   * Facilities
   */
  TOTAL_BOOKING_STATS: `${baseFacilities}/${version}/facility-booking-stats`
}

export default API
