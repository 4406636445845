import React, { useState, useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Components */
import Dialog from "components/Dialog"

/** Context */
import { Context as CategoriesMainCtx } from "context/Dashboard/UnitManagement/categories/main/Context"
import { Context as ParentCtx } from "context/Dashboard/UnitManagement/categories/vehicles/Context"
import { CtxMenuContext } from "components/ContextMenu"
import { AppContext } from "App"

/** Service */
import service from "services/Dashboard/Applications"

/** Styles */
import { IconEdit, IconDelete, IconView } from "components/Icons/ContextMenu"
import styles from "./styles"

interface ContextVehiclesProps {
  data: Record<string, any>
}

const ContextVehicles: React.FC<ContextVehiclesProps> = ({ data }) => {
  const { listItem } = styles()
  const { showEditVehicle } = useContext(CategoriesMainCtx)
  const { setActiveData, setShowDetails } = useContext(ParentCtx)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const [openDialog, setOpenDialog] = useState(false)
  const isArchived = data?.archived_date !== null

  const canEdit = permission?.unit_mgt_edit

  /** Notification */
  const notifySuccess = () =>
    toast("Successfully deleted this vehicle data.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  async function handleUpdateStatus() {
    try {
      const response = await service.archivedVehicle(data?._uid)
      notifySuccess()
      mutate("fetchUnitManagementVehicles")
      return response
    } catch (e) {
      return e
    }
  }

  function handleShowEdit() {
    openContext && openContext(false)
    setActiveData && setActiveData(data)
    showEditVehicle && showEditVehicle()
  }

  function handleShowDetails() {
    openContext && openContext(false)
    setActiveData && setActiveData(data)
    setShowDetails && setShowDetails(true)
  }

  function handleDelete() {
    openContext && openContext(false)
    setOpenDialog(true)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails()}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        {!isArchived && (
          <>
            <ListItem
              className={listItem}
              button
              disabled={!canEdit}
              onClick={() => handleShowEdit()}
            >
              <i>{IconEdit}</i>
              Edit
            </ListItem>
            <ListItem
              className={listItem}
              button
              onClick={() => handleDelete()}
              disabled={!canEdit}
            >
              <i>{IconDelete}</i>
              Archived
            </ListItem>
          </>
        )}
      </List>
      <Dialog
        action={() => handleUpdateStatus()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the status?"
      />
    </>
  )
}

export default ContextVehicles
