import React from "react"
import ContextMenu from "components/ContextMenu"
import { checkValue, checkDate } from "utils/helpers"
import { fromUnix } from "utils/date"
import ContextItems from "../../../components/contextMenus/default"

const tableHeaders = (resetCode?: Function | null, endpoint?: string) => {
  return [
    {
      fixed: "left",
      disableFilters: true,
      columns: [
        {
          width: 70,
          Cell: (row: any): JSX.Element => {
            return (
              <ContextMenu>
                <ContextItems data={row?.original} endpoint={endpoint || ""} />
              </ContextMenu>
            )
          },
          filterable: false
        }
      ]
    },
    {
      Header: "",
      columns: [
        {
          Header: "Block/Unit No",
          id: "unitNumber",
          accessor: (c: any) => checkValue(c?.unit[0]?.short_name),
          Cell: ({ original }: any) => checkValue(original?.unit[0]?.short_name),
          width: 150
        },
        {
          Header: "Name",
          id: "name",
          accessor: (c: any) => checkValue(c?.name),
          Cell: ({ original }: any) => checkValue(original?.name)
        },
        {
          Header: "Email",
          id: "email",
          accessor: (c: any) => checkValue(c?.email),
          Cell: ({ original }: any) => checkValue(original?.email)
        },
        {
          Header: "Mobile No.",
          id: "mobileNumber",
          accessor: (c: any) => checkValue(c?.mobile_no),
          Cell: ({ original }: any) => checkValue(original?.mobile_no)
        },
        {
          Header: "Registered Address",
          id: "registeredAddress",
          accessor: (c: any) => checkValue(c?.registered_address),
          Cell: ({ original }: any) => checkValue(original?.registered_address)
        },
        {
          Header: "Archived by",
          id: "archivedBy",
          accessor: (c: any) => checkValue(c?.archived_by_account_name),
          Cell: ({ original }: any) => checkValue(original?.archived_by_account_name)
        },
        {
          Header: "Archived Date",
          id: "archivedDate",
          accessor: (c: any) =>
            checkDate(c?.archived_date, fromUnix(c?.archived_date)),
          Cell: ({ original }: any) =>
            checkDate(original?.archived_date, fromUnix(original?.archived_date))
        }
      ]
    }
  ]
}

export default tableHeaders
