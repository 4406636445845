export const config: any = {
  status: {
    0: "All",
    1: "Pending",
    2: "Approved",
    3: "Cancelled",
    4: "Rejected"
  },
  statusColor: {
    1: "#FBA11A",
    2: "#166CBB",
    3: "#DE4625",
    4: "#D05611"
  },
  paymentStatus: {
    0: "-",
    1: "Pending",
    2: "Processing",
    3: "Closed",
    4: "Refunded",
    5: "Rejected",
    6: "Cancelled"
  }
}

export const apiMutateNames: Record<number, string> = {
  0: "fetchAllFeedback"
}

export const { status, statusColor, paymentStatus } = config
