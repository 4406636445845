import React from "react"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Image from "material-ui-image"
/** Components */
import Label from "components/Forms/Label"
/** Context */
import withReplyContext from "context/Dashboard/Feedback/reply/withReplyContext"
import { ReplyCtxType } from "context/Dashboard/Feedback/reply/ReplyContext"
/** Config */
// import {feedbackConfig} from "config/Dashboard/Feedback"
/** Styles */
import styles from "./styles"

interface MessageProps extends ReplyCtxType {
  reply: string
  date: string
  time: string
  byAdmin: boolean
  ownReply: boolean
  data: Record<string, any>
  details: any
}

const Message: React.FC<MessageProps> = (props) => {
  const { container, field, value, photoList, photoItem } = styles()
  const {
    reply,
    date,
    time,
    byAdmin,
    ownReply,
    handleActiveMessage,
    details,
    activeReply
    // data,
  } = props

  // const type = feedbackConfig.type[data?.feedback_type]

  return (
    <>
      <div className={`${container}`}>
        {ownReply && (
          <div className="actions">
            {activeReply?.uid === details?._uid ? (
              <IconButton
                aria-label="close"
                onClick={() => handleActiveMessage(details)}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="edit"
                onClick={() => handleActiveMessage(details)}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        )}
        {byAdmin && <div className={field}>Feedback by Admin</div>}
        <div className="two-col">
          <div className={field}>
            <Label label="Date" />
            <div className={value}>{date}</div>
          </div>

          <div className={field}>
            <Label label="Time" />
            <div className={value}>{time}</div>
          </div>
        </div>
        <div className={field}>
          <Label label="Description" />
          <div className={value}>{reply}</div>
        </div>

        <div className={photoList}>
          {details.feedback_reply_photo?.photos !== null &&
            details.feedback_reply_photo?.photos.map((photo: any) => {
              return (
                <div className={photoItem}>
                  <Image src={photo.url} aspectRatio={1 / 1} />
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default withReplyContext(Message)
