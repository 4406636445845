import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Components */
import Table from "pages/Dashboard/VisitorManagement/tables/vmsVisitors"
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
/** Context */
import { MenuIcons } from "pages/Dashboard/VisitorManagement/config/Menu"
import vmsConfig from "config/Dashboard/VMS/"
import { AppContext } from "App"
import { Context as ParentContext } from "context/Dashboard/VMS/categories/main/Context"
import withContext from "./withContext"
import { CtxType } from "./Context"

const VMSVisitorsView: React.FC<CtxType> = ({
  activeFilter,
  filterTable,
  showAddForm
}) => {
  const { status } = vmsConfig
  const { permission } = useContext(AppContext)
  const { downloadData } = useContext(ParentContext)
  const csvFilename = `VMS-Visitors-${status.visitor[activeFilter]}.csv`

  return (
    <Card>
      <Box>
        <TableHeader
          addActionLabel="Add New"
          hasSearch
          addAction={() => showAddForm()}
          title="Visitors"
          titleIcon={MenuIcons[0]}
          actionDisable={permission?.vms_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={activeFilter}
            handleChange={(e: any) => filterTable(e)}
            options={vmsConfig?.status?.visitor}
          />
        </Box>
        <Table activeFilter={activeFilter} />
      </Box>
    </Card>
  )
}

export default withContext(VMSVisitorsView)
