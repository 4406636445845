import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

interface FooterProps {
  handleNext: Function | undefined
  handleBack: Function | null
  handleCancel: Function | null
  handleDone?: Function | null
  handlePrint?: Function | null
  handleSendEmail?: Function | null
  disabled?: boolean
  label?: string
  forAcknowledge?: boolean
  submitting?: boolean
}

const Footer: React.FC<FooterProps> = ({
  handleNext,
  handleBack,
  handleCancel,
  handleDone,
  handlePrint,
  disabled,
  label,
  forAcknowledge,
  submitting = false
}) => {
  const ViewForAcknowledge = (): JSX.Element => {
    if (!forAcknowledge) {
      return <div />
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="30px 40px 0"
        borderTop="1px solid #F2F2F2"
        width="100%"
      >
        <Box>
          {handleDone !== null && (
            <Button
              color="primary"
              size="large"
              onClick={() => handleDone && handleDone()}
            >
              DONE
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {/* <Box marginRight="20px">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSendEmail && handleSendEmail()}
              className="btn-cancel"
            >
              SEND TO EMAIL
            </Button>
          </Box> */}
          <Box>
            <Button
              variant="contained"
              onClick={() => handlePrint && handlePrint()}
              size="large"
              style={{
                width: "169px",
                backgroundColor: "#153965",
                color: "#FFFFFF"
              }}
              disabled={disabled}
              className="btn-next"
            >
              PRINT A COPY
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const ViewDefault = (): JSX.Element => {
    if (forAcknowledge) {
      return <div />
    }
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="30px 40px 0"
        borderTop="1px solid #F2F2F2"
        width="100%"
      >
        <Box>
          {handleBack !== null && (
            <Button color="primary" size="large" onClick={() => handleBack()}>
              Back
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginRight="20px">
            {handleCancel !== null && (
              <Button
                color="primary"
                size="large"
                onClick={() => handleCancel && handleCancel()}
                className="btn-cancel"
              >
                Cancel
              </Button>
            )}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext && handleNext()}
              size="large"
              style={{ width: "169px" }}
              disabled={disabled || submitting}
              className="btn-next"
            >
              {submitting ? "Submitting." : label}
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      {ViewDefault()}
      {ViewForAcknowledge()}
    </>
  )
}

export default Footer
