import React from "react"

import FeedbackMainProvider, {
  FeedbackMainProviderProps
} from "context/Dashboard/Feedback/categories/main/Provider"
import FeddbackMainView from "context/Dashboard/Feedback/categories/main/View"

const FeedbackMain: React.FC<FeedbackMainProviderProps> = ({
  showAddForm,
  showEditForm,
  activeTable,
  showReplyForm,
  showDetails,
  setActiveStatus,
  activeStatus,
  filterTabStatus,
  tabStatus
  // contextActions,
}) => (
  <FeedbackMainProvider
    showAddForm={showAddForm}
    showEditForm={showEditForm}
    showReplyForm={showReplyForm}
    showDetails={showDetails}
    activeTable={activeTable}
    setActiveStatus={setActiveStatus}
    activeStatus={activeStatus}
    filterTabStatus={filterTabStatus}
    tabStatus={tabStatus}
  >
    <FeddbackMainView />
  </FeedbackMainProvider>
)

export default FeedbackMain
