import { Machine } from "xstate"

export const CreatePSMachine = Machine({
  id: "createPSMachine",
  initial: "personalDetails",
  states: {
    personalDetails: {
      on: {
        NEXT: "paymentSchedule"
      }
    },
    paymentSchedule: {
      on: {
        BACK: "personalDetails"
      }
    }
  },
  on: {
    PAYMENT_SCHEDULE: "paymentSchedule"
  }
})
