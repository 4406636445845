import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { toast } from "react-toastify"
import Fade from "@material-ui/core/Fade"
/** Components */
import Dialog from "components/Dialog"
/** Service */
import service from "services/Settings/AccountManagement/Staff"
/** Categories */
import { CtxType } from "context/Settings/AccountManagement/Staff/forms/accountSettings/Context"
import withContext from "context/Settings/AccountManagement/Staff/forms/accountSettings/withContext"
import Announcement from "./components/Announcement"
import MyAttendance from "./components/MyAttendance"
import TakeAttendance from "./components/TakeAttendance"
import Facilities from "./components/Facilities"
import Vms from "./components/Vms"
import Applications from "./components/Applications"
import Feedback from "./components/Feedback"
import Profile from "./components/Profile"

/** Styles */
import styles from "./styles"

const AccountPermission: React.FC<CtxType> = ({
  activeRole,
  payloads,
  setPayloads,
  isFetchingRoles,
  setIsRolesUpdating,
  initSetup
}) => {
  const { sectionHead, sectionItems } = styles()
  const [openDialog, setOpenDialog] = useState(false)

  /** Notification */
  const notifyUpdate = () =>
    toast("Successfully updated the permission.", {
      type: toast.TYPE.SUCCESS
    })

  /** Notification */
  const notifySuccessRemoveRole = () =>
    toast("Successfully remove the role.", {
      type: toast.TYPE.SUCCESS
    })

  const notifyErrorRemoveRole = () =>
    toast("Delete failed because there are users with this role.", {
      type: toast.TYPE.ERROR
    })

  function handleUpdate(key: string, value: boolean) {
    setPayloads &&
      setPayloads({
        ...payloads,
        [key]: value
      })
  }

  async function updatePermissions() {
    try {
      const response = await service.updatePermission(payloads, activeRole)
      notifyUpdate()
      return response
    } catch (e) {
      return e
    }
  }

  function handleDialog() {
    setOpenDialog(true)
  }

  async function handleRemoveRole(roleID: string) {
    setIsRolesUpdating(true)
    try {
      const response = await service.removeRole(roleID)

      if (response?.data?._statusCode === -292) {
        notifyErrorRemoveRole()
        setIsRolesUpdating(false)
      } else {
        notifySuccessRemoveRole()
        await initSetup().then(() => setIsRolesUpdating(false))
      }

      return response
    } catch (e) {
      setIsRolesUpdating(false)
      return e
    }
  }

  return (
    <>
      <Box>
        <Box display="flex" className={sectionHead}>
          <Box flex="3" />
          <Box flex="1">Edit</Box>
          <Box flex="1">View-Self</Box>
          <Box flex="1">View All</Box>
        </Box>

        <Fade in={!isFetchingRoles} timeout={1000}>
          <Box className={sectionItems}>
            <Box>
              <MyAttendance data={payloads} handleUpdate={handleUpdate} />
              <TakeAttendance data={payloads} handleUpdate={handleUpdate} />
              <Facilities data={payloads} handleUpdate={handleUpdate} />
              <Vms data={payloads} handleUpdate={handleUpdate} />
              <Applications data={payloads} handleUpdate={handleUpdate} />
              <Feedback data={payloads} handleUpdate={handleUpdate} />
              <Announcement data={payloads} handleUpdate={handleUpdate} />
              <Profile data={payloads} handleUpdate={handleUpdate} />
            </Box>
          </Box>
        </Fade>
        <Box
          padding="30px 40px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width="150px">
            <Button
              fullWidth
              size="large"
              onClick={() => handleDialog()}
              style={{ color: "#E12323" }}
            >
              REMOVE ROLE
            </Button>
          </Box>
          <Box width="150px">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={() => updatePermissions()}
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        action={() => handleRemoveRole(activeRole)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to remove this role?"
      />
    </>
  )
}
export default withContext(AccountPermission)
