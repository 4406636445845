const vmsConfig: any = {
  status: {
    visitor: {
      0: "All",
      1: "Pending",
      2: "Arrived",
      3: "Closed",
      4: "Cancelled"
    },
    serviceProviders: {
      0: "All",
      1: "Pending",
      2: "Arrived",
      3: "Closed",
      4: "Cancelled"
    },
    delivery: {
      0: "All",
      1: "Pending",
      2: "Arrived",
      3: "Closed",
      4: "Cancelled",
      5: "Received",
      6: "Collected"
    }
  },
  statusFilter: {
    visitor: {
      "All": 0,
      "Pending": 1,
      "Arrived": 2,
      "Closed": 3,
      "Cancelled": 4
    },
    serviceProviders: {
      "All": 0,
      "Pending": 1,
      "Arrived": 2,
      "Closed": 3,
      "Cancelled": 4
    },
    delivery: {
      "All": 0,
      "Pending": 1,
      "Arrived": 2,
      "Closed": 3,
      "Cancelled": 4,
      "Received": 5,
      "Collected": 6
    }
  },
  type: {
    1: "Visitors",
    2: "Delivery",
    3: "Service Providers"
  }
}

export default vmsConfig
