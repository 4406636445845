import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import FilterButtons from "components/FilterButtons"

// styles
// import styles from './styles'

interface Props {
  menu: Record<string, any> | undefined
  action: Function
  activeMenu: Record<string, any> | Array<{}> | undefined
  hasDefaultColor?: boolean
  disabled?: boolean
  isButton?: boolean
  hasData?: boolean
}

const CategoryFilterMulti: React.FC<Props> = ({
  menu,
  action,
  activeMenu,
  hasDefaultColor,
  hasData
}) => {
  return (
    <Box display="flex" overflow="auto">
      {menu &&
        menu?.map((item: Record<string, any>, index: number) => {
          return (
            <Box margin="0 7px 7px 0">
              <FilterButtons
                key={item?.category_name}
                label={item?.category_name}
                onClick={() => action(item, index)}
                isActive={activeMenu?.includes(item?._uid)}
                hasDefaultColor={hasDefaultColor}
                isButton
                disabled={item?.facility_uids === null || hasData}
              />
            </Box>
          )
        })}
    </Box>
  )
}
export default CategoryFilterMulti
