import React from "react"

// Layout
import WithHeader from "layout/WithHeader"

interface SOSProps {
  title: string
}

const SOS: React.FC<SOSProps> = () => {
  return (
    <>
      <WithHeader>
        <h1>Settings SOS</h1>
      </WithHeader>
    </>
  )
}

export default SOS
