import React from "react"

export type CtxType = {
  psActive: boolean
  detailsActive: boolean
  activeTable: string
  setPsActive: Function
  setactiveTable: Function
  setDetailsActive: Function
  activeTableFilter: any
}

export const Context = React.createContext({})
