import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"
import { CondoUID } from "config/Common/CondoInfo"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getVMS(params?: Record<string, any>) {
  return request({
    url: API.LIST_VMS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": CondoUID,
      ...params
    }
  })
}

function createVMS(data: Record<string, any>) {
  return request({
    url: API.CREATE_VMS,
    method: "POST",
    headers: headers(),
    data: data
  })
}

function updateVMS(data: Record<string, any>, uid: string) {
  return request({
    url: `${API.UPDATE_VMS}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: data
  })
}

function deleteVMS(data: Record<string, any>) {
  return request({
    url: API.DELETE_VMS,
    method: "DELETE",
    headers: headers(),
    data: data
  })
}

const vmsSettings = {
  getVMS,
  createVMS,
  updateVMS,
  deleteVMS
}

export default vmsSettings
