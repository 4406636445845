const version = "v1"
const base = "svc-vms"
const baseFacility = "svc-facility"
const baseStorage = "svc-storage"

const API = {
  // ONBOARD
  VMS_FETCH_ALL: `${base}/${version}/vms`,
  VMS_CREATE_VISITOR: `${base}/${version}/vms-visitor`,
  VMS_CREATE_DELIVERY: `${base}/${version}/vms-delivery`,
  VMS_CREATE_SERVICEPROVIDER: `${base}/${version}/vms-service-provider`,
  VMS_FETCH_ALL_UNITS: `${baseFacility}/${version}/units`,
  VMS_UPDATE_VISITOR: `${base}/${version}/vms-visitor/status-override`,
  VMS_UPDATE_DELIVERY: `${base}/${version}/vms-delivery/status-override`,
  VMS_UPDATE_SERVICEPROVIDER: `${base}/${version}/vms-service-provider/status-override`,
  VMS_CREATE_QR_CODE: `${baseStorage}/${version}/qrcode`
}

export default API
