import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    height: "100%"
  },
  navigation: {
    flex: 1,
    padding: "100px 0 100px 60px",
    borderRight: "1px solid #F2F2F2"
  }
}))

export default styles
