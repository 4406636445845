import { makeStyles, Theme } from "@material-ui/core/styles"

// TODO: Create a Theme
const styles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    backgroundColor: "#FFF",
    outline: "none",
    borderRadius: "10px",
    maxWidth: "412px",
    textAlign: "center",
    padding: "71px 20px 50px",
    position: "absolute",
    left: "50%",
    top: "50%",
    minWidth: "412px",
    transform: "translate(-50%, -50%)",
    "& button": {
      padding: "0",
      margin: "0",
      position: "absolute",
      right: "18px",
      top: "15px",
      backgroundColor: "transparent !important",
      minWidth: "0",
      outline: "none",
      "& span": {
        padding: "0",
        margin: "0"
      }
    },
    "& h2": {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "18px",
      textAlign: "center",
      letterSpacing: "0.0015em",
      color: theme.palette.body.gray,
      margin: "0 0 38px"
    },
    "& span": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.0125em",
      textTransform: "uppercase",
      color: theme.palette.primary.main,
      display: "block",
      margin: "0 0 15px",
      "& a": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.0125em",
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        textDecoration: "none",
        outline: "none"
      }
    }
  }
}))

export default styles
