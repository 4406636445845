import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"
import { CondoUID } from "config/Common/CondoInfo"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getApplication(params?: Record<string, any>) {
  return request({
    url: API.LIST_APPLICATIONS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": CondoUID,
      ...params
    }
  })
}

function createApplication(data: Record<string, any>) {
  return request({
    url: API.CREATE_APPLICATION,
    method: "POST",
    headers: headers(),
    data: data
  })
}

function updateApplication(data: Record<string, any>, uid: string) {
  return request({
    url: `${API.UPDATE_APPLICATION}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: data
  })
}

function deleteApplication(data: Record<string, any>) {
  return request({
    url: API.DELETE_APPLICATION,
    method: "DELETE",
    headers: headers(),
    data: data
  })
}

const applicationSettings = {
  getApplication,
  createApplication,
  updateApplication,
  deleteApplication
}

export default applicationSettings
