import React from "react"
import Box from "@material-ui/core/Box"

import { withRouter } from "react-router-dom"

// components
import LoginCard from "./LoginCard"
import Brand from "./Brand"

const useStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh"
  },
  section: {
    display: "flex",
    height: "100%",
    flex: "1"
  }
}

const Login: React.FC = () => {
  const { container, section } = useStyles
  return (
    <Box style={container}>
      <Box style={section}>
        <Brand />
      </Box>
      <Box style={section} justifyContent="center" alignItems="center">
        <LoginCard />
      </Box>
    </Box>
  )
}

export default withRouter(Login)
