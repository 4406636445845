import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  personalDetails: {
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      borderBottom: "1px solid #F2F2F2",
      marginBottom: "40px",
      margin: "0 auto"
    }
  },
  spaceBottom: {
    paddingBottom: "20px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  inputDescription: {
    fontSize: "16px",
    letterSpacing: "0.0015em",
    color: "#454B57"
  }
}))

export default styles
