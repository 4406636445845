import React from "react"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"

/** Component */
import PageHeaderTitle from "components/PageHeaderTitle"
import Roles from "components/Common/Roles"
import AccountPermission from "pages/Settings/AccountManagement/Admin/components/AccountSettings/components/AccountPermission"
import Form from "pages/Settings/AccountManagement/Admin/components/AccountSettings/Form"

/** Context */
import { IconAdd, IconEdit } from "components/Icons/Settings/AccountManagement"
import withContext from "./withContext"
import { CtxType } from "./Context"

const View: React.FC<CtxType> = ({
  selectTab,
  activeRole,
  activeRoleName,
  sendBack,
  showForm,
  setShowForm,
  showFormType
}) => {
  const ViewForm = (): JSX.Element => {
    return <Form openState={showForm} setopenState={setShowForm} />
  }

  const MainView = (): JSX.Element => {
    const roleValue = `${activeRole}/${activeRoleName}/1`

    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Admin Account Settings"
              breadcrumbs="SETTINGS / account management / settings"
              backAction={() => sendBack()}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              startIcon={IconAdd}
              onClick={() => showFormType(true, 1)}
              variant="contained"
            >
              ADD USER ROLE
            </Button>
          </Box>
        </Box>
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="30px 40px"
            borderBottom="3px solid #F2F2F2"
          >
            <Roles
              value={roleValue}
              onChange={(value: any) => selectTab(value)}
              withName
            />
            <IconButton onClick={() => showFormType(true, 2)}>{IconEdit}</IconButton>
          </Box>

          <Box marginBottom="30px">
            <AccountPermission />
          </Box>
        </Card>
      </Box>
    )
  }

  return (
    <>
      {MainView()}
      {ViewForm()}
    </>
  )
}

export default withContext(View)
