import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
/**
 * Components
 */

/**
 * Context
 */
import { Context } from "context/Dashboard/Dashboard/main/Context"
import { AppContext } from "App"
import Block from "./Block"

/**
 * Hooks
 */
import { CountPaymentToday, CountDepositToday } from "./hooks"

const NewAccountsRequest: React.FC = () => {
  const { dateOptions, dateRange } = useContext(Context)
  const { totalPayment, isValidatingPayment } = CountPaymentToday(dateRange)
  const { totalDeposit, isValidatingDeposit } = CountDepositToday(dateRange)

  const { condoInfo } = useContext(AppContext)
  const currency = condoInfo?.currency

  return (
    <Card>
      <Block
        hasIcon
        blockTitle="Payment Received Today"
        isLoading={isValidatingPayment}
        totalCount={totalPayment !== undefined ? totalPayment?.amt : "N/A"}
        date={dateOptions?.currentDate}
        currency={currency}
      />

      <Block
        blockTitle="Deposits this week"
        isLoading={isValidatingDeposit}
        totalCount={totalDeposit !== undefined ? totalDeposit?.amt : "N/A"}
        date={dateOptions?.currentDate}
        currency={currency}
      />
    </Card>
  )
}

export default NewAccountsRequest
