import React, { useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

/** Services */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Utils */
import { dateDefaultTime } from "utils/date"

/** Context */
import { Context } from "./Context"

/** Interface */
interface CreatePaymentProps {
  showPayment: boolean
  setShowPayment: Function
}

const CreatePaymentProvider: React.FC<CreatePaymentProps> = (props: any) => {
  const { userData } = useSelector((state: any) => state.user)

  const [submitting, setSubmitting] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState({
    unitName: "",
    unitUID: "",
    userDetailUid: "",
    payeeAccountUID: "",
    payeeName: "",
    description: "",
    category: "",
    paymentType: "",
    amt: "",
    tax: "",
    remarks: "",
    dueDate: "",
    addedBy: userData?.account_uid
  })

  const { children, showPayment, setShowPayment } = props

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully created the payment.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleCreatePayment = async () => {
    const payload = {
      "unit_uid": paymentDetails.unitUID,
      "payee_user_detail_uid": paymentDetails.userDetailUid,
      "payee_account_uid": paymentDetails.payeeAccountUID,
      "payee_name": paymentDetails.payeeName,
      "description": paymentDetails.description,
      "category": paymentDetails.category,
      "payment_type": +paymentDetails.paymentType,
      "amt": +paymentDetails.amt,
      "tax": +paymentDetails.tax,
      "admin_remarks": paymentDetails.remarks,
      "added_by_account_uid": paymentDetails.addedBy,
      "payment_due_date": dateDefaultTime(paymentDetails?.dueDate)
    }

    setSubmitting(true)
    try {
      const { data } = await BookkeepingAPI.createPayment(payload)
      await setShowPayment(false)
      setSubmitting(false)
      notifyCreate()
      return data
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        handleCreatePayment,
        setOpenDialog,
        setPaymentDetails,
        submitting,
        openDialog,
        paymentDetails,
        showPayment,
        setShowPayment
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreatePaymentProvider
