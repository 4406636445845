import React from "react"
import { Route, Redirect, withRouter, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"

/**
 * Context
 */
import PrivateRoute from "utils/PrivateRoute"
import OnboardLogin from "pages/Onboard/Login"
import OnboardResetPassword from "pages/Onboard/ResetPassword"
import OnboardForgotPassword from "pages/Onboard/ForgotPassword"
import PageNotFound from "pages/Common/PageNotFound"
import { DashboardRoute, SettingsRoute } from "./routes"

const AppMain = ({ location }: any) => {
  const ActiveRoutes: any =
    location.pathname.split("/")[1] === "property-management"
      ? DashboardRoute
      : SettingsRoute

  function ActiveRoutesView() {
    return ActiveRoutes?.map((item: any) => (
      <PrivateRoute path={item.route} component={item.component} />
    ))
  }

  return (
    <>
      <Switch>
        <Route path="/login" component={OnboardLogin} />
        <Route path="/forgot-password" component={OnboardForgotPassword} />
        <Route
          exact={false}
          path="/reset-password"
          component={OnboardResetPassword}
        />

        {ActiveRoutesView()}

        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route
          exact
          path="/property-management"
          render={() => <Redirect to="/property-management/dashboards" />}
        />
        <Route
          exact
          path="/settings"
          render={() => <Redirect to="/settings/account-management/admin" />}
        />
        <Route component={PageNotFound} />
      </Switch>
      <ToastContainer />
    </>
  )
}

export default withRouter(AppMain)
