import React, { useState, useContext } from "react"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Lock from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import { mutate } from "swr"
import { toast } from "react-toastify"

/** Component */
import Dialog from "components/Dialog"

/** Services */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Config */
// import {tableName} from "config/Dashboard/Bookkeeping"

/** Context */
import withContextMain from "context/Dashboard/Bookkeeping/categories/main/withContext"
import { Context as RootContext } from "context/Dashboard/Bookkeeping/main/Context"
import { CtxType } from "context/Dashboard/Bookkeeping/categories/main/Context"
import { CtxMenuContext } from "components/ContextMenu"
import { AppContext } from "App"

/** Utils */
import { binaryString, isRefundRequested } from "utils/helpers"

/** Styles */
import styles from "./styles"

/** Icons */
import { IconEdit, IconView, IconDown } from "./icons"

interface ContextProps extends CtxType {
  data: Record<string, any>
}

const ContextBookKeeping: React.FC<ContextProps> = ({ contextActions, data }) => {
  const { listItem, innerList, collapseControl, iconActive } = styles()
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  /** Data Details */
  const paymentStatus = data?.payment_status
  const paymentType = data?.payment_type
  const paymentCategory = data?.category
  const { openContext } = useContext(CtxMenuContext)
  const { setShowDetails } = useContext(RootContext)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.bookkeeping_edit
  /** check flags */
  // const fromBalance = +isFromAvailableBalance(data.flags) === 1
  const isRefundLock = +binaryString(data.flags) === 1
  const isRequestedForRefund = +isRefundRequested(data.flags) === 1

  const {
    showClosePayment,
    showEditPayment,
    showCancelPayment,
    showRejectPayment,
    showDetails,
    showCancelRefund,
    showCloseRefund,
    showRejectRefund
  } = contextActions

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully updated the lock status.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleClick = () => {
    setOpen(!open)
  }

  const handleDialog = () => {
    setOpenDialog(true)
  }

  const handleUpdateDepositLock = async () => {
    const payload = {
      "_uid": data?._uid,
      "is_deposit_lock": !isRefundLock
    }

    try {
      const response = await BookkeepingAPI.updateDepositLock(payload).then(() => [
        notifyCreate(),
        openContext && openContext(false),
        mutate(`fetchDepositPayments`),
        mutate(`fetchAllPayments`)
      ])
      return response
    } catch (e) {
      return e
    }
  }

  /** Views */
  const ViewDetails = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        onClick={() => {
          showDetails(data)
          setShowDetails && setShowDetails(true)
          openContext && openContext(false)
        }}
      >
        <i>{IconView}</i>
        View
      </ListItem>
    )
  }

  const ViewEdit = (): JSX.Element => {
    if (
      paymentStatus !== 1 ||
      paymentType === 3 ||
      paymentCategory === "Scheduled"
    ) {
      return <div />
    }

    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): null | Array<Function> => {
          return !canEdit
            ? null
            : [showEditPayment(data), openContext && openContext(false)]
        }}
      >
        <i>{IconEdit}</i>
        Edit
      </ListItem>
    )
  }

  const ViewPaymentTypePayment = (): JSX.Element => {
    if (paymentType !== 1) {
      return <div />
    }

    if (paymentStatus === 1) {
      return (
        <>
          <CancelPayment />
          <ClosePayment />
        </>
      )
    }

    if (paymentStatus === 2) {
      return (
        <>
          <CancelPayment />
          <ClosePayment />
          <RejectPayment />
        </>
      )
    }

    if (paymentStatus === 3) {
      return (
        <>
          <CancelPayment />
          {isRequestedForRefund ? <div /> : <CloseRefund />}
          <RejectPayment />
        </>
      )
    }

    return <div />
  }

  const ViewPaymentTypeDeposit = () => {
    if (paymentType !== 2) {
      return <div />
    }

    if (paymentStatus === 1) {
      return (
        <>
          <CancelPayment />
          <ClosePayment />
        </>
      )
    }

    if (paymentStatus === 2) {
      return (
        <>
          <CancelPayment />
          <ClosePayment />
          <RejectPayment />
        </>
      )
    }

    if (paymentStatus === 3) {
      return (
        <>
          <CancelPayment />
          {isRequestedForRefund || isRefundLock ? <div /> : <CloseRefund />}
          <RejectPayment />
        </>
      )
    }

    return <div />
  }

  const ViewPaymentTypeRefund = () => {
    if (paymentType !== 3) {
      return <div />
    }

    if (paymentStatus === 1) {
      return (
        <>
          <CancelRefund />
          <CloseRefund />
          <RejectRefund />
        </>
      )
    }

    if (paymentStatus === 2) {
      return (
        <>
          <CancelRefund />
          <CloseRefund />
          <RejectRefund />
        </>
      )
    }

    if (paymentStatus === 3) {
      return (
        <>
          <CancelRefund />
          {/* {isRequestedForRefund ? <div /> : <CloseRefund />} */}
          <RejectRefund />
        </>
      )
    }

    return <div />
  }

  const ViewLockUnlock = (): JSX.Element => {
    if (paymentType !== 3) {
      if (paymentStatus === 3) {
        return (
          <>
            <LockRefund />
          </>
        )
      }
    }

    return <div />
  }

  const ClosePayment = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showClosePayment(data))}
      >
        Close
      </ListItem>
    )
  }

  const CancelPayment = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showCancelPayment(data))}
      >
        Cancel
      </ListItem>
    )
  }

  const RejectPayment = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showRejectPayment(data))}
      >
        Reject
      </ListItem>
    )
  }

  const RejectRefund = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showRejectRefund(data))}
      >
        Reject Refund
      </ListItem>
    )
  }

  const LockRefund = (): JSX.Element => {
    if (paymentType !== 2) {
      return <div />
    }

    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : handleDialog())}
      >
        <i>{isRefundLock ? <LockOpenIcon /> : <Lock />}</i>
        {isRefundLock ? "Unlock Deposit" : "Lock Deposit"}
      </ListItem>
    )
  }

  const CloseRefund = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showCloseRefund(data))}
      >
        Refund
      </ListItem>
    )
  }

  const CancelRefund = (): JSX.Element => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={(): void | null => (!canEdit ? null : showCancelRefund(data))}
      >
        Cancel Refund
      </ListItem>
    )
  }

  return (
    <>
      <List component="nav">
        <ViewDetails />
        {paymentStatus !== 6 && paymentStatus !== 5 && paymentStatus !== 4 && (
          <ListItem className={listItem} button onClick={handleClick}>
            <div className={collapseControl}>
              <div>
                <i />
                Mark as
              </div>
              <div>
                <i className={open ? iconActive : ""}>{IconDown}</i>
              </div>
            </div>
          </ListItem>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className={innerList} component="div" disablePadding>
            <ViewPaymentTypePayment />
            <ViewPaymentTypeDeposit />
            <ViewPaymentTypeRefund />
          </List>
        </Collapse>
        <ViewLockUnlock />
        <ViewEdit />
      </List>
      <Dialog
        action={() => handleUpdateDepositLock()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the refund lock status?"
      />
    </>
  )
}

// export default withContext(withContextMain(ContextBookKeeping))
export default withContextMain(ContextBookKeeping)
