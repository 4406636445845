import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import { mutate } from "swr"
import { useSelector } from "react-redux"
/* Components */
import Staffs from "components/Common/Staffs"
import Dialog from "components/Dialog"
/* Config */
import { feedbackConfig } from "config/Dashboard/Feedback"
/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { CtxType as CtxTypeMain } from "context/Dashboard/Feedback/main/FeedbackContext"
import { CtxType } from "context/Dashboard/Feedback/categories/main/Context"
import withContext from "context/Dashboard/Feedback/main/withContext"
import withMainContext from "context/Dashboard/Feedback/categories/main/withContext"
import { AppContext } from "App"

/* Styles */
import styles from "./styles"
/** Service */

import RolesServiceHook from "./hooks"

const IconDown = (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42969 0.5L0.929688 2L7 8.07031L13.0703 2L11.5703 0.5L7 5.07031L2.42969 0.5Z"
      fill="#09707B"
    />
  </svg>
)

interface ContextFeedbackProps extends CtxTypeMain, CtxType {
  apiCallback: string
  rowData: {
    _uid: string
    feedback_by_account_uid: string
    feedback_owner_account_uid: string
    feedback_posted_by_admin: boolean
    is_urgent: boolean
    status: number
  }
}

const ContextFeedback: React.FC<ContextFeedbackProps> = ({
  handleReply,
  handleAssignToStaff,
  handleUpdateStatus,
  handleIsUrgent,
  feedbackEdit,
  handleView,
  showReplyForm,
  showDetails,
  showEditForm,
  rowData,
  apiCallback
}) => {
  const { listItem, innerList, collapseControl } = styles()
  const { roles } = RolesServiceHook(2)
  const { openContext } = useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const itemStatus = rowData?.status

  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState(0)
  const [roleId, setRoleId] = useState("")
  const [activeStaff, setActiveStaff] = useState("")
  const [activeStatus, setActiveStatus] = useState(0)

  const { userData } = useSelector((state: any) => state.user)
  const canEdit = permission?.feedback_edit

  const handleClick = () => {
    setOpen(!open)
  }

  const handleSelectStaff = (value: any) => {
    setActiveStaff(value)
    setDialogType(1)
    setOpenDialog(true)
  }

  const handleDialog = (type: number) => {
    setDialogType(type)
    setOpenDialog(true)
  }

  const handleDialogStatus = (status: number) => {
    setActiveStatus(status)
    setDialogType(3)
    setOpenDialog(true)
  }

  const handleAssigning = () => {
    openContext && openContext(false)
    handleAssignToStaff &&
      handleAssignToStaff(rowData._uid, activeStaff).then(() => mutate(apiCallback))
    setOpen(false)
    setRoleId("")
  }

  const handleStatus = () => {
    openContext && openContext(false)
    setOpen(false)
    setRoleId("")
    handleUpdateStatus(rowData._uid, activeStatus).then(() => mutate(apiCallback))
  }

  const handleUrgency = () => {
    openContext && openContext(false)
    handleIsUrgent &&
      handleIsUrgent(rowData._uid, !rowData.is_urgent).then(() =>
        mutate(apiCallback)
      )
  }

  const handleEdit = () => {
    openContext && openContext(false)
    feedbackEdit(rowData)
    showEditForm()
  }

  const handleViewLink = () => {
    openContext && openContext(false)
    handleView(rowData)
    showDetails()
  }

  /* View */
  const StatusOption = (): JSX.Element => {
    return (
      <>
        {Object.keys(feedbackConfig.status).map(
          (status: any): JSX.Element => {
            if (+status !== 0 && +status !== itemStatus) {
              return (
                <ListItem
                  className={listItem}
                  button
                  disabled={!canEdit}
                  onClick={() => (!canEdit ? null : handleDialogStatus(status))}
                >
                  {feedbackConfig.status[status]}
                </ListItem>
              )
            }
            return <div />
          }
        )}
      </>
    )
  }

  const UrgentOption = (): JSX.Element => {
    if (rowData?.is_urgent) {
      return <div />
    }
    return (
      <>
        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={() => (!canEdit ? null : handleDialog(2))}
        >
          Set as urgent
        </ListItem>
      </>
    )
  }

  const ReplyOption = () => {
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={() => {
          return !canEdit ? null : [handleReply(rowData), showReplyForm()]
        }}
      >
        reply
      </ListItem>
    )
  }

  const DialogMessage = (status: string) => {
    if (dialogType === 1) {
      return "Are you sure you want to assign?"
    }
    if (dialogType === 2) {
      return "Do you want to set this feedback to Urgent?"
    }
    return `Are you sure you want to update the status to ${status}?`
  }

  const DialogActions = () => {
    if (dialogType === 1) {
      return handleAssigning()
    }
    if (dialogType === 2) {
      return handleUrgency()
    }
    return handleStatus()
  }

  const RolesOption = () => {
    return (
      <List className={innerList} component="div" disablePadding>
        {Object.keys(roles).map((i: string) => {
          return (
            <ListItem
              className={`${listItem} ${roleId === roles[i]?._uid ? "active" : ""}`}
              button
              onClick={() => setRoleId(roles[i]?._uid)}
            >
              {roles[i]?.role_name}
            </ListItem>
          )
        })}
      </List>
    )
  }

  const EditView = () => {
    if (rowData?.feedback_owner_account_uid !== userData?.account_uid) {
      return null
    }
    return (
      <ListItem
        className={listItem}
        button
        disabled={!canEdit}
        onClick={() => (!canEdit ? null : handleEdit())}
      >
        Edit
      </ListItem>
    )
  }

  const DetailsView = () => {
    return (
      <ListItem className={listItem} button onClick={() => handleViewLink()}>
        View
      </ListItem>
    )
  }

  return (
    <>
      <Box display="flex" width="100%">
        <Box width="100%" bgcolor="#FFFFFF">
          <List component="nav" style={{ maxHeight: 500 }}>
            <EditView />
            <DetailsView />
            <ListItem
              className={listItem}
              button
              disabled={!canEdit}
              onClick={() => (!canEdit ? null : handleClick())}
            >
              <div className={collapseControl}>
                <div>Assign Staff</div>
                <div>
                  <i>{IconDown}</i>
                </div>
              </div>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <RolesOption />
            </Collapse>
            {" "}
            <UrgentOption />
            <ReplyOption />
            <StatusOption />
          </List>
        </Box>

        {roleId !== "" && open && (
          <Box minWidth="600px" marginLeft="20px" bgcolor="#FFFFFF">
            <Staffs
              type="context"
              value=""
              roleId={roleId}
              action={(e: any) => handleSelectStaff(e)}
            />
          </Box>
        )}

        <Dialog
          action={() => DialogActions()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel={dialogType === 1 ? "Assign" : "OK"}
          title=""
          message={DialogMessage(feedbackConfig.status[activeStatus])}
        />
      </Box>
    </>
  )
}

export default withMainContext(withContext(ContextFeedback))
