import React, { useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

/** Services */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Utils */
import { dateDefaultTime } from "utils/date"

/** Context */
import { Context } from "./Context"

/** Interface */
export interface EditPaymentProviderProps {
  showPayment: boolean
  setShowPayment: Function
  data: Record<any, any>
}

const EditPaymentProvider: React.FC<EditPaymentProviderProps> = ({
  children,
  showPayment,
  setShowPayment,
  data
}) => {
  const { userData } = useSelector((state: any) => state.user)
  const hasData = data !== undefined

  const [submitting, setSubmitting] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState({
    unitName: data?.unit?.short_name,
    unitUID: data?.unit_uid,
    userDetailUid: data?.payee_user_detail_uid,
    payeeAccountUID: data?.payee_account_uid,
    payeeName: data?.payee_name,
    description: data?.description,
    category: data?.category,
    paymentType: data?.payment_type,
    amt: data?.amt,
    tax: data?.tax,
    remarks: data?.admin_remarks,
    dueDate: data?.payment_due_date,
    addedBy: userData?.account_uid
  })

  /** Notification */
  const notifyEdit = () =>
    toast("Successfully updated the payment.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleEditPayment = async () => {
    const payload = {
      "unit_uid": paymentDetails.unitUID,
      "payee_user_detail_uid": paymentDetails.userDetailUid,
      "payee_account_uid": paymentDetails.payeeAccountUID,
      "payee_name": paymentDetails.payeeName,
      "description": paymentDetails.description,
      "category": paymentDetails.category,
      "payment_type": +paymentDetails.paymentType,
      "amt": +paymentDetails.amt,
      "tax": +paymentDetails.tax,
      "admin_remarks": paymentDetails.remarks,
      "added_by_account_uid": paymentDetails.addedBy,
      "payment_due_date": dateDefaultTime(paymentDetails?.dueDate)
    }

    setSubmitting(true)
    try {
      const { dataReturned } = await BookkeepingAPI.editPayment(payload, data?._uid)
      await setShowPayment(false)
      setSubmitting(false)
      notifyEdit()
      return dataReturned
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        hasData,
        handleEditPayment,
        setOpenDialog,
        setPaymentDetails,
        submitting,
        openDialog,
        paymentDetails,
        showPayment,
        setShowPayment
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default EditPaymentProvider
