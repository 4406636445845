import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Drawer from "components/Drawer"
import Label from "components/Forms/Label"
import Value from "components/Forms/Value"
import { fromUnix } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import DownloadWrapper from "./DownloadWrapper"
import PDF from "./PDF"
/**
 * Utils
 */
/** Styles */
import styles from "./styles"

interface DetailsProps {
  openState: boolean
  data?: Record<string, any>
  setopenState: Function
}

const Details: React.FC<DetailsProps> = ({ openState, setopenState, data }) => {
  const { title } = styles()

  return (
    <Drawer openState={openState} setopenState={setopenState} closeBtnDark>
      <Box display="flex" height="100%">
        <Box
          bgcolor="#F2F2F2"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="0 30px"
        >
          {data?.file?.url !== undefined && (
            <DownloadWrapper
              fileUrl={data?.file?.url}
              fileName={data?.file?.file_name}
            >
              <PDF file={data?.file?.url} />
            </DownloadWrapper>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding="0 50px"
        >
          <Box className={title}>{checkValue(data?.title)}</Box>
          <Box marginBottom="25px">
            <Label label="File Name" />
            <Value val={checkValue(data?.file?.file_name)} />
          </Box>
          <Box marginBottom="25px">
            <Label label="Description" />
            <Value val={checkValue(data?.description)} />
          </Box>
          <Box marginBottom="25px">
            <Label label="Start Date" />
            <Value val={checkDate(data?.start_date, fromUnix(data?.start_date))} />
          </Box>
          <Box marginBottom="25px">
            <Label label="End Date" />
            <Value val={checkDate(data?.end_date, fromUnix(data?.end_date))} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
export default Details
