import React from "react"

import TableProvider, {
  MainTableProviderProps
} from "context/Dashboard/Bookkeeping/categories/main/Provider"
import TableView from "context/Dashboard/Bookkeeping/categories/main/View"

const TableMain: React.FC<MainTableProviderProps> = ({
  showPayment,
  contextActions,
  filterActiveTable,
  activeTable
}) => (
  <TableProvider
    showPayment={showPayment}
    contextActions={contextActions}
    filterActiveTable={filterActiveTable}
    activeTable={activeTable}
  >
    <TableView />
  </TableProvider>
)

export default TableMain
