import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  section: {
    padding: "0 30px 30px 30px"
  },
  sectionLast: {
    borderBottom: "1px solid transparent",
    padding: "0 0 40px 0",
    margin: "0 auto 40px auto",
    width: "50%"
  }
}))

export default styles
