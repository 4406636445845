import React, { useMemo, useContext } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import DatePicker from "components/Forms/DatePicker"
import Select from "components/Forms/Select"
/** Context */
import { Context as MainContext } from "context/Dashboard/EstateMatters/ConstructionManuals/forms/document/Context"
/** Validation */
import { documentInfoValidation } from "config/Dashboard/EstateMatters/ConstructionManuals/validation"
/** Ref */
import { refSubmit } from "context/Dashboard/EstateMatters/ConstructionManuals/forms/document/View"
/** Config */
import { FrequencyType } from "config/Dashboard/EstateMatters/ConstructionManuals"
/** Styles */
import styles from "./styles"

interface Props {
  setIsValid: Function
}

const DocumentInfo: React.FC<Props> = ({ setIsValid }) => {
  const classes = styles()
  const { docDetails, handleFormChange, hasData } = useContext(MainContext)
  const {
    title,
    serialNo,
    description,
    startDate,
    endDate,
    quantity,
    frequency,
    remarks
  } = docDetails

  return (
    <div>
      <Box>
        <Formik
          validateOnMount
          initialValues={useMemo(() => {
            return {
              title,
              description,
              startDate,
              endDate,
              serialNo,
              quantity,
              frequency,
              remarks
            }
          }, [])}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={documentInfoValidation}
          isInitialValid={hasData || false}
        >
          {({
            isValid,
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form>
                <FormWrapper title="Document Info" width="60%">
                  <Box className={classes.container}>
                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="serialNo"
                        value={serialNo}
                        idValue="serialNo"
                        label="serialNo"
                        placeholder="Serial Number"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange &&
                          handleFormChange("serialNo", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.serialNo && Boolean(errors.serialNo)}
                        helperText={
                          errors.serialNo && touched.serialNo && errors.serialNo
                        }
                      />
                    </Box>

                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="title"
                        value={title}
                        idValue="title"
                        label="Title"
                        placeholder="Title"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange &&
                          handleFormChange("title", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={errors.title && touched.title && errors.title}
                      />
                    </Box>

                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="description"
                        value={description}
                        idValue="description"
                        label="Description"
                        placeholder="Description"
                        multiline
                        rows={3}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange &&
                          handleFormChange("description", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />
                    </Box>

                    <Box className={classes.twoCol}>
                      <Box>
                        <Select
                          displayEmpty={false}
                          idValue
                          label="Frequency"
                          selectName="frequency"
                          placeholder=""
                          value={frequency}
                          items={FrequencyType}
                          error={touched.frequency && Boolean(errors.frequency)}
                          helperText={
                            errors.frequency &&
                            touched.frequency &&
                            String(errors.frequency)
                          }
                          onChange={(e: any) =>
                            handleFormChange &&
                            handleFormChange("frequency", e, setFieldValue)}
                        />
                      </Box>

                      <Box>
                        <FormInput
                          disabled={Number(frequency) === 0}
                          type="number"
                          name="quantity"
                          value={quantity}
                          idValue="quantity"
                          label="Quantity"
                          placeholder="Quantity"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange &&
                            handleFormChange("quantity", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={touched.quantity && Boolean(errors.quantity)}
                          helperText={
                            frequency !== 0 &&
                            errors.quantity &&
                            touched.quantity &&
                            errors.quantity
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={classes.twoCol}>
                      <Box>
                        <DatePicker
                          label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          value={startDate}
                          placeholder="mm/dd/yyyy"
                          handleDateChange={(value: string): void =>
                            handleFormChange &&
                            handleFormChange("startDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={
                            errors.startDate && touched.startDate && errors.startDate
                          }
                        />
                      </Box>
                      <Box>
                        <DatePicker
                          label="End Date"
                          name="endDate"
                          format="MM/dd/yyyy"
                          value={endDate}
                          placeholder="mm/dd/yyyy"
                          minDate={startDate}
                          handleDateChange={(value: string): void =>
                            handleFormChange &&
                            handleFormChange("endDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={
                            errors.endDate && touched.endDate && errors.endDate
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={classes.spaceBottom}>
                      <FormInput
                        name="remarks"
                        value={remarks}
                        idValue="remarks"
                        label="Remarks"
                        placeholder="Remarks"
                        multiline
                        rows={3}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange &&
                          handleFormChange("remarks", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.remarks && Boolean(errors.remarks)}
                        helperText={
                          errors.remarks && touched.remarks && errors.remarks
                        }
                      />
                    </Box>
                  </Box>
                  <Box>{isValid ? setIsValid(true) : setIsValid(false)}</Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </FormWrapper>
              </form>
            )
          }}
        </Formik>
      </Box>
    </div>
  )
}

export default DocumentInfo
