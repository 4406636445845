/* eslint-disable @typescript-eslint/camelcase */
import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

/** Components */
import Label from "components/Forms/Label"

/** Utils */
import { fromUnix } from "utils/date"

/** Config */
import { vehicleType } from "config/Dashboard/Applications"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  },
  values: {
    minWidth: "calc(100%/3)",
    marginBottom: "20px"
  }
}))

interface VehicleRegistrationSummaryProps {
  info: Record<string, any>
}

const VehicleRegistrationSummary: React.FC<VehicleRegistrationSummaryProps> = ({
  info
}) => {
  const { value, values } = styles()

  const {
    iu_no,
    vehicle_model,
    vehicle_no,
    vehicle_type,
    owner_name
  } = info?.request_data?.vehicle
  const { start_date, end_date, serial_no } = info

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="initial"
      paddingBottom="30px"
      width="60%"
      margin="auto"
    >
      <Box className={values}>
        <Label label="Vehicle Type" />
        <Box className={value}>{vehicleType[vehicle_type]}</Box>
      </Box>

      <Box className={values}>
        <Label label="Vehicle Model" />
        <Box className={value}>{vehicle_model}</Box>
      </Box>

      <Box className={values}>
        <Label label="Vehicle No." />
        <Box className={value}>{vehicle_no}</Box>
      </Box>

      <Box className={values}>
        <Label label="Owner Name" />
        <Box className={value}>{owner_name}</Box>
      </Box>

      <Box className={values}>
        <Label label="Vehicle IU / Electronic Number" />
        <Box className={value}>{iu_no}</Box>
      </Box>
      {start_date !== 0 && (
        <Box className={values}>
          <Label label="Start Date" />
          <Box className={value}>{fromUnix(start_date)}</Box>
        </Box>
      )}
      {end_date !== 0 && (
        <Box className={values}>
          <Label label="End Date" />
          <Box className={value}>{fromUnix(end_date)}</Box>
        </Box>
      )}

      <Box className={values}>
        <Label label="Serial No." />
        <Box className={value}>{serial_no === "" ? "N/A" : serial_no}</Box>
      </Box>
    </Box>
  )
}
export default VehicleRegistrationSummary
