import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Component */
import AccountPermission from "pages/Settings/AccountManagement/OwnersTenants/components/AccountSettings/components/AccountPermission"
// import Others from "pages/Settings/AccountManagement/Admin/components/AccountSettings/components/Others"
import RoleTabs from "pages/Settings/AccountManagement/OwnersTenants/components/AccountSettings/components/Tabs"

/** Context */
import withContext from "./withContext"
import { CtxType } from "./Context"

const View: React.FC<CtxType> = ({
  activeTab,
  selectTab,
  activeRole,
  tabValues
}) => {
  const MainView = (): JSX.Element => {
    return (
      <Box>
        <Box display="flex" justifyContent="flex-end" minHeight="52px">
          <RoleTabs
            activeItem={activeTab}
            selectItem={selectTab}
            activeRole={activeRole}
            options={tabValues}
          />
        </Box>

        <Box marginBottom="30px">
          <Card>
            <AccountPermission />
          </Card>
        </Box>
      </Box>
    )
  }

  return <>{MainView()}</>
}

export default withContext(View)
