import useSWR from "swr"
import service from "services/Dashboard/Reports"

export function TotalRegisteredUnitsHook() {
  const fetchTotalRegisteredUnits = async () => service.getRegisteredUnits()

  const { data, isValidating, error } = useSWR(
    `fetchTotalRegisteredUnits`,
    fetchTotalRegisteredUnits,
    {
      revalidateOnFocus: false
    }
  )
  const totalRegisteredUnits = data?.data?._data?.total

  return {
    totalRegisteredUnits,
    isValidating,
    error
  }
}

export function TotalUnitsHook() {
  const fetchTotalUnits = async () => service.getTotalUnits()

  const { data, isValidating, error } = useSWR(`fetchTotalUnits`, fetchTotalUnits, {
    revalidateOnFocus: false
  })
  const totalUnits = data?.data?._data?.total

  return {
    totalUnits,
    isValidating,
    error
  }
}
