import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade"
import { Link } from "react-router-dom"
/**
 * Components
 */
import Table from "components/Table"
import Loader from "components/Loader"
import { Context } from "context/Dashboard/Dashboard/main/Context"
import { IconVMSDelivery } from "components/Icons/Dashboard"
import Header from "../Header"
/**
 * Icon
 */
/**
 * Hooks
 */
import { VMSPending, VMSTodayPreview, VMSTomorrowPreview } from "./hooks"

// sample config
import TableHeaderColumns from "./config/TableHeaderColumns"

const Application: React.FC = () => {
  const [value, setValue] = React.useState(0)
  const { dateOptions, dateRange } = useContext(Context)

  const { vmsList, isValidating } = VMSTodayPreview(dateRange)
  const { vmsListTomorrow, isValidatingTomorrow } = VMSTomorrowPreview(dateOptions)
  const { vmsPendingList, isValidatingPending } = VMSPending()

  function TableData(query: number) {
    switch (query) {
      case 0:
        return vmsList
      case 1:
        return vmsListTomorrow
      default:
        return vmsPendingList
    }
  }

  if (isValidating || isValidatingTomorrow || isValidatingPending) {
    return <Loader dark />
  }

  return (
    <Fade in timeout={1000}>
      <Box>
        <Card>
          <Header
            value={value}
            handleChange={setValue}
            tableName="Delivery"
            icon={IconVMSDelivery}
          />
          <Box>
            <Table
              data={TableData(value) || []}
              columns={TableHeaderColumns}
              minRows={3}
              showPagination={false}
            />
            <Box display="flex" justifyContent="flex-end" padding="10px 10px">
              <Button
                color="primary"
                size="large"
                component={Link}
                to="/property-management/visitor-management"
              >
                <Box fontWeight="600">VIEW ALL</Box>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Fade>
  )
}

export default Application
