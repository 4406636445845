import React, { useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { mutate } from "swr"
import { toast } from "react-toastify"

/** Component */
import Dialog from "components/Dialog"

/** Styles */
import { CtxMenuContext } from "components/ContextMenu"
import UnitManagementAPI from "services/Dashboard/UnitManagement"
import { AppContext } from "App"
import styles from "./styles"

/** Context */

/** Icons */
import { IconDetails } from "./icons"

/** Services */

interface ContextChangeRequestsProps {
  data: Record<string, any>
}

const ContextChangeRequests: React.FC<ContextChangeRequestsProps> = ({ data }) => {
  const { listItem } = styles()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [actionType, setActionType] = React.useState(0)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.unit_mgt_edit

  /** Notification */
  const notifyAprrove = () =>
    toast("Successfully approved the Change Address Request.", {
      type: toast.TYPE.SUCCESS
    })

  const handleDialog = (type: number) => {
    setOpenDialog(true)
    setActionType(type)
  }

  const handleUpdateStatus = async () => {
    openContext && openContext(false)
    const type = actionType
    if (type === 1) {
      try {
        const response = await UnitManagementAPI.approveChangeAddressRequests({
          "_uid": data?._uid
        }).then(() => [
          notifyAprrove(),
          openContext && openContext(false),
          mutate("fetchChangeAddressRequests")
        ])
        return response
      } catch (e) {
        return e
      }
    } else {
      return type
    }
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void | null => (!canEdit ? null : handleDialog(1))}
        >
          <i>{IconDetails}</i>
          Approve
        </ListItem>
      </List>
      <Dialog
        action={() => handleUpdateStatus()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to update the status?"
      />
    </>
  )
}

export default ContextChangeRequests
