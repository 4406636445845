import React from "react"

import Provider from "context/Dashboard/Attendance/main/Provider"
import View from "context/Dashboard/Attendance/main/View"

export default (): JSX.Element => (
  <Provider>
    <View />
  </Provider>
)
