import React from "react"

import UnitDetailsProvider from "context/Dashboard/UnitManagement/categories/unitDetails/Provider"
import UnitDetailsView from "context/Dashboard/UnitManagement/categories/unitDetails/View"

const UnitDetailsMain: React.FC = () => (
  <UnitDetailsProvider>
    <UnitDetailsView />
  </UnitDetailsProvider>
)

export default UnitDetailsMain
