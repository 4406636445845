import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

/** Interface */
export interface MainTableProviderProps {
  showPayment: Function
  filterActiveTable: Function
  contextActions: Record<any, any>
  activeTable: number
}

const MainTableProvider: React.FC<MainTableProviderProps> = ({
  children,
  showPayment,
  contextActions,
  filterActiveTable,
  activeTable
}) => {
  /** State */
  const [activeFilter, setActiveFilter] = React.useState(0)
  const [downloadData, setDownloadData] = useState([])

  /** Methods */
  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  return (
    <Context.Provider
      value={{
        setActiveFilter,
        filterTable,
        setDownloadData,
        showPayment,
        activeFilter,
        downloadData,
        contextActions,
        filterActiveTable,
        activeTable
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default MainTableProvider
