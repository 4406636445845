import React, { useState } from "react"
import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toUnix } from "utils/date"

function TotalFeedbackResolvedTimeHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalFeedbackLessThanAWeek = async () =>
    service.getFeedbackResolvedTimeCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "duration": "lessThan1Week"
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalFeedbackLessThanAWeek-${unixFrom}-${unixTo}`,
    fetchTotalFeedbackLessThanAWeek
  )

  const fetchTotalFeedback1to3Weeks = async () =>
    service.getFeedbackResolvedTimeCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "duration": "1to3Weeks"
    })

  const { data: oneThreeWeeks, isValidating: isValidating1to3Weeks } = useSWR(
    `fetchTotalFeedback1to3Weeks-${unixFrom}-${unixTo}`,
    fetchTotalFeedback1to3Weeks
  )

  const fetchTotalFeedbackMoreThan3Weeks = async () =>
    service.getFeedbackResolvedTimeCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "duration": "moreThan3Weeks"
    })

  const {
    data: moreThanThreeWeeks,
    isValidating: isValidatingMoreThan3Weeks
  } = useSWR(
    `fetchTotalFeedbackMoreThan3Weeks-${unixFrom}-${unixTo}`,
    fetchTotalFeedbackMoreThan3Weeks
  )

  const fetchFeedbackUnresolvedCleanliness = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 1,
      "status": 1
    })

  const {
    data: unresolvedCleanliness,
    isValidating: isValidatingCleanliness
  } = useSWR(
    `fetchFeedbackUnresolvedCleanliness-${unixFrom}-${unixTo}`,
    fetchFeedbackUnresolvedCleanliness,
    {
      revalidateOnFocus: false
    }
  )

  const fetchFeedbackUnresolvedSecurity = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 2,
      "status": 1
    })

  const { data: unresolvedSecurity, isValidating: isValidatingSecurity } = useSWR(
    `fetchFeedbackUnresolvedSecurity-${unixFrom}-${unixTo}`,
    fetchFeedbackUnresolvedSecurity,
    {
      revalidateOnFocus: true
    }
  )

  const fetchFeedbackUnresolvedDefects = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 3,
      "status": 1
    })

  const { data: unresolvedDefects, isValidating: isValidatingDefects } = useSWR(
    `fetchFeedbackUnresolvedDefects-${unixFrom}-${unixTo}`,
    fetchFeedbackUnresolvedDefects,
    {
      revalidateOnFocus: false
    }
  )

  const fetchFeedbackUnresolvedLandscape = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 4,
      "status": 1
    })

  const { data: unresolvedLandscape, isValidating: isValidatingLandscape } = useSWR(
    `fetchFeedbackUnresolvedLandscape-${unixFrom}-${unixTo}`,
    fetchFeedbackUnresolvedLandscape,
    {
      revalidateOnFocus: false
    }
  )

  const fetchFeedbackUnresolvedOthers = async () =>
    service.getFeedbackCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "feedback_type": 5,
      "status": 1
    })

  const { data: unresolvedOthers, isValidating: isValidatingOthers } = useSWR(
    `fetchFeedbackUnresolvedOthers-${unixFrom}-${unixTo}`,
    fetchFeedbackUnresolvedOthers,
    {
      revalidateOnFocus: false
    }
  )

  const totalLessThanAWeek = data?.data?._data
  const totalOneThreeWeeks = oneThreeWeeks?.data?._data
  const totalMoreThanThreeWeeks = moreThanThreeWeeks?.data?._data
  const totalUnresolvedCleanliness = unresolvedCleanliness?.data?._data?.total
  const totalUnresolvedSecurity = unresolvedSecurity?.data?._data?.total
  const totalUnresolvedDefects = unresolvedDefects?.data?._data?.total
  const totalUnresolvedLandscape = unresolvedLandscape?.data?._data?.total
  const totalUnresolvedOthers = unresolvedOthers?.data?._data?.total

  const [cleanliness, setCleanliness] = useState<any>([])
  const [security, setSecurity] = useState<any>([])
  const [defects, setDefects] = useState<any>([])
  const [landscape, setLandscape] = useState<any>([])
  const [others, setOthers] = useState<any>([])

  const CleanlinessMoreThan3Weeks =
    totalMoreThanThreeWeeks &&
    totalMoreThanThreeWeeks.filter(
      (i: any) => i?.feedback_type_name === "Cleanliness"
    )[0]

  const SecurityMoreThan3Weeks =
    totalMoreThanThreeWeeks &&
    totalMoreThanThreeWeeks.filter(
      (i: any) => i?.feedback_type_name === "Security"
    )[0]

  const DefectsMoreThan3Weeks =
    totalMoreThanThreeWeeks &&
    totalMoreThanThreeWeeks.filter(
      (i: any) => i?.feedback_type_name === "Defects"
    )[0]

  const LandscapeMoreThan3Weeks =
    totalMoreThanThreeWeeks &&
    totalMoreThanThreeWeeks.filter(
      (i: any) => i?.feedback_type_name === "Landscape"
    )[0]

  const OthersMoreThan3Weeks =
    totalMoreThanThreeWeeks &&
    totalMoreThanThreeWeeks.filter((i: any) => i?.feedback_type_name === "Others")[0]

  React.useEffect(() => {
    setCleanliness([
      totalLessThanAWeek && totalLessThanAWeek[0]?.total,
      totalOneThreeWeeks && totalOneThreeWeeks[0]?.total,
      CleanlinessMoreThan3Weeks?.total,
      totalUnresolvedCleanliness && totalUnresolvedCleanliness
    ])
    setSecurity([
      totalLessThanAWeek && totalLessThanAWeek[1]?.total,
      totalOneThreeWeeks && totalOneThreeWeeks[1]?.total,
      // totalMoreThanThreeWeeks && totalMoreThanThreeWeeks[1]?.total,
      SecurityMoreThan3Weeks?.total,
      totalUnresolvedSecurity && totalUnresolvedSecurity
    ])
    setDefects([
      totalLessThanAWeek && totalLessThanAWeek[2]?.total,
      totalOneThreeWeeks && totalOneThreeWeeks[2]?.total,
      // totalMoreThanThreeWeeks && totalMoreThanThreeWeeks[2]?.total,
      DefectsMoreThan3Weeks?.total,
      totalUnresolvedDefects && totalUnresolvedDefects
    ])
    setLandscape([
      totalLessThanAWeek && totalLessThanAWeek[3]?.total,
      totalOneThreeWeeks && totalOneThreeWeeks[3]?.total,
      // totalMoreThanThreeWeeks && totalMoreThanThreeWeeks[3]?.total,
      LandscapeMoreThan3Weeks?.total,
      totalUnresolvedLandscape && totalUnresolvedLandscape
    ])
    setOthers([
      totalLessThanAWeek && totalLessThanAWeek[4]?.total,
      totalOneThreeWeeks && totalOneThreeWeeks[4]?.total,
      // totalMoreThanThreeWeeks && totalMoreThanThreeWeeks[4]?.total,
      OthersMoreThan3Weeks?.total,
      totalUnresolvedOthers && totalUnresolvedOthers
    ])
  }, [])

  const newdata = [
    {
      name: "< 1 wks",
      cleanliness: totalLessThanAWeek && totalLessThanAWeek[0]?.total,
      security: totalLessThanAWeek && totalLessThanAWeek[1]?.total,
      defects: totalLessThanAWeek && totalLessThanAWeek[2]?.total,
      landscape: totalLessThanAWeek && totalLessThanAWeek[3]?.total,
      others: totalLessThanAWeek && totalLessThanAWeek[4]?.total
    },
    {
      name: "1-3 wks",
      cleanliness: totalOneThreeWeeks && totalOneThreeWeeks[0]?.total,
      security: totalOneThreeWeeks && totalOneThreeWeeks[1]?.total,
      defects: totalOneThreeWeeks && totalOneThreeWeeks[2]?.total,
      landscape: totalOneThreeWeeks && totalOneThreeWeeks[3]?.total,
      others: totalOneThreeWeeks && totalOneThreeWeeks[4]?.total
    },
    {
      name: "> 3 wks",
      cleanliness: CleanlinessMoreThan3Weeks?.total,
      security: SecurityMoreThan3Weeks?.total,
      defects: DefectsMoreThan3Weeks?.total,
      landscape: LandscapeMoreThan3Weeks?.total,
      others: OthersMoreThan3Weeks?.total
    },
    {
      name: "unresolved",
      cleanliness: totalUnresolvedCleanliness && totalUnresolvedCleanliness,
      security: totalUnresolvedSecurity && totalUnresolvedSecurity,
      defects: totalUnresolvedDefects && totalUnresolvedDefects,
      landscape: totalUnresolvedLandscape && totalUnresolvedLandscape,
      others: totalUnresolvedOthers && totalUnresolvedOthers
    }
  ]

  const Series = [
    {
      name: "Cleanliness",
      data: cleanliness
    },
    {
      name: "Security",
      data: security
    },
    {
      name: "Defects",
      data: defects
    },
    {
      name: "Landscape",
      data: landscape
    },
    {
      name: "Others",
      data: others
    }
  ]

  return {
    Series,
    cleanliness,
    security,
    defects,
    landscape,
    others,
    isValidating,
    isValidating1to3Weeks,
    isValidatingMoreThan3Weeks,
    isValidatingCleanliness,
    isValidatingSecurity,
    isValidatingDefects,
    isValidatingLandscape,
    isValidatingOthers,
    error,
    newdata
  }
}

export default TotalFeedbackResolvedTimeHook
