import numeral from "numeral"

export const toPercentage = (value: number, total: number): number | string => {
  if (value === null || value === undefined) {
    return "N/A"
  }

  const totalPercentage = (value * 100) / total
  return Math.ceil(totalPercentage)
}

export const toNumeral = (value: number, currency?: string): string => {
  if (value === null || value === undefined) {
    return "0.00"
  }

  if (currency !== undefined) {
    return `${currency} ${numeral(value).format("0,0.00")}`
  }

  return numeral(value).format("0,0.00")
}

export const toNumeralFixed = (value: number): string => {
  if (value === null || value === undefined) {
    return "0.00"
  }

  return numeral(value).format("0,0")
}

export const toFixed = (value: number, currency?: string): string => {
  if (currency !== undefined) {
    return `${currency} ${value?.toFixed(2)}`
  }
  return `${value?.toFixed(2)}`
}

export const arrayFromMask = (nMask: number): boolean[] => {
  let nShifted
  let aFromMask

  if (nMask > 0x7fffffff || nMask < -0x80000000) {
    throw new TypeError("arrayFromMask - out of range")
  }

  for (
    nShifted = nMask, aFromMask = [];
    nShifted;
    aFromMask.push(Boolean(nShifted & 1)), nShifted >>>= 1
  );
  return aFromMask
}

export const binaryString = (nMask: number): string => {
  let nFlag
  let nShifted
  let sMask
  for (
    nFlag = 0, nShifted = nMask, sMask = "";
    nFlag < 32;
    nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1
  );
  return sMask.charAt(sMask.length - 1)
}

export const isRefundRequested = (nMask: number): string => {
  let nFlag
  let nShifted
  let sMask
  for (
    nFlag = 0, nShifted = nMask, sMask = "";
    nFlag < 32;
    nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1
  );
  return sMask.charAt(sMask.length - 2)
}

export const isFromAvailableBalance = (nMask: number): string => {
  let nFlag
  let nShifted
  let sMask
  for (
    nFlag = 0, nShifted = nMask, sMask = "";
    nFlag < 32;
    nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1
  );
  return sMask.charAt(sMask.length - 3)
}

export function compare(a: Record<string, any>, b: Record<string, any>): number {
  const main = a.txn_id
  const other = b.txn_id
  let comparison = 0
  if (main > other) {
    comparison = 1
  } else if (main < other) {
    comparison = -1
  }
  return comparison * -1
}

export function checkValue(value: string | number, display?: any): any {
  if (value === "" || value === 0 || value === null || value === undefined) {
    return "N/A"
  }

  if (display) {
    return display
  }

  return value
}

export function checkDay(value: string | number, label = `days`): string | number {
  if (value === "" || value === 0 || value === null || value === undefined) {
    return "N/A"
  }

  return value > 1 ? `${value} ${label}` : `${value} ${label}`
}

export function checkDate(
  value: string | number,
  display: string | number
): string | number {
  return value === "" || value === 0 || value === null || value === undefined
    ? "N/A"
    : display
}

export default {
  toFixed,
  toNumeralFixed,
  arrayFromMask,
  binaryString,
  isFromAvailableBalance,
  isRefundRequested,
  compare,
  toNumeral,
  checkValue,
  checkDate
}
