import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    borderBottom: "1px solid #F0F3F7",
    padding: "10px 10px 10px 20px",
    width: "100%",
    "& i": {
      marginRight: "15px",
      paddingTop: "3px"
    }
  }
}))

export default styles
