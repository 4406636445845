import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

interface CheckBoxProps {
  label: string | null
  value: boolean
  onChange: Function
}

const CheckBox: React.FC<CheckBoxProps> = ({ label, value, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={() => onChange()}
            value={value}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  )
}
export default CheckBox
