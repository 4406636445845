import React, { useState, useEffect } from "react"
import { useMachine } from "@xstate/react"
/**
 * Component
 */
import Toast from "components/Toast"
/** Service */
import serviceSettings from "services/Settings/PropertyManagement/Facilities"
import service from "services/Dashboard/Facilities"
import { FacilitiesMachine } from "machines/Dashboard/Facilities"
import { Context } from "./Context"

const Provider = ({ children }: any) => {
  const [activeData, setActiveData] = useState<Record<string, any>>({})
  const [activeTable, setactiveTable] = useState(0)
  const [activeFilter, setActiveFilter] = useState(0)
  const [activeMenu, setActiveMenu] = useState<any>([])
  const [categories, setCategories] = useState<Array<{}>>([])
  const [facilities, setFacilities] = useState([])
  const [showDetails, setShowDetails] = useState(false)
  const [showUpdateStatus, setShowUpdateStatus] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isFacilityLoading, setFacilityLoading] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const [current, send] = useMachine(FacilitiesMachine)

  /** Machines */
  function showTable() {
    send("TABLE_VIEW")
  }

  function showAddBooking() {
    send("ADD_BOOKING")
  }

  function showEditBooking() {
    send("EDIT_BOOKING")
  }

  function showApproveBooking() {
    send("APPROVE_BOOKING")
  }

  function showUpdateStatusForm(item: Record<string, any>) {
    setShowUpdateStatus(true)
    setActiveData(item)
  }

  function showBookingDetails(item: Record<string, any>) {
    setShowDetails(true)
    setActiveData(item)
  }

  function showEditForm(item: Record<string, any>) {
    showEditBooking()
    setActiveData(item)
  }

  function showApproveForm(item: Record<string, any>) {
    showApproveBooking()
    setActiveData(item)
  }

  async function fetchCategories(): Promise<Response | null> {
    setLoading(true)
    try {
      const response = await serviceSettings.getCategories()
      const data = response?.data?._data
      const sortedData = data?.sort(
        (a: Record<string, any>, b: Record<string, any>) =>
          a.category_name.localeCompare(b.category_name)
      )
      setCategories(sortedData)
      setActiveMenu(sortedData[0])
      setLoading(false)
      return response?.data?._data
    } catch (e) {
      setLoading(false)
      return e
    }
  }

  async function fetchFacilities(categoryUID: string): Promise<Response> {
    setFacilityLoading(true)
    try {
      const response = await service.getFacilities({
        "facility_category_uid": categoryUID
      })
      const data = response?.data?._data
      setFacilities(
        data?.sort((a: Record<string, any>, b: Record<string, any>) =>
          a.name.localeCompare(b.name)
        )
      )
      setFacilityLoading(false)
      return response
    } catch (e) {
      setFacilityLoading(false)
      return e
    }
  }

  async function handleUpdateStatusBooking(payload: Record<string, any>) {
    const dataType = activeData?.type
    function dataTypeMessage(messageType: string) {
      switch (messageType) {
        case "reject":
          return "Rejected"
        case "cancel":
          return "Cancelled"
        case "noshow":
          return "No Show"
        default:
          return ""
      }
    }
    const message = `Successfully updated the facility booking status to ${dataTypeMessage(
      dataType
    )}.`

    setIsFormSubmitting(true)

    try {
      const response = await service?.updateBookingStatus(payload, dataType)
      setIsFormSubmitting(false)
      Toast(message, true)
      return response?.data?._data
    } catch (e) {
      Toast(`Error updating the status`, false)
      setIsFormSubmitting(false)
      return e
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    activeMenu?._uid !== undefined && fetchFacilities(activeMenu?._uid)
  }, [activeMenu])

  return (
    <Context.Provider
      value={{
        handleUpdateStatusBooking,
        setactiveTable,
        setActiveFilter,
        setActiveMenu,
        showAddBooking,
        showApproveForm,
        showEditForm,
        showUpdateStatusForm,
        setShowUpdateStatus,
        showTable,
        showBookingDetails,
        setShowDetails,
        activeFilter,
        activeTable,
        facilities,
        categories,
        activeMenu,
        isLoading,
        isFormSubmitting,
        showUpdateStatus,
        isFacilityLoading,
        showDetails,
        activeData,
        xValue: current?.value
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
