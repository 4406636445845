import React, { useEffect, useContext, FC } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"
import Button from "@material-ui/core/Button"

// components
import Table from "pages/Settings/AccountManagement/Admin/tables/main"
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import AccountSettings from "pages/Settings/AccountManagement/Admin/components/AccountSettings"
import FormAdmin from "pages/Settings/AccountManagement/Admin/components/Form"
import FormReset from "pages/Settings/AccountManagement/Admin/components/FormReset"
import PermissionMessage from "components/PermissionMessage"

/** Machine */
import { AdminMachine } from "machines/Settings/AccountManagement/Admin"

/** Icons */
import {
  IconAdd,
  IconSettings,
  IconSettingsDisabled
} from "components/Icons/Settings/AccountManagement"

/** Context */
import { AppContext } from "App"
import withContext from "./withContext"
import { CtxType } from "./Context"

const SettingsAdminView: FC<CtxType> = ({ activeData, showFormType, resetData }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0
  const [current, send] = useMachine(AdminMachine)
  const xValue = current?.value

  /** Views */
  function ViewTitleArea(): JSX.Element {
    return (
      <>
        <Box>
          <PageHeaderTitle
            title="Admin Account Management"
            breadcrumbs="SETTINGS / account management / admin"
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box display="flex">
            <Box marginRight="15px">
              <Button
                color="primary"
                startIcon={
                  !permission?.account_settings ? IconSettingsDisabled : IconSettings
                }
                onClick={() => send("ACCOUNT_SETTINGS")}
                disabled={!permission?.account_settings}
              >
                ADMIN ACCOUNT SETTINGS
              </Button>
            </Box>
          </Box>

          <Box display="flex">
            <Box marginRight="15px">
              <Button
                color="primary"
                startIcon={IconAdd}
                onClick={() => [showFormType(true, 1), resetData()]}
                variant="contained"
                disabled={!permission?.account_management}
              >
                ADD ADMIN
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  function ViewTable(): JSX.Element {
    if (xValue !== "tableView") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <ViewTitleArea />
        </Box>
        <WithTable>
          <Table />
        </WithTable>
      </>
    )
  }

  function ViewSettings(): JSX.Element {
    if (xValue !== "accountSettings") {
      return <div />
    }
    return (
      <>
        <AccountSettings sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  function ViewForm(): JSX.Element {
    return <FormAdmin data={activeData} />
  }

  function ViewResetForm(): JSX.Element {
    return <FormReset />
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        {ViewTable()}
        {ViewSettings()}
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.account_management) {
    return (
      <>
        {MainView()}
        {ViewForm()}
        {ViewResetForm()}
      </>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(SettingsAdminView)
