import useSWR from "swr"
import service from "services/Dashboard/Dashboard"
import { fromUnixTime } from "utils/date"

export function AttendanceToday(date: any) {
  const fetchTotal = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate
    })
  const { data, isValidating, error } = useSWR(`fetchTotal`, fetchTotal, {
    revalidateOnFocus: true
  })
  const totalEmployee = data?.data?._data?.total

  return {
    totalEmployee,
    isValidating,
    error
  }
}

export function LastTimein() {
  const fetchLastTimein = async () => service.getLastTimein()
  const { data, isValidating, error } = useSWR(`fetchLastTimein`, fetchLastTimein, {
    revalidateOnFocus: true
  })
  const lastTimein = data?.data?._data

  return {
    lastTimein: fromUnixTime(lastTimein?.attendance_date_time),
    gettingTime: isValidating,
    error
  }
}

export function AttendanceAdmin(date: any) {
  const fetchAttendanceAdmin = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 1
    })
  const { data } = useSWR(`fetchAttendanceAdmin`, fetchAttendanceAdmin, {
    revalidateOnFocus: true
  })
  const countAdmin = data?.data?._data?.total

  return {
    countAdmin
  }
}

export function AttendanceSecurity(date: any) {
  const fetchAttendanceSecurity = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 2
    })
  const { data } = useSWR(`fetchAttendanceSecurity`, fetchAttendanceSecurity, {
    revalidateOnFocus: true
  })
  const countSecurity = data?.data?._data?.total

  return {
    countSecurity
  }
}

export function AttendanceCleaning(date: any) {
  const fetchAttendanceCleaning = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 3
    })
  const { data } = useSWR(`fetchAttendanceCleaning`, fetchAttendanceCleaning, {
    revalidateOnFocus: true
  })
  const countCleaning = data?.data?._data?.total

  return {
    countCleaning
  }
}

export function AttendanceLandscape(date: any) {
  const fetchAttendanceLandscape = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 4
    })
  const { data } = useSWR(`fetchAttendanceLandscape`, fetchAttendanceLandscape, {
    revalidateOnFocus: true
  })
  const countLandscape = data?.data?._data?.total

  return {
    countLandscape
  }
}

export function AttendanceMaintenance(date: any) {
  const fetchAttendanceMaintenance = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 5
    })
  const { data } = useSWR(`fetchAttendanceMaintenance`, fetchAttendanceMaintenance, {
    revalidateOnFocus: true
  })
  const countMaintenance = data?.data?._data?.total

  return {
    countMaintenance
  }
}

export function AttendanceOthers(date: any) {
  const fetchAttendanceMaintenance = async () =>
    service.getCountAttendance({
      "start_date": date?.startDate,
      "end_date": date?.endDate,
      "user_role_category": 6
    })
  const { data } = useSWR(`fetchAttendanceMaintenance`, fetchAttendanceMaintenance, {
    revalidateOnFocus: true
  })
  const countOthers = data?.data?._data?.total

  return {
    countOthers
  }
}
