import React, { useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import BlockUnit from "components/Common/BlockUnit/new"
import Names from "components/Common/Names"
import DatePicker from "components/Forms/DatePicker"
import Select from "components/Forms/Select"
import RefButton from "components/Forms/RefButton"
/** Context */
import withContext from "context/Dashboard/Bookkeeping/forms/editPayment/withContext"
import { CtxType } from "context/Dashboard/Bookkeeping/forms/editPayment/Context"
import { refSubmit } from "context/Dashboard/Bookkeeping/forms/editPayment/View"
/** Config */
import validation from "config/Dashboard/Bookkeeping/validation"
import {
  paymentCategory,
  paymentType as paymentTypeConfig
} from "config/Dashboard/Bookkeeping"
/** Styles */
import { handleResidentName, handleBlockUnit } from "utils/functions"
import styles from "./styles"

interface Props extends CtxType {
  hasData: boolean
  setFormValid: Function
  setPaymentDetails: Function
  paymentDetails: Record<string, any>
}

const EditPayment: React.FC<Props> = ({
  setFormValid,
  paymentDetails,
  setPaymentDetails,
  hasData
}) => {
  const { section, titleStyle, twoCol } = styles()

  const {
    category,
    payeeName,
    unitUID,
    amt,
    tax,
    paymentType,
    description,
    dueDate
  } = paymentDetails

  const { addPayment } = validation

  /** Methods */
  const handleFormChange = (setFieldValue: any, key: string, e: any) => {
    return [
      setFieldValue(key, e.target.value),
      setPaymentDetails &&
        setPaymentDetails({
          ...paymentDetails,
          [key]: e.target.value
        })
    ]
  }

  const handleDateChange = (
    inputName: string,
    e: string,
    setFieldValue: Function
  ) => {
    setFieldValue(inputName, e)
    setPaymentDetails &&
      setPaymentDetails({
        ...paymentDetails,
        [inputName]: e
      })
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" margin="auto">
      <Formik
        initialValues={useMemo(() => {
          return {
            unitUID,
            payeeName,
            description,
            amt,
            tax,
            dueDate,
            category,
            paymentType
          }
        }, [])}
        onSubmit={(values: any) => {
          return values
        }}
        validationSchema={addPayment}
        isInitialValid={hasData || false}
      >
        {(formProps: any) => {
          const {
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid
          } = formProps
          return (
            <form>
              <Box display="flex" flexDirection="column" flex="1">
                <Box className={section}>
                  <Box padding="0 20%">
                    <Box
                      paddingBottom="30px"
                      borderBottom="1px solid #F2F2F2"
                      className={twoCol}
                    >
                      <Box>
                        <BlockUnit
                          iValue={unitUID}
                          onChange={(value: string) =>
                            handleBlockUnit(
                              setFieldValue,
                              value,
                              setPaymentDetails,
                              paymentDetails,
                              "unitUID",
                              "payeeAccountUID"
                            )}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.unitUID && Boolean(errors.unitUID)}
                          helperText={
                            errors.unitUID &&
                            touched.unitUID &&
                            errors.unitUID.toString()
                          }
                        />
                      </Box>

                      <Box>
                        <Names
                          value={payeeName}
                          onChange={(value: Event) =>
                            handleResidentName(
                              setFieldValue,
                              value,
                              setPaymentDetails,
                              paymentDetails,
                              "payeeName",
                              "payeeAccountUID",
                              "userDetailUid"
                            )}
                          unitUID={unitUID}
                          onBlur={(e: Event) => handleBlur(e)}
                          error={touched.payeeName && Boolean(errors.payeeName)}
                          helperText={
                            errors.payeeName && touched.payeeName && errors.payeeName
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box margin="30px 0 0 0" className={section}>
                    <Box padding="0 20%">
                      <Box marginBottom="30px" className={titleStyle}>
                        Payment Details
                      </Box>
                      <Box marginBottom="30px">
                        <FormInput
                          name="description"
                          label="payment description"
                          placeholder="Description"
                          value={description}
                          multiline
                          rows={2}
                          onBlur={(e: Event) => handleBlur(e)}
                          error={touched.description && Boolean(errors.description)}
                          helperText={
                            errors.description &&
                            touched.description &&
                            errors.description
                          }
                          handleOnChange={(e: any) =>
                            handleFormChange(setFieldValue, "description", e)}
                        />
                      </Box>
                      <Box marginBottom="30px" className={twoCol}>
                        <Box>
                          <Select
                            label="Payment Category"
                            selectName="category"
                            placeholder="Select Payment Category"
                            value={category}
                            items={paymentCategory}
                            error={touched.category && Boolean(errors.category)}
                            helperText={
                              errors.category && touched.category && errors.category
                            }
                            onChange={(e: any) =>
                              handleFormChange(setFieldValue, "category", e)}
                          />
                        </Box>

                        <Box>
                          <Select
                            label="Payment Type"
                            idValue
                            selectName="paymentType"
                            placeholder="Select Payment Type"
                            value={paymentType}
                            items={paymentTypeConfig}
                            error={
                              touched.paymentType && Boolean(errors.paymentType)
                            }
                            helperText={
                              errors.paymentType &&
                              touched.paymentType &&
                              errors.paymentType
                            }
                            onChange={(e: any) =>
                              handleFormChange(setFieldValue, "paymentType", e)}
                          />
                        </Box>
                      </Box>
                      <Box marginBottom="30px" className={twoCol}>
                        <Box>
                          <FormInput
                            type="number"
                            name="amt"
                            label="Amount"
                            placeholder="$ 00.00"
                            value={amt}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={touched.amt && Boolean(errors.amt)}
                            helperText={errors.amt && touched.amt && errors.amt}
                            handleOnChange={(e: any) =>
                              handleFormChange(setFieldValue, "amt", e)}
                          />
                        </Box>

                        {paymentType !== "3" && (
                          <Box>
                            <FormInput
                              type="number"
                              name="tax"
                              label="Tax"
                              value={tax}
                              placeholder="$ 00.00"
                              onBlur={(e: Event) => handleBlur(e)}
                              error={touched.tax && Boolean(errors.tax)}
                              helperText={errors.tax && touched.tax && errors.tax}
                              handleOnChange={(e: any) =>
                                handleFormChange(setFieldValue, "tax", e)}
                            />
                          </Box>
                        )}
                      </Box>

                      <Box className={twoCol} marginBottom="30px">
                        <Box>
                          <DatePicker
                            label="DUE DATE"
                            name="dueDate"
                            format="MM/dd/yyyy"
                            value={dueDate}
                            placeholder="mm/dd/yyyy"
                            handleDateChange={(value: string) =>
                              handleDateChange("dueDate", value, setFieldValue)}
                            onBlur={(e: Event) => handleBlur(e)}
                            error={touched.dueDate && Boolean(errors.dueDate)}
                            helperText={
                              errors.dueDate && touched.dueDate && errors.dueDate
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box>{isValid ? setFormValid(true) : setFormValid(false)}</Box>
                <RefButton refValue={refSubmit} action={handleSubmit} />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default withContext(EditPayment)
