import * as React from "react"

interface AdminProps {
  color: string
}

const Admin: React.FC<AdminProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C6.791 0 5 1.791 5 4C5 6.209 6.791 8 9 8C11.209 8 13 6.209 13 4C13 1.791 11.209 0 9 0ZM9 2C10.105 2 11 2.895 11 4C11 5.105 10.105 6 9 6C7.895 6 7 5.105 7 4C7 2.895 7.895 2 9 2ZM9 11C5.859 11 0 12.546 0 15.5V18H18V15.5C18 12.546 12.141 11 9 11ZM9 13C9.19802 13 9.39948 13.0239 9.59961 13.0352L11 15.4727L12.127 13.4297C14.2667 13.9722 16 14.9076 16 15.5V16H2V15.5C2 14.9076 3.73327 13.9722 5.87305 13.4297L7 15.4727L8.40039 13.0352C8.60052 13.0239 8.80198 13 9 13Z"
        fill={color}
      />
    </svg>
  )
}

export default Admin
