import { Machine } from "xstate"

export const VMSMachine = Machine({
  id: "vmsMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_VISITOR: "addVisitor",
        DETAILS: "details"
      }
    },
    addVisitor: {
      on: {
        BACK: "tableView"
      }
    },
    updateVisitor: {
      on: {
        BACK: "tableView"
      }
    },
    details: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_VISITOR: "addVisitor",
    UPDATE_VISITOR: "updateVisitor",
    DETAILS: "details"
  }
})
