import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** components */
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import WithTable from "layout/WithTable"
import UnitDetails from "pages/Dashboard/UnitManagement/categories/unitDetails"
/** Table */
import ActiveResidents from "pages/Dashboard/UnitManagement/tables/Residents/Active"
import ArchivedResidents from "pages/Dashboard/UnitManagement/tables/Residents/Archived"
import NewResidents from "pages/Dashboard/UnitManagement/tables/Residents/New"
import RegisteredResidents from "pages/Dashboard/UnitManagement/tables/Residents/Registered"
import ChangeRequests from "pages/Dashboard/UnitManagement/tables/Residents/ChangeRequests"
/** Form */
import CreateUserForm from "pages/Dashboard/UnitManagement/components/Common/UserForm"
/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"
/** Context */
import { CtxType } from "context/Dashboard/UnitManagement/main/Context"
import withMainContext from "context/Dashboard/UnitManagement/main/withContext"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { AppContext } from "App"
import { filterResidents } from "config/Dashboard/UnitManagement"
import { Context as SelfContext } from "./Context"

/** Config */

const ResidentsView: React.FC<CtxType> = ({ activeTableFilter }) => {
  const { ownersTab, setOwnersTab } = activeTableFilter
  const { activeView, showCreateResident, downloadData } = React.useContext(
    ParentContext
  )
  const { accountRequestData, activeData } = React.useContext(SelfContext)
  const { permission } = useContext(AppContext)

  const csvFilename = `Residents-${filterResidents[ownersTab]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <ActiveResidents />
      case 1:
        return <NewResidents />
      case 2:
        return <RegisteredResidents />
      case 3:
        return <ChangeRequests />
      case 4:
        return <ArchivedResidents />
      default:
        break
    }
    return activeTableParam
  }

  const ViewDetails = (): JSX.Element => {
    if (activeView?.details !== "unitDetailsMain") {
      return <div />
    }
    return <UnitDetails />
  }

  const ViewCreateResident = (): JSX.Element => {
    if (activeView !== "createResident") {
      return <div />
    }
    return <CreateUserForm />
  }

  const ViewCreateFromData = (): JSX.Element => {
    if (activeView !== "createFromData") {
      return <div />
    }
    return <CreateUserForm data={accountRequestData} />
  }

  const ViewEditResident = (): JSX.Element => {
    if (activeView !== "editResident") {
      return <div />
    }
    return <CreateUserForm data={activeData} />
  }

  const ViewEditOwner = (): JSX.Element => {
    if (activeView !== "editOwner") {
      return <div />
    }
    return <CreateUserForm data={activeData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }

    return (
      <WithTable>
        <TableHeader
          addActionLabel="Add New User"
          title="Residents"
          titleIcon={MenuIcons.residents}
          addAction={() => showCreateResident && showCreateResident()}
          actionDisable={permission?.unit_mgt_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={ownersTab}
            handleChange={setOwnersTab}
            options={filterResidents}
          />
        </Box>
        <Box>{TableView(ownersTab)}</Box>
      </WithTable>
    )
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewDetails()}
      {ViewCreateResident()}
      {ViewEditResident()}
      {ViewCreateFromData()}
      {ViewEditOwner()}
    </>
  )
}

export default withMainContext(ResidentsView)
