import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function encodeHeaders() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`,
    "Accept-Encoding": "gzip"
  }
}

function createVisitor(data: Object) {
  return request({
    url: API.VMS_CREATE_VISITOR,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function createDelivery(data: Object) {
  return request({
    url: API.VMS_CREATE_DELIVERY,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function createQRCode(params: Object) {
  return request({
    url: API.VMS_CREATE_QR_CODE,
    method: "GET",
    params: { "data": params },
    headers: encodeHeaders(),
    responseType: "blob"
  })
}

function createServiceProvider(data: Object) {
  return request({
    url: API.VMS_CREATE_SERVICEPROVIDER,
    method: "POST",
    data: data,
    headers: headers()
  })
}

function updateVisitor(data: Object, uid: string) {
  return request({
    url: `${API.VMS_CREATE_VISITOR}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function updateDelivery(data: Object, uid: string) {
  return request({
    url: `${API.VMS_CREATE_DELIVERY}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function updateServiceProvider(data: Object, uid: string) {
  return request({
    url: `${API.VMS_CREATE_SERVICEPROVIDER}/${uid}`,
    method: "PUT",
    data: data,
    headers: headers()
  })
}

function getVMS(params?: Record<string, number>) {
  return request({
    url: API.VMS_FETCH_ALL,
    method: "GET",
    params: { "condo_uid": Cookies.get("condoUID"), ...params },
    headers: headers()
  })
}

export function getUnits(params: any) {
  return request({
    url: API.VMS_FETCH_ALL_UNITS,
    method: "GET",
    params: params,
    headers: headers()
  })
}

function updateStatusVMS(type: number, data: Object) {
  const endpoint = (vmsType: number) => {
    switch (vmsType) {
      case 1:
        return API.VMS_UPDATE_VISITOR
      case 2:
        return API.VMS_UPDATE_DELIVERY
      case 3:
        return API.VMS_UPDATE_SERVICEPROVIDER
      default:
        return
    }
  }

  return request({
    url: endpoint(type),
    method: "POST",
    headers: {
      "Authorization": `Bearer ${Cookies.get("bearer")}`,
      "X-Device-Identifier": "web",
      "Content-Type": "application/json"
    },
    data: data
  })
}

const VmsAPI = {
  createVisitor,
  createDelivery,
  createServiceProvider,
  createQRCode,
  getVMS,
  getUnits,
  updateStatusVMS,
  updateVisitor,
  updateDelivery,
  updateServiceProvider
}

export default VmsAPI
