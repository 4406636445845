import React from "react"
import Button from "@material-ui/core/Button"

import ContextMenu from "components/ContextMenu"

import { toUnix, fromUnix, toUnixAddDay, formatDate } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import { StatusValue } from "config/Dashboard/Announcements"
import { statusDisplay } from "config/Dashboard/Announcements/helpers"
import ContextItems from "./ContextMenu"

const TableHeaderColumns = (viewFile: Function, showUpdate: Function) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} showUpdate={showUpdate} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Created by",
        id: "createdBy",
        accessor: (c: any) => checkValue(c?.created_by_account_name),
        Cell: ({ original }: any) => checkValue(original?.created_by_account_name)
      },
      {
        Header: "Title",
        id: "title",
        accessor: (c: any) => checkValue(c?.title),
        Cell: ({ original }: any) => checkValue(original?.title)
      },
      {
        Header: "Status",
        id: "status",
        accessor: (c: Record<string, any>) =>
          statusDisplay(
            toUnixAddDay(new Date().toString()),
            c?.start_date,
            toUnix(`${formatDate(c?.end_date)} 23:59:59`),
            StatusValue[c?.status]
          ),
        Cell: ({ original }: Record<string, any>) =>
          statusDisplay(
            toUnix(new Date().toString()),
            original?.start_date,
            toUnix(`${formatDate(original?.end_date)} 23:59:59`),
            StatusValue[original?.status]
          )
      },
      {
        Header: "Start Date",
        id: "startDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.start_date, fromUnix(c?.start_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.start_date, fromUnix(original?.start_date)),
        width: 150
      },
      {
        Header: "End Date",
        id: "endDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.end_date, fromUnix(c?.end_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.end_date, fromUnix(original?.end_date)),
        width: 150
      },
      {
        Header: "Attachment",
        id: "attachment",
        accessor: (c: any) =>
          checkValue(
            c?.attachment?.files[0]?.file_name,
            c?.attachment?.files[0]?.file_name
          ),
        Cell: ({ original }: any) =>
          checkValue(
            original?.attachment?.files[0]?.file_name,
            original?.attachment?.files[0]?.file_name
          )
      }
    ]
  },
  {
    fixed: "right",
    disableFilters: true,
    columns: [
      {
        Cell: ({ original }: any) =>
          checkValue(
            original?.attachment?.files[0]?.file_name,
            <Button color="primary" onClick={() => viewFile(original)}>
              View File
            </Button>
          ),
        filterable: false
      }
    ]
  }
]

export default TableHeaderColumns
