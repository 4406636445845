const version = "v1"
const base = "svc-facility"

const API = {
  // ONBOARD
  CREATE_OWNER: "svc-facility/v1/owner",
  CREATE_TENANT: "svc-facility/v1/tenant",
  UPDATE_OWNER: "svc-facility/v1/owner",
  UPDATE_TENANT: "svc-facility/v1/tenant",
  LIST_OWNERS: "svc-facility/v1/owners",
  LIST_RESIDENTS: "svc-facility/v1/residents",
  PRESIGN_URL: "svc-facility/v1/upload-url?file_type=1",
  RESIDENTS_BY_UNITID: `${base}/${version}/residents`,
  ACCOUNTS_REQUESTS: `${base}/${version}/account-requests`,
  CHANGE_ADDRESS_REQUESTS: `${base}/${version}/change-address-requests`,
  CHANGE_ADDRESS_REQUEST: `${base}/${version}/change-address-request`,
  RESET_CODE: `${base}/${version}/reset-access-code`,
  REJECT_ACCOUNT_REQUEST: `${base}/${version}/reject-account-request`,
  ARCHIVED_RESIDENT: `${base}/${version}/archive-resident`,
  LIST_ARCHIVED_RESIDENTS: `${base}/${version}/archived-residents`
}

export default API
