import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import PageHeaderTitle from "components/PageHeaderTitle"
import FilterWithInfo from "components/FilterButtons/FilterWithInfo"
import UMMain from "pages/Dashboard/UnitManagement/categories/main"
import PermissionMessage from "components/PermissionMessage"

/** Menu */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"

/** Context */
import { UnitManagementMachine } from "machines/Dashboard/UnitManagement"
import { AppContext } from "App"
import withContext from "./withContext"

const SampleFilterMenu = [
  {
    name: "accounts request",
    type: 1,
    color: "#004E8B",
    icon: MenuIcons.accountsrequests
  },
  {
    name: "owners",
    type: 2,
    color: "#007B83",
    icon: MenuIcons.owners
  },
  {
    name: "residents",
    type: 3,
    color: "#D8B469",
    icon: MenuIcons.residents
  },
  {
    name: "payment schedule",
    type: 4,
    color: "#9C6522",
    icon: MenuIcons.paymentschedule
  },
  {
    name: "vehicles",
    type: 5,
    color: "#D05611",
    icon: MenuIcons.vehicles
  },
  {
    name: "access cards",
    type: 6,
    icon: MenuIcons.accesscards,
    color: "#004E8B"
  }
]

const UMView: React.FC = ({ activeTable, setactiveTable, detailsActive }: any) => {
  const [current, send] = useMachine(UnitManagementMachine)
  const [activeData, setActiveData] = React.useState({})
  const xValue = current?.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const psPermission =
    permission?.payment_schedule_view ||
    permission?.payment_schedule_remove ||
    permission?.payment_schedule_edit ||
    permission?.payment_schedule_create
  if (hasPermission && !psPermission && SampleFilterMenu.length === 6) {
    SampleFilterMenu.splice(3, 1)
  }

  const FilterView = (): JSX.Element => {
    if (xValue !== "tableView" || detailsActive) {
      return <div />
    }

    return (
      <Box display="flex" flexWrap="wrap" padding="20px 0">
        {SampleFilterMenu.map((item: any) => {
          return (
            <FilterWithInfo
              type={item.type}
              name={item.name}
              color={item.color}
              icon={item.icon}
              action={setactiveTable}
            />
          )
        })}
      </Box>
    )
  }

  const ViewPageTitle = (): JSX.Element => {
    if (xValue === "tableView") {
      return (
        <PageHeaderTitle
          title="Unit Management"
          breadcrumbs="property management / unit management"
        />
      )
    }

    if (xValue === "createPs") {
      return (
        <PageHeaderTitle
          title="Create Payment Schedule"
          breadcrumbs="property management / unit management / create payment schedule"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editPs") {
      return (
        <PageHeaderTitle
          title="Update Payment Schedule"
          breadcrumbs="property management / unit management / update payment schedule"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (
      xValue === "createOwner" ||
      xValue === "createFromData" ||
      xValue === "createResident"
    ) {
      return (
        <PageHeaderTitle
          title="Create User"
          breadcrumbs="property management / unit management / create user"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editOwner") {
      return (
        <PageHeaderTitle
          title="Edit User"
          breadcrumbs="property management / unit management / owner"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editResident") {
      return (
        <PageHeaderTitle
          title="Edit User"
          breadcrumbs="property management / unit management / resident"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "detailsPS") {
      return (
        <PageHeaderTitle
          title="Payment Schedule Details"
          breadcrumbs="property management / unit management / payment schedule"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "createVehicle") {
      return (
        <PageHeaderTitle
          title="Create Vehicle Data"
          breadcrumbs="property management / unit management / vehicle data"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "createAccessCards") {
      return (
        <PageHeaderTitle
          title="Create Access Card"
          breadcrumbs="property management / unit management / access cards data"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editVehicle") {
      return (
        <PageHeaderTitle
          title="Update Vehicle Data"
          breadcrumbs="property management / unit management / update vehicle data"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    if (xValue === "editAccessCards") {
      return (
        <PageHeaderTitle
          title="Update Access Cards"
          breadcrumbs="property management / unit management / access cards data"
          backAction={() => send("TABLE_VIEW")}
        />
      )
    }

    return <div />
  }

  const ViewTable = (): JSX.Element => {
    return (
      <UMMain
        activeView={xValue}
        showCreateFromData={() => send("CREATE_FROM_DATA")}
        showCreateOwner={() => send("CREATE_OWNER")}
        showEditOwner={() => send("EDIT_OWNER")}
        showCreateResident={() => send("CREATE_RESIDENT")}
        showEditResident={() => send("EDIT_RESIDENT")}
        showCreateVehicle={() => send("CREATE_VEHICLE")}
        showEditVehicle={() => send("EDIT_VEHICLE")}
        showVehicleDetails={() => send("DETAILS_VEHICLE")}
        showCreateAccessCards={() => send("CREATE_ACCESS_CARDS")}
        showEditAccessCards={() => send("EDIT_ACCESS_CARDS")}
        showAccessCardsDetails={() => send("DETAILS_ACCESS_CARDS")}
        showAddPS={() => send("CREATE_PS")}
        showEditPS={(data: any): void => {
          send("EDIT_PS")
          setActiveData(data)
        }}
        showPSDetails={(data: any): void => {
          send("DETAILS_PS")
          setActiveData(data)
        }}
        showDetails={(data: any): void => {
          send("DETAILS")
          setActiveData(data)
        }}
        showUpdateUser={(data: any): void => {
          send("UPDATE_USER")
          setActiveData(data)
        }}
        showMain={() => send("TABLE_VIEW")}
        activeTable={+activeTable}
        activeData={activeData}
      />
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box>
          <ViewPageTitle />
        </Box>
        <Box>
          <FilterView />
        </Box>
        <Box>{ViewTable()}</Box>
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.unit_mgt_view) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(UMView)
