import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

// Context
import { AuthContext } from "context/auth-context"

// component
import Loader from "components/Loader"
import Header from "./Header"
import Form from "./Form"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: "412px",
    marginBottom: "40px"
  },
  forgot: {
    display: "flex",
    flexDirection: "column",
    padding: "0 28px",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontSize: "16px"
    }
  },
  copyright: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  ctaButton: {
    width: "100%",
    height: "42px",
    position: "relative"
  }
}))

const LoginCard: React.FC = () => {
  const { container, copyright, ctaButton, forgot } = useStyles()
  const { login, isLoading } = useContext(AuthContext)
  const [loginDetails, setloginDetails] = useState({
    email: "",
    pw: "",
    "device_id": "web",
    "method_type": "EMAIL_PASSWORD"
  })

  const handleChange = (key: string, value: string): void => {
    setloginDetails({
      ...loginDetails,
      [key]: value
    })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={container}>
        <Box padding="28px 28px 0">
          <Header />
          <Form loginDetails={loginDetails} handleChange={handleChange} />
        </Box>
        {/* CTA  */}
        <Box display="flex" flexDirection="column" padding="28px">
          <Box marginBottom="30px" flex="1">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(): void => login && login(loginDetails)}
              className={ctaButton}
            >
              {isLoading ? <Loader /> : "SIGN IN"}
            </Button>
          </Box>
        </Box>
        {/* Forgot Password */}
        <Box className={forgot}>
          <Link to="/forgot-password">Forgot your password?</Link>
        </Box>
      </Box>
      <Box className={copyright}>Copyright © CORA 2020</Box>
    </Box>
  )
}

export default LoginCard
