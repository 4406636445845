import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
/** Component */
import Dialog from "components/Dialog"
/** Context */
import { CtxMenuContext } from "components/ContextMenu"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/accountsRequests/Context"
import { AppContext } from "App"
/** Styles */
import { IconView, IconReject } from "components/Icons/ContextMenu"
import styles from "./styles"

/** Icons */

interface ContextNewAccountRequestsProps {
  data: Record<string, any>
}

const ContextNewAccountRequests: React.FC<ContextNewAccountRequestsProps> = ({
  data
}) => {
  const { listItem } = styles()
  const { showDetailsView, handleRejectRequest } = useContext(ParentContext)
  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = React.useContext(CtxMenuContext)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.unit_mgt_edit

  function handleShowDetails() {
    showDetailsView && showDetailsView(true, data)
  }

  function handleDialog() {
    setOpenDialog(true)
  }

  function handleReject() {
    openContext && openContext(false)
    handleRejectRequest && handleRejectRequest(data?._uid, `fetchAccountRequests`)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => handleShowDetails()}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>

        <ListItem
          className={listItem}
          button
          disabled={!canEdit}
          onClick={(): void | null => (!canEdit ? null : handleDialog())}
        >
          <i>{IconReject}</i>
          Reject
        </ListItem>
      </List>
      <Dialog
        action={() => handleReject()}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to reject this request?"
      />
    </>
  )
}

export default ContextNewAccountRequests
