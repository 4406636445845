import React from "react"

/** Context */
import { Context } from "./Context"

/** Utils */

const Provider: React.FC = ({ children }) => {
  /** States */

  return <Context.Provider value={{}}>{children}</Context.Provider>
}

export default Provider
