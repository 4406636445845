import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    width: "60%",
    margin: "auto auto 80px auto"
  },
  spaceBottom: {
    marginBottom: "25px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
    "& > div": {
      width: "48%"
    }
  }
}))

export default styles
