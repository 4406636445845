import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
/** Service */
import service from "services/Dashboard/Applications"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import withMainContext from "context/Dashboard/UnitManagement/categories/main/withContext"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import tableHeaders from "./table/tableHeaders"

interface TableAllProps {
  activeFilter: number
}

const TableAccessCardsList: React.FC<TableAllProps> = () => {
  const { setDownloadData } = useContext(ParentContext)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchUnitManagementAccessCards = async () =>
    service.getAccessCards({
      "is_archived": false
    })

  const { data, isValidating } = useSWR(
    "fetchUnitManagementAccessCards",
    fetchUnitManagementAccessCards,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data)
      : []

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData}
          columns={tableHeaders}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              values.length !== 0 &&
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Block/Unit No.": i?.blockUnitNo,
                    "Name": i?.ownerName,
                    "Serial Number": i?.serialNumber,
                    "Activation Date": i?.activationDate,
                    "Expiry Date": i?.expiryDate
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default withMainContext(TableAccessCardsList)
