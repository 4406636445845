import * as React from "react"

const TotalRegisteredUnits = (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.0996094L0 10H3V19H19V10H22L11 0.0996094ZM11 2.79102L17 8.19141V9V17H5V8.19141L11 2.79102ZM11 7C10.4696 7 9.96086 7.21071 9.58579 7.58579C9.21071 7.96086 9 8.46957 9 9C9 9.53043 9.21071 10.0391 9.58579 10.4142C9.96086 10.7893 10.4696 11 11 11C11.5304 11 12.0391 10.7893 12.4142 10.4142C12.7893 10.0391 13 9.53043 13 9C13 8.46957 12.7893 7.96086 12.4142 7.58579C12.0391 7.21071 11.5304 7 11 7ZM11 12.25C9.665 12.25 8 12.9708 8 14.3008V15H14V14.3008C14 12.9708 12.335 12.25 11 12.25Z"
      fill="#454B57"
    />
  </svg>
)

export default TotalRegisteredUnits
