import * as React from "react"

interface DocumentsProps {
  color: string
}

const Documents: React.FC<DocumentsProps> = ({ color }) => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.9 0 0 0.9 0 2V16H2V2H14V0H2ZM6 4C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V9L14 4H6ZM6 6H13V10H17V20H6V6ZM8 12V14H15V12H8ZM8 16V18H15V16H8Z"
        fill={color}
      />
    </svg>
  )
}

export default Documents
