import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  section: {
    padding: "20px 40px 10px",
    borderBottom: "1px solid #F2F2F2"
  },
  sectionHead: {
    padding: "30px 40px",
    borderBottom: "3px solid #F2F2F2"
  },
  sectionItems: {
    borderBottom: "3px solid #F2F2F2"
  },
  category: {
    fontSize: "14px"
  },
  removeLink: {
    color: "#E12323"
  },
  label: {
    color: "#454B57",
    fontSize: "10px",
    marginBottom: "4px",
    textTransform: "uppercase"
  },
  drawerOpen: {
    cursor: "pointer",
    "& button": {
      border: "0px solid",
      background: "transparent",
      outline: "none",
      cursor: "pointer"
    }
  }
}))

export default styles
