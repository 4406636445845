import React from "react"

import FeedbackProvider from "context/Dashboard/Feedback/main/FeedbackProvider"
import FeedbackView from "context/Dashboard/Feedback/main/FeedbackView"

export default () => (
  <FeedbackProvider>
    <FeedbackView />
  </FeedbackProvider>
)
