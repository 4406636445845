import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"
/** Components */
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import TableHeader from "components/TableHeader"
import PageHeaderTitle from "components/PageHeaderTitle"
import Table from "pages/Dashboard/Announcements/Post/tables"
import AddPostForm from "pages/Dashboard/Announcements/Post/Form"
import ViewFileDrawer from "pages/Dashboard/Announcements/Post/components/ViewFile"
import ViewDetails from "pages/Dashboard/Announcements/Post/components/ViewDetails"
import PermissionMessage from "components/PermissionMessage"
/** Config */
import { FilterTabs } from "config/Dashboard/Announcements"
/** Machine */
import { PostsMachine } from "machines/Dashboard/Announcements"
/** Icon */
import { IconPost } from "components/Icons/Announcements"
/** Context */
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

const View: React.FC<CtxType> = ({
  activeTable,
  activeData,
  showDetails,
  setShowDetails,
  setActiveTable,
  downloadData,
  showDocument,
  setShowDocument
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(PostsMachine)
  const xValue = current.value
  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const ViewAddPost = (): JSX.Element => {
    if (xValue !== "addPost") {
      return <div />
    }

    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Create a Post"
            breadcrumbs="property management / annoucements / posts / create a post"
            backAction={() => send("TABLE_VIEW")}
          />
        </Box>
        <AddPostForm sendBack={() => send("TABLE_VIEW")} />
      </Box>
    )
  }

  const ViewUpdatePost = (): JSX.Element => {
    if (xValue !== "updatePost") {
      return <div />
    }

    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Update Post"
            breadcrumbs="property management / annoucements / posts / update post"
            backAction={() => send("TABLE_VIEW")}
          />
        </Box>
        <AddPostForm data={activeData} sendBack={() => send("TABLE_VIEW")} />
      </Box>
    )
  }

  const ViewFile = (): JSX.Element => {
    return (
      <ViewFileDrawer
        openState={showDocument}
        setopenState={(value: boolean) => setShowDocument(value)}
        data={activeData}
      />
    )
  }

  const ViewSideDetails = (): JSX.Element => {
    return (
      <ViewDetails
        openState={showDetails}
        setopenState={(value: boolean) => setShowDetails(value)}
        data={activeData}
      />
    )
  }

  const MainView = () => {
    if (xValue !== "tableView") {
      return <div />
    }

    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <PageHeaderTitle
            title="Posts"
            breadcrumbs="property management / announcements / posts"
          />
        </Box>
        <WithTable>
          <Box>
            <TableHeader
              addActionLabel="Add New"
              title="Posts"
              titleIcon={IconPost}
              addAction={() => send("ADD_POST")}
              actionDisable={permission?.announcement_create}
              data={downloadData}
              fileName={`Announcement-post-${FilterTabs[activeTable]}.csv`}
            />
          </Box>
          <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
            <TabsFilter
              value={activeTable}
              handleChange={setActiveTable}
              options={FilterTabs}
            />
          </Box>
          <Table
            showUpdate={() => {
              send("UPDATE_POST")
            }}
            activeTable={activeTable}
          />
        </WithTable>
      </Box>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.announcement_view) {
    return (
      <>
        <div>
          <WithHeader>
            {MainView()}
            {ViewAddPost()}
            {ViewUpdatePost()}
          </WithHeader>
        </div>
        {ViewFile()}
        {ViewSideDetails()}
      </>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(View)
