/* eslint-disable @typescript-eslint/camelcase */
import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

/** Components */
import Label from "components/Forms/Label"

/** Utils */
import { fromUnix } from "utils/date"

/** Config */
import { accessCardType } from "config/Dashboard/Applications"

const styles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.secondary.dark,
    fontSize: "16px"
  },
  values: {
    minWidth: "calc(100%/3)",
    marginBottom: "20px"
  }
}))

interface AddAccessCardSummaryProps {
  info: Record<string, any>
}

const AddAccessCardSummary: React.FC<AddAccessCardSummaryProps> = ({ info }) => {
  const { value, values } = styles()
  //   const {
  //     iu_no,
  //     vehicle_model,
  //     vehicle_no,
  //     vehicle_type
  //   } = info?.request_data?.access_card
  const { start_date, end_date, serial_no, application_type } = info

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="initial"
      paddingBottom="30px"
      width="60%"
      margin="auto"
    >
      <Box className={values}>
        <Label label="Application Type" />
        <Box className={value}>{accessCardType[application_type]}</Box>
      </Box>
      {start_date !== 0 && (
        <Box className={values}>
          <Label label="Start Date" />
          <Box className={value}>{fromUnix(start_date)}</Box>
        </Box>
      )}
      {end_date !== 0 && (
        <Box className={values}>
          <Label label="End Date" />
          <Box className={value}>{fromUnix(end_date)}</Box>
        </Box>
      )}

      <Box className={values}>
        <Label label="Serial No." />
        <Box className={value}>{serial_no === "" ? "N/A" : serial_no}</Box>
      </Box>
    </Box>
  )
}
export default AddAccessCardSummary
