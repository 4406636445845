const message = {
  /** Dialog */
  bookingNotAllowed:
    "Application is disallowed according to Settings. Do you still want to proceed with Application?",
  cancelBookingNotAllowed:
    "Cancellation of Application is disallowed according to Settings. Do you still want to proceed?",
  confirmCancel: "Are you sure you want to cancel this Application?",
  expiredBookingCancel:
    "This Application has already expired. Do you want to proceed to cancel?",
  expiredBookingReject:
    "This Application has already expired. Do you want to proceed to reject?"
}

export default message
