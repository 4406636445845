import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade"
import { Link } from "react-router-dom"
/**
 * Components
 */
import Loader from "components/Loader"
/**
 * Icon
 */
import { IconPayments } from "components/Icons/Dashboard/"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Interface
 */
interface Props {
  blockTitle: string
  isLoading: boolean
  totalCount: number
  date: string | undefined
  currency: string
  hasIcon?: boolean
}

const AccountsBlock: React.FC<Props> = ({
  isLoading,
  totalCount,
  date,
  blockTitle,
  currency,
  hasIcon = false
}) => {
  const { title, body, totalStyle } = styles()

  function ViewCount() {
    if (isLoading) {
      return <Loader dark />
    }

    return (
      <Fade in={!isLoading} timeout={1000}>
        <Box alignItems="center" justifyContent="space-between">
          <Box className={totalStyle}>
            <span>{currency}</span>
            {totalCount}
          </Box>
        </Box>
      </Fade>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="20px 0"
      borderBottom="1px solid #F2F2F2"
    >
      <Box display="flex" padding="0 23px">
        {!hasIcon ? (
          <Box paddingRight="45px" />
        ) : (
          <Box paddingRight="25px">{IconPayments}</Box>
        )}
        <Box>
          <Box className={title}>{blockTitle}</Box>
          <Box className={body}>{`As of ${date}`}</Box>
          <ViewCount />

          <Button
            color="primary"
            size="large"
            component={Link}
            to="/property-management/book-keeping"
          >
            <Box fontWeight="600">VIEW ALL</Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountsBlock
