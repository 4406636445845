import { fromUnixTime } from "utils/date"
import vmsConfig from "config/Dashboard/VMS"

const TableHeaderColumns = [
  {
    Header: "",
    columns: [
      {
        Header: "Block/ Unit No.",
        accessor: "unit.short_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Name",
        accessor: "contact_person",
        filterable: false,
        sortable: false
      },
      {
        Header: "Time",
        id: "eta",
        Cell: ({ original }: Record<string, any>) => {
          return fromUnixTime(original?.eta_ms)
        },
        filterable: false,
        sortable: false
      },
      {
        Header: "Visitor's Name",
        id: "name",
        accessor: "name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Status",
        id: "status",
        Cell: ({ original }: Record<string, any>) => {
          return vmsConfig.status.visitor[original.status]
        },
        accessor: "status",
        filterable: false,
        sortable: false
      },
      {
        Header: "Entry",
        id: "numberOfPersons",
        Cell: ({ original }: Record<string, any>) => {
          return original?.no_of_persons > 1 ? "Multiple" : "Single"
        },
        filterable: false,
        sortable: false
      }
    ]
  }
]

export default TableHeaderColumns
