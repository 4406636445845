import React from "react"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"

/** Component */
import CardTitle from "components/CardTitle"
import DownloadCSV from "components/DownloadCSV"

/** Styles */
import styles from "./styles"

/** Icon */
const Icon = (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5003 0.0292969C9.53169 0.0292969 8.56371 0.3525 7.79753 1H4.77279L5.86963 7.22852C4.08039 8.91712 0.916992 12.5262 0.916992 17C0.916992 19.002 1.50018 20.5926 2.6521 21.7266L2.92912 22H18.0715L18.3486 21.7266C19.4995 20.5916 20.0837 19.002 20.0837 17C20.0837 12.5305 16.9222 8.9208 15.1329 7.23047L16.2297 1H13.2031C12.4369 0.3525 11.469 0.0292969 10.5003 0.0292969ZM10.5003 2.02344C11.1093 2.02344 11.7176 2.25334 12.1699 2.71484L12.4507 3H13.9275L13.3997 6H7.60099L7.07316 3H8.54997L8.83073 2.71484C9.28306 2.25334 9.8913 2.02344 10.5003 2.02344ZM7.95288 8H13.0496C14.0846 8.861 18.167 12.539 18.167 17C18.167 18.283 17.8759 19.269 17.2798 20H11.002V19.0879C11.2981 19.0489 12.9692 18.7627 12.9692 16.6387C12.9692 13.7137 9.94533 14.2735 9.94629 12.4785C9.94629 11.4505 10.424 11.418 10.6051 11.418C10.8371 11.418 11.3295 11.6142 11.3295 12.8672H12.9823C12.9823 10.4692 11.4109 10.097 11.1311 10.041V8.71289H10.2495V10.0176C9.95339 10.0566 8.29354 10.3817 8.29354 12.4707C8.29354 15.5187 11.3183 14.6164 11.3183 16.6504C11.3183 17.6684 10.6868 17.6934 10.5583 17.6934C10.4414 17.6934 9.67114 17.7572 9.67114 16.1992H8.01839C8.01839 18.8382 9.85285 19.0489 10.126 19.0879V20H3.71899C3.12387 19.269 2.83366 18.283 2.83366 17C2.83366 12.552 6.91788 8.863 7.95288 8Z"
      fill="#454B57"
    />
  </svg>
)

const IconDate = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13V2H5V0H3ZM2 4H16V6H2V4ZM2 8H16V18H2V8ZM10 12V16H14V12H10Z"
      fill="#09707B"
    />
  </svg>
)

interface HeaderProps {
  dates: Record<string, any>
  action: Function
  data: Array<{}> | undefined
}

const Header: React.FC<HeaderProps> = ({ dates, action, data }) => {
  const { totalSubtitle } = styles()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      borderBottom="1px solid #F2F2F2"
    >
      <CardTitle title="Total Payments Received" icon={Icon} />
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => action(true)} component="span" color="primary">
          {IconDate}
        </IconButton>
        <Box padding="0 30px 0 10px" className={totalSubtitle}>
          Date: 
          {' '}
          {`${dates.first} - ${dates.last}`}
        </Box>
        <DownloadCSV
          data={data}
          filename={`Total_Payments_Received+Refund-${dates.first}-${dates.last}.csv`}
        />
      </Box>
    </Box>
  )
}
export default Header
