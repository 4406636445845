import React, { FunctionComponent, useState, useEffect, useContext } from "react"
import { mutate } from "swr"
import { useSelector } from "react-redux"

/** Component */
import Toast from "components/Toast"
/** Context */
import service from "services/Settings/AccountManagement/Staff"
import { Context as MainContext } from "context/Settings/AccountManagement/Staff/main/Context"
import { Context } from "./Context"

/** Service */

export interface Props {
  data?: any
}

const Provider: FunctionComponent<Props> = ({ data, children }) => {
  const hasData = Object.keys(data).length !== 0 && data?.data !== ""
  const { setShowForm } = useContext(MainContext)
  const { userData } = useSelector((state: any) => state.user)
  const condoSuffix = `@${userData?.condos[0]?.username_suffix}`

  const [staffDetails, setStaffDetails] = useState({
    staffName: data?.account_name || "",
    staffUserName: data?.account_username || "",
    staffMobile: data?.account_mobile_no || "",
    methodType: "EMAIL_PASSWORD",
    staffPassword: "",
    role: data?.condo?.user_role?._uid || "",
    suffix: condoSuffix || ""
  })

  useEffect(() => {
    setStaffDetails({
      staffName: hasData ? data?.account_name : "",
      staffUserName: hasData ? data?.account_username : "",
      staffMobile: hasData ? data?.account_mobile_no : "",
      methodType: "EMAIL_PASSWORD",
      staffPassword: "",
      role: hasData ? data?.condo?.user_role?._uid : "",
      suffix: hasData ? "" : condoSuffix
    })
  }, [data])

  /**
   * Notification
   */
  const notifyCreate = (adminName: string, action: number) =>
    Toast(`Successfully ${action === 1 ? "created" : "updated"} ${adminName}.`, true)
  const notifyDuplicate = () => Toast(`Username not available.`, false)

  /** Method */
  async function handleFormSubmit() {
    const sd = staffDetails
    const payload = {
      "name": sd?.staffName,
      "mobile_no": sd?.staffMobile,
      "method_type": "EMAIL_PASSWORD",
      "user_role_uid": sd?.role,
      "account_username": sd?.staffUserName + condoSuffix,
      "account_pw": sd?.staffPassword
    }

    const updatePayload = {
      "name": sd?.staffName,
      "account_username": sd?.staffUserName,
      "mobile_no": sd?.staffMobile,
      "user_role_uid": sd?.role
    }

    const endpoint = hasData
      ? service.updateStaffUser(updatePayload, data?.account_uid)
      : service.createStaffUser(payload)

    try {
      const response = await endpoint
      if (response?.data?._statusCode === -108) {
        notifyDuplicate()
      } else {
        notifyCreate(sd?.staffName, hasData ? 2 : 1)
      }

      setShowForm && setShowForm(false)
      mutate("fetchSettingsStaff")
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        staffDetails,
        setStaffDetails,
        handleFormSubmit
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
