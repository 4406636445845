import React, { useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import DatePicker from "components/Forms/DatePicker"
import Spacer from "components/Spacer"

/** Context */
import { Context as MainContext } from "context/Dashboard/EstateMatters/PublicDocuments/forms/document/Context"

/** Validation */
import { documentInfoValidation } from "config/Dashboard/EstateMatters/PublicDocuments/validation"

/** Ref */
import { refSubmit } from "context/Dashboard/EstateMatters/PublicDocuments/forms/document/View"

interface Props {
  setIsValid: Function
}

const DocumentInfo: React.FC<Props> = ({ setIsValid }) => {
  const { docDetails, setDocDetails, hasData } = React.useContext(MainContext)
  const { title, serialNo, description, startDate, endDate } = docDetails

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setDocDetails &&
        setDocDetails({
          ...docDetails,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setDocDetails &&
        setDocDetails({
          ...docDetails,
          [name]: e?.target?.value
        })
    }
  }

  return (
    <div>
      <Box>
        <Formik
          validateOnMount
          initialValues={useMemo(() => {
            return {
              title,
              description,
              startDate,
              endDate,
              serialNo
            }
          }, [])}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={documentInfoValidation}
          isInitialValid={hasData || false}
        >
          {({
            isValid,
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          }): JSX.Element => {
            return (
              <form>
                <FormWrapper title="Document Info" width="60%">
                  <div
                    className="section"
                    style={{
                      width: "60%",
                      margin: "auto"
                    }}
                  >
                    <Box marginBottom="25px">
                      <FormInput
                        name="serialNo"
                        value={serialNo}
                        idValue="serialNo"
                        label="serialNo"
                        placeholder="Serial Number"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("serialNo", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.serialNo && Boolean(errors.serialNo)}
                        helperText={
                          errors.serialNo && touched.serialNo && errors.serialNo
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="title"
                        value={title}
                        idValue="title"
                        label="Title"
                        placeholder="Title"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("title", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={errors.title && touched.title && errors.title}
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="description"
                        value={description}
                        idValue="description"
                        label="Description"
                        placeholder="Description"
                        multiline
                        rows={3}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("description", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />
                    </Box>

                    <Box display="flex" marginBottom="25px">
                      <Box flex="1">
                        <DatePicker
                          label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          value={startDate}
                          placeholder="mm/dd/yyyy"
                          handleDateChange={(value: string): void =>
                            handleFormChange("startDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={
                            errors.startDate && touched.startDate && errors.startDate
                          }
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <DatePicker
                          label="End Date"
                          name="endDate"
                          format="MM/dd/yyyy"
                          value={endDate}
                          placeholder="mm/dd/yyyy"
                          minDate={startDate}
                          handleDateChange={(value: string): void =>
                            handleFormChange("endDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={
                            errors.endDate && touched.endDate && errors.endDate
                          }
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box>{isValid ? setIsValid(true) : setIsValid(false)}</Box>
                  <RefButton refValue={refSubmit} action={handleSubmit} />
                </FormWrapper>
              </form>
            )
          }}
        </Formik>
      </Box>
    </div>
  )
}

export default DocumentInfo
