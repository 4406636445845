import React from "react"

export type CtxType = {
  formType: number
  activeCategory: string
  activeRole: string
  activeRoleName: string
  setStaffPayload: Function
  setActiveRole: Function
  selectTab: Function
  setPayloads: Function
  setActiveRoleName: Function
  sendBack: Function
  setShowForm: Function
  showFormType: Function
  handleFormSubmit: Function
  setFormDetails: Function
  initSetup: Function
  setIsRolesUpdating: Function
  isRolesUpdating: boolean
  isFetchingRoles: boolean
  showForm: boolean
  submitting: boolean
  formDetails: any
  payloads: any
  tabValues: any
  staffPayload: any
}

export const Context = React.createContext<Partial<CtxType>>({})
