import React from "react"
import Drawer from "@material-ui/core/Drawer"

/** Components */
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"

/** Styles */
import styles from "./styles"

/** Props */
interface DrawerContainerProps {
  openState: boolean
  setOpenState: Function
  children: any
  wide?: boolean
  width?: number
  closeBtnDark?: boolean
}

const DrawerContainer: React.FC<DrawerContainerProps> = ({
  openState,
  setOpenState,
  children,
  closeBtnDark = false
}) => {
  const { drawer, content, closeBtn } = styles()
  const closeBtnColor = closeBtnDark ? "dark" : "light"

  return (
    <Drawer
      anchor="right"
      open={openState}
      onClose={() => setOpenState(false)}
      className={drawer}
      transitionDuration={400}
    >
      <Box
        component="div"
        display="flex"
        position="absolute"
        top="10px"
        right="5px"
        zIndex="2"
        bgcolor="#FFFFF"
      >
        <IconButton
          className={`${closeBtn} ${closeBtnColor}`}
          onClick={() => setOpenState(false)}
          aria-label="close"
        >
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Box component="div" className={content}>
        {children}
      </Box>
    </Drawer>
  )
}

export default DrawerContainer
