import React from "react"

/** Components */
import ContextMenu from "components/ContextMenu"
// import dateHelper from "utils/date"

/** Config */
import ContextSettingsApplications from "../../ContextMenu"

const TableHeaderColumns = (showEdit: Function) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any) => {
          return (
            <ContextMenu>
              <ContextSettingsApplications data={row.original} showEdit={showEdit} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: " ",
    columns: [
      {
        Header: "Category Name",
        id: "application_type_name",
        width: 150,
        accessor: "application_type_name"
      },
      {
        Header: "Advance Booking",
        id: "advanceBooking",
        width: 150,
        accessor: "advance_booking_days"
      },
      {
        Header: "Cancel Booking",
        id: "cancelBooking",
        width: 150,
        accessor: "cancel_booking_days"
      },
      {
        Header: "Allow Online Application",
        id: "allow_online_application",
        width: 150,
        // accessor: "allow_online_application"
        accessor: (c: Record<string, any>) => {
          return c?.allow_online_application
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.allow_online_application === true ? "Yes" : "No"
        }
      }
    ]
  },
  {
    Header: "Monday - Friday",
    columns: [
      {
        // Weekdays
        Header: "Start Time",
        id: "startTime",
        width: 150,
        accessor: (original: Record<string, any>) => {
          return original?.weekday_time_slots[0].start_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.weekday_time_slots[0].start_time === null ||
            original?.weekday_time_slots[0].start_time === ""
            ? "N/A"
            : original?.weekday_time_slots[0].start_time
        }
      },
      {
        Header: "End Time",
        id: "endTime",
        width: 150,
        // accessor: "weekday_time_slots[0].end_time"
        accessor: (c: Record<string, any>) => {
          return c?.weekday_time_slots[0].end_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.weekday_time_slots[0].end_time === null ||
            original?.weekday_time_slots[0].end_time === ""
            ? "N/A"
            : original?.weekday_time_slots[0].end_time
        }
      }
    ]
  },
  {
    Header: "Saturday",
    columns: [
      {
        // Saturdays
        Header: "Start Time",
        id: "startTime",
        width: 150,
        accessor: (original: Record<string, any>) => {
          return original?.saturday_time_slots[0].start_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.saturday_time_slots[0].start_time === null ||
            original?.saturday_time_slots[0].start_time === ""
            ? "N/A"
            : original?.saturday_time_slots[0].start_time
        }
      },
      {
        Header: "End Time",
        id: "endTime",
        width: 150,
        accessor: (original: Record<string, any>) => {
          return original?.saturday_time_slots[0].end_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.saturday_time_slots[0].end_time === null ||
            original?.saturday_time_slots[0].end_time === ""
            ? "N/A"
            : original?.saturday_time_slots[0].end_time
        }
      }
    ]
  },
  {
    Header: "Sunday",
    columns: [
      {
        // Holidays
        Header: "Start Time",
        id: "startTime",
        width: 150,
        accessor: (original: Record<string, any>) => {
          return original?.holiday_time_slots[0].start_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.holiday_time_slots[0].start_time === null ||
            original?.holiday_time_slots[0].start_time === ""
            ? "N/A"
            : original?.holiday_time_slots[0].start_time
        }
      },
      {
        Header: "End Time",
        id: "endTime",
        width: 150,
        accessor: (original: Record<string, any>) => {
          return original?.holiday_time_slots[0].end_time
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.holiday_time_slots[0].end_time === null ||
            original?.holiday_time_slots[0].end_time === ""
            ? "N/A"
            : original?.holiday_time_slots[0].end_time
        }
      }
    ]
  }
]

export default TableHeaderColumns
