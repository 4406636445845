import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "50px 0"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "48px",
    paddingBottom: "15px"
  }
}))

export default styles
