import React, { useContext, useEffect, useMemo, useState } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/facility/Context"
/** Config */
import { addFacilityDetails } from "config/Settings/PropertyManagement/Facilities/validation"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/facility/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormDetails: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const { facility, setFacility } = useContext(Context)
  const [isInitialValid, setIsInitialValid] = useState(false)

  const defaultValues = {
    facilityName: facility?.facilityName,
    description: facility?.description,
    location: facility?.location,
    capacity: facility?.capacity
  }

  useEffect(() => {
    addFacilityDetails
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false))
  }, [defaultValues])

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue(name, e?.target?.value)
    setFacility &&
      setFacility({
        ...facility,
        [name]: e?.target?.value
      })
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return defaultValues
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addFacilityDetails}
        isInitialValid={isInitialValid}
      >
        {({
          touched,
          errors,
          isValid,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper>
                  <Box className={classes.section}>
                    <Box className={classes.title}>Facility Details</Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="facilityName"
                        value={facility?.facilityName}
                        idValue="facilityName"
                        label="Facility Name"
                        placeholder="Facility Name"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange("facilityName", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.facilityName && Boolean(errors.facilityName)}
                        helperText={
                          errors.facilityName &&
                          touched.facilityName &&
                          errors.facilityName
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="description"
                        value={facility?.description}
                        idValue="description"
                        label="Description"
                        placeholder="Description"
                        rows={4}
                        multiline
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("description", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="location"
                        value={facility?.location}
                        idValue="location"
                        label="Location"
                        placeholder="Location"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("location", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.location && Boolean(errors.location)}
                        helperText={
                          errors.location && touched.location && errors.location
                        }
                      />
                    </Box>

                    <Box marginBottom="25px" width="200px">
                      <FormInput
                        type="number"
                        name="capacity"
                        value={facility?.capacity}
                        idValue="capacity"
                        label="Capacity"
                        placeholder="Capacity"
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange("capacity", e, setFieldValue)}
                        onBlur={handleBlur}
                        error={touched.capacity && Boolean(errors.capacity)}
                        helperText={
                          errors.capacity && touched.capacity && errors.capacity
                        }
                      />
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormDetails
