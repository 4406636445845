const version = "v1"
const base = "svc-attendance"

const API = {
  LIST_ATTENDANCES: `${base}/${version}/attendances`,
  CREATE_ATTENDANCE: `${base}/${version}/attendance`,
  DELETE_ATTENDANCE: `${base}/${version}/attendance`,
  UPDATE_ATTENDANCE: `${base}/${version}/attendance`
}

export default API
