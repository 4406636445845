import React from "react"

interface RefButtonProps {
  action: Function
  refValue: any
  isValid?: boolean
}

const RefButton: React.FC<RefButtonProps> = ({ action, refValue, isValid }) => {
  return (
    <div
      onClick={() => action()}
      ref={refValue}
      role="button"
      tabIndex={-1}
      data-valid={isValid}
      style={{ outline: "none", height: "1px" }}
    >
      &nbsp;
    </div>
  )
}
export default RefButton
