import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

/** Components */
import Spacer from "components/Spacer"

/** Context */
import withContext from "context/Dashboard/VMS/add-update/withContext"
import { AddUpdateCtxType } from "context/Dashboard/VMS/add-update/AddUpdateContext"

/** Styles */
import styles from "./styles"

const SelectType: React.FC<AddUpdateCtxType> = ({
  setVisitorDetails,
  visitorDetails
}) => {
  const { selectType, userOptions } = styles()

  const { vmsType } = visitorDetails

  return (
    <div className={selectType}>
      <div style={{ width: "30%", margin: "auto" }}>
        <div>
          <RadioGroup
            value={vmsType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setVisitorDetails &&
              setVisitorDetails({
                ...visitorDetails,
                vmsType: e.target.value,
                eta: ""
              })}
            className={userOptions}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Visitors"
              labelPlacement="end"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="Delivery /  Pickup"
              labelPlacement="end"
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="Service Providers"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <Spacer isDefault />
      </div>
    </div>
  )
}

export default withContext(SelectType)
