import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Image from "material-ui-image"
import Cookies from "js-cookie"
/** Components */
import Drawer from "components/Drawer"
import Header from "components/Header"

import vmsConfig from "config/Dashboard/VMS"
import Service from "services/Dashboard/VMS"
import InfoDelivery from "./Delivery"
import InfoServiceProviders from "./ServiceProviders"
import InfoVisitors from "./Visitors"
/** Styles */
import styles from "./styles"
/**
 * Config
 */

interface DetailsProps {
  openState: boolean
  data: Record<string, any>
  setopenState: Function
}

const Details: React.FC<DetailsProps> = ({ openState, setopenState, data }) => {
  const headerTitle = vmsConfig?.type[data?.vms_type]
  const classes = styles()
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (data?.vms_type) {
      const params = encodeURI(
        `condo_uid=${Cookies.get("condoUID")}&_uid=${
          data?.vms?._uid
        }&type=vms&vms_type=${data.vms_type}`
      )
      Service.createQRCode(params).then((res) => {
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(res.data)
        setUrl(imageUrl)
      })
    }
  }, [data])

  function ViewInfo(vmsType: number, items: Record<string, any>) {
    switch (vmsType) {
      case 1:
        return <InfoVisitors data={items} />
      case 2:
        return <InfoDelivery data={items} />
      case 3:
        return <InfoServiceProviders data={items} />
      default:
        return <div />
    }
  }

  return (
    <Drawer openState={openState} setopenState={setopenState}>
      <Header title={headerTitle} />
      <Box display="flex" width="100%">
        {ViewInfo(data?.vms_type, data)}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.subtitle}>QR Code</Box>
        <Image src={url} />
      </Box>
    </Drawer>
  )
}
export default Details
