import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    margin: "auto",
    width: "60%",
    padding: "0 0 30px 0"
  },
  section: {
    borderBottom: "1px solid #F2F2F2"
  },
  sectionRow: {
    display: "flex",
    marginBottom: "30px"
  },
  sectionRowLeft: {
    flex: "1",
    marginRight: "15px"
  },
  sectionRowRight: {
    flex: "1"
  }
}))

export default styles
