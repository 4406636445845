import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toNumeral } from "utils/helpers"
import { toUnix } from "utils/date"

export function TotalAmountHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalAmount = async () =>
    service.getTotalCollections({
      "start_date": unixFrom,
      "end_date": toUnix(`${date?.to} 11:59`),
      "payment_type": "1,2"
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalAmount-${unixFrom}-${unixTo}`,
    fetchTotalAmount,
    {
      revalidateOnFocus: false
    }
  )
  const amount = data?.data?._data?.amt

  return {
    totalAmount: toNumeral(amount),
    isValidating,
    error
  }
}

export function TotalAmountDepositHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalAmountDeposit = async () =>
    service.getTotalCollections({
      "start_date": unixFrom,
      "end_date": toUnix(`${date?.to} 11:59`),
      "payment_type": "2"
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalAmountDeposit-${unixFrom}-${unixTo}`,
    fetchTotalAmountDeposit,
    {
      revalidateOnFocus: false
    }
  )
  const amountDeposit = data?.data?._data?.amt

  return {
    totalDeposit: toNumeral(amountDeposit),
    isValidating,
    error
  }
}

export function TotalAmountPaymentHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)

  const fetchTotalAmountPayment = async () =>
    service.getTotalCollections({
      "start_date": unixFrom,
      "end_date": toUnix(`${date?.to} 11:59`),
      "payment_type": "1"
    })

  const { data, isValidating, error } = useSWR(
    `fetchTotalAmountPayment-${unixFrom}-${unixTo}`,
    fetchTotalAmountPayment,
    {
      revalidateOnFocus: false
    }
  )
  const amountPayment = data?.data?._data?.amt
  return {
    totalPayment: toNumeral(amountPayment),
    isValidating,
    error
  }
}
