import React from "react"

import AccountsRequestProvider from "context/Dashboard/UnitManagement/categories/accountsRequests/Provider"
import AccountsRequestView from "context/Dashboard/UnitManagement/categories/accountsRequests/View"

export default (): JSX.Element => (
  <AccountsRequestProvider>
    <AccountsRequestView />
  </AccountsRequestProvider>
)
