import * as Yup from "yup"

export const addApplication: Record<any, any> = Yup.object().shape({
  name: Yup.string().required("Required"),
  daysAdvanceBooking: Yup.string().required("Required"),
  daysCancelBooking: Yup.string().required("Required")
})

// export const addTimeSlots: Record<any, any> = Yup.object().shape({
//   weekdayStartTime: Yup.string().nullable(),
//   weekdayEndTime: Yup.string().nullable(),
//   saturdayStartTime: Yup.string().nullable(),
//   saturdayEndTime: Yup.string().nullable(),
//   holidayStartTime: Yup.string().nullable(),
//   holidayEndTime: Yup.string().nullable()
// })

export const addTimeSlots: Record<any, any> = Yup.object().shape(
  {
    weekdayStartTime: Yup.string()
      .nullable()
      .when("weekdayEndTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      }),
    weekdayEndTime: Yup.string()
      .nullable()
      .when("weekdayStartTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      }),
    saturdayStartTime: Yup.string()
      .nullable()
      .when("saturdayEndTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      }),
    saturdayEndTime: Yup.string()
      .nullable()
      .when("saturdayStartTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      }),
    holidayStartTime: Yup.string()
      .nullable()
      .when("holidayEndTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      }),
    holidayEndTime: Yup.string()
      .nullable()
      .when("holidayStartTime", {
        is: (val: any) => val === "" || val === null,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
      })
  },
  [
    ["weekdayStartTime", "weekdayEndTime"],
    ["saturdayStartTime", "saturdayEndTime"],
    ["holidayStartTime", "holidayEndTime"]
  ]
)

const validation = {
  addApplication,
  addTimeSlots
}

export default validation
