import React from "react"

export type CtxType = {
  activeItem: Record<string, string | number>
  activeTable: number
  activeStatus: number
  tabStatus: number
  setActiveStatus: Function
  filterData: Function
  filterTabStatus: Function
  handleReply: Function
  handleAssignToStaff: Function
  handleUpdateStatus: Function
  handleIsUrgent: Function
  feedbackEdit: Function
  handleView: Function
  setTabStatus: Function
  isCreate: boolean
}
export const Context = React.createContext<Partial<CtxType>>({})
