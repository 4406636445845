import * as React from "react"

const Received = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0C0.45 0 0 0.45 0 1V11C0 11.38 0.210781 11.7206 0.550781 11.8906L2 12.6191V17C2 17.55 2.45 18 3 18H5L5.66992 16H7.06055C7.02055 15.67 7 15.34 7 15C7 14.66 7.02055 14.33 7.06055 14H4V11C4 10.45 4.45 10 5 10H8.75977C9.33977 9.27 10.0398 8.65016 10.8398 8.16016H10.8496C10.8896 8.10016 10.94 8.05 11 8H6V6H12V7.58008C12.63 7.33008 13.3 7.15055 14 7.06055V6C14 4.9 13.1 4 12 4H6C4.9 4 4 4.9 4 6V8.17969C3.02 8.52969 2.27055 9.37016 2.06055 10.4102L2 10.3809V2H16V7.06055C16.7 7.15055 17.37 7.33008 18 7.58008V1C18 0.45 17.55 0 17 0H1ZM15 9C11.7 9 9 11.7 9 15C9 18.3 11.7 21 15 21C18.3 21 21 18.3 21 15C21 11.7 18.3 9 15 9ZM15 11C17.2 11 19 12.8 19 15C19 17.2 17.2 19 15 19C12.8 19 11 17.2 11 15C11 12.8 12.8 11 15 11ZM16.6133 13L14.5254 15.1641L13.3867 13.9844L12.5 14.9023L14.5254 17L17.5 13.918L16.6133 13Z"
      fill="#09707B"
    />
  </svg>
)

export default Received
