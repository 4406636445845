import React, { createContext } from "react"
import { withRouter } from "react-router-dom"
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"

import { createStyles, makeStyles } from "@material-ui/core/styles"

import NavDashboard from "../AppNav/Dashboard/VerticalNavWrapper"
import NavSettings from "../AppNav/Settings/VerticalNavWrapper"

// logo
import Logo from "../../assets/images/Cora_logo.png"

const drawerWidth = 270

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      padding: "81px 0 0 0",
      border: "none",
      borderTopRightRadius: "80px",
      background: "-webkit-linear-gradient(top,  #017c8d 12%,#053969 100%)"
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      minWidth: "270px",
      paddingLeft: "25px",
      margin: "-55px 0 20px 0"
    }
  })
)

export const SideBarContext = createContext("")

const AppSidebar: React.FC = (props: any) => {
  const { location } = props

  const activePage = location.pathname.split("/")[1]

  const { drawer, drawerPaper, logoContainer } = useStyles()
  const Nav = activePage === "property-management" ? NavDashboard : NavSettings

  return (
    <Drawer
      className={drawer}
      variant="permanent"
      classes={{
        paper: drawerPaper
      }}
      anchor="left"
    >
      <SideBarContext.Provider value={activePage}>
        <Box className={logoContainer}>
          <img src={Logo} alt="Cora" />
        </Box>
        <Nav />
        {/* <Box>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </Box> */}
      </SideBarContext.Provider>
    </Drawer>
  )
}

export default withRouter(AppSidebar)
