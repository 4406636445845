import React, { useState, useEffect } from "react"
import moment from "moment"
import useSWR from "swr"

/** Service */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"
/** Context */
import dateHelper, { fromUnix } from "utils/date"
/** Config */
import { paymentMethod, status, paymentType } from "config/Dashboard/Bookkeeping"
import { Context } from "./Context"

const Provider: React.FC = ({ children }) => {
  /** States */
  const [startDateState, setstartDateState] = useState<any>(moment().startOf("week"))
  const [endDateState, setendDateState] = useState<any>(moment().endOf("week"))
  const [focusedInput, setfocusedInput] = useState("startDate")
  const [isopenModal, setisopenModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [activeTable, setActiveTable] = useState(0)
  const [downloadDataStats, setDownloadDataStats] = useState([])

  /** Methods */
  const fetchMainAllPayments = async () => BookkeepingAPI.getPayments()
  const { data } = useSWR("fetchMainAllPayments", fetchMainAllPayments, {
    revalidateOnFocus: false
  })

  const itemsList = data?.data?._data
  const filteredItems =
    itemsList &&
    itemsList.filter(
      (i: any) =>
        i?.status_update_time?.status_changes?.slice(-1)[0]?.updated_at >=
          dateHelper.toUnix(startDateState) &&
        i?.status_update_time?.status_changes?.slice(-1)[0]?.updated_at <=
          dateHelper.toUnix(endDateState) &&
        (i?.payment_type === 1 || i?.payment_type === 2) &&
        i?.payment_status === 3
    )

  useEffect(() => {
    setDownloadDataStats(
      filteredItems &&
        filteredItems.map((i: any) => {
          return {
            "Status": status[i?.payment_status],
            "Receipt No.": i?.txn_id,
            "Block/Unit No.": i?.unit?.short_name,
            "Name": i?.payee_name,
            "Type": paymentType[i?.payment_type] || "-",
            "Category": i?.category,
            "Payment Description": i?.description,
            "Payment Date": fromUnix(i?._created),
            "Payment Method":
              i?.payment_method === 0 ? "N/A" : paymentMethod[i?.payment_method],
            "Payment Agent": i?.e_payment_agent === "" ? "N/A" : i?.e_payment_agent,
            "Payment Reference":
              i?.payment_reference === "" ? "N/A" : i?.payment_reference,
            "Amount": i?.amt,
            "Tax": i?.tax === "" ? "N/A" : i?.tax,
            "Closed By": i?.closed_by_account_name,
            "Closed Date": fromUnix(
              i?.status_update_time?.status_changes?.slice(-1)[0]?.updated_at
            )
          }
        })
    )
  }, [startDateState, endDateState, data])

  const onFocusChange = (value: any) => {
    setfocusedInput(value || "startDate")
  }

  const handleDateRange = ({ startDate, endDate }: any) => {
    setstartDateState(startDate)
    setendDateState(endDate)
  }

  const filterActiveTable = (value: number) => {
    setActiveTable(value)
  }

  function dateValue(start: any, end: any) {
    return {
      first: dateHelper.displayDate(start == null ? {} : start),
      last: dateHelper.displayDate(end == null ? {} : end)
    }
  }

  return (
    <Context.Provider
      value={{
        startDateState,
        endDateState,
        focusedInput,
        isopenModal,
        activeTable,
        downloadDataStats,
        showDetails,
        setShowDetails,
        filterActiveTable,
        setisopenModal,
        onFocusChange,
        handleDateRange,
        dateValue
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
