import React from "react"

export type CtxType = {
  activeData: any
  showForm: boolean
  setActiveData: Function
  setShowForm: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
