import React, { useState } from "react"
import { DayPickerRangeController } from "react-dates"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

const styles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    "& .DayPickerKeyboardShortcuts_buttonReset": {
      display: "none"
    }
  }
}))

interface DateRangePickerProps {
  handleDateRange: Function
  onFocusChange: Function
  handleClose: Function
  focusedInput: string
  startDate: any
  endDate: any
}

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  handleDateRange,
  handleClose,
  onFocusChange,
  focusedInput,
  startDate,
  endDate
}) => {
  const { paper } = styles()
  const [modalStyle] = useState(getModalStyle)

  return (
    <div className={paper} style={modalStyle}>
      <DayPickerRangeController
        onDatesChange={handleDateRange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        numberOfMonths={2}
        renderCalendarInfo={null}
        renderKeyboardShortcutsPanel={false}
        renderKeyboardShortcutsButton={false}
        hideKeyboardShortcutsPanel={false}
      />
      <Box display="flex" justifyContent="flex-end" padding="15px 25px">
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => handleClose()}
        >
          Confirm
        </Button>
      </Box>
    </div>
  )
}
export default DateRangePicker
