import React from "react"

import CreateUserProvider from "context/Dashboard/UnitManagement/forms/users/Provider"
import CreateUserView from "context/Dashboard/UnitManagement/forms/users/View"

interface CreateUserProps {
  data?: Record<string, any>
  successCallback?: Function
}

const CreateUser: React.FC<CreateUserProps> = ({ data, successCallback }) => {
  return (
    <CreateUserProvider data={data} successCallback={successCallback}>
      <CreateUserView />
    </CreateUserProvider>
  )
}

export default CreateUser
