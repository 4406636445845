import { Machine } from "xstate"

export const AddFeedbackMachine = Machine({
  id: "addFeedbackMachine",
  initial: "generalInfo",
  states: {
    generalInfo: {
      on: {
        NEXT: "feedback"
      }
    },
    feedback: {
      on: {
        BACK: "generalInfo"
      }
    }
  }
})
