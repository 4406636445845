import React from "react"

import EditFeedbackrovider, {
  EditFeedbackProviderProps
} from "context/Dashboard/Feedback/forms/editFeedback/Provider"
import AddUpdateView from "context/Dashboard/Feedback/forms/editFeedback/View"

const EditFeedbackMain: React.FC<EditFeedbackProviderProps> = ({
  showForm,
  activeItem
}) => {
  return (
    <EditFeedbackrovider showForm={showForm} activeItem={activeItem}>
      <AddUpdateView />
    </EditFeedbackrovider>
  )
}

export default EditFeedbackMain
