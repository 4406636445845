import React from "react"
import Box from "@material-ui/core/Box"

import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "38px",
    color: theme.palette.body.dark,
    lineHeight: "36px",
    paddingBottom: "15px"
  },
  subtitle: {
    fontSize: "16px",
    color: "#204B82",
    "& span": {
      color: theme.palette.body.dark
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    }
  }
}))

const Header: React.FC = () => {
  const { title, subtitle } = styles()
  return (
    <Box marginBottom="30px">
      <Box className={title}>Reset password</Box>
      <Box className={subtitle}>
        <span />
      </Box>
    </Box>
  )
}
export default Header
