import React from "react"

export const IconDeposit = (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00001 0.333328C5.15934 0.333328 3.66668 1.82599 3.66668 3.66666C3.66668 5.50733 5.15934 7 7.00001 7C8.84068 7 10.3333 5.50733 10.3333 3.66666C10.3333 1.82599 8.84068 0.333328 7.00001 0.333328ZM7.00001 1.66666C8.10468 1.66666 9.00001 2.562 9.00001 3.66666C9.00001 4.77133 8.10468 5.66666 7.00001 5.66666C5.89534 5.66666 5.00001 4.77133 5.00001 3.66666C5.00001 2.562 5.89534 1.66666 7.00001 1.66666ZM7.27215 2.33333L6.00652 2.85156V3.54296L6.53517 3.33854V5H7.44142V2.33333H7.27215ZM0.333344 4.33333V11.6667C0.333344 12.402 0.931343 13 1.66668 13H12.3333C13.0687 13 13.6667 12.402 13.6667 11.6667V4.33333H12.3333V11.6667H1.66668V4.33333H0.333344ZM4.33334 8.33333L7.00001 11L9.66668 8.33333H4.33334Z"
      fill="white"
    />
  </svg>
)

export const IconPayments = (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99999 0.666672C1.27128 0.666672 0.666656 1.2713 0.666656 2.00001V10C0.666656 10.7287 1.27128 11.3333 1.99999 11.3333H14C14.7287 11.3333 15.3333 10.7287 15.3333 10V2.00001C15.3333 1.2713 14.7287 0.666672 14 0.666672H1.99999ZM1.99999 2.00001H10.4766V2.6862C10.2506 2.71487 8.98436 2.9525 8.98436 4.47917C8.98436 6.7065 11.2904 6.04719 11.2904 7.53386C11.2904 8.27786 10.8102 8.29558 10.7122 8.29558C10.6229 8.29558 10.0351 8.34243 10.0351 7.20443H8.77473C8.77473 9.13243 10.1748 9.28514 10.3828 9.31381V10H1.99999V2.00001ZM11.1484 2.00001H14V10H11.0508V9.31381C11.2768 9.28514 12.5508 9.07608 12.5508 7.52474C12.5501 5.38741 10.2435 5.79571 10.2435 4.48438C10.2435 3.73305 10.6581 3.70964 10.7474 3.70964C10.9107 3.70964 11.2995 3.8542 11.2995 4.76954H12.5599C12.5599 3.01754 11.3624 2.7451 11.1484 2.70443V2.00001ZM3.33332 4V5.33334H7.33332V4H3.33332ZM3.33332 6.66667V8H6.66666V6.66667H3.33332Z"
      fill="white"
    />
  </svg>
)

export const IconRefund = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C6.46988 0 3.37511 1.83386 1.5957 4.5957L0 3V8H5L3.05469 6.05469C4.42999 3.63616 7.01934 2 10 2C14.418 2 18 5.582 18 10C18 14.418 14.418 18 10 18C9.404 18 8.81133 17.9347 8.23633 17.8047L7.79688 19.7559C8.51587 19.9179 9.257 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM9.71289 4V5.07812C9.37389 5.12113 7.47656 5.47758 7.47656 7.76758C7.47656 11.1086 10.9355 10.1206 10.9355 12.3496C10.9355 13.4656 10.2134 13.4922 10.0664 13.4922C9.93241 13.4922 9.05273 13.5635 9.05273 11.8555H7.16211C7.16211 14.7475 9.26027 14.9765 9.57227 15.0195V16H10.5742V15.0195C10.9122 14.9775 12.8242 14.6639 12.8242 12.3359C12.8242 9.12994 9.36623 9.74334 9.36523 7.77734C9.36523 6.65034 9.98514 6.61523 10.1191 6.61523C10.3641 6.61523 10.9473 6.83012 10.9473 8.20312H12.8379C12.8379 5.57613 11.0417 5.16647 10.7207 5.10547V4H9.71289ZM0 10L0.00195312 10.1582C0.0129531 10.9002 0.106297 11.6395 0.279297 12.3555L2.22461 11.8887C2.08561 11.3157 2.01095 10.722 2.00195 10.127L2 10H0ZM2.82812 13.5527L1.03711 14.4434C1.36711 15.1044 1.77028 15.7326 2.23828 16.3066L3.78906 15.0449C3.41506 14.5849 3.09212 14.0827 2.82812 13.5527ZM5.05469 16.2891L3.81641 17.8594C4.39941 18.3184 5.03222 18.7132 5.69922 19.0312L6.56055 17.2266C6.02655 16.9716 5.52069 16.6561 5.05469 16.2891Z"
      fill="white"
    />
  </svg>
)

export const IconFacilities = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 0.333328V2.33333H4V0.333328H2.66667ZM5.33333 0.333328V2.33333H6.66667V0.333328H5.33333ZM8 0.333328V2.33333H9.33333V0.333328H8ZM0.666667 2.99999V3.66666C0.666667 5.43754 1.7791 6.93693 3.38542 7.72916L0.666667 13.6667H2.11458L3.34375 11H8.64714L8.91016 11.5677C8.75221 11.7918 8.66718 12.0592 8.66667 12.3333C8.66667 12.687 8.80714 13.0261 9.05719 13.2761C9.30724 13.5262 9.64638 13.6667 10 13.6667C10.3536 13.6667 10.6928 13.5262 10.9428 13.2761C11.1929 13.0261 11.3333 12.687 11.3333 12.3333C11.3331 12.0006 11.2084 11.6801 10.9839 11.4346C10.7593 11.1891 10.4511 11.0364 10.1198 11.0065L8.60286 7.73567C10.2157 6.94502 11.3333 5.44198 11.3333 3.66666V2.99999H0.666667ZM2.16016 4.33333H9.83984C9.4643 5.8138 7.97222 7 6 7C4.02778 7 2.5357 5.8138 2.16016 4.33333ZM4.64714 8.17447C5.08184 8.27463 5.53395 8.33333 6 8.33333C6.4609 8.33333 6.90821 8.2764 7.33854 8.17838L8.02865 9.66666H3.95833L4.64714 8.17447Z"
      fill="white"
    />
  </svg>
)

export const IconApplications = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33333 0C0.604625 0 0 0.604625 0 1.33333V10.6667C0 11.3954 0.604625 12 1.33333 12H10.6667C11.3954 12 12 11.3954 12 10.6667V1.33333C12 0.604625 11.3954 0 10.6667 0H1.33333ZM1.33333 1.33333H10.6667V4H1.33333V1.33333ZM2.66667 2C2.48986 2 2.32029 2.07024 2.19526 2.19526C2.07024 2.32029 2 2.48986 2 2.66667C2 2.84348 2.07024 3.01305 2.19526 3.13807C2.32029 3.2631 2.48986 3.33333 2.66667 3.33333C2.84348 3.33333 3.01305 3.2631 3.13807 3.13807C3.2631 3.01305 3.33333 2.84348 3.33333 2.66667C3.33333 2.48986 3.2631 2.32029 3.13807 2.19526C3.01305 2.07024 2.84348 2 2.66667 2ZM4.66667 2C4.48986 2 4.32029 2.07024 4.19526 2.19526C4.07024 2.32029 4 2.48986 4 2.66667C4 2.84348 4.07024 3.01305 4.19526 3.13807C4.32029 3.2631 4.48986 3.33333 4.66667 3.33333C4.84348 3.33333 5.01305 3.2631 5.13807 3.13807C5.2631 3.01305 5.33333 2.84348 5.33333 2.66667C5.33333 2.48986 5.2631 2.32029 5.13807 2.19526C5.01305 2.07024 4.84348 2 4.66667 2ZM6.66667 2V3.33333H9.33333V2H6.66667ZM1.33333 5.33333H10.6667V10.6667H1.33333V5.33333ZM3.66667 6C3.40145 6 3.1471 6.10536 2.95956 6.29289C2.77202 6.48043 2.66667 6.73478 2.66667 7C2.66667 7.26522 2.77202 7.51957 2.95956 7.70711C3.1471 7.89464 3.40145 8 3.66667 8C3.93188 8 4.18624 7.89464 4.37377 7.70711C4.56131 7.51957 4.66667 7.26522 4.66667 7C4.66667 6.73478 4.56131 6.48043 4.37377 6.29289C4.18624 6.10536 3.93188 6 3.66667 6ZM6.66667 6.66667V8H9.33333V6.66667H6.66667ZM3.66667 8.66667C3.11067 8.66667 2 8.946 2 9.5V10H5.33333V9.5C5.33333 8.946 4.22267 8.66667 3.66667 8.66667ZM6.66667 8.66667V10H9.33333V8.66667H6.66667Z"
      fill="white"
    />
  </svg>
)

export const IconScheduled = (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0C1.8 0 0 1.8 0 4C0 6.2 1.8 8 4 8C6.2 8 8 6.2 8 4C8 1.8 6.2 0 4 0ZM4 1.33333C5.46667 1.33333 6.66667 2.53333 6.66667 4C6.66667 5.46667 5.46667 6.66667 4 6.66667C2.53333 6.66667 1.33333 5.46667 1.33333 4C1.33333 2.53333 2.53333 1.33333 4 1.33333ZM10.6667 1.33333V2.66667H9.15885C9.26885 3.09333 9.33333 3.53867 9.33333 4H12.6667V5.33333H9.15885C9.03685 5.80667 8.85392 6.254 8.61458 6.66667H12.6667L12.668 13.3333H3.33333V9.28776C2.86733 9.22976 2.42067 9.11207 2 8.94141V13.3333C2 14.0687 2.598 14.6667 3.33333 14.6667H12.6667C13.402 14.6667 14 14.0687 14 13.3333V4C14 3.26467 13.402 2.66667 12.6667 2.66667H12V1.33333H10.6667ZM4.60026 2.13281L3.46615 4.06641L4.93359 5.53385L5.53385 4.86719L4.53385 3.93359L5.33333 2.53385L4.60026 2.13281Z"
      fill="white"
    />
  </svg>
)

export const IconOthers = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66667 0.666664C3.29867 0.666664 3 0.965331 3 1.33333V1.66666V2.99479L0.994791 4.16536C0.585908 4.40377 0.333333 4.84312 0.333333 5.3164V12C0.333333 12.7287 0.937958 13.3333 1.66667 13.3333H12.3333C13.062 13.3333 13.6667 12.7287 13.6667 12V5.3164C13.6667 4.84312 13.4141 4.40377 13.0052 4.16536L11 2.99479V1.66666V1.33333C11 0.965331 10.7013 0.666664 10.3333 0.666664H10H4H3.66667ZM4.94141 2H9.05859C9.10834 2.1402 9.18874 2.26754 9.29393 2.37273C9.39912 2.47793 9.52646 2.55833 9.66667 2.60807V6.88932L8.9987 7.30468C8.99117 6.77946 8.77732 6.27827 8.40334 5.90941C8.02936 5.54055 7.52528 5.33362 7 5.33333C6.47472 5.33362 5.97064 5.54055 5.59666 5.90941C5.22268 6.27827 5.00883 6.77946 5.0013 7.30468L4.33333 6.88932V2.60807C4.47354 2.55833 4.60088 2.47793 4.70607 2.37273C4.81126 2.26754 4.89166 2.1402 4.94141 2ZM7 3.33333C6.63133 3.33333 6.33333 3.632 6.33333 4C6.33333 4.368 6.63133 4.66666 7 4.66666C7.36867 4.66666 7.66667 4.368 7.66667 4C7.66667 3.632 7.36867 3.33333 7 3.33333ZM3 4.53906V6.05989L1.73828 5.27474L3 4.53906ZM11 4.53906L12.2617 5.27474L11 6.05989V4.53906ZM1.66667 6.80078L7 10.1185L12.3333 6.80078V12H1.66667V6.80078Z"
      fill="white"
    />
  </svg>
)

export const IconOutstandingPayment = (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 0C10.119 0 9 1.119 9 2.5C9 3.881 10.119 5 11.5 5C11.6733 5 11.8425 4.98276 12.0059 4.94922C12.0056 4.96657 12 4.98259 12 5C12 7.209 13.791 9 16 9C18.209 9 20 7.209 20 5C20 2.791 18.209 1 16 1C15.2019 1 14.4621 1.23976 13.8379 1.64258C13.4869 0.68639 12.5781 0 11.5 0ZM11.5 1.75C11.914 1.75 12.25 2.086 12.25 2.5C12.25 2.914 11.914 3.25 11.5 3.25C11.086 3.25 10.75 2.914 10.75 2.5C10.75 2.086 11.086 1.75 11.5 1.75ZM16 3C17.105 3 18 3.895 18 5C18 6.105 17.105 7 16 7C14.895 7 14 6.105 14 5C14 3.895 14.895 3 16 3ZM5 8C4.709 8 4.43397 8.05386 4.16797 8.13086L0 9.10742V16.3086L4 15L12.1562 17.8535C12.4213 17.9465 12.6967 17.9922 12.9707 17.9922C13.3727 17.9922 13.7728 17.8952 14.1348 17.7012L21.4629 13.7832L20.2852 11.2676L13.1211 14.8809L12.4883 14.6953L13.0078 13.4277C13.4628 12.4127 13.0024 11.2204 11.9824 10.7754L6.11523 8.2207C5.76923 8.0817 5.395 8 5 8ZM5 10C5.114 10 5.23161 10.0224 5.34961 10.0664L11.1816 12.6074L11.168 12.6387L11.1543 12.6699L10.6367 13.9375L10.1855 15.0449L4.66016 13.1113L4.02148 12.8887L3.37891 13.0977L2 13.5508V10.6934L4.62305 10.0781L4.67578 10.0664L4.72461 10.0527C4.84461 10.0187 4.937 10 5 10Z"
      fill="white"
    />
  </svg>
)
