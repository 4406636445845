import React, { useState } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"
/** Context */
import Service from "services/Dashboard/UnitManagement"
import { Context } from "./Context"
/** Service */

const AccountRequestProvider: React.FC = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [accountRequestData, setAccountRequestData] = useState({})
  const [showSideDetails, setShowSideDetails] = useState(false)

  /** Notification */
  const notifyReject = () =>
    toast("Successfully rejected the request.", {
      type: toast.TYPE.SUCCESS
    })

  async function handleRejectRequest(
    itemId: string,
    apiName: string
  ): Promise<[] | string> {
    try {
      const response = await Service.rejectAccountRequest(itemId)
      notifyReject()
      mutate(apiName)
      return response
    } catch (e) {
      return e
    }
  }

  function showDetailsView(status: boolean, data: Record<string, any>) {
    setShowSideDetails(status)
    setAccountRequestData(data)
  }

  return (
    <Context.Provider
      value={{
        handleRejectRequest,
        setOpenDialog,
        setAccountRequestData,
        setShowSideDetails,
        showDetailsView,
        showSideDetails,
        openDialog,
        accountRequestData
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AccountRequestProvider
