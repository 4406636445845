import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

interface DialogProps {
  action: Function
  setOpen: Function
  actionLabel: string
  isOpen: boolean
  title: string
  message?: string
  noAction?: boolean
}

const DialogMessage: React.FC<DialogProps> = ({
  action,
  actionLabel,
  isOpen,
  setOpen,
  title,
  message,
  noAction = false
}) => {
  const handleAction = () => {
    action && action()
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "0 24px 24px" }}>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          className="btn-dialog-cancel"
        >
          Cancel
        </Button>
        {!noAction && (
          <Button
            onClick={handleAction}
            color="primary"
            variant="contained"
            className="btn-dialog-action"
          >
            {actionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default DialogMessage
