import React from "react"
import Box from "@material-ui/core/Box"

interface StatusBlockProps {
  status: string
  color: any
}

const StatusBlock: React.FC<StatusBlockProps> = ({ status, color }) => {
  return (
    <Box
      color="#FFFFFF"
      padding="5px 20px"
      borderRadius="20px"
      bgcolor={color}
      style={{ backgroundColor: color }}
    >
      {status}
    </Box>
  )
}

export default StatusBlock
