const version = "v1"
const base = "svc-facilities"

const root = `${base}/${version}`

const API = {
  CREATE_CATEGORY: `${root}/facility-category`,
  UPDATE_CATEGORY: `${root}/facility-category`,
  REMOVE_CATEGORY: `${root}/facility-category`,
  LIST_CATEGORIES: `${root}/facility-categories`,
  LIST_SETTINGS: `${root}/facility-settings`,
  CREATE_SETTING: `${root}/facility-setting`,
  REMOVE_SETTING: `${root}/facility-setting`,
  LIST_FACILITIES: `${root}/facilities`,
  CREATE_FACILITY: `${root}/facility`,
  UPDATE_FACILITY: `${root}/facility`,
  REMOVE_FACILITY: `${root}/facility`,
  GET_FACILITY: `${root}/facility`,
  TIME_SLOTS: `${root}/facility-time-slots`
}

export default API
