import React, { useContext, useState } from "react"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"

/** Component */
import CardLayout from "components/Card"
import Dialog from "components/Dialog"
import Loader from "components/Loader"

/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/categories/facilities/Context"
import { Context as MainContext } from "context/Settings/PropertyManagement/Facilities/main/Context"

import { fromUnix } from "utils/date"
import NoFacilityView from "./NoFacilityView"

import styles from "./styles"

const FacilitiesList: React.FC = () => {
  const classes = styles()

  const { facilitiesList, removeFacility, isValidating } = useContext(Context)
  const { showUpdateFacility, showAddFacility } = useContext(MainContext)

  const [openDialog, setOpenDialog] = useState(false)
  const [activeUID, setActiveUID] = useState("")

  const hasFacilities =
    facilitiesList !== undefined &&
    facilitiesList !== null &&
    facilitiesList?.length !== 0

  function handleRemove(itemUID: string): void {
    setActiveUID(itemUID)
    setOpenDialog(true)
  }

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={2000}>
      <Box className={hasFacilities ? classes?.container : ""}>
        {hasFacilities ? (
          facilitiesList?.map((item: any) => {
            const isOpen = item?.status === 1
            const suspendedDates = !isOpen
              ? `${fromUnix(item?.suspended_start_date)} - ${fromUnix(
                  item?.suspended_end_date
                )}`
              : null

            return (
              <CardLayout
                name={item?.name}
                status={isOpen ? "Open" : "Suspended"}
                suspendedDates={suspendedDates}
                description={item?.description}
                images={item?.facility_photo?.photos}
                editAction={() => showUpdateFacility && showUpdateFacility(item)}
                removeAction={() => handleRemove(item?._uid)}
              />
            )
          })
        ) : (
          <NoFacilityView action={() => showAddFacility && showAddFacility()} />
        )}
        <Dialog
          action={() => removeFacility && removeFacility(activeUID)}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="Confirm"
          title=""
          message="Are you sure you want to remove this facility?"
        />
      </Box>
    </Fade>
  )
}
export default FacilitiesList
