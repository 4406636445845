import Dashboards from "pages/Dashboard/Dashboards"
import AnnouncementsPost from "pages/Dashboard/Announcements/Post"
import Applications from "pages/Dashboard/Applications"
import Reports from "pages/Dashboard/Reports"
import Attendance from "pages/Dashboard/Attendance"
import Bookkeeping from "pages/Dashboard/Bookkeeping"
import Facilities from "pages/Dashboard/Facilities/"
import AddBookingForm from "pages/Dashboard/Facilities/pages/AddBookingForm"
import Feedback from "pages/Dashboard/Feedback"
import UnitManagement from "pages/Dashboard/UnitManagement"
import VisitorManagement from "pages/Dashboard/VisitorManagement"
import EstateMatters from "pages/Dashboard/EstateMatters/"
import PublicDocuments from "pages/Dashboard/EstateMatters/PublicDocuments"
import ManagementDocuments from "pages/Dashboard/EstateMatters/ManagementDocuments"
import ConstructionManuals from "pages/Dashboard/EstateMatters/ConstructionManuals"

const DashboardRoute = [
  {
    route: "/property-management/dashboards",
    component: Dashboards
  },
  {
    route: "/property-management/attendance",
    component: Attendance
  },
  {
    route: "/property-management/announcements/posts",
    component: AnnouncementsPost
  },
  {
    route: "/property-management/reports",
    component: Reports
  },
  {
    route: "/property-management/applications",
    component: Applications
  },
  {
    route: "/property-management/book-keeping",
    component: Bookkeeping
  },
  {
    route: "/property-management/facilities",
    component: Facilities
  },
  {
    route: "/property-management/facilities/add-new-booking",
    component: AddBookingForm
  },
  {
    route: "/property-management/feedback",
    component: Feedback
  },
  {
    route: "/property-management/unit-management",
    component: UnitManagement
  },
  {
    route: "/property-management/visitor-management",
    component: VisitorManagement
  },
  {
    route: "/property-management/estate-matters",
    component: EstateMatters
  },
  {
    route: "/property-management/estate-matters/public-documents",
    component: PublicDocuments
  },
  {
    route: "/property-management/estate-matters/construction-and-manuals",
    component: ConstructionManuals
  },
  {
    route: "/property-management/estate-matters/management-documents",
    component: ManagementDocuments
  }
]

export default DashboardRoute
