import React from "react"
import Box from "@material-ui/core/Box"

/** Context */
import { Context as MainContext } from "context/Dashboard/EstateMatters/PublicDocuments/forms/document/Context"

/** Components */
import Upload from "./Upload"
import SelectCategory from "./SelectCategory"

const ResidentInfo: React.FC = () => {
  const { docDetails, setDocDetails, filterTabs } = React.useContext(MainContext)
  const { estateMatterFolder } = docDetails

  /** Methods */

  function handleSelectCategory(e: React.ChangeEvent<HTMLInputElement>) {
    return (
      setDocDetails &&
      setDocDetails({
        ...docDetails,
        estateMatterFolder: e?.target?.value
      })
    )
  }

  return (
    <Box display="flex">
      <Box flex="1" padding="15px 30px 30px 30px" borderRight="1px solid #F2F2F2">
        <SelectCategory
          options={filterTabs}
          value={estateMatterFolder}
          action={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSelectCategory(e)}
        />
      </Box>
      <Box flex="2" padding="15px 50px 30px 50px">
        <Upload />
      </Box>
    </Box>
  )
}

export default ResidentInfo
