import * as React from "react"

const Others: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2V18C0 19.1016 0.898438 20 2 20H18C19.1016 20 20 19.1016 20 18V2C20 0.898438 19.1016 0 18 0H2C0.898438 0 0 0.898438 0 2ZM15 7H5V5H15V7ZM15 11H5V9H15V11ZM12 15H5V13H12V15Z"
        fill="white"
      />
    </svg>
  )
}

export default Others
