import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "50px 0",
    height: "calc(100vh - 150px)"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "48px",
    lineHeight: "56px",
    padding: "30px 0 10px"
  },
  subtitle: {
    color: theme.palette.body.gray,
    fontSize: "14px"
  }
}))

export default styles
