import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  section: {
    padding: "30px"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 30px",
    borderBottom: "1px solid #F2F2F2"
  },
  block: {
    marginBottom: "17px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  title: {
    fontSize: "16px",
    lineHeight: "28px",
    marginBottom: "12px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "17px",

    "& > div": {
      width: "50%"
    },
    "&:last-child": {
      marginBottom: "0"
    }
  }
}))

export default styles
