import useSWR from "swr"
import service from "services/Dashboard/Applications"

interface Props {
  dataID: string
}

function RegisteredVehiclesHook(dataID: string): Record<string, any> {
  const fetchVehiclesPerUnit = async () =>
    service.getVehicles({
      "unit_uid": dataID,
      "is_archived": false
    })

  const { data, isValidating, error } = useSWR(
    `fetchVehiclesPerUnit-${dataID}`,
    fetchVehiclesPerUnit,
    {
      revalidateOnFocus: false
    }
  )

  return {
    tableData: data?.data?._data,
    isValidating,
    error
  }
}

export default RegisteredVehiclesHook
