import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

import { CondoUID } from "config/Common/CondoInfo"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getBookkeeping() {
  return request({
    url: API.LIST_BOOKKEEPING,
    method: "GET",
    params: {
      "condo_uid": CondoUID
    },
    headers: headers()
  })
}

function updateBookkeeping(data: Record<string, any>, uid: string) {
  return request({
    url: `${API.UPDATE_BOOKKEEPING}/${uid}`,
    method: "PUT",
    headers: headers(),
    data: data
  })
}

const BookkeepingSettings = {
  getBookkeeping,
  updateBookkeeping
}

export default BookkeepingSettings
