import { Machine } from "xstate"

export const AddBookingMachine = Machine({
  id: "addBooking",
  initial: "bookingDetails",
  states: {
    bookingDetails: {
      on: {
        NEXT: "selectFacility"
      }
    },
    selectFacility: {
      on: {
        BACK: "bookingDetails",
        NEXT: "payment"
      }
    },
    payment: {
      on: {
        BACK: "selectFacility",
        NEXT: "confirmation"
      }
    },
    confirmation: {
      on: {
        BACK: "payment"
      }
    }
  }
})
