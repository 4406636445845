import React from "react"

export type CtxType = {
  activeDate: any
  selectedDate: any
  setActiveDate: Function
  setSelectedDate: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
