import * as React from "react"

const Office = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.36239 0C7.20756 0 5.46048 1.791 5.46048 4C5.46048 6.209 7.20756 8 9.36239 8C11.5172 8 13.2643 6.209 13.2643 4C13.2643 1.791 11.5172 0 9.36239 0ZM9.36239 2C10.4403 2 11.3133 2.895 11.3133 4C11.3133 5.105 10.4403 6 9.36239 6C8.28449 6 7.41144 5.105 7.41144 4C7.41144 2.895 8.28449 2 9.36239 2ZM9.36239 11C6.29842 11 0.583099 12.546 0.583099 15.5V18H18.1417V15.5C18.1417 12.546 12.4264 11 9.36239 11ZM9.36239 13C9.55555 13 9.75207 13.0239 9.9473 13.0352L11.3133 15.4727L12.4127 13.4297C14.5 13.9722 16.1907 14.9076 16.1907 15.5V16H2.53405V15.5C2.53405 14.9076 4.22482 13.9722 6.31212 13.4297L7.41144 15.4727L8.77749 13.0352C8.97271 13.0239 9.16923 13 9.36239 13Z"
      fill="#454B57"
    />
  </svg>
)

export default Office
