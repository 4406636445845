import * as React from "react"

const MovingInOut: React.FC = () => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.85631 0C1.79077 0 0.927734 0.895 0.927734 2V13H2.85631C2.85631 14.657 4.15134 16 5.74916 16C7.34699 16 8.64202 14.657 8.64202 13H13.4635H14.4277C14.4277 14.657 15.7228 16 17.3206 16C18.9184 16 20.2135 14.657 20.2135 13H21.1777H22.142V8L19.5147 2.55273C19.3518 2.21373 19.0195 2 18.654 2H15.392V0H2.85631ZM2.85631 2H13.4635V6V8V11H7.89056C7.36109 10.3907 6.60248 10 5.74916 10C4.89585 10 4.13724 10.3907 3.60777 11H2.85631V2ZM15.392 4H18.057L19.0213 6H15.392V4ZM15.392 8H19.9856L20.2135 8.47266V9V10.3574V11H19.462C18.9325 10.3907 18.1739 10 17.3206 10C16.5761 10 15.9047 10.3002 15.392 10.7793V9.53711V9V8ZM5.74916 11.75C6.41356 11.75 6.95452 12.311 6.95452 13C6.95452 13.689 6.41356 14.25 5.74916 14.25C5.08477 14.25 4.54381 13.689 4.54381 13C4.54381 12.311 5.08477 11.75 5.74916 11.75ZM17.3206 11.75C17.985 11.75 18.526 12.311 18.526 13C18.526 13.689 17.985 14.25 17.3206 14.25C16.6562 14.25 16.1152 13.689 16.1152 13C16.1152 12.311 16.6562 11.75 17.3206 11.75Z"
        fill="white"
      />
    </svg>
  )
}

export default MovingInOut
