import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    padding: "50px 0 0 0",
    margin: "30px 0 0 0",
    overflow: "auto",
    // borderTop: "1px solid #F2F2F2",
    "&.isFirstCategory": {
      borderTop: "1px solid transparent",
      padding: "0",
      margin: "0"
    }
  },
  category: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 400,
    color: theme.palette.body.main
  },
  facilityGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(315px, 315px))",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    padding: "20px 0"
  }
}))

export default styles
