import { SET_USER_ROLES } from "../constants"

const initialState = {}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER_ROLES:
      return {
        ...action.roles
      }
    default:
      return state
  }
}
