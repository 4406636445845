import React from "react"

import Provider, {
  ProviderProps
} from "context/Dashboard/EstateMatters/ManagementDocuments/forms/document/Provider"
import View from "context/Dashboard/EstateMatters/ManagementDocuments/forms/document/View"

const DocumentForm: React.FC<ProviderProps> = ({ sendBack, data }) => (
  <Provider sendBack={sendBack} data={data}>
    <View />
  </Provider>
)

export default DocumentForm
