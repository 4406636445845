import React from "react"
import Box from "@material-ui/core/Box"
import useSWR from "swr"
import Cookie from "js-cookie"

/** Component */

/** API */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Context */
import dateHelper from "utils/date"
import { toNumeral } from "utils/helpers"

/** Styles */
import styles from "../styles"

/** Icons */
import { IconDeposit } from "../icons"

interface TotalPaymentsProps {
  dates: any
  currency: string
}

const TotalPayments: React.FC<TotalPaymentsProps> = ({ dates, currency }) => {
  const { section, type, amount, iconContainer } = styles()

  const fetchTotalPaymentsDepositStats = async () =>
    BookkeepingAPI.getTotalCollections({
      "condo_uid": Cookie.get("condoUID"),
      "start_date": `${dateHelper.toUnix(dates.first)}`,
      "end_date": `${dateHelper.toUnix(dates.last)}`,
      "payment_type": "2"
    })

  const { data } = useSWR(
    "fetchTotalPaymentsDepositStats",
    fetchTotalPaymentsDepositStats,
    {
      revalidateOnFocus: true
    }
  )

  const oPayments = data?.data?._data !== undefined && data?.data?._data

  return (
    <Box className={section}>
      <Box display="flex" alignItems="center">
        <Box className={iconContainer} bgcolor="#007B83">
          {IconDeposit}
        </Box>
        <Box className={type}>Deposits</Box>
      </Box>
      <Box>
        <Box className={amount}>
          <small>{currency}</small> 
          {' '}
          {toNumeral(oPayments?.amt)}
        </Box>
      </Box>
    </Box>
  )
}
export default TotalPayments
