import { Machine } from "xstate"

export const AddVmsMachine = Machine({
  id: "addvmsmachine",
  initial: "selecttype",
  states: {
    selecttype: {
      on: {
        NEXT: "visitorsinfo"
      }
    },
    visitorsinfo: {
      on: {
        NEXT: "requestersinfo",
        BACK: "selecttype"
      }
    },
    requestersinfo: {
      on: {
        BACK: "visitorsinfo"
      }
    }
  }
})
