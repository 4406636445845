import Moment from "moment"
import momentTZ from "moment-timezone"

export function convertDate(value: Date): string {
  const formattedDate = `${value?.getMonth()} ${value?.getDate()} ${value?.getFullYear()}`
  return formattedDate
}

export function displayDate(value: Date, format?: string): string {
  if (format === undefined) {
    return Moment(value).format("MM/DD/YYYY")
  }

  return Moment(value).format(format)
}

export function formatDate(value: Date | string): string {
  return Moment(value).format("YYYY-MM-DD")
}

export function formatTime(value: string | null): string | null {
  if (value === null) {
    return null
  }
  return Moment(value).format("HH:mm")
}

export function formatDateTime(value: Date): string {
  return Moment(value).format("YYYY-MM-DD HH:MM:SS")
}

export function toUnix(value: string | number | Date): number {
  return Moment(value).valueOf()
}

export function toUnixDateOnly(value: string): number | string {
  const main = Moment(value).format("MMM DD YYYY")

  return Moment(main).valueOf()
}

export function toUnixTomorrow(value: string, numDays: number): number | string {
  return Moment(toUnixDateOnly(value))
    .add(numDays, "days")
    .valueOf()
}

export function toUnixAddDay(value: string): number | string {
  return Moment(value)
    .add(24, "hours")
    .valueOf()
}

export function toUnixTimeRange(value: any, time: string) {
  return toUnix(Moment(value).format(`MMMM DD, YYYY ${time}`))
}

export function fromUnix(value: Date): string {
  return Moment(value).format("DD MMM YYYY")
}

export function fromUnixTime(
  value: Date,
  hasMeridian?: boolean,
  tFormat = "HH:mm"
): string {
  if (!hasMeridian) {
    return Moment(value).format(tFormat)
  }

  return Moment(value).format(`${tFormat} A`)
}

export function fromUnixTimeTZ(
  value: Date,
  timezone: string,
  hasMeridian?: boolean,
  tFormat = "HH:mm"
): string {
  if (!hasMeridian) {
    return momentTZ.tz(value, timezone).format(tFormat)
  }

  return momentTZ.tz(value, timezone).format(`${tFormat} A`)
}

export function fromUnixDateTime(value: Date): string {
  return Moment(value).format("MMM DD YYYY h:mm A")
}

export function diffDays(value: Date): number {
  const before = Moment(value).format("YYYY-MM-DD")
  const numDays = Moment().diff(before, "days")
  return numDays
}

export function dateToday(): string {
  return Moment(new Date()).format("MMMM DD, YYYY")
}

export function dateRawToday(): Record<string, any> {
  return Moment(new Date())
    .set("hour", 0)
    .set("minute", 0)
}

export function dateAdvance(days: number): Record<string, any> {
  return Moment(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  )
    .add(days, "days")
    .set("hour", 0)
    .set("minute", 0)
}

export function dateIsAfter(date: any, toDate: Record<string, any>): boolean {
  if (toDate === null) {
    return true
  }

  return Moment(date).isAfter(toDate)
}

export function dateSameOrAfter(
  date: Record<string, any> | string,
  toDate: Record<string, any> | string
): boolean {
  const tYear = Moment(toDate).year()
  const tMonth = Moment(toDate).month()
  const tDate = Moment(toDate).date()

  const dYear = Moment(date).year()
  const dMonth = Moment(date).month()
  const dDate = Moment(date).date()

  const dFull = Moment(`${dYear}-${dMonth + 1}-${dDate}`)
  const tFull = Moment(`${tYear}-${tMonth + 1}-${tDate}`)

  if (toDate === null) {
    return true
  }

  if (dFull === tFull) {
    return true
  }

  return dFull.isSameOrAfter(tFull)
}

export function dayName(dateTime: string | Record<string, any>) {
  return Moment(dateTime).format("dddd")
}

export function timeFormat(
  dateTime: string,
  startTime: string,
  endTime: string
): boolean | any {
  const dtHour = Moment(dateTime).hour()
  const dtMinutes = Moment(dateTime).minutes()

  if (startTime === "" && endTime === "") {
    return false
  }

  if (startTime === "00:00:00" && endTime === "00:00:00") {
    return true
  }

  const from = startTime.split(":")
  const to = endTime.split(":")

  if (
    Moment(dateTime).format("HH") === from[0] &&
    Moment(dateTime).format("mm") === from[1]
  ) {
    return true
  }

  if (
    Moment(dateTime).format("HH") === to[0] &&
    Moment(dateTime).format("mm") === to[1]
  ) {
    return true
  }

  return Moment({ hour: dtHour, minute: dtMinutes }).isBetween(
    Moment({ hour: Number(from[0]), minute: Number(from[1]) }),
    Moment({ hour: Number(to[0]), minute: Number(to[1]) })
  )
}

export function isExpired(etams: number) {
  const today = Moment(new Date()).valueOf()
  return today > etams
}

export function dateDefaultTime(date: string, forStart = true) {
  const Year = Moment(date).year()
  const Month = Moment(date).month()
  const Date = Moment(date).date()

  const start = `00:00:00`
  const end = `23:59:59`

  const dayTime = forStart ? start : end

  return Moment(`${Year} ${Month + 1} ${Date} ${dayTime}`).valueOf()
}

/** Facilities settings */
export function checkTime(time: string): boolean | any {
  const dtHour = Moment(time, "HH:mm").hour()
  const dtMinutes = Moment(time, "HH:mm").minutes()

  const activeLastHour = new Date(`Jan 01 2020 23:59:59`)

  const activeTime = new Date(
    `Jan 01 2020 ${dtHour}:${dtMinutes === 0 ? dtMinutes + 0 : dtMinutes}:00`
  )

  return {
    isZero: dtHour === 0o0 && dtMinutes === 0o0,
    activeLastHour,
    activeTime
  }
}

export function checkValidTimeRange(
  timeFrom: string | null,
  timeTo: string | null
): boolean | any {
  const fromHour = Moment(timeFrom, "HH:mm").hour()
  const fromMinutes = Moment(timeFrom, "HH:mm").minutes()

  const toHour = Moment(timeTo, "HH:mm").hour()
  const toMinutes = Moment(timeTo, "HH:mm").minutes()

  const fromTime = new Date(
    `Jan 01 2020 ${fromHour}:${fromMinutes === 0 ? fromMinutes + 0 : fromMinutes}:00`
  )

  const toTime = new Date(
    `Jan 01 2020 ${toHour}:${toMinutes === 0 ? toMinutes + 0 : toMinutes}:00`
  )

  if (fromTime >= toTime) {
    return false
  }

  return true
}

export function checkDateBetween(
  dateValue: Date | any,
  dateFrom: Date,
  dateTo: Date
) {
  const vYear = Moment(dateValue).year()
  const vMonth = Moment(dateValue).month()
  const vDate = Moment(dateValue).date()

  const fYear = Moment(dateFrom).year()
  const fMonth = Moment(dateFrom).month()
  const fDate = Moment(dateFrom).date()

  const tYear = Moment(dateTo).year()
  const tMonth = Moment(dateTo).month()
  const tDate = Moment(dateTo).date()

  const vFull = `${vYear}-${vMonth + 1}-${vDate}`
  const fFull = `${fYear}-${fMonth + 1}-${fDate}`
  const tFull = `${tYear}-${tMonth + 1}-${tDate}`

  if (vFull === fFull) {
    return true
  }

  if (vFull === tFull) {
    return true
  }

  return Moment(vFull).isBetween(fFull, tFull)
}

export function checkSameMonth(dateEnd: Date): boolean {
  return (
    Moment(dateToday()).isSame(dateEnd, "month") &&
    Moment(dateToday()).isSame(dateEnd, "year")
  )
}

export function convertDateTimezone(date: Date | string, timezone: string) {
  const Year = Moment(date).year()
  const Month = Moment(date).month()
  const Date = Moment(date).date()

  const dateString = `${Year}-${Month + 1}-${Date}`

  return momentTZ.tz(dateString, timezone).valueOf()
}

export function toUnixTimeZone(
  value: string | number | Date,
  timezone: string
): number {
  return momentTZ
    .tz(`${value}`, timezone)
    .utc()
    .valueOf()
}

export function fromUnixTimeZone(
  value: string | number | Date,
  timezone: string
): string {
  return momentTZ.tz(value, timezone).format("DD MMM YYYY")
}

const dateHelper = {
  formatDate,
  formatDateTime,
  formatTime,
  displayDate,
  toUnix,
  toUnixTomorrow,
  toUnixDateOnly,
  toUnixTimeRange,
  toUnixAddDay,
  fromUnix,
  fromUnixTime,
  fromUnixTimeTZ,
  fromUnixDateTime,
  diffDays,
  convertDate,
  dateToday,
  dateAdvance,
  dateIsAfter,
  dateSameOrAfter,
  dateRawToday,
  timeFormat,
  dayName,
  dateDefaultTime,
  isExpired,
  checkTime,
  checkValidTimeRange,
  checkDateBetween,
  checkSameMonth,
  convertDateTimezone
}

export default dateHelper
