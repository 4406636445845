import React, { useState, useContext } from "react"
import { toast } from "react-toastify"

/** Context */
import { Context as RootContext } from "context/Dashboard/UnitManagement/categories/main/Context"

/** Service */
import service from "services/Dashboard/Applications"

/** Utils */
import { UploadImage } from "utils/uploadFile"
import { toUnix, fromUnix } from "utils/date"
import { Context } from "./Context"

export interface CreateAccessCardProviderProps {
  data?: Record<string, any>
  successCallback?: Function
}

const CreateAccessCardProvider: React.FC<CreateAccessCardProviderProps> = ({
  data,
  successCallback,
  children
}) => {
  /** Context */
  const { showMain } = useContext(RootContext)
  const hasData = data !== undefined
  const [openDialog, setOpenDialog] = useState(false)
  const [photos, setPhotos] = useState(data?.accessCard_photo?.photos || Array)
  const [localPhotos, setLocalPhotos] = useState(
    data?.accessCard_photo?.photos || Array
  )
  const [attaching, setAttaching] = useState(false)
  const [accessCardDetails, setAccessCardDetails] = useState({
    blockUnit: data?.unit_uid || "",
    ownerName: data?.owner_name || "",
    ownerAccountUID: data?.owner_account_uid || "",
    accessCardType: data?.accessCard_type || "",
    serialNumber: data?.serial_no || "",
    status: data?.status || 1,
    activationDate:
      data?.activation_date !== undefined && data?.activation_date !== 0
        ? fromUnix(data?.activation_date)
        : null,
    expiryDate:
      data?.expiry_date !== undefined && data?.expiry_date !== 0
        ? fromUnix(data?.expiry_date)
        : null
  })

  /** Notification */
  const notifyCreate = (action: string) =>
    toast(`Successfully ${action} the access card data.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleRegisterAccessCard = async () => {
    const vd = accessCardDetails
    const payload = {
      "unit_uid": vd?.blockUnit,
      "owner_account_uid": vd?.ownerAccountUID,
      "owner_name": vd?.ownerName,
      "serial_no": vd?.serialNumber,
      "status": vd?.status,
      "activation_date":
        vd?.activationDate === null ? 0 : toUnix(vd?.activationDate),
      "expiry_date": vd?.expiryDate === null ? 0 : toUnix(vd?.expiryDate)
    }

    const endpoint = hasData
      ? service.updateAccessCard(payload, data?._uid)
      : service.createAccessCard(payload)

    try {
      const response = endpoint
      response.then(() => {
        notifyCreate(hasData ? "updated" : "created")

        if (successCallback === undefined) {
          showMain && showMain()
        } else {
          successCallback && successCallback()
        }
      })

      return response
    } catch (e) {
      return e
    }
  }

  function handleDocsUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch(() => setAttaching(false))
  }

  const removeDoc = (value: number) => {
    const docsFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })

    const fileLocalFiltered = localPhotos.filter((item: any, index: number) => {
      return index !== value
    })

    setPhotos(docsFiltered)
    setLocalPhotos(fileLocalFiltered)
  }

  return (
    <Context.Provider
      value={{
        handleRegisterAccessCard,
        setOpenDialog,
        setAccessCardDetails,
        setPhotos,
        setAttaching,
        handleDocsUpload,
        removeDoc,
        attaching,
        accessCardDetails,
        openDialog,
        photos,
        localPhotos
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreateAccessCardProvider
