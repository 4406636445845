import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    minWidth: "350px",
    display: "flex",
    flexDirection: "column"
  },
  closeButton: {},
  drawer: {
    flexShrink: 0
  },
  closeBtn: {
    "&.light": {
      color: theme.palette.common.white
    },
    "&.dark": {
      color: theme.palette.common.black
    }
  }
}))

export default styles
