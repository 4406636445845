import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

/** Service */
import vmsAPI from "services/Dashboard/VMS"
import serviceSettings from "services/Settings/PropertyManagement/VisitorManagement"

/** Context */
import { setVMSTable } from "redux/dashboard/vms/actions"
import { dateAdvance, dateSameOrAfter, isExpired } from "utils/date"
import { Context } from "./Context"

export const RefAll = React.createRef<HTMLButtonElement>()
export const RefPending = React.createRef<HTMLButtonElement>()
export const RefArrived = React.createRef<HTMLButtonElement>()
export const RefClosed = React.createRef<HTMLButtonElement>()
export const RefCancelled = React.createRef<HTMLButtonElement>()

export const RefDeliveryAll = React.createRef<HTMLButtonElement>()
export const RefDeliveryPending = React.createRef<HTMLButtonElement>()
export const RefDeliveryArrived = React.createRef<HTMLButtonElement>()
export const RefDeliveryClosed = React.createRef<HTMLButtonElement>()
export const RefDeliveryCancelled = React.createRef<HTMLButtonElement>()
export const RefDeliveryReceived = React.createRef<HTMLButtonElement>()
export const RefDeliveryCollected = React.createRef<HTMLButtonElement>()

export const StatusRefs: {
  [index: string]: any
} = {
  1: RefAll,
  2: RefPending,
  3: RefArrived,
  4: RefClosed,
  5: RefCancelled
}

export const StatusDeliveryRefs: {
  [index: string]: any
} = {
  1: RefDeliveryAll,
  2: RefDeliveryPending,
  3: RefDeliveryArrived,
  4: RefDeliveryClosed,
  5: RefDeliveryCancelled,
  6: RefDeliveryReceived,
  7: RefDeliveryCollected
}

const VMSProvider: React.FC = ({ children }: any) => {
  const [tableData, settableData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [activeTable, setactiveTable] = useState(0)
  const [tabsFilter, setTabsFilter] = useState(0)
  const [activeTitle, setactiveTitle] = useState("All VMS")
  const [addVisitorsActive, setAddVisitorsActive] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [activeData, setActiveData] = useState({})
  const [settings, setSettings] = useState({})
  const [canCancel, setCanCancel] = useState(true)

  const dispatch = useDispatch()

  /** Methods */
  const fetchSettings = async () => {
    try {
      const response = await serviceSettings.getVMS()
      const data = response?.data._data
      const obj: any = {}
      await data?.map((d: Record<string, any>) => {
        obj[d?.vms_type] = {
          ...d,
          allowedDate:
            d?.advance_booking_days !== 0
              ? dateAdvance(d?.advance_booking_days)
              : null,
          allowedCancellation:
            d?.cancel_booking_days !== 0 ? dateAdvance(d?.cancel_booking_days) : null
        }
      })
      setSettings(obj)
      return obj
    } catch (e) {
      return e
    }
  }

  const fetchVms = async (params?: Record<string, number>) => {
    settableData([])
    try {
      const response = await vmsAPI.getVMS(params)
      if (response.data._data !== null) {
        settableData(response.data._data)
        setFilteredData(response.data._data)
        dispatch(setVMSTable(response.data._data))
        // setactiveTable(Number(type))
      } else {
        settableData([])
        setFilteredData([])
      }
      return response.data._data
    } catch (e) {
      return e
    }
  }

  const filterData = (value: number, label: string) => {
    setactiveTable(value)
    setactiveTitle(label)
    setTabsFilter(0)
  }

  const filterActiveTable = (value: number) => {
    setTabsFilter(value)
  }

  const tabChange = (event: any, newValue: any) => {
    setTabsFilter(newValue)
    filterActiveTable(newValue)
  }

  const setDetails = (items: Record<string, any>, forForm?: boolean) => {
    if (!forForm) {
      setShowDetails(true)
    }
    setActiveData(items)
  }

  const checkCancel = (settingsItem: Record<string, any>) => {
    return setCanCancel(
      dateSameOrAfter(new Date(), settingsItem?.allowedCancellation)
    )
  }

  const checkExpired = (etams: number) => {
    return isExpired(etams)
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  return (
    <Context.Provider
      value={{
        activeData,
        showDetails,
        tableData,
        filteredData,
        activeTable,
        tabsFilter,
        activeTitle,
        addVisitorsActive,
        settings,
        canCancel,
        checkExpired,
        checkCancel,
        settableData,
        setFilteredData,
        setactiveTable,
        setTabsFilter,
        setactiveTitle,
        setAddVisitorsActive,
        fetchVms,
        filterData,
        filterActiveTable,
        tabChange,
        setDetails,
        setShowDetails
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default VMSProvider
