export const text = {
  /** Dialog */
  TITLE: "facilities",
  TITLE_ADD: "add booking",
  TITLE_EDIT: "edit booking",
  TITLE_APPROVE: "approve booking",
  TITLE_DETAILS: "details",
  TITLE_SETTINGS: "facilities settings",
  BREADCRUMB: "property management / facilities",
  BREADCRUMB_DETAILS: "property management / facilities / details",
  BREADCRUMB_ADD: "property management / facilities/ add booking",
  BREADCRUMB_EDIT: "property management / facilities/ edit booking",
  BREADCRUMB_APPROVE: "property management / facilities/ approve booking",
  URL_SETTINGS: "/settings/property-management/facilities"
}
