import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  totalStyle: {
    color: theme.palette.body.green,
    fontSize: "48px",
    fontWeight: 500,
    "& span": {
      fontSize: "10px",
      paddingRight: "5px"
    }
  },
  revenue: {
    color: theme.palette.body.dark,
    fontSize: "14px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  total: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "86px",
    padding: "0 23px 0 80px",
    borderBottom: "1px solid #F2F2F2"
  },
  container: {
    height: "100%"
  }
}))

export default styles
