import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  title: {
    fontSize: "24px",
    lineHeight: "28px",
    marginBottom: "20px"
  }
}))

export default styles
