import * as React from "react"

const ReportsVMS = (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0444 0C10.1432 0 8.60208 1.567 8.60208 3.5C8.60208 5.433 10.1432 7 12.0444 7C13.9455 7 15.4867 5.433 15.4867 3.5C15.4867 1.567 13.9455 0 12.0444 0ZM12.0444 2C12.8578 2 13.5197 2.673 13.5197 3.5C13.5197 4.327 12.8578 5 12.0444 5C11.231 5 10.5691 4.327 10.5691 3.5C10.5691 2.673 11.231 2 12.0444 2ZM4.66801 3C3.30978 3 2.20922 4.119 2.20922 5.5C2.20922 6.881 3.30978 8 4.66801 8C6.02625 8 7.1268 6.881 7.1268 5.5C7.1268 4.119 6.02625 3 4.66801 3ZM19.4208 3C18.0625 3 16.962 4.119 16.962 5.5C16.962 6.881 18.0625 8 19.4208 8C20.779 8 21.8795 6.881 21.8795 5.5C21.8795 4.119 20.779 3 19.4208 3ZM4.66801 4.72266C5.08994 4.72266 5.43254 5.071 5.43254 5.5C5.43254 5.929 5.08994 6.27734 4.66801 6.27734C4.24608 6.27734 3.90348 5.929 3.90348 5.5C3.90348 5.071 4.24608 4.72266 4.66801 4.72266ZM19.4208 4.72266C19.8427 4.72266 20.1853 5.071 20.1853 5.5C20.1853 5.929 19.8427 6.27734 19.4208 6.27734C18.9988 6.27734 18.6562 5.929 18.6562 5.5C18.6562 5.071 18.9988 4.72266 19.4208 4.72266ZM12.0444 9C10.594 9 8.23408 9.46827 6.68883 10.3984C6.05825 10.1553 5.36754 10 4.66801 10C2.46789 10 0.242188 11.3976 0.242188 12.9316V16H4.66801H5.15977H6.14329H18.929H19.4208H23.8466V12.9316C23.8466 11.3976 21.6209 10 19.4208 10C18.7212 10 18.0305 10.1553 17.3999 10.3984C15.8547 9.46827 13.4948 9 12.0444 9ZM12.0444 11C14.1432 11 16.5902 11.9614 16.962 12.5664V14H7.1268V12.5664C7.49857 11.9614 9.94556 11 12.0444 11ZM4.66801 12C4.86269 12 5.0385 12.034 5.2174 12.0625C5.18086 12.2034 5.15977 12.3494 5.15977 12.5V14H4.66801H2.20922V13.0098C2.39019 12.6898 3.38747 12 4.66801 12ZM19.4208 12C20.7013 12 21.6986 12.6898 21.8795 13.0098V14H19.4208H18.929V12.5C18.929 12.3494 18.9079 12.2034 18.8714 12.0625C19.0503 12.034 19.2261 12 19.4208 12Z"
      fill="#454B57"
    />
  </svg>
)

export default ReportsVMS
