import React from "react"

import ContextMenu from "components/ContextMenu"
import { fromUnix } from "utils/date"
import { vehicleType } from "config/Dashboard/Applications"
import { checkValue, checkDate } from "utils/helpers"
import ContextItems from "../../ContextMenu"

const tableHeaders = [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: (row: any): JSX.Element => {
          return (
            <ContextMenu>
              <ContextItems data={row?.original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    Header: "",
    columns: [
      {
        Header: "Block/Unit No",
        id: "blockUnitNo",
        accessor: (c: Record<string, any>) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Owner Name",
        id: "ownerName",
        accessor: (c: Record<string, any>) => checkValue(c?.owner_name),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.owner_name),
        width: 150
      },
      {
        Header: "Vehicle Type",
        id: "vehicleType",
        accessor: (c: Record<string, any>) =>
          checkValue(c?.vehicle_type, vehicleType[c?.vehicle_type]),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.vehicle_type, vehicleType[original?.vehicle_type]),
        width: 200
      },
      {
        Header: "Vehicle Model",
        id: "vehicleModel",
        accessor: (c: Record<string, any>) => checkValue(c?.vehicle_model),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.vehicle_model),
        width: 200
      },
      {
        Header: "Vehicle Number",
        id: "vehicleNumber",
        accessor: (c: Record<string, any>) => checkValue(c?.vehicle_no),
        Cell: ({ original }: Record<string, any>) =>
          checkValue(original?.vehicle_no),
        width: 150
      },
      {
        Header: "Car Label",
        id: "carLabel",
        accessor: (c: Record<string, any>) => {
          return c?.car_label === "" ? "N/A" : c?.car_label
        },
        Cell: ({ original }: Record<string, any>) => {
          return original?.car_label === "" ? "N/A" : original?.car_label
        },
        width: 150
      },
      {
        Header: "Vehicle Electronic Tag Number",
        id: "iuNumber",
        accessor: (c: Record<string, any>) => checkValue(c?.iu_no),
        Cell: ({ original }: Record<string, any>) => checkValue(original?.iu_no),
        width: 200
      },
      {
        Header: "Activation Date",
        id: "activationDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.activation_date, fromUnix(c?.activation_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.activation_date, fromUnix(original?.activation_date)),
        width: 150
      },
      {
        Header: "Expiry Date",
        id: "expiryDate",
        accessor: (c: Record<string, any>) =>
          checkDate(c?.expiry_date, fromUnix(c?.expiry_date)),
        Cell: ({ original }: Record<string, any>) =>
          checkDate(original?.expiry_date, fromUnix(original?.expiry_date)),
        width: 150
      }
    ]
  }
]

export default tableHeaders
