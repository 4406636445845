import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
/** Component */
import PostForm from "pages/Dashboard/Announcements/Post/Form/Create"
import MobilePreview from "components/MobilePreview"
/** Context */
import { IconLeft, IconRight } from "components/Icons/Common"
import withContext from "./withContext"
import { CtxType } from "./Context"
/** Styles */
import styles from "./styles"
/** Icons */

const View: React.FC<CtxType> = ({
  postDetails,
  photos,
  previewImage,
  attachingPhoto
}) => {
  const { sectionTitle, previewButtons } = styles()
  const pd = postDetails
  const [activePreview, setActivePreview] = useState(1)

  const imageObj =
    Object.entries(previewImage).length === 0 ? photos[0]?.url : previewImage

  function ViewPostExcerpt() {
    if (activePreview !== 1) {
      return null
    }

    return (
      <MobilePreview
        header="Post Excerpt Preview"
        isAttaching={attachingPhoto}
        title={pd?.title}
        bgImage={imageObj}
        content={pd?.shortDescription || ""}
      />
    )
  }

  function ViewPostPreview() {
    if (activePreview !== 2) {
      return null
    }

    return (
      <MobilePreview
        header="Post Preview"
        isAttaching={attachingPhoto}
        title={pd?.title}
        bgImage={imageObj}
        content={pd?.description || ""}
      />
    )
  }

  function ViewButtons(): JSX.Element {
    return (
      <Box className={previewButtons}>
        <Box>
          {activePreview === 2 ? (
            <Box onClick={() => setActivePreview(1)}>{IconRight}</Box>
          ) : (
            <Box />
          )}
        </Box>
        <Box>
          {activePreview === 1 ? (
            <Box onClick={() => setActivePreview(2)}>{IconLeft}</Box>
          ) : (
            <Box />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box display="flex">
        <Box flex="2">
          <Card>
            <Box padding="40px 60px">
              <PostForm />
            </Box>
          </Card>
        </Box>

        <Box flex="1" display="flex" flexDirection="column" position="relative">
          <Box className={sectionTitle}>Preview</Box>
          {ViewPostExcerpt()}
          {ViewPostPreview()}

          <Box className={sectionTitle}>
            <ViewButtons />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default withContext(View)
