import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
/** components */
import TableHeader from "components/TableHeader"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import WithTable from "layout/WithTable"
import DetailsSummary from "pages/Dashboard/UnitManagement/components/Vehicles/Details"
import CreateVehicle from "pages/Dashboard/UnitManagement/components/Vehicles/CreateVehicle"
import ActiveTable from "pages/Dashboard/UnitManagement/tables/Vehicles/Active"
import ArchivedTable from "pages/Dashboard/UnitManagement/tables/Vehicles/Archived"
/** Icons */
import { MenuIcons } from "pages/Dashboard/UnitManagement/config/Menu"
/** Context */
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import { CtxType } from "context/Dashboard/UnitManagement/main/Context"
import { AppContext } from "App"
import withMainContext from "context/Dashboard/UnitManagement/main/withContext"
/** Config */
import { filterVehicles } from "config/Dashboard/UnitManagement"
import { Context as SelfContext } from "./Context"

const VehiclesView: React.FC<CtxType> = ({ activeTableFilter }) => {
  const { activeView, showCreateVehicle, downloadData } = useContext(ParentContext)
  const { showDetails, setShowDetails, activeData } = useContext(SelfContext)
  const { permission } = useContext(AppContext)
  const { vehiclesTab, setVehiclesTab } = activeTableFilter
  const csvFilename = `Vehicles-${filterVehicles[vehiclesTab]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <ActiveTable />
      case 1:
        return <ArchivedTable />
      default:
        break
    }
    return activeTableParam
  }

  const ViewDetails = (): JSX.Element => {
    return (
      <DetailsSummary
        openState={showDetails}
        setopenState={(val: any) => setShowDetails && setShowDetails(val)}
      />
    )
  }

  const ViewCreateVehicle = (): JSX.Element => {
    if (activeView !== "createVehicle") {
      return <div />
    }
    return <CreateVehicle />
  }

  const ViewEditVehicle = (): JSX.Element => {
    if (activeView !== "editVehicle") {
      return <div />
    }
    return <CreateVehicle data={activeData} />
  }

  const ViewTable = (): JSX.Element => {
    if (activeView !== "tableView") {
      return <div />
    }
    return (
      <Box>
        <TableHeader
          addActionLabel="Add Vehicle"
          title="Vehicles"
          titleIcon={MenuIcons.vehicles}
          addAction={() => showCreateVehicle && showCreateVehicle()}
          actionDisable={permission?.unit_mgt_create}
          data={downloadData}
          fileName={csvFilename}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={vehiclesTab}
            handleChange={setVehiclesTab}
            options={filterVehicles}
          />
        </Box>
        <Box>
          <Box>{TableView(vehiclesTab)}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <WithTable>{ViewTable()}</WithTable>
      {ViewDetails()}
      {ViewCreateVehicle()}
      {ViewEditVehicle()}
    </>
  )
}

export default withMainContext(VehiclesView)
