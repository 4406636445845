import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"

import { AuthContext } from "context/auth-context"

function PrivateRoute({ component: Component, ...rest }: any) {
  const { isAuth } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/" />)}
    />
  )
}

export default PrivateRoute
