import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

/** Components */
import TableHeader from "components/TableHeader"
import WithTable from "layout/WithTable"
import TabsFilter from "components/Table/components/TabsFilter/withRef"

import TableAll from "pages/Dashboard/Bookkeeping/tables/all"
import TablePayment from "pages/Dashboard/Bookkeeping/tables/payment"
import TableDeposit from "pages/Dashboard/Bookkeeping/tables/deposit"
import TableOutstanding from "pages/Dashboard/Bookkeeping/tables/outstandingPayments"
import TableRefund from "pages/Dashboard/Bookkeeping/tables/refund"

/** Context */
import { tableFilter } from "config/Dashboard/Bookkeeping"
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Refs */
export const RefAll = React.createRef<HTMLButtonElement>()
export const RefPayment = React.createRef<HTMLButtonElement>()
export const RefDeposit = React.createRef<HTMLButtonElement>()
export const RefOutstandingPayments = React.createRef<HTMLButtonElement>()
export const RefRefund = React.createRef<HTMLButtonElement>()

/** Page Table Icon */
const IconTitle = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V14C0 15.105 0.895 16 2 16H8.27734C8.62361 16.5956 9.26105 17 10 17C10.7389 17 11.3764 16.5956 11.7227 16H18C19.105 16 20 15.105 20 14V0H12C11.228 0 10.532 0.300297 10 0.779297C9.468 0.300297 8.772 0 8 0H0ZM2 2H8C8.551 2 9 2.449 9 3V14H2V2ZM12 2H18V14H11V3C11 2.449 11.449 2 12 2ZM14 3V4.05273C13.4606 4.16735 12.2773 4.58463 12.2773 6.16406C12.2773 8.85206 15.3242 8.05561 15.3242 9.84961C15.3242 10.7476 14.6876 10.7695 14.5586 10.7695C14.4406 10.7695 13.6641 10.8271 13.6641 9.45312H12C12 11.7801 13.848 11.965 14.123 12H14V13H15V12H15.0078C15.3058 11.966 16.9883 11.7128 16.9883 9.83984C16.9883 7.26084 13.9424 7.75588 13.9414 6.17188C13.9414 5.26487 14.4875 5.23633 14.6055 5.23633C14.8205 5.23633 15.3359 5.41062 15.3359 6.51562H17C17 4.40263 15.4187 4.07148 15.1367 4.02148L15 4.01758V3H14Z"
      fill="#454B57"
    />
  </svg>
)

export const refDownloadCSV = React.createRef<HTMLDivElement>()

const MainTableView: React.FC<CtxType> = ({
  showPayment,
  filterActiveTable,
  activeTable,
  downloadData
}) => {
  React.useEffect(() => {
    filterActiveTable(activeTable)
  }, [activeTable])

  const { permission } = useContext(AppContext)
  const csvFileName = `Bookkeeping-${tableFilter[activeTable]}.csv`

  const TableView = (activeTableParam: number) => {
    switch (activeTableParam) {
      case 0:
        return <TableAll />
      case 1:
        return <TablePayment />
      case 2:
        return <TableDeposit />
      case 3:
        return <TableOutstanding />
      case 4:
        return <TableRefund />
      default:
        break
    }
    return activeTableParam
  }

  return (
    <Box>
      <WithTable>
        <TableHeader
          addActionLabel="Add New Payment"
          hasSearch
          addAction={showPayment}
          title="Bookkeeping"
          titleIcon={IconTitle}
          actionDisable={permission?.bookkeeping_create}
          data={downloadData}
          fileName={csvFileName}
        />
        <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
          <TabsFilter
            value={activeTable}
            handleChange={filterActiveTable}
            options={tableFilter}
            filterRef={[
              RefAll,
              RefPayment,
              RefDeposit,
              RefOutstandingPayments,
              RefRefund
            ]}
          />
        </Box>
        <Box>{TableView(activeTable)}</Box>
      </WithTable>
    </Box>
  )
}

export default withContext(MainTableView)
