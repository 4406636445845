import React, { useContext } from "react"
/**
 * Component
 */
/**
 * Context
 */
import { Context } from "context/Dashboard/Facilites/forms/addBooking/Context"
import { AppContext } from "App"
/**
 * Utils
 */
import { toUnix, formatDate, toUnixTimeZone, fromUnixTimeTZ } from "utils/date"
// import Multiselect from "./Multiselect"
import TimeButton from "../TimeButton"
/**
 * Styles
 */
import styles from "./styles"

interface Props {
  timeslots: Record<string, any>
  settings: Record<string, any>
  facility: Record<string, any>
}

const Timeslots: React.FC<Props> = ({ timeslots, facility, settings }) => {
  const classes = styles()
  /** Context */
  const {
    selectTimeslot,
    facilityBookings,
    bookingDate,
    bookingDetails,
    hasData,
    data
  } = useContext(Context)
  const { userData, condoInfo } = useContext(AppContext)

  const bd = bookingDetails
  const settingsUID = settings?._uid

  return (
    <div className={classes.cardAction}>
      <div className={classes.timeslotHeader}>
        {timeslots === null ? "No Available Timeslots" : "Available Timeslots"}
      </div>
      <div className={classes.timeslotContainer}>
        {timeslots &&
          timeslots.map((timeslot: Record<string, any>) => {
            const startTime = fromUnixTimeTZ(
              timeslot?.start_time,
              condoInfo?.timezone,
              false,
              "HH:mm"
            )
            const endTime = fromUnixTimeTZ(
              timeslot?.end_time,
              condoInfo?.timezone,
              false,
              "HH:mm"
            )
            const timeSlotUID = timeslot?._uid
            const timeValue = `${startTime} - ${endTime}`

            const dataFacilityTimeUID = data?.facility_time_slot?._uid
            const dataFacilityUID = data?.facility?._uid
            const dataBookingDate = data?.booking_date
            /**
             * data deposit
             */
            const dDeposit = data?.deposit_for_booking
            const dUsage = data?.usage_fee_for_booking
            return (
              <TimeButton
                isActive={facilityBookings?.some(
                  (a: any) =>
                    a?.facility_time_slot_uid === timeSlotUID &&
                    a?.facility_uid === facility?._uid
                )}
                currentTimeslot={
                  hasData &&
                  dataFacilityTimeUID === timeSlotUID &&
                  dataFacilityUID === facility?._uid &&
                  dataBookingDate === bookingDate
                }
                disabled={facilityBookings?.some(
                  (a: any) =>
                    timeslot?.facility_booking_uid !== "" &&
                    a?._uid !== timeslot?.facility_booking_uid
                )}
                label={timeValue}
                action={() =>
                  selectTimeslot &&
                  selectTimeslot({
                    "condo_uid": condoInfo?._uid,
                    "unit_uid": bd?.unitUID,
                    "booking_date": toUnixTimeZone(
                      `${formatDate(bookingDate as string)} 00:00:00`,
                      condoInfo?.timezone
                    ),
                    "booking_by_name": bd?.bookingByName,
                    "booking_by_account_uid": bd?.bookingByAccountUID,
                    "booking_by_user_detail_uid": bd?.bookingByUserDetailUID,
                    "for_admin": bd?.forAdmin,
                    "remarks": bd?.remarks,
                    "facility_uid": facility?._uid,
                    "facility_setting_uid": settingsUID,
                    "facility_time_slot_uid": timeSlotUID,
                    "usage_fee_for_booking": {
                      "unit_uid": bd && bd?.unitUID,
                      "payee_account_uid": bd && bd?.bookingByAccountUID,
                      "payee_name": bd && bd?.bookingByName,
                      "payee_user_detail_uid": bd && bd?.bookingByUserDetailUID,
                      "description": dUsage?.description || "",
                      "category": "Facilities",
                      "payment_status": dUsage?.payment_status || 1,
                      "payment_type": 1,
                      "amt": dUsage?.amt || Number(settings?.usage_fee_amt),
                      "tax": dUsage?.tax || Number(settings?.usage_fee_tax),
                      "admin_remarks": dUsage?.admin_remarks || "",
                      "added_by_account_uid":
                        dUsage?.booking_by_account_uid || userData?.account_uid,
                      "payment_due_date": toUnix(
                        String(dUsage?.payment_due_date || bookingDate)
                      ),
                      "payment_method": dUsage?.payment_method || 1,
                      "payment_reference": dUsage?.payment_reference || "",
                      "e_payment_agent": dUsage?.e_payment_agent || "",
                      "payment_photo": {
                        "photos": null
                      },
                      "auto_approve": dUsage?.auto_approve || false
                    },
                    "deposit_for_booking": {
                      "unit_uid": bd && bd?.unitUID,
                      "payee_account_uid": bd && bd?.bookingByAccountUID,
                      "payee_name": bd && bd?.bookingByName,
                      "payee_user_detail_uid": bd && bd?.bookingByUserDetailUID,
                      "description": dDeposit?.description || "",
                      "category": "Facilities",
                      "payment_status": dDeposit?.payment_status || 1,
                      "payment_type": 2,
                      "amt": dDeposit?.amt || Number(settings?.deposit_amt),
                      "tax": 0,
                      "admin_remarks": dDeposit?.admin_remarks || "",
                      "added_by_account_uid":
                        dDeposit?.booking_by_account_uid || userData?.account_uid,
                      "payment_method": dDeposit?.payment_method || 1,
                      "payment_reference": dDeposit?.payment_reference || "",
                      "e_payment_agent": dDeposit?.e_payment_agent || "",
                      "payment_photo": {
                        "photos": null
                      },
                      "payment_due_date": toUnix(
                        String(dDeposit?.payment_due_date || bookingDate)
                      ),
                      "auto_approve": dDeposit?.auto_approve || false
                    },
                    "facility": { ...facility },
                    "settings": { ...settings },
                    "timeValue": timeValue
                  })}
              />
            )
          })}
      </div>
    </div>
  )
}
export default Timeslots
