import React, { useState, useEffect, useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import { mutate } from "swr"
/** Components */
import DialogMessage from "components/Dialog"
/** Context */
import { Context, CtxType } from "context/Dashboard/VMS/main/Context"
import withContext from "context/Dashboard/VMS/main/withContext"
import withContextMain from "context/Dashboard/VMS/categories/main/withContext"
import { CtxMenuContextConsumer, AddBookingCtxType } from "components/ContextMenu"
import { AppContext } from "App"
/** Service */
import vmsAPI from "services/Dashboard/VMS"
import message from "config/Text/Dashboard/VMS"
/**
 * Icons
 */
import {
  IconView,
  IconPending,
  IconArrived,
  IconClosed,
  IconCancel,
  IconEdit,
  IconReceived,
  IconCollected
} from "components/Icons/ContextMenu"

/** styles */
import styles from "./styles"

interface ContextVMSProps extends CtxType {
  data: any
  mutateValue: string
  activeData: Record<string, any>
  setDetails: Function
  setActiveData: Function
  showUpdateForm: Function
  settings: Record<string, any>
}

const ContextVMS: React.FC<ContextVMSProps> = ({
  data,
  setDetails,
  showUpdateForm,
  mutateValue,
  settings
}) => {
  const classes = styles()
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [option, setOption] = useState(0)
  const { canCancel, checkExpired, checkCancel } = useContext(Context)
  const { permission } = useContext(AppContext)
  const canEdit = permission?.vms_edit

  /** Methods */
  const handleClick = () => {
    setOpen(!open)
  }

  const updateStatus = async (statusType: number): Promise<Function> => {
    const params = {
      "vms_uid": data.vms._uid,
      "status": statusType
    }

    try {
      await vmsAPI.updateStatusVMS(data.vms_type, params)
      return mutate(mutateValue)
    } catch (e) {
      return e
    }
  }

  const handleOptions = (optionType: number): void => {
    setOpenDialog(true)
    setOption(optionType)
  }

  function showMessage() {
    function statusMessage() {
      switch (option) {
        case 1:
          return {
            status: message?.confirmPending,
            expired: message?.expiredBookingPending
          }
        case 2:
          return {
            status: message?.confirmArrived,
            expired: message?.expiredBookingArrived
          }
        case 3:
          return {
            status: message?.confirmClosed,
            expired: message?.expiredBookingClosed
          }
        case 4:
          return {
            status: message?.confirmCancel,
            expired: message?.expiredBookingCancel
          }
        case 5:
          return {
            status: message?.confirmReceived,
            expired: message?.expiredBookingReceived
          }
        case 6:
          return {
            status: message?.confirmCollected,
            expired: message?.expiredBookingCollected
          }
        default:
          return {
            status: message?.confirmPending,
            expired: message?.expiredBookingPending
          }
      }
    }

    if (checkExpired && checkExpired(data?.vms?.eta_ms || data?.vms?.end_date_ms)) {
      return statusMessage()?.expired
    }
    if (canCancel) {
      return statusMessage()?.status
    }
    return message?.cancelBookingNotAllowed
  }

  useEffect(() => {
    checkCancel && checkCancel(settings[data?.vms_type])
  }, [])

  return (
    <CtxMenuContextConsumer>
      {({ openContext }: Partial<AddBookingCtxType>): JSX.Element => (
        <>
          {data?.vms_type !== 2 ? (
            <List component="nav">
              <ListItem
                className={classes.listItem}
                button
                onClick={(): Array<Function> => [
                  openContext && openContext(null),
                  setDetails(data)
                ]}
              >
                <i>{IconView}</i>
                View
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                disabled={!canEdit}
                onClick={(): any => {
                  return !canEdit
                    ? null
                    : [
                        (openContext && openContext(null),
                        setDetails(data, true),
                        showUpdateForm())
                      ]
                }}
              >
                <i>{IconEdit}</i>
                Edit
              </ListItem>

              {canEdit && (
                <>
                  <ListItem
                    className={classes.listItem}
                    button
                    onClick={handleClick}
                  >
                    <div className={classes.collapseControl}>
                      <div>
                        <i />
                        Mark as
                      </div>
                    </div>
                  </ListItem>
                  <Collapse in timeout="auto" unmountOnExit>
                    <List
                      className={classes.innerList}
                      component="div"
                      disablePadding
                    >
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(1)
                        ]}
                      >
                        <i>{IconPending}</i>
                        Pending
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(2)
                        ]}
                      >
                        <i>{IconArrived}</i>
                        arrived
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(3)
                        ]}
                      >
                        <i>{IconClosed}</i>
                        closed
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(4)
                        ]}
                      >
                        <i>{IconCancel}</i>
                        Cancelled
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            </List>
          ) : (
            <List component="nav">
              <ListItem
                className={classes.listItem}
                button
                onClick={(): Array<Function> => [
                  openContext && openContext(null),
                  setDetails(data)
                ]}
              >
                <i>{IconView}</i>
                View
              </ListItem>
              <ListItem
                className={classes.listItem}
                disabled={!canEdit}
                onClick={(): any => {
                  return !canEdit
                    ? null
                    : [
                        (openContext && openContext(null),
                        setDetails(data, true),
                        showUpdateForm())
                      ]
                }}
              >
                <i>{IconEdit}</i>
                Edit
              </ListItem>

              {canEdit && (
                <>
                  <ListItem
                    className={classes.listItem}
                    button
                    disabled={!canEdit}
                    onClick={handleClick}
                  >
                    <div className={classes.collapseControl}>
                      <div>
                        <i />
                        Mark as
                      </div>
                    </div>
                  </ListItem>
                  <Collapse in timeout="auto" unmountOnExit>
                    <List
                      className={classes.innerList}
                      component="div"
                      disablePadding
                    >
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(1)
                        ]}
                      >
                        <i>{IconPending}</i>
                        Pending
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(2)
                        ]}
                      >
                        <i>{IconArrived}</i>
                        arrived
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(5)
                        ]}
                      >
                        <i>{IconReceived}</i>
                        Received
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(6)
                        ]}
                      >
                        <i>{IconCollected}</i>
                        Collected
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(3)
                        ]}
                      >
                        <i>{IconClosed}</i>
                        Closed
                      </ListItem>
                      <ListItem
                        className={classes.listItem}
                        button
                        onClick={(): Array<Function> => [
                          openContext && openContext(null),
                          handleOptions(4)
                        ]}
                      >
                        <i>{IconCancel}</i>
                        Cancelled
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            </List>
          )}
          <DialogMessage
            action={(): Promise<Function> => updateStatus(option)}
            actionLabel="OK"
            isOpen={openDialog}
            setOpen={setOpenDialog}
            title=""
            message={showMessage()}
          />
        </>
      )}
    </CtxMenuContextConsumer>
  )
}

export default withContext(withContextMain(ContextVMS))
