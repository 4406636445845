import React, { useMemo } from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControl from "@material-ui/core/FormControl"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import FormLabel from "components/Forms/Label"
import Spacer from "components/Spacer"
import FormInput from "components/Forms/FormInput"
import DatePicker from "components/Forms/DatePicker"
import DateTimePicker from "components/Forms/DateTimePicker"
import RefButton from "components/Forms/RefButton"

/** Config */
import { vmsValidation } from "config/Dashboard/VMS/validation"

/** Context */
import withContext from "context/Dashboard/VMS/add-update/withContext"
import { AddUpdateCtxType } from "context/Dashboard/VMS/add-update/AddUpdateContext"

/** Styles */
import { refSubmit } from "context/Dashboard/VMS/add-update/AddUpdateView"
import styles from "./styles"

interface Props extends AddUpdateCtxType {
  setVisitorValid: Function
  setDeliveryValid: Function
  setServiceValid: Function
  allowedDateMessage: string
}

const VisitorsInfo: React.FC<Props> = ({
  setVisitorDetails,
  visitorDetails,
  setVisitorValid,
  setDeliveryValid,
  setServiceValid,
  allowedDateMessage,
  allowedTimeMessage
}): JSX.Element => {
  const { generalInfo } = styles()
  const FormType = visitorDetails.vmsType
  const validatationSchema = vmsValidation[parseInt(FormType, 0)]

  const {
    name,
    eta,
    remarks,
    phoneNo,
    startDate,
    endDate,
    email,
    purpose,
    allowCondo,
    mobile,
    numberOfPersons,
    multiplePersons,
    vehicleNumber
  } = visitorDetails
  const allowedMesesage = `${allowedDateMessage} ${
    eta !== "" ? `${allowedTimeMessage}` : ``
  }`

  const validateCheck = (type: string, value: boolean): any => {
    switch (type) {
      case "1":
        return setVisitorValid(value)
      case "2":
        return setDeliveryValid(value)
      case "3":
        return setServiceValid(value)
      default:
        return setVisitorValid(value)
    }
  }

  const handleFormChange = (setFieldValue: Function, key: string, e: any): any => {
    if (e.target === undefined) {
      setVisitorDetails &&
        setVisitorDetails({
          ...visitorDetails,
          [key]: e
        })
      setFieldValue(key, e)
    } else {
      setVisitorDetails &&
        setVisitorDetails({
          ...visitorDetails,
          [key]: e?.target?.value
        })
      setFieldValue(key, e?.target?.value)
    }
  }

  return (
    <div className={generalInfo} style={{ position: "relative" }}>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return {
            name,
            eta,
            remarks,
            phoneNo,
            startDate,
            endDate,
            email,
            purpose,
            numberOfPersons,
            vehicleNumber
          }
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={validatationSchema}
        isInitialValid={visitorDetails?.name || false}
      >
        {({
          isValid,
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue
        }): JSX.Element => {
          return (
            <form onSubmit={handleSubmit}>
              <FormWrapper title="Visitor's Information" width="40%">
                <div className="section" style={{ width: "40%", margin: "auto" }}>
                  {FormType === "2" && (
                    <Box marginBottom="25px">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={allowCondo}
                              onChange={(): void =>
                                handleFormChange(
                                  setFieldValue,
                                  "allowCondo",
                                  !allowCondo
                                )}
                              value={allowCondo}
                              color="primary"
                            />
                          }
                          label="Allow Condo to Receive"
                        />
                      </FormGroup>
                    </Box>
                  )}
                  <Box>
                    <Box marginBottom="25px">
                      <FormInput
                        name="name"
                        idValue="name"
                        label="Name"
                        placeholder="Name"
                        value={name}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "name", e)}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Box>

                    {/* ETA */}
                    {FormType !== "2" && (
                      <Box display="flex" marginBottom="25px">
                        <DateTimePicker
                          ampm={false}
                          label="ETA"
                          name="eta"
                          value={eta}
                          handleDateChange={(value: string): void =>
                            handleFormChange(setFieldValue, "eta", value)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.eta && Boolean(errors.eta)}
                          helperText={
                            (touched.eta && errors.eta?.toString()) ||
                            allowedMesesage
                          }
                        />
                      </Box>
                    )}
                    {/* ETA */}

                    {/* start date end date */}
                    {FormType === "2" && (
                      <Box marginBottom="25px">
                        <Box flex="1">
                          <DatePicker
                            label="Start Date"
                            name="startDate"
                            format="MM/dd/yyyy"
                            value={startDate}
                            placeholder="mm/dd/yyyy"
                            handleDateChange={(value: string): void =>
                              handleFormChange(setFieldValue, "startDate", value)}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.startDate && Boolean(errors.startDate)}
                            helperText={
                              (errors.startDate &&
                                touched.startDate &&
                                errors.startDate) ||
                              allowedDateMessage
                            }
                            maxDate={endDate}
                          />
                        </Box>
                        <Spacer isDefault />
                        <Box flex="1">
                          <DatePicker
                            label="End Date"
                            format="MM/dd/yyyy"
                            placeholder="mm/dd/yyyy"
                            name="endDate"
                            value={endDate}
                            handleDateChange={(value: string): void =>
                              handleFormChange(setFieldValue, "endDate", value)}
                            onBlur={(e: Event): void => handleBlur(e)}
                            error={touched.endDate && Boolean(errors.endDate)}
                            helperText={
                              errors.endDate && touched.endDate && errors.endDate
                            }
                            minDate={startDate}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* start date end date */}

                    {/* Purpose */}
                    {FormType === "3" && (
                      <Box marginBottom="25px">
                        <FormInput
                          name="purpose"
                          idValue="purpose"
                          label="Purpose"
                          value={purpose}
                          placeholder="Purpose"
                          multiline
                          rows={2}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.purpose && Boolean(errors.purpose)}
                          helperText={
                            errors.purpose && touched.purpose && errors.purpose
                          }
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleFormChange(setFieldValue, "purpose", e)}
                        />
                      </Box>
                    )}
                    {/* end of Purpose */}

                    <Box display="flex" marginBottom="25px">
                      <Box flex="1">
                        <FormInput
                          name="mobileNumber"
                          idValue="mobileNumber"
                          label="mobile number"
                          value={mobile}
                          placeholder="123-456-7890"
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            setVisitorDetails &&
                            setVisitorDetails({
                              ...visitorDetails,
                              mobile: e?.target?.value
                            })}
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <FormInput
                          name="email"
                          label="email"
                          value={email}
                          placeholder="email@email.com"
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={errors.email && touched.email && errors.email}
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleFormChange(setFieldValue, "email", e)}
                        />
                      </Box>
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="vehicleNumber"
                        idValue="vehicleNumber"
                        label="Vehicle Number"
                        placeholder="Vehicle Number"
                        value={vehicleNumber}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(setFieldValue, "vehicleNumber", e)}
                        onBlur={handleBlur}
                        error={
                          touched.vehicleNumber && Boolean(errors.vehicleNumber)
                        }
                        helperText={
                          errors.vehicleNumber &&
                          touched.vehicleNumber &&
                          errors.vehicleNumber
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="remarks"
                        label="Remarks"
                        value={remarks}
                        placeholder="Remarks"
                        multiline
                        rows={2}
                        onBlur={handleBlur}
                        error={touched.remarks && Boolean(errors.remarks)}
                        helperText={
                          errors.remarks && touched.remarks && errors.remarks
                        }
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleFormChange(setFieldValue, "remarks", e)}
                      />
                    </Box>

                    {/* Entry */}
                    {FormType === "1" && (
                      <Box>
                        <FormControl>
                          <FormLabel label="entry" />
                          <RadioGroup
                            aria-label="entry"
                            name="entry"
                            value={multiplePersons}
                            onChange={(e: any): void =>
                              handleFormChange(setFieldValue, "multiplePersons", e)}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label="Single"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio color="primary" />}
                              label="Multiple"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    {/* Entry */}

                    {visitorDetails.multiplePersons === "2" && FormType !== "2" && (
                      <Box marginTop="25px">
                        <FormInput
                          type="number"
                          name="numberOfPersons"
                          idValue="numberOfPersons"
                          label="Number of Persons"
                          placeholder="Number of Persons"
                          value={numberOfPersons}
                          onBlur={handleBlur}
                          error={
                            touched.numberOfPersons &&
                            Boolean(errors.numberOfPersons)
                          }
                          helperText={
                            errors.numberOfPersons &&
                            touched.numberOfPersons &&
                            errors.numberOfPersons
                          }
                          handleOnChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void =>
                            handleFormChange(
                              setFieldValue,
                              "numberOfPersons",
                              e?.target?.value
                            )}
                        />
                      </Box>
                    )}
                  </Box>
                </div>
                <Box>
                  {isValid
                    ? validateCheck(FormType, true)
                    : validateCheck(FormType, false)}
                </Box>
                <RefButton refValue={refSubmit} action={handleSubmit} />
              </FormWrapper>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withContext(VisitorsInfo)
