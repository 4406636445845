import useSWR from "swr"
import service from "services/Dashboard/Common"

interface Props {
  dataID: string
}

function RolesServiceHook(dataID: number): Record<string, any> {
  const fetchRoles = async () =>
    service.getRoles({
      "user_type": dataID
    })

  const { data, isValidating, error } = useSWR(
    `fetchRoles-usertype-${dataID}`,
    fetchRoles,
    {
      revalidateOnFocus: false
    }
  )

  return {
    roles: data?.data?._data,
    isValidating,
    error
  }
}

export default RolesServiceHook
