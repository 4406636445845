import * as React from "react"

const AccountsRequests: React.FC = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C9.05867 0 6.66667 2.392 6.66667 5.33333C6.66667 8.27467 9.05867 10.6667 12 10.6667C14.9413 10.6667 17.3333 8.27467 17.3333 5.33333C17.3333 2.392 14.9413 0 12 0ZM12 2.66667C13.4707 2.66667 14.6667 3.86267 14.6667 5.33333C14.6667 6.804 13.4707 8 12 8C10.5293 8 9.33333 6.804 9.33333 5.33333C9.33333 3.86267 10.5293 2.66667 12 2.66667ZM20 12C17.5837 12 15.4128 13.0893 13.9427 14.7969C13.2487 14.7169 12.5842 14.6667 12 14.6667C7.812 14.6667 0 16.7267 0 20.6667V24H13.0885C14.4778 26.3837 17.0567 28 20 28C24.4 28 28 24.4 28 20C28 15.6 24.4 12 20 12ZM18.6667 16H21.3333V18.6667H24V21.3333H21.3333V24H18.6667V21.3333H16V18.6667H18.6667V16ZM12 17.3333C12.1511 17.3333 12.3061 17.3566 12.4583 17.362C12.1665 18.1898 12 19.0756 12 20C12 20.455 12.0485 20.8985 12.1224 21.3333H2.66667V20.6667C2.66667 19.508 7.61067 17.3333 12 17.3333Z"
        fill="white"
      />
    </svg>
  )
}

export default AccountsRequests
