import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Components */
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import FilterWithInfo from "components/FilterButtons/FilterWithInfo"
import TabsFilter from "components/Table/components/TabsFilter/withRef"
import TableHeader from "components/TableHeader"
import DatePicker from "components/Forms/DatePicker"
import PermissionMessage from "components/PermissionMessage"

import TableTimeIn from "pages/Dashboard/Attendance/tables/timein"
import TableTimeOut from "pages/Dashboard/Attendance/tables/timeout"
import TimeinStaff from "pages/Dashboard/Attendance/components/TimeinStaff"
import TimeoutStaff from "pages/Dashboard/Attendance/components/TimeoutStaff"
import ViewStaff from "pages/Dashboard/Attendance/components/ViewStaff"

/** Menu */
import { MenuFilter, MenuStatus } from "config/Dashboard/Attendance/"

/** Context */
import { AppContext } from "App"
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Ref */
export const refTable = React.createRef<HTMLDivElement>()

const UMView: React.FC<CtxType> = ({
  activeData,
  activeDate,
  activeTable,
  setactiveTable,
  activeStatus,
  setActiveStatus,
  setActiveDate,
  resetForm,
  showDrawer,
  showDetails,
  showStaff,
  setCurrentAction,
  downloadData,
  CSVFileName,
  timeinData,
  timeoutData,
  showTimein,
  showTimeout,
  logDetails,
  timeinOpen,
  timeoutOpen,
  setIsEdit
}) => {
  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  const FilterView = (): JSX.Element => {
    return (
      <Box display="flex" flexWrap="wrap" padding="20px 0">
        {MenuFilter.map((item: any) => {
          return (
            <FilterWithInfo
              type={item.type}
              name={item.name}
              color={item.color}
              icon={item.icon}
              action={setactiveTable}
            />
          )
        })}
      </Box>
    )
  }

  const ViewTimeinStaff = (): JSX.Element => {
    return (
      <TimeinStaff
        openState={timeinOpen}
        setopenState={(value: boolean) => {
          showTimein(value)
          setIsEdit(false)
        }}
      />
    )
  }

  const ViewTimeoutStaff = (): JSX.Element => {
    return (
      <TimeoutStaff
        openState={timeoutOpen}
        setopenState={(value: boolean) => {
          showTimeout(value)
        }}
        hasTimeout={logDetails?.hasTimeout}
      />
    )
  }

  const ViewDetailsStaff = (): JSX.Element => {
    return (
      <ViewStaff
        openState={showStaff}
        setopenState={(value: boolean) => {
          showDetails(value)
        }}
        data={activeData}
      />
    )
  }

  const ViewPageTitle = (): JSX.Element => {
    return (
      <Box>
        <Box>
          <PageHeaderTitle
            title=""
            breadcrumbs="property management / Attendance"
            noBottomGap
          />
        </Box>
        <Box display="flex" alignItems="center" minHeight="40px" overflow="hidden">
          <Box fontSize="24px" fontWeight="500" color="#454B57" paddingRight="10px">
            Attendance
          </Box>
          <DatePicker
            label=""
            name="activeDate"
            format="MM/dd/yyyy"
            value={activeDate}
            placeholder="mm/dd/yyyy"
            handleDateChange={(value: string): void => setActiveDate(value)}
            inlineStyles={{
              minHeight: "40px",
              backgroundColor: "#FFFFFF",
              minWidth: "215px",
              width: "215px"
            }}
          />
        </Box>
      </Box>
    )
  }

  const ViewTableTimein = (): JSX.Element => {
    const status = activeStatus !== undefined && activeStatus + 1

    if (status !== 1) {
      return <div />
    }

    return <TableTimeIn data={timeinData} setIsEdit={setIsEdit} />
  }

  const ViewTableTimeout = (): JSX.Element => {
    const status = activeStatus !== undefined && activeStatus + 1

    if (status !== 2) {
      return <div />
    }

    return (
      <TableTimeOut
        data={timeoutData}
        date={activeDate}
        activeTable={activeTable}
        showTimeout={(value: boolean, dataItems: Record<string, any>) => {
          showDrawer(1, value, dataItems)
          setCurrentAction(2)
        }}
      />
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <Box>
          <ViewPageTitle />
        </Box>
        <Box>
          <FilterView />
        </Box>

        <Card>
          <Box>
            <TableHeader
              addActionLabel="TIME-IN STAFF"
              title={MenuFilter[activeTable]?.name}
              titleIcon={MenuFilter[activeTable]?.icon}
              addAction={() => {
                showTimein(true)
                resetForm()
              }}
              actionDisable={permission?.attendance_create}
              data={downloadData}
              fileName={CSVFileName}
            />
          </Box>
          <Box paddingLeft="90px" borderBottom="1px solid #F2F2F2">
            <TabsFilter
              value={activeStatus}
              handleChange={setActiveStatus}
              options={MenuStatus}
            />
          </Box>
          <Box>
            {ViewTableTimein()}
            {ViewTableTimeout()}
          </Box>
        </Card>
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.attendance_view) {
    return (
      <Box paddingBottom="200px">
        {MainView()}
        {ViewTimeinStaff()}
        {ViewTimeoutStaff()}
        {ViewDetailsStaff()}
      </Box>
    )
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default withContext(UMView)
