import React from "react"

export type CtxType = {
  showUpdateUser: Function
  showCreateFromData: Function
  showCreateVehicle: Function
  showEditVehicle: Function
  showVehicleDetails: Function
  showCreateAccessCards: Function
  showEditAccessCards: Function
  showAccessCardsDetails: Function
  showPSDetails: Function
  handlePSArchive: Function
  showDetails: Function
  showEditPS: Function
  showAddPS: Function
  setActiveFilter: Function
  filterTable: Function
  showMain: Function
  showCreateOwner: Function
  showEditOwner: Function
  showCreateResident: Function
  showEditResident: Function
  setDownloadData: Function
  handleResetCode: Function
  handleArchived: Function
  resetting: boolean
  downloadData: Array<{}>
  activeFilter: number
  activeTable: number
  activeView: string | any
  activeData: Record<string, any>
}

export const Context = React.createContext<Partial<CtxType>>({})
