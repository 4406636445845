import React, { useContext } from "react"
import Box from "@material-ui/core/Box"

import { Formik } from "formik"

/** Component */
import Footer from "components/Forms/Footer/new"
import Editor from "components/Editor"
import Label from "components/Forms/Label"
import Loader from "components/Loader"

/** Contxt */
import { Context } from "context/Settings/Others/CondoInfo/main/Context"
import { AppContext } from "App"

/** Validation */
import { updateCondoInfo as updateCondoInfoValidation } from "config/Settings/Others/CondoInfo/validation"

const Form = () => {
  const {
    condoInfo,
    setCondoInfo,
    updateCondoInfo,
    submitting,
    isLoading
  } = useContext(Context)

  const { permission } = useContext(AppContext)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)

    if (e?.target === undefined) {
      setCondoInfo &&
        setCondoInfo((data: any) => ({
          ...data,
          [name]: e
        }))
    } else {
      setCondoInfo &&
        setCondoInfo((data: any) => ({
          ...data,
          [name]: e?.target?.value
        }))
    }
  }

  return (
    <Box>
      <Box paddingBottom="100px">
        <Formik
          initialValues={{
            terms: condoInfo?.terms
          }}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          validationSchema={updateCondoInfoValidation}
        >
          {({ touched, handleBlur, handleChange, setFieldTouched }): JSX.Element => {
            return (
              <form>
                <Box width="60%" margin="auto">
                  <Label label="Terms & Condition" />
                  {!isLoading ? (
                    <Editor
                      error={touched.terms && condoInfo?.terms === "<p></p>"}
                      onBlur={handleBlur}
                      content={condoInfo?.terms}
                      setContent={(content: any) =>
                        handleFormChange(
                          "terms",
                          content,
                          handleChange,
                          setFieldTouched
                        )}
                    />
                  ) : (
                    <Loader dark />
                  )}
                </Box>
              </form>
            )
          }}
        </Formik>
      </Box>
      <Box margin="0 0 30px">
        <Footer
          handleNext={() => updateCondoInfo && updateCondoInfo()}
          handleBack={null}
          handleCancel={null}
          label={submitting ? "Submitting..." : "SAVE"}
          disabled={!permission?.manage_condo_settings}
        />
      </Box>
    </Box>
  )
}

export default Form
