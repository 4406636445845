import React from "react"

import Provider, {
  Props
} from "context/Dashboard/Facilites/forms/addBooking/Provider"
import View from "context/Dashboard/Facilites/forms/addBooking/View"

export default ({ data, mainView }: Props): JSX.Element => (
  <Provider data={data} mainView={mainView}>
    <View />
  </Provider>
)
