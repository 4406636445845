import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Formik } from "formik"
/**
 * Context
 */
import { AuthContext } from "context/auth-context"
/**
 * Component
 */
import FormInput from "components/Forms/FormInput"
/**
 * Validation
 */
import { forgotPassword as validation } from "config/Onboard/validation"
import Header from "./Header"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "412px",
    marginBottom: "40px"
  },
  forgot: {
    display: "flex",
    flexDirection: "column",
    padding: "0 28px",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontSize: "16px"
    }
  },
  copyright: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  },
  ctaButton: {
    width: "100%"
  }
}))

const LoginCard: React.FC = () => {
  const classes = useStyles()
  const [details, setDetails] = useState({
    email: ""
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { forgotPassword } = useContext(AuthContext)

  const handleForgotPassword = async (handleSubmit: Function) => {
    if (details?.email === "") {
      handleSubmit()
    } else {
      setIsSubmitting(true)
      forgotPassword &&
        forgotPassword(details?.email).then(() => setIsSubmitting(false))
    }
  }

  /** Methods */
  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue("email", e?.target?.value)
    setDetails &&
      setDetails({
        ...details,
        "email": e?.target?.value
      })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.container}>
        <Box padding="28px 28px 0">
          <Header />
          <Formik
            initialValues={{
              email: details?.email
            }}
            onSubmit={(values, actions): void => {
              JSON.stringify(values, null, 2)
              actions.setSubmitting(false)
            }}
            validationSchema={validation}
          >
            {({
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue
            }): JSX.Element => {
              return (
                <form>
                  <FormInput
                    name="email"
                    value={details?.email}
                    idValue="email"
                    label="Email Address"
                    placeholder="Email Address"
                    handleOnChange={(e: React.ChangeEvent) =>
                      handleFormChange(e, setFieldValue)}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={errors.email && touched.email && errors.email}
                  />
                  {/* CTA  */}
                  <Box display="flex" flexDirection="column">
                    <Box margin="30px 0" flex="1">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => handleForgotPassword(handleSubmit)}
                        className={classes.ctaButton}
                      >
                        {isSubmitting ? "SUBMITTING" : "SUBMIT"}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )
            }}
          </Formik>
        </Box>

        {/* Forgot Password */}
        <Box className={classes.forgot}>
          <Link to="/login">Sign in to your account</Link>
        </Box>
      </Box>
      <Box className={classes.copyright}>Copyright © CORA 2020</Box>
    </Box>
  )
}

export default LoginCard
