import useSWR from "swr"
import service from "services/Dashboard/Dashboard"
import { toUnixAddDay } from "utils/date"

export function ApplicationsTodayPreview(date: any) {
  const todayStartDate = date?.startDate
  const todayEndDate = date?.endDate

  const fetchDashboardApplications = async () =>
    service.getListApplications({
      "application_date_sort": "desc",
      "start_timestamp": todayStartDate,
      "end_timestamp": todayEndDate,
      "timestamp_filter_by": "startDate",
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardApplications-${todayStartDate}-${todayEndDate}`,
    fetchDashboardApplications,
    {
      revalidateOnFocus: true
    }
  )
  const applicationsList = data?.data?._data

  return {
    applicationsList,
    isValidating,
    error
  }
}

export function ApplicationsTomorrowPreview(date: any) {
  const tomStartDate = toUnixAddDay(`${date?.currentDate} 00:00:00`)
  const tomEndDate = toUnixAddDay(`${date?.currentDate} 23:59:00`)

  const fetchDashboardApplicationsTomorrow = async () =>
    service.getListApplications({
      "application_date_sort": "desc",
      "start_timestamp": tomStartDate,
      "end_timestamp": tomEndDate,
      "timestamp_filter_by": "startDate",
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardApplicationsTomorrow-${tomStartDate}-${tomEndDate}`,
    fetchDashboardApplicationsTomorrow,
    {
      revalidateOnFocus: true
    }
  )
  const applicationsListTomorrow = data?.data?._data

  return {
    applicationsListTomorrow,
    isValidatingTomorrow: isValidating,
    error
  }
}

export function ApplicationsPending() {
  const fetchDashboardApplicationsPending = async () =>
    service.getListApplications({
      "application_date_sort": "desc",
      "status": 1,
      "limit": 5
    })

  const { data, isValidating, error } = useSWR(
    `fetchDashboardApplicationsPending`,
    fetchDashboardApplicationsPending,
    {
      revalidateOnFocus: true
    }
  )
  const applicationsPendingList = data?.data?._data

  return {
    applicationsPendingList,
    isValidatingPending: isValidating,
    error
  }
}
