import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  personalDetails: {
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      borderBottom: "1px solid #F2F2F2",
      marginBottom: "40px",
      margin: "0 auto"
    }
  },
  spaceBottom: {
    paddingBottom: "20px"
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > div": {
      width: "49%"
    }
  },
  inputDescription: {
    fontSize: "16px",
    letterSpacing: "0.0015em",
    color: "#454B57"
  },
  root: {
    display: "flex",
    padding: "0 0 8px",
    "& .MuiTextField-root": {
      flex: 1
    }
  },
  formControl: {
    width: "100%"
  },
  formControlButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 30px"
  },
  formControlButton: {
    width: "49%"
  },
  labelStyle: {
    fontSize: "10px",
    marginBottom: "8px",
    color: theme.palette.body.secondary,
    textTransform: "uppercase"
  },
  titleStyle: {
    fontSize: "18px",
    color: theme.palette.body.secondary
  },
  section: {
    margin: "30px 0 0 0"
    // borderBottom: "1px solid #F2F2F2",
  }
}))

export default styles
