import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

import { CondoUID } from "config/Common/CondoInfo"

function headers() {
  return {
    "Authorization": `Bearer ${Cookies.get("bearer")}`
  }
}

function getBookings(params?: Object) {
  return request({
    url: API.FETCH_BOOKINGS,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function getFacilities(params?: Object) {
  return request({
    url: API.FETCH_FACILITIES,
    method: "GET",
    params: {
      "condo_uid": Cookies.get("condoUID"),
      ...params
    },
    headers: headers()
  })
}

function createBooking(data: Record<string, any>) {
  return request({
    url: API.CREATE_BOOKING,
    method: "POST",
    headers: headers(),
    data
  })
}

function approveBooking(data: Record<string, any>) {
  return request({
    url: API.APPROVE_BOOKING,
    method: "POST",
    headers: headers(),
    data
  })
}

function updateBookingStatus(data: Record<string, any>, type: string) {
  function endpoint(dataType: string) {
    switch (dataType) {
      case "reject":
        return API.REJECT_BOOKING
      case "cancel":
        return API.CANCEL_BOOKING
      case "noshow":
        return API.NO_SHOW
      default:
        return API.REJECT_BOOKING
    }
  }

  return request({
    url: endpoint(type),
    method: "POST",
    headers: headers(),
    data
  })
}

function updateTimeslot(data: Record<string, any>) {
  return request({
    url: API.UPDATE_TIMESLOT,
    method: "POST",
    headers: headers(),
    data
  })
}

function updatePayment(data: Record<string, any>) {
  return request({
    url: API.UPDATE_PAYMENT,
    method: "POST",
    headers: headers(),
    data
  })
}

function updateRemarks(data: Record<string, any>) {
  return request({
    url: API.UPDATE_REMARKS,
    method: "POST",
    headers: headers(),
    data
  })
}

function noShow(data: Record<string, any>) {
  return request({
    url: API.NO_SHOW,
    method: "POST",
    headers: headers(),
    data
  })
}

const Facilities = {
  getBookings,
  getFacilities,
  createBooking,
  approveBooking,
  updateBookingStatus,
  updateTimeslot,
  updatePayment,
  updateRemarks,
  noShow
}

export default Facilities
