import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

// components
import ResetPasswordCard from "./ResetPasswordCard"
import Brand from "./Brand"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh"
  },
  sectionBrand: {
    display: "flex",
    height: "100%",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flex: 0
    }
  },
  section: {
    display: "flex",
    height: "100%",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      margin: "0 20px",
      paddingBottom: "20px",
      borderRadius: "10px",
      height: "auto"
    }
  }
}))

const ResetPassword: React.FC = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.sectionBrand}>
        <Brand />
      </Box>
      <Box className={classes.section} justifyContent="center" alignItems="center">
        <ResetPasswordCard />
      </Box>
    </Box>
  )
}

export default withRouter(ResetPassword)
