import React from "react"

import VMSProvider from "context/Dashboard/VMS/main/Provider"
import VMSView from "context/Dashboard/VMS/main/View"

export default (): JSX.Element => (
  <VMSProvider>
    <VMSView />
  </VMSProvider>
)
