import React, { useState } from "react"
import { toast } from "react-toastify"

/** Services */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Context */
import { Context } from "./Context"

/** Interface */
export interface RejectPaymentProps {
  showPayment: boolean
  setShowPayment: Function
  data: Record<string, any>
}

const RejectPaymentProvider: React.FC<RejectPaymentProps> = (props: any) => {
  /** Props */
  const { children, data, showPayment, setShowPayment } = props

  const [submitting, setSubmitting] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState({
    receiptNo: data?.txn_id,
    unitUID: data?.unit.short_name,
    payeeAccountUID: data?.payee_account_uid,
    payeeName: data?.payee_name,
    description: data?.description,
    category: data?.category,
    paymentType: data?.payment_type,
    amt: data?.amt,
    tax: data?.tax,
    remarks: data?.admin_remarks,
    dueDate: data?.payment_due_date,
    addedBy: data.added_by_account_uid,
    paymentMethod: "1",
    paymentDate: data?._created,
    paymentReference: "",
    ePaymentAgent: "",
    photos: data?.payment_photo.photos
  })

  /** Notification */
  const notifyCreate = () =>
    toast("Successfully rejected the payment.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleRejectPayment = async () => {
    const payload = {
      "_uid": data?._uid,
      "rejected_remarks": paymentDetails?.remarks
    }
    setSubmitting(true)
    try {
      const response = await BookkeepingAPI.rejectPayment(payload).then(() =>
        notifyCreate()
      )
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  return (
    <Context.Provider
      value={{
        handleRejectPayment,
        setOpenDialog,
        setPaymentDetails,
        submitting,
        openDialog,
        paymentDetails,
        showPayment,
        setShowPayment,
        data
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default RejectPaymentProvider
