import React from "react"

export type CtxType = {
  setPayloads: Function
  setCategories: Function
  setModesOfPayment: Function
  setPaymentTypes: Function
  setActiveTab: Function
  updateBookkeeping: Function
  payloads: any
  categories: any
  paymentTypes: any
  modesOfPayments: any
  activeTab: number
}

export const Context = React.createContext<Partial<CtxType>>({})
