import * as React from "react"

const TotalRegisteredUnits = (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0V1H0V3H1V19H15V3H16V1H12V0H4ZM3 3H13V17H3V3ZM5 5V7H7V5H5ZM9 5V7H11V5H9ZM5 9V11H7V9H5ZM9 9V11H11V9H9ZM5 13V15H7V13H5ZM9 13V15H11V13H9Z"
      fill="#454B57"
    />
  </svg>
)

export default TotalRegisteredUnits
