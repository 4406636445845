import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.secondary.dark,
    fontSize: "20px"
  },
  link: {
    fontSize: "12px",
    color: "#777E86",
    borderBottom: "1px solid #777E86",
    cursor: "pointer",
    paddingTop: "1px"
  }
}))

export default styles
