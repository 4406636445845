import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  generalInfo: {
    margin: "0 auto",
    padding: "0 0 40px 0",
    "& .section": {
      width: "40%",
      marginBottom: "40px"
    }
  },
  twoCol: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "48%"
    }
  },
  spaceBottom: {
    paddingBottom: "20px"
  }
}))

export default styles
