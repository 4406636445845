import React, { useState, useContext } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { CtxMenuContext } from "components/ContextMenu"
/** Components */
import Dialog from "components/Dialog"
/** Context */
import withContext from "context/Dashboard/UnitManagement/categories/main/withContext"
import {
  Context as CategoriesMainCtx,
  CtxType
} from "context/Dashboard/UnitManagement/categories/main/Context"
import { AppContext } from "App"

/** Styles */
import { IconView, IconDelete } from "components/Icons/ContextMenu"
import styles from "./styles"
/** Icons */

interface ContextUnitManagementProps extends CtxType {
  data: Record<string, any>
  showEditPs: Function
  showPSDetails: Function
}

const ContextPaymentSchedule: React.FC<ContextUnitManagementProps> = ({
  data,
  showPSDetails,
  activeFilter
}) => {
  const { listItem } = styles()
  const [openDialog, setOpenDialog] = useState(false)
  const { openContext } = useContext(CtxMenuContext)
  const { handlePSArchive } = React.useContext(CategoriesMainCtx)
  const { permission } = useContext(AppContext)
  // const canEdit = permission?.unit_mgt_edit && permission?.payment_schedule_edit
  const canRemove =
    permission?.unit_mgt_remove && permission?.payment_schedule_remove

  function handleDialog() {
    setOpenDialog(true)
  }

  function archivePS(itemId: string) {
    openContext && openContext(false)
    handlePSArchive && handlePSArchive(itemId)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={listItem}
          button
          onClick={(): void => showPSDetails(data)}
        >
          <i>{IconView}</i>
          View Details
        </ListItem>
        {activeFilter !== 7 && (
          <ListItem
            className={listItem}
            button
            disabled={!canRemove}
            onClick={() => (!canRemove ? null : handleDialog())}
          >
            <i>{IconDelete}</i>
            Archived
          </ListItem>
        )}
      </List>
      <Dialog
        action={() => archivePS(data?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to archive the scheduled payment?"
      />
    </>
  )
}

export default withContext(ContextPaymentSchedule)
