import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex"
  },
  title: {
    color: theme.palette.body.secondary,
    fontSize: "16px",
    fontWeight: 600
  },
  totalStyle: {
    color: theme.palette.body.gray,
    fontSize: "64px",
    fontWeight: 500
  },
  body: {
    color: theme.palette.body.dark,
    fontSize: "14px",
    display: "flex"
  },
  tfrInfo: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "26px 50px"
  },
  feedbackList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 50px",
    borderTop: "1px solid #F2F2F2",
    "& .feedbackListName": {
      paddingLeft: "20px"
    }
  },
  subtitleStyle: {
    color: theme.palette.body.dark,
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 300,
    padding: "3px"
  }
}))

export default styles
