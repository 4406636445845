import React from "react"
import { dateIsAfter, timeFormat, dayName, dateSameOrAfter } from "utils/date"

export function handleFormChange(
  setFieldValue: Function,
  StateFunction: Function,
  StateObj: Record<string, any>,
  inputName: string,
  e: any
): void {
  if (e.target === undefined) {
    StateFunction({
      ...StateObj,
      [inputName]: e
    })
    setFieldValue(inputName, e)
  } else {
    StateFunction({
      ...StateObj,
      [inputName]: e?.target?.value
    })
    setFieldValue(inputName, e?.target?.value)
  }
}

export function handleResidentName(
  setFieldValue: Function,
  value: Record<string, any>,
  StateFunction: Function | undefined,
  StateObj: Record<string, any>,
  residentName?: any,
  residentUID?: any,
  residentDetailUID?: any,
  residentEmail?: any,
  residentPhone?: any,
  residentAddress?: any
): void {
  const residentInfo = JSON.parse(value.currentTarget?.dataset?.userinfo)
  setFieldValue([residentName], residentInfo?.name)

  return (
    StateFunction &&
    StateFunction({
      ...StateObj,
      [residentName]: residentInfo?.name,
      [residentUID]: residentInfo?.account_info_uid,
      [residentDetailUID]: residentInfo?._uid,
      [residentEmail]: residentInfo?.email,
      [residentPhone]: residentInfo?.phone_no,
      [residentAddress]: residentInfo?.registered_address
    })
  )
}

export function handleBlockUnit(
  setFieldValue: Function,
  value: string | boolean,
  StateFunction: Function | undefined,
  StateObj: Record<string, any>,
  unitUID: string,
  residentUID?: any,
  residentName?: any
): void {
  setFieldValue([residentUID], "")
  setFieldValue([unitUID], value).then(
    () =>
      StateFunction &&
      StateFunction({
        ...StateObj,
        [unitUID]: value,
        [residentUID]: "",
        [residentName]: ""
      })
  )
}

/** Booking */
export function timeSettings(datetime: string, settings: Record<string, any>) {
  switch (dayName(datetime)) {
    case "Sunday":
      return settings?.holiday_time_slots
    case "Saturday":
      return settings?.saturday_time_slots
    default:
      return settings?.weekday_time_slots
  }
}

export function checkBooking(
  datetime: string,
  settings: Record<string, any>,
  stateFunc: Function,
  skipTime = false
) {
  if (skipTime) {
    return stateFunc(dateIsAfter(datetime, settings?.allowedDate))
  }
  if (
    timeSettings(datetime, settings)[0]?.start_time === "" &&
    timeSettings(datetime, settings)[0]?.end_time === ""
  ) {
    return stateFunc(false && dateIsAfter(datetime, settings?.allowedDate))
  }

  return stateFunc(
    timeFormat(
      datetime,
      timeSettings(datetime, settings)[0]?.start_time,
      timeSettings(datetime, settings)[0]?.end_time
    ) && dateIsAfter(datetime, settings?.allowedDate)
  )
}

export function canBookDateRange(
  allowedDate: Record<string, any> | string,
  startDate: Record<string, any> | string,
  stateFunc: Function
): boolean {
  if (allowedDate === null) {
    return stateFunc(true)
  }

  return stateFunc(dateSameOrAfter(startDate, allowedDate))
}

export function isEmpty(obj: Record<string, any>): boolean {
  if (Object.entries(obj).length === 0) {
    return true
  }
  return false
}

export function overlap(dateRanges: any) {
  const sortedRanges = dateRanges.sort((previous: any, current: any) => {
    // get the start date from previous and current
    const previousTime = previous.startTime
    const currentTime = current.endTime
    // if the previous is earlier than the current

    if (previousTime < currentTime) {
      return -1
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0
    }

    // if the previous time is later than the current time
    return 1
  })

  const resultRanges = sortedRanges.reduce(
    (result: any, current: any, idx: any, arr: any) => {
      if (idx === 0) {
        return result
      }
      const previous = arr[idx - 1]

      const previousEnd = previous.endTime
      const currentStart = current.startTime
      const overlapRange = previousEnd > currentStart

      if (overlapRange) {
        result.overlap = true
        result.ranges.push({
          previous,
          current
        })
      }

      return result
    },
    { overlap: false, ranges: [] }
  )

  return resultRanges
}

// Hook
export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef()

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
