import { SET_VMS_TABLE } from "../constants"

const initialState = {
  tableData: []
}

export default function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_VMS_TABLE:
      return {
        tableData: action.table
      }
    default:
      return state
  }
}
