import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  twoCol: {
    display: "flex",
    alignItems: "flex-start",
    "& > div": {
      width: "30%"
    }
  }
}))

export default styles
