import React from "react"

import RenovationProvider from "context/Dashboard/Applications/categories/renovation/Provider"
import RenovationView from "context/Dashboard/Applications/categories/renovation/View"

export default () => (
  <RenovationProvider>
    <RenovationView />
  </RenovationProvider>
)
