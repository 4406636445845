import React from "react"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
/**
 * Components
 */
import Loader from "components/Loader"
/**
 * Icon
 */
import { IconAccountRequest } from "components/Icons/Dashboard/"
/**
 * Styles
 */
import styles from "./styles"
/**
 * Interface
 */
interface Props {
  blockTitle: string
  isLoading: boolean
  totalCount: number
  date: string | undefined
  hasIcon?: boolean
  url?: string
}

const AccountsBlock: React.FC<Props> = ({
  isLoading,
  totalCount,
  date,
  blockTitle,
  hasIcon = false,
  url
}) => {
  const { viewAll, title, body, totalStyle, wrapper, totalWrapper } = styles()

  function ViewCount() {
    if (isLoading) {
      return <Loader dark />
    }

    return (
      <Box className={totalWrapper}>
        <Fade in={!isLoading} timeout={1000}>
          <Box className={totalStyle}>{totalCount}</Box>
        </Fade>
      </Box>
    )
  }

  return (
    <Box className={wrapper} borderBottom="1px solid #F2F2F2">
      <Box display="flex" padding="0 23px">
        {!hasIcon ? (
          <Box paddingRight="45px" />
        ) : (
          <Box paddingRight="25px">{IconAccountRequest}</Box>
        )}
        <Box>
          <Box className={title}>{blockTitle}</Box>
          <Box className={body}>{`As of ${date}`}</Box>
          <Box className={viewAll}>
            <Button color="primary" size="large" component={Link} to={url || ""}>
              <Box fontWeight="600">VIEW ALL</Box>
            </Button>
          </Box>
        </Box>
      </Box>

      <ViewCount />
    </Box>
  )
}

export default AccountsBlock
