import React, { useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"

/** Component */
import Label from "components/Forms/Label"

interface NamesProps {
  selectName: string
  value: string
  onChange: Function
  onBlur?: Function
  error?: boolean
  disabled?: boolean
  idValue?: boolean
  helperText?: string | boolean | undefined
  label?: string
  placeholder: string
  items: Record<string, any>
  rootClass?: any
  noHelper?: boolean
  displayEmpty?: boolean
}

const FormSelect: React.FC<NamesProps> = ({
  value,
  onChange,
  onBlur,
  helperText,
  placeholder,
  error = false,
  disabled = false,
  idValue = false,
  displayEmpty = true,
  items,
  selectName,
  label,
  rootClass,
  noHelper = true
}) => {
  const [isLoading] = useState(false)

  return (
    <Box>
      <Label label={label || ""} />
      <FormControl
        variant="outlined"
        error={error}
        disabled={disabled}
        className={rootClass || ""}
      >
        {isLoading ? (
          <Select
            displayEmpty
            value={value}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur && onBlur(e)}
            error={error}
            name="blockUnit"
          >
            <MenuItem value="" disabled>
              Loading...
            </MenuItem>
          </Select>
        ) : (
          <>
            <Select
              displayEmpty={displayEmpty}
              value={value}
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur && onBlur(e)}
              error={error}
              name={selectName}
            >
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
              {Object.keys(items).map((item: any) => {
                return (
                  <MenuItem
                    className={items[item]}
                    value={idValue ? item : items[item]}
                  >
                    {items[item]}
                  </MenuItem>
                )
              })}
            </Select>
            {noHelper && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
      </FormControl>
    </Box>
  )
}
export default FormSelect
