import request from "services/request"
import API from "./endpoints"
import Cookies from "js-cookie"

function headers() {
  return {
    Authorization: `Bearer ${Cookies.get("bearer")}`
  }
}

function getResidents() {
  return request({
    url: API.LIST_RESIDENTS,
    method: "GET",
    headers: headers(),
    params: {
      "condo_uid": Cookies.get("condoUID")
    }
  })
}

function getRolePermissions(roleId: string) {
  return request({
    url: `${API.LIST_ROLE_PERMISSION}/${roleId}`,
    method: "GET",
    headers: headers()
  })
}

function updatePermission(payload: Record<string, any>, roleId: string) {
  return request({
    url: `${API.UPDATE_PERMISSION}/${roleId}`,
    method: "PUT",
    headers: headers(),
    data: {
      "permission": payload
    }
  })
}

const residentSettings = {
  getResidents,
  updatePermission,
  getRolePermissions
}

export default residentSettings
