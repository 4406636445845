import React, { useState } from "react"
import { toast } from "react-toastify"
import Cookie from "js-cookie"

/** Services */
import service from "services/Dashboard/EstateMatters"

import { UploadImage } from "utils/uploadFile"

/** Context */
import { Context as ContextMain } from "context/Dashboard/EstateMatters/ConstructionManuals/main/Context"
import { toUnix } from "utils/date"
import { Context } from "./Context"

/** Utils */

export interface ProviderProps {
  data?: Record<string, any>
  sendBack: Function
}

const Provider: React.FC<ProviderProps> = ({ children, data, sendBack }) => {
  const { filterTabs } = React.useContext(ContextMain)
  const hasData = data !== undefined
  const [uploadError, setUploadError] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [fileAttaching, setFileAttaching] = useState(false)
  const [files, setFiles] = useState<any>(
    hasData ? [data?.file] : data?.file || Array
  )
  const [docDetails, setDocDetails] = useState({
    condoUID: data?.condo_uid || "",
    serialNo: data?.serial_no || "",
    title: data?.title || "",
    description: data?.description || "",
    remarks: data?.remarks || "",
    quantity: data?.quantity || "",
    frequency: data?.frequency || 0,
    startDate: data?.start_date || null,
    endDate: data?.end_date || null,
    file: data?.file || {},
    estateMatterFolder: data?.estate_matter_folder_uid || filterTabs[0]?._uid
  })

  /** Notification */
  const notifyCreate = () =>
    toast(`Successfully ${hasData ? "updated" : "created"} the document.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleDocument = async () => {
    const dd = docDetails
    const payload = {
      "condo_uid": Cookie.get("condoUID"),
      "title": String(dd?.title),
      "serial_no": String(dd?.serialNo),
      "description": String(dd?.description),
      "quantity": Number(dd?.quantity),
      "frequency": Number(dd?.frequency),
      "remarks": String(dd?.remarks),
      "start_date": toUnix(dd?.startDate),
      "end_date": toUnix(dd?.endDate),
      "file": {
        ...files[0],
        "file_type": 2
      },
      "estate_matter_folder_uid": String(dd?.estateMatterFolder)
    }
    setSubmitting(true)

    const endpoint = hasData
      ? service.updateEstateMatter(payload, data?._uid)
      : service.createEstateMatter(payload)

    try {
      const response = await endpoint
      setSubmitting(false)
      notifyCreate()
      return response?.data?._data
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  function handleFileUpload(event: any) {
    setUploadError(false)
    setFileAttaching(true)
    UploadImage(event, 2)
      .then((output: any) => {
        setFiles([...files, output])
        setFileAttaching(false)
      })
      .catch(() => setFileAttaching(false))
  }

  function removeFile(value: number) {
    const fileFiltered = files.filter((item: any, index: number) => {
      return index !== value
    })
    setFiles(fileFiltered)
  }

  function handleFormChange(
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setDocDetails &&
        setDocDetails({
          ...docDetails,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setDocDetails &&
        setDocDetails({
          ...docDetails,
          [name]: e?.target?.value
        })
    }
  }

  return (
    <Context.Provider
      value={{
        hasData,
        handleFormChange,
        handleDocument,
        setOpenDialog,
        setDocDetails,
        setFiles,
        handleFileUpload,
        removeFile,
        setUploadError,
        sendBack,
        uploadError,
        docDetails,
        fileAttaching,
        openDialog,
        files,
        filterTabs,
        isSubmitting
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
