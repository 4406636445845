import React from "react"

const Approve = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C14.4301 2 18 5.56988 18 10C18 14.4301 14.4301 18 10 18C5.56988 18 2 14.4301 2 10C2 5.56988 5.56988 2 10 2ZM14.293 6.29297L8 12.5859L5.70703 10.293L4.29297 11.707L8 15.4141L15.707 7.70703L14.293 6.29297Z"
      fill="#09707B"
    />
  </svg>
)

export default Approve
