import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import { useMachine } from "@xstate/react"

/** Components */
import Table from "pages/Settings/PropertyManagement/Applications/tables/main"
import PageHeaderTitle from "components/PageHeaderTitle"
import WithHeader from "layout/WithHeader"
import WithTable from "layout/WithTable"
import CategoryForm from "pages/Settings/PropertyManagement/Applications/components/CategoryForm"
import PermissionMessage from "components/PermissionMessage"

/** Machine */
import { SettingsApplicationsMachine } from "machines/Settings/PropertyManagement/Applications"

/** Context */
import { AppContext } from "App"
import styles from "./styles"

const SettingsApplications: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [current, send] = useMachine(SettingsApplicationsMachine)
  const xValue = current?.value

  const { permission } = useContext(AppContext)
  const hasPermission = permission && Object.keys(permission).length !== 0

  /** Views */
  const ViewTable = (): JSX.Element => {
    if (xValue !== "tableView") {
      return <div />
    }
    const { title } = styles()
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <PageHeaderTitle
              title="Applications"
              breadcrumbs="SETTINGS / Applications"
            />
          </Box>
        </Box>

        <WithTable>
          <Box>
            <div className={title}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 3C3.90694 3 3 3.90694 3 5V19C3 20.0931 3.90694 21 5 21H19C20.0931 21 21 20.0931 21 19V5C21 3.90694 20.0931 3 19 3H5ZM5 5H19V9H5V5ZM7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8C7.26522 8 7.51957 7.89464 7.70711 7.70711C7.89464 7.51957 8 7.26522 8 7C8 6.73478 7.89464 6.48043 7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6ZM10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7C9 7.26522 9.10536 7.51957 9.29289 7.70711C9.48043 7.89464 9.73478 8 10 8C10.2652 8 10.5196 7.89464 10.7071 7.70711C10.8946 7.51957 11 7.26522 11 7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6ZM13 6V8H17V6H13ZM5 11H19V19H5V11ZM8.5 12C8.10218 12 7.72064 12.158 7.43934 12.4393C7.15804 12.7206 7 13.1022 7 13.5C7 13.8978 7.15804 14.2794 7.43934 14.5607C7.72064 14.842 8.10218 15 8.5 15C8.89782 15 9.27936 14.842 9.56066 14.5607C9.84196 14.2794 10 13.8978 10 13.5C10 13.1022 9.84196 12.7206 9.56066 12.4393C9.27936 12.158 8.89782 12 8.5 12ZM13 13V15H17V13H13ZM8.5 16C7.666 16 6 16.419 6 17.25V18H11V17.25C11 16.419 9.334 16 8.5 16ZM13 16V18H17V16H13Z"
                  fill="#454B57"
                />
              </svg>
              <span>Applications</span>
            </div>
          </Box>
          <Table
            showEdit={() => {
              send("UPDATE_CATEGORY")
            }}
          />
        </WithTable>
      </>
    )
  }

  const ViewSettings = (): JSX.Element => {
    if (xValue !== "addCategory") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Add Category"
              breadcrumbs="SETTINGS / application / add category"
              backAction={() => send("TABLE_VIEW")}
            />
          </Box>
        </Box>
        <CategoryForm sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const ViewUpdateCategory = (): JSX.Element => {
    if (xValue !== "updateCategory") {
      return <div />
    }
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Update Category"
              breadcrumbs="SETTINGS / application / update category"
              backAction={() => send("TABLE_VIEW")}
            />
          </Box>
        </Box>
        <CategoryForm sendBack={() => send("TABLE_VIEW")} />
      </>
    )
  }

  const MainView = (): JSX.Element => {
    return (
      <WithHeader>
        <ViewTable />
        <ViewSettings />
        <ViewUpdateCategory />
      </WithHeader>
    )
  }

  if (!hasPermission) {
    return <div />
  }

  if (permission?.manage_condo_settings) {
    return <>{MainView()}</>
  }

  return (
    <WithHeader>
      <WithTable>
        <PermissionMessage />
      </WithTable>
    </WithHeader>
  )
}

export default SettingsApplications
