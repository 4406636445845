import MovingInOut from "components/Icons/MovingInOut"
import PetRegistration from "components/Icons/PetRegistration"
import BulkyDelivery from "components/Icons/BulkyDelivery"
import AccessCard from "components/Icons/AccessCard"
import AirconCleaning from "components/Icons/AirconCleaning"
import BikeTag from "components/Icons/BikeTag"
import VehicleRegistration from "components/Icons/VehicleRegistration"
import Renovation from "components/Icons/Renovation"
import AccessCards from "components/Icons/UnitManagement/AccessCards"

const Menu = [
  {
    label: "Vehicle Registration",
    icon: VehicleRegistration,
    value: "vehicleregistration",
    color: "#509ED7"
  },
  {
    label: "Moving In/Out",
    icon: MovingInOut,
    value: "movinginout",
    color: "#21B6BF"
  },
  {
    label: "Access Cards",
    icon: AccessCards,
    value: "accesscard",
    color: "#004E8B"
  },
  {
    label: "Renovation",
    icon: Renovation,
    value: "renovation",
    color: "#004E8B"
  }
]

const MenuIcons: {
  [index: string]: {}
} = {
  "movinginout": MovingInOut,
  "petregistration": PetRegistration,
  "bulkydelivery": BulkyDelivery,
  "accesscard": AccessCard,
  "airconcleaning": AirconCleaning,
  "biketag": BikeTag,
  "vehicleregistration": VehicleRegistration,
  "renovation": Renovation
}

export default Menu

export { MenuIcons }
