import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(() => ({
  bookingDetailsInfo: {
    display: "flex",
    alignItems: "flex-start",
    "& > div": {
      width: "40%"
    }
  },
  bookingDetails: {
    margin: "auto",
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "40px",
    marginBottom: "20px"
  },
  receivedBy: {
    width: "60%",
    margin: "0",
    padding: "40px 0",
    marginBottom: "20px"
  }
}))

export default styles
