import * as React from "react"

const Delete = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L6 1H0V3H1.10938L2.90234 18.2344C3.01959 19.234 3.882 20 4.88867 20H13.1113C14.118 20 14.9804 19.234 15.0977 18.2344L16.8906 3H18V1H16H12L11 0H7ZM3.12305 3H4H14.877L13.1113 18H4.88867L3.12305 3ZM6 5V15C6 15.552 6.448 16 7 16H8V5H6ZM10 5V16H11C11.552 16 12 15.552 12 15V5H10Z"
      fill="#09707B"
    />
  </svg>
)

export default Delete
