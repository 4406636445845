import React from "react"
import FormControl from "@material-ui/core/FormControl"
import Box from "@material-ui/core/Box"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import Fade from "@material-ui/core/Fade"
import MenuItem from "@material-ui/core/MenuItem"
import useSWR from "swr"

// service
import service from "services/Dashboard/Common"

interface StaffProps {
  value: any
  category: number | string
  error?: boolean
  disabled?: boolean
  helperText?: any
  handleChange: Function
  onBlur: Function
}

const Staff: React.FC<StaffProps> = ({
  value,
  category,
  error,
  helperText,
  handleChange,
  onBlur,
  disabled = false
}) => {
  function serviceCall() {
    if (Number(category) === 1) {
      return service.getAdminList()
    }
    return service.getStaffList({
      "user_role_category": category
    })
  }

  const fetchStaff = async () => serviceCall()

  const { data, isValidating } = useSWR(`fetchStaff-${category}`, fetchStaff, {
    revalidateOnFocus: true
  })
  const staffs = data?.data?._data

  if (isValidating) {
    return (
      <FormControl variant="outlined">
        <Select
          displayEmpty
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => onBlur(e)}
        >
          <MenuItem value="">Select Name</MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <Box>
        <FormControl variant="outlined" error={error}>
          <Select
            displayEmpty
            value={value}
            onChange={(e: any) => handleChange(e)}
            onBlur={(e) => onBlur(e)}
            error={error}
            name="staffName"
            disabled={disabled}
            defaultValue={value}
          >
            <MenuItem value="" disabled>
              Select Name
            </MenuItem>
            {staffs &&
              staffs.map((staff: any) => {
                return (
                  <MenuItem
                    value={`${staff?.account_uid}_${staff?.account_name}`}
                    key={staff?.account_uid}
                  >
                    {staff?.account_name}
                  </MenuItem>
                )
              })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Box>
    </Fade>
  )
}
export default Staff
