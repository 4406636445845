import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  timelineTitle: {
    color: theme.palette.body.main,
    fontSize: "14px"
  },
  timelineSubtitle: {
    color: theme.palette.body.secondary,
    fontSize: "12px"
  },
  dot: {
    width: "8px",
    height: "8px",
    backgroundColor: theme.palette.body.pink,
    position: "absolute",
    top: "5px",
    left: "-25px",
    borderRadius: "50%"
  },
  totalStyle: {
    color: theme.palette.body.green,
    fontSize: "48px",
    fontWeight: 500,
    "& span": {
      fontSize: "10px",
      paddingRight: "5px"
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "16px 23px",
    borderBottom: "1px solid #F2F2F2"
  },
  total: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 23px 0"
  },
  totalAmount: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 23px 0"
  },
  breakdown: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "15px",
    marginBottom: "15px",
    position: "relative"
  },
  content: {
    borderLeft: "3px solid #EDF0F1",
    padding: "10px 20px 0 20px",
    margin: "30px 30px 30px"
  }
}))

export default styles
