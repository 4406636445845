import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    position: "sticky",
    top: "40px"
  },
  wrapper: {
    width: "316px",
    margin: "auto",
    paddingBottom: "15px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px"
  },
  previewHeader: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    borderBottom: "1px solid #F2F2F2",
    padding: "20px"
  },
  previewTitle: {
    fontSize: "20px",
    fontWeight: 600,
    padding: "10px 20px 0",
    wordBreak: "break-word"
  },
  previewContent: {
    padding: "10px 20px",
    wordBreak: "break-word",
    height: "auto",
    maxHeight: "260px",
    overflow: "hidden"
  },
  previewImage: {
    padding: "15px 0",
    maxHeight: "250px",
    overflow: "hidden"
  }
}))

export default styles
