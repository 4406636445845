import React, { createContext } from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"

import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  menuContainer: {
    "& > .MuiPaper-root": {
      minWidth: "238px"
    },
    "& .MuiList-root": {
      padding: 0
    }
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    padding: "12px 22px",
    borderBottom: "1px solid #F0F3F7",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& svg > path": {
        fill: theme.palette.common.white
      }
    },
    "& span": {
      paddingRight: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}))

const Icon = (
  <svg
    width="4"
    height="18"
    viewBox="0 0 4 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0C0.448 0 0 0.448 0 1V3C0 3.552 0.448 4 1 4H3C3.552 4 4 3.552 4 3V1C4 0.448 3.552 0 3 0H1ZM1 7C0.448 7 0 7.448 0 8V10C0 10.552 0.448 11 1 11H3C3.552 11 4 10.552 4 10V8C4 7.448 3.552 7 3 7H1ZM1 14C0.448 14 0 14.448 0 15V17C0 17.552 0.448 18 1 18H3C3.552 18 4 17.552 4 17V15C4 14.448 3.552 14 3 14H1Z"
      fill="#777E86"
    />
  </svg>
)

/*
  Status: Approved
  Set to pending
  Reject
  Edit
  Cancel Booking
*/

/*
  Status: Pending
  Approved
  Reject
  Edit
  Cancel Booking
*/

export type AddBookingCtxType = {
  openContext: Function
}

// context

export const CtxMenuContext = createContext<Partial<AddBookingCtxType>>({})

interface ContextMenuProps {
  setopenDetails?: Function
  openDetails?: boolean
  children?: any
  testID?: string
}

const ContextMenu: React.FC<ContextMenuProps> = ({ children, testID }) => {
  const { menuContainer } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="context-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        data-testid={testID}
      >
        {Icon}
      </Button>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={menuContainer}
      >
        <CtxMenuContext.Provider
          value={{
            openContext: setAnchorEl
          }}
        >
          {children}
        </CtxMenuContext.Provider>
      </Menu>
    </div>
  )
}

export const CtxMenuContextConsumer = CtxMenuContext.Consumer
export default ContextMenu
