import React, { useState, useContext } from "react"
import { toast } from "react-toastify"

/** Context */
import { Context as RootContext } from "context/Dashboard/UnitManagement/categories/main/Context"

/** Service */
import service from "services/Dashboard/Applications"

/** Utils */
import { UploadImage } from "utils/uploadFile"
import { toUnix, fromUnix } from "utils/date"
import { Context } from "./Context"

export interface CreateVehicleProviderProps {
  data?: Record<string, any>
  successCallback?: Function
}

const CreateVehicleProvider: React.FC<CreateVehicleProviderProps> = ({
  data,
  successCallback,
  children
}) => {
  /** Context */
  const { showMain } = useContext(RootContext)
  const hasData = data !== undefined
  const [openDialog, setOpenDialog] = useState(false)
  const [photos, setPhotos] = useState(data?.vehicle_photo?.photos || Array)
  const [localPhotos, setLocalPhotos] = useState(
    data?.vehicle_photo?.photos || Array
  )
  const [attaching, setAttaching] = useState(false)
  const [vehicleDetails, setVehicleDetails] = useState({
    blockUnit: data?.unit_uid || "",
    ownerName: data?.owner_name || "",
    ownerAccountUID: data?.owner_account_uid || "",
    vehicleType: data?.vehicle_type || "",
    carLabel: data?.car_label || "",
    labelActivationDate: "",
    labelStatus: "",
    vehicleNumber: data?.vehicle_no || "",
    vehicleModel: data?.vehicle_model || "",
    IUnumber: data?.iu_no || "",
    activationDate:
      data?.activation_date !== undefined ? fromUnix(data?.activation_date) : null,
    expiryDate: data?.expiry_date !== undefined ? fromUnix(data?.expiry_date) : null
  })

  /** Notification */
  const notifyCreate = (action: string) =>
    toast(`Successfully ${action} the vehicle data.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  const handleRegisterVehicle = async () => {
    const vd = vehicleDetails
    const payload = {
      "unit_uid": vd?.blockUnit,
      "owner_account_uid": vd?.ownerAccountUID,
      "owner_name": vd?.ownerName,
      "vehicle_model": vd?.vehicleModel,
      "vehicle_no": vd?.vehicleNumber,
      "vehicle_type": vd?.vehicleType,
      "car_label": vd?.carLabel,
      "iu_no": vd?.IUnumber,
      "activation_date":
        vd?.activationDate === null ? null : toUnix(vd?.activationDate),
      "expiry_date": vd?.expiryDate === null ? null : toUnix(vd?.expiryDate),
      "vehicle_photo": {
        "photos": photos
      }
    }

    const endpoint = hasData
      ? service.updateVehicle(payload, data?._uid)
      : service.createVehicle(payload)

    try {
      const response = endpoint
      response.then(() => {
        notifyCreate(hasData ? "updated" : "created")

        if (successCallback === undefined) {
          showMain && showMain()
        } else {
          successCallback && successCallback()
        }
      })

      return response
    } catch (e) {
      return e
    }
  }

  function handleDocsUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch(() => setAttaching(false))
  }

  const removeDoc = (value: number) => {
    const docsFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })

    const fileLocalFiltered = localPhotos.filter((item: any, index: number) => {
      return index !== value
    })

    setPhotos(docsFiltered)
    setLocalPhotos(fileLocalFiltered)
  }

  return (
    <Context.Provider
      value={{
        handleRegisterVehicle,
        setOpenDialog,
        setVehicleDetails,
        setPhotos,
        setAttaching,
        handleDocsUpload,
        removeDoc,
        attaching,
        vehicleDetails,
        openDialog,
        photos,
        localPhotos
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CreateVehicleProvider
