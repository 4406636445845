import React, { useContext, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
/**
 * Components
 */
import FormWrapper from "components/Forms/FormWrapper"
import TimePicker from "components/Forms/TimePicker"
import RefButton from "components/Forms/RefButton"
import Toast from "components/Toast"
/**
 * Context
 */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/category/Context"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/category/View"
/**
 * Utils
 */
import { formatTime } from "utils/date"
/**
 * Styles
 */
import styles from "./styles"

const IconRemove = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L6 1H0V3H1.10938L2.90234 18.2344C3.01959 19.234 3.882 20 4.88867 20H13.1113C14.118 20 14.9804 19.234 15.0977 18.2344L16.8906 3H18V1H16H12L11 0H7ZM3.12305 3H4H14.877L13.1113 18H4.88867L3.12305 3ZM6 5V15C6 15.552 6.448 16 7 16H8V5H6ZM10 5V16H11C11.552 16 12 15.552 12 15V5H10Z"
      fill="#777E86"
    />
  </svg>
)

const FormTimeSlots: React.FC = () => {
  const classes = styles()
  const {
    timeSlots,
    handleTimeChange,
    addTimeSlot,
    removeTimeSlot,
    timeoverlap,
    checkHasBlank
  } = useContext(Context)

  function timeRanges() {
    return timeoverlap?.ranges?.map((tRange: Record<string, any>) => {
      const current = `${formatTime(tRange?.current?.startTime)} - ${formatTime(
        tRange?.current?.endTime
      )}`
      const previous = `${formatTime(tRange?.previous?.startTime)} - ${formatTime(
        tRange?.previous?.endTime
      )}`
      const overlapMessage = `* ${current} overlaps ${previous}`
      return Toast(overlapMessage, false)
    })
  }

  useEffect(() => {
    timeRanges()
  }, [timeoverlap?.overlap])

  return (
    <Box>
      <Formik
        initialValues={{
          startTime: null,
          endTime: null
        }}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
      >
        {({ touched, errors, handleBlur, handleSubmit }): JSX.Element => {
          return (
            <form>
              <Box>
                <Box className={classes.container}>
                  <FormWrapper title="Time Slot">
                    {timeSlots &&
                      timeSlots.map((timeslot: any, idx: number) => {
                        return (
                          <Box className={classes.section}>
                            <Box flex="1">
                              <TimePicker
                                label="Start Time"
                                name="startTime"
                                value={timeslot.startTime}
                                minutesStep={10}
                                ampm={false}
                                handleDateChange={(value: string): void => {
                                  return (
                                    handleTimeChange &&
                                    handleTimeChange(value, idx, "startTime")
                                  )
                                }}
                                onBlur={(e: Event): void => handleBlur(e)}
                                error={
                                  touched.startTime && Boolean(errors.startTime)
                                }
                              />
                            </Box>
                            <Box flex="1">
                              <TimePicker
                                label="End Time"
                                name="endTime"
                                value={timeslot.endTime}
                                minutesStep={10}
                                ampm={false}
                                handleDateChange={(value: string): void =>
                                  handleTimeChange &&
                                  handleTimeChange(value, idx, "endTime")}
                                onBlur={(e: Event): void => handleBlur(e)}
                                error={touched.endTime && Boolean(errors.endTime)}
                              />
                            </Box>
                            {timeSlots.length !== 1 ? (
                              <Button
                                onClick={() => removeTimeSlot && removeTimeSlot(idx)}
                              >
                                {IconRemove}
                              </Button>
                            ) : (
                              <div />
                            )}
                          </Box>
                        )
                      })}

                    <Box className={classes.addTimeSlotBtn}>
                      <Button
                        color="primary"
                        onClick={() => addTimeSlot && addTimeSlot()}
                      >
                        Add Time Slot
                      </Button>
                    </Box>
                  </FormWrapper>
                </Box>
                <Box className={classes.errorContainer}>
                  {timeoverlap?.overlap && (
                    <Box component="small" color="red">
                      * There is timeslot overlap, Please fix first to proceed.
                    </Box>
                  )}
                  {checkHasBlank && checkHasBlank() && (
                    <Box component="small" color="red">
                      * Start time and End time cannot be blank.
                    </Box>
                  )}
                </Box>
              </Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormTimeSlots
