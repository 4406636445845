import React from "react"

import FeedbackDefectsProvider from "context/Dashboard/Feedback/categories/defects/Provider"
import FeedbackDefectsView from "context/Dashboard/Feedback/categories/defects/View"

const FeedbackDefectsProviderMain: React.FC = (): JSX.Element => (
  <FeedbackDefectsProvider>
    <FeedbackDefectsView />
  </FeedbackDefectsProvider>
)

export default FeedbackDefectsProviderMain
