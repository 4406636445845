const BookkeepingConfig: any = {
  frequency: {
    0: "All",
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Quarterly",
    5: "Half Yearly",
    6: "Yearly"
  },
  frequencyPS: {
    0: "All",
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Quarterly",
    5: "Half Yearly",
    6: "Yearly",
    7: "Archived"
  },
  paymentType: {
    1: "Payment",
    2: "Deposit",
    3: "Refund"
  },
  paymentTypeCreate: {
    1: "Payment",
    2: "Deposit"
  },
  paymentMethod: {
    1: "Cash",
    2: "Cheque",
    3: "E-Payment",
    4: "Cora Wallet",
    5: "Credit Card"
  },
  paymentMethodFacilities: {
    1: "Cash",
    2: "Cheque",
    3: "E-Transfer",
    4: "Available Balance"
  },
  paymentCategory: {
    1: "Facilities",
    2: "Applications",
    3: "Maintenance",
    4: "Others"
  },
  refundPolicy: {
    1: "Refund to User",
    2: "Refund to Cora Wallet"
  },
  status: {
    1: "Pending",
    2: "Processing",
    3: "Closed",
    4: "Refunded",
    5: "Rejected",
    6: "Cancelled"
  },
  statusColor: {
    1: "#FBA11A",
    2: "#21A03D",
    3: "#000000",
    4: "#777E86",
    5: "#DE4625",
    6: "#7424D9"
  },
  tableFilter: {
    0: "All",
    1: "Payment",
    2: "Deposit",
    3: "Outstanding Payments",
    4: "Refund"
  },
  tableName: {
    0: "fetchAllPayments",
    1: "fetchPayments",
    2: "fetchDepositPayments",
    3: "fetchOutstandingPayments",
    4: "fetchRefunds"
  }
}

/**
 * const (
	BKPaymentTypePayment int = 1
	BKPaymentTypeDeposit     = 2
	BKPaymentTypeRefund      = 3
)
const (
	BKPaymentMethodCash       int = 1
	BKPaymentMethodCheque         = 2
	BKPaymentMethodEPayment       = 3
	BKPaymentMethodCoraWallet     = 4
	BKPaymentMethodCreditCard     = 5
)
const (
	BKPaymentRefundPolicyRefundToUser       int = 1
	BKPaymentRefundPolicyRefundToCoraWallet     = 2
)
const (
	BKPaymentScheduledPaymentFrequencyDaily      int = 1
	BKPaymentScheduledPaymentFrequencyWeekly         = 2
	BKPaymentScheduledPaymentFrequencyMonthly        = 3
	BKPaymentScheduledPaymentFrequencyQuarterly      = 4
	BKPaymentScheduledPaymentFrequencyHalfYearly     = 5
	BKPaymentScheduledPaymentFrequencyYearly         = 6
)
const (
	BKPaymentStatusPending    int = 1
	BKPaymentStatusProcessing     = 2
	BKPaymentStatusClosed         = 3
	BKPaymentStatusRefunded       = 4
	BKPaymentStatusRejected       = 5
	BKPaymentStatusCancelled      = 6
)
 */

export const {
  frequency,
  frequencyPS,
  paymentType,
  paymentTypeCreate,
  paymentMethod,
  paymentMethodFacilities,
  paymentCategory,
  refundPolicy,
  status,
  statusColor,
  tableFilter,
  tableName
} = BookkeepingConfig
