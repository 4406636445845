import useSWR from "swr"
import service from "services/Dashboard/Dashboard"
// import { toNumeral } from "utils/helpers"
import { formatDate, toUnix } from "utils/date"

export function ScheduleServicingHook(date: any) {
  const unixFrom = toUnix(`${formatDate(date?.startDate)} 00:00:00`)

  const fetchScheduleServicing = async () =>
    service.getScheduleServicing({
      "current_timestamp": unixFrom
    })

  const { data, isValidating, error } = useSWR(
    `fetchScheduleServicing-${unixFrom}`,
    fetchScheduleServicing,
    {
      revalidateOnFocus: false
    }
  )

  const servicingData = data?.data?._data

  return {
    servicingData: servicingData || [],
    isValidating,
    error
  }
}
