import * as React from "react"

const AllEmployees: React.FC = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 0.666656V2.13541C8.06524 2.66939 6.00273 4.7319 5.46875 7.33332H4V9.99999H6.66667C6.66667 12.9298 9.07022 15.3333 12 15.3333C14.9298 15.3333 17.3333 12.9298 17.3333 9.99999H20V7.33332H18.5312C17.9973 4.7319 15.9348 2.66939 13.3333 2.13541V0.666656H10.6667ZM12 4.66666C13.7531 4.66666 15.2117 5.77722 15.7578 7.33332H8.24219C8.78831 5.77722 10.2469 4.66666 12 4.66666ZM9.33333 9.99999H14.6667C14.6667 11.4889 13.4889 12.6667 12 12.6667C10.5111 12.6667 9.33333 11.4889 9.33333 9.99999ZM12 16.6667C9.6734 16.6667 6.91622 17.213 4.59896 18.1276C3.44033 18.5849 2.39293 19.1281 1.55469 19.8255C0.716447 20.523 0 21.4658 0 22.6667V26H24V24.6667V22.6667C24 21.4658 23.2836 20.523 22.4453 19.8255C21.6071 19.1281 20.5597 18.5849 19.401 18.1276C17.0838 17.213 14.3266 16.6667 12 16.6667ZM12 19.3333C13.1728 19.3333 14.6065 19.5426 16 19.875V23.3333H8V19.875C9.39354 19.5426 10.8272 19.3333 12 19.3333ZM5.33333 20.7187V23.3333H2.66667V22.6667C2.66667 22.5795 2.75678 22.294 3.26042 21.875C3.71846 21.4939 4.46691 21.0824 5.33333 20.7187ZM18.6667 20.7187C19.5331 21.0824 20.2815 21.4939 20.7396 21.875C21.2432 22.294 21.3333 22.5795 21.3333 22.6667V23.3333H18.6667V20.7187Z"
        fill="white"
      />
    </svg>
  )
}

export default AllEmployees
