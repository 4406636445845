import React from "react"
import Image from "material-ui-image"
/** Components */
import Label from "components/Forms/Label"
/** Context */
import withDetailsContext from "context/Dashboard/Feedback/details/withDetailsContext"
import { DetailsCtxType } from "context/Dashboard/Feedback/details/DetailsContext"
/**
 * Utils
 */
import { checkValue } from "utils/helpers"
/** Styles */
import styles from "./styles"

interface MessageProps extends DetailsCtxType {
  reply: string
  date: string
  time: string
  byAdmin: boolean
  ownReply: boolean
  data: Record<string, any>
  details: any
}

const Message: React.FC<MessageProps> = (props) => {
  const { container, field, value, photoList, photoItem } = styles()
  const { reply, date, time, byAdmin, details } = props

  return (
    <>
      <div className={`${container}`}>
        {byAdmin && <div className={field}>Feedback by Admin</div>}
        <div className="two-col">
          <div className={field}>
            <Label label="Date" />
            <div className={value}>{date}</div>
          </div>

          <div className={field}>
            <Label label="Time" />
            <div className={value}>{time}</div>
          </div>
        </div>

        <div className={field}>
          <Label label="Description" />
          <div className={value}>{checkValue(reply)}</div>
        </div>

        <div className={photoList}>
          {details.feedback_reply_photo?.photos !== null &&
            details.feedback_reply_photo?.photos.map((photo: any) => {
              return (
                <div className={photoItem}>
                  <Image src={photo.url} aspectRatio={1 / 1} />
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default withDetailsContext(Message)
