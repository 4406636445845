import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import { Formik } from "formik"
/** Components */
import Roles from "components/Common/Roles"
import FormWrapper from "components/Forms/FormWrapper"
import FormInput from "components/Forms/FormInput"
import FormInputPassword from "components/Forms/FormInput/password"

/** Validation */
import {
  addStaff,
  updateStaff
} from "config/Settings/AccountManagement/Staff/validation"

/** Context */
import withContext from "context/Settings/AccountManagement/Staff/forms/staff/withContext"
import { Context } from "context/Settings/AccountManagement/Staff/forms/staff/Context"
import { Context as MainContext } from "context/Settings/AccountManagement/Staff/main/Context"
import styles from "./styles"

const AdminForm: React.FC = () => {
  const { container, section, label, footer, wrapper, containerDefault } = styles()
  const { setStaffDetails, staffDetails, handleFormSubmit } = useContext(Context)
  const { setShowForm, formType } = useContext(MainContext)
  const sd = staffDetails
  const validation = formType === 1 ? addStaff : updateStaff

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setStaffDetails && setStaffDetails((data: any) => ({ ...data, [name]: e }))
    } else {
      setFieldValue(name, e?.target?.value)
      setStaffDetails &&
        setStaffDetails((data: any) => ({ ...data, [name]: e?.target?.value }))
    }
  }

  const handleSubmitForm = (handleSubmit: Function): void => {
    handleSubmit()
    if (
      formType === 1 &&
      sd?.staffName !== "" &&
      sd?.staffUserName !== "" &&
      sd?.staffMobile !== "" &&
      sd?.role !== "" &&
      sd?.staffPassword !== ""
    ) {
      handleFormSubmit && handleFormSubmit()
    }

    if (
      formType === 2 &&
      sd?.staffName !== "" &&
      sd?.staffUserName !== "" &&
      sd?.staffMobile !== "" &&
      sd?.role !== ""
    ) {
      handleFormSubmit && handleFormSubmit()
    }
  }

  return (
    <Formik
      initialValues={{
        staffName: sd?.staffName,
        staffMobile: sd?.staffMobile,
        staffUserName: sd?.staffUserName,
        role: sd?.role,
        staffPassword: sd?.staffPassword
      }}
      onSubmit={(values, actions): void => {
        JSON.stringify(values, null, 2)
        actions.setSubmitting(false)
      }}
      validationSchema={validation}
    >
      {({
        touched,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue
      }): JSX.Element => {
        return (
          <form style={{ height: "100%" }}>
            <Box className={wrapper}>
              <Box flex="3">
                <FormWrapper>
                  <Box className={container}>
                    <Box className={section}>
                      <Box className={label}>Personal Details</Box>
                      <Box>
                        <Box marginBottom="25px">
                          <FormInput
                            name="staffName"
                            value={sd?.staffName}
                            idValue="staffName"
                            label="Name"
                            placeholder="Name"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("staffName", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={touched.staffName && Boolean(errors.staffName)}
                            helperText={
                              errors.staffName &&
                              touched.staffName &&
                              errors.staffName
                            }
                          />
                        </Box>
                        <Box marginBottom="25px">
                          <FormInput
                            disabled={formType === 2}
                            name="staffUserName"
                            value={sd?.staffUserName}
                            idValue="staffUserName"
                            userAdorment={sd?.suffix}
                            label="Username"
                            placeholder="Username"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("staffUserName", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.staffUserName && Boolean(errors.staffUserName)
                            }
                            helperText={
                              errors.staffUserName &&
                              touched.staffUserName &&
                              errors.staffUserName
                            }
                          />
                        </Box>
                        <Box marginBottom="25px">
                          <FormInput
                            name="staffMobile"
                            value={sd?.staffMobile}
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("staffMobile", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.staffMobile && Boolean(errors.staffMobile)
                            }
                            helperText={
                              errors.staffMobile &&
                              touched.staffMobile &&
                              errors.staffMobile
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={containerDefault}>
                    <Box className={section}>
                      <Box marginBottom="25px">
                        <Roles
                          userType={2}
                          label="Position"
                          value={sd?.role}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            handleFormChange("role", e, setFieldValue)}
                          onBlur={handleBlur}
                          error={touched.role && Boolean(errors.role)}
                          helperText={
                            touched.role && Boolean(errors.role) && errors.role
                          }
                        />
                      </Box>

                      {formType === 1 && (
                        <Box marginBottom="25px">
                          <FormInputPassword
                            name="staffPassword"
                            value={sd?.staffPassword}
                            label="Password"
                            placeholder="Password"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                              handleFormChange("staffPassword", e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.staffPassword && Boolean(errors.staffPassword)
                            }
                            helperText={
                              errors.staffPassword &&
                              touched.staffPassword &&
                              errors.staffPassword
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box className={footer}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => setShowForm && setShowForm(false)}
                  >
                    CANCEL
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm(handleSubmit)}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default withContext(AdminForm)
