import React from "react"

import SettingsFacilitiesProvider from "context/Settings/PropertyManagement/Facilities/main/Provider"
import SettingsFacilitiesView from "context/Settings/PropertyManagement/Facilities/main/View"

const SettingsFacilities: React.FC = () => (
  <SettingsFacilitiesProvider>
    <SettingsFacilitiesView />
  </SettingsFacilitiesProvider>
)

export default SettingsFacilities
