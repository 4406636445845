const version = "v1"
const base = "svc-bookkeeping"

const API = {
  SCHEDULED_PAYMENTS: `${base}/${version}/scheduled-payments`,
  CREATE_SCHEDULED_PAYMENT: `${base}/${version}/scheduled-payment`,
  ARCHIVE_SCHEDULE_PAYMENT: `${base}/${version}/archive-scheduled-payment`,
  CREATE_PAYMENT: `${base}/${version}/payment`,
  EDIT_PAYMENT: `${base}/${version}/payment`,
  FETCH_PAYMENTS: `${base}/${version}/payments`,
  CLOSE_PAYMENTS: `${base}/${version}/close-payment`,
  CANCEL_PAYMENTS: `${base}/${version}/cancel-payment`,
  REJECT_PAYMENTS: `${base}/${version}/reject-payment`,
  UPDATE_DEPOSIT_LOCK: `${base}/${version}/update-deposit-lock`,
  REFUND_REQUEST: `${base}/${version}/refund-request`,
  REFUND_CLOSE: `${base}/${version}/close-refund`,
  REFUND_CLOSE_PAYMENT: `${base}/${version}/close-refund-payment`,
  REFUND_CLOSE_BALANCE: `${base}/${version}/close-refund-balance`,
  REFUND_CANCEL: `${base}/${version}/cancel-refund`,
  // Stats
  OUTSTANDING_PAYMENTS: `${base}/${version}/condo-outstanding-payments`,
  TOTAL_COLLECTIONS: `${base}/${version}/condo-total-collections`
}

export default API
