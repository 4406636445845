import React from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Cookie from "js-cookie"
/** Service */
import service from "services/Settings/PropertyManagement/Applications"
/** Components */
import Loader from "components/Loader"
import Table from "components/Table"
import tableHeaders from "./table/tableHeaders"

interface Props {
  showEdit: Function
}

const TableSettingsApplications: React.FC<Props> = ({ showEdit }) => {
  const condoUID = Cookie.get("condoUID")

  /** Methods */
  const fetchSettingsApplication = async () =>
    service.getApplication({
      "condo_uid": condoUID
    })

  const { data, isValidating } = useSWR(
    "fetchSettingsApplication",
    fetchSettingsApplication,
    {
      revalidateOnFocus: true
    }
  )
  const tableData = data?.data?._data

  if (isValidating) {
    return <Loader dark />
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={tableData || []}
          columns={tableHeaders(showEdit)}
          minRows={2}
          hasHeaderGroup
        />
      </div>
    </Fade>
  )
}

export default TableSettingsApplications
