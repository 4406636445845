import React, { useContext } from "react"

/** Components */
import FilterButtons from "components/FilterButtons"

/** Context */
import { Context } from "context/Dashboard/EstateMatters/ManagementDocuments/main/Context"

const Filters: React.FC = () => {
  const { activeTable, filterTabs, selectTab } = useContext(Context)

  return (
    <>
      {filterTabs !== undefined &&
        Object.keys(filterTabs).map((item: any, index: number) => {
          return (
            <FilterButtons
              key={filterTabs[item]?.title}
              label={filterTabs[item]?.title}
              onClick={() => selectTab && selectTab(index, filterTabs[item])}
              isActive={activeTable === index}
            />
          )
        })}
    </>
  )
}
export default Filters
