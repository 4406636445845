import React, { useContext, useMemo } from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import DatePicker from "components/Forms/DatePicker"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/forms/category/Context"
/** Config */
import { addEffectiveDate } from "config/Settings/PropertyManagement/Facilities/validation"
/** Ref */
import { refSubmit } from "context/Settings/PropertyManagement/Facilities/forms/category/View"
/** Styles */
import styles from "./styles"

interface Props {
  setValid: Function
}

const FormEffectiveDate: React.FC<Props> = ({ setValid }) => {
  const classes = styles()
  const { category, setCategory } = useContext(Context)

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    if (e?.target === undefined) {
      setFieldValue(name, e)
      setCategory &&
        setCategory({
          ...category,
          [name]: e
        })
    } else {
      setFieldValue(name, e?.target?.value)
      setCategory &&
        setCategory({
          ...category,
          [name]: e?.target?.value
        })
    }
  }

  return (
    <Box>
      <Formik
        validateOnMount
        initialValues={useMemo(() => {
          return {
            effectiveDate: category?.effectiveDate
          }
        }, [])}
        onSubmit={(values, actions): void => {
          JSON.stringify(values, null, 2)
          actions.setSubmitting(false)
        }}
        validationSchema={addEffectiveDate}
      >
        {({
          touched,
          errors,
          handleBlur,
          setFieldValue,
          isValid,
          handleSubmit
        }): JSX.Element => {
          return (
            <form>
              <Box className={classes.container}>
                <FormWrapper title="Effective Date">
                  <Box className={classes.title}>
                    Settings will be in effect from this date onwards. Any new
                    bookings beyond the date specified will take into account the new
                    Settings after submission.
                  </Box>
                  <Box className={classes.section}>
                    <Box width="50%">
                      <DatePicker
                        label="Effective Date"
                        name="effectiveDate"
                        format="MM/dd/yyyy"
                        minDate={new Date()}
                        value={category?.effectiveDate}
                        placeholder="mm/dd/yyyy"
                        handleDateChange={(value: string): void =>
                          handleFormChange("effectiveDate", value, setFieldValue)}
                        onBlur={(e: Event): void => handleBlur(e)}
                        error={
                          touched.effectiveDate && Boolean(errors.effectiveDate)
                        }
                        helperText={
                          errors.effectiveDate &&
                          touched.effectiveDate &&
                          errors.effectiveDate
                        }
                      />
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>
              <Box>{isValid ? setValid(true) : setValid(false)}</Box>
              <RefButton refValue={refSubmit} action={handleSubmit} />
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FormEffectiveDate
