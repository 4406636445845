import React, { useState } from "react"
import { toast } from "react-toastify"
import Cookies from "js-cookie"

/** API */
import FeedbackAPI from "services/Dashboard/Feedback"
import { UploadImage } from "utils/uploadFile"
import { AddFeedbackContext } from "./Context"

export interface AddFeedbackProviderProps {
  showForm: Function
}

const AddFeedbackProvider: React.FC<AddFeedbackProviderProps> = ({
  children,
  showForm
}) => {
  // const dispatch = useDispatch()
  const [activeFeedback] = React.useState([])

  /* Notification */
  const notifyCreate = () =>
    toast("Successfully created a Feedback.", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */

  const [openDialog, setOpenDialog] = useState(false)
  const [feedbackDetails, setFeedbackDetails] = useState({
    id: "",
    unitName: "",
    unitUID: "",
    byAdmin: "",
    type: "",
    description: "",
    isUrgent: "",
    staffName: "",
    photo: "",
    status: "",
    feedbackBy: "",
    feedbackById: ""
  })

  const [photos, setPhotos] = useState(Array)
  const [localPhotos, setLocalPhotos] = useState(Array)
  const [attaching, setAttaching] = useState(false)

  /** Methods */
  function handleImageUpload(event: any) {
    if (event) {
      const a = event[0]?.preview
      setLocalPhotos([...localPhotos, a])
    }

    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  const removePhoto = (value: number) => {
    const fileFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })
    setPhotos(fileFiltered)
    setLocalPhotos(fileFiltered)
  }

  const handleAddFeedback = async () => {
    const fd = feedbackDetails

    const data = {
      "feedback_type": fd?.type,
      "remarks": fd?.description,
      "feedback_photo": { "photos": photos },
      "feedback_unit_uid": fd?.unitUID === "" ? null : fd?.unitUID,
      "is_urgent": fd?.isUrgent,
      "assigned_to_staff_account_uid": fd?.staffName,
      "feedback_condo_uid": Cookies.get("condoUID"),
      "status": fd?.status,
      "by_admin": fd?.byAdmin === "" ? false : fd?.byAdmin,
      "feedback_posted_by_admin": true,
      "feedback_by_account_uid": fd?.byAdmin ? null : fd?.feedbackById,
      "feedback_by_name": fd?.byAdmin ? null : fd?.feedbackBy
    }

    if (fd?.staffName === "") {
      delete data.assigned_to_staff_account_uid
    }

    if (fd?.byAdmin) {
      if (fd?.staffName === "") {
        delete data.assigned_to_staff_account_uid
      }
    }

    try {
      const response = await FeedbackAPI.createFeedback(data).then(() =>
        notifyCreate()
      )
      return response
    } catch (e) {
      return e
    }
  }

  return (
    <AddFeedbackContext.Provider
      value={{
        feedbackDetails,
        photos,
        localPhotos,
        attaching,
        openDialog,
        activeFeedback,
        showForm,
        setFeedbackDetails,
        handleAddFeedback,
        setOpenDialog,
        handleImageUpload,
        removePhoto
      }}
    >
      {children}
    </AddFeedbackContext.Provider>
  )
}

export default AddFeedbackProvider
