import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import { Formik } from "formik"
/** Components */
import FormWrapper from "components/Forms/FormWrapper"
import FormInputPassword from "components/Forms/FormInput/password"

/** Validation */
import { staffResetPassword } from "config/Settings/AccountManagement/Staff/validation"

/** Context */
import withContext from "context/Settings/AccountManagement/Staff/forms/resetPassword/withContext"
import { Context } from "context/Settings/AccountManagement/Staff/forms/resetPassword/Context"
import { Context as MainContext } from "context/Settings/AccountManagement/Staff/main/Context"
import styles from "./styles"

const ResetForm: React.FC = () => {
  const classes = styles()
  const {
    userPassword,
    setUserPassword,
    handleFormSubmit,
    isSubmitting
  } = useContext(Context)
  const { showResetForm } = useContext(MainContext)

  /** Methods */
  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement> | any,
    setFieldValue: Function
  ) => {
    setFieldValue("staffPassword", e?.target?.value)
    setUserPassword && setUserPassword(e?.target?.value)
  }

  const handleSubmitForm = (handleSubmit: Function, isFormValid: boolean): void => {
    handleSubmit()

    if (isFormValid) {
      handleFormSubmit && handleFormSubmit()
    }
  }

  return (
    <Formik
      initialValues={{
        staffPassword: ""
      }}
      onSubmit={(values, actions): void => {
        JSON.stringify(values, null, 2)
        actions.setSubmitting(false)
      }}
      validationSchema={staffResetPassword}
    >
      {({
        touched,
        errors,
        isValid,
        handleBlur,
        handleSubmit,
        setFieldValue
      }): JSX.Element => {
        return (
          <form style={{ height: "100%" }}>
            <Box className={classes.wrapper}>
              <Box flex="3">
                <FormWrapper>
                  <Box className={classes.container}>
                    <Box className={classes.section}>
                      <Box>
                        <Box marginBottom="25px">
                          <FormInputPassword
                            name="staffPassword"
                            value={userPassword}
                            label="Password"
                            placeholder="Password"
                            handleOnChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleFormChange(e, setFieldValue)}
                            onBlur={handleBlur}
                            error={
                              touched.staffPassword && Boolean(errors.staffPassword)
                            }
                            helperText={
                              errors.staffPassword &&
                              touched.staffPassword &&
                              errors.staffPassword
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </FormWrapper>
              </Box>

              <Box className={classes.footer}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => showResetForm && showResetForm(false)}
                  >
                    CANCEL
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitForm(handleSubmit, isValid)}
                  >
                    {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default withContext(ResetForm)
