import * as React from "react"

const Admin: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C9.05467 0 6.66667 2.388 6.66667 5.33333C6.66667 8.27867 9.05467 10.6667 12 10.6667C14.9453 10.6667 17.3333 8.27867 17.3333 5.33333C17.3333 2.388 14.9453 0 12 0ZM12 2.66667C13.4733 2.66667 14.6667 3.86 14.6667 5.33333C14.6667 6.80667 13.4733 8 12 8C10.5267 8 9.33333 6.80667 9.33333 5.33333C9.33333 3.86 10.5267 2.66667 12 2.66667ZM12 14.6667C7.812 14.6667 0 16.728 0 20.6667V24H24V20.6667C24 16.728 16.188 14.6667 12 14.6667ZM12 17.3333C12.264 17.3333 12.5326 17.3652 12.7995 17.3802L14.6667 20.6302L16.1693 17.9062C19.0223 18.6296 21.3333 19.8768 21.3333 20.6667V21.3333H2.66667V20.6667C2.66667 19.8768 4.9777 18.6296 7.83073 17.9062L9.33333 20.6302L11.2005 17.3802C11.4674 17.3652 11.736 17.3333 12 17.3333Z"
        fill="white"
      />
    </svg>
  )
}

export default Admin
