import React from "react"

import AddFeedbackProvider, {
  AddFeedbackProviderProps
} from "context/Dashboard/Feedback/forms/addFeedback/Provider"
import AddFeedbackView from "context/Dashboard/Feedback/forms/addFeedback/View"

const AddUpdateMain: React.FC<AddFeedbackProviderProps> = ({ showForm }) => {
  return (
    <AddFeedbackProvider showForm={showForm}>
      <AddFeedbackView />
    </AddFeedbackProvider>
  )
}

export default AddUpdateMain
