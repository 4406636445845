import React from "react"
import { StateValue } from "xstate"

export type CtxType = {
  handleAdminAddBooking: Function
  handleAddBooking: Function
  handleEditBooking: Function
  handleApproveBooking: Function
  handleFormChange: Function
  setSelectedFacilities: Function
  fetchCategories: Function
  setOpenDialog: Function
  selectFacility: Function
  goNext: Function
  goBack: Function
  setBookingDate: Function
  setSelectedMenu: Function
  selectMenu: Function
  setSelectedFacility: Function
  selectTimeslot: Function
  setBookingDetails: Function
  setBooking: Function
  setSelectedForm: Function
  hasData: boolean
  openDialog: boolean
  submitting: boolean
  xValue: string | StateValue
  bookingDate: string
  mainView: string
  categories: Record<string, any>
  selectedFacilities: Record<string, any>
  categoryMenu: Record<string, any>
  selectedMenu: Record<string, any>
  selectedFacility: Record<string, any>
  bookingDetails: Record<string, any>
  booking: Record<string, any>
  selectedForm: Record<string, any>
  returnedBooking: Record<string, any>
  data: Record<string, any>
  facilityBookings: Array<{}>
}

export const Context = React.createContext<Partial<CtxType>>({})
