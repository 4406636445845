import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
/** Service */
import service from "services/Settings/Others/CondoInfo"
/** Context */
import { UploadImage } from "utils/uploadFile"
import { Context } from "./Context"
/** Utils */

const Provider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [photos, setPhotos] = useState(Array)
  const [attaching, setAttaching] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [condoInfo, setCondoInfo] = useState({
    uid: "",
    email: "",
    description: "",
    location: "",
    bannerPhoto: [],
    galleryPhoto: [],
    highlight: [],
    adminContact: "",
    sosNumber: "",
    terms: ""
  })

  /** Notification */
  const notifySuccess = () =>
    toast("Successfully updated the condo info", {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  async function getCondoInfo() {
    try {
      const response = await service.getCondoInfo()
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  async function updateCondoInfo() {
    const payload = {
      "description": condoInfo?.description,
      "location": condoInfo?.location,
      "banner_photo": {
        photos
      },
      "gallery_photo": { photos: [] },
      "highlight": { highlights: [] },
      "admin_contact_no": condoInfo?.adminContact,
      "sos_no": condoInfo?.sosNumber,
      "admin_contact_email": condoInfo?.email,
      "terms_and_conditions": condoInfo?.terms
    }

    setSubmitting(true)

    try {
      const response = await service.updateCondoInfo(payload, condoInfo?.uid)
      notifySuccess()
      setSubmitting(false)
      return response?.data?._data
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const removePhoto = (value: number) => {
    const photoFiltered = photos.filter((item: any, index: number) => {
      return index !== value
    })
    setPhotos(photoFiltered)
  }

  function handleImageUpload(event: any) {
    setAttaching(true)
    UploadImage(event, 1)
      .then((output: any) => {
        setPhotos([...photos, output])
        setAttaching(false)
      })
      .catch((e) => {
        setAttaching(false)
        return e
      })
  }

  useEffect(() => {
    setLoading(true)
    getCondoInfo().then((res) => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      setPhotos(res?.banner_photo?.photos || [])
      setCondoInfo({
        terms: res?.terms_and_conditions || "",
        uid: res?._uid || "",
        email: res?.admin_contact_email || "",
        description: res?.description || "",
        location: res?.location || "",
        bannerPhoto: res?.banner_photo?.photos || [],
        galleryPhoto: res?.gallery_photo?.photos || [],
        highlight: res?.highlight?.highlights || [],
        adminContact: res?.admin_contact_no || "",
        sosNumber: res?.sos_no || ""
      })
    })
  }, [])

  return (
    <Context.Provider
      value={{
        photos,
        attaching,
        condoInfo,
        submitting,
        isLoading,
        activeTab,
        setActiveTab,
        removePhoto,
        handleImageUpload,
        setCondoInfo,
        setSubmitting,
        updateCondoInfo
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
