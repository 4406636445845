import React from "react"
import styles from "./styles"

interface FormLabelProps {
  label: string
  light?: boolean
  large?: boolean
}

const FormLabel: React.FC<FormLabelProps> = ({ label, light, large }) => {
  const { labelStyle } = styles()

  const classNames = `${labelStyle} ${light ? "light" : ""} ${large ? "large" : ""}`

  return <div className={classNames}>{label}</div>
}

export default FormLabel
