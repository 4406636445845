import React, { useEffect, useRef, ReactInstance } from "react"
import Box from "@material-ui/core/Box"
/** Layout */
import Spacer from "components/Spacer"

/** Components */
import Facilities from "./components/Facilities"
import Application from "./components/Application"
import NewAccountsRequest from "./components/NewAccountsRequest"
import PaymentReceivedToday from "./components/PaymentReceivedToday"
import ScheduleServicing from "./components/ScheduleServicing"
import NewFeedback from "./components/NewFeedback"
import Attendance from "./components/Attendance"
import ServiceProviders from "./components/VisitorManagement/components/ServiceProviders"
import Delivery from "./components/VisitorManagement/components/Delivery"
import Visitors from "./components/VisitorManagement/components/Visitors"
import PageTitle from "./components/PageTitle"
import ExpiringSoon from "./components/ExpiringSoon"

/* eslint-disable react/prefer-stateless-function */
class EmptyComponent extends React.Component {
  render() {
    return null
  }
}

const Dashboard: React.FC = () => {
  const spaceHeight = "23px"
  const ele: ReactInstance = new EmptyComponent({})
  const refReport = useRef<any>(ele)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box>
        <PageTitle
          title="Dashboard"
          breadcrumbs="property management > dashboard"
          ele={ele}
          refElement={refReport}
        />
      </Box>
      <div ref={refReport} style={{ width: "100%" }}>
        <Box display="flex">
          <Box minWidth="70%" flex="1" paddingRight="23px">
            <Box>
              <ExpiringSoon />
            </Box>
            <Spacer isDefault />
            <Box>
              <Facilities />
            </Box>
            <Spacer isDefault />
            <Box>
              <Application />
            </Box>
            <Spacer isDefault />
            <Box>
              <ServiceProviders />
            </Box>
            <Spacer isDefault />
            <Box>
              <Visitors />
            </Box>
            <Spacer isDefault />
            <Box>
              <Delivery />
            </Box>
          </Box>
          <Spacer isDefault />
          <Box minWidth="30%">
            <ScheduleServicing />
            <Spacer height={spaceHeight} />
            <PaymentReceivedToday />
            <Spacer height={spaceHeight} />
            <NewAccountsRequest />
            <Spacer height={spaceHeight} />
            <NewFeedback />
            <Spacer height={spaceHeight} />
            <Attendance />
          </Box>
        </Box>
      </div>

      {/* <ManageSettings openStatus={openStatus} setopenStatus={setopenStatus} /> */}
    </>
  )
}

export default Dashboard
