import React, { useContext } from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade"
import { Link } from "react-router-dom"
/** Components */
import Loader from "components/Loader"
/** Styles */
import { IconFeedback } from "components/Icons/Dashboard"
import { toNumeralFixed } from "utils/helpers"
import { Context } from "context/Dashboard/Dashboard/main/Context"
import styles from "./styles"
/**
 * Context
 */
/** Hook */
import { CountFeedbackHook } from "./hooks"
/** Utils */

const NewFeedback: React.FC = () => {
  const { title, body, totalStyle, wrapper, totalWrapper } = styles()
  const { dateOptions, dateRange } = useContext(Context)
  const { totalFeedback, isValidating } = CountFeedbackHook(dateRange)

  function ViewTotalNewFeedback() {
    if (isValidating) {
      return <Loader dark />
    }

    return (
      <Box className={totalWrapper}>
        <Fade in={!isValidating} timeout={1000}>
          <Box className={totalStyle}>{toNumeralFixed(totalFeedback)}</Box>
        </Fade>
      </Box>
    )
  }

  return (
    <Card>
      <Box className={wrapper}>
        <Box display="flex" padding="0 23px">
          <Box paddingRight="25px">{IconFeedback}</Box>
          <Box>
            <Box className={title}>New Feedback</Box>
            <Box className={body}>{`As of ${dateOptions?.currentDate}`}</Box>
            <Button
              color="primary"
              size="large"
              component={Link}
              to="/property-management/feedback"
            >
              <Box fontWeight="600">VIEW ALL</Box>
            </Button>
          </Box>
        </Box>

        <ViewTotalNewFeedback />
      </Box>
    </Card>
  )
}

export default NewFeedback
