import * as React from "react"

const Users = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM10 2C14.418 2 18 5.582 18 10C18 11.5973 17.5254 13.0811 16.7188 14.3301L15.9492 13.7344C14.3972 12.5374 11.537 12 10 12C8.463 12 5.60178 12.5374 4.05078 13.7344L3.28125 14.332C2.47404 13.0828 2 11.5979 2 10C2 5.582 5.582 2 10 2ZM10 3.75C8.208 3.75 6.75 5.208 6.75 7C6.75 8.792 8.208 10.25 10 10.25C11.792 10.25 13.25 8.792 13.25 7C13.25 5.208 11.792 3.75 10 3.75ZM10 5.75C10.689 5.75 11.25 6.311 11.25 7C11.25 7.689 10.689 8.25 10 8.25C9.311 8.25 8.75 7.689 8.75 7C8.75 6.311 9.311 5.75 10 5.75ZM10 14C13.1007 14 14.7681 15.1685 15.5488 15.7539C14.11 17.1418 12.1569 18 10 18C7.84315 18 5.89002 17.1418 4.45117 15.7539C5.2319 15.1685 6.89929 14 10 14ZM4.05469 15.3398C4.17566 15.4731 4.29727 15.6059 4.42578 15.7305C4.29711 15.6053 4.17473 15.4734 4.05469 15.3398ZM15.9121 15.375C15.8024 15.4955 15.6929 15.6168 15.5762 15.7305C15.6926 15.6175 15.8015 15.495 15.9121 15.375Z"
      fill="#454B57"
    />
  </svg>
)

export default Users
