import React, { Suspense, useContext, useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { useMachine } from "@xstate/react"

/** Component */
import Loader from "components/Loader"
import Stepper from "components/Stepper"
import Dialog from "components/Dialog"
import Footer from "components/Forms/Footer/new"
/** Forms */
import PersonalDetails from "pages/Dashboard/Applications/components/AddApplication/PersonalDetails"
import ApplicationDetails from "pages/Dashboard/Applications/components/AddApplication/ApplicationDetails"
import SupportingDocuments from "pages/Dashboard/Applications/components/AddApplication/SupportingDocuments"
import Receipt from "pages/Dashboard/Applications/components/AddApplication/Receipt"
/** Context */
import { AddApplicationsMachine } from "machines/Dashboard/Applications/addApplication"
import { Context as RootContext } from "context/Dashboard/Applications//main/Context"
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Ref */
export const refSubmit = React.createRef<HTMLDivElement>()
export const refSubmitApplicationDetails = React.createRef<HTMLDivElement>()
export const refPrintBtn = React.createRef<HTMLButtonElement>()

const AddApplication: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  applicationDetails,
  handleCreateApplication,
  handleApproveApplication,
  forApproving,
  allowedTimeMessage,
  allowedDateMessage,
  showMain,
  submitting,
  bookingAllowed,
  setBookingAllowed,
  movingInfo
}) => {
  const [isMovingValid, setIsMovingValid] = useState(false)
  const [isRenovationValid, setIsRenovationValid] = useState(false)
  const [isVehiclesValid, setIsVehiclesValid] = useState(false)
  const [isApplicationValid, setIsApplicationValid] = useState(false)
  const [isAccessCardsValid, setIsAccessCardsValid] = useState(false)
  const { activeTable } = useContext(RootContext)
  const [current, send] = useMachine(AddApplicationsMachine)
  const xValue = current?.value
  const { unitUID, applicantName, startDate } = applicationDetails
  const { eta } = movingInfo

  /** Methods */
  const handleNext = () => {
    refSubmit.current?.click()
    /** Step 1 */
    if (xValue === "personalDetails" && unitUID !== "" && applicantName !== "") {
      send("NEXT")
    } else {
      refSubmit.current?.click()
    }
    /** Step 2 */
    if (
      xValue === "applicationDetails" &&
      activeTable === 1 &&
      isMovingValid &&
      isApplicationValid
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
      refSubmitApplicationDetails.current?.click()
    }

    if (
      xValue === "applicationDetails" &&
      activeTable === 3 &&
      isRenovationValid &&
      isApplicationValid
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
      refSubmitApplicationDetails.current?.click()
    }

    if (
      xValue === "applicationDetails" &&
      activeTable === 0 &&
      isVehiclesValid &&
      isApplicationValid
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
      refSubmitApplicationDetails.current?.click()
    }

    if (
      xValue === "applicationDetails" &&
      activeTable === 2 &&
      isAccessCardsValid &&
      isApplicationValid
    ) {
      send("NEXT")
    } else {
      refSubmit.current?.click()
      refSubmitApplicationDetails.current?.click()
    }

    /** Step 3 */
    if (xValue === "supportingDocs") {
      if (forApproving) {
        handleApproveApplication().then(() => send("NEXT"))
      } else {
        handleCreateApplication().then(() => send("NEXT"))
      }
    } else {
      refSubmit.current?.click()
    }
  }

  /** Views */
  const ViewPersonalDetails = (): JSX.Element => {
    if (xValue !== "personalDetails") {
      return <div />
    }
    return <PersonalDetails />
  }

  const ViewDetails = (): JSX.Element => {
    if (xValue !== "applicationDetails") {
      return <div />
    }

    return (
      <ApplicationDetails
        forApproving={forApproving}
        isVehicleValid={isVehiclesValid}
        isApplicationValid={isApplicationValid}
        isRenovationValid={isRenovationValid}
        isAccessCardsValid={isAccessCardsValid}
        allowedDateMessage={allowedDateMessage}
        allowedTimeMessage={allowedTimeMessage}
        setIsMovingValid={setIsMovingValid}
        setIsVehiclesValid={setIsVehiclesValid}
        setIsAccessCardsValid={setIsAccessCardsValid}
        setIsRenovationValid={setIsRenovationValid}
        setIsApplicationValid={setIsApplicationValid}
      />
    )
  }

  const ViewUploadDocs = (): JSX.Element => {
    if (xValue !== "supportingDocs") {
      return <div />
    }

    return <SupportingDocuments />
  }

  const ViewReceipt = (): JSX.Element => {
    if (xValue !== "receipt") {
      return <div />
    }

    return <Receipt />
  }

  const StepperView = ({ value }: any): JSX.Element => {
    const activeStep: Record<string, number> = {
      "personalDetails": 1,
      "applicationDetails": 2,
      "supportingDocs": 3,
      "receipt": 4
    }

    return (
      <Stepper
        items={[
          "personal details",
          "application details",
          "supporting docs",
          "receipt"
        ]}
        activeStep={activeStep[value]}
        justifyStart
      />
    )
  }

  const labelValue = () => {
    if (xValue === "receipt") {
      return "Print"
    }

    if (submitting) {
      return "Submitting..."
    }

    return "Next"
  }

  return (
    <>
      <Card>
        <StepperView value={current?.value} />
        <Box>
          <Suspense fallback={<Loader forContent />}>
            {ViewPersonalDetails()}
            {ViewDetails()}
            {ViewUploadDocs()}
            {ViewReceipt()}
          </Suspense>
          <Box margin="0 0 30px">
            <Footer
              handleNext={() => handleNext()}
              handleBack={xValue === "personalDetails" ? null : () => send("BACK")}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label={labelValue()}
              forAcknowledge={xValue === "receipt"}
              handlePrint={() => refPrintBtn.current?.click()}
              handleSendEmail={() => null}
              handleDone={() => setOpenDialog && setOpenDialog(true)}
              disabled={submitting}
            />
          </Box>
        </Box>
        <Dialog
          action={() => showMain()}
          isOpen={openDialog}
          setOpen={setOpenDialog}
          actionLabel="OK"
          title=""
          message={
            xValue === "receipt"
              ? "Go back to the Applications Page?"
              : "Are you sure you want to cancel?"
          }
        />

        {(eta !== "" || (startDate !== "" && activeTable === 3)) &&
          xValue === "applicationDetails" && (
            <Dialog
              action={(): null => null}
              isOpen={!bookingAllowed}
              setOpen={() => setBookingAllowed(true)}
              actionLabel="OK"
              title=""
              message="Application disallowed according to Settings. Do you still want to proceed with Application?"
            />
          )}
      </Card>
    </>
  )
}

export default withContext(AddApplication)
