import React, { useContext, useState } from "react"
import useSWR from "swr"
import Fade from "@material-ui/core/Fade"
import Box from "@material-ui/core/Box"
import Cookie from "js-cookie"
/** Service */
import UnitManagementAPI from "services/Dashboard/UnitManagement"
/** Components */
import Table from "components/Table"
import Loader from "components/Loader"
/** Context */
import withMainContext from "context/Dashboard/UnitManagement/categories/main/withContext"
import { Context as ParentContext } from "context/Dashboard/UnitManagement/categories/main/Context"
import tableHeaders from "./table/tableHeaders"

interface TableAllProps {
  activeFilter: number
}

const TableAll: React.FC<TableAllProps> = () => {
  const condoUID = Cookie.get("condoUID")
  const { setDownloadData } = useContext(ParentContext)
  const [currentPage, setCurrentPage] = useState(0)

  /** Methods */
  const fetchChangeAddressRequests = async () =>
    UnitManagementAPI.getChangeAddressRequests({
      "condo_uid": condoUID,
      "is_approved": false
    })

  const { data, isValidating } = useSWR(
    "fetchChangeAddressRequests",
    fetchChangeAddressRequests,
    {
      revalidateOnFocus: true
    }
  )

  const tableData =
    data?.data?._data !== undefined && data?.data?._data !== null
      ? Object.values(data?.data?._data).reverse()
      : []

  const filteredData = tableData?.filter((i: any) => i.is_approved === false)

  if (isValidating) {
    return (
      <Box height="500px">
        <Loader dark />
      </Box>
    )
  }

  return (
    <Fade in={!isValidating} timeout={1000}>
      <div>
        <Table
          data={filteredData || []}
          columns={tableHeaders}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isFilterable
          action={(values: any) => {
            return (
              values.length !== 0 &&
              setDownloadData &&
              setDownloadData(
                values?.map((i: any) => {
                  return {
                    "Current Block/Unit No.": i?.currentUnitShortName,
                    "New Block/Unit No.": i?.newUnitShortName,
                    "Name": i?.name,
                    "Email": i?.email,
                    "Mobile": i?.mobileNumber
                  }
                })
              )
            )
          }}
        />
      </div>
    </Fade>
  )
}

export default withMainContext(TableAll)
