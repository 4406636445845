import React, { useContext, useEffect } from "react"
/** Components */
import CategoryFilters from "pages/Dashboard/Facilities/components/CategoryFilters/multiSelect"
import DatePicker from "components/Forms/DatePicker"
/** Context */
import { Context as ParentContext } from "context/Dashboard/Facilites/forms/addBooking/Context"
import { toUnix } from "utils/date"
import CurrentBooking from "./CurrentBooking"
import FacilityList from "./FacilityList"
/** Utils */
/** Styles */
import styles from "./styles"

const SelectFacility: React.FC = () => {
  const classes = styles()

  const {
    selectedMenu,
    selectMenu,
    bookingDate,
    setBookingDate,
    categoryMenu,
    fetchCategories,
    hasData
    // data,
  } = useContext(ParentContext)

  // const dataBookingDate = data?.booking_date

  function ViewCurrentBooking(): JSX.Element {
    if (!hasData) {
      return <div />
    }

    return <CurrentBooking />
  }

  useEffect(() => {
    fetchCategories && fetchCategories(toUnix(String(bookingDate)))
  }, [bookingDate])

  return (
    <div>
      <ViewCurrentBooking />
      <div className={classes.container}>
        <div className={classes.bookingDate}>
          <DatePicker
            // minDate={
            //   hasData && new Date(dataBookingDate) > new Date()
            //     ? new Date()
            //     : new Date(dataBookingDate)
            // }
            // minDate={new Date()}
            name="bookingDate"
            format="MMM dd, yyyy"
            value={bookingDate}
            placeholder="mm/dd/yyyy"
            handleDateChange={(value: string): void => {
              setBookingDate && setBookingDate(value)
            }}
          />
        </div>

        <div className={classes.menus}>
          <CategoryFilters
            hasData={hasData}
            menu={categoryMenu}
            action={(menu: Record<string, any>) =>
              selectMenu && selectMenu(menu?._uid)}
            activeMenu={selectedMenu}
            hasDefaultColor
            isButton
          />
        </div>

        <div>
          <FacilityList />
        </div>
      </div>
    </div>
  )
}

export default SelectFacility
