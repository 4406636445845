import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    padding: "30px 0 0"
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "18px",
    letterSpacing: "0.0015em",
    color: theme.palette.secondary.dark,
    paddingBottom: "30px"
  },
  titleSmall: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.0015em",
    color: theme.palette.secondary.dark,
    paddingBottom: "30px"
  },
  hasBorder: {
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "30px"
  }
}))

export default styles
