import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

/** Service */

/** Interface */
export interface FeedbackMainProviderProps {
  showAddForm: Function
  showEditForm: Function
  showReplyForm: Function
  showDetails: Function
  setActiveStatus: Function
  activeStatus: number
  activeTable: number
  tabStatus: number
  filterTabStatus: Function
  // contextActions: Record<any, any>
}

const FeedbackMainProvider: React.FC<FeedbackMainProviderProps> = ({
  children,
  showAddForm,
  showEditForm,
  showReplyForm,
  showDetails,
  activeTable,
  setActiveStatus,
  activeStatus,
  tabStatus,
  filterTabStatus
}) => {
  /** State */
  const [activeFilter, setActiveFilter] = useState(0)
  const [downloadData, setDownloadData] = useState([])

  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  return (
    <Context.Provider
      value={{
        showAddForm,
        showEditForm,
        showDetails,
        showReplyForm,
        activeFilter,
        downloadData,
        setActiveFilter,
        filterTable,
        setDownloadData,
        activeTable,
        setActiveStatus,
        activeStatus,
        tabStatus,
        filterTabStatus,
        data: [],
        isValidating: false
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FeedbackMainProvider
