import useSWR from "swr"
import service from "services/Dashboard/Reports"
import { toUnix } from "utils/date"

export function TotalApplicationsHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalApplications = async () =>
    service.getApplicationsCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalApplications-${unixFrom}-${unixTo}`,
    fetchTotalApplications,
    {
      revalidateOnFocus: false
    }
  )
  const totalApplications = data?.data?._data?.total

  return {
    totalApplications,
    isValidating,
    error
  }
}

export function TotalMovingHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalMoving = async () =>
    service.getApplicationsCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "application_type": 2
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalMoving-${unixFrom}-${unixTo}`,
    fetchTotalMoving,
    {
      revalidateOnFocus: false
    }
  )
  const totalMoving = data?.data?._data?.total

  return {
    totalMoving,
    isValidating,
    error
  }
}

export function TotalVehiclesHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalVehicles = async () =>
    service.getApplicationsCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "application_type": 1
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalVehicles-${unixFrom}-${unixTo}`,
    fetchTotalVehicles,
    {
      revalidateOnFocus: false
    }
  )
  const totalVehicles = data?.data?._data?.total

  return {
    totalVehicles,
    isValidating,
    error
  }
}

export function TotalAccessCardHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalAccessCards = async () =>
    service.getApplicationsCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "application_type": "3,4,5"
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalAccessCards-${unixFrom}-${unixTo}`,
    fetchTotalAccessCards,
    {
      revalidateOnFocus: false
    }
  )
  const totalAccessCards = data?.data?._data?.total

  return {
    totalAccessCards,
    isValidating,
    error
  }
}

export function TotalRenovationHook(date: any) {
  const unixFrom = toUnix(`${date?.from} 00:00`)
  const unixTo = toUnix(`${date?.to} 11:59`)
  const fetchTotalRenovation = async () =>
    service.getApplicationsCount({
      "start_timestamp": unixFrom,
      "end_timestamp": unixTo,
      "application_type": 6
    })
  const { data, isValidating, error } = useSWR(
    `fetchTotalRenovation-${unixFrom}-${unixTo}`,
    fetchTotalRenovation,
    {
      revalidateOnFocus: false
    }
  )
  const totalRenovations = data?.data?._data?.total

  return {
    totalRenovations,
    isValidating,
    error
  }
}
