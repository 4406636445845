import React from "react"
import Box from "@material-ui/core/Box"
import axios from "axios"

interface Props {
  fileUrl: string
  fileName: string
}

const DownloadWrapper: React.FC<Props> = ({
  children,
  fileUrl,
  fileName
}): JSX.Element => {
  const downloadLink = async (urlLink: string) =>
    axios({
      url: urlLink,
      method: "GET",
      responseType: "blob" // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${fileName}`)
      document.body.appendChild(link)
      link.click()
    })

  return (
    <Box style={{ cursor: "pointer" }} onClick={() => downloadLink(fileUrl)}>
      {children}
    </Box>
  )
}

export default DownloadWrapper
