import * as Yup from "yup"

export const addAdmin: Record<any, any> = Yup.object().shape({
  adminName: Yup.string().required("Required"),
  adminEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  adminMobile: Yup.string().required("Required"),
  adminPassword: Yup.string().required("Required"),
  role: Yup.string().required("Required")
})

export const updateAdmin: Record<any, any> = Yup.object().shape({
  adminName: Yup.string().required("Required"),
  adminEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  adminMobile: Yup.string().required("Required"),
  role: Yup.string().required("Required")
})

export const addRole: Record<any, any> = Yup.object().shape({
  roleName: Yup.string().required("Required")
})

export const resetPassword: Record<any, any> = Yup.object().shape({
  adminPassword: Yup.string().required("Required")
})

const validation = {
  addAdmin,
  updateAdmin,
  addRole
}

export default validation
