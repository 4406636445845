import React from "react"

import PaymentSchedulesProvider from "context/Dashboard/UnitManagement/categories/paymentSchedules/main/Provider"
import PaymentSchedulesView from "context/Dashboard/UnitManagement/categories/paymentSchedules/main/View"

const OwnersTable: React.FC = () => (
  <PaymentSchedulesProvider>
    <PaymentSchedulesView />
  </PaymentSchedulesProvider>
)

export default OwnersTable
