import * as React from "react"

interface AnnouncementsProps {
  color: string
}

const Announcements: React.FC<AnnouncementsProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0L17 1.28516L4 6V12L6.45117 12.8887L6.16797 13.6895C5.95497 14.2905 6.26909 14.9491 6.87109 15.1621L9.04883 15.9336C9.64983 16.1466 10.3104 15.8305 10.5234 15.2285L10.793 14.4648L17 16.7148L18 18H20V0H18ZM18 3.05078V14.9492L17.6816 14.834L6 10.5977V7.40234L17.6816 3.16602L18 3.05078ZM0 6V12H2V6H0Z"
        fill={color}
      />
    </svg>
  )
}

export default Announcements
