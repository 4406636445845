import React, { useState, useEffect } from "react"

/** Context */
import service from "services/Settings/AccountManagement/Admin"
import { Context } from "./Context"

/** Service */

const Provider: React.FC = ({ children }) => {
  const [adminPayload, setAdminPayload] = useState({})
  const [activeRole, setActiveRole] = useState("")
  const [activeRoleName, setActiveRoleName] = useState("")
  const [activeTab, setActiveTab] = useState(0)
  const [tabValues, setTabValues] = useState(0)
  const [payloads, setPayloads] = useState({})
  const [isFetchingRoles, setisFetchingRoles] = useState(false)

  /** Methods */
  async function createTabs() {
    try {
      const response = await service.getRoles({ "user_type": 3 })
      return response?.data?._data?.sort(
        (a: Record<string, any>, b: Record<string, any>) =>
          a.role_name.localeCompare(b.role_name)
      )
    } catch (e) {
      return e
    }
  }

  async function fetchRolesPermission(roleId: string) {
    setisFetchingRoles(true)
    try {
      const response = await service.getRolePermissions(roleId)
      setPayloads(response?.data?._data?.permission)
      setisFetchingRoles(false)
      return response
    } catch (e) {
      setisFetchingRoles(false)
      return e
    }
  }

  function selectTab(value: number, roleid: string, roleName: string) {
    setActiveTab(value)
    setActiveRole(roleid)
    setActiveRoleName(roleName)
    fetchRolesPermission(roleid)
  }

  useEffect(() => {
    createTabs()
      .then((res) => {
        return Object.keys(res).map(() => {
          setTabValues(res)

          if (activeRole === "") {
            selectTab(0, res[0]?._uid, res[0]?.role_name)
          }

          return res[0]
        })
      })
      .then((res) => fetchRolesPermission(res[0]?._uid))
  }, [])

  return (
    <Context.Provider
      value={{
        adminPayload,
        activeRole,
        activeRoleName,
        activeTab,
        setAdminPayload,
        setActiveRole,
        selectTab,
        setPayloads,
        isFetchingRoles,
        payloads,
        tabValues
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
