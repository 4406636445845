const UnitManagementConfig: any = {
  filterAccountRequests: {
    0: "New",
    1: "Change Address Requests",
    2: "Rejected"
  },
  filterOwners: {
    0: "Active",
    1: "New",
    2: "Registered",
    3: "Change Address Requests",
    4: "Archived"
  },
  filterResidents: {
    0: "Active",
    1: "New",
    2: "Registered",
    3: "Change Address Requests",
    4: "Archived"
  },
  filterVehicles: {
    0: "Active",
    1: "Archived"
  },
  filterAccessCards: {
    0: "Active",
    1: "Archived"
  }
}

export const {
  filterAccountRequests,
  filterOwners,
  filterResidents,
  filterVehicles,
  filterAccessCards
} = UnitManagementConfig
