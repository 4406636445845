import React from "react"

import MainCategoriesProvider, {
  ProviderProps
} from "context/Dashboard/Applications/categories/main/Provider"
import MainCategoriesView from "context/Dashboard/Applications/categories/main/View"

const MainCategories: React.FC<ProviderProps> = ({
  showMain,
  showAddApplication,
  showUpdateApplication,
  showApproveApplication,
  activeFilter,
  setActiveFilter
}) => (
  <MainCategoriesProvider
    showMain={showMain}
    showAddApplication={showAddApplication}
    showUpdateApplication={showUpdateApplication}
    showApproveApplication={showApproveApplication}
    activeFilter={activeFilter}
    setActiveFilter={setActiveFilter}
  >
    <MainCategoriesView />
  </MainCategoriesProvider>
)

export default MainCategories
