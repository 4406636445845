import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Checkbox from "components/Forms/Checkbox"

/** Styles */
import styles from "./styles"

interface Props {
  handleUpdate: Function
  data: any
}

const ManagementDocuments: React.FC<Props> = ({ handleUpdate, data }) => {
  const { category, section } = styles()
  const [title] = useState("management documents")

  return (
    <Box display="flex" alignItems="center" className={section}>
      <Box flex="3" className={category}>
        {title}
      </Box>
      <Box flex="1">
        {/* View */}
        <Checkbox
          label={null}
          value={Boolean(data?.estate_matter_management_documents_view)}
          onChange={() =>
            handleUpdate(
              "estate_matter_management_documents_view",
              Boolean(!data?.estate_matter_management_documents_view)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Create */}
        <Checkbox
          label={null}
          value={Boolean(data?.estate_matter_management_documents_create)}
          onChange={() =>
            handleUpdate(
              "estate_matter_management_documents_create",
              Boolean(!data?.estate_matter_management_documents_create)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Update */}
        <Checkbox
          label={null}
          value={Boolean(data?.estate_matter_management_documents_edit)}
          onChange={() =>
            handleUpdate(
              "estate_matter_management_documents_edit",
              Boolean(!data?.estate_matter_management_documents_edit)
            )}
        />
      </Box>
      <Box flex="1">
        {/* Delete */}
        <Checkbox
          label={null}
          value={Boolean(data?.estate_matter_management_documents_remove)}
          onChange={() =>
            handleUpdate(
              "estate_matter_management_documents_remove",
              Boolean(!data?.estate_matter_management_documents_remove)
            )}
        />
      </Box>
    </Box>
  )
}
export default ManagementDocuments
