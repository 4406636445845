import React from "react"

// styles
import styles from "./styles"

interface FormWrapperProps {
  title?: string
  titleSmall?: boolean
  children: any
  width?: string
  hasBottomBorder?: boolean
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  title,
  children,
  width,
  hasBottomBorder,
  titleSmall
}) => {
  const classes = styles()
  const hasBorderClass = `${hasBottomBorder ? classes.hasBorder : ""}`
  const titleStyle = titleSmall ? classes.titleSmall : classes.title
  return (
    <div className={classes.container}>
      {title && (
        <div className={titleStyle} style={{ width, margin: "auto" }}>
          {title}
        </div>
      )}
      <div className={hasBorderClass} style={{ margin: "auto" }}>
        {children}
      </div>
    </div>
  )
}
export default FormWrapper
