import React from "react"
import Box from "@material-ui/core/Box"
import { Formik } from "formik"
/** Components */
import FormInput from "components/Forms/FormInput"
import FormWrapper from "components/Forms/FormWrapper"
import RefButton from "components/Forms/RefButton"
import DatePicker from "components/Forms/DatePicker"
import Spacer from "components/Spacer"

/** Context */
import { Context as MainContext } from "context/Dashboard/Applications/forms/addApplication/Context"
import { Context as RootContext } from "context/Dashboard/Applications/main/Context"

/** Validation */
import {
  addApplicationDetails,
  approveAccessCardDetails,
  approveVehiclesDetails,
  approveRenovationDetails
} from "config/Dashboard/Applications/validation"

/** Styles */
import { refSubmitApplicationDetails } from "context/Dashboard/Applications/forms/addApplication/View"
// import AccessCards from "components/Icons/UnitManagement/AccessCards"
import styles from "./styles"

const VehicleRegistration = React.lazy(() =>
  import(
    "pages/Dashboard/Applications/components/AddApplication/Types/VehicleRegistration"
  )
)
const MovingInOut = React.lazy(() =>
  import("pages/Dashboard/Applications/components/AddApplication/Types/MovingInOut")
)
const AddAccessCard = React.lazy(() =>
  import(
    "pages/Dashboard/Applications/components/AddApplication/Types/AddAccessCard"
  )
)

const Renovation = React.lazy(() =>
  import("pages/Dashboard/Applications/components/AddApplication/Types/Renovation")
)

interface Props {
  setIsMovingValid: Function
  setIsVehiclesValid: Function
  setIsApplicationValid: Function
  isVehicleValid: boolean
  isRenovationValid: boolean
  isAccessCardsValid: boolean
  isApplicationValid: boolean
  setIsAccessCardsValid: Function
  setIsRenovationValid: Function
  forApproving: boolean
  allowedDateMessage: string
  allowedTimeMessage: string
}

const ApplicationDetails: React.FC<Props> = ({
  setIsMovingValid,
  setIsVehiclesValid,
  setIsAccessCardsValid,
  setIsRenovationValid,
  setIsApplicationValid,
  allowedDateMessage,
  forApproving
}) => {
  const { generalInfo } = styles()
  const { applicationDetails, setApplicationDetails } = React.useContext(MainContext)
  const { activeTable } = React.useContext(RootContext)
  const { startDate, endDate, serialNo, remarks } = applicationDetails

  const allowedMesesage = activeTable === 3 ? `${allowedDateMessage}` : ``

  const FormView = (type: number): JSX.Element => {
    switch (type) {
      case 1:
        return <MovingInOut setIsMovingValid={setIsMovingValid} />
      case 0:
        return <VehicleRegistration setIsVehiclesValid={setIsVehiclesValid} />
      case 2:
        return <AddAccessCard setIsAccessCardsValid={setIsAccessCardsValid} />
      case 3:
        return <Renovation setIsRenovationValid={setIsRenovationValid} />
      default:
        return <div />
    }
  }

  /** Methods */
  const handleFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: Function,
    setFieldTouched: Function
  ) => {
    handleChange(e)
    setFieldTouched(name, true, false)
    setApplicationDetails &&
      setApplicationDetails({
        ...applicationDetails,
        [name]: e.target.value
      })
  }

  const handleDateChange = (name: string, e: string, setFieldValue: Function) => {
    setFieldValue(name, e)
    setApplicationDetails &&
      setApplicationDetails({
        ...applicationDetails,
        [name]: e
      })
  }

  const getValidationSchema = () => {
    if (activeTable === 3) {
      return approveRenovationDetails
    }
    if (forApproving) {
      if (activeTable === 2) {
        return approveAccessCardDetails
      }
      if (activeTable === 0) {
        return approveVehiclesDetails
      }
    }
    return addApplicationDetails
  }

  const getInitialValidation = () => {
    if (activeTable === 3) {
      return endDate && startDate
    }
    if (forApproving) {
      if (activeTable === 2) {
        return serialNo && startDate
      }
      if (activeTable === 0) {
        return !!serialNo
      }
    }
    return true
  }

  return (
    <div className={generalInfo}>
      <Box borderBottom="1px solid #F2F2F2">{FormView(activeTable || 0)}</Box>

      <Box>
        <Formik
          validateOnMount
          initialValues={{
            serialNo,
            remarks,
            startDate,
            endDate
          }}
          onSubmit={(values, actions): void => {
            JSON.stringify(values, null, 2)
            actions.setSubmitting(false)
          }}
          isInitialValid={getInitialValidation}
          validationSchema={getValidationSchema}
        >
          {({
            isValid,
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleChange,
            setFieldTouched
          }): JSX.Element => {
            return (
              <form>
                <FormWrapper title="Application Details" width="60%">
                  <div
                    className="section"
                    style={{
                      width: "60%",
                      margin: "auto"
                    }}
                  >
                    <Box marginBottom="25px">
                      <FormInput
                        name="serialNo"
                        value={serialNo}
                        idValue="serialNo"
                        label="Serial No."
                        placeholder="Serial No."
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(
                            "serialNo",
                            e,
                            handleChange,
                            setFieldTouched
                          )}
                        onBlur={handleBlur}
                        error={touched.serialNo && Boolean(errors.serialNo)}
                        helperText={
                          errors.serialNo && touched.serialNo && errors.serialNo
                        }
                      />
                    </Box>

                    <Box marginBottom="25px">
                      <FormInput
                        name="remarks"
                        value={remarks}
                        idValue="remarks"
                        label="Remarks"
                        placeholder="Remarks"
                        multiline
                        rows={3}
                        handleOnChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          handleFormChange(
                            "remarks",
                            e,
                            handleChange,
                            setFieldTouched
                          )}
                        onBlur={handleBlur}
                        error={touched.remarks && Boolean(errors.remarks)}
                        helperText={
                          errors.remarks && touched.remarks && errors.remarks
                        }
                      />
                    </Box>

                    <Box display="flex" marginBottom="25px">
                      <Box flex="1">
                        <DatePicker
                          label="Start Date"
                          name="startDate"
                          format="MM/dd/yyyy"
                          value={startDate}
                          placeholder="mm/dd/yyyy"
                          handleDateChange={(value: string): void =>
                            handleDateChange("startDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={
                            (errors.startDate &&
                              touched.startDate &&
                              errors.startDate) ||
                            allowedMesesage
                          }
                        />
                      </Box>
                      <Spacer isDefault />
                      <Box flex="1">
                        <DatePicker
                          label="End Date"
                          name="endDate"
                          format="MM/dd/yyyy"
                          value={endDate === "" ? null : endDate}
                          placeholder="mm/dd/yyyy"
                          minDate={startDate}
                          handleDateChange={(value: string): void =>
                            handleDateChange("endDate", value, setFieldValue)}
                          onBlur={(e: Event): void => handleBlur(e)}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={
                            errors.endDate && touched.endDate && errors.endDate
                          }
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box>{setIsApplicationValid(isValid)}</Box>
                  <RefButton
                    refValue={refSubmitApplicationDetails}
                    action={handleSubmit}
                  />
                </FormWrapper>
              </form>
            )
          }}
        </Formik>
      </Box>
    </div>
  )
}

export default ApplicationDetails
