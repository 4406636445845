import React from "react"

export type CtxType = {
  hasData: boolean
  allowedDateMessage: string
  allowedTimeMessage: string
  detailsInfo: any
  applicationDetails: any
  photos: any
  vehicleInfo: any
  accessCardInfo: any
  files: any
  movingInfo: any
  renovationInfo: any
  submittedData: any
  localPhotos: any
  forApproving: boolean
  openDialog: boolean
  attaching: boolean
  submitting: boolean
  fileAttaching: boolean
  bookingAllowed: boolean
  setBookingAllowed: Function
  setApplicationDetails: Function
  setDetailsInfo: Function
  setOpenDialog: Function
  handleDocsUpload: Function
  removeDoc: Function
  setVehicleInfo: Function
  setAccessCardInfo: Function
  setMovingInfo: Function
  setRenovationInfo: Function
  handleCreateApplication: Function
  handleApproveApplication: Function
  showMain: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
