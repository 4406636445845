const version = "v1"

const API = {
  // ONBOARD
  LOGIN_USER: `svc-account/${version}/admin-staff/login`,
  LOGIN_STAFF: `svc-account/${version}/staff/login`,
  FORGOT_PASSWORD: `svc-account/${version}/forgot-pw`,
  GET_ROLE: `/svc-account/${version}/user-role-permission/`,
  RESET_PASSWORD: `/svc-account/${version}/rap`
}

export default API
