import React, { useContext } from "react"
import Image from "material-ui-image"
/** Context */
import withContext from "context/Dashboard/Facilites/forms/addBooking/withContext"
import { CtxType } from "context/Dashboard/Facilites/forms/addBooking/Context"
import { AppContext } from "App"
/** Utils */
import { fromUnixTimeZone, fromUnixTimeTZ } from "utils/date"
/** Styles */
import styles from "./styles"

interface Props extends CtxType {
  bookingDateValue: string
  facilityBooking: Record<string, any>
  forSummary?: boolean
  hasData: boolean
  data: Record<string, any>
}

const FacilityInfoBreakdown: React.FC<Props> = ({
  facilityBooking,
  bookingDateValue,
  forSummary
}): JSX.Element => {
  const classes = styles()

  const { condoInfo } = useContext(AppContext)

  const fName = facilityBooking?.facility?.name
  const fTimeslot = facilityBooking?.facility_time_slot
  // const timeValue = facilityBooking?.timeValue

  const startTime = fromUnixTimeTZ(
    fTimeslot?.start_time,
    condoInfo?.timezone,
    false,
    "HH:mm"
  )
  const endTime = fromUnixTimeTZ(
    fTimeslot?.end_time,
    condoInfo?.timezone,
    false,
    "HH:mm"
  )
  const timeValue =
    facilityBooking?.timeValue !== undefined
      ? facilityBooking?.timeValue
      : `${startTime} - ${endTime}`

  /** Style override */
  const infoClass = `${classes.facilityInfo} ${forSummary ? "breakdown" : ""}`

  function FacilityInfo() {
    return (
      <div className={infoClass}>
        <div className={classes?.facilityImage}>
          <Image
            imageStyle={{ width: "inherit", height: "162px" }}
            aspectRatio={1 / 1}
            src={facilityBooking?.facility?.facility_photo?.photos[0]?.url}
          />
        </div>
        <div className={classes?.facilityDetails}>
          <div className={classes.facilityDetailsSection}>
            <div className={classes.labelStyle}>FACILITY</div>
            <div className={classes.valueStyle}>{fName}</div>
          </div>
          <div className={classes.twoCol}>
            <div className={classes.facilityDetailsSection}>
              <div className={classes.labelStyle}>DATE</div>
              <div className={classes.valueStyle}>
                {fromUnixTimeZone(bookingDateValue, condoInfo?.timezone)}
              </div>
            </div>
            <div className={classes.facilityDetailsSection}>
              <div className={classes.labelStyle}>TIME</div>
              <div className={classes.valueStyle}>{timeValue}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div className={classes.container}>{FacilityInfo()}</div>
}

export default withContext(FacilityInfoBreakdown)
