import React from "react"

export type CtxType = {
  adminPayload: any
  payloads: any
  tabValues: any
  activeTab: number
  activeRole: string
  activeRoleName: string
  setAdminPayload: Function
  setActiveRole: Function
  selectTab: Function
  setPayloads: Function
  isFetchingRoles: boolean
  setActiveRoleName: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
