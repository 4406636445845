import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import Drawer from "components/Drawer"
import Header from "components/Header"
import Summary from "./Summary"

interface DetailsProps {
  openState: boolean | undefined
  data?: Record<string, any>
  setopenState: Function
}

const AccessCardDetails: React.FC<DetailsProps> = ({ openState, setopenState }) => {
  return (
    <Drawer openState={openState || false} setopenState={setopenState}>
      <Header
        title="Access Card Details"
        subtitle="Unit Management / Access Card"
        subtitleAbove
      />
      <Box height="100%">
        <Summary />
      </Box>
    </Drawer>
  )
}
export default AccessCardDetails
