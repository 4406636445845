import React from "react"
import Box from "@material-ui/core/Box"

// styles
import styles from "./styles"

interface TabsButtonProps {
  options: Record<any, any>
  action: Function
  activeTab: number
}

const TabsButton: React.FC<TabsButtonProps> = ({ options, activeTab, action }) => {
  const { btn } = styles()
  return (
    <>
      <Box display="flex">
        {Object.keys(options).map((option) => {
          const objLength = Object.keys(options).length
          return (
            <Box
              data-testid={`tab-${option}`}
              component="button"
              className={`${btn} ${
                activeTab === parseInt(option, 0) ? "active" : ""
              }`}
              onClick={() => action(parseInt(option, 0))}
              key={options[option]}
            >
              {options[parseInt(option, 0)]}
              <Box
                className={`${
                  activeTab === parseInt(option, 0) ? `arrow-right` : ""
                } ${objLength > 3 ? `wide` : ""}`}
              />
            </Box>
          )
        })}
      </Box>
    </>
  )
}
export default TabsButton
