import { Machine } from "xstate"

export const PostsMachine = Machine({
  id: "postsMachine",
  initial: "tableView",
  states: {
    tableView: {
      on: {
        ADD_POST: "addPost"
      }
    },
    addPost: {
      on: {
        BACK: "tableView"
      }
    },
    updatePost: {
      on: {
        BACK: "tableView"
      }
    }
  },
  on: {
    TABLE_VIEW: "tableView",
    ADD_POST: "addPost",
    UPDATE_POST: "updatePost"
  }
})
