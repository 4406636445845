const version = "v1"
const base = "svc-feedback"

const API = {
  // ONBOARD
  FEEDBACK_FETCH: `${base}/${version}/feedback`,
  FEEDBACK_CREATE: `${base}/${version}/feedback`,
  FEEDBACK_CREATE_REPLY: `${base}/${version}/feedback-reply`,
  FEEDBACK_ASSIGNTO_STAFF: `${base}/${version}/feedback/assign`,
  FEEDBACK_SET_URGENT: `${base}/${version}/feedback/urgent`,
  FEEDBACK_UPDATE: `${base}/${version}/admin/feedback`,
  FEEDBACK_UPDATE_STATUS: `${base}/${version}/feedback/status-override`,
  FEEDBACK_UPDATE_REPLY: `${base}/${version}/feedback-reply`
}

export default API
