import React from "react"

import VMSServiceProvidersProvider, {
  VMSServiceProvidersProviderProps
} from "context/Dashboard/VMS/categories/vmsServiceProviders/Provider"
import VMSServiceProvidersView from "context/Dashboard/VMS/categories/vmsServiceProviders/View"

const VMSServiceProviders: React.FC<VMSServiceProvidersProviderProps> = ({
  showAddForm
}): JSX.Element => (
  <VMSServiceProvidersProvider showAddForm={showAddForm}>
    <VMSServiceProvidersView />
  </VMSServiceProvidersProvider>
)

export default VMSServiceProviders
