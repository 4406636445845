import React, { useState, useContext } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
// import gql from "graphql-tag"
// import { useQuery } from "@apollo/react-hooks"

// context
import { Context } from "context/Dashboard/Dashboard/main/Context"

/**
 * Hooks
 */

// components
import Table from "components/Table"
import Loader from "components/Loader"
import {
  FacilitiesTodayPreview,
  FacilitiesPending,
  FacilitiesTomorrowPreview
} from "./hooks"
import Header from "./components/Header"

import TableHeaderColumns from "./config/TableHeaderColumns"

const Attendance: React.FC = () => {
  const [value, setValue] = useState(0)
  const { dateOptions, dateRange } = useContext(Context)

  const { facilitiesList, isValidating } = FacilitiesTodayPreview(dateRange)
  const { facilitiesListTomorrow, isValidatingTomorrow } = FacilitiesTomorrowPreview(
    dateOptions
  )
  const { facilitiesPendingList, isValidatingPending } = FacilitiesPending()

  function TableData(query: number) {
    switch (query) {
      case 0:
        return facilitiesList
      case 1:
        return facilitiesListTomorrow
      default:
        return facilitiesPendingList
    }
  }

  if (isValidating || isValidatingTomorrow || isValidatingPending) {
    return <Loader dark />
  }

  return (
    <>
      <Box>
        <Card>
          <Header value={value} handleChange={setValue} />
          <Box>
            <Table
              data={TableData(value) || []}
              columns={TableHeaderColumns}
              showPagination={false}
              minRows={3}
            />
            <Box display="flex" justifyContent="flex-end" padding="10px 10px">
              <Button
                color="primary"
                size="large"
                component={Link}
                to="/property-management/facilities"
              >
                <Box fontWeight="600">VIEW ALL</Box>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  )
}

export default Attendance
