import React, { Suspense, useState } from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"

/** Layout */
import WithHeader from "layout/WithHeader"
import Footer from "components/Forms/Footer/new"

/** Component */
import Loader from "components/Loader"
import PageHeaderTitle from "components/PageHeaderTitle"
import Dialog from "components/Dialog"
import Form from "pages/Dashboard/Bookkeeping/components/EditPayment/Form"

/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

export const refSubmit = React.createRef<HTMLDivElement>()

const EditPaymentView: React.FC<CtxType> = ({
  openDialog,
  setOpenDialog,
  setShowPayment,
  paymentDetails,
  handleEditPayment,
  submitting
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isFormValid, setFormValid] = useState(false)
  const pd = paymentDetails

  function handleNext() {
    refSubmit.current?.click()

    if (pd?.paymentType !== "3" && isFormValid) {
      handleEditPayment()
    }
  }

  return (
    <>
      <WithHeader>
        <PageHeaderTitle
          title="Edit Payment"
          breadcrumbs="property management / bookkeeping / edit payment"
          backAction={() => setShowPayment(false)}
        />
        <Card>
          <Box>
            <Suspense fallback={<Loader forContent />}>
              <Form setFormValid={setFormValid} />
            </Suspense>
          </Box>
          <Box margin="30px 0">
            <Footer
              handleNext={() => handleNext()}
              handleBack={null}
              handleCancel={() => setOpenDialog && setOpenDialog(true)}
              label={submitting ? "Submitting" : "Submit"}
              disabled={submitting}
            />
          </Box>
          <Dialog
            action={() => setShowPayment(false)}
            isOpen={openDialog}
            setOpen={setOpenDialog}
            actionLabel="OK"
            title=""
            message="Are you sure you want to cancel?"
          />
        </Card>
      </WithHeader>
    </>
  )
}

export default withContext(EditPaymentView)
