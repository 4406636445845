import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 23px"
  },
  container: {
    backgroundColor: theme.palette.common.white,
    minHeight: "101px"
  },
  totalStyle: {
    color: theme.palette.body.gold,
    fontSize: "48px",
    fontWeight: 500,
    display: "flex",
    flex: "1",
    justifyContent: "flex-end"
  },
  bodyStyle: {
    color: theme.palette.body.dark,
    fontSize: "14px"
  }
}))

export default styles
