import React from "react"
import Box from "@material-ui/core/Box"

/** Components */
import VMSAll from "pages/Dashboard/VisitorManagement/categories/vmsAll"
import VMSVisitors from "pages/Dashboard/VisitorManagement/categories/vmsVisitors"
import VMSDeliveries from "pages/Dashboard/VisitorManagement/categories/vmsDeliveries"
import VMSServiceProviders from "pages/Dashboard/VisitorManagement/categories/vmsServiceProviders"

/** Context */
import { CtxType } from "./Context"
import withContext from "./withContext"

/** Refs */
export const RefAll = React.createRef<HTMLButtonElement>()
export const RefPayment = React.createRef<HTMLButtonElement>()
export const RefDeposit = React.createRef<HTMLButtonElement>()
export const RefOutstandingPayments = React.createRef<HTMLButtonElement>()
export const RefRefund = React.createRef<HTMLButtonElement>()

const MainTableView: React.FC<CtxType> = ({ showAddForm, activeTable }) => {
  const TableView = (value: number) => {
    switch (value) {
      case 0:
        return <VMSAll showAddForm={showAddForm} />
      case 1:
        return <VMSVisitors showAddForm={showAddForm} />
      case 2:
        return <VMSDeliveries showAddForm={showAddForm} />
      case 3:
        return <VMSServiceProviders showAddForm={showAddForm} />
      default:
        break
    }
    return value
  }

  return <Box>{TableView(activeTable)}</Box>
}

export default withContext(MainTableView)
