import React, { useState } from "react"

/** Context */
import { Context } from "./Context"

/** Interface */
export interface VMSMainProviderProps {
  showAddForm: Function
  showUpdateForm: Function
  activeTable: number
}

const VMSMainProvider: React.FC<VMSMainProviderProps> = ({
  children,
  showAddForm,
  showUpdateForm,
  activeTable
}) => {
  /** State */
  const [activeFilter, setActiveFilter] = useState(0)
  const [downloadData, setDownloadData] = useState([])

  /** Methods */
  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  return (
    <Context.Provider
      value={{
        showAddForm,
        activeFilter,
        activeTable,
        downloadData,
        setActiveFilter,
        filterTable,
        setDownloadData,
        showUpdateForm
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default VMSMainProvider
