import React, { useContext, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

/** Components */
import Dialog from "components/Dialog"
/** Context */
import { Context } from "context/Settings/PropertyManagement/Facilities/main/Context"
import { CtxMenuContext } from "components/ContextMenu"
/** Icons */
import { IconEdit, IconAdd, IconDelete } from "components/Icons/ContextMenu"

import styles from "./styles"

interface Props {
  data: any
}

const ContextFacilities: React.FC<Props> = ({ data }) => {
  const classes = styles()
  const { showFacilities, showAddCategory, removeSettings } = useContext(Context)
  const { openContext } = React.useContext(CtxMenuContext)
  const [openDialog, setOpenDialog] = useState(false)

  function showEditView(items: Record<string, any>) {
    showAddCategory && showAddCategory(items)
  }

  function showFacilitiesView(items: Record<string, any>) {
    showFacilities && showFacilities(items)
  }

  function handleRemove(settingsUID: string) {
    removeSettings && removeSettings(settingsUID)
    openContext && openContext(false)
  }

  function handleDialog() {
    setOpenDialog(true)
    openContext && openContext(false)
  }

  return (
    <>
      <List component="nav">
        <ListItem
          className={classes.listItem}
          button
          onClick={() => showFacilitiesView(data)}
        >
          <i>{IconAdd}</i>
          Add / View / Edit Facility
        </ListItem>
        <ListItem
          className={classes.listItem}
          button
          onClick={() => showEditView(data)}
        >
          <i>{IconEdit}</i>
          Edit
        </ListItem>

        <ListItem className={classes.listItem} button onClick={() => handleDialog()}>
          <i>{IconDelete}</i>
          Delete
        </ListItem>
      </List>
      <Dialog
        action={() => handleRemove(data?._uid)}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        actionLabel="Confirm"
        title=""
        message="Are you sure you want to delete this settings?"
      />
    </>
  )
}

export default ContextFacilities
