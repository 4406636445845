import * as React from "react"

const Collected = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 0C0.673 0 0 0.673 0 1.5V6H1V16C1 17.103 1.897 18 3 18H17C18.103 18 19 17.103 19 16V6H20V1.5C20 0.673 19.327 0 18.5 0H1.5ZM2 2H18V4H2V2ZM3 6H17L17.002 16H3V6ZM11 12V14H15V12H11Z"
      fill="#09707B"
    />
  </svg>
)

export default Collected
