import React from "react"
/**
 * Components
 */
import StatusBlock from "components/Table/components/StatusBlock"
import ContextMenu from "components/ContextMenu"
/**
 * Config
 */
import { status, statusColor } from "config/Dashboard/Facilities"
/**
 * Utils
 */
import { fromUnix, fromUnixTimeZone, fromUnixTimeTZ } from "utils/date"
import { checkValue, checkDate } from "utils/helpers"
import ContextApplications from "./ContextMenu"

const TableHeaderColumns = (isApproved: boolean, condoinfo: any) => [
  {
    fixed: "left",
    disableFilters: true,
    columns: [
      {
        width: 70,
        Cell: ({ row }: any) => {
          return (
            <ContextMenu data-testid="context-moveinout">
              <ContextApplications data={row?._original} />
            </ContextMenu>
          )
        },
        filterable: false
      }
    ]
  },
  {
    columns: [
      {
        Header: "Status",
        id: "status",
        sortable: false,
        filterable: false,
        accessor: (c: any) => checkValue(c.status, status[c.status]),
        Cell: ({ original }: any) =>
          checkValue(
            original.status,
            <StatusBlock
              status={status[original?.status]}
              color={statusColor[original?.status]}
            />
          ),
        width: 150
      },
      {
        Header: "No Show",
        id: "noshow",
        accessor: (c: any) => checkValue(c?.no_show, c?.no_show ? "Yes" : "No"),
        Cell: ({ original }: any) =>
          checkValue(original?.no_show, original?.no_show ? "Yes" : "No"),
        width: 150,
        show: isApproved
      },
      {
        Header: "Reference No.",
        id: "referenceNumber",
        accessor: (c: any) => checkValue(c?.facility_booking_reference_no),
        Cell: ({ original }: any) =>
          checkValue(original?.facility_booking_reference_no),
        width: 200
      },
      {
        Header: "Date",
        id: "date",
        accessor: (c: any) => checkDate(c._created, fromUnix(c._created)),
        Cell: ({ original }: any) =>
          checkDate(original._created, fromUnix(original._created)),
        width: 170
      },
      {
        Header: "Block/Unit No.",
        id: "blockUnitNumber",
        accessor: (c: any) => checkValue(c?.unit?.short_name),
        Cell: ({ original }: any) => checkValue(original?.unit?.short_name),
        width: 150
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c: any) => checkValue(c?.booking_by_name),
        Cell: ({ original }: any) => checkValue(original?.booking_by_name),
        width: 250
      },
      {
        Header: "Type",
        id: "type",
        accessor: (c: any) => checkValue(c?.facility?.name),
        Cell: ({ original }: any) => checkValue(original?.facility?.name),
        width: 250
      },
      {
        Header: "Booking Date",
        id: "bookingDate",
        accessor: (c: any) =>
          checkDate(
            c?.booking_date,
            fromUnixTimeZone(c?.booking_date, condoinfo?.timezone)
          ),
        Cell: ({ original }: any) =>
          checkDate(
            original?.booking_date,
            fromUnixTimeZone(original?.booking_date, condoinfo?.timezone)
          ),
        width: 170
      },
      {
        Header: "Time",
        id: "time",
        accessor: (c: any) =>
          `${checkDate(
            c?.facility_time_slot?.start_time,
            fromUnixTimeTZ(
              c?.facility_time_slot?.start_time,
              condoinfo?.timezone,
              false,
              "HH:mm"
            )
          )} - ${checkDate(
            c?.facility_time_slot?.end_time,
            fromUnixTimeTZ(
              c?.facility_time_slot?.end_time,
              condoinfo?.timezone,
              false,
              "HH:mm"
            )
          )}`,
        Cell: ({ original }: any) =>
          `${checkDate(
            original?.facility_time_slot?.start_time,
            fromUnixTimeTZ(
              original?.facility_time_slot?.start_time,
              condoinfo?.timezone,
              false,
              "HH:mm"
            )
          )} - ${checkDate(
            original?.facility_time_slot?.end_time,
            fromUnixTimeTZ(
              original?.facility_time_slot?.end_time,
              condoinfo?.timezone,
              false,
              "HH:mm"
            )
          )}`,
        width: 170
      }
    ]
  }
]

export default TableHeaderColumns
