import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

// component
import Header from "./Header"
import Form from "./Form"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "412px",
    marginBottom: "40px"
  },
  forgot: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    padding: "0 28px"
  },
  copyright: {
    color: theme.palette.body.dark,
    fontSize: "12px"
  }
}))

const LoginCard: React.FC = () => {
  const { container, copyright } = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={container}>
        <Box padding="28px 28px 0">
          <Header />
          <Form />
        </Box>
      </Box>
      <Box className={copyright}>Copyright © CORA 2020</Box>
    </Box>
  )
}

export default LoginCard
