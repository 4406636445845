import React, { useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { AuthContext } from "context/auth-context"

import Dialog from "components/Dialog"

const useStyles = makeStyles(() => ({
  container: {
    margin: "0 30px 0 30px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  linkName: {
    color: "#454B57",
    fontSize: "14px",
    fontWeight: 500,
    textDecoration: "none"
  },
  position: {
    color: "#454B57",
    fontSize: "12px"
  }
}))

interface UserBoxProps {
  userData: any
}

const UserBox: React.FC = () => {
  const { linkName, container } = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const { logout } = useContext(AuthContext)
  const { userData } = useSelector((state: any) => state.user)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirm = () => {
    setOpen(true)
    setAnchorEl(null)
  }

  /** Views */
  function defaultImage(): JSX.Element {
    if (userData?.account_profile_photo?.url === "") {
      return (
        <Box
          width="44px"
          height="44px"
          borderRadius="50%"
          border="1px solid #CCCCCC"
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight="15px"
        >
          <Box fontSize="30px" fontWeight="700" color="#CCCCCC">
            {userData?.account_name[0]}
          </Box>
        </Box>
      )
    }

    return (
      <Box
        style={{
          width: "44px",
          height: "44px",
          borderRadius: "50%",
          backgroundSize: "100%, 100%",
          marginRight: "15px",
          backgroundImage: `url(${userData?.account_profile_photo?.url})`,
          backgroundRepeat: "no-repeat"
        }}
      />
    )
  }

  return (
    <Box display="flex" alignItems="center">
      <Box aria-haspopup="true" onClick={handleClick} className={container}>
        <Box>{defaultImage()}</Box>
        <Box display="flex" flexDirection="column">
          <span className={linkName}>{userData.account_name}</span>
          {/* <span className={classes.position}>Manager</span> */}
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to="/settings/profile" className={linkName}>
          <MenuItem>Profile</MenuItem>
        </Link>
        <MenuItem onClick={handleConfirm} className={linkName}>
          Logout
        </MenuItem>
      </Menu>
      <Dialog
        action={() => logout && logout()}
        isOpen={open}
        setOpen={setOpen}
        actionLabel="Logout"
        title="Logout"
        message="Are you sure you want to logout?"
      />
    </Box>
  )
}

export default UserBox
