import React from "react"

export type CtxType = {
  showDocument: boolean
  showDetails: boolean
  activeTable: number
  activeFolder: string
  activeData: any
  downloadData: Array<{}>
  setActiveData: Function
  setActiveTable: Function
  setActiveFolder: Function
  handleDeletePost: Function
  setShowDocument: Function
  setShowDetails: Function
  setDownloadData: Function
}

export const Context = React.createContext<Partial<CtxType>>({})
