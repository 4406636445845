import React from "react"

/** Context */
import { Context } from "./Context"

const PaymentScheduleProvider: React.FC = ({ children }) => {
  const [activeFilter, setActiveFilter] = React.useState(0)

  const filterTable = (value: number) => {
    setActiveFilter(value)
  }

  return (
    <Context.Provider
      value={{
        activeFilter,
        setActiveFilter,
        filterTable
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default PaymentScheduleProvider
