import * as Yup from "yup"

export const addCategoryDetails: Record<any, any> = Yup.object().shape({
  categoryName: Yup.string().required("Required"),
  categoryType: Yup.string().required("Required"),
  advanceBookingDays: Yup.string().required("Required"),
  cancelBookingDays: Yup.string().required("Required"),
  lastMinuteBooking: Yup.string().required("Required")
})

export const addCategoryEntitlement: Record<any, any> = Yup.object().shape({
  slotsPerUnit: Yup.string().required("Required"),
  isDeposit: Yup.boolean(),
  amountDeposit: Yup.string().when("isDeposit", {
    is: true,
    then: Yup.string().required("Required")
  }),
  isUsageFee: Yup.boolean(),
  amountUsageFee: Yup.string().when("isUsageFee", {
    is: true,
    then: Yup.string().required("Required")
  })
})

export const addFacilityDetails: Record<any, any> = Yup.object().shape({
  facilityName: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  capacity: Yup.number()
    .max(1000, "Maximum capacity is 1000")
    .required("Required")
})

export const addFacilityStatus: Record<any, any> = Yup.object().shape({
  comments: Yup.string().required("Required"),
  status: Yup.number().required("Required"),
  suspensionStart: Yup.string()
    .nullable()
    .when("status", {
      is: "2",
      then: Yup.string().required("Required")
    }),
  suspensionEnd: Yup.string()
    .nullable()
    .when("status", {
      is: "2",
      then: Yup.string().required("Required")
    })
})

export const addEffectiveDate: Record<any, any> = Yup.object().shape({
  effectiveDate: Yup.string()
    .nullable()
    .required("Required")
})

const validation = {
  addCategoryDetails,
  addCategoryEntitlement,
  addEffectiveDate,
  addFacilityDetails
}

export default validation
