/* eslint global-require: "warn"*/
import React, { useContext } from "react"
/**
 * Component
 */
import { Context } from "context/Dashboard/Facilites/forms/addBooking/Context"
import FacilityItems from "./FacilityItems"
/**
 * Context
 */
/**
 * styles
 */
import styles from "./styles"

function FacilityList(): JSX.Element {
  const classes = styles()
  const { selectedMenu, categories } = useContext(Context)

  return (
    <div>
      {categories &&
        categories?.map((category: Record<string, any>, index: number) => {
          const categoryName = category?.category_name
          const isFirstCategory = `${classes.container} ${
            index === 0 ? "isFirstCategory" : "test"
          }`

          if (category?.facility_uids === null) {
            return <div />
          }

          if (selectedMenu && !selectedMenu.includes(category?._uid)) {
            return <div />
          }

          return (
            <div className={isFirstCategory}>
              <div className={classes.category}>{categoryName}</div>
              <FacilityItems
                facilitiesList={category?.facilities}
                settings={category?.facility_settings[0]}
              />
            </div>
          )
        })}
    </div>
  )
}
export default FacilityList
