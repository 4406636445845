import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"

/** Component */
import PageHeaderTitle from "components/PageHeaderTitle"
import Loader from "components/Loader"
import Roles from "components/Common/Roles"
import Label from "components/Forms/Label"
import AccountPermission from "pages/Settings/AccountManagement/Staff/components/AccountSettings/components/AccountPermission"
import Form from "pages/Settings/AccountManagement/Staff/components/AccountSettings/Form"

/** Context */
import { IconAdd, IconEdit } from "components/Icons/Settings/AccountManagement"
import { RolesCategory } from "config/Common/Roles"
import withContext from "./withContext"
import { CtxType } from "./Context"

const AccountSettingsView: React.FC<CtxType> = ({
  selectTab,
  activeCategory,
  activeRole,
  activeRoleName,
  sendBack,
  showForm,
  setShowForm,
  formType,
  showFormType,
  formDetails,
  isRolesUpdating
}) => {
  const ViewForm = (): JSX.Element => {
    return (
      <Form openState={showForm} setopenState={setShowForm} formType={formType} />
    )
  }

  const MainView = (): JSX.Element => {
    const roleValue = `${activeRole}/${activeRoleName}/${activeCategory}`
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PageHeaderTitle
              title="Staff Account Settings"
              breadcrumbs="SETTINGS / account management / settings"
              backAction={() => sendBack()}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              startIcon={IconAdd}
              onClick={() => showFormType(true, 1)}
              variant="contained"
            >
              ADD USER ROLE
            </Button>
          </Box>
        </Box>
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="30px 40px"
            borderBottom="3px solid #F2F2F2"
          >
            <Box display="flex">
              <Box marginRight="50px" width="300px">
                <Label label="User Role" />
                {!isRolesUpdating ? (
                  <Roles
                    userType={2}
                    value={roleValue}
                    onChange={(value: any) => selectTab(value)}
                    withName
                  />
                ) : (
                  <Loader dark />
                )}
              </Box>
              <Box>
                <Label label="Category" />
                <Box display="flex" alignItems="center" height="53px">
                  {RolesCategory[Number(formDetails?.category)]}
                </Box>
              </Box>
            </Box>
            <IconButton onClick={() => showFormType(true, 2)}>{IconEdit}</IconButton>
          </Box>

          <Box marginBottom="30px">
            <AccountPermission />
          </Box>
        </Card>
      </Box>
    )
  }

  return (
    <>
      {MainView()}
      {ViewForm()}
    </>
  )
}

export default withContext(AccountSettingsView)
