import React from "react"
import Box from "@material-ui/core/Box"
import useSWR from "swr"
import Cookie from "js-cookie"

/** Component */
import CardTitle from "components/CardTitle"

/** API */
import BookkeepingAPI from "services/Dashboard/Bookkeeping"

/** Context */
import dateHelper from "utils/date"
import { toNumeral } from "utils/helpers"

/** Styles */
import styles from "../styles"

/** Icons */
import { IconRefund } from "../icons"

interface TotalRefundProps {
  dates: any
  currency: string
}

const TotalRefund: React.FC<TotalRefundProps> = ({ dates, currency }) => {
  const { amount, iconContainerLarge } = styles()

  const fetchTotalRefundStats = async () =>
    BookkeepingAPI.getTotalCollections({
      "condo_uid": Cookie.get("condoUID"),
      "start_date": `${dateHelper.toUnix(dates.first)}`,
      "end_date": `${dateHelper.toUnix(dates.last)}`,
      "payment_type": "3"
    })

  const { data } = useSWR("fetchTotalRefundStats", fetchTotalRefundStats, {
    revalidateOnFocus: true
  })

  const oPayments = data?.data?._data !== undefined && data?.data?._data

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="25px 30px"
    >
      <CardTitle
        title="Refund"
        icon={
          <Box bgcolor="#9C6522" className={iconContainerLarge}>
            {IconRefund}
          </Box>
        }
      />
      <Box className={amount}>
        <small>{currency}</small> 
        {' '}
        {toNumeral(oPayments?.amt)}
      </Box>
    </Box>
  )
}
export default TotalRefund
