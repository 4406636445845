import { makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0 40px"
  },
  menus: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "40px 0 20px",
    marginBottom: "35px",
    borderBottom: "1px solid #F2F2F2"
  },
  bookingDate: {
    width: "300px",
    padding: "30px 0 0"
  },
  buttonContainer: {
    position: "relative",
    background: "rgba(33,33,33,0.08)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: theme.palette.body.main,
    borderRadius: "16px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "auto",
    margin: "0 15px 10px 0",
    outline: "none",
    "&.active": {
      background: theme.palette.body.orange,
      color: theme.palette.common.white
    }
  }
}))

export default styles
