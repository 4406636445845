import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { mutate } from "swr"

/** Context */
import service from "services/Settings/AccountManagement/Admin"
import { Context } from "./Context"

export interface Props {
  sendBack: Function
}

const Provider: React.FC<Props> = ({ children, sendBack }) => {
  const [submitting, setSubmitting] = useState(false)
  const [adminPayload, setAdminPayload] = useState({})
  const [activeRole, setActiveRole] = useState("")
  const [activeRoleName, setActiveRoleName] = useState("")
  const [isFetchingRoles, setisFetchingRoles] = useState(false)
  const [payloads, setPayloads] = useState({})
  const [showForm, setShowForm] = useState(false)
  const [formType, setFormType] = useState(1)
  const [formDetails, setFormDetails] = useState({
    roleName: ""
  })

  /**
   * Notification
   */
  const notifySuccess = (roleName: string, action: number) =>
    toast(`Successfully ${action === 1 ? "created" : "updated"} the role.`, {
      type: toast.TYPE.SUCCESS
    })

  /** Methods */
  async function init() {
    try {
      const response = await service.getRoles({
        "user_type": 1
      })
      return response?.data?._data
    } catch (e) {
      return e
    }
  }

  function showFormType(state: boolean, type: number): void {
    setShowForm(state)
    setFormType(type)

    if (type === 2) {
      setFormDetails({
        roleName: activeRoleName
      })
    } else {
      setFormDetails({
        roleName: ""
      })
    }
  }

  async function fetchRolesPermission(roleId: string) {
    setisFetchingRoles(true)
    try {
      const response = await service.getRolePermissions(roleId)
      setPayloads(response?.data?._data?.permission)
      setisFetchingRoles(false)
      return response
    } catch (e) {
      setisFetchingRoles(false)
      return e
    }
  }

  function selectTab(roleData: string) {
    const roleString = roleData?.split("/")
    setActiveRole(roleString[0])
    setActiveRoleName(roleString[1])
    fetchRolesPermission(roleString[0])
  }

  async function handleFormSubmit() {
    const payload = {
      "role_name": formDetails?.roleName,
      "category": 1,
      "permission_type": 1
    }

    setSubmitting(true)

    const setup = () =>
      init().then((res) => {
        Object.keys(res).map(() => {
          const newlyCreated = res.filter(
            (nc: any) => nc?.role_name === formDetails?.roleName
          )

          if (formType === 1) {
            selectTab(`${newlyCreated[0]?._uid}/${newlyCreated[0]?.role_name}`)
          } else {
            selectTab(`${activeRole}/${formDetails?.roleName}`)
          }

          return res
        })
      })

    const endpoint =
      formType === 1
        ? service.createAdminRole(payload)
        : service.updateAdminRole(payload, activeRole)

    try {
      const response = await endpoint
      await mutate("fetchRoles-usertype-1")
      await setup()

      showFormType(false, 1)
      notifySuccess(activeRoleName, formType)
      setSubmitting(false)
      return response
    } catch (e) {
      setSubmitting(false)
      return e
    }
  }

  const initSetup = async () => {
    return init()
      .then((res) => {
        return Object.keys(res).map(() => {
          setActiveRole(res[0]?._uid)
          setActiveRoleName(res[0]?.role_name)
          setFormDetails({
            roleName: res[0]?.role_name
          })
          mutate("fetchRoles-usertype-1")
          return res[0]
        })
      })
      .then((res) => fetchRolesPermission(res[0]?._uid))
  }

  useEffect(() => {
    init()
      .then((res) => {
        setisFetchingRoles(true)
        return Object.keys(res).map(() => {
          setisFetchingRoles(false)
          if (activeRole === "") {
            setActiveRole(res[0]?._uid)
            setActiveRoleName(res[0]?.role_name)
          }

          return res[0]
        })
      })
      .then((res) => fetchRolesPermission(res[0]?._uid))
  }, [])

  return (
    <Context.Provider
      value={{
        adminPayload,
        activeRole,
        activeRoleName,
        setAdminPayload,
        setActiveRole,
        selectTab,
        setPayloads,
        setShowForm,
        handleFormSubmit,
        showFormType,
        setFormDetails,
        initSetup,
        formDetails,
        sendBack,
        payloads,
        isFetchingRoles,
        showForm,
        submitting
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
